import { Constantes, GaUtils } from '@common/utils';
import { lowerCase } from 'lodash';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export const ItemMenu = ({ item }) => {
    const url = item.enabled && !item.active ? item.url : null;
    const classItem = 'menu-item ' + lowerCase(item.internalName) + (!item.enabled ? ' disabled' : '') + (item.active ? ' active' : '');

    const onClick = () => {
        if (item.internalName !== Constantes.APP_NAME)
            GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.UNIFIED_MENU, `/${item.internalName}/CLICK`, Constantes.GA_LABELS_EVENTS.MENU_BAR_LEFT)
    }

    return (
        <a href={url} id={"menuItem-" + item.id} onClick={onClick}>
            <div className={classItem}>
                <img src={item.iconUrl} alt="" />
                <UncontrolledTooltip boundariesElement='window' target={"menuItem-" + item.id} placement='right' delay={0}>
                    {item.name}
                </UncontrolledTooltip>
            </div>
        </a>
    )
}
