import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Utils, Constantes, RetailCompassStorage } from '@common/utils/';
import PropTypes from 'prop-types';
import CreditCardIcon from "mdi-react/CreditCardOutlineIcon";
import { sortBy, find } from 'lodash';
import * as PriceUtils from './product_detail/PriceUtils';
import { SellerNameButton } from './SellerNameButton';


class ComparerProductCard extends Component {

  static propTypes = {
    retailers: PropTypes.array,
    price: PropTypes.number,
    pivotPrice: PropTypes.number,
    showDifference: PropTypes.bool,
    onClick: PropTypes.func,
    status: PropTypes.number,
    paymentType: PropTypes.string,
    fees: PropTypes.number,
    isRetailView: PropTypes.bool,
    showCardIcon: PropTypes.bool,
    isSeller: PropTypes.bool,
    sellerName:PropTypes.string,
    productId: PropTypes.number,
    priceMapping: PropTypes.object,
    sellerMatchesByStore: PropTypes.object,
    storeName: PropTypes.string,
    storeId: PropTypes.number,
  };

  static defaultProps = {
    isSeller: false,
    sellerName:null,
    productId: null,
    priceMapping: {},
    isRetailView: false,
    showCardIcon: false,
    pivotPrice: null,
    retailers: [],
    onClick: () => { }
  };

  constructor(props) {
    super(props);
    this.getRetailersLegend = this.getRetailersLegend.bind(this);
    this.getPivotDifference = this.getPivotDifference.bind(this);
    this.getRetailDifference = this.getRetailDifference.bind(this);
  }

  getRetailersLegend() {
    const showTooltip = this.props.retailers.length > 1;
    const text = showTooltip ? [this.props.retailers.length, "retailers"].join(" ") : this.props.retailers[0].name;
    const id = Utils.generateRandomId({ prefix: "count", connector: "-" });
    const atLeastOneIsCardPrice = find(this.props.retailers, ['showCardIcon', true]) !== undefined;
    return (<div className="legend" key={id}>
      <span id={id}>{text}</span>
      {
        showTooltip ?
          <UncontrolledTooltip
            boundariesElement={"window"}
            placement='right'
            target={id}
            id={"tooltip-" + id}
            delay={0}>
            {sortBy(this.props.retailers, ['name']).map((retail) => (
              <div className="retail-tooltip" key={Utils.generateRandomId({ preffix: "ttlist" })}>
                {
                  atLeastOneIsCardPrice ?
                    retail.showCardIcon ? <CreditCardIcon className="card-icon" size={12} /> : <div className="card-icon" />
                    : null
                }
                {retail.name}
              </div>
            ))}
          </UncontrolledTooltip> : null
      }
    </div>);
  }

  getPivotDifference() {
    if (!this.props.pivotPrice) {
      return 0;
    }
    return parseInt(((this.props.price - this.props.pivotPrice) * 100 / this.props.pivotPrice).toFixed(0));
  }

  getRetailDifference() {
    const { normalPrice } = this.props.priceMapping;
    if (!this.props.isRetailView || !normalPrice) {
      return 0;
    }
    return parseInt(((this.props.price - normalPrice) * 100 / normalPrice).toFixed(0));
  }

  buildSellerButton(){
    const mkpKey = Utils.buildKey(Constantes.MKP_PREFIX, this.props.productId, null, null) + Utils.getRandomInt(999);
    return <SellerNameButton 
              id={mkpKey} 
              placement='left' 
              trigger='click' 
              iconName='seller-icon'
              storeName={this.props.storeName}
              sellerName={this.props.sellerName}
              product={this.props.sellerMatchesByStore}
              />
  }

  render() {
    let { containerClassName } = this.props;
    if (this.props.status === Constantes.STATUS_PRODUCT.WITHOUT_STOCK) {
      return (
        <div className="without-stock-card" onClick={this.props.onClick}>
            {(this.props.isRetailView && this.props.isSeller) ? this.buildSellerButton() : null}
          {this.props.isRetailView ?
            <div className="main">
              {this.props.showCardIcon ? <CreditCardIcon className="card-icon" size={12} /> : null}
              {Utils.formatPrice(Math.round(this.props.price))}
            </div> : null}
          <div><span className="text">sin stock</span></div>
        </div>
      )

    } else if (!this.props.price) {
      if (!this.props.applyToPriceView
        && Utils.isObjectWithData(this.props.priceMapping)) {
        return (
          <div className="compare-product-card" onClick={this.props.onClick}>
            {this.props.isRetailView
              && this.props.isSeller
              && this.buildSellerButton()}
                <div className="main">{'-'}</div>
          </div>
        )
      } else {
        return <div className="empty-card" onClick={this.props.onClick}></div>;
      }

    } else {
      const pivotDifference = this.getPivotDifference();
      const retailDifference = this.getRetailDifference();
      const isVisibleFees = PriceUtils.isVisibleFees(RetailCompassStorage.getStoreCountry(), this.props.price);
      const { offerPrice } = this.props.priceMapping;
      const countryCode = RetailCompassStorage.getCountryCode();
      const fee = this.props.fees;
      const activeFee = PriceUtils.isVisibleFees(countryCode, this.props.priceMapping.price);
      
      let feeClassName = null;
      if (activeFee) {
        containerClassName = containerClassName + ' active-fee';
        feeClassName = Utils.assignFeeClass(fee);
      }

      return (
        <div className="compare-product-card" onClick={this.props.onClick}>
            {(this.props.isRetailView && this.props.isSeller) ? this.buildSellerButton() : null}
          <div className="main">
            {this.props.showCardIcon ? <CreditCardIcon className="card-icon" size={12} /> : null}
            {Utils.formatPrice(Math.round(this.props.price))}
          </div>
          {this.props.retailers.length > 0 ? this.getRetailersLegend() : null}
          {this.props.isRetailView && offerPrice && offerPrice !== this.props.price ?
            <div className="secondary"> {Utils.formatPrice(offerPrice)} </div> : null}
          {this.props.isRetailView ?
            <div className="fees-and-difference">
              {this.props.fees && this.props.fees !== 0 && isVisibleFees ?
                <div className={"fees " + feeClassName}><span className="text">{PriceUtils.feeAbbreviation(countryCode) + fee}</span></div>
                : <div className="fees empty"></div>}
              {retailDifference !== 0 ?
                <div className="retail-difference"><span className="text">{retailDifference}%</span></div>
                : <div className="retail-difference empty"></div>}
            </div>
            : null}
          {this.props.showDifference && pivotDifference !== null ?
            pivotDifference === 0 ?
              <div className="difference difference--equal">0%</div> :
              pivotDifference < 0 ?
                <div className="difference difference--green">{pivotDifference}%</div> :
                <div className="difference difference--red">+{pivotDifference}%</div>
            : null}
        </div>
      );
    }
  }

}

export default ComparerProductCard;
