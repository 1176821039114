import { domain } from "../utils/auth0config";
import { AbstractService } from "@common/data-access/";
import { ConstantesApi } from "@common/utils";

const jwtDecode = require("jwt-decode");

const getDomain = () => {
    let domainEnvUrl = domain();
    if (!domainEnvUrl.startsWith("http")) {
        domainEnvUrl = "https://" + domainEnvUrl;
    }

    return domainEnvUrl;
};

export const getUserData = async (accessToken) => {
    const params = {
        api: `${getDomain()}/userinfo?`,
        additionalHeaders: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        skipDispatch: true,
    };

    return await AbstractService.getAsJson(params);
};

export const getStoresInformation = async (userCatalog) => {
    const data = {};
    if (userCatalog.id) {
        data.id = userCatalog.id;
    } else if (userCatalog.email) {
        data.username = userCatalog.email;
    }
    data.userFields = "ONLY_STORES";

    const params = {
        api: ConstantesApi.API_USERS,
        skipDispatch: true,
        data: data,
    };
    return await AbstractService.postAsJson(params);
};

export const getUserFromApi = async (accessToken) => {
    let userDataFromApi;
    if (accessToken) {
        let userFromToken = jwtDecode(accessToken);
        userDataFromApi = await getUserData(accessToken);

        if (userDataFromApi) {
            userDataFromApi = {
                ...userDataFromApi,
                exp: userFromToken.exp,
                iat: userFromToken.iat,
            };
        }
    }

    return userDataFromApi;
};
