import React, { Component } from 'react';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { isEqual, find, unionBy } from 'lodash';
import {
    Constantes as Constants,
    Utils,
    RetailCompassStorage,
    Constantes,
    ChurnZero
} from "@common/utils";

export const genericPropTypes = {
    brands: PropTypes.array.isRequired,
    stores: PropTypes.array.isRequired,
    fetchProductItems: PropTypes.func.isRequired,
    currentFilter: PropTypes.object.isRequired,
    lastFilterWithProducts: PropTypes.object,
    fetchFavorites: PropTypes.func,
    screenSizeItems: PropTypes.array,
    dynamicAttributes: PropTypes.array,
    isRestoringLastState: PropTypes.bool,
    isRestoringInitialState: PropTypes.bool,
    applicationData: PropTypes.object,
    currentCanonicalCategory: PropTypes.object
};

export const withGenericFilters = (WrappedComponent, formName) => {
    return class extends Component {
        static propTypes = genericPropTypes;

        static defaultProps = {
            dynamicAttributes: []
        };

        constructor(props) {
            super(props);

            this.screenSizePickerRef = null;

            this.setScreenSizePickerRef = ref => {
                this.screenSizePickerRef = ref;
            };

            this.onRemoveFilterData = this.onRemoveFilterData.bind(this);
            this.fetchProductItems = this.fetchProductItems.bind(this);
            this.buildCurrentFilter = this.buildCurrentFilter.bind(this);
            this.onChangeMultiSelect = this.onChangeMultiSelect.bind(this);
            this.onChangeFavorite = this.onChangeFavorite.bind(this);
            this.onChangeAvailability = this.onChangeAvailability.bind(this);
            this.onChangeScreenSize = this.onChangeScreenSize.bind(this);
            this.changeFieldValue = this.changeFieldValue.bind(this);
            this.onClickPriceRange = this.onClickPriceRange.bind(this);
            this.onChangeAttributeValue = this.onChangeAttributeValue.bind(this);
            this.getScreenSizePickerDefaultProps = this.getScreenSizePickerDefaultProps.bind(this);
            this.buildFilterDynamicAttributes = this.buildFilterDynamicAttributes.bind(this);
            this.getScreenSizePickerDefaultRangeProps = this.getScreenSizePickerDefaultRangeProps.bind(this);
            this.onChangeLabels = this.onChangeLabels.bind(this);
            this.onChangeSellerType = this.onChangeSellerType.bind(this);
            this.onChangeOutOfStockRanges = this.onChangeOutOfStockRanges.bind(this);
            this.onChangeRankingFilter = this.onChangeRankingFilter.bind(this);
            this.onChangeAvailableProductsFilter = this.onChangeAvailableProductsFilter.bind(this);
            this.onChangeContractTypeFilter = this.onChangeContractTypeFilter.bind(this);
            this.trackFilters = this.trackFilters.bind(this);
        };

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.isRestoringLastState) {
                let screenSizePickerDefaultProps = this.getScreenSizePickerDefaultProps();
                if (screenSizePickerDefaultProps && screenSizePickerDefaultProps.start) {
                    this.screenSizePickerRef.setDefaultValues(
                        screenSizePickerDefaultProps.start, screenSizePickerDefaultProps.end
                    );
                } else if (this.screenSizePickerRef) {
                    this.screenSizePickerRef.removeFilter(true);
                }
            }
            if (nextProps.isRestoringInitialState && this.screenSizePickerRef) {
                this.screenSizePickerRef.removeFilter(true);
            }
        }

        componentDidUpdate(prevProps) {
            if (Utils.getObjectKeysSize(this.props.reduxFormInitialValues) > 0 && !isEqual(this.props.reduxFormInitialValues, prevProps.reduxFormInitialValues)) {
                let rangeProps = this.getScreenSizePickerDefaultRangeProps(this.props.reduxFormInitialValues.screenSizeItems || []);
                if (this.screenSizePickerRef && rangeProps.start) {
                    this.screenSizePickerRef.setDefaultValues(rangeProps.start, rangeProps.end);
                }
            }
        }
        //se encarga de concatenar los atributos activos con los nuevos (evitar sobrescribir)
        buildFilterDynamicAttributes(params) {
            let conc = unionBy(params.dynamicAttributes || [], this.props.currentFilter.dynamicAttributes || [], 'id')
            return conc
        }

        buildCurrentFilter(params) {
            params.lastFilterField = Utils.hasValue(params.lastFilterField) ? params.lastFilterField : null;
            return { dynamicAttributes: this.buildFilterDynamicAttributes(params), ...this.props.currentFilter, ...params };
        }

        fetchProductItems(params, resetCurrentPage) {
            let currentFilter = this.buildCurrentFilter(params);
            if (resetCurrentPage) {
                currentFilter.paginationCurrentPage = 1;
            }

            if (this.props.applicationData) {
                currentFilter.applicationData = this.props.applicationData;
            }
            this.props.fetchProductItems(currentFilter);
            this.trackFilters(params)
        }

        trackFilters(params) {
            const trackEvent = eventName => ChurnZero.trackEvent({ eventName });

            if (params.favorites) {
                trackEvent("scalibur_filtro_favoritos");
            }
            if (params.labels?.length) {
                trackEvent("scalibur_filtro_label");
            }
            if (params.rankingFilter) {
                trackEvent("scalibur_filtro_posicionamiento");
            }
            if (params.stores?.length) {
                trackEvent("scalibur_filtro_retailer");
            }
            if (params.brands?.length) {
                trackEvent("scalibur_filtro_marca");
            }
            if (params.sellerType?.length) {
                trackEvent("scalibur_filtro_marketplace");
            }
            if (params.onlyAvailableProducts) {
                trackEvent("scalibur_filtro_solo_stock");
            }
            if (params.outOfStockRanges?.length) {
                trackEvent("scalibur_filtro_sin_stock");
            }
            if (params.from || params.to) {
                trackEvent("scalibur_filtro_rango_precio");
            }

            if (params?.dynamicAttributes?.length) {
                const dynamicAttribute = params.dynamicAttributes[0];
                const category = RetailCompassStorage.getCurrentCanonicalCategory();
                ChurnZero.trackEvent({
                    eventName: "scalibur_filtro_atributo",
                    customFields: {
                        categoria: category?.name,
                        atributo: dynamicAttribute.name
                    }
                });
            }
        }

        onRemoveFilterData(field) {
            this.changeFieldValue(field, []);
            return this.onChangeMultiSelect(field, []);
        }

        onChangeMultiSelect(field, values) {
            if (!field) {
                return;
            }

            let params = {};
            params[field] = values;
            this.fetchProductItems(params, true);
        }

        onChangeLabels(values) {
            const ids = values.map((value) => { return `${value.id}_${value.accessType}`; });
            let params = {};
            params['labels'] = ids;
            this.changeFieldValue('labels', ids);
            this.fetchProductItems(params, true);
        }

        onClickPriceRange(from, to) {
            let params = {};
            params['from'] = from;
            params['to'] = to;
            this.changeFieldValue('from', from);
            this.changeFieldValue('to', to);
            this.fetchProductItems(params, true);
        }

        onChangeFavorite(value) {
            if (this.props.fetchFavorites) this.props.fetchFavorites();
            let field = 'favorites';
            let params = {};
            params[field] = value;
            params['lastFilterField'] = field;
            this.changeFieldValue(field, value);
            this.fetchProductItems(params, true);
        }

        changeFieldValue(field, value) {
            this.props.dispatch(change(formName, field, value))
        }

        onChangeAvailability(value) {
            this.changeFieldValue('productAvailability', value);
            this.fetchProductItems({ productAvailability: value }, true);
        }

        onChangeScreenSize(values) {
            this.changeFieldValue('screenSizeItems', values);
            this.fetchProductItems({ screenSizeItems: values }, true);
        }

        onChangeSellerType(selectedValues, value) {
            let sellerType = selectedValues.map((item) => (item.value));
            this.changeFieldValue('sellerType', sellerType);
            this.fetchProductItems({ sellerType: sellerType }, true);
        }
        
        onChangeContractTypeFilter(checkedItems, clickedItem) {
            const contractType = checkedItems.map((item) => (item.value));
            this.changeFieldValue(Constantes.FIELD_NAMES.CONTRACT_TYPE, contractType);
            this.fetchProductItems({ contractType }, true);
        }

        onChangeOutOfStockRanges(selectedValues, value) {
            let outOfStockRanges = selectedValues.map((item) => (item.value));
            this.changeFieldValue(Constantes.FIELD_NAMES.OUT_OF_STOCK_RANGES, outOfStockRanges);
            this.fetchProductItems({ outOfStockRanges: outOfStockRanges }, true);
        }

        onChangeAttributeValue(attribute, value) {
            let attributeFound = find(this.props.dynamicAttributes, { id: attribute.id });
            if (attributeFound) {
                attributeFound.selectedData = value;
                this.changeFieldValue(attributeFound.name, attributeFound.selectedData);
                this.props.requestChangeAttributeSelectedData({ id: attribute.id, selectedData: value });
                this.fetchProductItems({ dynamicAttributes: this.buildFilterDynamicAttributes({ dynamicAttributes: [{ name: attributeFound.name, id: attributeFound.id, selectedData: attributeFound.selectedData, type: attributeFound.type }] }) }, true);
            }
        }

        onChangeRankingFilter(selectedValue) {
            this.changeFieldValue(Constantes.FIELD_NAME_RANKING_FILTER, selectedValue);
            this.fetchProductItems({ rankingFilter: selectedValue }, true);
        }

        onChangeAvailableProductsFilter(value) {
            const _value = !!value;
            this.changeFieldValue(Constantes.FIELD_NAMES.ONLY_AVAILABLE_PRODUCTS, _value);
            this.fetchProductItems({ onlyAvailableProducts: _value }, true);
        }

        getScreenSizePickerDefaultProps() {
            let screenSizeItems = this.props.lastFilterWithProducts.screenSizeItems;
            return this.getScreenSizePickerDefaultRangeProps(screenSizeItems);
        }

        getScreenSizePickerDefaultRangeProps(screenSizeItems) {
            let start = null;
            let end = null;

            if (screenSizeItems && screenSizeItems.length > 0) {
                start = screenSizeItems[0];
                end = screenSizeItems[screenSizeItems.length - 1];
            }

            return { start, end };
        }

        isVisibleAttribute(name) {
            let attribute = find(this.props.attributes, ['name', name]);
            return !!attribute
                && !!attribute.visualization
                && Utils.isAttributeVisibleFromCountryCodeAndUserType(attribute.visualization.visible);
        }

        getAttributesProps() {
            let userAttributes = RetailCompassStorage.getCurrentCategoryAttributes();
            let attributesProps = {};
            userAttributes.forEach((item) => {
                if (Utils.isAttributeVisibleFromCountryCodeAndUserType(item.visualization.visible)) {
                    if (item.id === Constants.ATTRIBUTES.SCREEN_SIZES_INCHES && this.props.screenSizeItems && this.props.screenSizeItems.length > 0) {
                        attributesProps.onChangeScreenSize = this.onChangeScreenSize;
                        attributesProps.setScreenSizePickerRef = this.setScreenSizePickerRef;
                        attributesProps.showScreenSize = true;
                    }
                }
            });

            return attributesProps;
        }

        render() {
            const otherProps = {
                onRemoveFilterData: this.onRemoveFilterData,
                onChangeMultiSelect: this.onChangeMultiSelect,
                onChangeFavorite: this.onChangeFavorite,
                onChangeAvailability: this.onChangeAvailability,
                onClickPriceRange: this.onClickPriceRange,
                onChangeAttributeValue: this.onChangeAttributeValue,
                onChangeLabels: this.onChangeLabels,
                onChangeSellerType: this.onChangeSellerType,
                onChangeOutOfStockRanges: this.onChangeOutOfStockRanges,
                onChangeRankingFilter: this.onChangeRankingFilter,
                onChangeAvailableProductsFilter: this.onChangeAvailableProductsFilter,
                onChangeContractTypeFilter: this.onChangeContractTypeFilter,
                ...this.getAttributesProps()
            };

            return <WrappedComponent {...this.props} {...otherProps} />
        }
    }
}

