import React from 'react';

const EmptyTable = (props) => {
    return (<div className="validations-empty-table">
        <div className="validations-empty-table__header">
            {props.title}
        </div>
    </div>);
}

export default EmptyTable;