import { min, filter } from 'lodash';
import { validateIfMatchIsMyProductByStoreId } from '../common/match';
import * as Utils from '../../utils/utils';
import * as Constants from '../../utils/constantes';
import { getPriceByView } from '../../../../components/src/common/components/product_detail/PriceUtils';

export const formatMatchedProducts = (params) => {
    let { storeId, matchedProducts } = params;
    matchedProducts = matchedProducts || [];
    const pricesToShow = params.currentFilter && params.currentFilter.viewPrices ? params.currentFilter.viewPrices : ["ALL"];

    return matchedProducts.map((item) => {
        let canonicalProduct = { ...item };

        if (Utils.isArrayWithData(canonicalProduct.competitorsProducts)) {
            canonicalProduct.competitorsProducts = canonicalProduct.competitorsProducts.map((match) => {
                let isMyProduct = validateIfMatchIsMyProductByStoreId(storeId, match, params.requestBody.sellerType)
                return {
                    ...match,
                    isMyProduct: isMyProduct,
                    storeName: (isMyProduct ? (match.isSeller ? 'Tu Mkp' : 'Tu tienda') : match.storeName)
                }
            });

            canonicalProduct.dpmValue = calculateDPM(canonicalProduct.competitorsProducts, pricesToShow);
            canonicalProduct.yourProduct = canonicalProduct.competitorsProducts.find((item) => item.isMyProduct);
            canonicalProduct.isMyProduct = !!canonicalProduct.yourProduct;
            canonicalProduct.yoursProducts = getAllYoursProducts(canonicalProduct.allCompetitorsProducts, storeId);
        }

        return {
            ...canonicalProduct
        }
    });
}

export const getAllYoursProducts = (allCompetitorsProducts, storeId) => {
    if (!Utils.isArrayWithData(allCompetitorsProducts)) {
        return [];
    }
    let yoursProducts = filter(allCompetitorsProducts, ['storeId', storeId]);
    return yoursProducts;
}

export const calculateDPM = (competitorsProducts, pricesToShow) => {
    let dpm;

    if (Utils.isArrayWithData(competitorsProducts)) {
        let yourProduct = competitorsProducts.find((match) => match.isMyProduct && match.status === Constants.STATUS_PRODUCT.WITH_STOCK);
        const competitorsPrices = competitorsProducts
            .filter((match) => (!match.isMyProduct && match.status === Constants.STATUS_PRODUCT.WITH_STOCK))
            .map((item) => (getPriceByView(item, pricesToShow, item.isPriceWithCard)))
            .map((match) => (match.price));

        if (yourProduct && Utils.isArrayWithData(competitorsPrices)) {
            const yourProductToShow = getPriceByView(yourProduct, pricesToShow, yourProduct.isPriceWithCard);
            let minimumPrice = min(competitorsPrices);
            dpm = ((yourProductToShow.price - minimumPrice) / minimumPrice) * 100;
        }
    }

    return dpm;
}
