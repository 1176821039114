import React from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import { ShippingUtils, Utils } from "@common/utils";

const ShippingButton = ({
    id,
    iconName,
    className,
    product,
    classShipping,
    filter
}) => {
    const days = Utils.getShippingDays(product.shippings.days);
    const price = ShippingUtils.getShippingPrice(Math.floor(product.shippings.price));
    let button;
                button = (
                    <button
                        className={`message-icon-button ${classShipping} ${iconName} ${className} ${filter} ${ShippingUtils.isOnDay(product.shippings.days)}`}
                        id={id}
                    >
                        {classShipping === "" ?
                        <div className="span-content">{filter === 'active-cost' ? price : days}</div>
                    : null}
                        {classShipping === "commune-without-shipping" ? 
                            <UncontrolledTooltip placement='top' boundariesElement='window' className="retailer-header-tooltip" delay={0} target={"tooltip-" + product.id} > 
                             <div className="commune-without-shipping-tooltip">Este competidor no tiene Shipping disponible para este producto en esta comuna</div> 
                            </UncontrolledTooltip> 
                            : null }
                            {classShipping === "product-without-shipping" ? <UncontrolledTooltip placement='top' boundariesElement='window' className="retailer-header-tooltip" delay={0} target={'tooltip-' + product.id} >
                            {filter === 'active-cost' ? <div className="cost-without-shipping-tooltip">No logramos conseguir el costo de Shipping de este competidor, pero lo podrás verificar en nuestra siguiente extracción de datos</div> 
                            : <div className="shipment-without-shipping-tooltip">No logramos conseguir el tiempo de Shipping de este competidor, pero lo podrás verificar en nuestra siguiente extracción de datos</div>}
                                 </UncontrolledTooltip > : null}
                        </button>
                );

    return (
        <span className={`message-button-container-shipping`}>
            {button}
        </span>
    );
};

ShippingButton.propTypes = {
    product: PropTypes.object,
    id: PropTypes.string,
    iconName: PropTypes.string,
    placement: PropTypes.string,
    trigger: PropTypes.string,
    storeId: PropTypes.number,
    className: PropTypes.string,
    classShipping: PropTypes.string,
    filter: PropTypes.string
};

export { ShippingButton };