import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, remove, orderBy, maxBy } from 'lodash';
import CloseIcon from 'mdi-react/CloseIcon';
import RightIcon from 'mdi-react/ChevronRightIcon';

const CounterSummaryPropType = {
    value: PropTypes.number.isRequired,
    legend: PropTypes.string.isRequired,
    isLocked: PropTypes.bool,
    url: PropTypes.string,
    onClick: PropTypes.func,
};

const CounterItemPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    filteredValue: PropTypes.number,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired
});

export default class InteractiveBarCounter extends PureComponent {
    static propTypes = {
        counterItems: PropTypes.arrayOf(CounterItemPropType).isRequired,
        summary1: PropTypes.shape(CounterSummaryPropType),
        summary2: PropTypes.shape(CounterSummaryPropType),
        title: PropTypes.string,
        summaryTitle: PropTypes.string,
        barsTitle: PropTypes.string,
        maxBars: PropTypes.number.isRequired,
        myItemsIds: PropTypes.array.isRequired,
        selectedItemsIds: PropTypes.array.isRequired,
        total: PropTypes.number.isRequired,
        othersLabel: PropTypes.string,
        onOthersClick: PropTypes.func,
        onOthersRemoveClick: PropTypes.func,
        showProportions: PropTypes.bool,
        onRemoveAllClick: PropTypes.func,
        removeAllText: PropTypes.string
    };

    static defaultProps = {
        othersLabel: "Others",
        onOthersClick: (array) => { },
        onOthersRemoveClick: (array) => { },
        CounterItemPropType: {
            filteredValue: 0
        },
        CounterSummaryPropType: {
            isLocked: false
        }
    };

    constructor(props) {
        super(props);
        this.getLegend = this.getLegend.bind(this);
        this.truncateOthers = this.truncateOthers.bind(this);
    }


    getLegend(summary) {
        if (!summary.legend) {
            return (<div className="legend"></div>);
        }
        return (<div className="legend">
            {summary.url ?
                <a href={summary.url}>
                    {summary.legend}<RightIcon />
                </a> :
                summary.legend
            }
        </div>);
    }

    isActive(id) {
        return this.props.selectedItemsIds.indexOf(id) !== -1;
    };

    truncateOthers() {
        let items = this.props.counterItems;
        let myIds = this.props.myItemsIds;
        let selectedIds = this.props.selectedItemsIds;
        let myItems = remove(items, function (item) {
            if (myIds.indexOf(item.id) !== -1) {
                item.isMine = true;
                return true;
            }
            return false;
        });
        items = orderBy(items, ["value"], ["desc"]);
        if (this.props.maxBars >= items.length + myItems.length) {
            items = items.concat(myItems);
            return orderBy(items, ["value"], ["desc"]);
        }
        let bars = items.splice(0, this.props.maxBars - myItems.length - 1);
        var othersObj = { id: 0, value: 0, filteredValue: 0, label: this.props.othersLabel, itemsIds: [], isActive: false };
        items.forEach(function (item) {
            othersObj.value += item.value;
            othersObj.filteredValue += item.filteredValue;
            othersObj.itemsIds.push(item.id);
            othersObj.isActive = othersObj.isActive ? othersObj.isActive : selectedIds.indexOf(item.id) !== -1
        });
        var onOthersClick = this.props.onOthersClick;
        var onOthersRemoveClick = this.props.onOthersRemoveClick;
        othersObj.onClick = function () { onOthersClick(items) }
        othersObj.onRemoveClick = function () { onOthersRemoveClick(items) }
        bars = orderBy(bars.concat(myItems), ["value", "isMine"], ["desc", "asc"]);
        bars.push(othersObj);
        return bars;
    }


    render() {
        const getHeight = (counter, total) => {
            if (isNaN(total) || total === 0) return 0;
            return ((counter * 100) / total).toFixed(0);
        }

        const isActive = (id) => {
            return this.props.selectedItemsIds.indexOf(id) !== -1;
        };
        const { showProportions } = this.props;
        const barElements = this.truncateOthers();
        let total = maxBy(barElements, 'value');
        let anySelected = this.props.selectedItemsIds.length > 0;
        total = total ? total.value : 0;
        return (
            <div className={"interactive-barcounter" + (anySelected ? " barcounter--filtered" : "")}>
                {
                    this.props.title ?
                        <div className="barcounter-title">{this.props.title}</div> :
                        null
                }
                <div className="barcounter-section-titles clearfix">
                    <div className="barcounter-summary-title">
                        {this.props.summaryTitle ? this.props.summaryTitle : null}
                    </div>
                    <div className="barcounter-bars-title">
                        {this.props.barsTitle ? this.props.barsTitle : null}
                    </div>
                </div>
                <div className="barcounter-summary-container">
                    <div className={"barcounter-summary" + (this.props.summary1.isLocked ? " summary--locked" : "")}
                        onClick={this.props.summary1.onClick ? this.props.summary1.onClick : () => { }} >
                        <div className="count">
                            {this.props.summary1.value}
                        </div>
                        {this.getLegend(this.props.summary1)}
                    </div>
                    <div className={"barcounter-summary" + (this.props.summary2.isLocked ? " summary--locked" : "")}
                        onClick={this.props.summary2.onClick ? this.props.summary2.onClick : () => { }} >
                        <div className="count">
                            {this.props.summary2.value}
                        </div>
                        {this.getLegend(this.props.summary2)}
                    </div>
                </div>
                <div className="barcounter-bars-container clearfix">
                    <div className="barcounter-filter-container">
                        {
                            this.props.onRemoveAllClick && anySelected ?
                                <div className="barcounter-remove-all" onClick={this.props.onRemoveAllClick}>
                                    <CloseIcon size={18} color={"#999999"} />{this.props.removeAllText}
                                </div> : null
                        }
                        {
                            map(barElements, function (item) {
                                let barStyle = {
                                    height: getHeight(item.value, total) + "%"
                                }
                                let innerBarStyle = {
                                    "height": getHeight(item.filteredValue, item.value) + "%",
                                    "transition": "height 1s"
                                }
                                item.isActive = item.isActive ? item.isActive : isActive(item.id);
                                return (
                                    <div className={"barcounter-bar-layout" + (item.isActive ? " barcounter--active" : "")} key={"bar-" + item.id}
                                        onClick={!item.isActive ? (e) => { item.onClick(item); } : (e) => { item.onRemoveClick(item); }}>
                                        <div className="title">{item.label}</div>
                                        <div className="bar-container">
                                            <div className={item.isMine ? "bar bar--mine" : "bar"} style={barStyle}>
                                                <div className="filtered-bar" style={innerBarStyle}></div>
                                            </div>
                                        </div>
                                        {showProportions ? <div className="legend"><span>{item.filteredValue}/</span>{item.value}</div> : <div className="legend">{item.value}</div>}
                                        <div className="disable-container">
                                            <div className="disable-bar">
                                                <div className="disable-btn">
                                                    <CloseIcon size={18} color={"#999999"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
