
/*
    Polyfill for path and composedPath
    https://developer.mozilla.org/en-US/docs/Web/API/Event/composedPath
 */
export const alternativePath = (el) => {
    let path = [];
    while (el) {
        path.push(el);
        if (el.tagName === 'HTML') {
            path.push(document);
            path.push(window);
            return path;
        }
        el = el.parentElement;
    }
};

export const isEdge = () => window.navigator.userAgent.indexOf("Edge") > -1;