import React, { PureComponent } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { range } from "lodash";
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { Utils } from '@common/utils';

const mainIdText = "pagination-";



export default class PaginationHelper extends PureComponent {

  static propTypes = {
    onChangePage: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    allowSetCurrentPage: PropTypes.bool
  };

  static defaultProps = {
    allowSetCurrentPage: false
  }

  constructor(props) {
    super(props);
    this.state = { pager: {}, initialPage: 1, size: this.props.pageSize, randomId: Math.random().toString(13).replace('0.', '') };
    this.setPage = this.setPage.bind(this);
    this.getPager = this.getPager.bind(this);
    this.upperOffset = 60;
  }

  UNSAFE_componentWillMount() {
    // set page if items array isn't empty
    let initialPage = this.state.initialPage;
    if (this.props.allowSetCurrentPage && this.props.currentPage > initialPage) {
      initialPage = this.props.currentPage;
    }
    this.setPage(initialPage);
  }

  componentDidMount() {
    this.upperOffset = Utils.getUpperOffset() + 10;
  }


  valueChanged(currentCount, nextCount) {
    if (currentCount !== nextCount) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.valueChanged(this.props.count, nextProps.count)) {
      this._setPage(this.pager.currentPage, nextProps.count, true);
    } else if (this.valueChanged(this.props.currentPage, nextProps.currentPage)) {
      this._setPage(nextProps.currentPage, nextProps.count, true);
    }
  }

  setPage(page) {
    this._setPage(page, this.props.count, false);
    const paginationHTML = document.getElementById(mainIdText + this.state.randomId);
    if (paginationHTML) {     
      window.scrollTo(0,0);
    }
  }

  _setPage(page, count, forceReload) {
    const pager = this.state.pager;
    const size = this.state.size;

    if (!forceReload) {
      if (page < 1 || page > pager.totalPages) {
        return;
      }
    }

    // get new pager object for specified page
    this.pager = this.getPager(count, page, size);

    // update state
    this.setState({ pager: this.pager });

    // call change page function in parent component
    this.props.onChangePage(this.pager);
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  render() {
    const pager = this.pager;

    return (
      <div className='pagination__wrap' id={mainIdText + this.state.randomId}>
        {(!pager.pages || pager.pages.length <= 1) ? '' :
          <Pagination className="pagination">
            {pager.currentPage !== 1 ?
              <PaginationItem className="pagination__item">
                <PaginationLink
                  className="pagination__link pagination__link--arrow"
                  type="button"
                  onClick={() => this.setPage(pager.currentPage - 1)}
                >
                  <ChevronLeftIcon className="pagination__link-icon" />
                </PaginationLink>
              </PaginationItem> : <div className="pagination__dummy"></div>}
            {pager.pages.map((page, index) =>
              (<PaginationItem className="pagination__item" key={index} active={pager.currentPage === page}>
                <PaginationLink className="pagination__link" type="button" onClick={() => this.setPage(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>))}
            {pager.currentPage !== pager.totalPages ?
              <PaginationItem className="pagination__item">
                <PaginationLink
                  className="pagination__link pagination__link--arrow"
                  type="button"
                  onClick={() => this.setPage(pager.currentPage + 1)}
                >
                  <ChevronRightIcon className="pagination__link-icon" />
                </PaginationLink>
              </PaginationItem> : null}
          </Pagination>
        }
      </div>
    );
  }
}
