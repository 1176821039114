import React, { memo } from 'react';
import { translate } from 'react-i18next';
import {
    TableView as TableViewGeneric
} from '@common/components';
import {TableStateUtils} from '@common/utils';

export const onBeforeRender = (props) => {
    const element = document.querySelector('.custom-table-header-column-sortable.header-price');
    if (element) {
        element.style.visibility = TableStateUtils.shouldShowProductSelectionSummary(props) ? 'hidden' : 'visible';
    }
}

export const TableView = (props) => {
    let newProps = { ...props };
    newProps.onBeforeRender = () => onBeforeRender(props);
    return <TableViewGeneric {...newProps} />
}

export default memo((translate('common')(TableView)));