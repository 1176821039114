import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, some, includes } from 'lodash'
import {
    Utils,
    TrackJS,
    RouterUtils,
    RetailCompassStorage,
    ReduxFormsUtils,
    AppConfigActions,
    SubRouterState,
    ChurnZero
} from "@common/utils";

export const withRouterInterceptor = (WrappedComponent) => {
    class WrappedComponentWithRouterInterceptor extends PureComponent {

        static propTypes = {
            changeCurrentCanonicalCategory: PropTypes.func.isRequired,
            currentCanonicalCategory: PropTypes.object,
            changeSelectedView: PropTypes.func
        }

        constructor(props) {
            super(props);
            this.state = { componentUniqueKey: Utils.generateUniqueID() };
        }

        componentDidMount() {
            this.processRoute();
        }

        componentDidUpdate(prevProps) {
            let prevMatchParams = this.extractMatchParamsOrEmpty(prevProps.match);
            let nextMatchParams = this.extractMatchParamsOrEmpty(this.props.match);

            let categoryChanged = prevMatchParams.canonicalCategoryKey && nextMatchParams.canonicalCategoryKey &&
                prevMatchParams.canonicalCategoryKey !== nextMatchParams.canonicalCategoryKey;

            let currentViewChanged = prevMatchParams.currentView && nextMatchParams.currentView &&
                prevMatchParams.currentView !== nextMatchParams.currentView;

            if (categoryChanged || currentViewChanged) {
                this.processRoute();
                this.trackUrl()
            }
        }

        extractMatchParamsOrEmpty(match) {
            if (match && match.params) {
                return match.params;
            }
            return {};
        }

        processRoute() {
            const {
                changeCurrentCanonicalCategory,
                changeCurrentRouterView,
                changeSelectedView,
                match, history: { push }
            } = this.props;
            let matchParams = this.extractMatchParamsOrEmpty(match);

            if (match.path === RouterUtils.MODULES.BRAND || match.path === RouterUtils.MODULES.RETAIL) {
                // if the path is /retail or /brand, the user will be redirect to 
                // /(retail|brand)/categoryKey/defaultView
                let currentCanonicalCategory = RetailCompassStorage.getDefaultCanonicalCategory();
                ReduxFormsUtils.restoreState(this.props.dispatch, {});
                changeCurrentCanonicalCategory(currentCanonicalCategory);
                changeCurrentRouterView(matchParams.currentView);
                changeSelectedView && changeSelectedView();
                TrackJS.pageview();

                push(
                    RouterUtils.buildCategoryPartMenuPath(currentCanonicalCategory.key, RetailCompassStorage.isStoreTypeBrand())
                );
            } else if (matchParams.canonicalCategoryKey) {
                // if the path is /module/categoryKey/view and the categoryKey exists in the context of the user
                // we dispatch actions
                let canonicalCategory = this.getCategory(matchParams.canonicalCategoryKey);
                if (canonicalCategory) {
                    ReduxFormsUtils.restoreState(this.props.dispatch, {});
                    changeCurrentCanonicalCategory(canonicalCategory);
                    changeSelectedView && changeSelectedView();
                    if (some(RouterUtils.MODULES_VIEWS_LIST, (el) => includes(matchParams.currentView, el))) {
                        changeCurrentRouterView(matchParams.currentView);
                        TrackJS.pageview();
                    } else {
                        // we should redirect to page 404 because the view doesn't exist
                    }
                } else {
                    // we should redirect to page 404 because the category doesn't exist
                }
            }
        }

        getCategory(canonicalCategoryKey) {
            let canonicalCategories = RetailCompassStorage.getCanonicalCategories() || [];
            if (canonicalCategories.length > 0) {
                return find(canonicalCategories, { key: canonicalCategoryKey });
            }
            return null;
        }

        trackUrl() {
            const category = RetailCompassStorage.getCurrentCanonicalCategory()
            ChurnZero.trackEvent({
                eventName: "scalibur_pagina_visitada",
                customFields: {
                    categoria: category.name,
                    url: window.location.pathname
                }
            });
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapStateToProps = (state, ownProps) => {
        return { currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory };
    };
    const mapDispatchToProps = (dispatch, ownProps) => {
        return {
            dispatch,
            changeCurrentCanonicalCategory: (canonicalCategory) => {
                dispatch(AppConfigActions.changeCurrentCanonicalCategory(canonicalCategory));
            },
            changeCurrentRouterView: (view) => {
                dispatch(AppConfigActions.changeCurrentRouterView(view));
            },
            changeSelectedView: () => {
                SubRouterState.resetSelectedView(dispatch);
            }
        };
    };
    return connect(mapStateToProps, mapDispatchToProps)(WrappedComponentWithRouterInterceptor)
}

