/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class productListService.js
 * @date 10 oct. 2018
 */


import { postAsJson, validateParams, postAsDownload, getAsJson } from './AbstractService';

import { ConstantesApi } from '@common/utils';

/**
 * Doc: https://pricingcompass.atlassian.net/wiki/spaces/DOCS/pages/665878598/Categor+as+Canonica
 */
export const fetchProductItems = (params) => {
    validateParams(params);
    return postAsJson({ api: ConstantesApi.API_ES_PRODUCT_SEARCH, ...params });
};

export const fetchReportInfo = (params) => {
    validateParams(params);
    return postAsJson({ api: ConstantesApi.API_ES_PRODUCT_SEARCH, ...params });
}

export const fetchDownload = (params) => {
    validateParams(params);
    return postAsDownload({ api: ConstantesApi.API_ES_PRODUCT_SEARCH_EXPORT, ...params }, 'application/vnd.ms-excel');
};

export const fetchPotentialMatches = (params) => {
    validateParams(params);
    return postAsJson({ api: ConstantesApi.API_ES_PRODUCT_SEARCH_POTENTIAL_MATCHES, ...params });
};

export const fetchStarCategories = params => {
    validateParams(params);
    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_STAR_CATEGORIES,
        ...params
    });
};

export const fetchStarCategoriesFullPath = params => {
    validateParams(params);
    // const ids = params.data.map(category => `&categoriesIds=${category}`); 
    // const list = ids.join('');
    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_STAR_CATEGORIES_FULL_PATH,
        ...params
    });
};

export const fetchUpdateProducts = (params) => {
    validateParams(params);
    return postAsJson({
        api: ConstantesApi.API_ES_FEED_PRODUCTS,
        ...params
    });
};