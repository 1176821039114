import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

export default function RankingNotification({ activeRanking }) {
    const [hide, setHide] = useState(false);
    const [counter, setCounter] = useState(0);
    const TIMEOUT = 15 * 1000;
    const MAX_COUNT = 2;

    const handleClose = useCallback(() => {
        incrementCounter();
        setHide(true);
    }, []);

    useEffect(() => {
        setHide(!activeRanking);
        let timer;
        if (activeRanking) {
            timer = setTimeout(handleClose, TIMEOUT);
        }
        return () => clearTimeout(timer);
    }, [TIMEOUT, activeRanking, handleClose]);

    const incrementCounter = () => setCounter(current => current + 1);

    if (!activeRanking || hide || counter >= MAX_COUNT) return null;

    return (
        <div className="ranking-notification">
            <CloseIcon
                className="ranking-notification__close"
                onClick={handleClose}
            />
            <span className="ranking-notification__title">
                ¡Posicionamiento activo!
            </span>
            <span className="ranking-notification__desc">
                Ahora verás el listado con el dato de la posición de tus
                productos y sus matches en el listado de cada web
            </span>
        </div>
    );
}
