import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Config } from "@common/auth/";
import { RetailCompassStorage } from "@common/utils";

export default function useLogout() {
    const { logout } = useAuth0();
    const _logout = () => {
        RetailCompassStorage.logout();
        logout({ returnTo: Auth0Config.REDIRECT_URL });
    };
    return { logout: _logout };
}
