const actionsFilter = {
    UPDATE_MIN_PRICE: "UPDATE_MIN_PRICE",
    UPDATE_COLORS: "UPDATE_COLORS",
    UPDATE_OPERATORS: "UPDATE_OPERATORS",
}

const initialFilters = {
    minPrice: 0,
    colors: [],
    postpaidOperators: []
}

const FilterReducer = (state, action) => {

    switch (action.type) {
        case actionsFilter.UPDATE_MIN_PRICE:
            return {
                ...state,
                minPrice: action.payload
            }
        case actionsFilter.UPDATE_COLORS:
            return {
                ...state,
                colors: action.payload
            }
        case actionsFilter.UPDATE_OPERATORS:
            return {
                ...state,
                postpaidOperators: action.postpaidOperators
            }
        default:
            return state;
    }
}

export { initialFilters, actionsFilter }
export default FilterReducer
