import React, { Component } from 'react';
import ProductPanel from './ProductPanel';
import { PropTypes } from "prop-types";
import IdenticalMatchesTable from '../../common/components/IdenticalMatchesTable';
import { getNameToShow } from '../../common/utils/utils'


export class CandidatePanel extends Component {

    static propTypes = {
        currentProduct: PropTypes.object.isRequired,
        urlParams: PropTypes.object.isRequired,
        removeIdentical: PropTypes.func,
        allMatches: PropTypes.array,
        isRemovingTheMatch: PropTypes.bool,
        taskTitle: PropTypes.string,

        attributesTitle: PropTypes.string,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        showMarker: PropTypes.bool,
        matchesActions: PropTypes.func,
        showImageComparer: PropTypes.bool,
        showUserName: PropTypes.bool,
        showRetailerButton: PropTypes.bool,
        isTransformingTheMatch: PropTypes.bool,
    };

    static defaultProps = {
        attributesTitle: "Atributos sugeridos",
        title: "Producto por aprobar",
        showMarker: false,
        showImageComparer: true,
        showUserName: true

    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    advanceAction() {
    }

    render() {
        let { currentProduct, urlParams } = this.props;
        return (
            <div>
                <ProductPanel
                    product={currentProduct}
                    title={this.props.title}
                    subtitle={this.props.taskTitle}
                    attributesTitle={this.props.attributesTitle}
                    nameToShow={getNameToShow(urlParams.contextInfo)}
                    showRetailerButton={true}
                    showMarker={this.props.showMarker}
                    showUserName={this.props.showUserName}
                />
                <IdenticalMatchesTable
                    currentProduct={currentProduct}
                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                    removeIdentical={this.props.removeIdentical}
                    allMatches={this.props.allMatches}
                    showImageComparer={true}
                    comparerLeftTitle={"Producto por aprobar"}
                    comparerThumbnailMainTitle={"Por aprobar"}
                    customActions={this.props.matchesActions || null}
                    showImageComparer={this.props.showImageComparer}
                    showRetailerButton={this.props.showRetailerButton}
                />
            </div>
        )
    }
}
export default CandidatePanel;