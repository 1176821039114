import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, maxBy} from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';
import { Utils } from '@common/utils';

const CounterItemPropType = PropTypes.shape({ 
    id: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string.isRequired,    
    onClick: PropTypes.func,
    color: PropTypes.string,
    tooltipContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    showValueOnTop: PropTypes.bool,
    customTotal: PropTypes.number,
    minColumnHeight: PropTypes.string      
});

export default class QuickColumnChart extends PureComponent {
     static propTypes = {
        counterItems: PropTypes.arrayOf(CounterItemPropType).isRequired,
        maxColumnHeight: PropTypes.string,
        showPointerCursor: PropTypes.bool
    };

    static defaultProps = {
       maxColumnHeight: "100px",
       minColumnHeight: "5px",
       showPointerCursor: false
    };

    constructor(props) {
        super(props);
    }

    render() {
        const  getHeight = (counter, total)=>{
            if(this.props.customTotal){
                total = this.props.customTotal;
            }
            if(isNaN(total) || total === 0) return 0;
            return ((counter*100)/total).toFixed(0);
        }
        const barElements = this.props.counterItems;
        let total = maxBy(barElements, 'value');
        total = total ? total.value : 0;
        const chartId = Utils.generateRandomId();
        return(
            <div className="column-chart clearfix" key={"quickchart-"+chartId}>       
                    {
                        map(barElements, (item, inx)=>{
                            item.id = item.id || inx;
                            let minHeight = item.value > 0 ? this.props.minColumnHeight : "unset";
                            let barStyle = {
                                height: getHeight(item.value, total)+"%",
                                backgroundColor: item.color || "#f39c12",
                                minHeight: minHeight               
                            }
                            let layoutStyle = {
                                cursor: item.onClick || this.props.showPointerCursor ? "pointer" : "default"                                
                            }
                            return(
                            <div className="column-layout" key={["chart-item",chartId,item.id].join("-")} style={layoutStyle} onClick={item.onClick}>
                                {item.showValueOnTop ? <div className="title">{item.value}</div>:null}
                                <div className="column-container" style={{height: this.props.maxColumnHeight}}>                                
                                    <div className="column" style={barStyle} id={["chart-column",chartId,item.id].join("-")} onClick={(e) => { e.stopPropagation() }}></div>
                                    {item.tooltipContent ?
                                        <UncontrolledTooltip placement='top' delay={50} target={["chart-column",chartId,item.id].join("-")}>
                                            {item.tooltipContent}
                                        </UncontrolledTooltip>: null}                                    
                                </div>
                                <div className="legend">{item.label}</div>
                            </div> 
                            )
                        })
                    }
                </div>
        );
    }
}
