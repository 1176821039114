import { UserType } from '../utils/constantes';

export const ACCEPTED_USERS = [UserType.CLIENT, UserType.CLIENT_ADMIN]

export const EVENTS = {
    USED_PRICE_COMPARATOR: "Used price comparator",
    USED_OUT_OF_STOCK_FILTER: "Used filter (out of stock)",
    USED_PRICE_RANGE_FILTER: "Used filter (price range)",
    USED_RETAILER_FILTER: "Used filter (retailer)",
    USED_BRAND_FILTER: "Used filter (brand)",
    USED_FAVORITE_FILTER: "Used filter (favorite)",
    USED_LABEL_FILTER: "Used filter (label)",
    USED_MKP_FILTER: "Used filter (MKP)",
    USED_DYNAMIC_FILTER: "Used filter ({})",
    CREATED_LABEL: "Created label",
    DOWNLOADED_EXCEL: "Downloaded excel",
    CREATED_ALERT: "Created alert",
    VIEWED_PRODUCT_DETAIL: "Viewed product detail",
    VIEWED_OUT_OF_STOCK_REPORT: "Viewed report (out of stock)",
    VIEWED_COMPETITIVENESS_REPORT: "Viewed report (competitiveness)",
    VIEWED_COMPETITIVENESS_RETAIL_SUBREPORT: "Viewed subreport (competitiveness > retail)",
    VIEWED_COMPETITIVENESS_BRAND_SUBREPORT: "Viewed subreport (competitiveness > brand)",
    VIEWED_COMPETITIVENESS_LOWER_PRICE_SUBREPORT: "Viewed subreport (competitiveness > lower price)",
    VIEWED_DISCOUNTS_REPORT: "Viewed report (discounts)",
    VIEWED_DISCOUNTS_ALL_PRICES_SUBREPORT: "Viewed subreport (discounts > all offers)",
    VIEWED_DISCOUNTS_CARD_PRICES_SUBREPORT: "Viewed subreport (discounts > card offers)",
    VIEWED_DISCOUNTS_SALE_PRICES_SUBREPORT: "Viewed subreport (discounts > offers without card)"
};
