import { connect } from 'react-redux';
import LabelsForm from './LabelsForm';
import { fetchCreate, patchLabel, resetProductsLabel } from '../LabelsState';
import {  Utils } from '@common/utils/';
import { hideLabelsForm } from '../label-new-button/ButtonNewLabelActions';

export const mapStateToProps = (state, ownProps) => {
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    return {
        totalSelectedProducts: state.labelStateReducer.newLabel.selectedProducts.length,
        selectedProducts: state.labelStateReducer.newLabel.selectedProducts,
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory,
        productItems: state.productListBrandReducer.productItems || [],
        isCreating: state.labelStateReducer.isCreating,
        isCreated: state.labelStateReducer.isCreated,
        hasError: state.labelStateReducer.hasError,
        currentFilter,
        errorMessage: state.labelStateReducer.error,
        hidePublicLabels: state.labelStateReducer.hidePublicLabels
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        createLabel: (params) => fetchCreate(dispatch, params),
        patchLabel: (params) => patchLabel(dispatch, params),
        hideLabelsForm: () => dispatch(hideLabelsForm()),
        resetSelection: () => resetProductsLabel(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelsForm);