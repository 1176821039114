import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { isEqual, cloneDeep, get, uniqWith } from "lodash";
import moment from "moment";
import {
    Utils,
    Constantes,
    ComparerUtils,
    RetailCompassStorage
} from "@common/utils/";
import { HistoricDatedChart } from "@common/components";
import * as HistoricUtils from "@common/components/src/Historic/historicUtils";

const {
    extractStoreProductMap,
    getLowerPrice,
    buildComparerChartSeries,
    tooltipComparerFormatter
} = HistoricUtils;

const RIGHT_SUMMARY_TITLE_CONFIG = {
    [Constantes.COMPARER_TYPE.MIN_PRICE]: "Menor precio histórico",
    [Constantes.COMPARER_TYPE.AVG_PRICE]: "Menor precio medio histórico",
    [Constantes.COMPARER_TYPE.RETAILERS]: "Menor precio histórico"
};

export default class HistoricView extends Component {
    static propTypes = {
        products: PropTypes.array,
        className: PropTypes.string,
        fetchHistoricData: PropTypes.func.isRequired,
        lastFilter: PropTypes.object,
        historicData: PropTypes.object,
        lowerPriceFromMaxHistoricPeriodSelectedStores: PropTypes.object,
        lowerPriceBetweenSelectedDatesSelectedStores: PropTypes.object,
        lowerPriceFromMaxHistoricPeriodAllStores: PropTypes.object,
        lowerPriceBetweenSelectedDatesAllStores: PropTypes.object,
        stores: PropTypes.array,
        fetchLowerPriceFromMaxHistoricPeriodYourProduct:
            PropTypes.func.isRequired,
        lowerPriceFromMaxHistoricPeriodYourProduct: PropTypes.object,

        lowerPriceBetweenSelectedDatesYourProduct: PropTypes.object,

        fetchLowerPriceBetweenSelectedDatesYourProduct:
            PropTypes.func.isRequired,
        fetchLowerPriceFromMaxHistoricPeriodAllStores:
            PropTypes.func.isRequired,
        fetchLowerPriceBetweenSelectedDatesAllStores: PropTypes.func.isRequired,
        fetchLowerPriceFromMaxHistoricPeriodSelectedStores:
            PropTypes.func.isRequired,
        fetchLowerPriceBetweenSelectedDatesSelectedStores:
            PropTypes.func.isRequired,
        emptyHistory: PropTypes.bool.isRequired,

        isFetchingHistoricData: PropTypes.bool,
        isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: PropTypes.bool,
        isFetchingLowerPriceBetweenSelectedDatesYourProduct: PropTypes.bool,
        isFetchingLowerPriceBetweenSelectedDatesAllStores: PropTypes.bool,
        isFetchingLowerPriceFromMaxHistoricPeriodAllStores: PropTypes.bool,
        isFetchingLowerPriceBetweenSelectedDatesSelectedStores: PropTypes.bool,
        isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: PropTypes.bool,
        configOptions: PropTypes.object,
        selectedComparer: PropTypes.object
    };

    static defaultProps = {
        isFetchingHistoricData: false,
        isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: false,
        isFetchingLowerPriceBetweenSelectedDatesYourProduct: false,
        isFetchingLowerPriceBetweenSelectedDatesAllStores: false,
        isFetchingLowerPriceFromMaxHistoricPeriodAllStores: false,
        isFetchingLowerPriceBetweenSelectedDatesSelectedStores: false,
        isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: false
    };

    constructor(props) {
        super(props);
        this.state = {
            historicChartKey: null,
            endDate: ComparerUtils.DEFAULT_DATE_RANGE.endDate,
            startDate: ComparerUtils.DEFAULT_DATE_RANGE.startDate,
            includeCardPrices: true,
            comparerData: []
        };

        this.storeProductMap = {};

        this.getHistoricData = this.getHistoricData.bind(this);
        this.extractPropsFromLowerPriceFromMaxHistoricPeriodSelectedStores = this.extractPropsFromLowerPriceFromMaxHistoricPeriodSelectedStores.bind(
            this
        );
        this.extractPropsFromLowerPriceBetweenSelectedDatesSelectedStores = this.extractPropsFromLowerPriceBetweenSelectedDatesSelectedStores.bind(
            this
        );

        this.onRangeSelected = this.onRangeSelected.bind(this);
        this.onSwitchChange = this.onSwitchChange.bind(this);
        this.setComparerData = this.setComparerData.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
        this.onChangeViewPrice = this.onChangeViewPrice.bind(this);
    }

    componentDidMount() {
        this.setState({ viewPrices: RetailCompassStorage.getPriceView() });
    }

    shouldComponentUpdate(nextProps) {
        const viewPrices = RetailCompassStorage.getPriceView();
        if (!isEqual(this.state.viewPrices, viewPrices)) {
            this.onChangeViewPrice({ value: viewPrices });
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(this.props.products, prevProps.products)) {
            this.getHistoricData();
            this.props.products.forEach(currentProduct => {
                this.storeProductMap = {
                    ...this.storeProductMap,
                    ...extractStoreProductMap({
                        stores: this.props.stores,
                        canonicalProduct: currentProduct.original
                    })
                };
            });
        }

        const viewPricesChanged = !isEqual(
            prevState.viewPrices,
            this.state.viewPrices
        );

        const historicChanged =
            !isEqual(this.props.historicData, prevProps.historicData) &&
            Utils.isArrayWithData(get(this.props.historicData, "history"));

        const prevView = get(prevProps.selectedComparer, "view", undefined);
        const nextView = get(this.props.selectedComparer, "view", undefined);
        const selectedComparerChanged =
            Utils.hasValue(prevView) &&
            Utils.hasValue(nextView) &&
            !isEqual(this.props.selectedComparer, prevProps.selectedComparer);

        if (selectedComparerChanged || historicChanged || viewPricesChanged) {
            this.setComparerData();
        }
    }

    setComparerData() {
        this.setState({ historicChartKey: Utils.generateUniqueID() });

        let history = get(this.props.historicData, "history", []);

        let startDate = this.state.startDate;
        let dates = history.map(item => item.date);
        dates = [startDate.format()].concat(dates);
        const today = ComparerUtils.DEFAULT_DATE_RANGE.endDate.format();
        dates.push(today);

        dates = uniqWith(dates, (val1, val2) =>
            moment(val1).isSame(val2, "day")
        );

        const comparerData = ComparerUtils.getComparerData(
            this.props.products,
            history,
            ComparerUtils.orderStores(this.props.stores),
            this.props.selectedComparer,
            dates
        );
        this.setState({ comparerData });
    }

    getHistoricData(chartFilters = { ...this.state }) {
        this.setState({ historicChartKey: null });

        this.props.changeSelectedDateRange &&
            this.props.changeSelectedDateRange({
                startDate: chartFilters.startDate.format(),
                endDate: chartFilters.endDate.format()
            });
        this.props.changeIncludeCardPrices &&
            this.props.changeIncludeCardPrices(chartFilters.includeCardPrices);

        const { products } = this.props;

        if (!Utils.isArrayWithData(products)) {
            return null;
        }

        const allProductIdItems = ComparerUtils.getProductIdsForHistoric(
            this.props.products,
            this.props.lastFilter
        );
        if (Utils.isArrayWithData(allProductIdItems)) {
            this.props.fetchHistoricData({
                productIds: allProductIdItems,
                from: chartFilters.startDate.format(),
                to: chartFilters.endDate.format(),
                includeCardPrices: chartFilters.includeCardPrices,
                disableAbortController: true
            });
        }
    }

    extractPropsFromLowerPriceFromMaxHistoricPeriodSelectedStores() {
        return getLowerPrice(
            this.props.isFetchingLowerPriceFromMaxHistoricPeriodYourProduct,
            this.props.isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores,
            this.props.lowerPriceFromMaxHistoricPeriodSelectedStores,
            this.props.lowerPriceFromMaxHistoricPeriodYourProduct,
            this.storeProductMap,
            this.state.includeCardPrices,
            this.props.countryCode
        );
    }

    extractPropsFromLowerPriceBetweenSelectedDatesSelectedStores() {
        return getLowerPrice(
            this.props.isFetchingLowerPriceBetweenSelectedDatesYourProduct,
            this.props.isFetchingLowerPriceBetweenSelectedDatesSelectedStores,
            this.props.lowerPriceBetweenSelectedDatesSelectedStores,
            this.props.lowerPriceBetweenSelectedDatesYourProduct,
            this.storeProductMap,
            this.state.includeCardPrices,
            this.props.countryCode
        );
    }

    extractPropsFromLowerPriceFromMaxHistoricPeriodAllStores() {
        let historic = getLowerPrice(
            this.props.isFetchingLowerPriceFromMaxHistoricPeriodYourProduct,
            this.props.isFetchingLowerPriceFromMaxHistoricPeriodAllStores,
            this.props.lowerPriceFromMaxHistoricPeriodAllStores,
            this.props.lowerPriceFromMaxHistoricPeriodYourProduct,
            this.storeProductMap,
            this.state.includeCardPrices,
            this.props.countryCode
        );
        // el contador mostrará el modelo en lugar del tradicional nombre de la tienda
        return { ...historic, storeName: historic.model };
    }

    extractPropsFromLowerPriceBetweenSelectedDatesAllStores() {
        let historic = getLowerPrice(
            this.props.isFetchingLowerPriceBetweenSelectedDatesYourProduct,
            this.props.isFetchingLowerPriceBetweenSelectedDatesAllStores,
            this.props.lowerPriceBetweenSelectedDatesAllStores,
            this.props.lowerPriceBetweenSelectedDatesYourProduct,
            this.storeProductMap,
            this.state.includeCardPrices,
            this.props.countryCode
        );
        // el contador mostrará el modelo en lugar del tradicional nombre de la tienda
        return { ...historic, storeName: historic.model };
    }

    onRangeSelected({ start, end }) {
        this.props.changeFilterCallback &&
            this.props.changeFilterCallback({
                start,
                end,
                includeCardPrices: this.state.includeCardPrices,
                type: Constantes.COMPARER_CALLBACK_TYPE.RANGE
            });
        this.setState({
            startDate: start,
            endDate: end
        });
        this.getHistoricData({
            ...this.state,
            startDate: start,
            endDate: end
        });
    }

    onSwitchChange(includeCardPrices) {
        if (includeCardPrices !== this.state.includeCardPrices) {
            this.setState({ includeCardPrices: includeCardPrices }, () => {
                this.props.changeFilterCallback &&
                    this.props.changeFilterCallback({
                        includeCardPrices,
                        start: this.state.startDate,
                        end: this.state.endDate,
                        type:
                            Constantes.COMPARER_CALLBACK_TYPE
                                .INCLUDE_CARD_PRICES
                    });
                this.props.changeIncludeCardPrices &&
                    this.props.changeIncludeCardPrices(includeCardPrices);
                this.setComparerData();
            });
        }
    }

    computeTitle() {
        let selectedComparer = get(this.props, "selectedComparer", {});
        let suffix = "";

        if (
            selectedComparer.view === Constantes.COMPARER_TYPE.RETAILERS &&
            Utils.hasValue(selectedComparer.title)
        ) {
            suffix = " EN " + selectedComparer.title.toUpperCase();
        }

        return "PRECIOS HISTÓRICOS" + suffix;
    }

    onChangeViewPrice(item) {
        RetailCompassStorage.setPriceView(item.value);
        this.setState({ viewPrices: item.value });
        this.getHistoricData({ ...this.state });
    }

    render() {
        if (!Utils.isArrayWithData(this.props.products)) {
            return null;
        }

        return (
            <Card className={this.props.className || ""}>
                <CardBody>
                    <HistoricDatedChart
                        categoryEvents={Constantes.GA_CATEGORY_EVENTS.COMPARE}
                        title={this.computeTitle()}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        includeCardPrices={this.state.includeCardPrices}
                        onDateFilter={this.onRangeSelected}
                        onPriceFilter={this.onSwitchChange}
                        leftSummary={null}
                        rightSummary={null}
                        chart={{
                            series: buildComparerChartSeries(
                                cloneDeep(this.state.startDate),
                                cloneDeep(this.state.endDate),
                                cloneDeep(this.state.includeCardPrices),
                                cloneDeep(
                                    this.props.products.map(item => ({
                                        ...item.original
                                    }))
                                ),
                                cloneDeep(this.state.comparerData),
                                this.props.countryCode
                            ),
                            isFetchingHistoricData: this.props
                                .isFetchingHistoricData,
                            emptyHistory: this.props.emptyHistory,
                            key: this.state.historicChartKey,
                            tooltipFormatter: tooltipComparerFormatter,
                            tooltipClassName: "comparer"
                        }}
                        viewPricesComponent={this.props.viewPricesComponent}
                        viewPriceValue={this.state.viewPrices}
                        onChangeViewPrice={this.onChangeViewPrice}
                    />
                </CardBody>
            </Card>
        );
    }
}
