import React from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row } from 'reactstrap';
import { withRouter } from "react-router";
import { get, includes, orderBy } from "lodash";
import InteractiveCounter from "@common/components/src/ui/InteractiveCounter";
import { Utils, Constantes, RetailCompassStorage } from '@common/utils';
import * as CommonUtils from '../common/common';
import { withGenericReport } from "../common/withGenericReport";
import ShareReportTooltip from "./common/ShareReportTooltip";

const AVAILABLE_REPORTS = [
    Constantes.TYPE_REPORT_BRAND.SHARE_BY_BRAND
];

const COUNTERS_KEYS = {
    SHARE_BRAND: 'SHARE_BRAND',
    WITHOUT_RESULTS: 'WITHOUT_RESULTS'
}

const isOwnBrand = (brands, brandName) => {
    let isMyBrand = brands.allBrands.find(b => b.name === brandName);
    
    return isMyBrand !== undefined;
}

const buildCounterItem = (items, myBrands, totalGreater, currentReport, goToProductList) => {
    return items.map((entityItem, index) => {
        const total = get(entityItem, 'count', 0);
        const counterItems = buildCounterItems({ total, myBrands, entityItem });
        const maxWidth = totalGreater <= 0 ? 100 : (100 * total) / totalGreater;
        const barContainerStyles = { maxWidth: maxWidth.toFixed(2) + '%', minWidth: '10px' };
        const label = entityItem.brandName;

        return <InteractiveCounter
            key={index}
            counterItems={counterItems}
            showProgressBarZone={total > 0}
            containerClassName={(total === 0 ? 'empty' : '')}
            barContainerStyles={barContainerStyles}
            // barContainerClassName={haveIt === 0 ? 'counter-empty-data' : ''}
            showFilterZone={false}
            label={label}

            total={1}
            onContainerClick={() => {
                goToProductList({ entity: entityItem });
            }}
            onSelectItem={(item) => {
                goToProductList(item);
            }}
            onDeselectItem={(item) => {
            }}
            formatPercentage={(counterItem) => {
                if (counterItem.counterKey === COUNTERS_KEYS.WITHOUT_RESULTS
                    || counterItem.counterKey === COUNTERS_KEYS.SHARE_BRAND) {
                    if (total === 0) {
                        return 0;
                    }
                    return total;
                }
                return '';
            }}
            formatCounterLabel={(total, label) => {
                if (!total) {
                    return null;
                }
                return label;
            }}
            isReportBrand={(currentReport) => {
                return currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_BRAND ||
                currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE ||
                currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL;
            }}
            currentReport={currentReport}
            progressBarTooltipConfig={{
                show: true,
                renderContent: (item) => {
                    let totalText = item.entity.count + ' producto' + (item.entity.count > 1 ? 's' : '');
                    return <div className="progress-bar-tooltip">
                        <ShareReportTooltip containerClassName='tooltip-range' iconClass={item.barClassName} text={item.entity.brandName} />
                        <ShareReportTooltip containerClassName='tooltip-total' iconClass="total-of-products" text={totalText} />
                    </div>
                }
            }}
        />
    });
}

export const buildCounterItems = ({ total, myBrands, entityItem }) => {
    const isMyBrand = includes(myBrands, parseInt(entityItem.brandId));
    
    let counterItems = [
        {
            id: 1,
            label: '',
            total: total,
            isSelected: false,
            percentageClassName: isMyBrand ? 'counter-share-report-have-it-percentage' : 'counter-share-report-dont-have-it-percentage',
            entity: entityItem,
            counterKey: COUNTERS_KEYS.SHARE_BRAND,
            barClassName: isMyBrand ? 'counter-share-report-have-it' : 'counter-share-report-dont-have-it',
            enableCounterPercentageClick: false
        }
    ];

    // se debe mostrar nombre de retail y 0 en caso de que el usuario haya filtrado por entities
    if (!Utils.isArrayWithData(counterItems)) {
        counterItems = [{
            id: 100,
            barClassName: '',
            percentageClassName: '',
            counterKey: COUNTERS_KEYS.WITHOUT_RESULTS,
            label: '',
            isSelected: false,
            total: 0
        }];
    }

    return counterItems;
}

export const interactiveCounterBuilder = ({ entityItems, goToProductList, containerClassName, totalGreater, currentReport, collapseToogle, getCollapseIsOpen }) => {
    if (!Utils.isArrayWithData(entityItems)) {
        return null;
    }
    
    containerClassName = containerClassName || '';
    totalGreater = totalGreater || 0;
    const myBrands = RetailCompassStorage.getBrandIds();
    const myBrandNames = RetailCompassStorage.getBrands();
    
    let entityItemsRes = []
    if(entityItems.length > 10){
        let ownEntityItems =   [...entityItems.filter(e => isOwnBrand(myBrandNames, e.brandName))];
        let otherEntityItems = [...entityItems.filter(e => !isOwnBrand(myBrandNames, e.brandName))];

        let allEntityItems = [...ownEntityItems, ...otherEntityItems];
        let orderedFirstTen = orderBy(allEntityItems.slice(0, 10), ['count'], ['desc'])

        entityItemsRes.push(<Collapse timeout={0} exit={false} isOpen={!getCollapseIsOpen()} key="colapsible-brand-action-first-ten">{
            buildCounterItem(orderedFirstTen, myBrands, totalGreater, currentReport, goToProductList)
        }</Collapse>)

        entityItemsRes.push(<Collapse isOpen={getCollapseIsOpen()} key="colapsible-brand-action-last">{
            buildCounterItem(entityItems, myBrands, totalGreater, currentReport, goToProductList)
        }</Collapse>)

        entityItemsRes.push(
            <div key="colapsible-brand" className="load-more-container" >
                <div onClick={collapseToogle} className="load-more-action">
                    Ver todas las marcas 
                    { getCollapseIsOpen() ?
                    <svg width="18" height="18" viewBox="0 0 24 24" style={{fill:"#999999"}}><path d="M12 6.879L4.939 13.939 7.061 16.061 12 11.121 16.939 16.061 19.061 13.939z"></path></svg>
                    :
                    <svg width="18" height="18" viewBox="0 0 24 24" style={{fill:"#999999"}}><path d="M16.939 7.939L12 12.879 7.061 7.939 4.939 10.061 12 17.121 19.061 10.061z"></path></svg>
                    }
                    
                </div>
            </div>);
    }else{
        entityItemsRes = buildCounterItem(entityItems, myBrands, totalGreater, currentReport, goToProductList);
    }

    return [...entityItemsRes];
}



const ShareBrand = (props) => {
    if (!props.shouldRender) {
        return null;
    }
    
    return (
        <div className={'with-generic-report-container haveit-dont-haveit-container ' + (props.containerClassName || '')}>
            <Row className="title-container">
                <Col md={12} sm={12} >
                    {props.title}
                </Col>
            </Row>

            <Row className="subtitle-container">
                <Col md={12} sm={12}>
                    {props.subtitle}
                </Col>
            </Row>
            {props.interactiveCounterRender(props)}
        </div>
    );
}

ShareBrand.propTypes = {
    currentReport: PropTypes.oneOf(AVAILABLE_REPORTS),
    interactiveCounterRender: PropTypes.func.isRequired,
    goToProductList: PropTypes.func.isRequired,
    containerClassName: PropTypes.string,
}

let ShareBrandWithGenericReport = withGenericReport(ShareBrand, interactiveCounterBuilder);
export default CommonUtils.getComponentConnected(withRouter(ShareBrandWithGenericReport));
