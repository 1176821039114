import React from "react";
import { Utils } from "@common/utils/";
import { CalendarIcon } from '../CalendarIcon';

export const DaysInfo = ({ createdDate, showIcon }) => {

    function buildDaysInfo(date) {
        let n = Utils.getDaysFromToday(date);
        if (n <= 0) {
            return "Desde hoy";
        } else if (n === 1) {
            return n + " día";
        } else {
            return n + " días";
        }
    }

    return (
        <div className="days">
            {showIcon ? <span><CalendarIcon /></span> : null}
            {buildDaysInfo(createdDate)}
        </div>
    )
}