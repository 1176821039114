import React from "react";
import { Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogout } from "@common/components";

const LoginRoute = ({ ...rest }) => {
    const { error } = useAuth0();
    const { logout } = useLogout();

    if (error) {
        console.error(error);
        logout();
    }
    return (
        <PrivateRoute
            {...rest}
            component={() => <Redirect to={{ pathname: "/product_list" }} />}
        />
    );
};

export default LoginRoute;
