import { Utils } from '@common/utils';

const ACTION_BASE_NAME = Utils.composeActionName('ATTRIBUTES_TOOL');


//GET ATTRIBUTES CATEGORY
export const REQUEST_ATTRIBUTES_CATEGORY = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_ATTRIBUTES_CATEGORY');
export const RECEIVE_ATTRIBUTES_CATEGORY = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_ATTRIBUTES_CATEGORY');
export const RECEIVE_ATTRIBUTES_CATEGORY_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_ATTRIBUTES_CATEGORY_ERROR');
//SAVE ACTION
export const REQUEST_CREATE_PRODUCT_ATTRIBUTE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CREATE_PRODUCT_ATTRIBUTE');
export const RECEIVE_CREATE_PRODUCT_ATTRIBUTE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_PRODUCT_ATTRIBUTE');
export const RECEIVE_CREATE_PRODUCT_ATTRIBUTE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_PRODUCT_ATTRIBUTE_ERROR');
//GET PRODUCT ATTRIBUTES
export const REQUEST_PRODUCT_ATTRIBUTES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_PRODUCT_ATTRIBUTES');
export const RECEIVE_PRODUCT_ATTRIBUTES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_ATTRIBUTES');
export const RECEIVE_PRODUCT_ATTRIBUTES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_ATTRIBUTES_ERROR');
//GET CATEGORY INFO
export const REQUEST_CATEGORY_INFO = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CATEGORY_INFO');
export const RECEIVE_CATEGORY_INFO = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CATEGORY_INFO');
export const RECEIVE_CATEGORY_INFO_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CATEGORY_INFO_ERROR');




export const requestAttributesCategoryAction = (data) => ({
    type: REQUEST_ATTRIBUTES_CATEGORY,
    payload: { ...data }
});

export const receiveAttributesCategoryAction = (data) => ({
    type: RECEIVE_ATTRIBUTES_CATEGORY,
    payload: data
});

export const receiveAttributesCategoryErrorAction = (error) => ({
    type: RECEIVE_ATTRIBUTES_CATEGORY_ERROR,
    payload: {
        error: error
    }
});

export const requestCreateProductAttributeAction = (data) => ({
    type: REQUEST_CREATE_PRODUCT_ATTRIBUTE,
    payload: { ...data }
});

export const receiveCreateProductAttributeAction = (data) => ({
    type: RECEIVE_CREATE_PRODUCT_ATTRIBUTE,
    payload: { ...data }
});

export const receiveCreateProductAttributeErrorAction = (error) => ({
    type: RECEIVE_CREATE_PRODUCT_ATTRIBUTE_ERROR,
    payload: {
        error: error
    }
});

export const requestProductAttributesAction = (data) => ({
    type: REQUEST_PRODUCT_ATTRIBUTES,
    payload: { ...data }
});

export const receiveProductAttributesAction = (data) => ({
    type: RECEIVE_PRODUCT_ATTRIBUTES,
    payload: { ...data }
});

export const receiveProductAttributesErrorAction = (error) => ({
    type: RECEIVE_PRODUCT_ATTRIBUTES_ERROR,
    payload: {
        error: error
    }
});

export const requestCategoryInfoAction = (data) => ({
    type: REQUEST_CATEGORY_INFO,
    payload: { ...data }
});

export const receiveCategoryInfoAction = (data) => ({
    type: RECEIVE_CATEGORY_INFO,
    payload: data
});

export const receiveCategoryInfoErrorAction = (error) => ({
    type: RECEIVE_CATEGORY_INFO_ERROR,
    payload: {
        error: error
    }
});
