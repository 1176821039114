import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { } from 'lodash';
import CheckBoxField from "@common/components/src/ui/CheckBox";
import ResizeSensor from 'css-element-queries/src/ResizeSensor';

export default class ProductSelectionSummary extends Component {

    selectionSummaryClass = 'selection-summary';
    selectionSummarySelector = '.' + this.selectionSummaryClass;

    static propTypes = {
        parentSelector: PropTypes.string.isRequired,
        totalSelectedProducts: PropTypes.number,
        selectedProducts: PropTypes.array,
        productItems: PropTypes.array,
        onSelectCancellation: PropTypes.func,
        width: PropTypes.number,
        onAllSelected: PropTypes.func.isRequired,
        className: PropTypes.string,
        overlappedHeadersSelector: PropTypes.string
    };

    static defaultProps = {
        totalSelectedProducts: 1,
        className: ""
    };

    constructor(props) {
        super(props);

        this.builSelectedProductsMessage = this.builSelectedProductsMessage.bind(this);
        this.isAllItemsSelected = this.isAllItemsSelected.bind(this);
        this.extractProductIds = this.extractProductIds.bind(this);
        this.calculateWidth = this.calculateWidth.bind(this);
        this.state = {
            allSelected: false,
            partialSelection: false,
            productIds: [],
            realParent: null
        }

        this.resizeSensorInstance = null;
    }

    configureResizeSensor() {
        let miniProductSheetElement = document.querySelector(".custom-table-header-mini-product-sheet");
        if (miniProductSheetElement) {
            this.resizeSensorInstance = new ResizeSensor(miniProductSheetElement, () => {
                let thisElement = document.querySelector(this.selectionSummarySelector);
                if (thisElement) {
                    thisElement.style.height = miniProductSheetElement.clientHeight + "px"
                }
                miniProductSheetElement.style.position = "sticky";
            });
        }
    }

    componentDidMount() {
        let parent = document.querySelector(this.props.parentSelector);
        let  thisElement = document.querySelector(this.selectionSummarySelector);

        if (parent && thisElement) {
            this.setState({ realParent: thisElement.parentElement })
            parent.insertBefore(thisElement, null);
        }
        this.isAllItemsSelected(this.props.selectedProducts, this.props.productItems);

        this.configureResizeSensor();
    }

    componentWillUnmount() {
        let parent = this.state.realParent;
        let thisElement = document.querySelector(this.selectionSummarySelector);

        if (parent && thisElement) {
            parent.insertBefore(thisElement, null);
        }

        if (this.resizeSensorInstance) {
            this.resizeSensorInstance.detach();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.isAllItemsSelected(nextProps.selectedProducts, nextProps.productItems);
    }

    extractProductIds(productItems) {
        productItems = productItems || []
        let productIds = productItems.map((item) => item.productId)
        return productIds;
    }

    builSelectedProductsMessage() {
        return [this.props.totalSelectedProducts, this.props.totalSelectedProducts > 1 ? "productos seleccionados" : "producto seleccionado"].join(" ");
    }
    isAllItemsSelected(selectedProducts, productItems) {
        selectedProducts = selectedProducts || [];
        let partialSelection = false;
        let allSelected = true;
        let productIds = this.extractProductIds(productItems);
        productIds.forEach((item) => {
            if (selectedProducts.indexOf(item) > -1) {
                partialSelection = true;
            } else {
                allSelected = false;
            }
        })
        if (allSelected) {
            partialSelection = false;
        }

        this.setState({
            partialSelection,
            allSelected
        })
    }

    calculateWidth(){
        let selector = this.props.overlappedHeadersSelector;
        let headers = document.querySelectorAll(selector);
        if(headers.length > 0){
            let width = 0;
            for(let i= 0; i < headers.length; i++){
                width+= headers[i].offsetWidth;
            }
            return width;
        }
        return this.props.width || "100%";
        
    }

    render() {
        let { totalSelectedProducts, shouldDisplay} = this.props;

        return <div className={`${this.selectionSummaryClass} ` + this.props.className} style={
            {
                width: this.calculateWidth(),
                display: shouldDisplay ? 'inline' : 'none',
                left: 0,
                paddingLeft: 12
            }
            }>
            <div className="checkbox-container">
                <CheckBoxField
                    value={this.state.allSelected}
                    label={null}
                    className='compass'
                    input={{
                        value: this.state.allSelected,
                        name: "select-all-checks",
                        onChange: (e) => {
                            if (!e) return;
                            this.props.onAllSelected({ allSelected: this.state.allSelected, partialSelection: this.state.partialSelection })
                        }
                    }}
                />
            </div>
            <div className="summary">
                <div className="product-counter">
                    {this.builSelectedProductsMessage()}
                </div>
                {
                    totalSelectedProducts > 0 ?
                        <div className="cancel-selection" onClick={this.props.onSelectCancellation}>
                            Cancelar selección
                        </div> : null
                }
            </div>
        </div>;
    }
}
