import PurchaseSummaryCard from "@common/components/src/ui/PurchaseSummaryCard";
import { minBy, maxBy, isEmpty, isEqual } from "lodash";
import { Constantes, Utils } from "../..";
import moment from "moment";

export const setPriceShippingCellClasses = (directMatches, communeIdFilter) => {
    let classes = {
        min: PurchaseSummaryCard.ContainerClassNames.CHEAPEST_OF_ALL_THE_RETAILER,
        minColor: "#50ea7b",
        halfMin: PurchaseSummaryCard.ContainerClassNames.CLOSEST_TO_THE_CHEAPEST_PRICE,
        halfMinColor: "#bbfbcd",
        halfMax: PurchaseSummaryCard.ContainerClassNames.CLOSEST_TO_THE_MOST_EXPENSIVE_PRICE,
        halfMaxColor: "#ffd0d0",
        max: PurchaseSummaryCard.ContainerClassNames.MOST_EXPENSIVE_OF_ALL_THE_RETAILERS,
        maxColor: "#ffadad",
        noStock: PurchaseSummaryCard.ContainerClassNames.WITHOUT_STOCK,
        noStockColor: "#e0e0e0",
        noShipping: PurchaseSummaryCard.ContainerClassNames.WITHOUT_SHIPPING,
        noShippingColor: "#e0e0e0",
        none: PurchaseSummaryCard.ContainerClassNames.NONE,
        noneColor: "#e0e0e0",
    };

    const saturday = Utils.getSaturdayDate();

    directMatches.forEach(function (item) {
        if (!isEmpty(item.shippings)) {
            item.shippings = item.shippings.filter(
                match => match.communeId === communeIdFilter
            );

            item.shippings = item.shippings[0];
        }
        if(isEmpty(item.shippings)) {
            item.shippings = {};
        }
    });

    directMatches.forEach(function (item) {
        if(!isEmpty(item.shippings)) {
            const shippingDate = moment(item.shippings.updated).utc().format();
            if (shippingDate < saturday) {
                item.shippings.status = 3;
        }
        }
    });

    let shipping = directMatches.filter(
        (item) => item.shippings.status === Constantes.STATUS_PRODUCT.WITH_STOCK && item.statusProduct === Constantes.STATUS_PRODUCT.WITH_STOCK);

    const minMatchPriceShipping = minBy(shipping, "shippings.price");
    const maxMatchPriceShipping = maxBy(shipping, "shippings.price");
    const minPriceShipping = minMatchPriceShipping
        ? minMatchPriceShipping.shippings.price
        : null;
    const maxPriceShipping = maxMatchPriceShipping
        ? maxMatchPriceShipping.shippings.price
        : null;

    let pricesShipping = shipping.map((item) => item.shippings.price);
    const averagePriceShipping =
        pricesShipping.reduce((a, b) => a + b, 0) / pricesShipping.length;

    directMatches.forEach(function (item) {
        item.priceShippingColor = null;
        item.priceShippingClass = null;

        if (item.shippings === undefined || isEqual(item.shippings, {})) {
            item.priceShippingClass = classes.noStock;
            item.priceShippingColor = classes.noStockColor;
        } else if (item.shippings.status === 2) {
            item.priceShippingClass = classes.noShipping;
            item.priceShippingColor = classes.noShippingColor;
        } else if (item.shippings.status === 3 || item.shippings.status === 4) {
            item.priceShippingClass = classes.none;
            item.priceShippingColor = classes.noneColor;
        } else if (item.shippings.price === minPriceShipping) {
            item.priceShippingClass = classes.min;
            item.priceShippingColor = classes.minColor;
        } else if (item.shippings.price === maxPriceShipping || item.shippings.price === 0) {
            item.priceShippingClass = classes.max;
            item.priceShippingColor = classes.maxColor;
        } else if (item.shippings.price <= averagePriceShipping) {
            item.priceShippingClass = classes.halfMin;
            item.priceShippingColor = classes.halfMinColor;
        } else {
            item.priceShippingClass = classes.halfMax;
            item.priceShippingColor = classes.halfMaxColor;
        }
    });
};
