import React from "react";
import LabelModal from "./LabelModal";

const ChangeLabelPublicToPrivateModal = ({
    onConfirm,
    onCancel,
    ownerName
}) => {
    const description = ownerName
        ? `Este label le pertenece a ${ownerName}, pero a partir de ahora
            tendrás la responsabilidad de la información del label y todos tus
            compañeros que lo usan dejarán de verlo.`
        : `Estás cambiando este label a privado, sólo tú tendrás el control de
            la información y todos tus compañeros que lo usan dejarán de verlo.`;
    const title = "Label privado";
    const subTitle = "¿Estás seguro de realizar esta acción?";
    const confirmAction = "Sí, hacerlo privado";

    return (
        <LabelModal
            className="danger"
            title={title}
            subtitle={subTitle}
            description={description}
            confirmAction={confirmAction}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
};

export default ChangeLabelPublicToPrivateModal;
