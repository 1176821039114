import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CloseButton from "./CloseButton";

export default class CustomModal extends PureComponent {
    static propTypes = {
        header: PropTypes.node,
        body: PropTypes.node.isRequired,
        footer: PropTypes.node,
        onClose: PropTypes.func,
        isOpen: PropTypes.bool,
        className: PropTypes.string,
        toggle: PropTypes.func,
        wrapperClassName: PropTypes.string,
        container: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.object
        ])
    };

    static defaultProps = {
        onClose: () => {},
        className: "",
        wrapperClassName: ""
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen
        };
        this.toggle = this.toggle.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen != this.state.isOpen) {
            this.setState({
                isOpen: nextProps.isOpen
            });
        }
    }

    toggle() {
        if (this.props.toggle) {
            this.props.toggle();
            return;
        }
        if (this.state.isOpen) {
            this.props.onClose();
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <Modal
                centered={true}
                wrapClassName={
                    "custom-modal-rc " + (this.props.wrapperClassName || "")
                }
                isOpen={this.state.isOpen}
                toggle={this.toggle}
                onClosed={this.props.onClosed}
                className={this.props.className}
                innerRef={this.props.container}
            >
                <CloseButton onClick={this.toggle} />
                {this.props.header ? (
                    <ModalHeader toggle={this.toggle}>
                        {this.props.header}
                    </ModalHeader>
                ) : null}
                <ModalBody>{this.props.body}</ModalBody>
                {this.props.footer ? (
                    <ModalFooter>{this.props.footer}</ModalFooter>
                ) : null}
            </Modal>
        );
    }
}
