import { RetailCompassStorage, BuildProductSearchFilters, TableStateUtils, Constantes as Constants } from "@common/utils/"
import { ProductListService } from "@common/data-access/"
import * as DashboardAction from './dashboardActions';


export const initialState = {
    isFetching: false,
    brands: [],
    stores: [],
    lastFilter: {},
    lastSearchRequest: {},
}

let abortController = new window.AbortController();

export const fetchReportInfo = (dispatch, payload) => {
    payload = payload || {};

    abortController.abort();
    abortController = new window.AbortController();

    const userStoreId = RetailCompassStorage.getStoreId();
    const canonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
    const canonicalStoreId = canonicalCategory.storeId;
    const canonicalCategoryPath = canonicalCategory.path;
    const ranges = canonicalCategory.rangesPrice;
    const includeStores = RetailCompassStorage.getIncludeStores();

    let body = {
        viewType: "PRICING",
        storeIdObserver: userStoreId,
        page: 0,
        size: 0,
        stores: [
            {
                storeId: canonicalStoreId,
                children: false
            }
        ],
        categoryPath: canonicalCategoryPath,
        statuses: [1, -1],
        sort: {
            field: Constants.SortValues.PRICE,
            order: "DESC"
        },
        filters: {
            brands: {
                size: 70,
                includeOthers: false,
                includeStats: false,
                groupBy: "PARENT_BRAND",
                sort: "COUNT"
            }
        },
        aggregations: [Constants.AGGREGATIONS_NAMES.BRAND_SHARE_CANONICAL],
        matches: {
            contains: "ALL",
            statusId: null,
            storeIds: []
        }
    }

    BuildProductSearchFilters.buildAggregationRangePrice(body, ranges);

    const receiveCallback = (response) => {
        return DashboardAction.receiveProductSearchAction({
            response: response,
            requestBody: {
                ...body,
                includeStores: includeStores
            }
        })
    };

    const receiveAbortErrorCallback = (payload) => {
        return DashboardAction.receiveProductSearchAbortErrorAction(payload);
    };

    const signal = abortController.signal;
    return ProductListService.fetchReportInfo({
        signal,
        dispatch,
        data: body,
        requestCallback: DashboardAction.requestProductSearchAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: DashboardAction.receiveProductSearchErrorAction,
        receiveAbortErrorCallback: receiveAbortErrorCallback
    });
}

export const DashboardStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case DashboardAction.REQUEST_PRODUCT_SEARCH:
            return Object.assign({}, state, { isFetching: true });
        case DashboardAction.RECEIVE_PRODUCT_SEARCH:
            const response = action.payload.response;
            const requestBody = action.payload.requestBody;
            const storeId = RetailCompassStorage.getStoreId();
            let brands = TableStateUtils.formatBrandsFromAPI(response.filters.brands);
            let stores = TableStateUtils.formatStoresFromAPIInclude(response.aggregations, requestBody.includeStores);
            let ranges = TableStateUtils.formatRangesMatchesFromAPI(storeId, brands, response.aggregations);
            return Object.assign({}, state, {
                brands: brands,
                stores: stores,
                ranges: ranges,
                isFetching: false,
            });
        case DashboardAction.RECEIVE_PRODUCT_SEARCH_ERROR:
            return Object.assign({}, state, { isFetching: false });
        case DashboardAction.RECEIVE_PRODUCT_SEARCH_ABORT_ERROR:
            return Object.assign({}, state, { isFetching: false });
        default:
            return state;
    }
}
