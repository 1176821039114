import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';
import { Utils, EdgeUtils, Constantes } from '@common/utils/';
import LanguageIcon from '@material-ui/icons/Language';
import { isEqual } from 'lodash'
import DeleteLabelProductModal from '../modals/DeleteLabelProductModal';

export const labelType = PropTypes.shape({
    "color": PropTypes.string.isRequired,
    "name": PropTypes.string.isRequired,
    "id": PropTypes.number
});
export default class Label extends Component {

    static propTypes = {
        onLabelSelection: PropTypes.func,
        onLabelRemoval: PropTypes.func,
        popoverRemoveClick: PropTypes.func,
        mode: PropTypes.oneOf(['row', 'simple', 'form']),
        label: labelType.isRequired,
        showPopoverRemove: PropTypes.bool,
        showCloseIcon: PropTypes.bool
    };
    static defaultProps = {
        showCloseIcon: true,
        applyHover: true
    }

    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            popoverOpen: false,
            uniqueID: Utils.generateUniqueID(),
            showModalLabel: false
        }
        this.timeoutClosePopover = null;
        this.labelRef = React.createRef();

        this.onLabelClick = this.onLabelClick.bind(this);
        this.createTimeoutClosePopover = this.createTimeoutClosePopover.bind(this);
        this.cancelTimeoutClosePopover = this.cancelTimeoutClosePopover.bind(this);
        this.openPopover = this.openPopover.bind(this);
        this.closePopover = this.closePopover.bind(this);
        this.handlePopoverRemoveClick = this.handlePopoverRemoveClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClickConfirmationModal = this.onClickConfirmationModal.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    componentWillUnmount() {
        this.cancelTimeoutClosePopover();
    }

    onLabelClick(selected) {
        if (this.props.selected && this.props.onLabelRemoval) {
            this.props.onLabelRemoval(selected);
        } else if (this.props.onLabelSelection) {
            this.props.onLabelSelection(selected);
        }
    }

    openPopover() {
        if (this.props.showPopoverRemove) {
            this.setState({ popoverOpen: true })
        }
    }

    closePopover(e) {
        if (this.props.showPopoverRemove) {
            this.setState({ popoverOpen: false })
        }
    }

    createTimeoutClosePopover(time) {
        if (this.props.showPopoverRemove) {
            time = time || 200;
            this.cancelTimeoutClosePopover();
            this.timeoutClosePopover = setTimeout(() => {
                this.setState({ popoverOpen: false, })
            }, time);
        }
    }

    cancelTimeoutClosePopover() {
        if (this.props.showPopoverRemove && this.timeoutClosePopover) {
            clearTimeout(this.timeoutClosePopover);
        }
    }

    handlePopoverRemoveClick() {
        if (this.props.showPopoverRemove && this.props.popoverRemoveClick) {

            if (this.props.label.accessType === Constantes.ACCESS_TYPES_LABEL.PUBLIC) {
                this.setState({ showModalLabel: true });
            } else {
                this.handleSubmit();
            }
        }
    }

    handleSubmit() {
        this.props.popoverRemoveClick(this.props.label);
        this.closePopover();
    }

    onClickCancel() {
        this.setState({ showModalLabel: false });
        this.closePopover();
    }

    onClickConfirmationModal() {
        this.handleSubmit();
        this.setState({ showModalLabel: false });
    }

    render() {
        let { label, showModalLabel } = this.state;
        let { mode, selected } = this.props;
        let id = 'label-' + label.id + "-" + this.state.uniqueID;
        let hoverClass = (this.props.applyHover ? ' ' : ' no-hover ')

        return (
            <div className={"label-container label-" + (mode) + (selected ? " label-container--active" : "") + hoverClass}
                onClick={(e) => { this.onLabelClick(label) }}
                key={"label-" + label.id}>

                <div id={id}
                    className={"label " + label.color}
                    onMouseEnter={this.openPopover}
                    ref={this.labelRef}
                    onMouseLeave={() => this.createTimeoutClosePopover()}>
                    {label.name}

                    {isEqual(label.accessType, Constantes.ACCESS_TYPES_LABEL.PUBLIC) &&
                        <div className='global-icon-container'>
                            <LanguageIcon id={id} className="global-icon" />
                        </div>
                    }

                    {this.labelRef.current ?
                        <Popover className={"label-remove-container " + (EdgeUtils.isEdge() ? 'edge-browser' : '')}
                            placement="top"
                            target={this.labelRef.current}
                            isOpen={this.state.popoverOpen}
                            onMouseEnter={this.cancelTimeoutClosePopover}
                            onMouseLeave={this.closePopover}
                            delay={0}>
                            <PopoverBody className="label-remove-body" onClick={this.handlePopoverRemoveClick}>
                                <CloseIcon className="label-remove-icon common-close-icon" size={18} />
                                <span className="label-remove-text">Quitar label</span>
                            </PopoverBody>
                        </Popover> : null}

                    {showModalLabel &&
                        <DeleteLabelProductModal
                            onCancel={this.onClickCancel}
                            onConfirm={this.onClickConfirmationModal}
                        />
                    }
                </div>

                {this.props.showCloseIcon ?
                    <div className="close-btn">
                        <CloseIcon size={22} />
                    </div> : null}
            </div >
        )
    }
}
