import { Constantes, Utils } from '@common/utils/';
import React from 'react';
import { feeAbbreviation, isVisibleFees } from './PriceUtils';

const InstallmentCell = props => {
    const { product, countryCode } = props; 
    const fees = product.fees;
    const activeFee = isVisibleFees(countryCode, product.price);
    let containerClassName = '';

    if (!fees) {
        return <div></div>;
    }

    if (product.status === Constantes.STATUS_PRODUCT.WITHOUT_STOCK) {
        return <div></div>;
    }

    let feeClassName = null;
    if (activeFee) {
        containerClassName = containerClassName + ' active-fee';
        feeClassName = Utils.assignFeeClass(fees);
    }

    return <div className="product-detail-body--price-cell">
        <span className={"installment-cell " + feeClassName} style={{ fontWeight: 500 }}>{feeAbbreviation(countryCode) + fees}</span>
    </div>;
};

export default InstallmentCell;