import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, get, orderBy, maxBy } from "lodash";
import { Constantes, Utils, RouterUtils } from '@common/utils';

const AVAILABLE_REPORTS = [
    Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL,
    Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE,
    Constantes.TYPE_REPORT_BRAND.SHARE_BY_BRAND,
    Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST,
];

const filterPricesData = (pricesData, filteredFrom, filteredTo) => {
    if (filteredFrom || filteredTo) {
        return pricesData && pricesData.filter((item) => {
            let fromValues = [0, filteredFrom];
            if (fromValues.includes(item.from) && item.to === filteredTo) {
                return true;
            }
            return false;
        })
    }
    return pricesData;
}

const extractBrandIdsFromShareReportInfo = (counterItem, params) => {
    if (counterItem.counterKey === Constantes.REPORT_KEYS.HAVE_IT) {
        let brands = get(counterItem, 'entity.shareReportInfo.haveIt.brandIds', undefined);
        params.brands = brands && brands.join(',');
    }
}

const orderRetailers = (entityItems) => {
    return orderBy(entityItems, (entityItem) => {
        return getTotalOrDefaultFromShareReportInfo(entityItem);
    }, 'desc');
}

const orderBrands = (entityItems) => {
    return orderBy(entityItems, (entityItem) => {
        return get(entityItem, 'count', 0);
    }, 'desc');
}

const putAllBrands = (brandsResult, allBrands) => {
    return allBrands.map((brand) => {
        brand.count = 0;
        const brandResult = find(brandsResult, ['brandId', brand.brandId]);
        if (brandResult) {
            brand.count = brandResult.count;
        }
        return brand;
    });
}

const getTotalOrDefaultFromShareReportInfo = (entity) => get(entity, 'shareReportInfo.total', 0);

export const buildShareByLowerPriceReport = (props, interactiveCounterBuilder) => {
    let pricesData = filterPricesData(props.shareByPriceRanges, props.lastFilter.from, props.lastFilter.to);
    let greaterPrice = maxBy(pricesData, (currentItem) => getTotalOrDefaultFromShareReportInfo(currentItem));
    let totalGreater = getTotalOrDefaultFromShareReportInfo(greaterPrice);

    return interactiveCounterBuilder({
        entityItems: pricesData,
        totalGreater: totalGreater,
        totalInTheMarket: props.paginationCount,
        goToProductList: (counterItem, urlParams) => {
            let params = { from: counterItem.entity.from, to: counterItem.entity.to, ...urlParams };
            extractBrandIdsFromShareReportInfo(counterItem, params);
            props.goToProductList(params);
        },
        currentReport: props.currentReport
    });
}

export const buildShareByRetailReport = (props, interactiveCounterBuilder) => {
    const { collapseToogle, getCollapseIsOpen } = props;
    let retailers = orderRetailers(Utils.filterEntities(props.stores, props.lastFilter.stores, 'storeId'));
    let totalGreater = get(retailers, '[0].shareReportInfo.total', 0);

    return interactiveCounterBuilder({
        entityItems: retailers,
        totalGreater: totalGreater,
        totalInTheMarket: props.paginationCount,
        goToProductList: (counterItem, urlParams) => {
            let params = { stores: counterItem.entity.storeId, ...urlParams };
            extractBrandIdsFromShareReportInfo(counterItem, params);
            props.goToProductList(params);
        },
        currentReport: props.currentReport,
        collapseToogle,
        getCollapseIsOpen,
    });
}

export const buildShareByBrandReport = (props, interactiveCounterBuilder) => {
    const { shareBrand, brands, collapseToogle, getCollapseIsOpen } = props;
    let shareBrands = putAllBrands(shareBrand, brands);

    shareBrands = orderBrands(Utils.filterEntities(shareBrands, props.lastFilter.brands, 'brandId'));
    const totalGreater = get(shareBrands, '[0].count', 0);
    
    return interactiveCounterBuilder({
        entityItems: shareBrands,
        totalGreater: totalGreater,
        totalInTheMarket: props.paginationCount,
        goToProductList: (counterItem, urlParams) => {
            let params = { brands: counterItem.entity.brandId, ...urlParams };
            props.goToProductList(params);
        },
        currentReport: props.currentReport,
        collapseToogle,
        getCollapseIsOpen,
    });
}

export const withGenericReport = (WrappedComponent, interactiveCounterBuilder) => {
    if (!Utils.isFunction(interactiveCounterBuilder)) {
        throw new Error("`interactiveCounterBuilder` is required");
    }

    const STRATEGIES = {
        [Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL]: (props) => buildShareByRetailReport(props, interactiveCounterBuilder),
        [Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE]: (props) => buildShareByLowerPriceReport(props, interactiveCounterBuilder),
        [Constantes.TYPE_REPORT_BRAND.SHARE_BY_BRAND]: (props) => buildShareByBrandReport(props, interactiveCounterBuilder)
    }

    return class WithGenericReportHOC extends Component {

        static propTypes = {
            currentReport: PropTypes.oneOf(AVAILABLE_REPORTS),
            history: PropTypes.object.isRequired,
            lastFilter: PropTypes.object.isRequired,
            onSelectedView: PropTypes.func.isRequired
        };

        constructor(props) {
            
            super(props);
            this.state ={
                collapseIsOpen: false,
            }
            this.goToProductList = this.goToProductList.bind(this);
            this.collapseToogle = this.collapseToogle.bind(this);
            this.getCollapseIsOpen = this.getCollapseIsOpen.bind(this);
        }

        collapseToogle(){
            this.setState({
                collapseIsOpen: !this.state.collapseIsOpen,
            });
        }

        getCollapseIsOpen(){
            return this.state.collapseIsOpen;
        }

        goToProductList(params) {
            RouterUtils.goToProductListTabView({ location: this.props.location, history: this.props.history, onSelectedView: this.props.onSelectedView, params })
        }

        render() {
            let interactiveCounterRender = STRATEGIES[this.props.currentReport];

            if (!interactiveCounterRender) {
                return null;
            }

            const otherProps = {
                interactiveCounterRender,
                goToProductList: this.goToProductList,
                collapseToogle: this.collapseToogle,
                getCollapseIsOpen: this.getCollapseIsOpen,
            };

            return <WrappedComponent {...this.props} {...otherProps} />
        }
    }
}
