import { connect } from 'react-redux';
import { ProductListContainer, TableState, TableActions } from '@common/components/';
import { SubRouterState, Constantes, RetailCompassStorage } from '@common/utils';
import Reportview from './index';

const mapStateToProps = (state, ownProps) => {
    let commonProps = ProductListContainer.mapStateToProps(state, ownProps);
    const configuration = RetailCompassStorage.getConfigurationCurrentCategory();
    const productAccess = RetailCompassStorage.getProductAccessConfig();
    const modules = productAccess ? productAccess.modules : []; 
    return {
        ...commonProps,
        selectedReport: state.subRouterStateReducer.selectedReport || Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF,
        selectedSubReport: state.subRouterStateReducer.selectedSubReport || Constantes.RETAIL_VIEW_SUBREPORT[Constantes.RETAIL_VIEW_REPORT.DEFAULT].DEFAULT,
        modules: modules,
        configuration: configuration,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        reportChange: (subReport) => SubRouterState.reportChange(dispatch, subReport),
        subReportChange: (subReport) => SubRouterState.subReportChange(dispatch, subReport),
        fetchProductItems: (params) => TableState.fetchProductItems(dispatch, params),
        resetStoreForShipping: () => dispatch(TableActions.resetStoreForShippingAction()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Reportview);
