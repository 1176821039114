import React from 'react';
import { get } from 'lodash';
import Label from '../../../labels/label/Label';
import { LabelsUtils } from '@common/utils';

export const LabelsProductList = ({ productLabels, labelsFilter, productId, hidePublicLabels, handleOnRemove }) => {

    /**
    * obtenemos los labels ordenados, para ello tenemos en cuenta 
    * la fecha de los productos en el label. De esta forma el último producto agregado...
    * estará al inicio y así con los demás.
    * En el caso de tener filtro activo por labels el orden mostrado será el mismo del filtro.
    */
    function getOrderedLabelsByProduct() {
        const _productLabels = productLabels
            .filter(currentLabel => LabelsUtils.hasRelationshipWithTheLabel(currentLabel.labelProducts, productId))
            .filter(label => LabelsUtils.validateHidePublicLabel(hidePublicLabels, label));
        if (labelsFilter?.length) {
            return _productLabels;
        } else {
            return _productLabels.sort(function compare(a, b) {
                const dateA = new Date(a.updated);
                const dateB = new Date(b.updated);
                return dateB - dateA;
            });
        }
    }

    function onRemove(label) {
        handleOnRemove(label)
    }

    const labels = getOrderedLabelsByProduct();

    return (
        <div className="labels-container">
            {
                labels.map(label =>
                    <Label
                        label={label}
                        popoverRemoveClick={onRemove}
                        showPopoverRemove={get(label, "showPopoverRemove", true)}
                        mode="simple"
                        showCloseIcon={false}
                        key={`label-${label.id}-${label.accessType}`}
                    />
                )
            }
        </div>
    )
}