import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';


export class WhereAmINav extends PureComponent {

    static propTypes = {
        currentViewText: PropTypes.string.isRequired,
        showGoBackToLink: PropTypes.bool.isRequired,
        goBackToPath: PropTypes.string,
        goBackToText: PropTypes.string,
        customTitleText: PropTypes.string,
        onClick: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    onClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
        this.props.history.push(this.props.goBackToPath);
    }


    render() {
        const { t, customTitleText, goBackToText, showGoBackToLink } = this.props;

        return (
            <div className="where-am-i-nav-container">

                <Col md={12}>
                    {showGoBackToLink ?
                        <div className='go-back-to' onClick={this.onClick}>
                            <ChevronLeftIcon className='arrow-back' color={'#2c71bc'} size={22} />
                            <span className="go-back-to-text">{t('go_back_to') + ' ' + goBackToText}</span>
                        </div>
                        : null}

                    {
                        customTitleText ?
                            <div className='page-title'>
                                <span className="title custom-text">{customTitleText}</span>
                            </div>
                            :
                            null
                    }
                </Col>

            </div >
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory || {},
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    };
};

export const WhereAmINavI18n = translate('common')(WhereAmINav);
export const WhereAmINavI18nConnected = connect(mapStateToProps, mapDispatchToProps)(WhereAmINavI18n);
export default withRouter(WhereAmINavI18nConnected);



