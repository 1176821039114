import { UserApiHelper } from "@common/auth";
import {
    ConstantesApi,
    RetailCompassStorage,
    UserUtils,
} from "@common/utils";

export const domain = () => {
    let domain = ConstantesApi.AUTH0_TENANT_DEV_DOMAIN;
    if (process.env.REACT_APP_RC_ENV === "production") {
        domain = ConstantesApi.AUTH0_TENANT_PROD_DOMAIN;
    }
    return domain;
};

export const clientId = () => {
    let clientId = ConstantesApi.AUTH0_TENANT_DEV_CLIENT_ID;
    if (process.env.REACT_APP_RC_ENV === "production") {
        clientId = ConstantesApi.AUTH0_TENANT_PROD_CLIENT_ID;
    }
    return clientId;
};

export const parseUser = async (nextUser) => {
    const userCatalog = nextUser[ConstantesApi.JWT_USER_CATALOG_NS];
    const userLite = await UserApiHelper.getStoresInformation(userCatalog);
    const storesInfo = userLite ? userLite.stores : [];
    RetailCompassStorage.setTotalStores(storesInfo);
    const stores = storesInfo.filter(
        (store) =>
            store.categories &&
            store.categories.length > 0 &&
            store.active === true
    );
    let user = Object.assign({}, { ...nextUser }, { ...userCatalog, stores });
    delete user[ConstantesApi.JWT_USER_CATALOG_NS];
    user.authenticationToken = user.token;

    return UserUtils.processUserObj(user);
};

export const DOMAIN = domain();
export const CLIENT_ID = clientId();
export const LEEWAY = 3600;
export const REDIRECT_URL = window.location.origin + "/login";
