import * as Utils from '../../utils/utils'

export const validateIfMatchIsMyProductByBrandsIds = (userBrandItems, match) => {
    return userBrandItems.filter((currentBrand) => (currentBrand.id === match.brandId || currentBrand.name === match.brand)).length > 0;
}

export const validateIfMatchIsMyProductByStoreId = (storeId, match, sellerTypes) => {
    let haveAllMarket = Utils.haveAllMarketFromSellerType(sellerTypes);
    if (haveAllMarket) {
      return storeId === match.storeId;
    } else {
      let isSeller = Utils.isSeller(sellerTypes);
      return storeId === match.storeId && isSeller === match.isSeller;
    }
}
