import React, { useState, useEffect } from 'react'
import { OperatorUtils, Utils } from '@common/utils/';
import RangeSlider from '../../RangeSlider';
import { concat, isNull } from 'lodash';
import { useFilterDispatch } from '../FiltersProvider';
import { actionsFilter } from '../FiltersReducer';


const PostpagoPriceRangeFilter = () => {

    const [ranges, setRanges] = useState([]);
    const dispatch = useFilterDispatch();

    useEffect(() => {
        let rangePrices = OperatorUtils.getRangesForFilterRangePlanPrice();
        rangePrices = concat([{ to: 0 }], rangePrices);
        rangePrices = rangePrices
            .filter(range => !isNull(range.to))
            .map(range => buildItem(range))
        setRanges(rangePrices);
    }, [])

    const buildItem = (item) => {
        return {
            label: Utils.formatPrice(item.to) || 0,
            value: item.to,
        }
    }

    const onChangePrice = (item) => {
        dispatch({ type: actionsFilter.UPDATE_MIN_PRICE, payload: item });
    }

    return (
        <RangeSlider
            ranges={ranges}
            header="Precio plan"
            onChange={onChangePrice} />
    )
}

export default PostpagoPriceRangeFilter