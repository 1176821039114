import React, { memo } from 'react';
import PostpagoFilterSection from './filters/PostpagoFilterSection';
import FilterProvider from './FiltersProvider';
import PostpagoTable from './table/PostpagoTable';

const PostPagoDetailBody = (props) => {

    return (
        <div className="postpago-detail">
            <FilterProvider>
                <PostpagoFilterSection
                    currentFilter={props.currentFilter}
                    canonicalProduct={props.canonicalProduct}
                    stores={props.stores}
                />

                <PostpagoTable
                    tableStrategy={props.tableStrategy}
                    canonicalProduct={props.canonicalProduct}
                    stores={props.stores}
                    operators={props.currentFilter.operators}
                    idTable="postpagoDetailTable"
                    responsive={true}
                />
            </FilterProvider>
        </div>
    )
}

export default memo(PostPagoDetailBody)