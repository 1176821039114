import React from 'react'
import LabelModal from './LabelModal'

const CreatePublicLabelModal = ({ onConfirm, onCancel }) => {

    const title = <>Label público</>;
    const description = <>Hacer un label público puede simplificar<br />mucho el trabajo en tu equipo, recuerda que<br />todos podrán editarlo y eliminarlo</>;
    const subTitle = "¿Estás seguro de realizar esta acción?";
    const confirmAction = "Si, hacer público";

    return <LabelModal
        title={title}
        description={description}
        subtitle={subTitle}
        confirmAction={confirmAction}
        onCancel={onCancel}
        onConfirm={onConfirm}
    />
}

export default CreatePublicLabelModal;