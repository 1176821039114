import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual } from 'lodash';
import { Utils } from '@common/utils/';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { tooltipFormatter } from './historicUtils';

/* existe un issue abierto que produce error de z-index en el tooltip con outside:true
este plugin es una solucion temporal https://github.com/highcharts/highcharts/issues/11494 */
(function (H) {
    H.wrap(
        H.Tooltip.prototype,
        'getLabel',
        function (proceed) {
            var t = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
            if (this.container) {
                H.css(
                    this.container, {
                    zIndex: 2000
                }
                );
            }
            return t;
        }
    );
})(Highcharts); 

const emptyStateSVG = process.env.PUBLIC_URL + '/img/_ES_GRAFICO.svg';

export default class CustomizedChart extends PureComponent {

    static propTypes = {
        emptyHistory: PropTypes.bool.isRequired,
        isFetchingHistoricData: PropTypes.bool,
        chartDates: PropTypes.object,
        chartSeries: PropTypes.array,
        includeCardPrices: PropTypes.bool,
        tooltipFormatter: PropTypes.func,
        tooltipClassName: PropTypes.string
    };

    static defaultProps = {
        chartDates: {},
        includeCardPrices: true,
        tooltipFormatter: tooltipFormatter,
        tooltipClassName: ''
    }

    chartConfig = null;

    constructor(props) {
        super(props);
        this.state = {
            chart: null,
            chartKey: "ONETWOTHREE"
        };

        this.chartConfig = this.buildChartConfig();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(this.props.chartSeries, prevProps.chartSeries)) {
            this.setState({ chartKey: Utils.generateUniqueID() });
        }
    }

    componentDidMount() {
        this.chartConfig = this.buildChartConfig();
    }

    buildChartConfig() {
        let series = this.props.chartSeries || [];
        let height = 230;
        let config = {
            title: {
                text: null,
                y: 0,
                margin: 0
            },
            margin: [0, 0, 0, 0],
            spacing: [0, 0, 0, 0],
            chart: {
                //margin: [0, 20, 60, 65],
                marginTop: 10,
                spacing: [0, 0, 0, 0],
                style: {
                    fontFamily: "'Roboto', sans-serif"
                },
                height: height,
            },
            rangeSelector: {
                inputEnabled: false,
                buttonTheme: {
                    visibility: 'hidden'
                },
                labelStyle: {
                    visibility: 'hidden'
                }
            },
            plotOptions: {
                series: {
                    connectNulls: false,
                    gapSize: 1,
                    marker: {
                        enabled: true,
                        radius: 3,
                        symbol: "circle",
                        states: {
                            hover: {
                                marker: {
                                    enabled: false,

                                    animation: {
                                        duration: 0
                                    }
                                }
                            }
                        }
                    },
                },
                point: {
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    }
                }
            },
            yAxis: {
                opposite: false,
                labels: {
                    align: 'center',
                    formatter: function () {
                        return Utils.formatPrice(this.value);
                    },
                    overflow: "allow"
                },
                endOnTick: false,
                startOnTick: false,
                showFirstLabel: true,
                showLastLabel: true,
                tickPixelInterval: 40,
                tickAmount: 6,
                title: {
                    reserveSpace: false,
                    text: null
                },
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return moment.utc(this.value).format("DD/MM");
                    },
                    step: 3,
                    reserveSpace: true,
                    inside: true
                },
                endOnTick: true,
                startOnTick: false,
                showFirstLabel: true,
                showLastLabel: false,
                tickAmount: 11,
                tickPixelInterval: 25
            },
            series: series,
            tooltip: {
                hideDelay: 50,
                outside: true,
                split: false,
                shared: true,
                useHTML: true,
                formatter: this.props.tooltipFormatter,
                className: this.props.tooltipClassName,
                backgroundColor: null,
                borderColor: null,
                borderWidth: 0,
                shadow: false,
                style: {
                    padding: 0
                }
            },
            legend: {
                enabled: true,
                align: 'center',
                backgroundColor: 'transparent',
                borderWidth: 0,
                layout: 'horizontal',
                verticalAlign: "bottom",
                shadow: false,
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6,
                squareSymbol: true,
                reversed: true,
                navigation: {
                    enabled: false
                },
                itemStyle: {
                    fontSize: '13px',
                    color: '#555555',
                    fontWeight: 'normal'
                }
            },
            scrollbar: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        };

        return config;
    }

    setMkpMarker() {
        if (!Highcharts.SVGRenderer.prototype.symbols.mkpMarker) {
            Highcharts.SVGRenderer.prototype.symbols.mkpMarker = function (x, y, w, h) {
                return [
                    "M", x - 2.5, y,
                    "h 11.5",
                    "a 2 2 1 0 1 2 2",
                    "v 2",
                    "a 2 2 1 0 1 -2 2",
                    "h -11.5",
                    "a 2 2 1 0 1 -2 -2",
                    "v -2",
                    "a 2 2 1 0 1 2 -2",
                    "z"];
            };
        }
    }


    render() {

        if (this.props.isFetchingHistoricData) {
            return (<div className="historic-blank"></div>);
        }
        else if (this.props.chartSeries.length === 0) {

            return (<div className="historic-fallback">
                <div className="historic-fallback--image">
                    <img src={emptyStateSVG} />
                </div>
                <div className="historic-fallback--title">
                    Prueba con otras fechas
                </div>
                <div className="historic-fallback--subtitle">
                    El o los productos no tienen datos en las fechas seleccionadas
                </div>
            </div>)

        } else {
            this.setMkpMarker();
            return (<div className="historic-chart">
                <HighchartsReact options={this.chartConfig} highcharts={Highcharts} key={this.state.chartKey} />
            </div>)
        }
    }
}
