/**
 * @author @author <a href="mailto:jvillada@retailcompass.com">Jorge Villada</a>
 * @class labelService.js
 * @date 15 may. 2018
 */

import { postAsJson, getAsJson, deleteAsJson, patchAsJson, sendBeacon } from './AbstractService';
import { ConstantesApi, Utils } from '@common/utils';

export const fetchCreate = (userId, params) => {
    if (!params.data.categoryId) {
        throw new Error('categoryId required');
    }
    if (!userId) {
        throw new Error('userId required');
    }

    if (!Utils.hasValue(params.data.productIds)) {
        throw new Error('productIds required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_LABELS_PATH + "/" + userId)

    return postAsJson({ api: api, ...params });
};
export const patchLabel = (userId, labelId, params) => {
    if (!labelId) {
        throw new Error('labelId required');
    }
    if (!userId) {
        throw new Error('userId required');
    }

    if (!Utils.hasValue(params.data.productIds)) {
        throw new Error('productIds required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_LABELS_PATH + "/" + userId + "/" + labelId)

    return patchAsJson({ api: api, ...params });
};


export const fetchDelete = (userId, params) => {


    if (!userId) {
        throw new Error('userId required');
    }

    if (!params.data.labelId) {
        throw new Error('labelId required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_LABELS_PATH + "/" + userId + "/" + params.data.labelId)

    return deleteAsJson({ api: api, ...params });
};

export const fetchList = (userId, params) => {
    if (!userId) {
        throw new Error('userId required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_LABELS_PATH + "/" + userId)

    return getAsJson({ api: api, ...params });
}


export const fetchDeleteProductOfALabel = (userId, labelId, productId, params) => {
    if (!userId) {
        throw new Error('userId required');
    }
    if (!labelId) {
        throw new Error('labelId required');
    }
    if (!productId) {
        throw new Error('productId required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_LABELS_PATH + "/" + userId + "/" + labelId + "/" + productId)

    return deleteAsJson({ api: api, ...params });
}

export const fetchRefreshUpdateDate = (userId, params) => {
    if (!userId) {
        throw new Error('userId required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_LABELS_PATH + "/" + userId + "/refreshUpdateDate")

    if (params.keepalive) {
        sendBeacon({ api: api, data: params.data });
    } else {
        return postAsJson({ api: api, ...params });
    }
};

export const updateAccessType = params => {
    if (!params.data.userId) throw new Error("userId required");
    if (!params.data.id) throw new Error("id required");
    if (!params.data.accessType) throw new Error("accessType required");

    const api = ConstantesApi.buildEndpoint(
        ConstantesApi.API_LABELS_PATH + "/updateAccessType"
    );
    return postAsJson({ api, ...params });
};
