/**
 * @author @author <a href="mailto:jvillada@pricingcompass.com">Jorge Villada</a>
 * @class alertService.js
 * @date 15 May. 2019
 */


import { getAsJson, postAsJson, putAsJson, deleteAsJson } from './AbstractService';

import { ConstantesApi } from '@common/utils';

export const fetchAlerts = (params) => {
    const { storeId, userId, categoryId } = params;
    if (!storeId) {
        throw new Error('Store Required');
    }
    if (!userId) {
        throw new Error('User required');
    }
    return getAsJson({
        api: `${ConstantesApi.API_ALERTS_PATH}/${storeId}/${userId}/${categoryId}`,
        ...params
    });
};
export const create = (params) => {
    const { categoryId, userId } = params.data;
    if (!categoryId) {
        throw new Error('categoryId Required');
    }
    if (!userId) {
        throw new Error('userId required');
    }
    return postAsJson({
        api: ConstantesApi.buildEndpoint(`${ConstantesApi.API_ALERTS_PATH}/save`),
        ...params
    });
};
export const edit = (params) => {
    const { categoryId, userId } = params;
    if (!categoryId) {
        throw new Error('categoryId Required');
    }
    if (!userId) {
        throw new Error('userId required');
    }
    return putAsJson({
        api: `${ConstantesApi.API_ALERTS_PATH}/searches/save`,
        ...params
    });
};
export const deleteAlert = (params) => {
    const { alertId } = params;
    if (!alertId) {
        throw new Error('alertId Required');
    }
    return deleteAsJson({
        api: `${ConstantesApi.API_ALERTS_PATH}/${alertId}`,
        ...params
    });
};
