import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select-latest';
import {sortBy} from 'lodash';

export default class ButtonSwitch extends PureComponent  {

  static propTypes = {
    elements : PropTypes.arrayOf(PropTypes.shape({
        "id": PropTypes.number.isRequired,
        "name": PropTypes.string.isRequired,
        "value": PropTypes.string, //necessary for buttons, ingnored on selects
        "type": PropTypes.string,
        "title": PropTypes.string.isRequired,
        "options": PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        }))
    })).isRequired,
    onSelection: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
        selectedItemId: this.props.elements.length > 0 ? this.props.elements[0].id : null,
        selectedValue: null
    }
    this.onOptionSelection = this.onOptionSelection.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onOptionSelection(option){
    let item = option.item;
    this.setState({
        selectedItemId: item.id,
        selectedValue: option
    });

    this.props.onSelection(item.name, option.value, option.name, 'EN');
  }

  onButtonClick(item){
    this.setState({
        selectedItemId: item.id,
        selectedValue: true       
    });
    this.props.onSelection(item.name, true, item.title);
  }


  render() {
    let elements = sortBy(this.props.elements, "id");
    let size = 12 / (this.props.elements.length ? this.props.elements.length : 1);
    size =  size < 1 ? 1 : size;
    return(<Row className="button-switch-container">
    {
        elements.map((item, key) => {
            if(item.type === "button"){
                return(
                    <Col sm={size} className="button-container" key={item.id+"-"+key}>
                        <div className={"button-switch__button "+(this.state.selectedItemId === item.id ? "button-switch__button--active": "")} onClick={()=>{this.onButtonClick(item)}}>
                            { item.title}
                        </div>
                    </Col>
                );
            }
            return(<Col sm={size} className="button-container" key={item.id+"-"+key}>
            <Select
            isClearable={false}
            isSearchable={false}
            placeholder={item.title}
            options={item.options.map((el)=>{return {...el, item}})}   
            onChange={this.onOptionSelection}             
            className={"form__form-group-select button-switch__select "+(this.state.selectedItemId === item.id ? "button-switch__select--active": "")}
            value={this.state.selectedItemId !== item.id ? item.title : this.state.selectedValue}
            styles={{
                menu: ( defaultStyle,{ data, isDisabled, isFocused, isSelected })=>{
                    return {
                        ...defaultStyle,
                        borderRadius: 0,
                        marginTop: 1
                    };
                },
                menuList: 
                    ( defaultStyle,{ data, isDisabled, isFocused, isSelected })=>{
                        return {
                            ...defaultStyle,
                            borderRadius: 0
                        };
                    },
                option: 
                    ( defaultStyle,{ data, isDisabled, isFocused, isSelected })=>{
                        return {
                            ...defaultStyle,
                            ":hover": {backgroundColor: "#f4f4f4"},
                            backgroundColor: isSelected ? "#f4f4f4" : "white",
                            fontWeight: "normal",
                            fontFamily: "Roboto",
                            color: "#444444",
                            textAlign: "left"                                
                        };
                    },
                    indicatorSeparator: ( defaultStyle,{ data, isDisabled, isFocused, isSelected })=>{
                        return {
                            ...defaultStyle,
                            display:"none"                                
                        };
                    },
                    indicatorsContainer: ( defaultStyle,{ data, isDisabled, isFocused, isSelected })=>{
                        return {
                            ...defaultStyle,
                            padding: 0,
                            ">div":{
                                padding: 0
                            }                                
                        };
                    },
                    control: ( defaultStyle,{ data, isDisabled, isFocused, isSelected })=>{
                        return {
                            ...defaultStyle,                                        
                            padding: 0,
                            boxShadow: "none",
                            ">div" : {
                                minHeight: "unset",
                                position: "initial",
                                padding: "0px 15px"
                            }                        
                        }
                    }
                    
                }}             
            />
        </Col>);
        })
    }
    </Row>);
  }
}
