import { Utils } from "@common/utils/"
import { ACTION_BASE_NAME as ALERT_ACTION_BASE_NAME } from '../../AlertActions';

const ACTION_BASE_NAME = Utils.composeActionName(ALERT_ACTION_BASE_NAME, 'MODAL');

//MODAL ACTION
export const SHOW_MODAL = Utils.composeActionName(ACTION_BASE_NAME, 'SHOW_MODAL');
export const CLOSE_MODAL = Utils.composeActionName(ACTION_BASE_NAME, 'CLOSE_MODAL');

export const showModalAction = () => ({
    type: SHOW_MODAL,
});

export const closeModalAction = () => ({
    type: CLOSE_MODAL
});

