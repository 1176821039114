export const SHOW_LABELS_FORM = 'BUTTON_NEW_LABEL/SHOW_LABELS_FORM';
export const HIDE_LABELS_FORM = 'BUTTON_NEW_LABEL/HIDE_LABELS_FORM';


export function showLabelsForm() {
  return {
    type: SHOW_LABELS_FORM
  };
}

export function hideLabelsForm() {
  return {
    type: HIDE_LABELS_FORM
  };
}