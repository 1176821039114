import React from 'react';
import { Utils } from '@common/utils/';
import { SellerNameButton } from '../SellerNameButton';

const RetailerCell = props => {
    const { product } = props;

    if (!product.storeName) {
        return <div></div>;
    }

    const mkpKey = `mkp-${Utils.getRandomInt(999)}`;
    return (
        <div>
            <div className="retailer-cell__name-container">
                <div className={"store-name " + product.storeNameClassName}>
                   <span >
                       {product.storeName}
                    </span>
                    </div>    
                      {product.isSeller && product.sellerMatchesByStore ? 
                        <div className="mkp-btn-retailer" id='mkp-modal-container'>
                           <SellerNameButton 
                             id={mkpKey}
                             placement='right'
                             trigger='click'
                             iconName='seller-icon'
                             product={product.sellerMatchesByStore}
                             storeName={product.storeName}
                             sellerName={product.sellerName}
                             container="mkp-modal-container"
                             showMessage={true}
                             showHistoric={false}
                            />
                            <span className='minimum-price-seller'>
                              {product.sellerName}
                            </span>
                      </div> : null}
            </div>
        </div>
    );
};

export default RetailerCell;