import React, { useState, useRef, useEffect } from "react";
import { ChurnZero } from "@common/utils/";
import { ArrowBackIos, Info } from "@mui/icons-material";
import "./churn-zero-button.scss";

export function ChurnZeroButton() {
    const [showSuccessCenter, setShowSuccessCenter] = useState(false);

    useEffect(() => {
        const scConfig = { attributes: true, attributeFilter: ["class"] };
        const scObserver = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes") {
                    if (mutation.target.className.includes("cz-sc-open")) {
                        setShowSuccessCenter(true);
                        document.body.classList.add("hide-scroll");
                    } else {
                        setShowSuccessCenter(false);
                        document.body.classList.remove("hide-scroll");
                    }
                }
            }
        });

        const iframeConfig = { childList: true, subtree: true };
        const iframeObserver = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === "childList") {
                    mutation.addedNodes.forEach((node) => {
                        if (
                            node.nodeType === Node.ELEMENT_NODE &&
                            node.matches(
                                ".success-center-panel-frame-container"
                            )
                        ) {
                            scObserver.observe(node, scConfig);
                        }
                    });
                }
            }
        });

        iframeObserver.observe(document.body, iframeConfig);

        return () => {
            iframeObserver.disconnect();
            scObserver.disconnect();
        };
    }, []);

    const toggleSuccessCenter = () => {
        const element = document.querySelector(".cz-sc-open");
        if (element) {
            ChurnZero.close();
        } else {
            ChurnZero.open();
        }
    };

    return (
        <button
            data-testid="churn-zero-button"
            className="churn-zero-button"
            onClick={toggleSuccessCenter}
        >
            {showSuccessCenter ? (
                <ArrowBackIos className="close-icon" />
            ) : (
                <Info />
            )}
        </button>
    );
}
