import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { Constantes, Utils, RouterUtils } from '@common/utils/';
import { SearchBox } from '@common/components/';
import * as WithGenericMainFilter from "@common/components/src/common/hoc/withGenericMainFilter";
import { withRouter } from "react-router";

const formName = 'productListMainFiltersForm';

export class MainFilters extends PureComponent {

  static propTypes = {
    ...WithGenericMainFilter.genericPropTypes,
    currentFilter: PropTypes.object,
    fetchProductItems: PropTypes.func.isRequired,
    fetchFavorites: PropTypes.func,
    lastExecutedFilter: PropTypes.object,
    changeFieldValue: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.fetchProductItems = this.fetchProductItems.bind(this);
    this.searchFieldSubmit = this.searchFieldSubmit.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.hasActiveFilter = this.hasActiveFilter.bind(this);
  }

  fetchProductItems(params, resetCurrentPage, searchType) {
    if (this.props.fetchFavorites) this.props.fetchFavorites();
    let currentFilter = this.props.buildCurrentFilter(params);
    if (resetCurrentPage) {
      currentFilter.paginationCurrentPage = 1;
    }
    this.props.fetchProductItems(currentFilter, searchType);
  }

  searchFieldSubmit() {
    let skus = Utils.getSkuArray(this.props.currentFilter.search);
    this.props.changeFieldValue(Constantes.searchFields.skus, skus);
    this.props.changeFieldValue(Constantes.searchFields.keyWords, "");
    this.props.changeFieldValue(Constantes.searchFields.searchType, Constantes.SearchType.SKUS);
    let searchParams = { search: this.props.currentFilter.search };
    searchParams[Constantes.URL_PARAMS.SEARCH_TYPE] = Constantes.SearchType.SKUS;
    this.fetchProductItems(searchParams, true);
  }

  resetSearch() {
    this.props.changeFieldValue(Constantes.searchFields.main, "");
    this.props.changeFieldValue(Constantes.searchFields.skus, []);
    this.props.changeFieldValue(Constantes.searchFields.keyWords, "");
    this.fetchProductItems({ skus: [], keyWords: "", search: "" }, true);
  }

  hasActiveFilter() {
    const { lastExecutedFilter } = this.props;
    if (!lastExecutedFilter) {
      return false;
    }
    return Utils.hasValue(lastExecutedFilter.keyWords) || Utils.hasValue(lastExecutedFilter.search);
  }

  render() {
    return (
      <Col md={12} lg={12} className="product-list-main-filters-container">
        <Card>
          <SearchBox
            formName={formName}
            onSubmit={this.props.handleSubmit}
            hasActiveSearch={this.hasActiveFilter()}
            onKeyPress={this.props.onKeyPress}
            onButtonClick={this.props.startSearchClick}
            onSearchReset={this.resetSearch}
            showCategories={false}
            buttonText={"Buscar"}
          />
        </Card>
      </Col>
    );
  }
}

export default reduxForm({
  form: formName,
})(withRouter(WithGenericMainFilter.withGenericMainFilter(MainFilters, formName)));

export const mainFilters = WithGenericMainFilter.withGenericMainFilter(MainFilters, formName);
