import { Utils, Constantes } from '@common/utils/';

export const HistoricActionsBuilder = (consumerType) => {

    if (!consumerType) {
        throw 'ConsumerType cant be null';
    }

    const ACTION_BASE_NAME = consumerType;
    const SET_INITIAL_STATE = Utils.composeActionName(ACTION_BASE_NAME, 'SET_INITIAL_STATE');

    const RECEIVE_HISTORIC_DATA = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORIC_DATA');
    const RECEIVE_HISTORIC_DATA_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORIC_DATA_ERROR');
    const RECEIVE_HISTORIC_DATA_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORIC_DATA_ABORT_ERROR');
    const RECEIVE_HISTORIC_DATA_EMPTY = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORIC_DATA_EMPTY');
    const REQUEST_HISTORIC_DATA = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_HISTORIC_DATA');

    const REQUEST_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ERROR');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ABORT_ERROR');

    const REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ERROR');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ABORT_ERROR');

    const REQUEST_LOWER_PRICE_A_YEAR_AGO_ALL_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LOWER_PRICE_A_YEAR_AGO_ALL_STORES');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ERROR');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ABORT_ERROR');

    const REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ERROR');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ABORT_ERROR');

    const REQUEST_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ERROR');
    const RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ABORT_ERROR');

    const REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ERROR');
    const RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ABORT_ERROR');

    const setInitialStateAction = () => ({
        type: SET_INITIAL_STATE
    });

    const requestProductHistoryAction = () => ({
        type: REQUEST_HISTORIC_DATA
    });

    const receiveProductHistoryAction = (data) => ({
        type: RECEIVE_HISTORIC_DATA,
        payload: { ...data }
    });

    const receiveProductHistoryEmptyAction = () => ({
        type: RECEIVE_HISTORIC_DATA_EMPTY,
        payload: {
            empty: true
        }
    });

    const receiveProductHistoryErrorAction = (error) => ({
        type: RECEIVE_HISTORIC_DATA_ERROR,
        payload: {
            error: error
        }
    });

    const receiveProductHistoryAbortErrorAction = (payload) => ({
        type: RECEIVE_HISTORIC_DATA_ABORT_ERROR,
        payload: { ...payload }
    });

    const requestLowerPriceFromMaxHistoricPeriodSelectedStoresAction = () => ({
        type: REQUEST_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES
    });

    const receiveLowerPriceFromMaxHistoricPeriodSelectedStoresAction = (data) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES,
        payload: { ...data.bestPrice }
    });

    const receiveLowerPriceFromMaxHistoricPeriodSelectedStoresErrorAction = (error) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ERROR,
        payload: {
            error: error
        }
    });

    const receiveLowerPriceFromMaxHistoricPeriodSelectedStoresAbortErrorAction = (payload) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ABORT_ERROR,
        payload: { ...payload }
    });


    const requestLowerPriceBetweenSelectedDatesSelectedStoresAction = () => ({
        type: REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES
    });

    const receiveLowerPriceBetweenSelectedDatesSelectedStoresAction = (data) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES,
        payload: { ...data.bestPrice }
    });

    const receiveLowerPriceBetweenSelectedDatesSelectedStoresErrorAction = (error) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ERROR,
        payload: {
            error: error
        }
    });
    const receiveLowerPriceBetweenSelectedDatesSelectedStoresAbortErrorAction = (payload) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ABORT_ERROR,
        payload: { ...payload }
    });

    const requestLowerPriceFromMaxHistoricPeriodAllStoresAction = () => ({
        type: REQUEST_LOWER_PRICE_A_YEAR_AGO_ALL_STORES
    });

    const receiveLowerPriceFromMaxHistoricPeriodAllStoresAction = (data) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES,
        payload: { ...data.bestPrice }
    });

    const receiveLowerPriceFromMaxHistoricPeriodAllStoresErrorAction = (error) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ERROR,
        payload: {
            error: error
        }
    });

    const receiveLowerPriceFromMaxHistoricPeriodAllStoresAbortErrorAction = (payload) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ABORT_ERROR,
        payload: { ...payload }
    });

    const requestLowerPriceBetweenSelectedDatesAllStoresAction = () => ({
        type: REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES
    });

    const receiveLowerPriceBetweenSelectedDatesAllStoresAction = (data) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES,
        payload: { ...data.bestPrice }
    });

    const receiveLowerPriceBetweenSelectedDatesAllStoresErrorAction = (error) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ERROR,
        payload: {
            error: error
        }
    });

    const receiveLowerPriceBetweenSelectedDatesAllStoresAbortErrorAction = (payload) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ABORT_ERROR,
        payload: { ...payload }
    });


    const requestLowerPriceFromMaxHistoricPeriodYourProductAction = () => ({
        type: REQUEST_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT
    });

    const receiveLowerPriceFromMaxHistoricPeriodYourProductAction = (data) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT,
        payload: { ...data.bestPrice }
    });

    const receiveLowerPriceFromMaxHistoricPeriodYourProductErrorAction = (error) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ERROR,
        payload: {
            error: error
        }
    });

    const receiveLowerPriceFromMaxHistoricPeriodYourProductAbortErrorAction = (payload) => ({
        type: RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ABORT_ERROR,
        payload: { ...payload }
    });

    const requestLowerPriceBetweenSelectedDatesYourProductAction = () => ({
        type: REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT
    });

    const receiveLowerPriceBetweenSelectedDatesYourProductAction = (data) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT,
        payload: { ...data.bestPrice }
    });

    const receiveLowerPriceBetweenSelectedDatesYourProductErrorAction = (error) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ERROR,
        payload: {
            error: error
        }
    });

    const receiveLowerPriceBetweenSelectedDatesYourProductAbortErrorAction = (payload) => ({
        type: RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ABORT_ERROR,
        payload: { ...payload }
    });


    return {
        SET_INITIAL_STATE,

        RECEIVE_HISTORIC_DATA,
        RECEIVE_HISTORIC_DATA_ERROR,
        RECEIVE_HISTORIC_DATA_ABORT_ERROR,
        RECEIVE_HISTORIC_DATA_EMPTY,
        REQUEST_HISTORIC_DATA,

        REQUEST_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ERROR,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ABORT_ERROR,

        REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ERROR,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ABORT_ERROR,

        REQUEST_LOWER_PRICE_A_YEAR_AGO_ALL_STORES,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ERROR,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ABORT_ERROR,

        REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ERROR,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ABORT_ERROR,

        REQUEST_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ERROR,
        RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ABORT_ERROR,

        REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ERROR,
        RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ABORT_ERROR,

        setInitialStateAction,
        requestProductHistoryAction,
        receiveProductHistoryAction,
        receiveProductHistoryEmptyAction,
        receiveProductHistoryErrorAction,
        receiveProductHistoryAbortErrorAction,
        requestLowerPriceFromMaxHistoricPeriodSelectedStoresAction,
        receiveLowerPriceFromMaxHistoricPeriodSelectedStoresAction,
        receiveLowerPriceFromMaxHistoricPeriodSelectedStoresErrorAction,
        receiveLowerPriceFromMaxHistoricPeriodSelectedStoresAbortErrorAction,
        requestLowerPriceBetweenSelectedDatesSelectedStoresAction,
        receiveLowerPriceBetweenSelectedDatesSelectedStoresAction,
        receiveLowerPriceBetweenSelectedDatesSelectedStoresErrorAction,
        receiveLowerPriceBetweenSelectedDatesSelectedStoresAbortErrorAction,
        requestLowerPriceFromMaxHistoricPeriodAllStoresAction,
        receiveLowerPriceFromMaxHistoricPeriodAllStoresAction,
        receiveLowerPriceFromMaxHistoricPeriodAllStoresErrorAction,
        receiveLowerPriceFromMaxHistoricPeriodAllStoresAbortErrorAction,
        requestLowerPriceBetweenSelectedDatesAllStoresAction,
        receiveLowerPriceBetweenSelectedDatesAllStoresAction,
        receiveLowerPriceBetweenSelectedDatesAllStoresErrorAction,
        receiveLowerPriceBetweenSelectedDatesAllStoresAbortErrorAction,
        requestLowerPriceFromMaxHistoricPeriodYourProductAction,
        receiveLowerPriceFromMaxHistoricPeriodYourProductAction,
        receiveLowerPriceFromMaxHistoricPeriodYourProductErrorAction,
        receiveLowerPriceFromMaxHistoricPeriodYourProductAbortErrorAction,
        requestLowerPriceBetweenSelectedDatesYourProductAction,
        receiveLowerPriceBetweenSelectedDatesYourProductAction,
        receiveLowerPriceBetweenSelectedDatesYourProductErrorAction,
        receiveLowerPriceBetweenSelectedDatesYourProductAbortErrorAction,
    }
}


export let ComparerHistoricActions = HistoricActionsBuilder(Constantes.HISTORIC_CONSUMER_TYPE.COMPARER);
export let ProductDetailHistoricActions = HistoricActionsBuilder(Constantes.HISTORIC_CONSUMER_TYPE.PRODUCT_DETAIL);