import React from 'react';
import { Utils, RetailCompassStorage, Constantes } from '@common/utils';
import { updateCurrentProduct, updateSort, requestProductDetailOpened } from './TableActions';
import { addProductsToLabel, removeProductsToLabel, resetProductsLabel } from '../labels/LabelsState';
import { fetchProductItems, validateStarCategoriesRequest } from "./TableState";
import { ProductDetailHistoricActions } from '../Historic/HistoricActions';
import * as HeadersBuilder from './HeadersBuilder';
import * as CellsBuilder from './CellsBuilder';

export const buildRetailersHeaders = (retailItems, currentFilter, activeRanking) => {
    if (!Utils.hasValue(retailItems)) {
        return [];
    }

    let totalChars = 5;

    return retailItems.map((retailItem) => {
        const storeId = retailItem.storeId;
        let storeName = retailItem.name;
        let shortStoreName = Utils.firstChars(storeName, totalChars);
        let headerClassName = '';

        if (retailItem.isYourRetail) {
            if (currentFilter.selectedView === Constantes.TYPE_VIEW_RETAIL.SHIPPING) {
                headerClassName = 'header-your-retail-shipping';
            }
            else {
                headerClassName = 'header-your-retail';
             }
            shortStoreName = retailItem.shortStoreName;
        }
        return ({
            key: "retail-" + storeId,
            name: shortStoreName,
            fullName: storeName,
            sortable: false,
            resizable: false,
            className: 'header-retail header-retail-' + storeId,
            accessor: "retail-" + storeId,
            headerClassName: headerClassName,
            Header: (headersProps) => HeadersBuilder.buildRetailHeader(headersProps, { shortStoreName, storeId, storeName }),
            width: 96, //representa el ancho maximo a tener cada celda de precio por retailer
            Cell: (row) => CellsBuilder.buildRetailCell(row, { shortStoreName, storeId, storeName }, currentFilter, activeRanking, {showRankingInfo: false})
        })
    });
}

export const mapStateToProps = (state, ownProps) => {
    const currentFilter = Utils.extractCurrentProductListFilter(state);
    currentFilter.labelsForProductList = state.labelStateReducer.labels || [];
    currentFilter.viewPrices = RetailCompassStorage.getPriceView();

    let retailItems = state.productListBrandReducer.stores || [];

    if (Utils.isArrayWithData(currentFilter.stores)) {
        retailItems = Utils.filterEntities(retailItems, currentFilter.stores, 'storeId')
    }
    return {
        retailsHeaders: [],
        retailItems: retailItems,
        headers: [],
        productItems: state.productListBrandReducer.productItems || [],
        isFetching: state.productListBrandReducer.isFetching,
        paginationCount: state.productListBrandReducer.paginationCount || 0,
        paginationCurrentPage: state.productListBrandReducer.paginationCurrentPage,
        lastFilter: state.productListBrandReducer.lastFilter || {},
        currentFilter: currentFilter,
        currentProduct: state.productListBrandReducer.currentProduct,
        scrollableHeader: true,
        productDetailOpenedProp: state.productListBrandReducer.productDetailOpened,
        stores: state.productListBrandReducer.stores,
        selectedProducts: state.labelStateReducer.newLabel.selectedProducts || [],
        ranges: state.productListBrandReducer.ranges,
        isDownloading: state.productListBrandReducer.isDownloading,
        isDeletingFavorite: state.favoriteStateReducer.isDeleting,
        isCreatingFavorite: state.favoriteStateReducer.isCreating,
        favoriteProductItems: state.favoriteStateReducer.favoriteProductItems,
        visibleLabelsForm: state.buttonNewLabelStateReducer.visibleLabelsForm,
        labels: state.labelStateReducer.labels || [],
        shouldReloadTableFromLabelDeletion: state.labelStateReducer.shouldReloadTable,
        isOpenLabelEditModal: state.labelStateReducer.forceOpenEditModal,
        storeType: RetailCompassStorage.getStoreType(),
        activeRanking: RetailCompassStorage.getActiveRanking() && RetailCompassStorage.isEnableProductRanking() && RetailCompassStorage.isEnableCategoryRanking(), 
        hidePublicLabels: state.labelStateReducer.hidePublicLabels,
        isLoadingStarCategories: state.productListBrandReducer.isLoadingStarCategories,
        showComparerTooltip: state.productListBrandReducer.showComparerTooltip,
        shippingType: state.subRouterStateReducer.shippingType
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params, searchType) => fetchProductItems(dispatch, params, searchType),
        addProductsToLabel: (products) => addProductsToLabel(dispatch, products),
        removeProductsToLabel: (products) => removeProductsToLabel(dispatch, products),
        resetProductsLabel: () => resetProductsLabel(dispatch),
        updateCurrentProduct: (currentProduct) => dispatch(updateCurrentProduct(currentProduct)),
        productDetailOpened: (value) => {
            dispatch(ProductDetailHistoricActions.setInitialStateAction());
            dispatch(requestProductDetailOpened(value));
        },
        updateSort: (sort) => dispatch(updateSort(sort)),
        validateStarCategoriesRequest: (canonicalProducts) => validateStarCategoriesRequest(dispatch, canonicalProducts),
    };
};
