import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import {Link} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { buildPreviousStepUrl } from '../../common/utils/utils';

export class StepPanel extends Component {

    static propTypes = {
        currentStep: PropTypes.number.isRequired, 
        totalSteps: PropTypes.number.isRequired, 
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        urlParams: PropTypes.object
    };

    static defaultProps = {
    };

    constructor(props) {
        super(props);
        this.state = {};
    }


    render(){
        let { currentStep, totalSteps, title, showBackLink } = this.props;
        return (
                <div className="step-panel-container">
                    <div className="step-panel">
                        <div className="step-count">Paso {currentStep} de {totalSteps}</div>
                        <div className="title">{ title }</div>
                    </div>
                    <div className="back-container">
                        {
                            showBackLink ? 
                            <Link className='back-link' to={buildPreviousStepUrl(this.props.urlParams)}>
                                <ArrowBackIcon/> Atrás
                            </Link>: null
                        }                        
                    </div>
                </div>                                  
                )
    }
}
export default StepPanel;