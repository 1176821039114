import ProductListSummary from './ProductListSummary'
import { connect } from 'react-redux';
import * as ButtonNewLabelActions from '../../../labels/label-new-button/ButtonNewLabelActions'
import * as LabelsState from '../../../labels/LabelsState'
import * as FavoriteActions from '../../../favorite/FavoriteActions'
import * as LabelsActions from '../../../labels/LabelsActions'
import * as TableState from '../../../table/TableState'
import * as TableActions from '../../../table/TableActions'
import { SubRouterState } from '@common/utils/';

export const mapStateToProps = (state, ownProps) => {
    return {
        isDownloading: state.productListBrandReducer.isDownloading,
        visibleLabelsForm: state.buttonNewLabelStateReducer.visibleLabelsForm,
        labels: state.labelStateReducer.labels || [],
        lastSearchRequest: state.productListBrandReducer.lastSearchRequest,
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory || {},
        currentRouterView: state.appConfigReducer.currentRouterView,
        paginationCount: state.productListBrandReducer.paginationCount || 0,
        listOptions: ownProps.listOptions || null,
        activeRanking: state.productListBrandReducer.activeRanking,
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params) => TableState.fetchProductItems(dispatch, params),
        resetStoreAction: () => {
            dispatch(TableActions.resetStoreAction());
            dispatch(FavoriteActions.resetStoreAction());
            dispatch(LabelsActions.resetStoreAction());
        },
        downloadFile: (params) => TableState.downloadFile(dispatch, params),
        hideLabelsForm: () => dispatch(ButtonNewLabelActions.hideLabelsForm()),
        resetSelection: () => LabelsState.resetProductsLabel(dispatch),
        fetchListLabels: (params) => LabelsState.fetchListLabels(dispatch, params),
        onSelectedView: (selected) => SubRouterState.onSelectedView(dispatch, selected),
        updateActiveRanking: (value) => dispatch(TableActions.updateActiveRanking(value))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListSummary);
