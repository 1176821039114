import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Utils } from '@common/utils/';

const Loading = () => {
    const { isLoading } = useAuth0();

    return (
        !Utils.shouldIgnoreRoute() && isLoading ?
            <div className="spinner">
                <div className='spinner-logo' />
            </div>
            : null
    );
}

export default Loading;