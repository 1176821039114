import { isArray, filter, includes, sortBy, remove, min } from 'lodash';
import * as Constants from './constantes';
import * as Utils from './utils';
import PurchaseSummaryCard from "@common/components/src/ui/PurchaseSummaryCard";
import { validateIfMatchIsMyProductByBrandsIds } from '../matched-products/common/match';

export const getPaymentTypeFees = (paymentType) => {
    const values = processPaymentType(paymentType);
    if (!values || isNaN(values[0])) {
        return null;
    }
    return parseInt(values[0]);
}

export const getPaymentTypeCard = (paymentType) => {
    const values = processPaymentType(paymentType);
    if (!values) {
        return null;
    }
    if (values.length === 1) {
        return values[0];
    }
    return values[1];
}

export const processPaymentType = (paymentType) => {
    if (!paymentType) {
        return null;
    }
    const values = paymentType.replace(/\'/g, '').split(/(\d+)/).filter(Boolean);
    return values;
}


const setPriceCellClasses = (directMatches, averagePrice) => {
    var classes = {
        min: PurchaseSummaryCard.ContainerClassNames.CHEAPEST_OF_ALL_THE_RETAILER,
        minColor: '#50ea7b',
        halfMin: PurchaseSummaryCard.ContainerClassNames.CLOSEST_TO_THE_CHEAPEST_PRICE,
        halfMinColor: '#bbfbcd',
        halfMax: PurchaseSummaryCard.ContainerClassNames.CLOSEST_TO_THE_MOST_EXPENSIVE_PRICE,
        halfMaxColor: '#ffd0d0',
        max: PurchaseSummaryCard.ContainerClassNames.MOST_EXPENSIVE_OF_ALL_THE_RETAILERS,
        maxColor: '#ffadad',
        noStock: PurchaseSummaryCard.ContainerClassNames.WITHOUT_STOCK,
        noStockColor: '#e0e0e0',
        none: PurchaseSummaryCard.ContainerClassNames.NONE,
        noneColor: '#e0e0e0',
    };

    var matches = sortBy(directMatches, ["price"]);
    remove(matches, function (item) { return item.status === Constants.STATUS_PRODUCT.WITHOUT_STOCK });

    directMatches.forEach(function (item) {
        item.priceCellClass = null;
        item.priceCellColor = null;
        if (item.status === Constants.STATUS_PRODUCT.WITHOUT_STOCK) {
            item.priceCellClass = classes.noStock;
            item.priceCellColor = classes.noStockColor;
            return;
        }
        if (matches.length === 0) {
            item.priceCellClass = classes.min;
            item.priceCellColor = classes.minColor;
            return;
        }
        if (item.price === matches[0].price) {
            item.priceCellClass = classes.min;
            item.priceCellColor = classes.minColor;
        } else if (item.price === matches[matches.length - 1].price) {
            item.priceCellClass = classes.max;
            item.priceCellColor = classes.maxColor;
        } else if (item.price <= averagePrice) {
            item.priceCellClass = classes.halfMin;
            item.priceCellColor = classes.halfMinColor;
        } else {
            item.priceCellClass = classes.halfMax;
            item.priceCellColor = classes.halfMaxColor;
        }
    });
}


export const formatMatchedProducts = (userBrandItems, matchedProducts, requestBody) => {
    if (!isArray(matchedProducts) || matchedProducts.length <= 0) {
        return [];
    }
    userBrandItems = userBrandItems || [];

    return matchedProducts.map((item) => {
        let canonicalProduct = { ...item, competitorsProducts: [] };
        const directMatches = canonicalProduct.directMatches;

        if (isArray(directMatches)) {
            let competitorsProducts = directMatches;

            competitorsProducts = competitorsProducts.map((match) => {
                let paymentType = getPaymentTypeCard(match.paymentType);
                let isPriceWithCard = (paymentType === Constants.PaymentType.CARD);
                let priceWithCard = null;
                let priceWithoutCard = null;
                let isMyProduct = false;
                let fees = getPaymentTypeFees(match.paymentType);

                match.paymentType = paymentType;

                if (isPriceWithCard) {
                    priceWithCard = match.price;
                } else {
                    priceWithoutCard = match.price;
                }

                isMyProduct = validateIfMatchIsMyProductByBrandsIds(userBrandItems, match);

                // we assume that when a product has a status equal to -1, it is not updated...
                // therefore, we calculate the days without stock, using the `updated` field as criteria
                let daysWithoutStock = -1;
                if (match.statusProduct === Constants.STATUS_PRODUCT.WITHOUT_STOCK) {
                    daysWithoutStock = Utils.getDaysFromToday(match.updated);
                }

                return {
                    ...match,
                    id: match.productId,
                    status: match.statusProduct,
                    isPriceWithCard: isPriceWithCard,
                    priceWithCard: priceWithCard,
                    priceWithoutCard: priceWithoutCard,
                    fees: fees,
                    name: match.productName,
                    isMyProduct: isMyProduct,
                    daysWithoutStock: daysWithoutStock
                }
            });

            canonicalProduct.isMyProduct = validateIfMatchIsMyProductByBrandsIds(userBrandItems, canonicalProduct);
            if (!canonicalProduct.isMyProduct) {
                canonicalProduct.isMyProduct = competitorsProducts.filter((item) => item.isMyProduct).length > 0;
            }

            let allCompetitorsProducts = competitorsProducts;

            if (requestBody.allowedToFilterDirectMatches) {
                const storeIds = Utils.getStoresOrEmpty(requestBody);
                if (storeIds.length > 0) {
                    competitorsProducts = filter(competitorsProducts, (match) => (includes(storeIds, match.storeId)));
                }
            }

            let averagePrice = 0;
            let minimumPrice = 0;
            const competitorsPrices = competitorsProducts
                .filter((item) => (item.status === Constants.STATUS_PRODUCT.WITH_STOCK))
                .map((item) => (item.price));

            if (competitorsPrices.length > 0) {
                averagePrice = competitorsPrices.reduce((a, b) => (a + b), 0) / competitorsPrices.length;
                minimumPrice = min(competitorsPrices);
            }

            canonicalProduct.averagePrice = Utils.roundNumber(averagePrice, 0);
            canonicalProduct.minimumPrice = Utils.roundNumber(minimumPrice, 0);
            canonicalProduct.daysInMarket = Utils.getDaysFromToday(canonicalProduct.created);
            canonicalProduct.competitorsProducts = competitorsProducts;
            setPriceCellClasses(canonicalProduct.competitorsProducts, averagePrice);
            canonicalProduct.allCompetitorsProducts = allCompetitorsProducts;

        }
        return {
            ...canonicalProduct
        }
    });
}
