import React, { Component } from 'react'
import CheckBoxField from "@common/components/src/ui/CheckBox";
import PropTypes from 'prop-types';

export default class CheckboxLabel extends Component {

    static propTypes = {
        productId: PropTypes.number,
        selectedProducts: PropTypes.array,
        addProductsToLabel: PropTypes.func,
        removeProductsToLabel: PropTypes.func,
        showLabelsForm: PropTypes.func,
        shouldTriggerLabelsForm: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getCheckValue = this.getCheckValue.bind(this);
    };


    onChange(e) {
        if (!e) return;
        const target = e.target;
        const checked = target.checked;
        if (checked) {
            this.props.addProductsToLabel([this.props.productId]);
            if(this.props.shouldTriggerLabelsForm){
                this.props.showLabelsForm();
            }
        }else{
            this.props.removeProductsToLabel([this.props.productId])
        }
    }

    getCheckValue() {
        return this.props.selectedProducts && this.props.selectedProducts.indexOf(this.props.productId) > -1;
    }

    render() {
        return (
            <div className={"checkbox-label "} onClick={(e) => e.stopPropagation()}>
                <CheckBoxField
                    value={this.getCheckValue()}
                    label={null}
                    className='compass'
                    input={{
                        value: this.getCheckValue(),
                        name: "check-" + this.props.productId,
                        onChange: this.onChange
                    }}
                />
            </div>
        )
    }
}
