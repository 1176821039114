import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestSuggestedMatches = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: true,
        isFetchingSuggestedMatches: true,
        suggestedMatches: []
    });
}

export const receiveSuggestedMatches = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: false,
        isFetchingSuggestedMatches: false,
        suggestedMatches: action.payload.suggestedMatches
    });
}

export const receiveSuggestedMatchesError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: false,
        isFetchingSuggestedMatches: false,
        suggestedMatches: []
    });
}

const handlers = {
    [ValidationActions.REQUEST_SUGGESTED_MATCHES]: requestSuggestedMatches,
    [ValidationActions.RECEIVE_SUGGESTED_MATCHES]: receiveSuggestedMatches,
    [ValidationActions.RECEIVE_SUGGESTED_MATCHES_ERROR]: receiveSuggestedMatchesError,
}

export default handlers