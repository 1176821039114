import React from "react";
import { AttributesUtils, Utils } from "@common/utils/";
import DynamicAttributeFieldLayout from "./DynamicAttributeFieldLayout";
import MultiOptionButtonGroup from "../ui/MultiOptionButtonGroup";
import CheckboxList from "../ui/CheckboxList";

let buildDynamicAttributeWithLayout = (attribute, component) => {
    return (
        <DynamicAttributeFieldLayout
            fieldLabel={AttributesUtils.attributeTitleFromAttribute(attribute)}
            fieldClass={attribute.visualization.containerClass}
            showRemoveFilters={attribute.selectedData.length > 0}
            key={"attribute-" + attribute.name}
        >
            {component}
        </DynamicAttributeFieldLayout>
    );
};

export const buildDynamicAttributeFilter = (attribute, onChangeValue) => {
    if (
        attribute.visualization &&
        Utils.isAttributeVisibleFromCountryCodeAndUserType(
            attribute.visualization.visible
        )
    ) {
        switch (attribute.visualization.componentType) {
            case "multiOptionButtonGroup": {
                return buildDynamicAttributeWithLayout(
                    attribute,
                    <MultiOptionButtonGroup
                        rangeItems={attribute.filterItems}
                        ref={React.createRef()}
                        selectedRangeItems={attribute.selectedData}
                        totalItemsByLine={6}
                        key={Utils.generateUniqueID()}
                        onChange={value => {
                            onChangeValue(attribute, value);
                        }}
                    />
                );
            }
            case "checkboxList": {
                if (!attribute.filterItems.length) {
                    return null;
                }
                return buildDynamicAttributeWithLayout(
                    attribute,
                    <CheckboxList
                        items={attribute.filterItems}
                        ref={React.createRef()}
                        checkedItems={attribute.selectedData}
                        fieldName={attribute.name}
                        keyName={AttributesUtils.attributeComponentKeyNameFromType(
                            attribute
                        )}
                        onChange={(items, item) => {
                            onChangeValue(attribute, items);
                        }}
                    />
                );
            }
            default:
                return null;
        }
    }
};
