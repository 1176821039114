import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Utils } from "@common/utils";
import CreditCardIcon from 'mdi-react/CreditCardOutlineIcon';
import { SellerNameButton } from '../common/components/SellerNameButton';


export default class HistoricSummary extends PureComponent {

    static propTypes = {
        title: PropTypes.string.isRequired,
        historicPrice: PropTypes.number.isRequired,
        historicStore: PropTypes.string.isRequired,
        historicDate: PropTypes.string.isRequired,
        historicIsMine: PropTypes.bool,
        periodPrice: PropTypes.number.isRequired,
        periodStore: PropTypes.string.isRequired,
        periodDate: PropTypes.string.isRequired,
        period: PropTypes.string.isRequired,
        periodIsMine: PropTypes.bool,
        hideTitle: PropTypes.bool,
        containerClassName: PropTypes.string,
        isFetchingPeriod: PropTypes.bool,
        isFetchingHistoric: PropTypes.bool,
        historicIsSeller: PropTypes.bool,
        periodIsSeller: PropTypes.bool,
        periodIsCardPrice: PropTypes.bool,
        historicIsCardPrice: PropTypes.bool,
        historicSellerName: PropTypes.string,
        periodSellerName: PropTypes.string
    };

    static defaultProps = {
        isFetchingPeriod: false,
        isFetchingHistoric: false
    }

    constructor(props) {
        super(props);
        this.state = {
        };
        this.buildSubsectionPrice = this.buildSubsectionPrice.bind(this);
        this.buildSubsectionDetail = this.buildSubsectionDetail.bind(this);
    }

    buildSubsectionPrice(isMine, price, isSeller, sellerName, isCardPrice) {
        if (price > 0) {
            const mkpKey = `mkp-${Utils.getRandomInt(999)}`;
            return (
                <div className={"summary-subsection--price" + (isMine ? " mine" : "")}>
                    {isCardPrice ? <CreditCardIcon className='card-icon' size={14} /> : null}
                    {Utils.formatPrice(price)}
                     {isSeller ? 
                    <span id="mkp-modal-container-popover" className="mkp-btn-container">
                        <SellerNameButton id={mkpKey} placement='top' trigger='click' iconName='seller-icon' container="mkp-modal-container-popover" showHistoric={true} sellerName={sellerName}/>
                    </span> : null} 
                </div>
            )
        }
        return (<div className={"summary-subsection--price unavailable"}>
            No disponible
        </div>)
    }

    buildSubsectionDetail(isMine, store, date, price){        
        if(price > 0){
            return (<div className="summary-subsection--detail">
                        <span className={isMine ? " mine" : ""}>
                            {store}</span> - {date}
                    </div>)
        }
        return (<div className="summary-subsection--detail">
                    Periodo sin datos
                </div>)        
    }

    buildSubSection(params){
        if(params.isFetching){
            return (<div className={"summary-subsection summary-subsection__blank"}></div>)
        }
        return(
            <div className="summary-subsection">
                <div className="summary-subsection--title">{params.title}</div>
                    {this.buildSubsectionPrice(params.isMine, params.price, params.isSeller, params.sellerName, params.isCardPrice)}
                    {this.buildSubsectionDetail(params.isMine, params.store, params.date, params.price) }
            </div>
        );
    }
  
    render() {
        let { hideTitle, containerClassName } = this.props;

        let historicData = { 
                title: this.props.historicTitle || "Menor precio histórico",            
                isMine: this.props.historicIsMine,
                price: this.props.historicPrice,
                store: this.props.historicStore,
                date: this.props.historicDate,
                isFetching: this.props.isFetchingHistoric,
                isSeller: this.props.historicIsSeller,
                sellerName: this.props.historicSellerName,
                isCardPrice: this.props.historicIsCardPrice
            },
            periodData = {
                title: "Menor precio "+this.props.period,
                isMine: this.props.periodIsMine,
                price: this.props.periodPrice,
                store: this.props.periodStore,
                date: this.props.periodDate,
                isFetching: this.props.isFetchingPeriod,
                isSeller: this.props.periodIsSeller,
                sellerName: this.props.periodSellerName,
                isCardPrice: this.props.periodIsCardPrice
            };

        return (<div className={"historic-summary-container" + (containerClassName ? ' ' + containerClassName : '')}>

            {hideTitle ? null : <div className="summary-title">{this.props.title}</div>}

            <div className="summary-subsections-container clearfix">
                {this.buildSubSection(historicData)}
                {this.buildSubSection(periodData)}                
            </div>
        </div>)
    }
}

