import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select-latest';
import { isEqual } from 'lodash';

export default class SortingSelect extends Component {

    static propTypes = {
        options: PropTypes.array.isRequired,
        placeholder: PropTypes.string,
        overflowY: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        defaultValue: PropTypes.object,
        value: PropTypes.object,
        containerClassName: PropTypes.string,
        singleValuePrefix: PropTypes.string,
        enableHandleCloseMenuOnScroll: PropTypes.bool
    };

    static defaultProps = {
        enableHandleCloseMenuOnScroll: true
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedValue: props.input.value,
            isOpenMenu: false
        };
        this.onChange = this.onChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (!isEqual(nextProps.input.value, this.props.input.value)) {
            this.setState({ selectedValue: nextProps.input.value })
        }
        return true;
    }

    onClick(e) {
        e.stopPropagation();
    };

    onChange(value) {
        this.setState({
            selectedValue: value
        });
        this.props.onChange(value);
    }

    handleCloseMenuOnScroll = (event) => {
        if (event.target && event.target.parentNode) {
            const items = event.target.parentNode.className.trim().split("-");
            const isScrollOverMenu = items.includes("css") && items.includes("menu");
            return !isScrollOverMenu;
        }
        return true;
    }

    render() {
        return (<div className={'form form--vertical ' + (this.props.containerClassName || '')}>
            <Select
                isClearable={false}
                isSearchable={false}
                name={this.props.name}
                placeholder={this.props.placeholder}
                defaultValue={this.props.defaultValue}
                value={this.state.selectedValue}
                onChange={this.onChange}
                options={this.props.options}
                className={"form__form-group-select " + (this.state.isOpenMenu && "z-index-98")}
                menuPosition="fixed"
                onMenuOpen={() => this.setState({ isOpenMenu: true })}
                onMenuClose={() => this.setState({ isOpenMenu: false })}
                closeMenuOnScroll={this.props.enableHandleCloseMenuOnScroll && this.handleCloseMenuOnScroll}
                styles={{
                    menu: (defaultStyle) => {
                        return {
                            ...defaultStyle,
                            borderRadius: 0,
                            marginTop: 1
                        };
                    },
                    menuList:
                        (defaultStyle) => {
                            defaultStyle.overflowY = 'auto';
                            return {
                                ...defaultStyle,
                                borderRadius: 0
                            };
                        },
                    option:
                        (defaultStyle, state) => {
                            return {
                                ...defaultStyle,
                                ":hover": { backgroundColor: "#f4f4f4" },
                                backgroundColor: state.isSelected ? "#f4f4f4" : "white",
                                fontWeight: "normal",
                                fontFamily: "Roboto",
                                color: "#444444",
                                textAlign: "left"
                            };
                        },
                    indicatorSeparator: (defaultStyle) => {
                        return {
                            ...defaultStyle,
                            display: "none"
                        };
                    },
                    indicatorsContainer: (defaultStyle) => {
                        return {
                            ...defaultStyle,
                            padding: 0,
                            ">div": {
                                padding: 0
                            }
                        };
                    },
                    control: (defaultStyle) => {
                        return {
                            ...defaultStyle,
                            height: 32,
                            minHeight: "unset",
                            position: "initial",
                            fontWeight: "normal",
                            fontFamily: "Roboto",
                            color: "#555555",
                            padding: 0,
                            boxShadow: "none",
                            borderColor: "#eaeaea",
                            ">div": {
                                height: 32,
                                minHeight: "unset",
                                position: "initial",
                                padding: "0px 4px"
                            },
                            ":hover": {
                                borderColor: "#eaeaea",
                            }
                        }
                    },
                    singleValue: (defaultStyle) => {
                        return {
                            ...defaultStyle,
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            color: '#555555',
                            '.prefix': {
                                fontWeight: '300',
                            }
                        }
                    }
                }}
                components={{
                    SingleValue: ({ children, ...props }) => {
                        return (
                            <components.SingleValue {...props}>
                                {this.props.singleValuePrefix &&
                                    <span className='prefix'>{this.props.singleValuePrefix}</span>
                                }
                                {children}
                            </components.SingleValue>
                        );
                    }
                }}
            />
        </div>)
    }
}
