import { ProductListService, ProductService, MatchesService, ProductAttributeService, HomologationAttributesService } from '@common/data-access';
import * as ValidationActions from './ValidationActions';
import { MatchInputBuilder } from "../common/utils/matchInput";
import reducerHandlersCases from './reducer_handlers_cases';
import { Constantes } from "@common/utils/";

export const initialState = {
    isFetching: false,
    currentProduct: null,
    suggestedMatches: [],
    potentialMatches: [],
    isTransformingTheMatch: false,
    isRemovingTheMatch: false,
    hasError: false,
    error: null,
    isCreatingNewMatch: false,
    allMatches: [],
    isFetchingSuggestedMatches: false,

    canonicalIsTransformingIntoRemoved: false,
    canonicalIsTransformingIntoCanonical: false,
    canonicalIsFindingPotentialDuplicates: false,
    canonicalIsSearchingByKeywords: false,
    canonicalIsMerging: false,
    canonicalPotentialDuplicates: [],
    canonicalSearchResult: [],
    canonicalIsMarkAsReviewed: false,

    potentialMatchesEmptyState: false,
    canonicalSearchResultEmptyState: false,

    isSavingRelationshipProductAttributeNewLov: false,
    hasErrorSavingRelationshipProductAttributeNewLov: false,

    isFetchingHomologationAttributes: false,
    hasErrorFetchingHomologationAttributes: false
};

let abortController = new window.AbortController();

export const fetchProduct = (dispatch, payload) => {
    payload = payload || {};

    if (!payload.productId) {
        return;
    }

    abortController.abort();
    abortController = new window.AbortController();

    const currentProductId = payload.productId;

    const receiveCallback = (response) => {
        return ValidationActions.receiveFindMatchedFullProductAction({
            response: response,
            requestBody: {
                productId: currentProductId
            }
        })
    };

    const receiveAbortErrorCallback = (errorInfo) => {
        return ValidationActions.receiveFindMatchedFullProductAbortErrorAction(errorInfo);
    };

    const signal = abortController.signal;
    return ProductService.findMatchedProductById(currentProductId, {
        signal,
        dispatch,
        requestCallback: ValidationActions.requestFindMatchedFullProductAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveFindMatchedFullProductErrorAction,
        receiveAbortErrorCallback: receiveAbortErrorCallback
    });
}


export const searchPotentialMatches = (dispatch, matchInputParams) => {
    matchInputParams = matchInputParams || new MatchInputBuilder().build();

    let body = {
        ...matchInputParams
    }

    const receiveCallback = (response) => {
        return ValidationActions.receiveProductSearchPotentialMatchesAction({
            response: response,
            requestBody: {
                ...body,
            }
        })
    };

    return ProductListService.fetchPotentialMatches({
        dispatch,
        data: body,
        requestCallback: ValidationActions.requestProductSearchPotentialMatchesAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveProductSearchPotentialMatchesErrorAction
    });
}


export const saveProductInfo = (dispatch, payload) => {
    payload = payload || {};
    const { productId, modelo } = payload;

    if (!productId || !modelo) {
        throw new Error('invalid data to save');
    }

    let data = {
        modelo
    }

    return ProductService.saveProductInfo(productId, {
        dispatch,
        data,
        requestCallback: ValidationActions.requestSaveProductInfoAction,
        receiveCallback: ValidationActions.receiveSaveProductInfoAction,
        receiveErrorCallback: ValidationActions.receiveSaveProductInfoErrorAction
    })
}

export const getSuggestedMatchesByProductId = (dispatch, productId) => {
    if (!productId) {
        return;
    }
    return MatchesService.getSuggestedMatchesByProductId(productId, {
        dispatch,
        requestCallback: ValidationActions.requestSuggestedMatchesAction,
        receiveCallback: ValidationActions.receiveSuggestedMatchesAction,
        receiveErrorCallback: ValidationActions.receiveSuggestedMatchesErrorAction
    })
}


export const transformIntoIdentical = (dispatch, payload) => {
    let { data, match } = payload;

    const receiveCallback = (response) => {
        return ValidationActions.receiveTransformIntoIdenticalAction({
            response: response,
            requestBody: {
                ...payload,
                match: match
            }
        })
    };

    return MatchesService.transformIntoIdentical({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestTransformIntoIdenticalAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveTransformIntoIdenticalErrorAction
    })
}

export const transformIntoRemoved = (dispatch, payload) => {
    let { data, match } = payload;
    const receiveCallback = (response) => {
        return ValidationActions.receiveTransformIntoRemovedAction({
            response: response,
            requestBody: {
                ...payload,
                match: match
            }
        })
    };

    return MatchesService.transformIntoRemoved({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestTransformIntoRemovedAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveTransformIntoRemovedErrorAction
    })
}

export const removeIdentical = (dispatch, payload) => {
    let { data, match } = payload;
    const receiveCallback = (response) => {
        return ValidationActions.receiveRemoveIdenticalAction({
            response: response,
            requestBody: {
                ...payload,
                match: match
            }
        })
    };

    return MatchesService.transformIntoRemoved({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestRemoveIdenticalAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveRemoveIdenticalErrorAction
    })
}


export const createNewMatch = (dispatch, payload) => {
    const { match, matchEntity } = payload;
    const receiveCallback = (response) => {
        return ValidationActions.receiveCreateNewMatchAction({
            response: response,
            requestBody: {
                ...payload,
                match: match
            }
        })
    };
    return MatchesService.createNewMatch({
        data: matchEntity,
        dispatch,
        requestCallback: ValidationActions.requestCreateNewMatchAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCreateNewMatchErrorAction
    })
}

export const getAllMatchesByProductId = (dispatch, productId) => {
    if (!productId) {
        return;
    }
    return MatchesService.getAllMatchesByProductId(productId, {
        dispatch,
        requestCallback: ValidationActions.requestAllMatchesAction,
        receiveCallback: ValidationActions.receiveAllMatchesAction,
        receiveErrorCallback: ValidationActions.receiveAllMatchesErrorAction
    })
}

export const canonicalFindPotentialDuplicates = (dispatch, competitorsIds, canonicalProductIdsToExclude) => {
    let data = { competitorsIds, canonicalProductIdsToExclude };
    const receiveCallback = (response) => {
        return ValidationActions.receiveCanonicalPotentialDuplicatesAction({
            response: response,
            requestBody: {
            }
        })
    };

    return ProductService.canonicalFindPotentialDuplicates({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestCanonicalPotentialDuplicatesAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCanonicalPotentialDuplicatesErrorAction
    })
}

export const canonicalSearchByKeywords = (dispatch, storeId, categoryId, keywords, canonicalProductIdsToExclude) => {
    let data = { storeId, categoryId, keywords, canonicalProductIdsToExclude };
    const receiveCallback = (response) => {
        return ValidationActions.receiveCanonicalSearchByKeywordsAction({
            response: response,
            requestBody: {
                ...data
            }
        })
    };

    return ProductService.canonicalSearchByKeywords({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestCanonicalSearchByKeywordsAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCanonicalSearchByKeywordsErrorAction
    })
}

export const canonicalTransformIntoRemoved = (dispatch, productId, jobId, userId) => {
    let data = { productId, jobId, userId };
    const receiveCallback = (response) => {
        return ValidationActions.receiveCanonicalTransformIntoRemovedAction({
            response: response,
            requestBody: {
                ...data
            }
        })
    };

    return ProductService.canonicalTransformIntoRemoved({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestCanonicalTransformIntoRemovedAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCanonicalTransformIntoRemovedErrorAction
    })
}

export const canonicalMerge = (dispatch, mergeCanonicalInput) => {
    if (!mergeCanonicalInput) {
        return;
    }

    let requestBody = { ...mergeCanonicalInput };

    const receiveCallback = (response) => {
        return ValidationActions.receiveCanonicalMergeAction({
            response: response,
            requestBody: {
                ...requestBody
            }
        })
    };

    return ProductService.canonicalMerge({
        data: requestBody,
        dispatch,
        requestCallback: ValidationActions.requestCanonicalMergeAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCanonicalMergeErrorAction
    })
}



export const canonicalTransformIntoCanonical = (dispatch, productId, jobId, userId) => {
    let data = { productId, jobId, userId, producer: Constantes.PRODUCERS.SCALIBUR_VALIDATION };
    const receiveCallback = (response) => {
        return ValidationActions.receiveCanonicalTransformIntoCanonicalAction({
            response: response,
            requestBody: {
                ...data
            }
        })
    };

    return ProductService.canonicalTransformIntoCanonical({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestCanonicalTransformIntoCanonicalAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCanonicalTransformIntoCanonicalErrorAction
    })
}

export const canonicalMarkAsReviewed = (dispatch, productId, jobId, userId) => {
    let data = { productId, jobId, userId, producer: Constantes.PRODUCERS.SCALIBUR_VALIDATION };
    const receiveCallback = (response) => {
        return ValidationActions.receiveCanonicalMarkAsReviewedAction({
            response: response,
            requestBody: {
                ...data
            }
        })
    };

    return ProductService.canonicalMarkAsReviewed({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestCanonicalMarkAsReviewedAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCanonicalMarkAsReviewedErrorAction
    })
}

export const saveRelationshipProductAttributeNewLov = (dispatch, productId, attributeId, valueName, userId) => {
    let data = { productId, attributeId, valueName, userId };
    const receiveCallback = (response) => {
        return ValidationActions.receiveCreateProductAttributeNewLovsAction({
            response: response,
            requestBody: {
                ...data
            }
        })
    };

    return ProductAttributeService.saveRelationshipProductAttributeNewLov({
        data: data,
        dispatch,
        requestCallback: ValidationActions.requestCreateProductAttributeNewLovsAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveCreateProductAttributeNewLovsErrorAction
    })
}

export const fetchHomologationAttributes = (dispatch, productId, attributeId) => {
    let body = {
        productId, attributeId
    }

    const receiveCallback = (response) => {
        return ValidationActions.receiveFetchHomologationAttributesAction({
            response: response,
            requestBody: {
                ...body
            }
        })
    };

    return HomologationAttributesService.fetchHomologationAttributes(productId, attributeId, {
        dispatch,
        requestCallback: ValidationActions.requestFetchHomologationAttributesAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ValidationActions.receiveFetchHomologationAttributesErrorAction
    });
}


export const ValidationStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};
    return reducerHandlersCases(state, action) || state;
};