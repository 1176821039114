import { change } from 'redux-form';
import { Utils, Constantes,RetailCompassStorage } from '@common/utils/';

/**
 * función útil que es usada tanto en el modal de eliminación de labels como en la minificha de productos
 * el objetivo es que una vez que se confirme que un label ha sido eliminado, se debe hacer:
 * 1. eliminar el label de redux
 * 2. enviar a redux-form el nuevo valor del listado de labels para que currentFilter sea actualizado
 * 3. hacer un llamado a product-search para recargar el listado de productos
 * 
 * @param {*} dispatch función para propagar acciones de redux
 * @param {*} fetchProductItems función para hacer llamado a product-search
 * @param {*} currentFilter últimos filtros seleccionados por el usuario
 */
export const receiveDeleteLabelResponseCallback = ({ dispatch, fetchProductItems, currentFilter }) => {
    return (response) => {
        currentFilter = currentFilter || {};
        let params = { ...currentFilter };
        if (response && response.labelWasRemoved && response.labelId) {
            params.labels = params.labels || [];
            if (Utils.isArrayWithData(params.labels)) {
                params.labels = params.labels.filter(
                    item => String(item).split("_")[0] !== String(response.labelId)
                );
            }
            dispatch && dispatch(change(Constantes.FORM_NAMES.FILTERS_FORM, 'labels', params.labels))
        }
        fetchProductItems && fetchProductItems(params);
    }
}

export const handleRemoveProductLabelClick = ({ label, dispatch, fetchProductItems, currentFilter,
    removeLabel, removeProductFromLabel, productId, receiveDeleteLabelResponseCallback }) => {

    const _receiveDeleteLabelResponseCallback = receiveDeleteLabelResponseCallback({
        dispatch: dispatch, fetchProductItems: fetchProductItems, currentFilter: currentFilter
    });

    if (removeLabel && removeProductFromLabel) {
        let isTheLatestProductInTheLabel = (Utils.isArrayWithData(label.labelProducts) && label.labelProducts.length === 1);
        if (isTheLatestProductInTheLabel) {
            removeLabel({ label, receiveDeleteLabelResponseCallback: _receiveDeleteLabelResponseCallback })
        } else {
            removeProductFromLabel(label.id, productId)
        }
    }
}

export const receiveUpdateAccessTypeCallback = ({
    dispatch,
    fetchProductItems,
    currentFilter,
    label
}) => {
    const hiddenPublicLabels = RetailCompassStorage.getHidePublicLabels();
    console.log({ hiddenPublicLabels });
    const params = { ...(currentFilter || {}) };
    params.labels = params.labels || [];
    params.labels = params.labels
        .map(id => {
            const _id = String(id).split("_")[0];
            if (_id === String(label.id)) {
                return `${label.id}_${label.accessType}`;
            } else {
                return id;
            }
        })
        .filter(id => {
            const split = String(id).split("_");
            if (split.length > 1) {
                const _accessType = split[1];
                return (
                    !hiddenPublicLabels ||
                    Number(_accessType) === Constantes.ACCESS_TYPES_LABEL.PRIVATE
                );
            } else {
                return true;
            }
        });
    dispatch &&
        dispatch(
            change(Constantes.FORM_NAMES.FILTERS_FORM, "labels", params.labels)
        );
    fetchProductItems && fetchProductItems(params);
};
