import { connect } from 'react-redux';
import ButtonNewLabel from './ButtonNewLabel';
import { showLabelsForm, hideLabelsForm } from './ButtonNewLabelActions'
import { SubRouterState } from '@common/utils';

const mapStateToProps = (state, ownProps) => {

    return {
        visibleLabelsForm: state.buttonNewLabelStateReducer.visibleLabelsForm,
        selectedView: state.subRouterStateReducer.selectedView
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        showLabelsForm: () => dispatch(showLabelsForm()),
        hideLabelsForm: () => dispatch(hideLabelsForm()),
        onSelectedView: (selected) => SubRouterState.onSelectedView(dispatch, selected)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonNewLabel);