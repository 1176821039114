import * as LabelsActions from "./LabelsActions";
import { LabelsService } from '@common/data-access/';
import { Constantes, RetailCompassStorage } from "@common/utils/"
import { LabelsUtils } from '@common/utils/';
import { mustForceProductListUpdateAction } from '../table/TableActions';
import { filter, get } from "lodash"


export const DEFAULT_NEW_LABEL = {
    name: "",
    color: "",
    selectedProducts: []
};

export const initialState = {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    form: {},
    isCreated: false,
    isDeleted: false,
    hasError: null,
    error: null,
    newLabel: { ...DEFAULT_NEW_LABEL },
    labels: [],
    forceOpenEditModal: false,
    shouldReloadTable: true,
    isEditModalOpen: false,
    labelsNeedToBeSynchronized: false,
    isFetchingRefreshUpdateDate: false,
    hidePublicLabels: RetailCompassStorage.getHidePublicLabels()
};

export const addItemsToList = (items, newItems) => {
    items = items || [];
    let returnList = [...items];
    newItems = newItems || [];
    newItems.forEach((newItem) => {
        if (returnList.indexOf(newItem) === -1) {
            returnList.push(newItem)
        }
    })

    return returnList;
}

export const addProductsToLabel = (dispatch, products) => {
    dispatch(LabelsActions.addProductsToLabel(products));
}
export const removeProductsToLabel = (dispatch, products) => {
    dispatch(LabelsActions.removeProductsToLabel(products));
}
export const changeCategory = (dispatch) => {
    dispatch(LabelsActions.changeCategory());
}
export const resetProductsLabel = (dispatch) => {
    dispatch(LabelsActions.resetProductsLabel());
}
export const hidePublicLabels = (dispatch, value) => {
    dispatch(LabelsActions.hidePublicLabelsAction(value));
}

export const fetchListLabels = (dispatch, payload) => {
    if (!payload || !payload.categoryId) {
        return;
    }

    const currentUser = RetailCompassStorage.getUser();
    const userId = currentUser.id;
    const storeId = currentUser.currentStore.id;
    
    let body = {
        includeProducts: true,
        categoryId: payload.categoryId,
        storeId
    }

    const receiveCallback = (response) => {
        return LabelsActions.receiveLabelsUserAction({
            response: response,
            requestBody: {
                ...body
            }
        })
    };

    const receiveErrorCallback = (payload) => {
        return LabelsActions.receiveLabelsUserErrorAction(payload);
    };

    return LabelsService.fetchList(userId, {
        dispatch,
        data: body,
        requestCallback: LabelsActions.requestLabelsUserAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: receiveErrorCallback
    });
}

export const fetchCreate = (dispatch, payload) => {
    if (!payload || !payload.categoryId || !payload.productIds) {
        return;
    }
    const currentUser = RetailCompassStorage.getUser();
    const storeId = RetailCompassStorage.getStoreId();
    let userId = currentUser.id;

    let buildPayload = (response, request) => ({
        response: response,
        requestBody: {
            ...request
        }
    });

    let receiveCallback = (response) => {
        fetchListLabels(dispatch, payload);
        return LabelsActions.receiveLabelCreateAction(buildPayload(response, payload));
    };

    let receiveErrorCallback = (response) => {
        let action = LabelsActions.receiveLabelCreateErrorAction(response);
        action.payload = buildPayload({ error: action.payload.error }, payload);
        return action;
    };

    let body = {
        categoryId: payload.categoryId,
        name: payload.name,
        storeId,
        color: payload.color,
        productIds: payload.productIds,
        accessType: payload.accessType,
    };

    return LabelsService.fetchCreate(userId, {
        dispatch,
        data: body,
        requestCallback: LabelsActions.requestLabelCreateAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: receiveErrorCallback
    });
};
export const patchLabel = (dispatch, payload) => {
    if (!payload || !payload.labelId || !payload.productIds) {
        return;
    }
    const currentUser = RetailCompassStorage.getUser();
    const categoryId = RetailCompassStorage.getCurrentCanonicalCategory().id;
    let userId = currentUser.id;

    let buildPayload = (response, request) => ({
        response: response,
        requestBody: {
            ...request
        }
    });

    let receiveCallback = (response) => {
        fetchListLabels(dispatch, { categoryId });
        return LabelsActions.receiveLabelPatchAction(buildPayload(response, payload));
    };

    let receiveErrorCallback = (response) => {
        let action = LabelsActions.receiveLabelPatchErrorAction(response);
        action.payload = buildPayload({ error: action.payload.error }, payload);
        return action;
    };

    let body = {
        productIds: payload.productIds
    };

    return LabelsService.patchLabel(userId, payload.labelId, {
        dispatch,
        data: body,
        requestCallback: LabelsActions.requestLabelPatchAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: receiveErrorCallback
    });
};


export const fetchDelete = (dispatch, payload) => {
    payload = payload || {};
    const currentUser = RetailCompassStorage.getUser();
    let userId = currentUser.id;

    let buildPayload = (response, request) => ({
        response: response,
        requestBody: {
            ...request,
            labelId: payload.label.labelId
        }
    });

    let receiveCallback = (response) => {
        payload.receiveDeleteLabelResponseCallback &&
            payload.receiveDeleteLabelResponseCallback({ labelWasRemoved: true, labelId: payload.label.labelId });

        return LabelsActions.receiveLabelDeleteAction(buildPayload(response, payload));
    };

    let receiveErrorCallback = (response) => {
        let action = LabelsActions.receiveLabelDeleteErrorAction(response);
        action.payload = buildPayload({ error: action.payload.error }, payload);
        return action;
    };

    let body = {
        userId: userId,
        labelId: payload.label.id
    };

    return LabelsService.fetchDelete(userId, {
        dispatch,
        data: body,
        requestCallback: LabelsActions.requestLabelDeleteAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: receiveErrorCallback
    });
};

export const updateAccessType = (dispatch, payload = {}) => {
    if (!payload || !payload.label) return;
    const { id: userId } = RetailCompassStorage.getUser();
    const data = {
        userId,
        id: payload.label.labelId,
        accessType: Constantes.ACCESS_TYPES_LABEL_STRING[payload.label.accessType]
    };

    const buildPayload = (response, request) => ({
        response,
        requestBody: {
            ...request,
            ...data
        }
    });

    const receiveCallback = response => {
        payload.receiveUpdateAccessTypeCallback &&
            payload.receiveUpdateAccessTypeCallback({ label: payload.label });

        return LabelsActions.receiveUpdateAccessType(
            buildPayload(response, payload)
        );
    };

    const receiveErrorCallback = response => {
        let action = LabelsActions.receiveUpdateAccessTypeError(response);
        action.payload = buildPayload({ error: action.payload.error }, payload);
        return action;
    };

    return LabelsService.updateAccessType({
        dispatch,
        data,
        requestCallback: LabelsActions.requestUpdateAccessType,
        receiveCallback,
        receiveErrorCallback
    });
};

export const fetchDeleteProductOfLabel = (dispatch, labelId, productId, updateList = true) => {
    let payload = { labelId, productId };

    if (!payload.labelId || !payload.productId) {
        return;
    }

    const currentUser = RetailCompassStorage.getUser();
    let userId = currentUser.id;

    let buildPayload = (response, request) => ({
        response: response,
        requestBody: {
            ...request,
            ...payload
        }
    });

    let receiveCallback = (response) => {
        dispatch(LabelsActions.receiveLabelOfProductDeleteAction(buildPayload(response, payload)));
        return mustForceProductListUpdateAction(updateList);
    };

    let receiveErrorCallback = (response) => {
        let action = LabelsActions.receiveLabelOfProductDeleteErrorAction(response);
        action.payload = buildPayload({ error: action.payload.error }, payload);
        return action;
    };

    return LabelsService.fetchDeleteProductOfALabel(userId, payload.labelId, payload.productId, {
        dispatch,
        data: null,
        requestCallback: LabelsActions.requestLabelOfProductDeleteAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: receiveErrorCallback
    });
};

export const refreshUpdateDate = (dispatch, payload) => {
    if (!payload || !payload.labels) {
        return;
    }

    // cuando se llama antes de cerrar sesión ya no existe el usuario...
    let currentUser = RetailCompassStorage.getUser();
    let userId = null;
    if (currentUser && currentUser.id) {
        userId = currentUser.id;
    } else {
        userId = payload.userId;
    }

    if (!userId) {
        return;
    }

    let labels = payload.labels.map(item => {
        return {
            ...item,
            labelProducts: []
        }
    });

    let body = labels;

    const receiveCallback = (response) => {
        dispatch(LabelsActions.changeLabelsNeedToBeSynchronizedAction(false));
        return LabelsActions.receiveLabelRefreshUpdateDateAction(response);
    }

    return LabelsService.fetchRefreshUpdateDate(userId, {
        dispatch,
        keepalive: payload.keepalive || false,
        data: body,
        requestCallback: LabelsActions.requestLabelRefreshUpdateDateAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: LabelsActions.receiveLabelRefreshUpdateDateErrorAction
    });
};

export const LabelStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};
    let products = get(action, "payload.products", []);
    let _newLabels;

    switch (action.type) {
        case LabelsActions.RESET_STORE:
            initialState.hidePublicLabels = RetailCompassStorage.getHidePublicLabels();
            return Object.assign({}, initialState);
        case LabelsActions.REQUEST_LABELS_USER:
            return Object.assign({}, state, { labelsNeedToBeSynchronized: false });
        case LabelsActions.RECEIVE_LABELS_USER:
            const response = action.payload.response;
            const labels = LabelsUtils.getLabelsFromResponse(response);
            let forceOpenEditModal = false;
            return Object.assign({}, state, { labels: labels, forceOpenEditModal: forceOpenEditModal, isDeleted: false });

        case LabelsActions.RESET_PRODUCTS_LABEL:
            return Object.assign({}, state, { newLabel: { ...state.newLabel, selectedProducts: [] } });
        case LabelsActions.CHANGE_CATEGORY:
            return Object.assign({}, state, {
                newLabel: { ...DEFAULT_NEW_LABEL }
            });
        case LabelsActions.ADD_PRODUCTS_TO_LABEL:
            let selectedProducts = state.newLabel.selectedProducts || []
            selectedProducts = addItemsToList(selectedProducts, products);
            return Object.assign({}, state, {
                newLabel: { ...state.newLabel, selectedProducts },
            });
        case LabelsActions.REMOVE_PRODUCTS_LABEL:
            let currentProducts = state.newLabel.selectedProducts || []
            currentProducts = filter(currentProducts, (item) => products.indexOf(item) === -1);
            return Object.assign({}, state, {
                newLabel: { ...state.newLabel, selectedProducts: currentProducts }
            });
        case LabelsActions.REQUEST_LABEL_CREATE:
            return Object.assign({}, state, { isCreating: true, hasError: false, error: undefined, isCreated: false });
        case LabelsActions.RECEIVE_LABEL_CREATE:
            return Object.assign({}, state, { isCreating: false, hasError: false, error: undefined, isCreated: true });
        case LabelsActions.RECEIVE_LABEL_CREATE_ERROR:
        case LabelsActions.RECEIVE_LABEL_PATCH_ERROR:
            return Object.assign({}, state, {
                isCreating: false,
                isCreated: false,
                error: action.payload.response.error,
                hasError: true,
            });
        case LabelsActions.RECEIVE_LABEL_OF_PRODUCT_DELETE:
            let requestBody = action.payload.requestBody;
            let newLabels = LabelsUtils.removeProductFromLabel(state.labels, requestBody.labelId, requestBody.productId);
            return Object.assign({}, state, {
                labels: [...newLabels]
            });
        case LabelsActions.FORCE_LABELS_LIST_UPDATE:
            return Object.assign({}, state, { mustForceLabelsListUpdate: action.payload.mustForceLabelsListUpdate });
        case LabelsActions.REQUEST_LABEL_PATCH:
            return Object.assign({}, state, { isCreating: true, hasError: false, error: undefined, isCreated: false });
        case LabelsActions.RECEIVE_LABEL_PATCH:
            return Object.assign({}, state, { isCreating: false, hasError: false, error: undefined, isCreated: true });
        case LabelsActions.ENABLE_OPEN_EDIT_MODAL:
            return Object.assign({}, state, {
                isEditModalOpen: true
            });
        case LabelsActions.DISABLE_OPEN_EDIT_MODAL:
            return Object.assign({}, state, {
                isEditModalOpen: false
            });
        case LabelsActions.REQUEST_LABEL_DELETE:
            return Object.assign({}, state, { isDeleting: true, hasError: false, error: undefined, isDeleted: false, forceOpenEditModal: true });
        case LabelsActions.RECEIVE_LABEL_DELETE:
            let _requestBody = action.payload.requestBody;
            _newLabels = LabelsUtils.removeLabel(state.labels, _requestBody.labelId);
            return Object.assign({}, state, {
                isDeleting: false,
                hasError: false,
                error: undefined,
                isDeleted: true,
                shouldReloadTable: true,
                labels: [..._newLabels]
            });
        case LabelsActions.RECEIVE_LABEL_DELETE_ERROR:
            return Object.assign({}, state, {
                isDeleting: false,
                isDeleted: false,
                error: action.payload.response.error,
                hasError: true,
                forceOpenEditModal: true
            });
        case LabelsActions.DISABLE_FORCE_OPEN_MODAL:
            return Object.assign({}, state, {
                forceOpenEditModal: false
            });
        case LabelsActions.DISABLE_SHOULD_RELOAD_TABLE:
            return Object.assign({}, state, {
                shouldReloadTable: false
            });
        case LabelsActions.FILTER_LABEL_SELECTED:
            _newLabels = LabelsUtils.refreshUpdateDateFromLabels(state.labels, action.payload.label.labelId)
            let isSynchronizationRequired = state.labelsNeedToBeSynchronized;
            if (!isSynchronizationRequired) {
                isSynchronizationRequired = LabelsUtils.labelsNeedToBeSynchronized(state.labels, _newLabels);
            }
            return Object.assign({}, state, {
                labels: _newLabels,
                labelsNeedToBeSynchronized: isSynchronizationRequired
            });
        case LabelsActions.CHANGE_LABELS_NEED_TO_BE_SYNCHRONIZED:
            return Object.assign({}, state, {
                labelsNeedToBeSynchronized: action.payload.isNeeded
            });
        case LabelsActions.REQUEST_LABEL_REFRESH_UPDATE_DATE:
            return Object.assign({}, state, {
                isFetchingRefreshUpdateDate: true
            });
        case LabelsActions.RECEIVE_LABEL_REFRESH_UPDATE_DATE_ERROR:
        case LabelsActions.RECEIVE_LABEL_REFRESH_UPDATE_DATE:
            return Object.assign({}, state, {
                isFetchingRefreshUpdateDate: false
            });
        case LabelsActions.REQUEST_UPDATE_ACCESS_TYPE:
            return Object.assign({}, state, {});
        case LabelsActions.RECEIVE_UPDATE_ACCESS_TYPE:
            return Object.assign({}, state, {});
        case LabelsActions.RECEIVE_UPDATE_ACCESS_TYPE_ERROR:
            return Object.assign({}, state, {
                error: action.payload.response.error,
                hasError: true,
            });
        case LabelsActions.HIDE_PUBLIC_LABELS:
            return Object.assign({}, state, {
                hidePublicLabels: action.payload.hidePublicLabels
            });
        default:
            return state;
    }
};
