import SelectView from './SelectView';
import { connect } from 'react-redux';
import { Utils } from '@common/utils';
import { hideLabelsForm } from '../../../labels/label-new-button/ButtonNewLabelActions'
import { SubRouterState, Constantes, RetailCompassStorage } from '@common/utils';
import * as TableActions from '../../../table/TableActions';
import * as ComparerActions from '../../../../../../app/brands/src/comparer/ComparerActions';

const onSelectedViewDefault = (dispatch, nextTabView, prevTabView) => {
    SubRouterState.onSelectedView(dispatch, nextTabView);
    SubRouterState.subReportReset(dispatch);
    SubRouterState.reportReset(dispatch);
}

export const ON_SELECT_VIEW_STRATEGIES = {
    [Constantes.StoreType.BRAND]: (dispatch, nextTabView, prevTabView) => {
        if (prevTabView === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST && nextTabView === Constantes.TYPE_VIEW_RETAIL.REPORT) {
            SubRouterState.subReportChange(dispatch, Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF);
            SubRouterState.reportChange(dispatch, Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF);
            SubRouterState.onSelectedView(dispatch, nextTabView);
        } else {
            onSelectedViewDefault(dispatch, nextTabView, prevTabView);
        }
    },
    [Constantes.StoreType.RETAILER]: (dispatch, nextTabView, prevTabView) => {
        if (prevTabView === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST && nextTabView === Constantes.TYPE_VIEW_RETAIL.REPORT) {
            SubRouterState.subReportChange(dispatch, Constantes.TYPE_REPORT_RETAIL.RETAIL);
            SubRouterState.reportChange(dispatch, Constantes.RETAIL_VIEW_REPORT.COMPETITIVENESS);
            SubRouterState.onSelectedView(dispatch, nextTabView);
        } else {
            onSelectedViewDefault(dispatch, nextTabView, prevTabView);
        }
    }
};


export const mapStateToProps = (state, ownProps) => {
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    const configuration = RetailCompassStorage.getConfigurationCurrentCategory();
    const productAccess = RetailCompassStorage.getProductAccessConfig();
    const modules = productAccess ? productAccess.modules : [];
    return {
        selectedView: state.subRouterStateReducer.selectedView,
        modules: modules,
        configuration: configuration,
        currentFilter: currentFilter,
        lastFilter: state.productListBrandReducer.lastFilter,
        visibleLabelsForm: state.buttonNewLabelStateReducer.visibleLabelsForm || false,
        emptyStateType: state.productListBrandReducer.emptyStateType,
        paginationCount: state.productListBrandReducer.paginationCount,
        selectedReport: state.subRouterStateReducer.selectedReport
    };
}

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        onSelectedView: (nextTabView, prevTabView) => {
            let onSelectedView = ON_SELECT_VIEW_STRATEGIES[RetailCompassStorage.getStoreType()];
            if (onSelectedView) {
                onSelectedView(dispatch, nextTabView, prevTabView)
            } else {
                onSelectedViewDefault(dispatch, nextTabView, prevTabView)
            }
        },
        resetStoreAction: () => {
            dispatch(TableActions.resetStoreAction());
            dispatch(ComparerActions.resetStoreAction());
        },
        hideLabelsForm: () => dispatch(hideLabelsForm()),
        resetStoreForShipping: () => dispatch(TableActions.resetStoreForShippingAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectView);
