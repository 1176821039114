import React from 'react';
import { Constantes } from "@common/utils";
import { getHigherPrice, getDiscountBetweenPirces } from "./PriceUtils";
import { isEmpty } from 'lodash';

const DiscountCell = props => {
    const { product } = props;

    if (isEmpty(product)) {
        return <div></div>;
    }

    if (product.price === getHigherPrice(product)
        || product.status === Constantes.STATUS_PRODUCT.WITHOUT_STOCK) {
        return <div></div>;
    }

    const discount = getDiscountBetweenPirces(product);

    if (discount == 0) {
        return <div></div>;
    }

    return <div className="product-detail-body--price-cell">
        <div className="discount-cell" style={styles.rectangle}>
            <span style={styles.text}>{discount}%</span>
        </div>
    </div>
};

const styles = {
    rectangle: {
        backgroundColor: '#efefef',
        display: 'inline-block',
        borderRadius: '3px',
        width: '30px',
    },
    text: {
        color: '#838383',
        fontSize: 11,
        lineHeight: '19px'
    }
};

export default DiscountCell;
