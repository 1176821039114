import React from 'react';
import { Constantes, GaUtils, Utils } from "@common/utils/";
import moment from 'moment';

export const RangeDateOptions = ({ options, start, end, onSelectOption, categoryEvent }) => {

    const isActiveRangeSelection = (end, option, days) => {
        const endFormat = end.format("DD/MM/YYYY");
        return option.today === endFormat && days === option.days;
    }

    const onSelectedRange = (option, active) => {
        if (!active) {
            const today = moment().utcOffset(0).startOf("day");
            const start = moment(today).subtract(option.days, 'days');
            onSelectOption({ start: start, end: today });

            GaUtils.trackGAEvent(
                categoryEvent,
                Constantes.GA_ACTION_EVENTS.COMPARE.DATES,
                Constantes.GA_LABELS_EVENTS.COMPARE.BUTTON,
                option.days
            );
        }
    }

    const days = end.diff(start, 'days');

    return (
        <div className="range-options">
            <span className="title">Ver últimos días atrás</span>
            <div className="range-selection">
                {options.map((option) => {
                    const isActiveRange = isActiveRangeSelection(end, option, days);
                    return (
                        <div className={"btn-range-selection " + (isActiveRange ? "active" : "")}
                            onClick={() => onSelectedRange(option, isActiveRange)}
                            key={Utils.generateRandomId()}
                        >
                            <span>
                                {option.label}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}