import React from "react";
import classnames from "classnames";
import "./withStickyColumns.scss";

const withStickyColumns = (ReactTable) => (props) => {
    const findNextColumnNotHidden = (columns, currentIndex) => {
        for (let i = currentIndex + 1; i < columns.length; i += 1) {
            const column = columns[i];
            if (column.show !== false) return column;
        }
        return undefined;
    };

    const findPrevColumnNotHidden = (columns, currentIndex) => {
        for (let i = currentIndex - 1; i >= 0; i -= 1) {
            const column = columns[i];
            if (column.show !== false) return column;
        }
        return undefined;
    };

    const getLeftOffsetColumns = (columns, index) => {
        let offset = 0;
        for (let i = 0; i < index; i += 1) {
            const column = columns[i];
            if (column.show !== false) {
                const width = column.width || column.minWidth;
                offset += width;
            }
        }
        return offset;
    };

    const getStickyColumns = (
        columns,
        columnProps,
        parentIsfixed,
        parentIsLastFixed,
        parentIsFirstFixed
    ) => {
        const defaultColumn = columnProps;

        return columns.map((column, index) => {
            const isFixed = column.fixed || parentIsfixed || false;

            const nextColumn = findNextColumnNotHidden(columns, index);

            const _parentIsLastFixed =
                isFixed &&
                parentIsfixed === undefined &&
                nextColumn &&
                !nextColumn.fixed;

            const isLastFixed =
                isFixed &&
                (parentIsfixed ? parentIsfixed && parentIsLastFixed : true) &&
                ((parentIsfixed && !nextColumn) ||
                    (!parentIsfixed && nextColumn && !nextColumn.fixed));

            const prevColumn = findPrevColumnNotHidden(columns, index);

            const _parentIsFirstFixed =
                isFixed &&
                parentIsfixed === undefined &&
                prevColumn &&
                !prevColumn.fixed;

            const isFirstFixed =
                isFixed &&
                (parentIsfixed ? parentIsFirstFixed : true) &&
                ((parentIsfixed && !prevColumn) ||
                    (!parentIsfixed && prevColumn && !prevColumn.fixed));

            const left = isFixed && getLeftOffsetColumns(columns, index);

            const output = {
                ...column,
                fixed: isFixed,
                className: classnames(
                    defaultColumn.className,
                    column.className,
                    isFixed && "rt-td-fixed",
                    isFixed && "rt-td-fixed-left",
                    isLastFixed && "rt-td-fixed-left-last",
                    isFirstFixed && "rt-td-fixed-right-first"
                ),
                style: {
                    ...defaultColumn.style,
                    ...column.style,
                    left,
                },
                headerClassName: classnames(
                    defaultColumn.headerClassName,
                    column.headerClassName,
                    isFixed && "rt-th-fixed",
                    isFixed && "rt-th-fixed-left",
                    (_parentIsLastFixed ||
                        (parentIsLastFixed && isLastFixed)) &&
                        "rt-th-fixed-left-last",
                    (_parentIsFirstFixed ||
                        (parentIsFirstFixed && isFirstFixed)) &&
                        "rt-th-fixed-right-first"
                ),
                headerStyle: {
                    ...defaultColumn.headerStyle,
                    ...column.headerStyle,
                    left,
                },
            };

            if (column.columns) {
                output.columns = getStickyColumns(
                    column.columns,
                    columnProps,
                    isFixed,
                    _parentIsLastFixed,
                    _parentIsFirstFixed
                );
            }

            return output;
        });
    };

    return (
        <ReactTable
            {...props}
            className={classnames(props.className, "rt", "sticky")}
            columns={getStickyColumns(
                props.columns,
                ReactTable.defaultProps.column
            )}
        />
    );
};

export default withStickyColumns;
