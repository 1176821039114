import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ButtonCheck extends Component {

  static propTypes = {
    onActivation: PropTypes.func.isRequired,
    onDeactivation: PropTypes.func.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    isActivated: PropTypes.bool
  };

  static defaultProps = {
    className: "",
    activated: false
  };

  constructor(props) {
    super(props);
    this.state = {
      activated: props.isActivated
    };
    this.onClick = this.onClick.bind(this)
  }

  componentDidUpdate(){
    if(typeof this.props.isActivated === "boolean" && this.props.isActivated !== this.state.activated){
      this.setState({activated: this.props.isActivated});
    }
  }


  onClick() {
    if (this.state.activated) {
      this.props.onDeactivation(this.props.value);
    } else {
      this.props.onActivation(this.props.value);
    }
    this.setState({activated: !this.state.activated});
  }

  getClassName(){
    let className = ["buttoncheck",this.props.className];
    if(this.state.activated){
      className.push("btn--activated");
    }
    return className.join(" ");
  }

  render() {
    return (
        <div className={this.getClassName()} onClick={this.onClick} >
          {this.props.children}
        </div>
    );
  }
}
export default ButtonCheck;