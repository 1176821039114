import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export default class SuggestedActions extends Component {

    static propTypes = {
        showApproveButton: PropTypes.bool,
        btnClass: PropTypes.string,
        isTransformingTheMatch: PropTypes.bool,
        isRemovingTheMatch: PropTypes.bool,
        handleApproveClick: PropTypes.func,
        handleRejectClick: PropTypes.func,
        showRejectButton: PropTypes.bool,
        onActionCompleted: PropTypes.func,
        product: PropTypes.object.isRequired,
        computeRejectButtonText: PropTypes.func
    };

    static defaultProps = {
        className: "",
        handleApproveClick: () => { },
        handleRejectClick: () => { },
    };

    constructor(props) {
        super(props);
        this.state = {
        }
        this.onApproveClick = this.onApproveClick.bind(this);
        this.onRejectClick = this.onRejectClick.bind(this);

    }

    onApproveClick() {
        this.props.handleApproveClick(this.props.product);
        if (this.props.onActionCompleted) {
            this.props.onActionCompleted();
        }
    }

    onRejectClick() {
        this.props.handleRejectClick(this.props.product);
        if (this.props.onActionCompleted) {
            this.props.onActionCompleted();
        }
    }


    render() {
        const { showApproveButton, btnClass, isTransformingTheMatch, isRemovingTheMatch, showRejectButton, computeApproveButtonText } = this.props;

        let approveButtonText = <div>Aprobar <span>match</span></div>;

        if (computeApproveButtonText) {
            approveButtonText = computeApproveButtonText();
        }

        return (<div className="match__actions">
            {showRejectButton ? <Button size={'sm'} className={"btn-outline-light action button__reject " + btnClass} disabled={isTransformingTheMatch || isRemovingTheMatch} onClick={this.onRejectClick}>
                Rechazar <span>match</span>
            </Button> : null}
            {showApproveButton ? <Button size={'sm'} className={"btn-outline-light action button__approve " + btnClass} disabled={isTransformingTheMatch || isRemovingTheMatch} onClick={this.onApproveClick}>
                {approveButtonText}
            </Button> : null}
        </div>);
    }
}
