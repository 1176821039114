/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class AbstractService.js
 * @date 13 mar. 2018
 */


import { Utils, Constantes, i18n } from '@common/utils';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

let successfulStatusCodes = [200, 201];

const validateResponse = (response) => {
    if (!successfulStatusCodes.includes(response.status)) {
        throw i18n['server_error'];
    }
};

let fetchMethod = window.Cypress ? fetchPolyfill : window.fetch;

const TYPE_REQUEST_GET = 'GET', TYPE_REQUEST_POST = 'POST', TYPE_REQUEST_PUT = 'PUT', TYPE_REQUEST_DELETE = 'DELETE',
    TYPE_REQUEST_PATCH = 'PATCH';

const formatResponse = (response, typeResponse) => {
    validateResponse(response);
    if (typeResponse === 'text') {
        return response.text();
    } else if (typeResponse === 'json') {
        return response.json();
    } else if (typeResponse === 'blob') {
        return response.blob();
    } else {
        return response;
    }
};

const buildDataFromTypeRequest = (typeRequest, data, signal, additionalHeaders = {}) => {
    let body = {};

    body.headers = {
        ...{
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        ...additionalHeaders
    };

    if (typeRequest === TYPE_REQUEST_GET) {
        body.method = TYPE_REQUEST_GET;
    } else if (typeRequest === TYPE_REQUEST_POST) {
        body.method = TYPE_REQUEST_POST;
        body.body = JSON.stringify(data);
    } else if (typeRequest === TYPE_REQUEST_PUT) {
        body.method = TYPE_REQUEST_PUT;
        body.body = JSON.stringify(data);
    } else if (typeRequest === TYPE_REQUEST_PATCH) {
        body.method = TYPE_REQUEST_PATCH;
        body.body = JSON.stringify(data);
    } else if (typeRequest === TYPE_REQUEST_DELETE) {
        body.method = TYPE_REQUEST_DELETE;
    }

    if (signal) {
        body.signal = signal;
    }

    return body;
};

const dispatchSecureCallback = (dispatch, callback, value) => {
    try {
        if (Utils.isFunction(callback)) {
            if (value) {
                dispatch(callback(value));
            } else {
                dispatch(callback());
            }
        }
    } catch (error) {
        console.error(error)
    }
};

const request = (params) => {
    let { api, typeResponse, typeRequest, data, dispatch, requestCallback, receiveCallback, receiveErrorCallback, receiveAbortErrorCallback, signal, additionalHeaders, skipDispatch } = params;

    dispatchSecureCallback(dispatch, requestCallback, undefined);

    let body = buildDataFromTypeRequest(typeRequest, data, signal, additionalHeaders);

    if (typeRequest === TYPE_REQUEST_GET) {
        api += "&" + Utils.urlParamsString(data);
    }

    return fetchMethod(api, body)
        .then(response => formatResponse(response, typeResponse))
        .then(response => !skipDispatch ? dispatchSecureCallback(dispatch, receiveCallback, response) : response)
        .catch(error => {
            if (error.name === 'AbortError') {
                dispatchSecureCallback(dispatch, receiveAbortErrorCallback, null);
                return;
            }
            //check if json is empty
            if (error.name === 'SyntaxError') {
                dispatchSecureCallback(dispatch, receiveCallback, null);
                return;
            }
            dispatchSecureCallback(dispatch, receiveErrorCallback, i18n['server_error']);
        });
};

const requestDownload = (params, acceptType) => {
    let { api, filename, typeRequest, data, dispatch, requestCallback, receiveCallback, receiveErrorCallback, receiveAbortErrorCallback } = params;

    dispatchSecureCallback(dispatch, requestCallback, undefined);

    let body = buildDataFromTypeRequest(typeRequest, data);
    body.headers['Accept'] = acceptType;

    return fetchMethod(api, body)
        .then(response => formatResponse(response, 'blob'))
        .then(blob => {
            dispatchSecureCallback(dispatch, receiveCallback, {});

            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `${filename}${Constantes.FILE_TYPES.EXCEL_XLSX}`;
            a.click();
        })
        .catch(error => {
            if (error.name == 'AbortError') {
                dispatchSecureCallback(dispatch, receiveAbortErrorCallback, null);
                return;
            }
            dispatchSecureCallback(dispatch, receiveErrorCallback, i18n['server_error']);
        });
};

export const validateParams = (params) => {
    if (!params) {
        throw new Error('Params required');
    }
}

export const getAsText = (params) => {
    validateParams(params);
    return request({ ...params, typeResponse: 'text', typeRequest: TYPE_REQUEST_GET });
};

export const getAsJson = (params) => {
    validateParams(params);
    return request({ ...params, typeResponse: 'json', typeRequest: TYPE_REQUEST_GET });
};

export const postAsJson = (params) => {
    validateParams(params);
    return request({ ...params, typeResponse: 'json', typeRequest: TYPE_REQUEST_POST });
};
export const putAsJson = (params) => {
    validateParams(params);
    return request({ ...params, typeResponse: 'json', typeRequest: TYPE_REQUEST_PUT });
};
export const patchAsJson = (params) => {
    validateParams(params);
    return request({ ...params, typeResponse: 'json', typeRequest: TYPE_REQUEST_PATCH });
};
export const deleteAsJson = (params) => {
    validateParams(params);
    return request({ ...params, typeResponse: 'json', typeRequest: TYPE_REQUEST_DELETE });
};

export const getAsDownload = (params) => {
    validateParams(params);
    return requestDownload({ ...params, typeRequest: TYPE_REQUEST_GET });

};

export const postAsDownload = (params, acceptType) => {
    validateParams(params);
    return requestDownload({ ...params, typeRequest: TYPE_REQUEST_POST }, acceptType);
};

/**
 * envía una petición POST a la url indicada sin esperar resultado alguno.
 * la idea principal de  `navigator.sendBeacon` es poder sincronizar datos...
 * cuando el usuario ha recargado la página o la ha dejado (cerrar pestaña)
 * la petición será ejecutada en segundo plano
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
 */
export const sendBeacon = (params) => {
    validateParams(params);

    const data = JSON.stringify(params.data);
    navigator.sendBeacon(params.api, data);
};
