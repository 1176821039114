import React, { Component } from 'react';
import { Row, Col, Container, CardBody, Card } from 'reactstrap';
import CandidatePanel from './CandidatePanel';
import CurrentStepPanel from './CurrentStepPanel';
import AdvancePanel from './AdvancePanel';
import { PropTypes } from "prop-types";
import { AttributesUtils, Utils } from "@common/utils/";
import { AttributesForm } from '@app/attributes';
import { isEqual } from 'lodash';


export class Step5 extends Component {

    static propTypes = {
        taskTitle: PropTypes.string,
        currentProduct: PropTypes.object.isRequired,
        storedAttributes: PropTypes.array,
        attributesData: PropTypes.array,
        urlParams: PropTypes.object.isRequired,
        totalSteps: PropTypes.number.isRequired,
        saveProductAttribute: PropTypes.func,
        onFinishedTask: PropTypes.func,
        canonicalIsTransformingIntoCanonical: PropTypes.bool.isRequired,
        canonicalTransformIntoCanonical: PropTypes.func.isRequired,
        removeIdentical: PropTypes.func
    };

    static defaultProps = {
        taskTitle: "",
        saveProductAttribute: (v) => { console.log(v) },
        onFinishedTask: () => { }
    };

    constructor(props) {
        super(props);
        this.state = {
            allSelected: false,
            values: {},
            somethingHasChanged: false,
            formValues: { suggested: {}, stored: {} }
        };
        this.onValuesChange = this.onValuesChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    onSaveClick() {
        if (this.state.allSelected) {
            let { currentProduct, urlParams, attributesData } = this.props;
            let { userId, jobId } = Utils.getDecodedContextInfo(urlParams.contextInfo);
            let { values } = this.state;
            if (userId && Utils.isObjectWithData(values)) {
                let productAttributes = AttributesUtils.formToProductAttributesFormat(values, attributesData, currentProduct.productId, userId);
                this.props.saveProductAttribute(productAttributes);
                this.setState({ somethingHasChanged: false })
            }
            this.props.canonicalTransformIntoCanonical(currentProduct.productId, jobId, userId);
        }
    }


    onValuesChange(values) {
        this.setState({
            somethingHasChanged: true,
            allSelected: Object.keys(values).length === this.props.attributesData.length,
            values: values
        })
    }

    componentDidMount() {
        let formValues = AttributesUtils.buildAttributesFormElements(this.props.storedAttributes || [], this.props.attributesData);
        this.setState({ formValues: { ...formValues }, values: { ...formValues.stored } });
    }

    componentDidUpdate(prevProps, prevState) {
        let { storedAttributes, attributesData } = this.props;
        if (Utils.isArrayWithData(storedAttributes) && Utils.isArrayWithData(attributesData) && //Tienen valores validos
            !Utils.isObjectWithData(this.state.formValues.suggested) && !Utils.isObjectWithData(this.state.formValues.stored) && // No se han inicializado los valores en el state
            (!isEqual(storedAttributes, prevProps.storedAttributes) || !isEqual(attributesData, prevProps.attributesData))) { // al menos uno es diferente a su valor anterior (evita loop infinito en caso de error)

            let formValues = AttributesUtils.buildAttributesFormElements(this.props.storedAttributes || [], this.props.attributesData);
            this.setState({ formValues: formValues, values: { ...formValues.stored }, inited: true });
        }

    }

    render() {
        let { currentProduct, attributesData, storedAttributes, urlParams } = this.props;
        currentProduct.enhancedAttributes = AttributesUtils.enhanceProductAttributes(storedAttributes, attributesData);
        const { formValues } = this.state;
        return (
            <Container className="approval-step approval-step--5">
                <Row>
                    <Col sm={12}>
                        <CurrentStepPanel
                            currentStep={5}
                            totalSteps={this.props.totalSteps}
                            title={<span>Marca los atributos del producto por aprobar</span>}
                            showBackLink={true}
                            urlParams={urlParams}
                        />
                    </Col>
                </Row>
                <Row className="content-row">
                    <Col md={6}>
                        <Card>
                            <CardBody className="no-padding">
                                <CandidatePanel
                                    currentProduct={this.props.currentProduct}
                                    urlParams={this.props.urlParams}
                                    removeIdentical={this.props.removeIdentical}
                                    allMatches={this.props.allMatches}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    taskTitle={this.props.taskTitle}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="right-panel">
                        <Card className="attributes-panel">
                            <CardBody className="no-padding">
                                <div className="title">Atributos</div>
                                <AttributesForm attributesData={attributesData}
                                    stored={formValues.stored}
                                    suggested={formValues.suggested}
                                    urlParams={urlParams}
                                    onValuesChange={this.onValuesChange} />
                            </CardBody>
                        </Card>
                        <AdvancePanel
                            urlParams={this.props.urlParams}
                            buttonCaption={"Guardar y cerrar"}
                            buttonAction={this.onSaveClick}
                            buttonClassName="green btn-finish"
                        />
                    </Col>
                </Row>

            </Container>
        )
    }
}
export default Step5;