import * as ProductListContainer from '../product_list/indexContainer';
import { SubRouterState, RetailCompassStorage, ModuleUtils } from "@common/utils";
import { updateActiveRanking } from '../../../table/TableActions';
import { configureViewPriceComponent, getInitValueViewPrice, onChangeViewPrice } from '../../../table/ViewPricesUtil';

export const mapStateToProps = (state, ownProps) => {
    let commonProps = ProductListContainer.mapStateToProps(state, ownProps);
    const configuration = RetailCompassStorage.getConfigurationCurrentCategory();
    const productAccess = RetailCompassStorage.getProductAccessConfig();
    const modules = productAccess ? productAccess.modules : [];
    const hasAccessShipping = ModuleUtils.isActiveShippingModule(configuration, modules);
    return {
        ...commonProps,
        viewPricesComponent: configureViewPriceComponent,
        onChangeViewPrice: onChangeViewPrice,
        getInitValueViewPrice: getInitValueViewPrice,
        hasAccessShipping: hasAccessShipping
    }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    let commonFuncts = ProductListContainer.mapDispatchToProps(dispatch, ownProps);
    return {
        ...commonFuncts,
        onSelectedView: (selectedView) => {
            SubRouterState.onSelectedView(dispatch, selectedView);
        },
        updateActiveRanking: (activeRanking) => dispatch(updateActiveRanking(activeRanking))
    };
};
