/**
 * @author @author <a href="mailto:jvillada@pricingcompass.com">Jorge Villada</a>
 * @class CategoryActions.js
 * @date 21 mar. 2018
 */


const composeActionName = (action) => ('CategoryState/' + action);

export const REQUEST_UPDATE_CATEGORY = composeActionName('REQUEST_UPDATE_CATEGORY');
export const REQUEST_CLEAR_CATEGORY = composeActionName('REQUEST_CLEAR_CATEGORY');
export const REQUEST_CATEGORY_STORE = composeActionName('REQUEST_CATEGORY_STORE');
export const RECEIVE_CATEGORY_STORE = composeActionName('RECEIVE_CATEGORY_STORE');
export const RECEIVE_CATEGORY_STORE_ERROR = composeActionName('RECEIVE_CATEGORY_STORE_ERROR');

export const requestCategoryStoreAction = () => ({
    type: REQUEST_CATEGORY_STORE
});

export const receiveCategoryStoreAction = (data) => ({
    type: RECEIVE_CATEGORY_STORE,
    payload: {
        data: data
    }
});

export const receiveCategoryStoreErrorAction = (error) => ({
    type: RECEIVE_CATEGORY_STORE_ERROR,
    payload: {
        error: error
    }
});

export const requestUpdateCategory = (id, categoria, subcats, level, parent) => ({
    type: REQUEST_UPDATE_CATEGORY,
    payload: {
        id, categoria, subcats, level, parent
    }
});
export const requestClearCategory = () => ({
    type: REQUEST_CLEAR_CATEGORY,
});