import React, { useState, useEffect } from "react";
import { LabelsUtils } from "@common/utils/";
import Label from '../label/Label';
import { receiveDeleteLabelResponseCallback, handleRemoveProductLabelClick } from '../helpers';
import { fetchDeleteProductOfLabel, fetchDelete } from '../LabelsState';

const LabelsProductDetail = ({ productId, labels, showPopoverRemove, dispatch, currentFilter, onRemoveLabel, hidePublicLabels }) => {
    const [productLabels, setProductLabels] = useState([]);

    useEffect(() => {
        function getProductLabels() {
            const newLabels = labels ? labels.filter(label => LabelsUtils.hasRelationshipWithTheLabel(label.labelProducts, productId)) : [];
            setProductLabels(newLabels);
        }

        getProductLabels();
    }, [labels]);

    function handleRemoveProductLabel(label) {
        onRemoveLabel && onRemoveLabel(label);
        handleRemoveProductLabelClick({
            label: label,
            dispatch: dispatch,
            productId: productId,
            currentFilter: currentFilter,
            removeLabel: (lbel) => fetchDelete(dispatch, lbel),
            removeProductFromLabel: (labelId, idProduct) => fetchDeleteProductOfLabel(dispatch, labelId, idProduct, false),
            receiveDeleteLabelResponseCallback: receiveDeleteLabelResponseCallback
        })
    }

    return (
        productLabels
            .filter(label => LabelsUtils.validateHidePublicLabel(hidePublicLabels, label))
            .map((label) =>
                <Label label={label}
                    popoverRemoveClick={() => handleRemoveProductLabel(label)}
                    showPopoverRemove={showPopoverRemove}
                    mode="simple"
                    showCloseIcon={false}
                    key={productId + "-" + label.id}>
                </Label>
            )
    )
}

export default LabelsProductDetail
