import { getPriceMapping } from '@common/components/src/common/components/product_detail/PriceUtils';
import { isNumber } from 'highcharts';
import { head, inRange, isEmpty, isEqual, isNull, min, uniqWith } from 'lodash';
import { Constantes, OperatorUtils, Utils } from "../..";
import { getDaysWithoutStock, orderMatches } from '../format';

export const mapCanonicalProductPostpago = (canonicalProduct, competitorsProducts, params) => {
    const { isBrand, currentFilter, brandsId, storeId } = params;
    let allCompetitorsProducts = competitorsProducts.map(item => mapCompetitorProductPostpago(item, params));
    allCompetitorsProducts = orderMatches(allCompetitorsProducts, params.requestBody);
    const _competitorsProducts = allCompetitorsProducts.filter(match => checkMatchFilters(match, { ...currentFilter, storeId }));
    const filteredProducts = getFilteredProducts(_competitorsProducts, currentFilter.stores);

    const attributes = {
        ...canonicalProduct.attributes,
        ...getColorCanonicalAttribute(allCompetitorsProducts)
    };

    return {
        ...canonicalProduct,
        attributes,
        filteredProducts: filteredProducts,
        allCompetitorsProducts,
        competitorsProducts: _competitorsProducts,
        isMyProduct: isBrand ? OperatorUtils.isMyCanonicalProductBrand(canonicalProduct, brandsId) : OperatorUtils.isMyCanonicalProduct(canonicalProduct, storeId),
        yourProduct: allCompetitorsProducts.find((item) => item.isMyProduct),
    }
}

export const mapCompetitorProductPostpago = (match, params) => {
    const { isBrand, brandsId, storeId } = params;
    const storeNameMyProduct = match.isSeller ? 'Tu Mkp' : 'Tu tienda';
    const isMyProduct = isBrand ? OperatorUtils.isMyProductBrand(match, brandsId) : isEqual(match.storeId, storeId);

    return {
        ...match,
        ...getPriceMapping(match),
        id: match.productId,
        status: match.statusProduct,
        name: match.productName,
        isMyProduct: isMyProduct,
        storeName: isMyProduct ? storeNameMyProduct : match.storeName,
        daysWithoutStock: getDaysWithoutStock(match),
    }
}

const getColorCanonicalAttribute = (allCompetitorsProducts) => {
    const colors = OperatorUtils.getColors(allCompetitorsProducts);
    const valueColorAttribute = colors.length + (colors.length > 1 ? " Colores" : " Color");
    return { [Constantes.MATCH_ATTRIBUTES.COLOR]: [valueColorAttribute] };
}

const getFilteredProducts = (competitorsProducts, stores = []) => {
    return stores.map(store => getMatchProduct(competitorsProducts, store.storeId, store.storeId + "-" + store.operatorName));
}

export const getMatchProduct = (competitorsProducts, storeId, operatorKey) => {
    let products = OperatorUtils.filterByStore(competitorsProducts, storeId, operatorKey);
    products = OperatorUtils.getProductsByMinPlanPrice(products);
    const colors = OperatorUtils.getColorsByStatus(products);

    products = uniqByContract(products);
    const daysWithOutStock = getDaysWithoutStockByFilter(products);
    const ranking = min(products, "absoluteRanking");

    let product = processProducts(products);
    if (!isEmpty(product)) {
        product = {
            ...product,
            colors: colors.length,
            url: head(products.map(e => e.url)),
            storeId: head(products.map(e => e.storeId)),
            operatorKey: head(products.map(e => e.operatorKey)),
            absoluteRanking: ranking.absoluteRanking,
            daysWithOutStock: daysWithOutStock
        };
    }
    return product;
}

const uniqByContract = (products) => {
    let productos = products.filter(e => !isEmpty(e.attributes[Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE]));
    return uniqWith(productos, (item1, item2) => {
        const label1 = OperatorUtils.getValueAttribute(item1, Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE);
        const label2 = OperatorUtils.getValueAttribute(item2, Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE);
        return isEqual(label1, label2);
    });
}

const processProducts = (productos) => {
    let data = {};
    productos.forEach(producto => {
        const label = OperatorUtils.getValueAttribute(producto, Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE);
        const key = OperatorUtils.getKeyValueContractByLabel(label);
        if (key) {
            data[key] = {
                daysWithoutStock: producto.daysWithoutStock,
                plan: producto.planPrice,
                equipo: producto.price
            };
        }
    });
    return data;
}

export const getDaysWithoutStockByFilter = (products) => {
    if (isEmpty(products)) return null;
    const values = products.map(p => p.daysWithoutStock);
    if (values.includes(-1)) {
        return null;
    }

    return min(values);
}

export const checkMatchFilters = (match, currentFilter) => {
    return isMatchOperatorsFilter(match, currentFilter)
        && isMatchTypeContractFilter(match, currentFilter)
        && isMatchRangePriceFilter(match, currentFilter);
}

export const isMatchOperatorsFilter = (match, currentFilter) => {
    if (Utils.isArrayWithData(currentFilter.operators)) {
        const operatorKeys = currentFilter.operators.map(o => o.value);
        return match.storeId == currentFilter.storeId
            || operatorKeys.includes(match.operatorKey);
    }
    return true;
}

export const isMatchTypeContractFilter = (match, currentFilter) => {
    if (!Utils.isArrayWithData(currentFilter.contractType))
        return true;

    const CONTRACT_TYPE = Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE;
    if (!Utils.isArrayWithData(match.attributes[CONTRACT_TYPE]))
        return false;

    const value = OperatorUtils.getValueAttribute(match, CONTRACT_TYPE)
    return currentFilter.contractType.includes(value);
}

export const isMatchRangePriceFilter = (match, currentFilter) => {
    const { from, to } = currentFilter;
    if (!isNumber(from) && !isNumber(to))
        return true;

    if (isNull(match.planPrice))
        return false;

    if (isNumber(from) && !isNumber(to))
        return match.planPrice >= from;

    return inRange(match.planPrice, from, to);
}
