import { connect } from 'react-redux';
import SummaryBrandView from './SummaryBrandView';
import { orderBy, isEqual } from 'lodash';
import { fetchProductItems } from '../table/TableState';
import { Utils } from '@common/utils';

const mapStateToProps = (state, ownProps) => {
    let brands = state.productListBrandReducer.brands || [];
    let brandsResult = extractBrandsResult(state.productListBrandReducer.brandsResult);
    let brandIds = state.productListBrandReducer.brandIds || null;
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    let lastRequest = state.productListBrandReducer.lastSearchRequest;
    let initialRequest = state.productListBrandReducer.initialRequest;

    const value = {
        label: 'PRODUCTOS POR MARCA',
        values: brands,
        valuesFiltered: isEqualResult(brands, brandsResult) ? [] : brandsResult,
        brandsFiltered: brandIds,
        currentFilter: currentFilter,
        isOnlyFilterBrand: isOnlyFilterBrand(initialRequest, lastRequest),
        isFetching: state.productListBrandReducer.isFetching,
    };
    return value;
};

export const isEqualResult = (brands1, brands2) => {
    if (brands1.length != brands2.length) {
        return false;
    }
    brands1 = orderBy(brands1, ['count'], ['desc']);
    brands2 = orderBy(brands2, ['count'], ['desc']);

    for (let i = 0; i < brands1.length; i++) {
        if (!isEqualBrand(brands1[i], brands2[i])) {
            return false;
        }
    }
    return true;
}

export const isOnlyFilterBrand = (initialRequest, lastRequest) => {
    let initialRequestCopy = Object.assign({}, initialRequest);
    let lastRequestCopy = Object.assign({}, lastRequest);
    
    if (isEqual(initialRequestCopy, lastRequestCopy)) {
        return false;
    }

    if (!isEqual(initialRequestCopy.matches, lastRequestCopy.matches)) {
        return false;
    }

    for (let property in initialRequestCopy) {
        delete lastRequestCopy[property];
    }

    if (!lastRequestCopy.hasOwnProperty('brandIds')) {
        return false;
    }

    let count = Object.keys(lastRequestCopy).length;
    
    return count === 1;
} 

export const isEqualBrand = (brand1, brand2) => {
    if (!brand1 || !brand2) {
        return false;
    }
    return brand1.brandId === brand2.brandId && brand1.count === brand2.count;
}

export const extractBrandsResult = (brandResult) => {
    if (!brandResult) {
        return [];
    }
    return brandResult.data;
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params) => fetchProductItems(dispatch, params),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryBrandView);