import { remove } from "lodash";
import * as ValidationActions from "../ValidationActions";
import { Constantes } from "@common/utils/"
export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const removeMatch = (currentProduct, match) => {
    currentProduct.competitorsProducts = currentProduct.competitorsProducts || [];
    currentProduct.allCompetitorsProducts = currentProduct.allCompetitorsProducts || [];
    currentProduct.directMatches = currentProduct.directMatches || [];
    currentProduct.removedMatches = currentProduct.removedMatches || [];

    if (match && match.productId) {
        remove(currentProduct.competitorsProducts, ["productId", match.productId]);
        remove(currentProduct.allCompetitorsProducts, ["productId", match.productId]);
        remove(currentProduct.directMatches, ["productId", match.productId]);
        match.statusMatch = Constantes.MATCH_STATUS_IDS.REMOVED;
        currentProduct.removedMatches.push(match);
    }
    return currentProduct;
}

export const requestRemoveIdentical = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isRemovingTheMatch: true
    });
}

export const receiveRemoveIdentical = (state, action) => {
    const requestBody = action.payload.requestBody;
    const match = requestBody.match;
    return updateObject(state, {
        hasError: false,
        error: null,
        isRemovingTheMatch: false,
        currentProduct: removeMatch(state.currentProduct, match),
    });
}

export const receiveRemoveIdenticalError = (state, action) => {
    return updateObject(state, {
        hasError: true,
        error: null,
        isRemovingTheMatch: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_REMOVE_IDENTICAL]: requestRemoveIdentical,
    [ValidationActions.RECEIVE_REMOVE_IDENTICAL]: receiveRemoveIdentical,
    [ValidationActions.RECEIVE_REMOVE_IDENTICAL_ERROR]: receiveRemoveIdenticalError
}

export default handlers