import { connect } from 'react-redux';
import AttributesTool from './index';
import { fetchCategoryAttributes, saveProductAttribute, fetchProductAttributes } from './AttributesState';

export const mapStateToProps = (state, ownProps) => {
  return {
    productId: ownProps.match.params.productId,
    countryCode: ownProps.match.params.countryCode,
    categoryId: ownProps.match.params.categoryId,
    attributesData: state.attributesStateReducer.attributes || [],
    errorCreating: state.attributesStateReducer.errorCreating,
    isSaved: state.attributesStateReducer.isSaved,
    storedAttributes: state.attributesStateReducer.storedAttributes || [],
    contextInfo: ownProps.match.params.contextInfo,
    urlParams: ownProps.match.params,
    isIFrame: ownProps.isIFrame
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    fetchCategoryAttributes: (categoryId) => fetchCategoryAttributes(dispatch, categoryId),
    saveProductAttribute: (payload) => saveProductAttribute(dispatch, payload),
    fetchProductAttributes: (productId) => fetchProductAttributes(dispatch, productId)
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributesTool);
