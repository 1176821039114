import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { SidebarReducer, GlobalActions, AppConfigReducer } from '@common/utils/';
import * as CategoryState from "@common/components/src/category/CategoryState";
import * as HistoricState from "@common/components/src/Historic/HistoricState";
import * as TableState from "@common/components/src/table/TableState";
import * as FavoriteState from "@common/components/src/favorite/FavoriteState";
import * as AlertFormBasicState from "@common/components/src/alerts/components/AlertForm/components/AlertFormBasic/AlertFormBasicState";
import * as LabelsState from "@common/components/src/labels/LabelsState";
import * as ButtonNewLabelState from "@common/components/src/labels/label-new-button/ButtonNewLabelState";
import * as AlertModalState from "@common/components/src/alerts/components/AlertModal/AlertModalState";
import * as NotificationState from "@common/components/src/notification/NotificationState";
import * as StoreState from "@common/components/src/store/StoreState";
import * as ShippingState from "@common/components/src/shipping/ShippingState";
import { AttributesState } from '@app/attributes/';
import { BrandDashboardState, ComparerState } from '@app/brands';
import { SubRouterState } from '@common/utils';
import { ValidationState } from '@app/validation';

export const initialState = {
    categoryState: CategoryState.initialState,
    appConfigReducer: AppConfigReducer.initialState,
    historicReducer: HistoricState.ProductDetailHistoricState.initialState,
    comparerHistoricReducer: HistoricState.ComparerHistoricState.initialState,
    productListBrandReducer: TableState.initialState,
    dashboardBrandReducer: BrandDashboardState.initialState,
    favoriteStateReducer: FavoriteState.initialState,
    alertFormBasicStateReducer: AlertFormBasicState.initialState,
    labelStateReducer: LabelsState.initialState,
    attributesStateReducer: AttributesState.initialState,
    alertModalStateReducer: AlertModalState.initialState,
    notificationStateReducer: NotificationState.initialState,
    buttonNewLabelStateReducer: ButtonNewLabelState.initialState,
    subRouterStateReducer: SubRouterState.getInitialState(),
    comparerStateReducer: ComparerState.initialState,
    validationStateReducer: ValidationState.initialState,
    storeStateReducer: StoreState.initialState,
    shippingStateReducer: ShippingState.initialState,
};

const combinedReducer = combineReducers({
    form: reduxFormReducer,
    sidebar: SidebarReducer.SidebarReducer,
    categoryState: CategoryState.CategoryStateReducer,
    labelStateReducer: LabelsState.LabelStateReducer,
    appConfigReducer: AppConfigReducer.appConfigReducer,
    historicReducer: HistoricState.ProductDetailHistoricState.HistoricStateReducer,
    comparerHistoricReducer: HistoricState.ComparerHistoricState.HistoricStateReducer,
    productListBrandReducer: TableState.TableStateReducer,
    dashboardBrandReducer: BrandDashboardState.DashboardStateReducer,
    favoriteStateReducer: FavoriteState.FavoriteStateReducer,
    alertModalStateReducer: AlertModalState.AlertModalStateReducer,
    attributesStateReducer: AttributesState.AttributesStateReducer,
    alertFormBasicStateReducer: AlertFormBasicState.AlertFormBasicStateReducer,
    notificationStateReducer: NotificationState.NotificationReducer,
    buttonNewLabelStateReducer: ButtonNewLabelState.ButtonNewLabelStateReducer,
    subRouterStateReducer: SubRouterState.ReportStateReducer,
    comparerStateReducer: ComparerState.ComparerStateReducer,
    validationStateReducer: ValidationState.ValidationStateReducer,
    storeStateReducer: StoreState.StoreStateReducer,
    shippingStateReducer: ShippingState.ShippingStateReducer
});

const rootReducer = (state, action) => {
    if (action.type === GlobalActions.RESET_REDUX_STATE) {
        state = { ...state, ...initialState };
    }
    return combinedReducer(state, action);
}

export default rootReducer;
