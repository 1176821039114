import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { some, includes } from 'lodash';
import loadable from '@loadable/component'
import MainWrapper from './MainWrapper';
import PrivateRoute from './PrivateRoute'
import { ChurnZeroButton, Layout } from "@common/components"
import LoginRoute from './LoginRoute';
import { RetailCompassStorage, RouterUtils, Utils } from "@common/utils/"
import { Auth0Config } from "@common/auth/";
import { Auth0Provider } from "@auth0/auth0-react";
import Loading from "./Loading";
import { Menu } from './MenuApps/Menu';

const AttributesApp = loadable(() => import(/* webpackChunkName: "attributes-chunk" */ './apps-routes/AttributesApp'));
const ValidationApp = loadable(() => import(/* webpackChunkName: "validation-chunk" */ './apps-routes/ValidationApp'));
const BrandListApp = loadable(() => import(/* webpackChunkName: "brand-list-chunk" */ './apps-routes/BrandListApp'));
const RetailListApp = loadable(() => import(/* webpackChunkName: "retail-list-chunk" */ './apps-routes/RetailListApp'));
const BrandComparerApp = loadable(() => import(/* webpackChunkName: "brand-comparer-chunk" */ './apps-routes/BrandComparerApp'));
const ValidationApprovalApp = loadable(() => import(/* webpackChunkName: "validation-approval--chunk" */ './apps-routes/ValidationApprovalApp'));
const OperatorListApp = loadable(() => import(/* webpackChunkName: "operator-list-chunk" */ './apps-routes/OperatorListApp'));

const MODULES_LIST = [RouterUtils.MODULES.RETAIL, RouterUtils.MODULES.BRAND, RouterUtils.MODULES.VALIDATION];

const onRedirectCallback = appState => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const ProtectedRoutes = () => {
  return (
      <Auth0Provider
          domain={Auth0Config.DOMAIN}
          clientId={Auth0Config.CLIENT_ID}
          redirectUri={Auth0Config.REDIRECT_URL}
          onRedirectCallback={onRedirectCallback}>
          <Loading />
          <Switch>
              <LoginRoute path="/login" />              
              <PrivateRoute path="/" component={WrappedRoutes} />
          </Switch>
      </Auth0Provider>
  );
};

const Router = () => (
    <MainWrapper>
        <main>
            <Switch>
                <Route
                    path="/attributes/:countryCode/:categoryId/:productId/:contextInfo"
                    component={Attributes}
                />
                <Route path="/validation" component={Validation} />
                <Route path="/" component={ProtectedRoutes} />
            </Switch>
        </main>
    </MainWrapper>
);

const buildDefaultModulePathname = () => {
  const isStoreTypeRetailer = RetailCompassStorage.isStoreTypeRetailer();
  const isStoreTypeBrand = RetailCompassStorage.isStoreTypeBrand();
  let categoryKey = RetailCompassStorage.getDefaultCanonicalCategory().key;  

  if (isStoreTypeRetailer) {
    return RouterUtils.buildCategoryPartMenuPathByModule(categoryKey, RouterUtils.MODULES.RETAIL);
  } else if (isStoreTypeBrand) {
    return RouterUtils.buildCategoryPartMenuPathByModule(categoryKey, RouterUtils.MODULES.BRAND);
  }
  return '';
}

const redirectToDefaultModule = (props) => {  
  const pathname = buildDefaultModulePathname();  
  return <Redirect to={{ pathname: pathname, state: { from: props.location } }} />;
}

export function WrappedRoutes() {
  const showChurnZero = Utils.ENV.isDevelopment() || Utils.ENV.isTest() || RetailCompassStorage.isCustomerUser()

  return (
      <React.Fragment>
          <Layout />
          <Menu />
          <Route path="/retail" component={Retail} />
          <Route path="/brand" component={Brand} />
          <Route
              component={props => {
                  if (!some(MODULES_LIST, el => includes(props.location.pathname, el))) {                     
                    return redirectToDefaultModule(props);                      
                  }
                  return null;
              }}
          />
          {showChurnZero && <ChurnZeroButton />}
      </React.Fragment>
  )
};

const Retail = (props) => {
  if (!RetailCompassStorage.isStoreTypeRetailer()) {
    let categoryKey = RetailCompassStorage.getDefaultCanonicalCategory().key;
    let pathname = RouterUtils.buildCategoryPartMenuPathByModule(categoryKey, RouterUtils.MODULES.BRAND);
    return <Redirect to={{ pathname: pathname, state: { from: props.location } }} />;
  }

  return (
    <Switch>
      <Route exact path='/retail' component={() => RetailListApp} />
      <Route path='/retail/:canonicalCategoryKey/:currentView' render={props => {
        return getListApp(props);
      }} />
    </Switch>
  )
};

const Brand = (props) => {
  if (!RetailCompassStorage.isStoreTypeBrand()) {
    let categoryKey = RetailCompassStorage.getDefaultCanonicalCategory().key;
    let pathname = RouterUtils.buildCategoryPartMenuPathByModule(categoryKey, RouterUtils.MODULES.RETAIL);
    return <Redirect to={{ pathname: pathname, state: { from: props.location } }} />;
  }
  return (
    <Switch>
      <Route exact path='/brand' component={BrandListAppComp} />
      <Route path='/brand/:canonicalCategoryKey/:currentView' render={props => {
        if (props.match.params.currentView === RouterUtils.MODULES_VIEWS.COMPARE) {
          return <BrandComparerApp {...props} />
        }
        return getListApp(props);
      }} />
    </Switch>
  );
};

const BrandListAppComp = (props) => <BrandListApp {...props} />;

const Validation = (props) => {
  return (
    <Switch>
      <Route path='/validation/:currentView/:countryCode/:categoryId/:productId/:contextInfo/:step?' render={props => {
        if (props.match.params.currentView === "edit") {
          return <ValidationApp {...props} />
        }
        if (props.match.params.currentView === "approval") {
          return <ValidationApprovalApp {...props} />
        }
      }} />
    </Switch>
  );
};

const Attributes = (props) => (<AttributesApp {...props} />);

const getListApp = (props) => {
  if (RouterUtils.isCategoryTypeOperator(props.match.params.canonicalCategoryKey)) {
    return <OperatorListApp {...props} />;
  } else if (RetailCompassStorage.isStoreTypeRetailer()) {
    return <RetailListApp {...props} />
  } else if (RetailCompassStorage.isStoreTypeBrand()) {
    return <BrandListApp {...props} />
  }
};

export default Router;
