import React, { useEffect, useState } from 'react';
import { Utils, AttributesUtils } from '@common/utils';
import { filter } from 'lodash';
import { memo } from 'react';

const AttributeTags = ({ enhancedAttributes = [], className = "", parentRef = {} }) => {

    const [width, setWidth] = useState();

    useEffect(() => {
        const _width = getWidthAttrs();
        setWidth(_width);
    }, [parentRef])

    const getWidthElementByClass = (elementClass) => {
        const current = parentRef.current;
        let value = null;
        if (current) {
            const elem = current.getElementsByClassName(elementClass)[0];
            value = elem ? elem.clientWidth : 0;
        }
        return value;
    }

    const getWidthAttrs = () => {
        const current = parentRef.current;
        let value = null;
        if (current) {
            const productContent = getWidthElementByClass("product-content");
            const checkbox = getWidthElementByClass("checkbox-container");
            const favorite = getWidthElementByClass("favorite-container");
            const img = getWidthElementByClass("img-container");
            const planesContainer = getWidthElementByClass("planes-container");
            const margins = 23;
            value = productContent - checkbox - favorite - img - planesContainer - margins;
        }
        return value;
    }

    const buildClassName = () => {
        let classes = ["attribute-tags", className];
        if (!Utils.isArrayWithData(enhancedAttributes) || filter(enhancedAttributes, 'visible').length === 0) {
            classes.push("empty")
        }
        return classes.join(" ")
    }

    return (
        <div className={buildClassName()} style={{ maxWidth: width, overflowX: 'auto' }}>
            {
                filter(enhancedAttributes, 'visible').map((item) =>
                    <span key={"attribute-tag-" + Utils.generateRandomString()}>
                        {AttributesUtils.buildAttributeValue(item)}
                    </span>
                )
            }
        </div>
    );
}

export default memo(AttributeTags);
