import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from "lodash"
import { GaUtils, RetailCompassStorage, RouterUtils, Utils } from "@common/utils/"
import { hideLabelsForm } from '../../labels/label-new-button/ButtonNewLabelActions'
import { forceAbortController } from '../../table/TableState';
import classnames from 'classnames';
import { DropdownMenuCategories } from './DropdownMenuCategories';
import useWindowSize from '../../hooks/useWindowSize';

export const CategoryPartMenu = ({ currentCanonicalCategory, hideLabelsForm }) => {

    const LOGO_SECTION_WIDTH = 200;
    const SELECT_STORE = 200;
    const MENU_MORE_CATEGORIES = 171;
    const MARGIN_RIGHT = 50;

    const [allCanonicalCategories, setAllCanonicalCategories] = useState([])
    const [categoriesMapped, setCategoriesMapped] = useState([])
    const [breakWidthsMap, setBreakWidthsMap] = useState({})
    const [show, setShow] = useState(false);
    const screenSize = useWindowSize();

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 100);
    }, [])

    useEffect(() => {
        let { canonicalCategories, visibleCanonicalCategories } = getCanonicalCategoryInfo();
        setAllCanonicalCategories(canonicalCategories);
        setCategoriesMapped(visibleCanonicalCategories);
    }, [])

    useEffect(() => {
        calculateWidthCategories();
    }, [allCanonicalCategories, show])

    useEffect(() => {
        setTimeout(() => {
            if (Utils.isObjectWithData(breakWidthsMap))
                changeVisibility(breakWidthsMap);
        });
    }, [screenSize.width, breakWidthsMap, currentCanonicalCategory])

    const isLoggedIn = () => {
        return RetailCompassStorage.isLoggedIn();
    }

    const getCanonicalCategoryInfo = () => {
        if (!isLoggedIn()) {
            return {
                canonicalCategories: [],
                visibleCanonicalCategories: []
            };
        }

        const canonicalCategories = RetailCompassStorage.getCanonicalCategories() || [];
        const categories = canonicalCategories.map(item => ({ ...item, isVisible: true, hidden: true }));

        return {
            canonicalCategories: categories,
            visibleCanonicalCategories: categories,
        }
    }

    const calculateWidthCategories = () => {
        const visibleNavLinks = document.querySelectorAll('.topbar__nav > .topbar__nav-link');
        if (visibleNavLinks.length > 0) {
            let totalSpace = 0;
            let _breakWidthsMap = {};

            visibleNavLinks.forEach(currentNavLink => {
                const width = getWidth(currentNavLink, 'full');
                const categoryId = currentNavLink.getAttribute('data-category-id');
                totalSpace += width;
                _breakWidthsMap[categoryId] = { totalSpace, width };
            })

            setBreakWidthsMap(_breakWidthsMap);
            changeVisibility(_breakWidthsMap);
        }
    }

    const getWidth = (el, type) => {
        if (!el) {
            return null;
        }

        if (type === 'inner')  // .innerWidth()
            return el.clientWidth;
        else if (type === 'outer')  // .outerWidth()
            return el.offsetWidth;
        let s = window.getComputedStyle(el, null);
        if (type === 'width')  // .width()
            return el.clientWidth - parseInt(s.getPropertyValue('padding-left')) - parseInt(s.getPropertyValue('padding-right'));
        else if (type === 'full')  // .outerWidth(includeMargins = true)
            return el.offsetWidth + parseInt(s.getPropertyValue('margin-left')) + parseInt(s.getPropertyValue('margin-right'));
        return null;
    }

    const changeVisibility = (breakWidthsMap) => {
        const availableSpace = screenSize.width - LOGO_SECTION_WIDTH - SELECT_STORE - MENU_MORE_CATEGORIES - MARGIN_RIGHT;
        const _categoriesMapped = allCanonicalCategories.map(item => {
            const requiredSpace = get(get(breakWidthsMap, item.id, {}), 'totalSpace', 0);
            return {
                ...item,
                isVisible: requiredSpace < availableSpace,
                hidden: false
            };
        });

        setCategoriesMapped(_categoriesMapped);
    }

    const getActiveClassName = (canonicalCategory, defaultCurrentCanonicalCategory) => {
        if (canonicalCategory &&
            defaultCurrentCanonicalCategory &&
            canonicalCategory.id === defaultCurrentCanonicalCategory.id) {
            return "active";
        }
        return '';
    }

    const onTabClick = (currentCanonicalCategory, item) => {
        return currentCanonicalCategory.id !== item.id ? hideLabelsForm() : null;
    };

    const onClick = (currentStoreId, currentCategory) => {
        RetailCompassStorage.setLastSelectedStoreAndCategory(
            currentStoreId,
            currentCategory.id,
            currentCategory.key
        )

        forceAbortController()
        onTabClick(currentCanonicalCategory, currentCategory)
        GaUtils.trackGAPage()
    }

    const CustomLink = ({ currentCategory, params = {} }) => {
        const isStoreTypeBrand = RetailCompassStorage.isStoreTypeBrand();
        const currentStoreId = RetailCompassStorage.getStoreId();
        const activeClassName = getActiveClassName(currentCategory, currentCanonicalCategory);

        let classNameParams = {
            "topbar__nav-link": true,
            'topbar__nav-menu-underline': get(params, 'shouldIncludeUnderlineClassName', true),
            "hidden": currentCategory.hidden || false
        };

        classNameParams[activeClassName] = Utils.hasValue(activeClassName);
        const className = classnames(classNameParams);

        return (
            <Link
                data-testid="category-link"
                key={currentCategory.id}
                data-category-id={currentCategory.id}
                className={className}
                to={RouterUtils.buildCategoryPartMenuPath(currentCategory.key, isStoreTypeBrand)}
                onClick={() => onClick(currentStoreId, currentCategory)}>
                {currentCategory.name}
            </Link>
        )
    }

    return (
        isLoggedIn() && show ?
            <>
                {
                    categoriesMapped
                        .filter(item => item.isVisible)
                        .map((item) => <CustomLink currentCategory={item} key={item.id} />)
                }
                <DropdownMenuCategories currentCanonicalCategory={currentCanonicalCategory} categories={categoriesMapped} onClick={onClick} getActiveClassName={getActiveClassName} />
            </>
            : null
    );
}


const mapStateToProps = (state) => {
    return {
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        hideLabelsForm: () => dispatch(hideLabelsForm()),
    };
};

export const CategoryPartMenuConnected = connect(mapStateToProps, mapDispatchToProps)(CategoryPartMenu);

export default withRouter(CategoryPartMenuConnected);