import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import CounterPercent from '@common/components/src/ui/CounterPercent';
import { RetailCompassStorage, ReduxFormsUtils, GaUtils, Constantes } from "@common/utils/"
import { filter, sumBy, orderBy, map, find, } from 'lodash';

export default class CrackStock extends PureComponent {

    static propTypes = {
        stores: PropTypes.array.isRequired,
        isFetching: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
    }

    buildInfoForCrackReport(stores) {
        let storesDetail = [];
        for (let i = 0; i < stores.length; i++) {
            let store = stores[i];
            let brandsDetail;
            if (typeof store.aggregations === "undefined"
                || typeof store.aggregations.brandsShare === "undefined") {
                brandsDetail = [];
            } else {
                brandsDetail = this.buildBrandDetail(store.aggregations.brandsShare, store);
            }
            if (brandsDetail.length > 0) {
                let brandDetailMain = find(brandsDetail, ["isMain", true]);
                storesDetail.push({
                    id: store.storeId,
                    label: store.name,
                    items: brandsDetail,
                    percentOrder: this.calculatePercentOrder(brandDetailMain),
                    totalOrder: this.calculateTotalOrder(brandDetailMain)
                });
            }
        }
        return storesDetail;
    }

    calculateTotalOrder(brandDetail) {
        if (!brandDetail) {
            return 0;
        }
        return brandDetail.total;
    }

    calculatePercentOrder(brandDetail) {
        if (!brandDetail || brandDetail.total === 0) {
            return 0;
        }
        return brandDetail.count / brandDetail.total;
    }

    buildBrandDetail(brandsShare, store) {
        if (!brandsShare || brandsShare.length == 0) {
            return [];
        }
        const myConfigBrands = RetailCompassStorage.getBrandIds();
        let myBrands = filter(brandsShare, (value) => {
            return myConfigBrands.includes(value.brandId);
        });
        if (!myBrands || myBrands.length == 0) {
            return [];
        }
        const total = sumBy(myBrands, 'count');
        const totalStock = sumBy(myBrands, 'active');
        const totalOutStock = sumBy(myBrands, 'outStock');

        const brandIds = map(myBrands, 'brandId').join(',');
        const sttoreName = store.name;
        const id = store.storeId + '-' + brandIds;

        return [
            { id: id, label: sttoreName, count: totalOutStock, total: total, barClassName: 'percent-main-progress', isMain: true },
            { id: id, label: sttoreName, count: totalStock, total: total, barClassName: 'percent-secundary-progress', isMain: false },
        ]
    }

    calculateTotalCrack(storesDetail) {
        let total = 0;
        for (let i = 0; i < storesDetail.length; i++) {
            let store = storesDetail[i];
            total += find(store.items, ["isMain", true]).count;
        }
        return total;
    }

    goToProductList(params) {
        this.props.history.push({
            pathname: 'list',
            search: ReduxFormsUtils.converterFormFieldsToUrlSearchParams(params),
            state: { from: this.props.location, needRerender: true }
        });
    }

    render() {
        const self = this;
        const { stores } = self.props;
        if (!stores || stores.length == 0) {
            return null;
        }
        let storesDetail = self.buildInfoForCrackReport(stores);
        let totalCrack = self.calculateTotalCrack(storesDetail);

        storesDetail = orderBy(storesDetail, ['percentOrder', 'totalOrder'], ['desc', 'desc']);

        let onClickEvent = (item) => {
            const paramArray = item.id.split("-");
            const myConfigBrands = RetailCompassStorage.getBrandIds();
            let params = { brands: myConfigBrands.map((id) => ({ value: id })), stores: [{ value: paramArray[0] }] };
            GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.DASHBOARD, Constantes.GA_ACTION_EVENTS.OUT_OF_STOCK, RetailCompassStorage.getCurrentCanonicalCategory().name, item.total);
            self.goToProductList(params);
        }

        let mainLabel = totalCrack === 1 ? 'QUIEBRE DE STOCK' : 'QUIEBRES DE STOCK';
        
        return (<CounterPercent
            mainLabel={mainLabel}
            total={totalCrack}
            counterItems={storesDetail}
            onClickItem={onClickEvent}
        />);
    }
}
