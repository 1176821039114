import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import InteractiveCounter from "@common/components/src/ui/InteractiveCounter";
import classNames from 'classnames';
import { change } from 'redux-form';
import { Constantes, Utils } from "@common/utils/";
import { get } from 'lodash';

let containerClassName = classNames(
    'counters-container'
);

const HAVE_IT_ID = 1;
const DONT_HAVE_IT_ID = 2;

const EXPENSIVE_ID = 4;
const SAME_ID = 5;
const CHEAPER_ID = 6;

export default class CounterView extends Component {

    static propTypes = {
        labelHaveIt: PropTypes.string.isRequired,
        labelMatches: PropTypes.string.isRequired,
        labelHaveItSingular: PropTypes.string,
        labelMatchesSingular: PropTypes.string,
        myAggregations: PropTypes.object,
        total: PropTypes.number,
        onSelectCounterItem: PropTypes.func,
        currentFilter: PropTypes.object.isRequired
    };

    static defaultProps = {}

    constructor(props) {
        super(props);
        this.fetchProductItems = this.fetchProductItems.bind(this);
        this.onCounterSelection = this.onCounterSelection.bind(this);
        this.getComputedValuesFromSelectedCounter = this.getComputedValuesFromSelectedCounter.bind(this);
    }

    getComputedValuesFromSelectedCounter(selected, value) {
        let haveItDontHaveitValues = [HAVE_IT_ID, DONT_HAVE_IT_ID];
        let competitivenessValues = [EXPENSIVE_ID, SAME_ID, CHEAPER_ID];

        if (haveItDontHaveitValues.includes(selected)) {
            return {
                haveIt: selected === HAVE_IT_ID && value,
                dontHaveIt: selected === DONT_HAVE_IT_ID && value,
                expensive: false,
                same: false,
                cheaper: false
            }
        } else if (competitivenessValues.includes(selected)) {
            return {
                expensive: (selected === EXPENSIVE_ID) ? value : this.props.currentFilter.expensive,
                same: (selected === SAME_ID) ? value : this.props.currentFilter.same,
                cheaper: (selected === CHEAPER_ID) ? value : this.props.currentFilter.cheaper,
                haveIt: false,
                dontHaveIt: false
            }
        }
        return {};
    }

    onCounterSelection(selected, value) {
        //Se unifica el evento para lograr el funcionamiento "tipo radiogroup"
        let values = this.getComputedValuesFromSelectedCounter(selected, value);

        if (Utils.isFunction(this.props.onSelectCounterItem)) {
            this.props.onSelectCounterItem(selected, values);
        } else {
            let currentFilter = { ...this.props.currentFilter, ...values };
            this.fetchProductItems(currentFilter, values);
        }
    }

    fetchProductItems(params, fields) {
        Object.keys(fields).forEach((key) => {
            this.props.dispatch(change(Constantes.FORM_NAMES.FILTERS_FORM, key, fields[key]));
        })
        let currentFilter = { ...this.props.currentFilter, ...params };
        currentFilter.paginationCurrentPage = 1;
        this.props.fetchProductItems(currentFilter);
    }

    render() {

        const { myAggregations, total, currentFilter } = this.props;
        if (!myAggregations) {
            return null;
        }
        let totalWithMatches = myAggregations.moreExpensive + myAggregations.same + myAggregations.cheaper;

        return ([<Card key={1} className={containerClassName} >
            <CardBody>
                <Row>
                    <Col md={6} className="counter-container" style={get(this.props, 'customStyle.haveIt', undefined)}>
                        <InteractiveCounter
                            counterItems={[{
                                id: HAVE_IT_ID,
                                label: "Tiene tu tienda",
                                total: myAggregations.haveIt || 0,
                                barClassName: 'counter-lo-tienes',
                                percentageClassName: 'counter-lo-tienes-percentage',
                                isSelected: currentFilter && currentFilter.haveIt ? currentFilter.haveIt : false,
                                enableCounterPercentageClick: false
                            },
                            {
                                id: DONT_HAVE_IT_ID,
                                label: "No tiene tu tienda",
                                total: myAggregations.dontHaveIt || 0,
                                barClassName: 'counter-no-lo-tienes',
                                percentageClassName: 'counter-no-lo-tienes-percentage',
                                isSelected: currentFilter && currentFilter.dontHaveIt ? currentFilter.dontHaveIt : false,
                                enableCounterPercentageClick: false
                            }]}
                            label={total === 1 && this.props.labelHaveItSingular ? this.props.labelHaveItSingular : this.props.labelHaveIt}
                            total={total}
                            onSelectItem={(item) => {
                                this.onCounterSelection(item.id, true);
                            }}
                            onDeselectItem={(item) => {
                                this.onCounterSelection(item.id, false);
                            }}
                            formatPercentage={() => {
                                return ''
                            }}
                        />
                    </Col>
                    <Col md={6} className="counter-container" style={get(this.props, 'customStyle.matches', undefined)}>
                        <InteractiveCounter
                            counterItems={[{
                                id: EXPENSIVE_ID,
                                label: "Más caros",
                                total: myAggregations.moreExpensive || 0,
                                barClassName: 'counter-mas-caros',
                                percentageClassName: 'counter-mas-caros-percentage',
                                isSelected: currentFilter && currentFilter.expensive ? currentFilter.expensive : false,
                                enableCounterPercentageClick: false
                            },
                            {
                                id: SAME_ID,
                                label: "Igualados",
                                total: myAggregations.same || 0,
                                barClassName: 'counter-igualados',
                                percentageClassName: 'counter-igualados-percentage',
                                isSelected: currentFilter && currentFilter.same ? currentFilter.same : false,
                                enableCounterPercentageClick: false
                            },
                            {
                                id: CHEAPER_ID,
                                label: "Más baratos",
                                total: myAggregations.cheaper || 0,
                                barClassName: 'counter-mas-baratos',
                                percentageClassName: 'counter-mas-baratos-percentage',
                                isSelected: currentFilter && currentFilter.cheaper ? currentFilter.cheaper : false,
                                enableCounterPercentageClick: false
                            }]
                            }
                            label={totalWithMatches === 1 && this.props.labelMatchesSingular ? this.props.labelMatchesSingular : this.props.labelMatches}
                            total={totalWithMatches}
                            onSelectItem={(item) => {
                                this.onCounterSelection(item.id, true);
                            }}
                            onDeselectItem={(item) => {
                                this.onCounterSelection(item.id, false);
                            }}
                            formatPercentage={() => {
                                return ''
                            }}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>]);
    }
}
