import React from 'react';

const ToggleSwitch = ({ isOn, handleToggle, onColor }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span
            className={`react-switch-button`}
            style={{ background: isOn && onColor }}
        />
      </label>
    </>
  );
};

export default ToggleSwitch;