import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY
} from '../actions/sidebarActions';

export const initialState = {
  show: false,
  collapse: false
};

export const SidebarReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return Object.assign({}, state, { collapse: !state.collapse });
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return Object.assign({}, state, { show: !state.show });
    default:
      return state;
  }
}