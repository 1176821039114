import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestMarkAsReviewed = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsMarkAsReviewed: true,
        canonicalMarkAsReviewedError: false
    });
}

export const receiveMarkAsReviewed = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsMarkAsReviewed: false,
        canonicalMarkAsReviewedError: false
    });
}

export const receiveMarkAsReviewedError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsMarkAsReviewed: false,
        canonicalMarkAsReviewedError: true
    });
}

const handlers = {
    [ValidationActions.REQUEST_CANONICAL_MARK_AS_REVIEWED]: requestMarkAsReviewed,
    [ValidationActions.RECEIVE_CANONICAL_MARK_AS_REVIEWED]: receiveMarkAsReviewed,
    [ValidationActions.RECEIVE_CANONICAL_MARK_AS_REVIEWED_ERROR]: receiveMarkAsReviewedError,
}

export default handlers