import { getId, getStoreId, isCustomerUser } from "../app/Storage";
import { CHURN_ZERO_TOKEN_ENDPOINT } from "../utils/constantesApi";
import * as Utils from "../utils/utils";

const CONFIG = Utils.ENV.isProduction()
    ? {
          URL: "https://retailcompass.eu2app.churnzero.net/churnzero.js",
          APP_KEY: "1!weS6EwmP5UxFs1e9RTlHEZPAZjtu1xEAlYOVeS0OYpItFDC",
      }
    : {
          URL: "https://retailcompass-dev.eu2app.churnzero.net/churnzero.js",
          APP_KEY: "1!3xnQr9xB5uBDahqJXFvZCJaXzm5mAGBF7-jPGkx-oZQtFDB",
      };

function load(onload) {
    if (Utils.ENV.isJest()) {
        window.ChurnZero = [];
        onload();
        return;
    }
    (function (a, b, c, d) {
        var cz = a.createElement(b);
        cz.type = c;
        cz.async = true;
        cz.src = d;
        cz.onload = onload;
        var s = a.getElementsByTagName(b)[0];
        s.parentNode.insertBefore(cz, s);
    })(document, "script", "text/javascript", CONFIG.URL);
}

export function init() {
    const onload = async () => {
        const userId = getId();
        const storeId = getStoreId();
        const sessionId = `CZLogin-${userId}-${storeId}`;
        const token = await getToken(userId, storeId);

        window.ChurnZero.push(["setAppKey", CONFIG.APP_KEY]);
        window.ChurnZero.push([
            "setAuth",
            () => ({
                userId,
                storeId,
                token,
            }),
        ]);
        window.ChurnZero.push(["setContact", storeId, userId]);

        const alreadyLogged = sessionStorage.getItem(sessionId);
        if (!alreadyLogged) {
            trackEvent({ eventName: "scalibur_login" });
            sessionStorage.setItem(sessionId, "true");
        }
    };

    if (
        (Utils.ENV.isDevelopment() || Utils.ENV.isTest() || isCustomerUser()) &&
        !window.ChurnZero
    ) {
        load(onload);
    }
}

export function trackEvent({ eventName, description, quantity, customFields }) {
    if (!window.ChurnZero) return;
    window.ChurnZero.push([
        "trackEvent",
        eventName,
        description,
        quantity,
        customFields,
    ]);
}

export function open() {
    if (!window.ChurnZero) return;
    window.ChurnZero.push(["open"]);
}

export function close() {
    if (!window.ChurnZero) return;
    window.ChurnZero.push(["close"]);
}

async function getToken(userId, storeId) {
    return fetch(CHURN_ZERO_TOKEN_ENDPOINT, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, storeId }),
    })
        .then((response) => response.json())
        .then((data) => data.token);
}
