import React from 'react';
import {
    RetailCompassStorage,
    Utils,
    GaUtils,
    Constantes,
    ChurnZero
} from "@common/utils/";
import { isEqual, isEmpty } from 'lodash';
import SortingSelect from './SortingSelect';

export const configureViewPriceComponent = (props) => {
    const options = getOptions();
    const itemSelected = props.viewPriceValue ? options.find(e => isEqual(e.value, props.viewPriceValue)) : options[0];

    const handleChange = (selectedValue) => {
        props.onChangeViewPrice(selectedValue, props);
        ChurnZero.trackEvent({
            eventName: "scalibur_filtro_tres_precios",
            customFields: { valor: selectedValue.label }
        });
    }

    return (
        <SortingSelect
            overflowY="hidden"
            input={{ value: itemSelected }}
            name="product-column-sort"
            placeholder="Select"
            onChange={handleChange}
            options={options}
            enableHandleCloseMenuOnScroll={true}
            singleValuePrefix="Ver: "
        />
    );
}

export const getInitValueViewPrice = (props) => {
    const lastView = props.lastSearchRequest && props.lastSearchRequest.behaviour ? props.lastSearchRequest.behaviour.prices : null;
    const itemToSearch = props.currentFilter && props.currentFilter.viewPrices ? props.currentFilter.viewPrices : RetailCompassStorage.getPriceView();

    if (lastView && !isEqual(lastView, itemToSearch) && isValidOption(lastView)) {
        RetailCompassStorage.setPriceView(lastView);
        return lastView;
    }
    return itemToSearch;
}

export const onChangeViewPrice = (itemSelected, props) => {
    props.currentFilter.viewPrices = itemSelected.value;
    props.currentFilter.paginationCurrentPage = 1;
    RetailCompassStorage.setPriceView(itemSelected.value);
    props.fetchProductItems(props.currentFilter);
    trackGA(Constantes.GA_CATEGORY_EVENTS.PRODUCT_LIST, Constantes.GA_ACTION_EVENTS.PRICES, itemSelected);
}

export const trackGA = (category, action, itemSelected) => {
    let eventCategory = category;
    let eventAction = action;
    let eventLabel = isEmpty(itemSelected.value) ? "" : itemSelected.value.join("&").toLowerCase();
    let eventValue = null;
    if(!isEmpty(itemSelected.value)){
        GaUtils.trackGAEvent(eventCategory, eventAction, eventLabel, eventValue, null);
    }
}

const getOptions = () => {
    return [
        { label: "Todos los precios", value: ["ALL"] },
        { label: "Precio oferta y precio normal", value: ["OFFER", "NORMAL"] },
        { label: "Precio normal", value: ["NORMAL"] }
    ]
}

export const isValidOption = (value) => {
    return Utils.isObjectWithData(getOptions().find(e => isEqual(e.value, value)));
}
