import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { CustomTable } from '@common/components';
import ValidationCell from './ValidationCell';
import { Utils, Constantes } from "@common/utils/"
import SuggestedActions from "./SuggestedActions";

export class SuggestedMatchesTable extends Component {

    static propTypes = {
        currentProduct: PropTypes.object.isRequired,
        isTransformingTheMatch: PropTypes.bool,
        handleApproveClick: PropTypes.func,
        handleRejectClick: PropTypes.func,
        onEmpty: PropTypes.object,
        suggestedMatches: PropTypes.array,
        showImageComparer: PropTypes.bool,
        comparerLeftTitle: PropTypes.string,
        comparerRightTitle: PropTypes.string,
        comparerThumbnailMainTitle: PropTypes.string,
        computeCustomTitle: PropTypes.func,
        showViewCanonicalProductLink: PropTypes.bool,
        isCellPhoneCategory: PropTypes.bool
    };

    static defaultProps = {
        handleApproveClick: () => { },
        handleRejectClick: () => { },
        isTransformingTheMatch: false,
        showViewCanonicalProductLink: false,
        isCellPhoneCategory: false,
        onEmpty: null,
        suggestedMatches: [],
        comparerRightTitle: "Candidato a match: "
    };

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        let { currentProduct, isTransformingTheMatch, isRemovingTheMatch, suggestedMatches, computeCustomTitle,
            computeApproveButtonText, showViewCanonicalProductLink } = this.props;


        const matches = suggestedMatches.map(o => ({ ...o, canonicalProduct: this.props.currentProduct }))
        if (matches.length === 0) {
            return this.props.onEmpty
        }
        const suggestedActionsProps = {
            showApproveButton: true,
            computeApproveButtonText: computeApproveButtonText,
            showRejectButton: true,
            isTransformingTheMatch: isTransformingTheMatch,
            isRemovingTheMatch: isRemovingTheMatch,
            btnClass: "btn__sm",
            handleApproveClick: this.props.handleApproveClick,
            handleRejectClick: this.props.handleRejectClick,
            comparerLeftTitle: PropTypes.string,
            comparerRightTitle: PropTypes.string
        }

        let titleText = Utils.getCountText(matches.length, "CANDIDATO A MATCH", "CANDIDATOS A MATCH");

        if (computeCustomTitle) {
            titleText = computeCustomTitle(matches.length);
        }

        return (<CustomTable
            responsive={true}
            headers={[{
                key: 'validationCell',
                name: titleText,
                sortable: false,
                className: 'header-min-price',
                cellClass: 'validation-cell',
                formatter: (product) => {
                    return <ValidationCell
                        product={product}
                        priceType={Constantes.PriceType.MIN_PRICE}
                        showImageComparer={true}
                        showViewCanonicalProductLink={showViewCanonicalProductLink}
                        comparerLeftTitle={this.props.comparerLeftTitle}
                        comparerRightTitle={this.props.comparerRightTitle}
                        productActions={<SuggestedActions {...suggestedActionsProps} product={product} />}
                        comparerThumbnailMainTitle={this.props.comparerThumbnailMainTitle}
                    />
                }
            }]}
            rows={matches}
            tableIdId='validationsTable--candidates'
            className={'table--bordered validations-module'}
            rowKey="productId"
            onSort={() => { }}
            count={matches.length}
            allRows={[]}
            rowInfo={null}
            key={currentProduct.productId}
            sortKeyDefault={'validationCell'}
            sortDirectionDefault={'ASC'}
            onRowClick={() => { }}
        />
        )
    }
}
export default SuggestedMatchesTable;
