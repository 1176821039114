/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class loginService.js
 * @date 17 oct. 2018
 */
import { getAsJson, validateParams, postAsJson } from './AbstractService';
import { ConstantesApi } from '@common/utils';


export const fetchLogin = (params) => {
    validateParams(params);
    return getAsJson({ api: ConstantesApi.API_USERS, ...params });
};
export const sessionInformation = (params) => {
    validateParams(params);
    return postAsJson({ api: ConstantesApi.API_USERS, ...params });
};