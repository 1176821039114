
import * as RetailCompassStorage from '../app/Storage';
import * as Constantes  from '../utils/constantes'

const Delighted = () => {
    if(document.getElementsByTagName("script").length === 0){
        const script = document.createElement('script');
        document.body.appendChild(script); //test relevant
    }
    const user = RetailCompassStorage.getUser();
    // eslint-disable-next-line no-unused-expressions
    !function(e,t,r,n,a){if(!e[a]){for(var i=e[a]=[],s=0;s<r.length;s++){var c=r[s];i[c]=i[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);i.push([e,t])}}(c)}i.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+n+"/"+a+".js";var l=t.getElementsByTagName("script")[0];l.parentNode.insertBefore(o,l)}}(window,document,["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],"jeVCtl3fTK3QsGW3","delighted"); //NOSONAR
    // eslint-disable-next-line no-undef
    delighted.survey({
        email: user.email,
        name: user.name,
        createdAt: new Date(user.created).toISOString(),
        properties: {
            company: user.currentStore.name,
            nombre: user.name,
            pais: user.currentStore.country,
            appName: Constantes.APP_NAME
        }
    });
}

export const  load = ( forceAllUsers = false ) => {
    if ((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
        return;
    }
    
    if(!window._delighted && 
         RetailCompassStorage.isLoggedIn() && 
        ( forceAllUsers || RetailCompassStorage.isCustomerUser() )){
        Delighted();
    }
}

