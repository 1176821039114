import React, { Component } from 'react';
import { Col, Container, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Hotjar, Utils, Constantes, GaUtils } from '@common/utils/'
import { SearchBox, WhereAmINav } from '@common/components'
import ShareForStoresContainer from './shareReports/ShareForStoresContainer';
import ShareForPriceContainer from './shareReports/ShareForPriceContainer';
import CrackStockContainer from './stockReport/CrackStockContainer';
import BarCounter from "@common/components/src/ui/BarCounter";
import { RetailCompassStorage, ReduxFormsUtils } from "@common/utils/"
import { isEqual, includes, map } from 'lodash';

const formName = 'dashboardForm';

export class DashboardBrand extends Component {

  static propTypes = {
    currentCanonicalCategory: PropTypes.object,
    currentRouterView: PropTypes.string,
    fetchReportInfo: PropTypes.func.isRequired,
    applicationReportData: PropTypes.object.isRequired,
    currentFilter: PropTypes.object,
    isFetching: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.getShareOfBrandsData = this.getShareOfBrandsData.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.goToProductList = this.goToProductList.bind(this);
    this.searchBoxAction = this.searchBoxAction.bind(this);
  }

  componentDidMount() {
    Hotjar.load();
    Utils.onUrlChange(this.props.history.location.pathname + this.props.history.location.search);
  }

  componentDidUpdate(prevProps) {
    let currentRouterViewChanged = !isEqual(this.props.currentRouterView, prevProps.currentRouterView);
    let currentCanonicalCategoryChanged = !isEqual(this.props.currentCanonicalCategory, prevProps.currentCanonicalCategory);

    if (currentCanonicalCategoryChanged || currentRouterViewChanged) {
      this.props.fetchReportInfo({});
    }
  }


  onKeyPress(e) {
    e.persist();
    if (e.charCode === 13) {
      this.searchBoxAction(e);
    }
  }

  searchBoxAction(e) {
    e.preventDefault();
    let searchParams = { search: this.props.currentFilter.search };
    searchParams[Constantes.URL_PARAMS.SEARCH_TYPE] = Constantes.SearchType.KEYWORDS;
    this.goToProductList(searchParams);
  }

  goToProductList(params) {
    this.props.history.push({
      pathname: 'list',
      search: ReduxFormsUtils.converterFormFieldsToUrlSearchParams(params),
      state: { from: this.props.location, needRerender: true }
    });
  }

  getShareOfBrandsData() {
    let self = this;
    var data = this.props.applicationReportData;
    if (!data.brands) return null;
    var counterItems = [];
    var total = 0, myTotal = 0;
    var myBrands = RetailCompassStorage.getBrandIds();
    data.brands.forEach(function (item) {
      counterItems.push({
        id: item.brandId,
        value: item.count,
        label: item.brandName,
        onClick: function (a) {
          self.trackGA(item.count);
          self.goToProductList({ brands: [{ value: item.brandId }] });
        }
      });
      total += item.count;
      if (myBrands.indexOf(item.brandId) !== -1) {
        myTotal += item.count;
      }
    });

    return {
      summary1: {
        value: total
      },
      summary2: {
        value: myTotal
      },
      counterItems: counterItems,
      myItemIds: myBrands,
      total: total
    }
  }

  getTotalOtherBrands(brands, ids) {
    if (!brands || brands.length == 0) {
      return 0;
    }
    let count = 0;
    map(brands, (brand) => {
      if (includes(ids, brand.id)) {
        count += brand.value;
      }
    });
    return count;
  }

  trackGA(totalProducts) {
    GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.DASHBOARD, Constantes.GA_ACTION_EVENTS.BRAND_SHARE, this.props.categoryName, totalProducts);
  }

  render() {
    const { t } = this.props;
    var shareOfBrands = this.getShareOfBrandsData();
    let self = this;

    return (
      <div className="wrapper wrapper--full-width clearfix">
        <Container className="brands-dashboard-container clearfix">

          <Col sm={12} className="where-am-i-nav">
            <WhereAmINav
              currentViewText={t('dashboard')}
              showGoBackToLink={false}
            />
          </Col>

          <Col sm={4}>
            <Card className="side-card">
              <CardBody>
                <CrackStockContainer />
              </CardBody>
            </Card>
          </Col>
          <Col sm={8}>
            <Card className="search-card">
              <CardBody>
                <SearchBox
                  formName={formName}
                  title={"Buscador de "+this.props.categoryName}
                  subtitle={"Escribe modelo o palabra clave"}
                  onSubmit={this.searchBoxAction}
                  hasActiveSearch={false}
                  onKeyPress={this.onKeyPress}
                  onButtonClick={this.searchBoxAction}
                  onSearchReset={this.searchBoxAction}
                  showCategories={false}
                  buttonText={"Buscar"}
                />
              </CardBody>
            </Card>
            <Card className="main-card">
              <CardBody>
                <div className="share-brand clearfix">
                  {
                    shareOfBrands ?
                      <BarCounter
                        title={"PRODUCTOS EN EL MERCADO"}
                        summary1={{
                          value: shareOfBrands.summary1.value,
                          legend: "Todos",
                          url: "#",
                          onClick: (event) => {
                            let nativeEvent = event.nativeEvent;
                            nativeEvent.preventDefault();
                            self.trackGA(shareOfBrands.summary1.value);
                            self.goToProductList({});
                          }
                        }}
                        summary2={{
                          value: shareOfBrands.summary2.value,
                          legend: "Solo tuyos",
                          url: "#",
                          onClick: (event) => {
                            let nativeEvent = event.nativeEvent;
                            nativeEvent.preventDefault();
                            self.trackGA(shareOfBrands.summary2.value);
                            self.goToProductList({ brands: shareOfBrands.myItemIds.map((id) => ({ value: id })) });
                          }
                        }}
                        counterItems={shareOfBrands.counterItems}
                        myItemsIds={shareOfBrands.myItemIds}
                        maxBars={10}
                        total={shareOfBrands.total}
                        onOthersClick={function (ids) {
                          self.trackGA(self.getTotalOtherBrands(shareOfBrands.counterItems, ids));
                          self.goToProductList({ brands: ids.map((id) => ({ value: id })) });
                        }}
                      />
                      : null
                  }
                </div>
                <div className="share-retailer">
                  <ShareForStoresContainer />
                </div>
                <div className="share-price">
                  <ShareForPriceContainer />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    )
  }
}

export const DashboardBrandWithReduxForm = reduxForm({ form: formName })(DashboardBrand);
const DashboardBrandWithI18n = (translate('common')(DashboardBrandWithReduxForm));

export default DashboardBrandWithI18n;
