import React from 'react';
import moment from 'moment';
import { Constantes, GaUtils } from '@common/utils';
import IntervalDatePicker from '../../ui/IntervalDatePicker';

export const DateRangePicker = ({ start, end, onButtonClick, categoryEvent }) => {

    const onManualChange = (params) => {
        onButtonClick(params);
        const days = params.end.diff(params.start, 'days');
        trackGAEvent(days);
    }

    const trackGAEvent = (value) => {
        GaUtils.trackGAEvent(
            categoryEvent,
            Constantes.GA_ACTION_EVENTS.COMPARE.DATES,
            Constantes.GA_LABELS_EVENTS.COMPARE.MANUAL,
            value
        );
    }

    return (
        <IntervalDatePicker
            input={{ onChange: () => { } }}
            start={start}
            end={end}
            onButtonClick={onManualChange}
            minDate={moment().utcOffset(0).subtract(Constantes.MAX_HISTORY_TIME.value, Constantes.MAX_HISTORY_TIME.unit).startOf("day")}
            maxDate={moment().utcOffset(0).endOf("day")}
        />
    )
}