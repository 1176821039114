import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { chunk, find, remove, isEqual } from 'lodash';

const MultiOptionButtonGroupPropType = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired
});

export default class MultiOptionButtonGroup extends Component {
    static propTypes = {
        rangeItems: PropTypes.arrayOf(MultiOptionButtonGroupPropType).isRequired,
        totalItemsByLine: PropTypes.number.isRequired,
        onChange: PropTypes.func,
        selectedRangeItems: PropTypes.arrayOf(MultiOptionButtonGroupPropType)
    };

    static defaultProps = {
        totalItemsByLine: 5
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedRangeItems: [],
            totalItemsByLine: props.totalItemsByLine,
            rangeItems: props.rangeItems,
            isFilterApplied: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.computeClassName = this.computeClassName.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
    }

    handleClick(rangeItem) {
        let selectedRangeItems = this.state.selectedRangeItems || [];
        let cointainsItem = find(selectedRangeItems, { id: rangeItem.id });
        if (!cointainsItem) {
            selectedRangeItems.push(rangeItem);
        } else {
            remove(selectedRangeItems, { value: rangeItem.value });
        }
        this.setState({
            selectedRangeItems
        });
        this.props.onChange(selectedRangeItems, rangeItem);
    }



    removeFilter(ignoreOnChange) {
        this.setState({
            selectedRangeItems: []
        });

        if (!ignoreOnChange && this.props.onChange) {
            this.props.onChange([]);
        }
    }

    buildRangeLimits(itemStart, itemEnd) {
        let start = itemStart.index;
        let end = itemEnd.index;
        if (start > end) {
            start = itemEnd.index;
            end = itemStart.index;
        }
        return { start, end };
    }

    computeClassName(rangeItem) {
        let selectedRangeItems = this.state.selectedRangeItems  || [];
        let itemFound = find(selectedRangeItems, { value: rangeItem.value });
        if (itemFound) {
            return 'selection-type--unique';
        }
        itemFound = find(this.props.suggestedItems, { value: rangeItem.value });
        if (itemFound) {
            return 'item--suggested';
        }
        return '';
    }

    componentDidUpdate(prevProps, prevState){
        if(!isEqual(prevProps.selectedRangeItems ,this.props.selectedRangeItems)
            && !isEqual(this.state.selectedRangeItems ,this.props.selectedRangeItems) ){
            this.setState({ selectedRangeItems: this.props.selectedRangeItems})
        }
    }

    render() {
        const { rangeItems, totalItemsByLine } = this.state;
        const { buttonLayout } = this.props;  
        if (rangeItems.length <= 0) {
            return null;
        }
        const _rangeItems = rangeItems.map((item, index) => ({ ...item, index: index }));
        const screenSizeGroupItems = chunk(_rangeItems, totalItemsByLine);

        return (<div className={!buttonLayout ? "screen-size-picker-container": "button-layout-container"}>

            <div className="table group">
                <div>
                    {screenSizeGroupItems.map((groupItems, groupIndex) => {
                        return <div key={groupIndex} className="line">{groupItems.map((group, index) => {
                            if (group.isFake) {
                                return <div key={index} className="item-fake">a</div>
                            }
                            return (<div key={index}
                                onClick={() => this.handleClick({ ...group })}
                                className="item">
                                <div className={"item-label-wrapper item-label-button-group " + this.computeClassName(group)}>
                                    <span className="label">{group.label}</span>
                                </div>
                            </div>)
                        })}</div>
                    })}
                </div>
            </div>


        </div>);
    }
}
