import Dexie from 'dexie';

/* https://dexie.org/docs/API-Reference */
const db = new Dexie("Postpaid");
db.version(1).stores({
    searches: "++id, [model+storage], lastSearch, searchCount",
});

export const database = db;

export async function putSearch(search) {
    if (!isValidSearch(search)) {
        return Promise.reject('invalid search to be saved: ', search);
    }
    const { model, storage } = search;
    const currentSearch = await db.searches
        .where("[model+storage]")
        .equals([model, storage])
        .first();

    const newSearch = {
        model,
        storage,
        lastSearch: new Date(),
        searchCount: currentSearch ? currentSearch.searchCount + 1 : 1
    }

    return db.searches.put(currentSearch
        ? { ...newSearch, id: currentSearch.id }
        : newSearch);
}

export async function getSearchesForSuggestions() {
    return db.searches
        .orderBy("lastSearch").reverse()
        .limit(30)
        .toArray();
}

function isValidSearch(search) {
    return search && search.model && search.storage;
}
