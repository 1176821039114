import React from "react";
import classnames from "classnames";
import ReactTable from "react-table";
import withStickyColumns from "../../hoc/withStickyColumns/withStickyColumns";
import "./ShippingReportTable.scss";

const StickyColumnsTable = withStickyColumns(ReactTable);

const ShippingReportTable = (props) => {
    return (
        <StickyColumnsTable
            {...props}
            className={classnames(props.className, "rt-shipping")}
        />
    );
};

export default ShippingReportTable;
