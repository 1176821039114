import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { find, remove, uniqBy, isEqual, filter, isEmpty } from 'lodash';

const SELECTION_TYPE = {
    LIST: 'list',
    SINGLE: 'single'
}
const withLabelsList = (WrappedComponent) => {

    return class LabelsListHOC extends Component {

        static propTypes = {
            showAll: PropTypes.bool,
            filterLabels: PropTypes.array,
            labels: PropTypes.array,
            onLabelSelection: PropTypes.func.isRequired,
            onLabelRemoval: PropTypes.func.isRequired,
            selectionType: PropTypes.oneOf([SELECTION_TYPE.SINGLE, SELECTION_TYPE.LIST])
        };

        static defaultProps = {
            selectionType: SELECTION_TYPE.LIST
        }

        constructor(props) {
            super(props);
            this.state = {
                showAll: false,
                selectedLabels: [],
            }

            this.onLabelClick = this.onLabelClick.bind(this);
            this.onLabelSelection = this.onLabelSelection.bind(this);
            this.onLabelRemoval = this.onLabelRemoval.bind(this);
            this.isActive = this.isActive.bind(this);
            this.getSelectedLabels = this.getSelectedLabels.bind(this);
            this.updateStateWithSelectedLabels = this.updateStateWithSelectedLabels.bind(this);
            this.onListLabelRemoval = this.onListLabelRemoval.bind(this);
        }

        componentDidUpdate(prevProps) {
            const newFilter = this.props.filterLabels;
            const oldFilter = prevProps.filterLabels;
            const filteredLabelsChanged = !isEqual(newFilter, oldFilter);
            const labelsChanged = !isEmpty(this.props.labels) && isEmpty(prevProps.labels);
            if (filteredLabelsChanged || labelsChanged) {
                this.updateStateWithSelectedLabels(newFilter);
            }
        }

        updateStateWithSelectedLabels(newFilter) {
            newFilter = newFilter || []
            const ids = newFilter.map((value) => { return value.toString().split('_')[0]; });
            const newSelected = filter(this.props.labels, (label) => ids.includes(label.id.toString()));
            this.setState({ selectedLabels: newSelected });
        }

        getSelectedLabels(item) {
            let selected = this.state.selectedLabels || [];
            selected.push(item);
            let selectedLabels = uniqBy(selected, 'id');
            let ids = selectedLabels.map(item => item.id);
            selectedLabels = this.props.labels.filter((label) => ids.includes(label.id));
            return selectedLabels;
        }

        onLabelSelection(item, keepSelected) {
            let selectedLabels = this.getSelectedLabels(item);

            if (this.props.selectionType === SELECTION_TYPE.SINGLE) {
                selectedLabels = [item];
            }

            if (keepSelected) {
                this.setState({ selectedLabels: selectedLabels });
            } else {
                this.setState({ selectedLabels: [] });
            }

            this.props.onLabelSelection(selectedLabels);
        }

        onLabelRemoval(item) {
            let selected = this.state.selectedLabels;
            remove(selected, function (obj) { return item.id === obj.id });
            this.setState({ selectedLabels: selected });
            this.props.onLabelRemoval(selected);
        }

        onListLabelRemoval(labels) {
            let selected = this.state.selectedLabels;
            remove(selected, function (obj) { return labels.map(e => e.id).includes(obj.id) });
            this.setState({ selectedLabels: selected });
            this.props.onLabelRemoval(selected);
        }

        onLabelClick(item) {
            if (this.isActive(item)) {
                this.onLabelRemoval(item);
            } else {
                this.onLabelSelection(item, true);
            }
        }

        isActive(item) {
            let found = find(this.state.selectedLabels, ["id", item.id]);
            return !!found;
        }


        render() {
            let otherProps = {
                isActive: this.isActive,
                onLabelClick: this.onLabelClick,
                onLabelRemoval: this.onLabelRemoval,
                onLabelSelection: this.onLabelSelection,
                onListLabelRemoval: this.onListLabelRemoval
            }
            return <WrappedComponent {...this.props} {...otherProps} />
        }
    }
}

export default withLabelsList;
