import { clone, isEqual, isNil, max, min } from 'lodash';
import { Constantes, Utils } from "@common/utils";
import { PriceStyles } from "./constants/Styles";

export const productStyleName = {
    bestPrice: "bestPrice",
    higherPrice: "higherPrice",
    lowestPrice: "lowestPrice",
    normalPrice: "normalPrice"
};

export const getProductStyleType = (product, priceType) => {
    const price = getPriceByPriceType(product, priceType);

    if (product.status === Constantes.STATUS_PRODUCT.WITH_STOCK) {
        if (price === product.bestPriceAmongSellers && price === product.price) {
            return productStyleName.bestPrice;
        } else if (price === product.higherPriceAmongSellers && price === product.price) {
            return productStyleName.higherPrice;
        } else if (product.price === price) {
            return productStyleName.lowestPrice;
        } else if (priceType === Constantes.PriceType.NORMAL) {
            return productStyleName.normalPrice;
        }
    }

    return "other";
};

export const getPriceByPriceType = (product, priceType) => {
    if (priceType === Constantes.PriceType.CARD && hasCardPrice(product)) {
        return product.price;
    } else if (priceType === Constantes.PriceType.OFFER && hasOfferPrice(product)) {
        return product.offerPrice;
    } else if (priceType === Constantes.PriceType.NORMAL && hasNormalPrice(product)) {
        return product.normalPrice;
    } else if (priceType === Constantes.PriceType.MIN_PRICE) {
        return min([product.normalPrice, product.offerPrice, product.price]);
    }

    return undefined;
};

export const getPriceMapping = product => {
    if (!!product.alreadyAppliedMap) {
        return;
    }

    const { price, offerPrice, normalPrice, paymentType } = product;
    let has = {
        nuevo_precio: !!offerPrice, precio_normal: !!normalPrice
    };

    let mappedPrices = {};

    if (Utils.isPaymentTypeCard(paymentType)) {
        if (has.nuevo_precio && has.precio_normal
            && price !== offerPrice
            && price !== normalPrice
            && offerPrice !== normalPrice) {
            mappedPrices = { offerPrice, normalPrice };
        } else if ((has.nuevo_precio && has.precio_normal && offerPrice !== normalPrice)
            || (!has.nuevo_precio && has.precio_normal)) {
            mappedPrices = { offerPrice: null, normalPrice };
        } else if (has.nuevo_precio && !has.precio_normal) {
            mappedPrices = { offerPrice: null, normalPrice: offerPrice }
        } else if ((has.nuevo_precio && has.precio_normal && price === offerPrice && offerPrice === normalPrice)
            || (!has.nuevo_precio && !has.precio_normal)) {
            mappedPrices = { offerPrice: null, normalPrice: null }
        } else if (has.nuevo_precio && has.precio_normal
            && offerPrice === normalPrice
            && price !== normalPrice) {
            mappedPrices = { offerPrice: null, normalPrice: normalPrice }
        }
    } else {
        if (has.nuevo_precio && !has.precio_normal && price === offerPrice) {
            mappedPrices = { offerPrice, normalPrice: offerPrice }
        } else if (!has.nuevo_precio && has.precio_normal && price < normalPrice) {
            mappedPrices = { offerPrice: price, normalPrice: normalPrice }
        } else if (has.nuevo_precio && !has.precio_normal) {
            mappedPrices = { offerPrice: price, normalPrice: offerPrice }
        } else if (has.nuevo_precio && has.precio_normal && price === offerPrice && offerPrice === normalPrice) {
            mappedPrices = { offerPrice: null, normalPrice: price };
        } else if (!has.nuevo_precio && !has.precio_normal) {
            mappedPrices = { offerPrice: null, normalPrice: price };
        } else if (has.nuevo_precio && has.precio_normal
            && offerPrice === normalPrice
            && price !== normalPrice) {
            mappedPrices = { offerPrice: price, normalPrice: normalPrice }
        } else if (has.nuevo_precio && has.precio_normal
            && offerPrice !== normalPrice) {
            mappedPrices = { offerPrice, normalPrice }
        }
    }

    mappedPrices.price = price;
    mappedPrices.paymentType = paymentType;
    mappedPrices.alreadyAppliedMap = true;

    return mappedPrices;

};

export const getHigherPrice = product => {
    const normalPrice = !!product.normalPrice ? product.normalPrice : 0;
    const offerPrice = !!product.offerPrice ? product.offerPrice : 0;
    const price = !!product.price ? product.price : 0;

    return Math.max(normalPrice, offerPrice, price);
};

export const hasCardPrice = product => {
    if ((!product || !Utils.isPaymentTypeCard(product.paymentType))
        && !(product.price < product.offerPrice)
    ) {
        return false;
    }

    return true;
};

export const hasOfferPrice = product => {
    if (!product || !product[Constantes.PriceType.OFFER] ||
        ((!product[Constantes.PriceType.OFFER]) && !product[Constantes.PriceType.NORMAL])) {
        return false;
    }

    return true;
};

export const hasNormalPrice = product => {
    if (!product || (!product[Constantes.PriceType.NORMAL] && product.isPriceWithCard)
        || (product[Constantes.PriceType.OFFER] && product[Constantes.PriceType.CARD] && !product[Constantes.PriceType.NORMAL])
    ) {
        return false;
    }

    return true;
};

export const getPriceStyle = (product, priceType) => {
    const styleTypeName = getProductStyleType(product, priceType);

    switch (styleTypeName) {
        case productStyleName.bestPrice:
            return PriceStyles.bestPrice;
        case productStyleName.higherPrice:
            return PriceStyles.higherPrice;
        case productStyleName.lowestPrice:
            return PriceStyles.lowerProductPrice;
        case productStyleName.normalPrice:
            return PriceStyles.normalPrice;
        default:
            return {}
    }
};

export const getPricesCount = product => {
    let prices = [];

    if (!!product.price) {
        prices.push(product.price);
    }

    if (!!product.normalPrice) {
        prices.push(product.normalPrice);
    }

    if (!!product.offerPrice) {
        prices.push(product.offerPrice);
    }

    return prices.length;
};

export const getShouldAddDaysWithoutStock = (product, priceType) => {
    const pricesCount = getPricesCount(product);

    const isValidForOffer = prod => (Utils.isPaymentTypeCard(prod.paymentType) && priceType === Constantes.PriceType.CARD)
        || (!Utils.isPaymentTypeCard(prod.paymentType)
            && (priceType === Constantes.PriceType.OFFER || !prod.offerPrice));

    if (product.status === Constantes.STATUS_PRODUCT.WITHOUT_STOCK) {
        switch (pricesCount) {
            case 3:
                if (product.price < product.offerPrice && product.offerPrice < product.normalPrice
                    && !Utils.isPaymentTypeCard(product.paymentType)) {

                    return priceType === Constantes.PriceType.CARD;
                }

                return isValidForOffer(product);
            case 2:
                return isValidForOffer(product);
            case 1:
                return true;
            default:
                return false;
        }

    }

    return false;
};

export const getMinAndMaxPriceFromList = newItems => {
    let bestPriceAmongSellers = 0;
    let higherPriceAmongSellers = 0;
    newItems.forEach(e => {
        if (e.status === Constantes.STATUS_PRODUCT.WITH_STOCK) {
            bestPriceAmongSellers = (bestPriceAmongSellers === 0 || bestPriceAmongSellers > e.price)
                ? e.price : bestPriceAmongSellers;

            higherPriceAmongSellers = e.price > higherPriceAmongSellers ? e.price : higherPriceAmongSellers;
        }
    });

    return { bestPriceAmongSellers, higherPriceAmongSellers };
};

export const getDiscountBetweenPirces = (match) => {

    if (match.daysWithoutStock > 0 || match.status == Constantes.STATUS_PRODUCT.WITHOUT_STOCK) {
        return null;
    }

    if (!match.normalPrice && !match.offerPrice) {
        return null;
    }

    const price = match.price;
    const offerPrice = match.offerPrice;
    const normalPrice = match.normalPrice;

    const lowPrice = min([price, offerPrice, normalPrice]);
    const highestPrice = max([price, offerPrice, normalPrice]);

    if (lowPrice == highestPrice) {
        return null;
    }

    return Math.round(((lowPrice - highestPrice) * 100 / highestPrice));
}

export const getOffertPriceForDetailCell = (match) => {
    if (!isCardPrice(match)) {
        return null;
    }

    const price = match.price;
    const offerPrice = match.offerPrice;
    const normalPrice = match.normalPrice;

    if (offerPrice && normalPrice) {
        if (price != offerPrice && price != normalPrice) {
            return offerPrice;
        }
    }

    return null;
}

export const isFeeAvailableForCountry = (countryCode) => {
    switch (countryCode) {
        case Constantes.COUNTRIES_CODE.ARGENTINA: 
            return true;
        case Constantes.COUNTRIES_CODE.MEXICO:
            return true;
        default:
            return false;
    }
}

export const feeAbbreviation = (countryCode) => {
    switch (countryCode) {
        case Constantes.COUNTRIES_CODE.ARGENTINA:
            return "SI ";
        case Constantes.COUNTRIES_CODE.MEXICO:
            return "MSI ";
        default:
            return "";
    }
}

export const isVisibleFees = (countryCode, price) => {
    if (isFeeAvailableForCountry(countryCode)
        && !isNil(price)) {
        return true;
    }
    return false;
}

export const isCardPrice = (match) => {
    if (match.isPriceWithCard) {
        return match.isPriceWithCard;
    }

    const price = match.price;
    const offerPrice = match.offerPrice;
    const normalPrice = match.normalPrice;

    if (offerPrice
        && normalPrice
        && price != offerPrice
        && price != normalPrice
        && offerPrice != normalPrice) {
        return true;
    }
    return false;
}

export const getPriceByView = (match, pricesToShow, isPriceWithCard) => {
    if (!match) {
        return null;
    }
    let cloneMatch = clone(match);

    if (pricesToShow.includes("OFFER")) {
        if (cloneMatch.offerPrice && cloneMatch.normalPrice) {
            cloneMatch.price = cloneMatch.offerPrice;
        } else if (isPriceWithCard && !cloneMatch.offerPrice && cloneMatch.normalPrice) {
            cloneMatch.price = cloneMatch.normalPrice;
        } else if (isPriceWithCard && !cloneMatch.offerPrice && !cloneMatch.normalPrice) {
            cloneMatch.price = null;
        }
        cloneMatch.paymentType = "N";
    } else if (isEqual(pricesToShow, ["NORMAL"])) {
        if (cloneMatch.normalPrice) {
            cloneMatch.price = cloneMatch.normalPrice;
        } else if (isPriceWithCard && !cloneMatch.offerPrice && !cloneMatch.normalPrice) {
            cloneMatch.price = null;
        }
        cloneMatch.offerPrice = null;
        cloneMatch.paymentType = "N";
    }

    return cloneMatch;
}
