import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { Utils, Hotjar, AttributesUtils, Constantes, RouterUtils } from "@common/utils/";
import { PropTypes } from "prop-types";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import { getFieldFromContext, productIsApprovable } from '../common/utils/utils';
import CustomModal from "@common/components/src/ui/CustomModal";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MergeCanonicalInputBuilder from '../common/utils/mergeCanonicalInputBuilder'

const TOTAL_STEPS = 5;

export class Approval extends Component {

    static propTypes = {
        currentProduct: PropTypes.object,
        canonicalSearchByKeywords: PropTypes.func.isRequired,
        canonicalSearchResult: PropTypes.array,
        canonicalTransformIntoRemoved: PropTypes.func.isRequired,
        canonicalIsTransformingIntoRemoved: PropTypes.bool.isRequired,
        canonicalMerge: PropTypes.func.isRequired,
        canonicalIsMerging: PropTypes.bool.isRequired,
        canonicalIsTransformingIntoCanonical: PropTypes.bool.isRequired,
        canonicalTransformIntoCanonical: PropTypes.func.isRequired,
        isFetchingSuggestedMatches: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isFetchingSuggestedMatches: false
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            fetchingSuggestedMatchesFinished: false
        };

        this.handleApproveClick = this.handleApproveClick.bind(this);
        this.handleRejectClick = this.handleRejectClick.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.onFinishedTask = this.onFinishedTask.bind(this);
        this.handleCanonicalMerge = this.handleCanonicalMerge.bind(this);
    }

    handleApproveClick(product) {
        this.props.transformIntoIdentical(Utils.buildMatchParams(product, this.props.urlParams.contextInfo, this.props.currentProduct.productId));
    }

    handleRejectClick(product) {
        this.props.transformIntoRemoved(Utils.buildMatchParams(product, this.props.urlParams.contextInfo, this.props.currentProduct.productId));
    }

    handleRemoveClick(product, canonicalProduct = this.props.currentProduct) {
        this.props.removeIdentical(Utils.buildMatchParams(product, this.props.urlParams.contextInfo, canonicalProduct.productId));
    }

    componentDidMount() {
        let { productId, categoryId, contextInfo } = this.props.match.params;
        let { canonicalStoreId } = Utils.getDecodedContextInfo(contextInfo);
        this.props.fetchCategoryAttributes(categoryId);
        this.props.fetchCategoryInfo(categoryId);
        this.props.fetchProductAttributes(productId);

        this.props.fetchProduct({ productId });
        this.props.getSuggestedMatchesByProductId(productId);
        this.props.getStoreAndCompetitors(canonicalStoreId);
        this.props.getAllMatchesByProductId(productId);
        Hotjar.load(true);
    }

    componentDidUpdate(prevProps) {
        // se elimina un producto por no pertenecer a la categoría
        if (prevProps.canonicalIsTransformingIntoRemoved && !this.props.canonicalIsTransformingIntoRemoved) {
            this.onFinishedTask();
        }

        // se une el producto con un canonico ya existente
        if (prevProps.canonicalIsMerging && !this.props.canonicalIsMerging) {
            this.onFinishedTask();
        }

        // se crea el producto como canonico
        if (prevProps.canonicalIsTransformingIntoCanonical && !this.props.canonicalIsTransformingIntoCanonical) {
            this.onFinishedTask();
        }

        // para poder consultar los potenciales duplicados, debemos haber cargado ya los productos sugeridos
        if (prevProps.isFetchingSuggestedMatches && !this.props.isFetchingSuggestedMatches) {
            this.setState({ fetchingSuggestedMatchesFinished: true });
        }
    }

    buildTaskTitle(urlParams) {
        let { taskName } = Utils.getDecodedContextInfo(urlParams.contextInfo);
        if (taskName) {
            return "Tarea: " + taskName;
        }
        return null;
    }

    handleCanonicalMerge(currentDuplicate) {
        let { currentProduct, urlParams } = this.props;
        let { userId, jobId, username } = Utils.getDecodedContextInfo(urlParams.contextInfo);

        let fromProductId = currentProduct.productId
        let toProductId = currentDuplicate.productId

        let input = new MergeCanonicalInputBuilder()
            .setJobId(jobId)
            .setUserId(userId)
            .setFromProductId(fromProductId)
            .setToProductId(toProductId)
            .setUsername(username)
            .setSourceId(Constantes.MATCH_SOURCE_IDS.VALIDATOR)
            .setStoreId(currentDuplicate.storeId)
            .build();
        this.props.canonicalMerge(input);
    }

    getCurrentStep() {
        let { currentProduct, storedAttributes, attributesData, urlParams } = this.props;
        let taskTitle = this.buildTaskTitle(urlParams);
        const isApprovable = productIsApprovable(currentProduct);
        const step = isApprovable ? urlParams.step : "1";
        currentProduct.enhancedAttributes = AttributesUtils.enhanceProductAttributes(storedAttributes, attributesData);

        let commonProps = {
            taskTitle: taskTitle,
            currentProduct: currentProduct,
            attributesData: attributesData,
            storedAttributes: storedAttributes,
            urlParams: this.props.match.params,
            totalSteps: TOTAL_STEPS,
            allMatches: this.props.allMatches,
            isTransformingTheMatch: this.props.isTransformingTheMatch,
            isRemovingTheMatch: this.props.isRemovingTheMatch
        }
        let contextInfo = Utils.getDecodedContextInfo(urlParams.contextInfo);
        switch (step) {
            case "2":
                return (<Step2
                    {...commonProps}
                    competitors={this.props.competitors}
                    searchPotentialMatches={this.props.searchPotentialMatches}
                    potentialMatches={this.props.potentialMatches}
                    handleApproveClick={this.handleApproveClick}
                    handleRejectClick={this.handleRejectClick}
                    createNewMatch={this.props.createNewMatch}
                    suggestedMatches={this.props.suggestedMatches}
                    potentialMatchesEmptyState={this.props.potentialMatchesEmptyState}
                    removeIdentical={this.handleRemoveClick}
                />);
            case "3":
                let canonicalPotentialDuplicates = this.props.canonicalPotentialDuplicates.map(o => (
                    {
                        ...o, enhancedAttributes: AttributesUtils.enhanceAttributesFromProductSearch(o.attributes, attributesData, getFieldFromContext("userId", urlParams.contextInfo))
                    }
                ));
                if (!this.state.fetchingSuggestedMatchesFinished) {
                    return null;
                }
                return (<Step3
                    {...commonProps}
                    canonicalPotentialDuplicates={canonicalPotentialDuplicates}
                    canonicalFindPotentialDuplicates={this.props.canonicalFindPotentialDuplicates}
                    suggestedMatches={this.props.suggestedMatches}
                    canonicalIsFindingPotentialDuplicates={this.props.canonicalIsFindingPotentialDuplicates}
                    onFinishedTask={this.onFinishedTask}
                    canonicalMerge={this.handleCanonicalMerge}
                    contextInfo={contextInfo}
                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                    removeIdentical={this.handleRemoveClick}
                    setDuplicateAsDiscarded={this.props.setDuplicateAsDiscarded}
                    history={this.props.history}
                    isFetchingSuggestedMatches={this.props.isFetchingSuggestedMatches}
                />);
            case "4":
                return (<Step4
                    {...commonProps}
                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                    removeIdentical={this.handleRemoveClick}
                    competitors={this.props.competitors}
                    history={this.props.history}
                    canonicalSearchByKeywords={this.props.canonicalSearchByKeywords}
                    canonicalSearchResult={AttributesUtils.enhanceAttributesToProductSet(this.props.canonicalSearchResult, this.props.attributesData, getFieldFromContext("userId", urlParams.contextInfo))}
                    canonicalSearchResultEmptyState={this.props.canonicalSearchResultEmptyState}
                    canonicalIsSearchingByKeywords={this.props.canonicalIsSearchingByKeywords}
                    onFinishedTask={this.onFinishedTask}
                    canonicalMerge={this.handleCanonicalMerge}
                    contextInfo={contextInfo}
                />);
            case "5":
                return (<Step5
                    {...commonProps}
                    saveProductAttribute={this.props.saveProductAttribute}
                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                    onFinishedTask={this.onFinishedTask}
                    contextInfo={contextInfo}
                    canonicalIsTransformingIntoCanonical={this.props.canonicalIsTransformingIntoCanonical}
                    canonicalTransformIntoCanonical={this.props.canonicalTransformIntoCanonical}
                    removeIdentical={this.handleRemoveClick}
                />);
            default:
                return (<Step1
                    {...commonProps}
                    categoryName={this.props.categoryInfo.name}
                    onFinishedTask={this.onFinishedTask}
                    canonicalTransformIntoRemoved={this.props.canonicalTransformIntoRemoved}
                    contextInfo={contextInfo}
                    isApprovable={isApprovable} />
                );
        }
    }

    onFinishedTask() {
        this.setState({ isOpenModal: true });
    }


    render() {
        if (!Utils.isObjectWithData(this.props.currentProduct)) {
            return null;
        }

        // solo productos con status -6 (por aprobación), pueden ser procesados en este componente
        let productStatusId = get(this.props.currentProduct, 'status');
        if (productStatusId !== Constantes.STATUS_PRODUCT.FOR_APPROVAL) {
            let canonicalStatusItems = [Constantes.STATUS_PRODUCT.WITH_STOCK, Constantes.STATUS_PRODUCT.WITHOUT_STOCK];
            if (canonicalStatusItems.includes(productStatusId)) {
                return <Redirect to={RouterUtils.getValidationEditPathWithParams(this.props.match.params)} />
            }
            return null;
        }

        return (<div className="wrapper wrapper--full-width clearfix approval-view">
            {this.getCurrentStep()}
            <CustomModal
                isOpen={this.state.isOpenModal}
                toggle={() => { }}
                wrapperClassName="approval-success-view"
                body={<div>
                    <div className="icon-container"><CheckCircleOutlineIcon /></div>
                    <h1>¡ Buen trabajo !</h1>
                    <p>Terminaste con este producto y se guardó correctamente</p>
                    <p>Ahora puedes cerrar la pestaña</p>
                </div>}
            />
        </div>
        )
    }
}
export default withRouter(Approval);
