import React, { createElement, useState, useEffect, memo } from "react";
import { Constantes, OperatorUtils } from "@common/utils/";
import ProductDescription from "./headers/ProductDescription";
import ProductDescriptionValidations from "./headers/ProductDescriptionValidations";
import ProductDescriptionPostpago from "./headers/ProductDescriptionPostpago";

const ProductDetailHeader = (props) => {

    const [descriptionComp, setDescriptionComp] = useState();
    const [strategy, setStrategy] = useState();

    const descriptionByStrategy = {
        [Constantes.StoreType.RETAILER]: () => ProductDescription,
        [Constantes.StoreType.BRAND]: () => ProductDescription,
        [Constantes.SubStoreType.RETAILER_POSTPAID]: () => ProductDescriptionPostpago,
        [Constantes.SubStoreType.BRAND_POSTPAID]: () => ProductDescriptionPostpago,
        [Constantes.MODULES.VALIDATION_APP]: () => ProductDescriptionValidations
    }

    useEffect(() => {
        const newStrategy = OperatorUtils.getStoreTypeBasedInCategory(props.tableStrategy);
        setStrategy(newStrategy);
        setDescriptionComp(descriptionByStrategy[newStrategy]);
    }, [])

    const DefaultDescription = () => {
        return (
            <div className="product-detail__card clearfix">
                <div className="triangle"></div>
            </div>
        )
    }

    return descriptionComp ? createElement(descriptionComp, { ...props, tableStrategy: strategy })
        : <DefaultDescription />
}

export default memo(ProductDetailHeader);