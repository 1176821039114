import React from "react";
import * as TableContainer from "../../table/TableContainer";
import * as TableState from "../../table/TableState";
import * as HeadersBuilder from "../../table/HeadersBuilder";
import ShippingCell from "../../shipping/ShippingReportTable/ShippingCell";
import ShippingPriceCell from "../../shipping/ShippingReportTable/ShippingPriceCell";
import { Constantes, RetailCompassStorage, ShippingUtils, Utils } from "@common/utils";
import { capitalize } from "lodash";
const CommuneIcon = process.env.PUBLIC_URL + "/img/comunas.svg";
const RegionIcon = process.env.PUBLIC_URL + "/img/region.svg";
import { onSelectCostType } from "../ShippingState";

export const getRetailerItemsSkippingMyself = (retailItems) => {
    let currentStoreId = RetailCompassStorage.getStoreId();
    return retailItems.filter(
        (item) => parseInt(item.storeId) !== currentStoreId
    );
};

export const getTableColumns = (props, stores, type) => {
    const { retailItems, storeType } = props;
    const retails = getRetailerItemsSkippingMyself(retailItems);
    const currentStoreId = RetailCompassStorage.getStoreId().toString();
    const myStoreName = RetailCompassStorage.getStoreName();
    const storesWithCommunes = ShippingUtils.hasCommunes(stores);
    const isRetailer =
        RetailCompassStorage.getStoreType() === Constantes.StoreType.RETAILER;

    const commonProps = {
        width: 150,
        fixed: true,
        sortable: false,
        resizable: false,
        storesWithCommunes: storesWithCommunes,
        myStoreName: myStoreName,
        costType: type,
    };

    const columns = retails.map((retail) => {
        const { name, storeId } = retail;
        const realName = name ? name : "No informado";
        let shortStoreName = realName.substring(0, 12);

        return {
            ...commonProps,
            Header: (headersProps) =>
                HeadersBuilder.buildRetailHeader(headersProps, {
                    shortStoreName,
                    storeId,
                    storeName: realName,
                }),
            accessor: realName,
            width: 140,
            fixed: false,
            Cell: ShippingPriceCell,
        };
    });

    if (isRetailer) {
        columns.unshift({
            ...commonProps,
            Header: (headersProps) =>
                HeadersBuilder.buildRetailHeader(headersProps, {
                    shortStoreName: "Tu Tienda",
                    currentStoreId,
                    storeName: myStoreName,
                }),
            accessor: "Tu Tienda",
            Cell: ShippingPriceCell,
        });
    }

    columns.unshift({
        ...commonProps,
        Header: (
            <div className="header-left">
                <img src={CommuneIcon} className="img" />
                Comuna
            </div>
        ),
        accessor: "comuna",
        width: storeType === Constantes.StoreType.BRAND ? 160 : 130,
        Cell: ShippingCell,
    });

    columns.unshift({
        ...commonProps,
        Header: (
            <div className="header-left">
                <img src={RegionIcon} className="img" />
                Región
            </div>
        ),
        accessor: "region",
        width: 245,
        Cell: ShippingCell,
    });

    return columns;
};

export const getDataForTable = (communes, retailItems, regions) => {
    const currentStoreId = RetailCompassStorage.getStoreId().toString();
    const myStoreName = RetailCompassStorage.getStoreName();
    const retailIds = [
        ...retailItems.map((item) => item.storeId),
        currentStoreId,
    ];

    const retailItemsWithShipping = communes.filter((item) =>
        retailIds.includes(item.storeId)
    );

    const regionsWithStores = regions.map((region) => {
        const stores = retailItemsWithShipping.map((item) => ({
            name: item.name === myStoreName ? "Tu Tienda" : item.name,
            storeId: item.storeId,
            commune: item.communes
                ? item.communes.find((commune) => commune.id === region.id)
                : {},
        }));

        return {
            ...region,
            stores,
        };
    });

    const data = regionsWithStores.map((region) => {
        const { regionName, name, regionId, id: communeId } = region;
        const stores = region.stores.reduce((acc, store) => {
            const storeName = store.name;
            const price = store.commune ? store.commune.average : null;
            const freeShippingCount = store.commune?.freeShippingCount;
            const paidShippingCount = store.commune?.paidShippingCount;
            const priceObject = {
                price: price != null ? (price !== 0.0 ? `$${Utils.formatPrice(Math.floor(price))}` : "$0.000") : "-",
                freeShippingCount: freeShippingCount,
                paidShippingCount: paidShippingCount,
            };
            acc[storeName] = priceObject;
            return acc;
        }, {});

        return {
            region: ShippingUtils.capitalizeRegionName(regionName),
            comuna: capitalize(name),
            communeId: String(communeId),
            regionId: String(regionId),
            ...stores,
        };
    });

    return ShippingUtils.formatDataForShippingTable(data);
};

export const mapStateToProps = (state, ownProps) => {
    const commonProps = TableContainer.mapStateToProps(state, ownProps);
    const regions = state.shippingStateReducer.shippingCommunes;
    const communes = state.productListBrandReducer.shippingTable;
    const type = state.shippingStateReducer.costType ? state.shippingStateReducer.costType : Constantes.TYPE_SHIPPING_REPORT.ALL;
    const columns = getTableColumns(commonProps, communes, type);
    const isLoading = state.productListBrandReducer.isFetching;
    const isLoadingShippingCost = state.productListBrandReducer.isLoadingShippingCost;

    let data = [];
    if (communes?.length) {
        data = getDataForTable(communes, commonProps.retailItems, regions);
    }


    return {
        ...commonProps,
        columns,
        data,
        isLoading,
        isLoadingShippingCost
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params) =>
            TableState.fetchProductItems(dispatch, params),
        onSelectCostType: (costType) => onSelectCostType(dispatch, costType),
    };
};
