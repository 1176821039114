import React, { useState, useEffect } from 'react'
import { Utils } from '@common/utils/'

const TimeIntoCommerce = ({ date }) => {
    const [days, setDays] = useState();

    useEffect(() => {
        setDays(Utils.getDaysFromToday(date))
    }, [date]);

    const getEdadMercadoRow = () => {
        if (!date) return null;
        if (days === 0) return "Desde hoy en el mercado"
        return days === 1 ? "1 día en el mercado" : days + " días en el mercado";
    }

    return (
        <h2 className="lbl-days">
            {getEdadMercadoRow()}
        </h2>
    )
}

export default TimeIntoCommerce