import React, { Component } from 'react';
import { GoogleSearchButton, RetailerButton } from '@common/components'
import { PropTypes } from "prop-types";
import { AttributesUtils, Utils } from "@common/utils/";

export class ProductPanel extends Component {

    static propTypes = {
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        subtitle: PropTypes.string,
        attributesTitle: PropTypes.string,
        product: PropTypes.object.isRequired,
        showGoogleButton: PropTypes.bool,
        showRetailerButton: PropTypes.bool,
        showMarker: PropTypes.bool,
        showUserName: PropTypes.bool
    };

    static defaultProps = {
        title: "",
        subtitle: "",
        attributesTitle: "",
        showGoogleButton: true,
        showRetailerButton: false,
        showMarker: false,
        nameToShow: null,
        showUserName: true
    };

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        let { title, subtitle, attributesTitle, product, showGoogleButton, showRetailerButton, showMarker, nameToShow, showUserName } = this.props;
        let originalMatch = Utils.getOriginalMatch(product);
        return (
            <div className="product-panel">
                <div className={"canonical-marker" + (showMarker ? " active" : "")}></div>
                {nameToShow && showUserName?
                    <div className="user-badge">{nameToShow}</div> : null
                }
                <div className="product-container">
                    <div className="title">{title}</div>
                    <div className="subtitle">{subtitle ? subtitle : null}</div>
                    <div className="image-container">
                        <img src={product.image} />
                    </div>
                    {
                        !Utils.isArrayWithData(product.enhancedAttributes) ? null :
                            <div className="attributes-container">
                                <div className="attributes-title">{attributesTitle}</div>
                                <div className="attributes-values">
                                    {
                                        product.enhancedAttributes.map((o, index) => (o ? <span key={index} className={o.isSuggested ? "suggested" : ""}>{AttributesUtils.buildAttributeValue(o)}</span> : null))
                                    }
                                </div>
                            </div>
                    }
                    <div className="product-name">{product.name}</div>
                    <div className="product-info">{product.brand}</div>
                    <div className="product-info">{product.model}</div>

                    {
                        showRetailerButton && originalMatch.storeName ?
                            <div className="btn-container">
                                <RetailerButton price={originalMatch.price} storeName={originalMatch.storeName} isSeller={!!originalMatch.isSeller} url={originalMatch.url} statusProduct={originalMatch.statusProduct} />
                            </div>
                            : null
                    }
                    {
                        showGoogleButton ?
                            <div className="btn-container">
                                <GoogleSearchButton product={product} />
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }
}
export default ProductPanel;