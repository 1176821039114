import * as RetailCompassStorage from '../app/Storage';
import * as RouterUtils  from '../utils/routerUtils'

const Hotjar = () => {      
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1086698,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }

export const  load = ( forceAllUsers = false ) => {
    
    if ((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
        return;
    }

    if(!window._hjSettings && 
        ( RouterUtils.isNotPrivateRoute() || RetailCompassStorage.isLoggedIn()) && 
        ( forceAllUsers || RetailCompassStorage.isCustomerUser() )){
        Hotjar();
    }
}

