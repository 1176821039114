export const Colors = {
    bestPrice: '#37cda2',
    higherPrice: '#fa697d',
    hexToRgb: hex =>
        hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
            ,(m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16))
};

export const TextStyleValues = {
    boldWeight: 500,
    decorationPriceNotAvailable: 'line-through'
};