import { find } from 'lodash';
import { FILTER_OPERATORS } from './constantes';
import { formatDynamicAttributes } from './attributesUtils'
import { hasValue, formatNumber } from './utils';

export const formatReduxFormInitialValues = (reduxFormInitialValues, applicationStateProps) => {
    reduxFormInitialValues = reduxFormInitialValues || {};
    applicationStateProps = applicationStateProps || {};
    const { brands, models, stores, screenSizeItems, dynamicAttributes } = applicationStateProps;

    let initialValues = {};

    initialValues.stores = null;
    if (hasValue(stores) && hasValue(reduxFormInitialValues.stores)) {
        initialValues.stores = stores.filter((item) => find(reduxFormInitialValues.stores, { storeId: item.storeId + '' }));
    }
    initialValues.brands = null;
    if (hasValue(brands) && hasValue(reduxFormInitialValues.brands)) {
        initialValues.brands = brands.filter((item) => find(reduxFormInitialValues.brands, { brandId: item.brandId + '' }));
    }
    initialValues.models = null;
    if (hasValue(models) && hasValue(reduxFormInitialValues.models)) {
        initialValues.models = models.filter((item) => find(reduxFormInitialValues.models, { value: item.value + '' }));
    }
    initialValues.labels = null;
    if (hasValue(reduxFormInitialValues.labels)) {
        initialValues.labels = reduxFormInitialValues.labels.map((value) => parseInt(value));
    }
    initialValues.screenSizeItems = null;
    if (hasValue(screenSizeItems) && hasValue(reduxFormInitialValues.screenSizeItems)) {
        initialValues.screenSizeItems = screenSizeItems.filter((item) => find(reduxFormInitialValues.screenSizeItems, { value: item.value + '' }));
    }
    if (hasValue(dynamicAttributes)) {
        initialValues = { ...initialValues, ...formatDynamicAttributes(reduxFormInitialValues, dynamicAttributes) };
    }

    initialValues.skus = null;
    initialValues.search = null;
    initialValues.keyWords = null;

    if (hasValue(reduxFormInitialValues.skus)) {
        initialValues.skus = reduxFormInitialValues.skus;
        initialValues.search = reduxFormInitialValues.skus;
    } else if (hasValue(reduxFormInitialValues.keyWords)) {
        initialValues.keyWords = reduxFormInitialValues.keyWords;
        initialValues.search = reduxFormInitialValues.keyWords;
    } else if (hasValue(reduxFormInitialValues.search)) {
        initialValues.search = reduxFormInitialValues.search;
    }

    if (hasValue(initialValues.search) && hasValue(reduxFormInitialValues.searchType)) {
        initialValues.searchType = reduxFormInitialValues.searchType;
    }

    initialValues.from = null;
    initialValues.to = null;
    const from = formatNumber(reduxFormInitialValues.from);
    const to = formatNumber(reduxFormInitialValues.to);
    if (from) {
        initialValues.from = from;
    }
    if (to) {
        initialValues.to = to;
    }

    if (hasValue(reduxFormInitialValues.productAvailability)) {
        initialValues.productAvailability = reduxFormInitialValues.productAvailability;
    }
    initialValues.favorites = null;
    if (reduxFormInitialValues.favorites) {
        initialValues.favorites = reduxFormInitialValues.favorites;
    }
    if (reduxFormInitialValues.selectedView) {
        initialValues.selectedView = reduxFormInitialValues.selectedView;
    }
    if (reduxFormInitialValues.report) {
        initialValues.report = reduxFormInitialValues.report;
    }
    if (reduxFormInitialValues.subReport) {
        initialValues.subReport = reduxFormInitialValues.subReport;
    }

    initialValues.haveIt = false;
    if (reduxFormInitialValues.haveIt) {
        initialValues.haveIt = true;
    }

    initialValues.dontHaveIt = false;
    if (reduxFormInitialValues.dontHaveIt) {
        initialValues.dontHaveIt = true;
    }

    initialValues.expensive = false;
    if (reduxFormInitialValues.expensive) {
        initialValues.expensive = true;
    }

    initialValues.cheaper = false;
    if (reduxFormInitialValues.cheaper) {
        initialValues.cheaper = true;
    }

    initialValues.same = false;
    if (reduxFormInitialValues.same) {
        initialValues.same = true;
    }

    if (reduxFormInitialValues.sellerType) {
        initialValues.sellerType = reduxFormInitialValues.sellerType;
    }

    if (reduxFormInitialValues.outOfStockRanges) {
        initialValues.outOfStockRanges = reduxFormInitialValues.outOfStockRanges;
    }

    initialValues.rankingFilter = null;
    if (reduxFormInitialValues.rankingFilter) {
        initialValues.rankingFilter = reduxFormInitialValues.rankingFilter;
    }

    initialValues.onlyAvailableProducts = false;
    if (reduxFormInitialValues.onlyAvailableProducts) {
        initialValues.onlyAvailableProducts = true;
    }

    if (reduxFormInitialValues.contractType) {
        initialValues.contractType = reduxFormInitialValues.contractType;
    }

    if (reduxFormInitialValues[FILTER_OPERATORS]) {
        initialValues[FILTER_OPERATORS] = reduxFormInitialValues[FILTER_OPERATORS];
    }

    if (reduxFormInitialValues.viewPrices) {
        initialValues.viewPrices = reduxFormInitialValues.viewPrices;
    }

    initialValues.shippingType = null;
    if (reduxFormInitialValues.shippingType) {
        initialValues.shippingType = reduxFormInitialValues.shippingType;
    }

    initialValues.commune = null;
    if (reduxFormInitialValues.commune) {
        initialValues.commune = parseInt(reduxFormInitialValues.commune);
    }

    initialValues.sendCost = false;
    if (reduxFormInitialValues.sendCost) {
        initialValues.sendCost = reduxFormInitialValues.sendCost;
    }

    initialValues.sendShipment = false;
    if (reduxFormInitialValues.sendShipment) {
        initialValues.sendShipment = reduxFormInitialValues.sendShipment;
    }
    return initialValues;
}

