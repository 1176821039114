import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import {
    Utils, Constantes, OutOfStockReportCommon, RetailCompassStorage,
    OperatorUtils
} from '@common/utils/';
import * as BuildDynamicAttributeFilter from '../../buildDynamicAttributeFilter';
import FilterFieldLayout from '../../FilterFieldLayout';
import FavoriteInput from '../../../favorite/FavoriteInput';
import LabelsList from '../../../labels/label-list/FilterLabelsList';
import * as  WithGenericFilters from '../../hoc/withGenericFilters';
import { translate } from 'react-i18next';
import { orderBy, filter, isEqual } from 'lodash';
import CheckboxList from "@common/components/src/ui/CheckboxList";
import ToggleButton from "@common/components/src/ui/ToggleButton";
import TextFieldRange from "@common/components/src/ui/TextFieldRange";
import MultiSelectField from "@common/components/src/ui/MultiSelect";
import RankingFilter from "@common/components/src/ui/RankingFilter";

const formName = 'filtersForm';

const store = process.env.PUBLIC_URL + '/img/store.svg';
const StoreIcon = (props) => (<img className="store-icon" src={store} alt='' />)

export const sellerTypeItems = [
    { id: "mkp-1", value: Constantes.SELLERTYPE_FILTER.MARKETPLACE, label: <span>Marketplaces<span><StoreIcon/></span></span> },
    { id: "mkp-2", value: Constantes.SELLERTYPE_FILTER.DIRECT, label: "Venta directa" }
];

class Filters extends Component {
    static propTypes = {
        ...WithGenericFilters.genericPropTypes
    };

    constructor(props) {
        super(props);
        this.state = { labelsKey: Utils.generateUniqueID() };
        this.buildAttributeFilter = this.buildAttributeFilter.bind(this);
    };

    componentDidUpdate(prevProps) {
        let currentCanonicalCategoryChanged = !isEqual(this.props.currentCanonicalCategory, prevProps.currentCanonicalCategory);
        if ((this.props.backPressedNavigate && !prevProps.backPressedNavigate) || currentCanonicalCategoryChanged) {
            this.setState({ labelsKey: Utils.generateUniqueID() });
        }
    }

    buildAttributeFilter(attribute) {
        return BuildDynamicAttributeFilter.buildDynamicAttributeFilter(attribute, this.props.onChangeAttributeValue);
    }

    rankingFilter() {
        if (RetailCompassStorage.isEnableCategoryRanking() && RetailCompassStorage.isEnableProductRanking()) {
            return <FilterFieldLayout
                showRemoveFilters={!!this.props.currentFilter.rankingFilter}
                onClear={() => { this.props.onChangeRankingFilter(null) }}
                fieldLabel={"Top posicionamiento"}
                fieldClass={"filters-container--rankingFilter"}>
                <RankingFilter
                    items={Constantes.DEFAULT_RANKING_FILTER_ITEMS}
                    ref={React.createRef()}
                    selectedValue={parseInt(this.props.currentFilter.rankingFilter)}
                    fieldName={Constantes.FIELD_NAME_RANKING_FILTER}
                    onChange={(selectedValue) => { this.props.onChangeRankingFilter(selectedValue) }}
                />
            </FilterFieldLayout>;
        }
    }

    sellerTypeFilter() {
        if (Utils.getMkpVisibleValueFromCurrentCountry()) {
            return <FilterFieldLayout
                showRemoveFilters={!!(this.props.currentFilter.sellerType && this.props.currentFilter.sellerType.length > 0)}
                onClear={() => { this.props.onChangeSellerType([]) }}
                fieldLabel={"Precios marketplaces"}
                fieldClass={"filters-container--sellertype"}>
                <CheckboxList
                    items={sellerTypeItems}
                    ref={React.createRef()}
                    checkedItems={this.props.currentFilter.sellerType ? filter(sellerTypeItems, (o) => { return this.props.currentFilter.sellerType.indexOf(o.value) !== -1 }) : []}
                    fieldName={Constantes.FIELD_NAME_MKP_FILTER}
                    keyName={null}
                    onChange={(checkedItems, clickedItem) => { this.props.onChangeSellerType(checkedItems) }}
                />
            </FilterFieldLayout>;
        }
    }

    outOfStockFilter() {
        let counterItems = OutOfStockReportCommon.buildCounterItemsConfig({});

        if (!Utils.isArrayWithData(counterItems)) {
            return null;
        }

        let fieldName = Constantes.FIELD_NAMES.OUT_OF_STOCK_RANGES;

        // format options
        let items = counterItems.map((item) => ({ id: item.counterKey, value: item.counterKey, label: item.label + ' sin stock' }));

        // extract selected options
        let outOfStockRanges = this.props.currentFilter[fieldName];
        let hasCheckedItems = Utils.isArrayWithData(outOfStockRanges);
        let checkedItems = [];
        if (hasCheckedItems) {
            checkedItems = filter(items, (o) => outOfStockRanges.indexOf(o.value) !== -1);
        }

        const label = RetailCompassStorage.getStoreType() === Constantes.StoreType.RETAILER ?
            "No disponibles en tu tienda" : "Productos no disponibles";

        return (
            <FilterFieldLayout
                showRemoveFilters={hasCheckedItems}
                onClear={() => { this.props.onChangeOutOfStockRanges([]) }}
                fieldLabel={label}
                fieldClass={"filters-container--out-of-stock"}>
                <CheckboxList
                    items={items}
                    ref={React.createRef()}
                    checkedItems={checkedItems}
                    fieldName={fieldName}
                    keyName={null}
                    onChange={(checkedItems, clickedItem) => { this.props.onChangeOutOfStockRanges(checkedItems) }}
                />
            </FilterFieldLayout>
        );
    }

    onlyAvailableFilter() {
        return (
            <FilterFieldLayout
                fieldLabel="Productos disponibles"
                fieldClass="filters-container--products-available">
                <div className="main-container">
                    <ToggleButton
                        callOnComponentDidMount={false}
                        defaultChecked={false}
                        input={{
                            value: this.props.currentFilter.onlyAvailableProducts || false,
                            checked: this.props.currentFilter.onlyAvailableProducts || false,
                            name: Constantes.FIELD_NAMES.ONLY_AVAILABLE_PRODUCTS,
                            onChange: (value) => { this.props.onChangeAvailableProductsFilter(value) }
                        }}
                    />
                    <span className="label">Solo con stock</span>
                </div>
            </FilterFieldLayout>
        );
    }

    favoritesFilter() {
        return (
            <Field
                name={"favorites"}
                component={FavoriteInput}
                placeholder={"Tus Favoritos"}
                onChange={(e, value) => { this.props.onChangeFavorite(value) }}
            />
        )
    }

    labelsFilter() {
        return (
            <LabelsList key={this.state.labelsKey}
                labels={this.props.labels}
                filterLabels={this.props.currentFilter.labels}
                onLabelSelection={(items) => { this.props.onChangeLabels(items) }}
                onLabelRemoval={(items) => { this.props.onChangeLabels(items) }}
                forceOpenEditModal={this.props.forceOpenEditModal}
                visibleLabelsForm={this.props.visibleLabelsForm}
                dispatch={this.props.dispatch}
                hidePublicLabels={this.props.hidePublicLabels}
                currentFilter={this.props.currentFilter}
            />
        )
    }

    storesFilter() {
        return (
            <FilterFieldLayout
                showRemoveFilters={!!(this.props.currentFilter.stores && this.props.currentFilter.stores.length > 0)}
                onClear={() => { this.props.onRemoveFilterData(Constantes.FIELD_NAMES.STORES); }}
                fieldLabel={"Retailers"}
                fieldClass={"filters-container--retailers"}>
                <Field
                    name={Constantes.FIELD_NAMES.STORES}
                    component={MultiSelectField}
                    options={this.props.stores}
                    noResultsText="No hay registros"
                    placeholder={"Todos"}
                    removeSelected={true}
                    onChange={(e, values) => { this.props.onChangeMultiSelect(
                        Constantes.FIELD_NAMES.STORES,
                        values
                    ); }}
                />
            </FilterFieldLayout>
        )
    }

    brandsFilter() {
        return (
            <FilterFieldLayout
                showRemoveFilters={!!(this.props.currentFilter.brands && this.props.currentFilter.brands.length > 0)}
                onClear={() => { this.props.onRemoveFilterData("brands") }}
                fieldLabel={"Marcas"}
                fieldClass={"filters-container--brands"}>
                <Field
                    name={"brands"}
                    component={MultiSelectField}
                    options={this.props.brands}
                    noResultsText="No hay registros"
                    placeholder={"Todas"}
                    removeSelected={true}
                    onChange={(e, values) => { this.props.onChangeMultiSelect("brands", values) }}
                />
            </FilterFieldLayout>
        )
    }

    priceRangeFilter() {
        return (
            <FilterFieldLayout fieldClass={"filters-container--price-range"}>
                <TextFieldRange
                    label='Precio mínimo'
                    from={Utils.formatNumber(this.props.currentFilter.from)}
                    to={Utils.formatNumber(this.props.currentFilter.to)}
                    placeholderFrom='Mínimo'
                    placeholderTo='Máximo'
                    ranges={this.props.ranges}
                    applyFilterListener={(from, to) => { this.props.onClickPriceRange(from, to) }}
                    removeFilterListener={(from, to) => { this.props.onClickPriceRange(from, to) }} />
            </FilterFieldLayout>
        )
    }

    dynamicAttributesFilters() {
        const dynamicAttributes = orderBy(this.props.dynamicAttributes, (item) => (item.visualization.order));
        return dynamicAttributes.map(this.buildAttributeFilter);
    }

    operatorsFilter() {
        return (
            <FilterFieldLayout
                showRemoveFilters={!!(this.props.currentFilter.operators && this.props.currentFilter.operators.length > 0)}
                onClear={() => { this.props.onRemoveFilterData(Constantes.FILTER_OPERATORS) }}
                fieldLabel={"Operadores"}
                fieldClass={"filters-container--brands"}>
                <Field
                    name={"operators"}
                    component={MultiSelectField}
                    options={this.props.operators}
                    noResultsText="No hay registros"
                    placeholder={"Todos"}
                    removeSelected={true}
                    onChange={(e, values) => { this.props.onChangeMultiSelect(Constantes.FILTER_OPERATORS, values) }}
                />
            </FilterFieldLayout>
        )
    }

    contractTypeFilter() {
        const items = OperatorUtils.getValuesFromMatchAttributes(
            Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE
        );
        const currentContractType = this.props.currentFilter.contractType;
        const hasCheckedItems = currentContractType
            && Utils.isArrayWithData(currentContractType);
        const checkedItems = currentContractType
            ? filter(items, (i) => currentContractType.indexOf(i.value) !== -1)
            : [];

        return (
            <FilterFieldLayout
                showRemoveFilters={hasCheckedItems}
                onClear={() => this.props.onChangeContractTypeFilter([], null)}
                fieldLabel={'Tipo de contrato'}
                fieldClass={"filters-container--contract-type"}>
                <CheckboxList
                    items={items}
                    ref={React.createRef()}
                    checkedItems={checkedItems}
                    fieldName={Constantes.FIELD_NAMES.CONTRACT_TYPE}
                    keyName={null}
                    onChange={(checkedItems, clickedItem) => {
                        this.props.onChangeContractTypeFilter(
                            checkedItems, clickedItem
                        )
                    }}
                />
            </FilterFieldLayout>
        );
    }

    memoryCapacityFilter() {
        const items = [
            { label: '8 GB', value: '1' },
            { label: '16 GB', value: '2' },
            { label: '9999 GB', value: '3' }
        ]
        return (
            <FilterFieldLayout
                showRemoveFilters={!!(this.props.currentFilter.memoryCapacity && this.props.currentFilter.memoryCapacity.length > 0)}
                onClear={() => { this.props.onRemoveFilterData("memoryCapacity") }}
                fieldLabel={"Memoria"}
                fieldClass={"filters-container--brands"}>
                <Field
                    name={"memoryCapacity"}
                    component={MultiSelectField}
                    options={items}
                    noResultsText="No hay registros"
                    placeholder={"Todas"}
                    removeSelected={true}
                    onChange={(e, values) => { this.props.onChangeMultiSelect("memoryCapacity", values) }}
                />
            </FilterFieldLayout>
        )
    }

    pricePlanFilter() {
        return (
            <FilterFieldLayout fieldClass={"filters-container--price-range"}>
                <TextFieldRange
                    label='Precio plan'
                    from={Utils.formatNumber(this.props.currentFilter.from)}
                    to={Utils.formatNumber(this.props.currentFilter.to)}
                    ranges={this.props.ranges}
                    applyFilterListener={(from, to) => { this.props.onClickPriceRange(from, to) }}
                    removeFilterListener={(from, to) => { this.props.onClickPriceRange(from, to) }}
                    showInputs={false}
                />
            </FilterFieldLayout>
        )
    }

    preventSubmit = (values) => {
        values.preventDefault()
    }

    commonRender() {
        return (
            <div className="filters-container">
                { this.props.currentFilter.selectedView === Constantes.TYPE_VIEW_RETAIL.SHIPPING ? "" : this.rankingFilter()}
                { this.storesFilter()}
                { this.brandsFilter()}
                { this.sellerTypeFilter()}
                { this.dynamicAttributesFilters()}
                { this.onlyAvailableFilter()}
                { this.outOfStockFilter()}
                { this.priceRangeFilter()}
            </div>
        )
    }

    operatorRender() {
        return (
            <div className="filters-container">
                {/* put here ranking filter */}
                { this.operatorsFilter()}
                { this.brandsFilter()}
                { this.dynamicAttributesFilters()}
                { this.contractTypeFilter()}
                { this.pricePlanFilter()}
                {/* put filter availability and stock */}
            </div>
        )
    }

    filtersContainerRender() {
        if (OperatorUtils.currentCategoryIsOperator()) {
            return this.operatorRender();
        } else {
            return this.commonRender();
        }
    }

    render() {
        return (
            <form className="form form--vertical brands-filters-form" onSubmit={this.preventSubmit}>
                <div className={this.props.elementsContainerClassName}>
                    {this.favoritesFilter()}
                    {this.labelsFilter()}
                    {this.filtersContainerRender()}
                </div>
            </form>
        );
    }
}

export const FiltersViewGeneric = WithGenericFilters.withGenericFilters(Filters, formName);

export default reduxForm({
    form: formName, // a unique identifier for this form
})(translate('common')(FiltersViewGeneric));
