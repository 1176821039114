import React, { useState, useEffect } from 'react'
import LanguageIcon from '@material-ui/icons/Language';
import CreatePublicLabelModal from '../modals/CreatePublicLabelModal';

export default function LabelsFormCheckbox ({isPublic, onChangeValue}) {
    const [checked, setChecked] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    useEffect(() => {
        setChecked(isPublic);
    }, [])

    const openModal = () => {
        if(!checked){
            setModalOpened(true);
        }else{
            cancelModal();
        }
    }

    const cancelModal = () => {
        setChecked(false);
        setModalOpened(false);
        onChangeValue(false);
    }

    const acceptModal = () => {
        setChecked(true);
        setModalOpened(false);
        onChangeValue(true);
    }

    return (
        <>
            <input 
                name="label-public" 
                id="label-public" 
                type="checkbox" 
                className="public-checkbox" 
                checked={checked} 
                onClick={openModal} 
            />
            <label onClick={openModal}>
                <LanguageIcon className="global-icon" />
                Hacer público
            </label>
            { modalOpened &&
                <CreatePublicLabelModal
                onConfirm={acceptModal}
                onCancel={cancelModal}
                />
            }
        </>
    )
}