import { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, find } from 'lodash';
import * as Constantes  from '../utils/constantes'
import * as Utils  from '../utils/utils'
import * as Intercom  from '../scripts/intercom'
import * as IntercomConstants  from '../utils/intercomConstants'
import * as ReduxFormsUtils  from '../utils/reduxFormsUtils'
import * as RetailCompassStorage  from '../app/Storage'

import queryString from 'qs';

export class IntercomListener extends Component {

    constructor(props) {
        super(props);
        this.checkReports = this.checkReports.bind(this);
        this.checkFilters = this.checkFilters.bind(this);
        this.checkDynamicAttributes = this.checkDynamicAttributes.bind(this);
    }

    componentDidUpdate(prevProps) {
        try {
            this.checkReports(prevProps);
            this.checkFilters(prevProps);
            this.checkDynamicAttributes(prevProps);
        } catch (error) {
            console.error('(IntercomListener) ' + error.name + ': ' + error.message);
        }
    }

    checkReports(prevProps) {
        const { selectedView, selectedReport, selectedSubReport } = this.props;
        if (isEqual(selectedView, Constantes.TYPE_VIEW_RETAIL.REPORT)) {
            const isReportUsed = !isEqual(selectedReport, prevProps.selectedReport) && selectedReport;
            if (isReportUsed) {
                switch (selectedReport) {
                    case Constantes.TYPE_REPORT_RETAIL.COMPETITIVENESS:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_COMPETITIVENESS_REPORT)
                        break;
                    case Constantes.TYPE_REPORT_RETAIL.OUT_OF_STOCK:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_OUT_OF_STOCK_REPORT)
                        break;
                    case Constantes.TYPE_REPORT_RETAIL.DISCOUNTS:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_DISCOUNTS_REPORT)
                        break;
                }
            }

            const isSubReportUsed = !isEqual(selectedSubReport, prevProps.selectedSubReport) && selectedSubReport;
            if (isSubReportUsed) {
                switch (selectedSubReport) {
                    case Constantes.TYPE_REPORT_RETAIL.RETAIL:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_COMPETITIVENESS_RETAIL_SUBREPORT)
                        break;
                    case Constantes.TYPE_REPORT_RETAIL.BRANDS:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_COMPETITIVENESS_BRAND_SUBREPORT)
                        break;
                    case Constantes.TYPE_REPORT_RETAIL.LOWER_PRICE:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_COMPETITIVENESS_LOWER_PRICE_SUBREPORT)
                        break;
                    case Constantes.RETAIL_DISCOUNTS_REPORT.ALL:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_DISCOUNTS_ALL_PRICES_SUBREPORT)
                        break;
                    case Constantes.RETAIL_DISCOUNTS_REPORT.CARD_PRICE:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_DISCOUNTS_CARD_PRICES_SUBREPORT)
                        break;
                    case Constantes.RETAIL_DISCOUNTS_REPORT.SALE_PRICE:
                        Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_DISCOUNTS_SALE_PRICES_SUBREPORT)
                        break;
                }
            }
        }
    }

    checkFilters(prevProps) {
        const { sellerType, labels, favorites, stores, brands, from, to, outOfStockRanges } = this.props.currentFilter;
        const prevFilter = { ...prevProps.currentFilter }

        const isMkpFilterUsed = !isEqual(sellerType, prevFilter.sellerType) && Utils.isArrayWithData(sellerType);
        if (isMkpFilterUsed) Intercom.trackEvent(IntercomConstants.EVENTS.USED_MKP_FILTER);

        const isLabelFilterUsed = !isEqual(labels, prevFilter.labels) && Utils.isArrayWithData(labels);
        if (isLabelFilterUsed) Intercom.trackEvent(IntercomConstants.EVENTS.USED_LABEL_FILTER);

        const isFavoriteFilterUsed = !isEqual(favorites, prevFilter.favorites) && !!favorites;
        if (isFavoriteFilterUsed) Intercom.trackEvent(IntercomConstants.EVENTS.USED_FAVORITE_FILTER);

        const isStoreFilterUsed = !isEqual(stores, prevFilter.stores) && Utils.isArrayWithData(stores);
        if (isStoreFilterUsed) Intercom.trackEvent(IntercomConstants.EVENTS.USED_RETAILER_FILTER);

        const isBrandFilterUsed = !isEqual(brands, prevFilter.brands) && Utils.isArrayWithData(brands);
        if (isBrandFilterUsed) Intercom.trackEvent(IntercomConstants.EVENTS.USED_BRAND_FILTER);

        const isPriceRangeFilterUsed = (!isEqual(from, prevFilter.from) && from)
            || !isEqual(to, prevFilter.to) && to;
        if (isPriceRangeFilterUsed) Intercom.trackEvent(IntercomConstants.EVENTS.USED_PRICE_RANGE_FILTER);

        const isOutOfStockFilterUsed = !isEqual(outOfStockRanges, prevFilter.outOfStockRanges) && Utils.isArrayWithData(outOfStockRanges);
        if (isOutOfStockFilterUsed) Intercom.trackEvent(IntercomConstants.EVENTS.USED_OUT_OF_STOCK_FILTER);
    }

    checkDynamicAttributes(prevProps) {
        if (isEqual(this.props.locationSearch, prevProps.locationSearch)) return;
        const prevUrlSearchParams = queryString.parse(prevProps.locationSearch, { ignoreQueryPrefix: true });
        const prevDynamicAttributes = ReduxFormsUtils.converterUrlSearchParamsToFormFields(prevUrlSearchParams).dynamicAttributes;
        const currDynamicAttributes = this.props.currentFilter.dynamicAttributes || [];
        const currCanonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();

        const visibleAttributes = currDynamicAttributes
            .filter(attr => isEqual(attr.visualization.visible ? attr.visualization.visible.value : false, true))
            .map(attr => Utils.isAttributeVisibleFromCountryCodeAndUserType(attr.visualization.visible) ? attr : null);

        visibleAttributes.forEach(currAttr => {
            const prevAttr = find(prevDynamicAttributes, ['name', currAttr.name]);
            const prevData = prevAttr.selectedData.map(item => item.value);
            const currData = currAttr.selectedData.map(item => item.value);
            const isAttrFilterUsed = !isEqual(currData, prevData) && Utils.isArrayWithData(currData);
            if (isAttrFilterUsed) {
                Intercom.trackEvent(IntercomConstants.EVENTS.USED_DYNAMIC_FILTER
                    .replace('{}', currCanonicalCategory.key + ' > ' + currAttr.name));
            }
        });
    }

    render() {
        return (null);
    }
}

const mapStateToProps = (state) => {
    return {
        selectedView: state.subRouterStateReducer.selectedView,
        selectedReport: state.subRouterStateReducer.selectedReport,
        selectedSubReport: state.subRouterStateReducer.selectedSubReport,
        currentFilter: Utils.extractCurrentProductListFilter(state)
    };
};

const IntercomListenerWithRedux = connect(mapStateToProps)(IntercomListener);
export default IntercomListenerWithRedux;
