import React, { PureComponent } from 'react';
import { Table, Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { isObject, isEqual, includes } from 'lodash';
import { Utils, Constantes } from "@common/utils/";
import CheckBoxField from "@common/components/src/ui/CheckBox";
import CustomHeader from "./CustomHeader";
import MinusIcon from 'mdi-react/MinusIcon';
import { findIndex } from 'lodash';


const CheckTypes = { ALL: 'ALL', PARTIAL: 'PARTIAL', NONE: 'NONE' };

export default class CustomTable extends PureComponent {

  static propTypes = {
    striped: PropTypes.bool,
    hover: PropTypes.bool,
    responsive: PropTypes.bool,
    className: PropTypes.string,
    cellClass: PropTypes.string,
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    rowKey: PropTypes.string.isRequired,
    allRows: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    onRowSelected: PropTypes.func,
    onRowDeselected: PropTypes.func,
    onSort: PropTypes.func,
    selectedRows: PropTypes.array,
    onRowsAllSelected: PropTypes.func,
    onRowsAllDeselected: PropTypes.func,
    tableIdId: PropTypes.string.isRequired,
    rowInfo: PropTypes.object,
    activeElementKey: PropTypes.any,
    sortKeyDefault: PropTypes.string,
    sortDirectionDefault: PropTypes.string,
    sortAvailable: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [], 
      sortHeaderKey: null, 
      sortHeaderDirection: 'NONE',
      sortDescendingFirst: false,
    };
    this.getContent = this.getContent.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onHeaderCheckBoxChange = this.onHeaderCheckBoxChange.bind(this);
    this.onRowCheckBoxChange = this.onRowCheckBoxChange.bind(this);
    this.computeRowChecked = this.computeRowChecked.bind(this);
    this.computeHeaderChecked = this.computeHeaderChecked.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.onRowDeselected = this.onRowDeselected.bind(this);
    this.notifyRowSelected = this.notifyRowSelected.bind(this);
    this.notifyRowDeselected = this.notifyRowDeselected.bind(this);
    this.getHeaderColumnClass = this.getHeaderColumnClass.bind(this);
    this.onSort = this.onSort.bind(this);
    this.notifySort = this.notifySort.bind(this);
    this.computeCellClassNames = this.computeCellClassNames.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.isSellerNameButton = this.isSellerNameButton.bind(this);
  }

  componentDidMount(){
    if (this.props.sortKeyDefault && this.props.sortDirectionDefault) {
      this.setState({
        sortHeaderKey: this.props.sortKeyDefault,
        sortHeaderDirection: this.props.sortDirectionDefault
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let selectedRows = [].concat(this.props.selectedRows).sort();
    let newSelectedRows = [].concat(nextProps.selectedRows).sort();

    if (!isEqual(selectedRows, newSelectedRows)) {
      this.setState({ selectedRows: nextProps.selectedRows });
    }

    let sortKeyDefault = this.props.sortKeyDefault;
    let sortDirectionDefault = this.props.sortDirectionDefault;
    if(!nextProps.sortAvailable) {
      let newSortKeyDefault = nextProps.sortKeyDefault;
      if (!isEqual(sortKeyDefault, newSortKeyDefault)) {
        this.setState({
          sortHeaderKey: newSortKeyDefault,
          sortHeaderDirection: nextProps.sortDirectionDefault
        });
      }
    } else {
      this.setState({ sortHeaderKey: sortKeyDefault, sortHeaderDirection: sortDirectionDefault });
    }
  }

  getContent(o, k) {
    const value = Utils.getPropertyByKey(o, k);
    if (isObject(value)) {
      return value;
    }
    return value;
  }

  getValue(header, row) {
    if (header.formatter) {
      return header.formatter(row);
    }
    return this.getContent(row, header.key);
  }

  isSelectedRow(rowItemKey, keyName) {
    const selectedRows = this.state.selectedRows;
    return findIndex(selectedRows, [keyName, rowItemKey]) != -1;
  }

  isSellerNameButton(target) {
    if (includes(target.className, 'popover-header')) {
        return true;
    }
    if (includes(target.className, 'popover-body')) {
      return true;
    }
    if (!target.parentNode) {
        return false;
    }
    return this.isSellerNameButton(target.parentNode);
}

  computeRowChecked(row) {
    const { rowKey } = this.props;
    const items = this.state.selectedRows;
    const checkeds = items.filter(item => item[rowKey] === row[rowKey]);
    return checkeds.length > 0;
  }

  computeHeaderChecked() {
    const { count } = this.props;

    if (this.state.selectedRows.length > 0) {
      if (this.state.selectedRows.length === count) {
        return CheckTypes.ALL;
      } else {
        return CheckTypes.PARTIAL
      }
    }

    return CheckTypes.NONE
  }

  onRowSelected(row) {
    const selectedRows = this.state.selectedRows.concat(row);
    this.setState({ selectedRows: selectedRows });
    this.notifyRowSelected(row, selectedRows);
  }

  notifyRowSelected(row, selectedRows) {
    if (this.props.onRowSelected) {
      this.props.onRowSelected(row, selectedRows);
    }
  }

  onRowDeselected(row) {
    const rowKey = this.props.rowKey;
    const selectedRows = this.state.selectedRows.filter(item => item[rowKey] !== row[rowKey]);
    this.setState({ selectedRows: selectedRows });
    this.notifyRowDeselected(row, selectedRows);
  };

  notifyRowDeselected(row, selectedRows) {
    if (this.props.onRowDeselected) {
      this.props.onRowDeselected(row, selectedRows);
    }
  }

  onRowCheckBoxChange(event, row) {
    if (isObject(event)) {
      const target = event.target;
      const checked = target.checked;

      if (checked) {
        this.onRowSelected(row);
      } else {
        this.onRowDeselected(row);
      }
    }
  }

  onHeaderCheckBoxChange(event) {
    if (isObject(event)) {
      const target = event.target;
      const checked = target.checked;
      if (checked) {
        if (this.props.onRowsAllSelected) {
          this.props.onRowsAllSelected();
        }
      } else {
        if (this.props.onRowsAllDeselected) {
          this.props.onRowsAllDeselected();
        }
      }
    }
  }

  onRowClick(event, rowItem) {
    if (isObject(event) && isObject(rowItem)) {
      if (!this.isSellerNameButton(event.target)) {
        if (this.props.onRowClick) {
          this.props.onRowClick(event, rowItem);
        }
      }  
    }
  }

  getHeaderColumnClass(headerItem) {
    let classes = ['custom-table-header-column'];
    if (headerItem.sortable) {
      classes.push('custom-table-header-column-sortable');
    }
    if (headerItem.className) {
      classes.push(headerItem.className);
    }
    return classes.join(" ");
  }

  onSort(key, direction) {
    
    if (direction === 'NONE') {
      direction = key === 'name' ? 'DESC' : 'ASC';
    }
    this.setState({ sortHeaderKey: key, sortHeaderDirection: direction });
    this.notifySort(key, direction);
  }

  notifySort(key, direction) {
    if (this.props.onSort) {
      this.props.onSort(key, direction);
    }
  }

  computeCellClassNames(cellClass) {
    return ["custom-table-cell", this.props.cellClass, cellClass].filter(Boolean).join(" ");
  }

  computeRowsClassNames(rowItemKey, activeItemKey, keyName){
    let classes = ["custom-table-row"];
    if(this.isSelectedRow(rowItemKey, keyName)){
      classes.push("custom-table-row--selected")
    }
    if(rowItemKey == activeItemKey){
      classes.push("custom-table-row--active")
    }
    return classes.join(" ");
  }

  render() {
    const { rowKey, className, striped, hover, responsive, headers, rows,
      enableRowSelect, activeElementKey, tableIdId, rowInfo, showMessageEmpty } = this.props;      

    let checkType = this.computeHeaderChecked();
    return (
        <>
          <Table id={tableIdId} className={["custom-table ", className].join(" ")} striped={striped} hover={hover} responsive={responsive}>
            <thead id={tableIdId + "__header"}>
              <tr className='custom-table-header'>
                {enableRowSelect ?
                  <th className="custom-table-header-selection">
                    <CheckBoxField
                      defaultChecked={false}
                      value={false}
                      class='custom-table-header-check'
                      icon={checkType === CheckTypes.PARTIAL ? <MinusIcon /> : null}
                      input={{
                        value: checkType !== CheckTypes.NONE,
                        name: "ALL",
                        onChange: (event) => this.onHeaderCheckBoxChange(event)
                      }}
                    />
                  </th> :
                  null}

                {headers && headers.length ? headers.map((headerItem, index) => {
                  return (
                    <CustomHeader
                      key={index}
                      className={this.getHeaderColumnClass(headerItem)}
                      headerKey={headerItem.key}
                      header={headerItem}
                      sortDirection={this.state.sortHeaderDirection}
                      sortKey={this.state.sortHeaderKey}
                      sortDescendingFirst={this.state.sortDescendingFirst}
                      invertSortResults={headerItem.invertSort || false}
                      onSort={this.onSort}
                    />
                  );
                })
                  : null}
              </tr>
            </thead>

            <tbody>

              {rowInfo ? (<tr className={this.computeRowsClassNames(0, 1, -1) + ' custom-table-row--info'}>
                <td className="custom-table-cell--info" colSpan={headers.length}>{rowInfo}</td>
              </tr>) :
                null}

              {rows && rows.length ? rows.filter(rowItem => !!rowItem).map((rowItem, rowIndex) => {
                return (
                  <tr className={this.computeRowsClassNames(rowItem[rowKey], activeElementKey, rowKey)}
                    key={rowIndex} onClick={(e) => this.onRowClick({ ...e }, rowItem)} >

                    {enableRowSelect ?
                      <td className="custom-table-row-cell-selection">
                        <CheckBoxField
                          defaultChecked={false}
                          className='custom-table-row-selection'
                          input={{
                            name: "".concat(rowItem[rowKey]),
                            onChange: (event) => this.onRowCheckBoxChange(event, rowItem),
                            value: this.computeRowChecked(rowItem)
                          }}
                        />
                      </td> :
                      null}

                    {headers && headers.map((headerItem, headerKey) => {
                      return (
                        <td className={this.computeCellClassNames(headerItem.cellClass)} key={headerKey}>
                          {headerItem.formatter ? headerItem.formatter(rowItem) :
                            <span className={headerItem.computeContainerClassName ? headerItem.computeContainerClassName(rowItem) : ""}>
                              {this.getValue(headerItem, rowItem)}
                            </span>
                          }
                        </td>
                      );
                    })}
                  </tr>
                )
              }) : null
              }
            </tbody>
          </Table>

          {showMessageEmpty && !Utils.isArrayWithData(rows) &&
            <WithoutResults />
          }
        </>
    )
  }
}

const WithoutResults = () => {

  const icon = Utils.getIcon(Constantes.ICONS_EMPTY_STATES.EMPTY_STATE_SVG)

  return (
    <Col md={12} lg={12} className="empty-state-view-container">
      <Card>
        <CardBody className="card-body-brand">
          <div className="icon-brand">
            {icon}
          </div>
          <div className="title">
            No hay productos con esos filtros
          </div>
          <div className="subtitle">
            Puedes ajustar filtros y analizar otros <br/> productos
          </div>
        </CardBody>
      </Card>
    </Col>
  ) 
}
