import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TriangleIcon from 'mdi-react/TriangleIcon';
import { UncontrolledTooltip } from 'reactstrap';
import { Constantes } from '@common/utils';


const sort = process.env.PUBLIC_URL + '/img/sort.svg';
const SortIcon = (props) => (<img className="sort-up-down-icon" src={sort} alt='' />)



export default class CustomHeader extends PureComponent {

    static propTypes = {
        headerKey: PropTypes.string.isRequired,
        header: PropTypes.object.isRequired,
        onSort: PropTypes.func.isRequired,
        sortDirection: PropTypes.oneOf(Object.keys(Constantes.SORT_TYPE)),
        sortKey: PropTypes.string,
        sortDescendingFirst: PropTypes.bool,
        className: PropTypes.string,
        invertSortResults: PropTypes.bool,
        containerTag: PropTypes.oneOf(['th', 'div']),
        sortComponent: PropTypes.node
    };

    static defaultProps = {
        invertSortResults: false,
        containerTag: 'th'
    }

    constructor(props) {
        super(props);

        this.state = {};

        this.onClick = this.onClick.bind(this);
        this.getSortIcon = this.getSortIcon.bind(this);
        this.getSortComponent = this.getSortComponent.bind(this);
    }

    onClick() {
        let direction;
        const { header, sortDirection, sortKey, headerKey, invertSortResults } = this.props;
        let _sortDirection = sortDirection;

        if (!header.sortable) {
            return;
        }

        const sortDescendingFirst = invertSortResults ? !this.props.sortDescendingFirst : this.props.sortDescendingFirst;

        if (sortKey !== headerKey) {
            _sortDirection = Constantes.SORT_TYPE.NONE;
        }

        switch (_sortDirection) {
            default:
            case null:
            case undefined:
            case Constantes.SORT_TYPE.NONE:
                direction = sortDescendingFirst ? Constantes.SORT_TYPE.DESC : Constantes.SORT_TYPE.ASC;
                break;
            case Constantes.SORT_TYPE.ASC:
                direction = sortDescendingFirst ? Constantes.SORT_TYPE.NONE : Constantes.SORT_TYPE.DESC;
                break;
            case Constantes.SORT_TYPE.DESC:
                direction = sortDescendingFirst ? Constantes.SORT_TYPE.ASC : Constantes.SORT_TYPE.NONE;
                break;
        }
        window.scrollTo(0, 0);
        this.props.onSort(this.props.headerKey, direction);
    };

    getSortIcon() {
        const { header, sortDirection, sortKey, headerKey, invertSortResults } = this.props;

        if (!header.sortable) {
            return null;
        }

        let classes = { up: "triangle-icon-up", down: "triangle-icon-dowm" };

        if (invertSortResults) {
            classes = { down: "triangle-icon-up", up: "triangle-icon-dowm" };
        }

        if (sortKey === headerKey) {
            if (sortDirection === Constantes.SORT_TYPE.ASC) {
                return <TriangleIcon className={classes.up} size={10} />
            } else if (sortDirection === Constantes.SORT_TYPE.DESC) {
                return <TriangleIcon className={classes.down} size={10} />
            }
        }
        return <SortIcon />
    };

    getSortComponent() {
        if (this.props.sortComponent) {
            return (<div className="sort-component">{this.props.sortComponent}</div>);
        }
        let header = this.props.header;
        if (header.sortable) {
            return (<span className="custom-table-header-selection-icons">
                {header.sortLegend ? <span className="sort-legend">{header.sortLegend}</span> : ""}
                {this.getSortIcon()}
                {header.renderOtherHeaderActions && header.renderOtherHeaderActions()}
            </span>);
        }
        return null;

    }


    render() {
        const { header, className, containerTag, sortComponent } = this.props;
        const ContainerTag = containerTag;
        return (
            <ContainerTag className={'custom-header-container ' + className} onClick={sortComponent ? () => { } : this.onClick}>
                <span id={header.key}>
                    {
                        header.fullName &&
                        <UncontrolledTooltip placement='top' boundariesElement='window' delay={0} target={header.key} >
                            {header.fullName}
                        </UncontrolledTooltip>
                    }
                    {header.tooltip}

                    {header.icon ?
                        <div className='icon-name'>
                            <div className='icon'> {header.icon} </div>
                            <span>{header.name}</span>
                        </div>
                        : <span>{header.name}</span>
                    }
                </span>
                {this.getSortComponent()}
            </ContainerTag>
        )
    }
}
