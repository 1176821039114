import * as AlertFormActions from './AlertFormBasicActions';
import { RetailCompassStorage } from "@common/utils/"
import { AlertService } from "@common/data-access/"

const DEFAULT_USER_ID = 2455;

export const initialState = {
    isSaving: false,
    error: null,
    alert: null,
    isEdit: false,
    hasError: false,
    type: null
};

export const actionType = { CREATE: "create", EDIT: "edit" }


export const actionTypeCallback = {
    [actionType.CREATE]: {
        requestCallback: AlertFormActions.requestCreateAlertAction,
        receiveCallback: AlertFormActions.receiveCreateAlertAction,
        receiveErrorCallback: AlertFormActions.receiveCreateAlertErrorAction
    },
    [actionType.EDIT]: {
        requestCallback: AlertFormActions.requestEditAlertAction,
        receiveCallback: AlertFormActions.receiveEditAlertAction,
        receiveErrorCallback: AlertFormActions.receiveEditAlertErrorAction
    }
}

export const buildBody = (payload) => {
    const currentUser = RetailCompassStorage.getUser();
    const isRealUser = currentUser.isRealUser || false;
    let userId = (isRealUser ? currentUser.id : DEFAULT_USER_ID);
    return { ...payload, userId };
}

export const buildMethod = (dispatch, actType, payload) => {
    let data = buildBody(payload);

    let method = AlertService[actType];
    if (!method) {
        return;
    }

    let callbacks = { ...actionTypeCallback[actType] };

    let buildPayload = (response, request) => ({
        response: response,
        requestBody: {
            ...request
        }
    });

    const _receiveCallback = callbacks.receiveCallback;
    callbacks.receiveCallback = (response) => {
        return _receiveCallback(buildPayload(response, payload));
    };

    const _receiveErrorCallback = callbacks.receiveErrorCallback;
    callbacks.receiveErrorCallback = (response) => {
        let action = _receiveErrorCallback(response);
        action.payload = buildPayload({ error: action.payload.error }, payload);
        return action;
    };

    return method({
        dispatch,
        data,
        ...callbacks
    })
}



export const createAlert = (dispatch, payload) => {
    return buildMethod(dispatch, actionType.CREATE, payload)
};
export const editAlert = (dispatch, payload) => {
    return buildMethod(dispatch, actionType.EDIT, payload)
};
export const receiveCreateAlert = (dispatch, payload) => {
    return dispatch(AlertFormActions.receiveCreateAlertAction({
            response: payload,
            requestBody: {
                ...payload
            }
        }
    ))
};

export const resetAlertForm = () => ({
    type: AlertFormActions.RESET_FORM
});


export const AlertFormBasicStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};
    switch (action.type) {
        case AlertFormActions.RESET_FORM:
            return { ...initialState };
        case AlertFormActions.REQUEST_CREATE_ALERT:
            return Object.assign({}, state, { isSaving: true, hasError: false });
        case AlertFormActions.RECEIVE_CREATE_ALERT_ERROR:
            return Object.assign({}, state, {
                error: action.payload.response.error,
                hasError: true,
                isEdit: false,
                savedAlert: false,
                isSaving: false,
                type: action.payload.requestBody.type
            });
        case AlertFormActions.REQUEST_EDIT_ALERT:
            return Object.assign({}, state, { isSaving: true, hasError: false, isEdit: true });
        //retorna el mismo state para ambos casos create y edit    
        case AlertFormActions.RECEIVE_CREATE_ALERT:
        case AlertFormActions.RECEIVE_EDIT_ALERT:
            return Object.assign({}, state, {
                isSaving: false,
                hasError: false,
                savedAlert: true,
                alert: action.payload.response,
                isEdit: true,
                type: action.payload.requestBody.type
            });
        case AlertFormActions.RECEIVE_EDIT_ALERT_ERROR:
            return Object.assign({}, state, {
                error: action.payload.response.error,
                hasError: true,
                isSaving: false,
                savedAlert: false,
                isEdit: true,
                type: action.payload.requestBody.type
            });
        default:
            return state;
    }
};

