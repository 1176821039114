import React, { Component } from 'react'
import Star from '@material-ui/icons/Star';
import CloseIcon from 'mdi-react/CloseIcon';
export default class FavoriteInput extends Component {
    render() {
        const { input: { value, onChange } } = this.props
        return (
            <div className={"favorite-filter " + (value ? "favorite-filter--active" : "")} >
                <div className="favorite-filter-button" onClick={() => onChange(!value)}>
                    <Star className="star" /><span>Tus favoritos</span><CloseIcon className="close" size={22} />
                </div>
            </div>
        )
    }
}
