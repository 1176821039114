import { isPlainObject } from "lodash";
import { RECEIVE_FAVORITE, RECEIVE_FAVORITE_ERROR } from "./FavoriteActions"
import { RetailCompassStorage } from "@common/utils/"
import { getDefaultFavorite } from './FavoriteState';

export const favoriteMiddleware = ({ getState }) => {
    return (next) => (action) => {
        if (isPlainObject(action)) {
            const result = next(action);
            if (result && result.type === RECEIVE_FAVORITE && result.payload) {
                RetailCompassStorage.setFavoriteConfig(result.payload.favorite)
            } else if (result && result.type === RECEIVE_FAVORITE_ERROR) {
                // si existe algún error al momento de obtener la información de favoritos,
                // es necesario establecer un valor.
                // establecemos el mismo si la categoría es igual o nulo si el usuario cambió de categoría
                RetailCompassStorage.setFavoriteConfig(getDefaultFavorite());
            }
            return result;
        }
    };
};
