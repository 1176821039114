import GA4 from "react-ga4";
import ReactGA from "react-ga";
import * as RetailCompassStorage from '../app/Storage';
import { ENV } from './utils';
import { isObject, lowerCase } from 'lodash';
import { Constantes, Utils } from "..";
import moment from "moment";

export const gaID = process.env.REACT_APP_RC_GA_ID;
export const ga4ID = process.env.REACT_APP_RC_GA4_ID;
export const defaultSpeedSampleRate = 100;

let loaded = false;
let loadedGA4 = false;
let startTime = false;
let executeLoad = false;

export const init = (id, params) => {
    if (isLoaded()) { return; }
    params = params ? params : {};
    ReactGA.initialize(id || gaID, {
        testMode: Utils.ENV.isJest(),
        titleCase: false,
        gaOptions: { siteSpeedSampleRate: defaultSpeedSampleRate },
        ...params
    });
    initGA4();
    loaded = true;
}

export const trackGAPage = (pageUrl, customParams) => {
    if (!isLoaded()) { return; }
    if (!pageUrl) {
        pageUrl = window.location.pathname + window.location.search;
    }
    let params = getCustomDimensions();
    if (customParams && isObject(customParams)) {
        params = { ...params, customParams };
    }
    ReactGA.ga('send', 'pageview', pageUrl, { ...params });
    trackGAPageGA4(pageUrl);
    return true;
}

export const trackGAEvent = (category, action, label, value, customParams) => {
    if (!isLoaded()) { return; }
    let params = getCustomDimensions();
    if (customParams && isObject(customParams)) {
        params = { ...params, ...customParams };
    }
    const event = {
        category: category || "unknown",
        action: action || "unknown",
        label: label || "unknown",
        value: value || "unknown",
        ...params
    };
    ReactGA.event(event);
    trackGA4Event(category, action, label, value)
    return true;
}

export const isLoaded = () => {
    return !!window.ga || (ENV.isJest() && loaded);
}

export const printTimeToUse = () => {
    const { performance } = window;
    if (!performance) return;
    return performance.now();
}

const getCustomDimensions = () => {
    if (RetailCompassStorage.isLoggedIn()) {
        const dimensions = {
            dimension1: RetailCompassStorage.getUser().email,
            dimension2: RetailCompassStorage.getUser().userType,
            dimension3: RetailCompassStorage.getFullStoreName(),
            dimension4: RetailCompassStorage.getUser().isRealUser,
            dimension5: RetailCompassStorage.getCountryCode(),
            dimension6: RetailCompassStorage.getProductType(),
            dimension7: RetailCompassStorage.getJobTitle(),
            dimension8: RetailCompassStorage.getActiveRanking().toString(),
            dimension9: "SCALIBUR",
            dimension10: RetailCompassStorage.getHidePublicLabels().toString()
        };
        return dimensions;
    }
    return {};
}

export const initGA4 = () => {
    if (isLoadedGA4()) { return; }
    const user = RetailCompassStorage.getUser();
    const params = getUserDimensions(user);
    const eventParams = getEventDimensions();
    if (user) {
        GA4.initialize(ga4ID, {
            testMode: Utils.ENV.isJest(),
            gaOptions: {
                userId: user.id,
            },
            gtagOptions: {
                ...eventParams
            }
        });
        GA4.gtag("set", "user_properties", { ...params })
        loadedGA4 = true;
        startTime = printTimeToUse();
    }
}

export const initLoadTime = () => {
    if (!executeLoad) {
        const endTime = printTimeToUse();
        executeLoad = true;
        return endTime;
    }
    return 0;
}


export const trackGAPageGA4 = (pageUrl) => {
    pageUrl = getTitlePage(pageUrl);
    GA4.gtag("set", "page_path", pageUrl)
    GA4.send({ hitType: "pageview", page: pageUrl })
}

export const trackGA4Event = (category, action, label, value) => {
    if (!isLoaded()) { return; }
    const event = {
        category: category || "unknown",
        action: action || "unknown",
        label: label || "unknown",
        value: value || "unknown",
    };
    GA4.event(event);
    return true;
}

export const resetPagePath = () => {
    GA4.gtag("set", "page_path", undefined)
}

export const isLoadedGA4 = () => {
    return (ENV.isJest() && loadedGA4);
}

const getEventDimensions = () => {
    const dimensions = {
        APP: "SCALIBUR",
    };
    return dimensions;
}

const getUserDimensions = (user) => {
    if (RetailCompassStorage.isLoggedIn()) {
        const dimensions = {
            UserID: user.id,
            UserType: user.userType,
            Store: user.currentStore.internalName,
            StrategicUser: user.strategic,
            StoreCountry: user.currentStore.country,
            ProductType: user.currentStore.storeType,
            UserRole: user.jobTitle,
            UserName: user.email,
            UserCreatedDate: moment(user.created).format("yyyy-MM-DD")
        };
        return dimensions;
    }
    return {};
}

const getTitlePage = (pageUrl) => {
    if (!pageUrl.includes(Constantes.GA_CATEGORY_EVENTS.DOWNLOAD_SHEET)) {
        if (pageUrl.includes("/list") && !lowerCase(pageUrl).includes("report")) {
            pageUrl = "/PRODUCT/LIST"
        } else if (pageUrl.includes("/product/")) {
            pageUrl = "/PRODUCT/DETAIL"
        } else if (pageUrl.includes("/compare")) {
            pageUrl = "/PRODUCT/COMPARE"
        } else if (pageUrl.includes("COMPETITIVENESS")) {
            pageUrl = "/PRODUCT/LIST/COMPETITIVENESS_REPORT"
        } else if (pageUrl.includes("OUT_OF_STOCK")) {
            pageUrl = "/PRODUCT/LIST/OUT_OF_STOCK_REPORT"
        } else if (pageUrl.includes("DISCOUNTS")) {
            pageUrl = "/PRODUCT/LIST/DISCOUNTS_REPORT"
        } else if (pageUrl.includes("SHARE_OF_SHELF")) {
            pageUrl = "/PRODUCT/LIST/SHARE_OF_SHELF_REPORT"
        }
    }
    return pageUrl;
}