import React from 'react'
import LabelModal from './LabelModal'

const AddLabelProductModal = ({ onConfirm, onCancel }) => {

    const title = <>Agregar producto <br/> al label público</>;
    const description = <>Este cambio afectará a todas las personas <br /> que vean y usen este label</>;
    const subTitle = "¿Estás seguro de realizar esta acción?";
    const confirmAction = "Si, agregar";

    return <LabelModal
        title={title}
        description={description}
        subtitle={subTitle}
        confirmAction={confirmAction}
        onCancel={onCancel}
        onConfirm={onConfirm}
    />
}

export default AddLabelProductModal;