import React from 'react';
import * as Utils from './utils';
import * as PriceUtils from '../../../components/src/common/components/product_detail/PriceUtils';
import * as RetailCompassStorage from '../../src/app/Storage';
import * as Format from '../../src/matched-products/format';
import * as Constantes from './constantes';
import { filter, minBy, mean, orderBy, isEqual, find, includes, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import { validateIfMatchIsMyProductByBrandsIds } from '../matched-products/common/match';
import CheckBoxField from '@common/components/src/ui/CheckBox';

export const ALLOWED_STATES = [Constantes.STATUS_PRODUCT.WITH_STOCK, Constantes.STATUS_PRODUCT.WITHOUT_STOCK];
export const COMPETITORS_COLORS = [
    "#33d500", "#ff0080", "#ff9d00", "#943126", "#2fcbac", "#8b95a0", "#cad100",
    "#ff0000", "#ff87dc", "#008137", "#a230d3", "#2300ff", "#9a7d0a"
];

export const DEFAULT_DATE_RANGE = {
    startDate: moment().utcOffset(0).startOf("day").subtract(1, "week"),
    endDate: moment().utcOffset(0).startOf("day")
}

export const convertProductsForComparator = (canonicalProducts, userBrandItems) => {
    if (!Utils.isArrayWithData(canonicalProducts)) {
        return [];
    }

    let productsWithoutModel = canonicalProducts.filter(product => !Utils.hasValue(product.model)).length;
    let usedColorsMap = { colors: [], currentIndex: -1 };
    let alternativeModelCounter = 0;

    return canonicalProducts.map((product) => {
        let alternativeModel = undefined;

        if (productsWithoutModel > 0 && !Utils.hasValue(product.model)) {
            alternativeModelCounter++;
            alternativeModel = 'SIN MODELO';
            if (productsWithoutModel > 1) {
                alternativeModel = alternativeModel + ' ' + alternativeModelCounter;
            }
        }

        return {
            original: {
                ...product,
                id: product.productId,
                productId: product.productId,
                name: product.name,
                competitorsProducts: product.competitorsProducts,
                allCompetitorsProducts: product.allCompetitorsProducts,
                yoursProducts: product.yoursProducts,
                price: product.price,
                image: product.image,
                model: product.model,
                brand: product.brand,
                created: product.created,
                directMatches: product.directMatches,
                attributes: product.attributes,
                hideMarker: true,
                hideFavorite: true,
                hideCheckbox: true,
                isMyProduct: userBrandItems ? validateIfMatchIsMyProductByBrandsIds(userBrandItems, product) : false,
                alternativeModel: alternativeModel,
                color: getComparerSeriesColor(usedColorsMap, product.productId)
            }
        }
    });
}

const getComparerSeriesColor = (usedColorsMap, canonicalProductId) => {
    let foundColor = find(usedColorsMap.colors, ["canonicalProductId", canonicalProductId]);
    if (foundColor) {
        return foundColor.color;
    }

    let lastCompetitorColorIndex = COMPETITORS_COLORS.length - 1;

    if (usedColorsMap.currentIndex >= lastCompetitorColorIndex) {
        usedColorsMap.currentIndex = 0;
    } else {
        usedColorsMap.currentIndex += 1;
    }

    let usedColor = { canonicalProductId: canonicalProductId, color: COMPETITORS_COLORS[usedColorsMap.currentIndex] };
    usedColorsMap.colors.push(usedColor);
    return usedColor.color;
}

export const orderStores = (stores) => {
    if (!Utils.isArrayWithData(stores)) {
        return [];
    }
    return orderBy(stores, ['aggregations.matches'], ['desc']);
}

export const getProductIdsForHistoric = (canonicalProducts, lastFilter) => {
    if (!Utils.isArrayWithData(canonicalProducts)) {
        return [];
    }

    lastFilter = lastFilter || {};
    const { includeStores } = RetailCompassStorage.getUser();
    const { sellerType } = lastFilter;
    const isSeller = Utils.isSeller(sellerType);

    const filterStore = (product) => {
        const conditionalStores = includes(includeStores, product.storeId);
        if (isSeller === null) {
            return conditionalStores;
        }
        return conditionalStores && product.isSeller === isSeller;
    };

    let allProductIdItems = [];
    canonicalProducts.forEach(currentProduct => {
        let originalProduct = currentProduct.original;
        let competitorsProducts = Format.buildUniqueProductByStoreInMarket(filter(originalProduct.allCompetitorsProducts, filterStore)) || [];
        let oldCompetitorsProducts = Format.buildUniqueProductByStoreInMarket(filter(originalProduct.oldDirectMatches, filterStore)) || [];
        allProductIdItems = allProductIdItems.concat(competitorsProducts.map((item) => item.id));
        allProductIdItems = allProductIdItems.concat(oldCompetitorsProducts.map((item) => item.productId));
    });

    return uniq(allProductIdItems);
}

export const getComparerData = (canonicalProducts, historyData, stores, selectedComparer, dates) => {
    if (!Utils.isArrayWithData(canonicalProducts)
        || !Utils.isArrayWithData(dates)) {
        return [];
    }
    let comparerData = canonicalProducts.map((p) => {
        return getHistory(p.original, historyData, stores, selectedComparer, dates);
    });
    return comparerData;
}

export const getHistory = (canonicalProduct, historyData, stores, selectedComparer, dates) => {
    if (selectedComparer.view === Constantes.COMPARER_TYPE.MIN_PRICE) {
        return buildByMinPrice(canonicalProduct, historyData, stores, dates);
    } else if (selectedComparer.view === Constantes.COMPARER_TYPE.AVG_PRICE) {
        return buildByAvgPrice(canonicalProduct, historyData, stores, dates);
    } else if (selectedComparer.view === Constantes.COMPARER_TYPE.RETAILERS) {
        return buildByRetailer(canonicalProduct, historyData, stores, selectedComparer, dates);
    }
    return [];
}

export const buildByRetailer = (canonicalProduct, historyData, stores, selectedComparer, dates) => {
    const competitorsProducts = getCompetitorsProducts(canonicalProduct, true);
    if (!Utils.isArrayWithData(competitorsProducts)) return []

    const filteredMatches = competitorsProducts
        .filter(match => match.storeId === parseInt(selectedComparer.value));
    const historyMap = convertHistoricToMap(historyData);
    const today = moment.utc().format(Constantes.DATE_FORMATS.DEFAULT_FORMAT);
    let weeklyHistory = [];

    for (const key in dates) {
        let priceItems = [];
        const date = moment.utc(dates[key]).format(Constantes.DATE_FORMATS.DEFAULT_FORMAT);

        if (date === today) {
            priceItems = getPriceItemsToday(filteredMatches, stores);
        } else {
            priceItems = getPriceItemsBySpecificDate(filteredMatches, historyMap[date], stores);
        }
        priceItems = orderBy(priceItems, ['paymentType'], ['desc']);

        if (Utils.isArrayWithData(priceItems)) {
            const priceItemsWithStock = filter(priceItems, (priceItem) => { return priceItem.status === Constantes.STATUS_PRODUCT.WITH_STOCK })
            if (Utils.isArrayWithData(priceItemsWithStock)) {
                const minPriceItem = minBy(priceItemsWithStock, 'price')
                    || priceItemsWithStock[0];
                weeklyHistory.push({
                    price: minPriceItem.price,
                    priceMapping: minPriceItem.priceMapping,
                    date: date,
                    canonicalProductId: canonicalProduct.productId,
                    status: Constantes.STATUS_PRODUCT.WITH_STOCK,
                    paymentType: minPriceItem.paymentType,
                    fees: minPriceItem.fees,
                    model: canonicalProduct.model || canonicalProduct.alternativeModel,
                    isRetailView: true,
                    showCardIcon: minPriceItem.showCardIcon,
                    isSeller: minPriceItem.isSeller,
                    sellerName: minPriceItem.sellerName,
                    productId: minPriceItem.productId,
                    applyToPriceView: minPriceItem.applyToPriceView
                });
            } else {
                const minPriceItem = minBy(priceItems, 'price');
                weeklyHistory.push({
                    price: minPriceItem.price,
                    priceMapping: minPriceItem.priceMapping,
                    date: date,
                    canonicalProductId: canonicalProduct.productId,
                    status: Constantes.STATUS_PRODUCT.WITHOUT_STOCK,
                    paymentType: minPriceItem.paymentType,
                    fees: minPriceItem.fees,
                    model: canonicalProduct.model || canonicalProduct.alternativeModel,
                    isRetailView: true,
                    showCardIcon: minPriceItem.showCardIcon,
                    isSeller: minPriceItem.isSeller,
                    sellerName: minPriceItem.sellerName,
                    productId: minPriceItem.productId,
                    applyToPriceView: minPriceItem.applyToPriceView
                });
            }
        } else {
            weeklyHistory.push({
                date: date,
                canonicalProductId: canonicalProduct.productId,
                model: canonicalProduct.model || canonicalProduct.alternativeModel,
            });
        }
    }
    return weeklyHistory;
};

export const buildByMinPrice = (canonicalProduct, historyData, stores, dates) => {
    const competitorsProducts = getCompetitorsProducts(canonicalProduct, false);
    if (!Utils.isArrayWithData(competitorsProducts)
        || !Utils.isArrayWithData(stores)) {
        return [];
    }

    const storeIds = stores.map((store) => { return parseInt(store.storeId) });
    const filteredMatches = filter(competitorsProducts, (match) => {
        if (storeIds.includes(match.storeId)) {
            return match;
        }
    });
    const historyMap = convertHistoricToMap(historyData);
    const today = DEFAULT_DATE_RANGE.endDate.format(Constantes.DATE_FORMATS.DEFAULT_FORMAT);
    let weeklyHistory = [];

    for (const key in dates) {
        let priceItems = [];
        const date = moment(dates[key]).utcOffset(0).format(Constantes.DATE_FORMATS.DEFAULT_FORMAT);

        if (date === today) {
            priceItems = getPriceItemsToday(filteredMatches, stores);
        } else {
            priceItems = getPriceItemsBySpecificDate(filteredMatches, historyMap[date], stores);
        }
        priceItems = orderBy(priceItems, ['paymentType'], ['desc']);

        if (Utils.isArrayWithData(priceItems)) {
            const priceItemsWithStock = filter(priceItems, (priceItem) => { return priceItem.status === Constantes.STATUS_PRODUCT.WITH_STOCK })
            if (Utils.isArrayWithData(priceItemsWithStock)) {
                const minPriceItem = minBy(priceItemsWithStock, 'price') || priceItemsWithStock[0]
                const retailersItems = filterMinPrice(priceItemsWithStock, minPriceItem);
                weeklyHistory.push({
                    price: minPriceItem.price,
                    retailers: mapRetailers(retailersItems),
                    date: date,
                    canonicalProductId: canonicalProduct.productId,
                    status: Constantes.STATUS_PRODUCT.WITH_STOCK,
                    paymentType: minPriceItem.paymentType,
                    model: canonicalProduct.model || canonicalProduct.alternativeModel,
                    showCardIcon: minPriceItem.showCardIcon,
                    applyToPriceView: minPriceItem.applyToPriceView
                });
            } else {
                const minPriceItem = minBy(priceItems, 'price');
                const retailersItems = filterMinPrice(priceItems, minPriceItem);
                weeklyHistory.push({
                    price: minPriceItem.price,
                    retailers: mapRetailers(retailersItems),
                    date: date,
                    canonicalProductId: canonicalProduct.productId,
                    status: Constantes.STATUS_PRODUCT.WITHOUT_STOCK,
                    paymentType: minPriceItem.paymentType,
                    model: canonicalProduct.model || canonicalProduct.alternativeModel,
                    showCardIcon: minPriceItem.showCardIcon,
                    applyToPriceView: minPriceItem.applyToPriceView
                });
            }
        } else {
            weeklyHistory.push({
                date: date,
                canonicalProductId: canonicalProduct.productId,
                model: canonicalProduct.model || canonicalProduct.alternativeModel
            });
        }
    }
    return weeklyHistory;
}

export const buildByAvgPrice = (canonicalProduct, historyData, stores, dates) => {
    const competitorsProducts = getCompetitorsProducts(canonicalProduct, false);
    if (!Utils.isArrayWithData(competitorsProducts)
        || !Utils.isArrayWithData(stores)) {
        return [];
    }
    const storeIds = stores.map((store) => parseInt(store.storeId));
    const filteredMatches = filter(competitorsProducts, (match) => storeIds.includes(match.storeId));
    const historyMap = convertHistoricToMap(historyData);
    const today = moment.utc().format(Constantes.DATE_FORMATS.DEFAULT_FORMAT);
    let weeklyHistory = [];

    for (const key in dates) {
        let priceItems = [];
        const date = moment.utc(dates[key]).format(Constantes.DATE_FORMATS.DEFAULT_FORMAT);

        if (date === today) {
            priceItems = getPriceItemsToday(filteredMatches, stores);
        } else {
            priceItems = getPriceItemsBySpecificDate(filteredMatches, historyMap[date], stores);
        }
        priceItems = orderBy(priceItems, ['paymentType'], ['desc']);

        if (Utils.isArrayWithData(priceItems)) {
            const priceItemsWithStock = filter(
                priceItems,
                (item) => item.status === Constantes.STATUS_PRODUCT.WITH_STOCK
            )
            const uniqPriceItems = uniqBy(priceItemsWithStock, 'storeName')
            if (Utils.isArrayWithData(uniqPriceItems)) {
                const mappedPrices = uniqPriceItems
                    .filter(item => !!item.price)
                    .map((item) => item.price)
                const avgPrice = parseFloat(mean(mappedPrices).toFixed())

                weeklyHistory.push({
                    price: avgPrice,
                    date: date,
                    canonicalProductId: canonicalProduct.productId,
                    status: Constantes.STATUS_PRODUCT.WITH_STOCK,
                    model: canonicalProduct.model || canonicalProduct.alternativeModel,
                    applyToPriceView: true
                });
            } else {
                weeklyHistory.push({
                    date: date,
                    canonicalProductId: canonicalProduct.productId,
                    status: Constantes.STATUS_PRODUCT.WITHOUT_STOCK,
                    model: canonicalProduct.model || canonicalProduct.alternativeModel
                });
            }
        } else {
            weeklyHistory.push({
                date: date,
                canonicalProductId: canonicalProduct.productId,
                model: canonicalProduct.model || canonicalProduct.alternativeModel
            });
        }
    }
    return weeklyHistory;
}

export const getPriceItemsBySpecificDate = (matches, mapHistory, stores) => {
    if (!Utils.isArrayWithData(matches) || !mapHistory) {
        return [];
    }
    return filter(matches.map((match) => {
        let historyItem = mapHistory[match.productId];
        if (historyItem && ALLOWED_STATES.includes(historyItem.status)) {
            return buildPriceItem(historyItem.prices, match, historyItem, stores);
        }
    }), price => price != null);
}

export const getPriceItemsToday = (matches, stores) => {
    if (!Utils.isArrayWithData(matches)) {
        return [];
    }
    return filter(matches.map((match) => {
        if (ALLOWED_STATES.includes(match.statusProduct)) {
            const prices = {
                price: match.price,
                normalPrice: match.normalPrice,
                offerPrice: match.offerPrice
            };
            return buildPriceItem(prices, match, null, stores);
        }
    }), price => price != null);
}

export const buildPriceItem = (prices, match, historyItem, stores) => {
    const pricesView = RetailCompassStorage.getPriceView();
    const fees = historyItem ? historyItem.fees : match.fees;
    const status = historyItem ? historyItem.status : match.statusProduct;
    const paymentType = historyItem ? historyItem.tipoPago : match.paymentType;
    const includeCardPrices = true;
    const price = Utils.getPrice(prices, paymentType, includeCardPrices, pricesView);
    const priceMapping = PriceUtils.getPriceMapping({ ...prices, paymentType });
    const isPriceWithCard = Utils.checkCardPrice(priceMapping.paymentType, priceMapping, pricesView);
    const applyToPriceView = !!price && !!priceMapping.price

    if (!price && !priceMapping.price) return;

    return {
        price: price,
        priceMapping: priceMapping,
        storeId: match.storeId,
        storeName: Utils.getProductStoreName(stores, match),
        status: status,
        fees: fees,
        paymentType: priceMapping.paymentType,
        showCardIcon: Utils.validateIsCardPriceWithView(isPriceWithCard, pricesView),
        isSeller: match.isSeller,
        sellerName: match.sellerName,
        productId: match.productId,
        applyToPriceView
    }
}

export const filterMinPrice = (priceItems, minPriceItem) => {
    return filter(priceItems, (priceItem) => isEqual(priceItem.price, minPriceItem.price));
}

export const convertHistoricToMap = (historyData) => {
    if (!Utils.isArrayWithData(historyData)) {
        return {};
    }

    let historyMap = {};
    historyData.forEach((history) => {
        let keyDate = moment.utc(history.date).format(Constantes.DATE_FORMATS.DEFAULT_FORMAT);
        let mapProducts = {}
        history.products.forEach((product) => {
            let keyProduct = product.productId;
            mapProducts[keyProduct] = product;
        });
        historyMap[keyDate] = mapProducts;
    });
    return historyMap;
}

export const buildTableHeaders = (dates, dateFormat) => {
    let headers = [];
    dates
        .sort((o1, o2) => {
            return o1.isBefore(o2) ? -1 : o1.isAfter(o2) ? 1 : 0;
        })
        .forEach(date => {
            headers.push({
                week: date.isoWeek(),
                date: date.format(dateFormat)
            });
        })
    return headers;
}

export const mapRetailers = (retailersItems) => {
    return retailersItems
        .map((item) => {
            return {
                storeId: item.storeId,
                name: item.storeName,
                paymentType: item.paymentType,
                showCardIcon: item.showCardIcon,
                isSeller: item.isSeller,
                sellerName: item.sellerName
            }
        })
}

export const getCompetitorsProducts = (canonicalProduct, uniqByStore) => {
    if (!canonicalProduct
        || !canonicalProduct.allCompetitorsProducts
        || !canonicalProduct.competitorsProducts) {
        return [];
    }
    let currentMatches = Format.buildMatchesForCanonicalProduct(
        canonicalProduct.allCompetitorsProducts,
        canonicalProduct.competitorsProducts,
        canonicalProduct.yoursProducts,
        null) || [];

    if (uniqByStore) {
        currentMatches = Format.orderMatches(currentMatches, null)
        currentMatches = uniqBy(currentMatches, 'storeId')
    }

    if (!Utils.isArrayWithData(canonicalProduct.oldDirectMatches)) {
        return currentMatches;
    }

    let oldMatches = Format.buildUniqueProductByStoreInMarket(canonicalProduct.oldDirectMatches);
    return [...currentMatches, ...oldMatches];
}

export const generateTooltipComparer = (onClickCallback) => {
    return (
        <div style={{ display: 'inline-flex' }}><hr className='Rectangle' />
            <div className="checkbox-container"><CheckBoxField value={true} label={null} className='compass' input={{ value: true, name: "check", onChange: () => { } }} /></div>
            <div className="Primero-selecciona-p">Primero selecciona productos y presiona <br></br> "Comparar productos”. <br></br>
                <br></br><span className="text-style-1"> O tambien puedes </span>
                <button onClick={onClickCallback} className="text-style-2 text-button btn"> probar con algunos productos.</button>
            </div>
        </div>
    );
}
