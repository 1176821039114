import React, { memo } from "react";
import { translate } from "react-i18next";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import {
    DownloadExcel,
    CompareButton,
    SummaryBrandContainer,
    ProductListSummaryContainer,
    ShippingFilterContainer,
    ContentView as ContentViewOriginal,
    NewBadge,
    RankingSwitch,
    TotalSummary,
} from "@common/components";
import { RetailCompassStorage, RouterUtils } from "@common/utils/";
import LabelIcon from "@material-ui/icons/Label";
import TableContainer from "../table/TableContainer";
import ReportView from "../reports/indexContainer";
import TableShippingContainer from "../shipping/tableShipping/TableShippingContainer";

export const goToProductList = (props, params) => {
    RouterUtils.goToProductListTabView({
        location: props.location,
        history: props.history,
        onSelectedView: props.onSelectedView,
        params,
    });
};

export const SummaryBrandContainerCard = memo(
    (props) => {
        let myBrands = RetailCompassStorage.getBrandIds();
        let summariesConfig = {
            summary1: {
                isLocked: false,
                onClick: () => goToProductList(props, {}),
            },
            summary2: {
                isLocked: false,
                onClick: () =>
                    goToProductList(props, { brands: myBrands.join(",") }),
            },
        };

        return (
            <Card className="product-list-summary product-list-summary--brand">
                <CardBody className="product-list-summary-counter product-list-summary-counter--unclickable">
                    <SummaryBrandContainer summariesConfig={summariesConfig} />
                </CardBody>
            </Card>
        );
    },
    (prevProps, nextProps) => nextProps.customId === prevProps.customId
);

export const ListOptionsContainer = (props) => {
    const isEnableRanking = RetailCompassStorage.getEnableRanking();
    let ViewPricesComponent = props.viewPricesComponent;

    return (
        <Col md={12} lg={12} className="product-list-content clearfix">
            <Card className="product-list-summary download-section">
                <CardBody>
                    <Row style={{ width: "100%" }} className="table-summary">
                        {isEnableRanking && (
                            <Col
                                md={3}
                                className="ranking-switch ranking-switch-size"
                            >
                                <RankingSwitch
                                    onToggle={props.updateActiveRanking}
                                ></RankingSwitch>
                            </Col>
                        )}
                        {ViewPricesComponent && (
                            <Col
                                md={2}
                                className={isEnableRanking ? "pl-0" : ""}
                            >
                                <div className="price-view">
                                    <ViewPricesComponent
                                        {...props}
                                        viewPriceValue={props.getInitValueViewPrice(
                                            props
                                        )}
                                    />
                                </div>
                            </Col>
                        )}
                        <Col
                            md={isEnableRanking ? 7 : 9}
                            className="list-options ml-auto"
                        >
                            <div
                                style={{
                                    position: "relative",
                                    display: "inline",
                                }}
                            >
                                <CompareButton
                                    dispatch={props.dispatch}
                                    selectedProducts={props.selectedProducts}
                                    maxProductsToCompare={30}
                                    resetStoreAction={props.resetStoreAction}
                                />
                                <NewBadge
                                    text="Hasta 14 meses atrás"
                                    style={{
                                        position: "absolute",
                                        top: "-12px",
                                        right: "20px",
                                        margin: 0,
                                    }}
                                />
                            </div>

                            <Button
                                size={"sm"}
                                className="icon labels-button text-button"
                                onClick={props.showLabelsForm}
                            >
                                <LabelIcon />
                                Asignar label
                            </Button>

                            <DownloadExcel
                                paginationCount={props.paginationCount}
                                lastSearchRequest={props.lastSearchRequest}
                                isDownloading={props.isDownloading}
                                downloadFile={props.downloadFile}
                                className="text-button"
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );
};

export const ListOptionsContainerShipping = (props) => {
    return (
        <Col md={12} lg={12} className="product-list-content clearfix">
            <Card className="product-list-summary download-section">
                <CardBody>
                    <Row style={{ width: "100%" }} className="table-summary">
                        <TotalSummary
                            productCount={props.paginationCount}
                            shippingCount={props.shippingCount}
                        />
                    </Row>
                    <div className="list-options ml-auto">
                            <DownloadExcel
                               paginationCount={props.paginationCount}
                               lastSearchRequest={props.lastSearchRequest}
                               isDownloading={props.isDownloading}
                               downloadFile={props.downloadFile}
                               className="text-button"
                            />
                        </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export const getProductListSummaryContainerComponent = ({
    hideDownload,
    customStyle,
    selectedProducts,
    showLabelsForm,
    summaryCard,
    currentFilter,
}) => {
    return (
        <ProductListSummaryContainer
            hideDownload={hideDownload}
            customStyle={customStyle}
            listOptions={ListOptionsContainer}
            downloadSectionClassName="product-list-summary--brand download-section--brand"
            selectedProducts={selectedProducts}
            showLabelsForm={showLabelsForm}
            summaryCard={summaryCard}
            currentFilter={currentFilter}
        />
    );
};

export const getReportView = (props) => {
    return (
        <ReportView
            productListSummaryView={getProductListSummaryContainerComponent({
                hideDownload: true,
                customStyle: undefined,
                selectedProducts: props.selectedProducts,
                showLabelsForm: props.showLabelsForm,
                selectedView: props.selectedView,
                summaryCard: SummaryBrandContainerCard,
            })}
        />
    );
};

export const getProductListView = (props) => {
    let customStyle = {
        haveIt: {
            paddingLeft: 15,
        },
        matches: {
            paddingRight: 15,
        },
    };

    return (
        <div className="product-list-brand-container">
            {getProductListSummaryContainerComponent({
                hideDownload: true,
                customStyle: customStyle,
                selectedProducts: props.selectedProducts,
                showLabelsForm: props.showLabelsForm,
                currentFilter: props.currentFilter,
                summaryCard: (ownProps) =>
                    ListOptionsContainer({ ...ownProps, ...props }),
            })}
            <TableContainer />
        </div>
    );
};

export const getShippingView = (props) => {
    let customStyle = {
        haveIt: {
            paddingLeft: 15,
        },
        matches: {
            paddingRight: 15,
        },
    };

    return (
        <div className="product-list-brand-container">
            <Col md={12} className="shipping-tabs">
                <Card>
                    <CardBody className="container-filter-shipping">
                        <ShippingFilterContainer selectType={"COST"} />
                    </CardBody>
                </Card>
            </Col>
            {getProductListSummaryContainerComponent({
                hideDownload: true,
                customStyle: customStyle,
                selectedProducts: props.selectedProducts,
                showLabelsForm: props.showLabelsForm,
                currentFilter: props.currentFilter,
                summaryCard: (ownProps) =>
                    ListOptionsContainerShipping({ ...ownProps, ...props }),
            })}
            <TableShippingContainer />
        </div>
    );
};

export const ContentView = (props) => {
    let newProps = {
        ...props,
        getReportView: () => getReportView(props),
        getProductListView: () => getProductListView(props),
        getShippingView: () => getShippingView(props),
    };
    return <ContentViewOriginal {...newProps} />;
};

export default translate("common")(ContentView);
