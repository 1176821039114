import * as ValidationActions from "../ValidationActions";
import { findIndex } from 'lodash';

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestCanonicalFindPotentialDuplicates = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsFindingPotentialDuplicates: true
    });
}

export const receiveCanonicalFindPotentialDuplicates = (state, action) => {
    const response = action.payload.response;

    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsFindingPotentialDuplicates: false,
        canonicalPotentialDuplicates: response
    });
}

export const receiveCanonicalFindPotentialDuplicatesError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsFindingPotentialDuplicates: false
    });
}

export const setDiscardedPotentialDuplicate = (state, action) => {
    let { canonicalPotentialDuplicates } = state
    let inx = findIndex(canonicalPotentialDuplicates, ["id", action.payload.product.id])
    canonicalPotentialDuplicates[inx].discardedAsDuplicate = true;
    return updateObject(state, {
        canonicalPotentialDuplicates: canonicalPotentialDuplicates
    });
}

const handlers = {
    [ValidationActions.REQUEST_CANONICAL_POTENTIAL_DUPLICATES]: requestCanonicalFindPotentialDuplicates,
    [ValidationActions.RECEIVE_CANONICAL_POTENTIAL_DUPLICATES]: receiveCanonicalFindPotentialDuplicates,
    [ValidationActions.RECEIVE_CANONICAL_POTENTIAL_DUPLICATES_ERROR]: receiveCanonicalFindPotentialDuplicatesError,
    [ValidationActions.DISCARD_POTENTIAL_DUPLICATE]: setDiscardedPotentialDuplicate ,
}

export default handlers