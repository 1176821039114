
export default class MergeCanonicalInputBuilder {
    setJobId(jobId) {
        this.jobId = jobId;
        return this;
    }
    setUserId(userId) {
        this.userId = userId;
        return this;
    }
    setFromProductId(fromProductId) {
        this.fromProductId = fromProductId;
        return this;
    }
    setToProductId(toProductId) {
        this.toProductId = toProductId;
        return this;
    }
    setUsername(username) {
        this.username = username;
        return this;
    }
    setSourceId(sourceId) {
        this.sourceId = sourceId;
        return this;
    }
    setStoreId(storeId) {
        this.storeId = storeId;
        return this;
    }
    build() {
        return {
            jobId: this.jobId,
            userId: this.userId,
            fromProductId: this.fromProductId,
            toProductId: this.toProductId,
            username: this.username,
            sourceId: this.sourceId,
            storeId: this.storeId
        }
    }
}