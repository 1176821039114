import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Utils} from "@common/utils/";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export default class RetailerButton extends Component {

    static propTypes = {
        storeName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        url: PropTypes.string,
        isSeller: PropTypes.bool.isRequired,
        className: PropTypes.string,
        statusProduct: PropTypes.number
    };

    static defaultProps = {
        className: ""
    };

    constructor(props) {
        super(props);
        this.state = {
        }

        this.buildMkpTag = this.buildMkpTag.bind(this);
        this.buildClassName = this.buildClassName.bind(this);
    }
    buildMkpTag( isMkp ){
        if(!isMkp){
            return null;
        }
        return <span className="mkp-tag">MKP</span>;
    }

    buildClassName(){
        let classes = ["retailer-button", this.props.className];
        if( !Utils.productHasStock(this.props) ){
            classes.push("retailer-button--no-stock")
        }
        if( !this.props.url ){
            classes.push("no-events")
        }
        return classes.join(" ");
    }

    render() {
        const {storeName, url, price, isSeller} = this.props;
        return(<div className={this.buildClassName()} onClick={() => { Utils.openInNewTab(url) }}>
            <div className="responsive-block">
                { this.buildMkpTag( isSeller ) }                                
                <span className="retailer-button__name">{storeName}</span>
            </div>
            <span className="retailer-button__slash"> / </span>
            <div className="responsive-block">
                <span className="retailer-button__price">{Utils.formatPrice(price)}</span>
                <span className="retailer-button__icon"><OpenInNewIcon /></span> 
            </div>
        </div>);
    }
}
