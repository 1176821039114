import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FavoriteButton from '../../../favorite/FavoriteContainer';
import ProductImage from '../ProductImage';
import CheckboxLabel from '../../../labels/checkbox-label/CheckboxLabelContainer';
import EditIcon from '@material-ui/icons/Edit';
import AttributeTags from '../AttributeTags';
import { LabelsProductList } from './LabelsProductList';
import { ProductMarker } from './ProductMarker';
import { Constantes, Utils } from '@common/utils/';

const SimpleProductCell = (props) => {

    const {
        productId,
        currentFilter,
        showRankingInfo,
        rankingInfo,
        productInfo,
        markerProductClass,
        productCardClass,
        isMyProduct,
        hideMarker,
        hideCheckbox,
        hideFavorite,
        hidePublicLabels,
        imageUrl,
        dpmValue,
        minimumPrice,
        attributeValues,
        labels,
        editAction,
        handleRemoveProductLabelClick,
        editLinkProps,
        showEditLink
    } = props

    const ref = useRef()
    const [randomId] = useState(Utils.generateUniqueID())

    function onEditClick(e) {
        avoidClickPropagation(e);
        if (editAction) {
            editAction();
        }
    }

    function avoidClickPropagation(e) {
        e.stopPropagation();
    }

    const RankingInfo = rankingInfo
    const ProductInfo = productInfo

    return (
        <div ref={ref} className={"product-card clearfix " + productCardClass}>
            <ProductMarker
                isMyProduct={isMyProduct}
                hideMarker={hideMarker}
                id={"marker-" + randomId}
                classNames={markerProductClass}
            />

            <div className="product-content clearfix">
                {!hideCheckbox && currentFilter.selectedView === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST &&
                    <div className={"checkbox-container"} onClick={avoidClickPropagation}>
                        <CheckboxLabel productId={productId} />
                    </div>
                }

                {!hideFavorite &&
                    <div className={"favorite-container"} onClick={avoidClickPropagation}>
                        <FavoriteButton productId={productId} key={productId} />
                    </div>
                }

                <div className="img-container">
                    <ProductImage imageUrl={imageUrl} />
                </div>

                <div className="info-container">
                    <div className="info-section">
                        <AttributeTags enhancedAttributes={attributeValues} parentRef={ref} className="attributes" />

                        <div className='info'>
                            {ProductInfo &&
                                <ProductInfo  {...{ ...props, randomId }} />
                            }
                            {showRankingInfo && RankingInfo &&
                                <RankingInfo {...{ dpmValue, minimumPrice, typesContracts: currentFilter.contractType }} />
                            }
                        </div>

                     <LabelsProductList productLabels={labels}
                            labelsFilter={currentFilter.labels}
                            productId={productId}
                            hidePublicLabels={hidePublicLabels}
                            handleOnRemove={handleRemoveProductLabelClick}
                        />
                        
                    </div>
                </div>

            </div>

            {
                showEditLink &&
                <div className="edit-product">
                    <a href={editLinkProps.url || ""}
                        onClick={onEditClick}
                        target={editLinkProps.target || "_blank"} >
                        <EditIcon />
                    </a>
                </div>
            }
        </div>
    );
}

SimpleProductCell.propTypes = {
    name: PropTypes.string,
    productId: PropTypes.number,
    productName: PropTypes.string,
    currentFilter: PropTypes.object,
    showRankingInfo: PropTypes.bool,
    rankingInfo: PropTypes.func,
    productInfo: PropTypes.func,
    markerProductClass: PropTypes.string,
    productCardClass: PropTypes.string,
    isMyProduct: PropTypes.bool.isRequired,
    hideMarker: PropTypes.bool,
    hideCheckbox: PropTypes.bool,
    hideFavorite: PropTypes.bool,
    hidePublicLabels: PropTypes.bool,
    imageUrl: PropTypes.string,
    dpmValue: PropTypes.number,
    minimumPrice: PropTypes.number,
    attributeValues: PropTypes.array,
    labels: PropTypes.array,
    editAction: PropTypes.func,
    handleRemoveProductLabelClick: PropTypes.func,
    editLinkProps: PropTypes.object,
    showEditLink: PropTypes.bool,
    model: PropTypes.string,
    brand: PropTypes.string,
    color: PropTypes.string,
    showModelTooltip: PropTypes.bool,
    created: PropTypes.string,
    competitorsProducts: PropTypes.array,
}

export default memo(SimpleProductCell);
