import { StoreType, UserType, USER_TYPE_CODE, CLIENT_SUCCESS } from '../utils/constantes';
import { get, find, isNil, includes } from "lodash";
import { ENV, isArrayWithData, hasValue, isNullOrUndefined } from '../utils/utils';
import { addUserData as addUserDataTrackJS, removeUserData as removeUserDataTrackJS } from '../scripts/track';
import { updateUserObj } from '../utils/userUtils';
import { removeIntercomSettings } from '../scripts/intercom';
import * as Constantes from '../utils/intercomConstants';
import packageJson from "../../../../package.json";

export const APP_KEY = "RetailCompassAPP-User";
export const STORES = "RetailCompassAPP-Stores";
export const APP_CONFIG = "RetailCompassAPP-AppConfig";
export const FAVORITE_CONFIG = "RetailCompassAPP-FavoriteConfig";
export const ACTIVE_RANKING = "RetailCompassAPP-ActiveRanking";
export const CLIENT_SUCCESS_REQUIRED = "RetailCompassAPP-ClientSuccessRequired";
export const APP_LAST_SELECTED_STORE_BY_DYNAMIC_USER = "RetailCompassAPP-last-selected-store-and-category-by-user-{userId}";
export const PRICE_VIEW = "RetailCompassAPP-PriceView";
export const PRODUCT_ACCES_SCALIBUR = "RetailCompassAPP-ProductAccessScalibur";
export const DEFAULT_COMMUNE = "RetailCompassAPP-DefaultCommune";

export function setUser(payload) {
    localStorage.setItem(APP_KEY, JSON.stringify(payload));
    addUserDataTrackJS();
}

export function setDefaultCommune(payload) {
    localStorage.setItem(DEFAULT_COMMUNE, JSON.stringify(payload));
}

export function getDefaultCommune() {
    const dCommune = localStorage.getItem(DEFAULT_COMMUNE);
    
    if (!dCommune || dCommune === 'null' || dCommune.trim().length <= 0) {
        return null;
    }
    const defaultCommune = JSON.parse(dCommune);
    return defaultCommune.id;
}

export function setAppConfig(payload) {
    localStorage.setItem(APP_CONFIG, JSON.stringify(payload));
}

export function setFavoriteConfig(payload) {
    localStorage.setItem(FAVORITE_CONFIG, JSON.stringify(payload));
}

export function getUser() {
    const user = localStorage.getItem(APP_KEY);
    if (!user || user.trim().length <= 0) {
        return null;
    }
    return JSON.parse(user);
}

export function getAppConfig() {
    const config = localStorage.getItem(APP_CONFIG);
    if (!config || config.trim().length <= 0) {
        return null;
    }
    return JSON.parse(config);
}

export function getStoreId(user = getUser()) {
    return user && user.currentStore ? user.currentStore.id : null;
}

export function getCurrentCategoryAttributes() {
    const category = getCurrentCanonicalCategory();
    return category && category.attributes ? category.attributes : [];
}

export function getCurrentCategoryAttribute(id) {
    return getCurrentCategoryAttributes().find((item) => item.id === id);
}

export function getBrandIds(user = getUser()) {
    if (!user || !user.currentStore) return []
    const brands = user.currentStore.brands;
    if (!brands) return [];
    return brands.map(value => value.id);
}

export function getExcludedStores() {
    const user = getUser();
    return user.excludedStores || [];
}

export function getIncludeStores() {
    const user = getUser();
    return user != null ? (user.includeStores || []) : [];
}

export function getIncludeOperators() {
    const user = getUser();
    return user.includeOperators || [];
}

export function getMyOperators() {
    const operators = getIncludeOperators();
    if (!isArrayWithData(operators)) {
        return [];
    }
    const storeId = getStoreId();
    return operators.filter(operator => parseInt(operator.split("-")[0]) === storeId);
}

export function getStoreType(user = getUser()) {
    return user && user.currentStore && user.currentStore.storeType;
}

export function getCanonicalCategories() {
    const user = getUser();
    return user.currentStore.canonicalCategories;
}

export function getCanonicalCategoryByKey(categoryKey) {
    const categories = getCanonicalCategories();
    return find(categories, ['key', categoryKey]);
}

export function getBrands() {
    const user = getUser();
    let brands = user.currentStore.brands || [];

    return {
        mainBrand: brands.find((item) => item.isMain),
        allBrands: brands
    };
}

export function getDefaultCanonicalCategory() {
    const user = getUser();
    return user.currentStore.defaultCanonicalCategory;
}

export function setCurrentCanonicalCategory(currentCanonicalCategory) {
    let user = { ...getUser() };
    user.currentStore.currentCanonicalCategory = currentCanonicalCategory;
    setUser(user);
    return user;
}

export function getCurrentCanonicalCategory(user = getUser()) {
    if (!user) {
        return null;
    }

    if (!user.currentStore.currentCanonicalCategory) {
        user.currentStore.currentCanonicalCategory = user.currentStore.defaultCanonicalCategory;
        setUser(user);
        user = getUser();
    }
    return user.currentStore.currentCanonicalCategory;
}

export function getStoreName() {
    const user = getUser();
    return user.currentStore.name;
}

export function getStoreInternalName() {
    const user = getUser();
    return user.currentStore.internalName;
}

export function getCountryCode(user = getUser()) {
    if (user && user.currentStore) {
        return user.currentStore.country;
    }
    else {
        const app = getAppConfig();
        if (app) {
            return getAppConfig().countryCode;
        }
        return null;
    }
}

export function isLoggedIn() {
    let logged = isValidUser(getUser());
    if (logged) {
        if (packageJson.version !== getUser().appVersion && !ENV.isJest()) {
            logout();
            return false;
        }
        updateUser(!ENV.isProduction());
    }
    return logged;
}

export function updateUser(forced) {
    let user = getUser();
    if (user && (!user.appVersion || user.appVersion !== packageJson.version)) {
        let newObj = updateUserObj(user.id);
        if (newObj) {
            newObj.authenticationToken = newObj.token;
            setUser(newObj);
        }
    }
}

export function isValidUser(user) {
    return user && user.id && user.currentStore && isArrayWithData(user.currentStore.categories);
}

export function isValidToken() {
    let user = getUser();

    if (user && user.exp && new Date(user.exp * 1000) < new Date()) {
        return false;
    }

    return true;
}

export function isAdmin() {
    return getUser().userType === UserType.ADMIN;
}

export function isCustomerUser() {
    return getUser() && getUserType() && Constantes.ACCEPTED_USERS.indexOf(getUserType()) !== -1;
}

export function isStoreTypeBrand(user) {
    return getStoreType(user) === StoreType.BRAND;
}

export function isStoreTypeRetailer(user) {
    return getStoreType(user) === StoreType.RETAILER;
}

export function getAuthenticationToken() {
    if (localStorage.getItem(APP_KEY)) {
        let parsed = JSON.parse(localStorage.getItem(APP_KEY))
        if (parsed) {
            return parsed.authenticationToken;
        }
    }
    return null;
}
export function getFavoriteConfig() {
    if (localStorage.getItem(FAVORITE_CONFIG)) {
        return JSON.parse(localStorage.getItem(FAVORITE_CONFIG))
    }
    return null;
}
export function getStoreTypeCode() {
    const user = getUser();
    return user.currentStore.storeTypeCode;
}

export function logout() {
    removeUserDataTrackJS();
    removeIntercomSettings();
    localStorage.removeItem(APP_KEY);
    localStorage.removeItem(FAVORITE_CONFIG);

}

export function getFullName() {
    const user = getUser();
    if (user.name) {
        return user.name + (user.lastName ? " " + user.lastName : "");
    } else {
        return user.currentStore.name + " " + user.currentStore.country;
    }
}

export function getFullStoreName() {
    const user = getUser();
    return user.currentStore.name + " " + user.currentStore.country;
}

export function getEmail() {
    const user = getUser();
    return user.email;
}

export function getRole() {
    const user = getUser();
    return user.role;
}

export function getId() {
    const user = getUser();
    return parseInt(user.id);
}

export function getAppVersion() {
    const user = getUser();
    return user.appVersion;
}

export function getUsername() {
    const user = getUser();
    return user.nickname;
}

export function getJobTitle() {
    const user = getUser();
    return user.jobTitle;
}

export function getStoreCountry() {
    const user = getUser();
    return user.currentStore.country;
}

export function getProductType() {
    const user = getUser();
    return user.currentStore.storeType;
}

export function setAppCountryCode(countryCode) {
    setAppConfig({ countryCode });
}

export function getUserType() {
    const user = getUser();
    return user.userType;
}
export function getUserTypeForIntercom() {
    let userType = String(getUserType()).toUpperCase();
    return userType === USER_TYPE_CODE.CUSTOMER ? USER_TYPE_CODE.USER : userType;
}

export function isClientSuccessTrackable() {
    return CLIENT_SUCCESS.ACCEPTED_USERS.indexOf(getUserType().toUpperCase()) !== -1;
}

export function isStrategic() {
    const user = getUser();
    return !!user.strategic;
}

export function getPhone() {
    const user = getUser();
    return user.phone;
}

export function getClientSuccessMap(key) {
    const clientSuccessData = getUser().currentStore.clientSuccessData;
    if (!clientSuccessData || !clientSuccessData[key]) {
        return {
            organizationId: getStoreId(),
            organizationName: getStoreInternalName()
        };
    } else {
        const map = clientSuccessData[key].split('::');
        return {
            organizationId: map[0],
            organizationName: map[1]
        };
    }
}

export function getStores() {
    const user = getUser();
    return user.stores;
}

const buildAppLastSelectedStoreByDynamicUser = (userId) => {
    let currentUserId = userId;

    if (!currentUserId) {
        const currentUser = getUser();
        currentUserId = get(currentUser, 'id')
    }

    if (currentUserId) {
        return APP_LAST_SELECTED_STORE_BY_DYNAMIC_USER.replace("{userId}", currentUserId);
    }

    return null;
}

export function setLastSelectedStoreAndCategory(storeId, categoryId, categoryKey) {
    const key = buildAppLastSelectedStoreByDynamicUser(undefined);
    localStorage.setItem(key, JSON.stringify({ storeId, categoryId, categoryKey }));
}

export function getLastSelectedStoreAndCategory(userId) {
    const key = buildAppLastSelectedStoreByDynamicUser(userId);
    const config = localStorage.getItem(key);
    let savedConfig = {};

    if (hasValue(config)) {
        savedConfig = JSON.parse(config);
    }

    return {
        lastSelectedStoreId: savedConfig.storeId,
        lastSelectedCategoryId: savedConfig.categoryId,
        lastSelectedCategoryKey: savedConfig.categoryKey,
    };
}

export function isEnableProductRanking() {
    const user = getUser();
    return user.currentStore.enableProductRanking !== undefined && user.currentStore.enableProductRanking == true;
}

export function isEnableCategoryRanking() {
    const user = getUser();
    return includes(user.currentStore.currentCanonicalCategory.configuration, "ranking\": true");
}

export function getConfigurationCurrentCategory() {
    const user = getUser();
    if (isNullOrUndefined(user) || isNullOrUndefined(user.currentStore) 
        || isNullOrUndefined(user.currentStore.currentCanonicalCategory) 
        || isNullOrUndefined(user.currentStore.currentCanonicalCategory.configuration)) {
        return null;
        
    }
    return JSON.parse(user.currentStore.currentCanonicalCategory.configuration);
}

export function setActiveRanking(value) {
    localStorage.setItem(ACTIVE_RANKING, value);
}
export function setTrackClientSuccessRequired(value) {
    localStorage.setItem(CLIENT_SUCCESS_REQUIRED, value);
}
export function isTrackClientSuccessRequired() {
    const value = localStorage.getItem(CLIENT_SUCCESS_REQUIRED);
    return value !== undefined && value == 'true';
}

export function getActiveRanking() {
    const value = localStorage.getItem(ACTIVE_RANKING);
    return value !== undefined && value == 'true';
}

export function getCurrentCategoryMatchAttributes() {
    const currentCategory = getCurrentCanonicalCategory();
    return isArrayWithData(currentCategory.matchAttributes)
        ? currentCategory.matchAttributes
        : []
}

export function setPriceView(value) {
    localStorage.setItem(PRICE_VIEW, value);
}

export function getPriceView() {
    const value = localStorage.getItem(PRICE_VIEW);
    return value ? value.split(",") : ["ALL"];
}

export function setHidePublicLabels(value) {
    if (!isNil(value)) {
        let object = getAppConfig();
        if (!object) object = {};
        object.hidePublicLabels = value;
        setAppConfig(object);
    }
}

export function getHidePublicLabels() {
    let object = getAppConfig();
    if (!object) {
        return false;
    }
    return object.hidePublicLabels || false;
}

export function getEnableRanking() {
    return isEnableCategoryRanking() && isEnableProductRanking();
}

export function setTotalStores(payload) {
    localStorage.setItem(STORES, JSON.stringify(payload));
}

export function getTotalStores() {
    const values = localStorage.getItem(STORES);
    return JSON.parse(values)
}

export function setProductAccessConfig(productAccess) {
    localStorage.setItem(PRODUCT_ACCES_SCALIBUR, JSON.stringify(productAccess));
}

export function getProductAccessConfig() {
    const productAccess = localStorage.getItem(PRODUCT_ACCES_SCALIBUR);
    if (isNullOrUndefined(productAccess)) {
        return null;
    }
    return JSON.parse(productAccess);
}

export function getModulesAccessConfig() {
    const productAccess = getProductAccessConfig();
    return isArrayWithData(productAccess.modules) ? productAccess.modules : [];
}
