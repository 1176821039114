
export class MatchInputBuilder {
    setBrand(brand) {
        this.brand = brand;
        return this;
    }
    setSku(sku) {
        this.sku = sku;
        return this;
    }
    setKeyWords(keyWords) {
        this.keyWords = keyWords;
        return this;
    }
    setProductIdsToExclude(productIdsToExclude) {
        this.productIdsToExclude = productIdsToExclude;
        return this;
    }
    setModel(model) {
        this.model = model;
        return this;
    }
    setStoreFilters(storeFilters) {
        this.storeFilters = storeFilters;
        return this;
    }
    build() {
        return {
            brand: this.brand,
            sku: this.sku,
            keyWords: this.keyWords || '',
            productIdsToExclude: this.productIdsToExclude || [],
            model: this.modelundefined,
            storeFilters: this.storeFilters || []
        }
    }
}

export class StoreFilterBuilder {
    constructor() {
    }
    setStoreId(storeId) {
        this.storeId = storeId;
        return this;
    }
    setChildren(children) {
        this.children = children;
        return this;
    }
    build() {
        if (!this.storeId) {
            throw new Error('storeId required');
        }
        return {
            storeId: this.storeId,
            children: !!this.children
        }
    }
}