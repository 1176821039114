import React, { Component } from 'react'
import PropTypes from 'prop-types';
import CustomModal from "@common/components/src/ui/CustomModal";
import { Row, Col } from 'reactstrap';
import { RetailerButton } from "@common/components";
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { AttributesUtils } from '@common/utils/';

class ImageProductComparer extends Component {

    static propTypes = {
        product: PropTypes.object.isRequired,
        canonicalProduct: PropTypes.object.isRequired,
        productActions: PropTypes.object,
        leftTitle: PropTypes.string,
        rightTitle: PropTypes.string,
        thumbnailMainTitle: PropTypes.string,
    };

    static defaultProps = {
        leftTitle: "Producto Canónico",
        rightTitle: "Candidato a Match: ",
        thumbnailMainTitle: "Canónico"
    }

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            currentProduct: { ...props.canonicalProduct }
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.buildTapeItemClass = this.buildTapeItemClass.bind(this);
    }

    toggleModal() {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    buildTapeItemClass(product) {
        let classes = ["image-tape__item"];
        if (product.productId === this.state.currentProduct.productId) {
            classes.push("image-tape__item--selected");
        }
        if (product.isCanonical) {
            classes.push("image-tape__item--main");
        }
        return classes.join(" ");
    }

    render() {
        let { canonicalProduct, product } = this.props;
        if (!canonicalProduct || !product) return null;
        let matches = [...canonicalProduct.directMatches];
        const { productName, brand, model, attributes, image, productId } = canonicalProduct;
        matches.unshift({ productName, brand, model, attributes, image, productId, isCanonical: true });
        let productActions = React.cloneElement(this.props.productActions, { onActionCompleted: this.toggle });
        return (<div className={this.props.className}>
            <img src={product.image} alt="" />
            <div className="veil" onClick={this.toggleModal}>
                <div className="veil-icon"><AspectRatioIcon /></div>
                <div className="veil-text">Comparar</div>
            </div>
            {
                !this.state.isOpenModal ? null :
                    <CustomModal
                        body={
                            <Row>
                                <Col sm={6}>
                                    <div className="comparing-title">
                                        {
                                            this.props.canonicalProduct.productId === this.state.currentProduct.productId ?
                                                this.props.leftTitle :
                                                <span>{"Match idéntico: "}<span className="storeName">{this.state.currentProduct.storeName}</span></span>
                                        }
                                    </div>
                                    <div className="comparing-image">
                                        <img src={this.state.currentProduct.image} />
                                    </div>
                                    <div className="product-info">
                                        <div className="product-info__info product-info__info--bold">
                                            {this.state.currentProduct.productName}
                                        </div>
                                        <div className="product-info__info">
                                            {this.state.currentProduct.brand}
                                        </div>
                                        <div className="product-info__info">
                                            {this.state.currentProduct.model}
                                        </div>
                                        {
                                            this.state.currentProduct.attributes ?
                                                <div className="product-info__attributes">
                                                    {
                                                        canonicalProduct.enhancedAttributes.map((o, index) => (<span key={index}>{AttributesUtils.buildAttributeValue(o)}</span>))
                                                    }
                                                </div>
                                                : <RetailerButton
                                                    price={this.state.currentProduct.price}
                                                    storeName={this.state.currentProduct.storeName}
                                                    isSeller={this.state.currentProduct.isSeller}
                                                    url={this.state.currentProduct.url}
                                                    statusProduct={this.state.currentProduct.statusProduct || this.state.currentProduct.status}
                                                />
                                        }
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="comparing-title">
                                        {this.props.rightTitle} <span className="storeName">{product.storeName}</span>
                                    </div>
                                    <div className="comparing-image">
                                        <img src={product.image} />
                                    </div>
                                    <div className="product-info">
                                        <div className="product-info__info product-info__info--bold">
                                            {product.productName}
                                        </div>
                                        <div className="product-info__info">
                                            {product.brand}
                                        </div>
                                        <div className="product-info__info">
                                            {product.model}
                                        </div>
                                        <RetailerButton price={product.price} storeName={product.storeName} isSeller={product.isSeller} url={product.url} statusProduct={product.statusProduct} />
                                    </div>
                                    <div className="product-actions">
                                        {productActions}
                                    </div>
                                </Col>
                                <div className="image-tape-container">
                                    <div className="image-tape">
                                        {
                                            matches.map(((o, i) => {
                                                return (<div key={"tape-item-" + i} className={this.buildTapeItemClass(o)} onClick={() => { this.setState({ currentProduct: o }) }}>
                                                    <div className="item__image">
                                                        <img src={o.image} />
                                                    </div>
                                                    {   this.props.canonicalProduct.productId === o.productId ?
                                                            <div className="item__legend item__legend--bold">{this.props.thumbnailMainTitle}</div> :
                                                            <div className="item__legend">{o.storeName}</div>
                                                    }
                                                </div>)
                                            }))
                                        }
                                    </div>
                                </div>

                            </Row>}

                        isOpen={this.state.isOpenModal}
                        toggle={this.toggleModal}
                        className="validations-modal"
                    />
            }
        </div>
        )
    }
}

export default ImageProductComparer;
