import { attributeUrlSeparatorFromAttribute, transformAttributeUrlValue } from './attributesUtils';
import { find, filter, flow, pickBy, map, get } from 'lodash';
import { stringToArray, isArrayWithData, hasValue, isAttributeVisibleFromCountryCodeAndUserType } from './utils';
import * as RetailCompassStorage from '../app/Storage';

export const converterUrlSearchDynamicAttributesToFormFields = (attributeUrlParams) => {
    return flow([
        //atributos activos
        (attributes) => filter(attributes, item => isAttributeVisibleFromCountryCodeAndUserType(item.visualization.visible)),
        //filtra las claves del objeto urlSearch dejando solo los atributos (si existen) 
        (attributes) => ({ searchParams: pickBy(attributeUrlParams, (value, key) => find(attributes, { name: key }) && value !== undefined), attributes }),
        //for cada clave del objeto de urlsearch filtrado (solo contiene los atributos encontrados)
        (data) => map(data.attributes, (item) => {
            //formatea el  atributo, debe de ser igual a onChangeAttributeValue en withGenericFilters (ambos se envían a TableState.fetchProductItems() )
            return {
                id: item.id, name: item.name, type: item.type, "selectedData": stringToArray(data.searchParams[item.name], attributeUrlSeparatorFromAttribute(item)).map((attrId) => {
                    return { value: transformAttributeUrlValue(item, attrId, false), label: '', type: item.type }
                })
            }
        })

    ])(RetailCompassStorage.getCurrentCategoryAttributes());
}

export const converterFormFieldsDynamicAttributesToUrlSearchParams = (formFields) => {
    if (!formFields || !isArrayWithData(formFields.dynamicAttributes)) {
        return {};
    }

    let attributes = get(formFields, "dynamicAttributes", []);
    let attributeData = {};

    attributes
        .filter((item) => !(!item || !item.name || !isArrayWithData(item.selectedData)))
        .forEach((item) => {
            let selectedDataAsString = item.selectedData.filter(itemData => itemData.value).map((itemData) => {
                return transformAttributeUrlValue(item, itemData.value, true);
            }).join(attributeUrlSeparatorFromAttribute(item));
            if (hasValue(selectedDataAsString)) {
                attributeData[item.name] = selectedDataAsString;
            }
        })
    return attributeData;
}
