/**
 * @author @author <a href="mailto:fhurtado@retailcompass.com">Jose Fary Hurtado Cuero</a>
 * @class favoriteContainer.js
 * @date 15 may. 2018
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FavoriteButton from '@common/components/src/ui/FavoriteButton';
import PropTypes from 'prop-types';
import { find, toInteger } from 'lodash';
import * as FavoriteActions from './FavoriteActions';
import { fetchCreate, fetchDeleteByFavoriteAndProductIds } from './FavoriteState';

class FavoriteButtonWrapper extends Component {

    static propTypes = {
        currentProductId: PropTypes.number,
        favorite: PropTypes.object,
        favoriteProductItems: PropTypes.array,
        fetchCreate: PropTypes.func,
        fetchDeleteByFavoriteAndProductIds: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.isChecked = this.isChecked.bind(this);
        this.onClick = this.onClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.checkComponent = this.checkComponent.bind(this);

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.idComponent && this.props.currentProductId) {
            this.checkComponent(nextProps.idComponent);
        }
        return false;
    }

    checkComponent(idProduct) {
        if (toInteger(idProduct) === this.props.currentProductId) {
            this.wrapperRef.onFavoriteClick();
            this.props.dispatch(FavoriteActions.updateStateFavoriteAction({ idComponent: null }));
        }
    }

    isChecked() {
        let { currentProductId, favoriteProductItems } = this.props;
        let favoriteProduct = find(favoriteProductItems, { "productId": currentProductId });
        if (favoriteProduct && favoriteProduct.id) {
            return true;
        }
        return false;
    }

    onClick(item) {
        let { currentProductId,
            favorite,
            fetchCreate,
            fetchDeleteByFavoriteAndProductIds } = this.props;

        if (item.isChecked) {
            fetchCreate({ favoriteId: favorite.id, productIds: [currentProductId] });
        } else {
            fetchDeleteByFavoriteAndProductIds(favorite.id, [currentProductId]);
        }
    }

    setWrapperRef(node) {
        if (node) {
            this.wrapperRef = node;
        }
    }

    render() {
        return <FavoriteButton
            id={this.props.currentProductId}
            ref={this.setWrapperRef}
            favoriteObject={{}}
            isChecked={this.isChecked()}
            onClick={(item) => this.onClick(item)}
            tooltipText={"Agregar a favoritos"} checkedTooltipText={"Eliminar de favoritos"} />;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentProductId: ownProps.productId,
        favorite: state.favoriteStateReducer.favorite || {},
        favoriteProductItems: state.favoriteStateReducer.favoriteProductItems || [],
        idComponent: state.favoriteStateReducer.idComponent
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchCreate: (params) => fetchCreate(dispatch, params),
        fetchDeleteByFavoriteAndProductIds: (favoriteId, productIds) => fetchDeleteByFavoriteAndProductIds(dispatch, favoriteId, productIds),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteButtonWrapper);
