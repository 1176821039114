import {
    CHANGE_NEW_USER_VALUE,
    CHANGE_CURRENT_CANONICAL_CATEGORY,
    CHANGE_CURRENT_ROUTER_VIEW
} from '../actions/appConfigActions';

import * as RetailCompassStorage from '../../app/Storage';
const version = (require("../../../package.json")).version;
export const initialState = {
    isNewUser: false,
    version,
    currentCanonicalCategory: null,
    currentRouterView: null // la vista actual ejm: http://www.retailcompass.com/brand//tv/ESTA_ES_LA_VISTA 
};

export const appConfigReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case CHANGE_NEW_USER_VALUE:
            return Object.assign({}, state, { isNewUser: false });
        case CHANGE_CURRENT_CANONICAL_CATEGORY:
            const currentCanonicalCategory = action.payload.currentCanonicalCategory;
            RetailCompassStorage.setCurrentCanonicalCategory(currentCanonicalCategory);
            return Object.assign({}, state, { currentCanonicalCategory: currentCanonicalCategory });
        case CHANGE_CURRENT_ROUTER_VIEW:
            const currentRouterView = action.payload.currentRouterView;
            return Object.assign({}, state, { currentRouterView: currentRouterView });
        default:
            return state;
    }
}
