import { isArray, concat, uniq, isEqual } from "lodash";
import * as TableStateUtils from "./TableStateUtils";
import * as AttributesUtils from "./attributesUtils";
import * as Utils from "./utils";
import * as Constants from "./constantes";
import * as OperatorUtils from "./OperatorUtils";

const { buildMatchesFilter, setSkuFilter, getKeys, buildMatchesAttributes } =
    TableStateUtils;

export const buildSelectedStore = (storeIds) => {
    let stores = [];
    if (isArray(storeIds) && storeIds.length > 0) {
        stores = stores.concat(storeIds);
    }
    return stores;
};

export const buildSelectedStoreRetail = (storesIds, currentStoreId) => {
    if (!Utils.isArrayWithData(storesIds)) {
        return [];
    }
    let ids = [currentStoreId]
        .concat(storesIds || [])
        .filter((itemId) => !!itemId);
    return uniq(ids);
};

export const FORMAT_SELECTED_STORES_STRATEGIES = {
    [Constants.StoreType.BRAND]: (selectedStoresIds, currentUserStoreId) =>
        buildSelectedStore(selectedStoresIds),
    [Constants.StoreType.RETAILER]: (selectedStoresIds, currentUserStoreId) =>
        buildSelectedStoreRetail(selectedStoresIds, currentUserStoreId),
    [Constants.StoreType.SHIPPING_PROVIDER]: (
        selectedStoresIds,
        currentUserStoreId
    ) => buildSelectedStore(selectedStoresIds),
    [Constants.StoreType.SHIPPING_RETAILER]: (
        selectedStoresIds,
        currentUserStoreId
    ) => buildSelectedStoreRetail(selectedStoresIds, currentUserStoreId),
};

export const sortAddStoreTypePostpaid = (sort, storeType) => {
    let newSort = sortAddStoreType(sort, storeType);
    if (!newSort) return null;
    if (isEqual(newSort.field, Constants.SortValues.PRODUCT_RELEVANCE)) {
        newSort.subStoreType =
            Constants.SUB_STORE_TYPE_BY_STORE_TYPE[storeType];
    }
    return newSort;
};

export const sortAddStoreType = (sort, storeType) => {
    if (!sort) {
        return null;
    }
    if (
        sort.field === Constants.SortValues.PRODUCT_RELEVANCE ||
        sort.field === Constants.SortValues.SHIPPING_RELEVANCE
    ) {
        sort.storeType = storeType;
    }
    return sort;
};

export const SORT_STRATEGIES = {
    [Constants.StoreType.BRAND]: sortAddStoreType,
    [Constants.StoreType.RETAILER]: sortAddStoreType,
    [Constants.SubStoreType.RETAILER_POSTPAID]: sortAddStoreTypePostpaid,
    [Constants.SubStoreType.BRAND_POSTPAID]: sortAddStoreTypePostpaid,
    [Constants.StoreType.SHIPPING_PROVIDER]: sortAddStoreType,
    [Constants.StoreType.SHIPPING_RETAILER]: sortAddStoreType,
};

export const REPORT_AGGREGATIONS_STRATEGIES = {
    [Constants.StoreType.BRAND]: (currentAggregations, reportSelected) => {
        currentAggregations = currentAggregations || [];

        if (
            !currentAggregations.includes(
                Constants.AGGREGATIONS_NAMES.PRICE_RANGES_MATCHES
            )
        ) {
            currentAggregations.push(
                Constants.AGGREGATIONS_NAMES.PRICE_RANGES_MATCHES
            );
        }

        if (
            !currentAggregations.includes(
                Constants.AGGREGATIONS_NAMES.OUT_OF_STOCK_RANGES_MATCHES
            )
        ) {
            currentAggregations.push(
                Constants.AGGREGATIONS_NAMES.OUT_OF_STOCK_RANGES_MATCHES
            );
        }

        return currentAggregations;
    },
    [Constants.StoreType.RETAILER]: (currentAggregations, reportSelected) =>
        buildAggregationForReport(currentAggregations, reportSelected),
    [Constants.StoreType.SHIPPING_RETAILER]: (
        currentAggregations,
        reportSelected
    ) => buildAggregationForShippingReports(reportSelected),
    [Constants.StoreType.SHIPPING_PROVIDER]: (
        currentAggregations,
        reportSelected
    ) => buildAggregationForShippingReports(reportSelected),
};

export const buildProductSearchFilters = (
    payload,
    totalStoreIds,
    brandIds,
    storeType,
    currentUserStoreId,
    currentUserOperators,
    defaultCommune,
    communes
) => {
    let currentUserFilter = {};

    if (payload) {
        let storeIds = getKeys("storeId", payload.stores) || [];

        if (payload.paginationCurrentPage) {
            currentUserFilter.page = payload.paginationCurrentPage - 1;
        }
        if (isArray(payload.brands) && payload.brands.length > 0) {
            currentUserFilter.brandIds = getKeys("brandId", payload.brands);
        }
        if (payload.favorites && payload.favoriteId) {
            currentUserFilter.favoriteId = payload.favoriteId;
        }

        let attributes = [];
        if (
            isArray(payload.screenSizeItems) &&
            payload.screenSizeItems.length > 0
        ) {
            let attributesValues = getKeys("value", payload.screenSizeItems);
            attributes.push({
                id: Constants.ATTRIBUTES.SCREEN_SIZES_INCHES,
                values: attributesValues,
            });
        }
        if (Utils.isArrayWithData(payload.dynamicAttributes)) {
            attributes = concat(
                payload.dynamicAttributes
                    .filter(
                        (item) =>
                            item && Utils.isArrayWithData(item.selectedData)
                    )
                    .map((item) => {
                        let data = { id: item.id };
                        let dataKey =
                            AttributesUtils.attributeKeyFromType(item);
                        data[dataKey] = getKeys("value", item.selectedData);
                        return data;
                    }),
                attributes
            );
        }

        if (attributes.length > 0) {
            currentUserFilter.attributes = attributes;
        }

        //values dafault
        let contains = Constants.MATCHES.ALL;
        let formatSelectedStore = FORMAT_SELECTED_STORES_STRATEGIES[storeType];
        let filterStores = storeIds;
        if (
            payload.applicationData &&
            Utils.isArrayWithData(payload.applicationData.stores)
        ) {
            const applicationDataStores = payload.applicationData.stores
                ? payload.applicationData.stores.map((item) =>
                      parseInt(item.storeId)
                  )
                : null;
            filterStores = applicationDataStores.filter((item) =>
                storeIds.includes(item)
            );
        }

        /* Es posible que las tiendas seleccionadas en un módulo específico no estén
        disponibles al cambiar a otro módulo. En esta situación, la variable "filterStores"
        será un array vacío, por lo cual se asignan todas las tiendas disponibles. */
        if (!filterStores.length) {
            filterStores = totalStoreIds;
        }

        let stores =
            formatSelectedStore &&
            formatSelectedStore(filterStores, currentUserStoreId);

        if (
            (payload.haveIt || payload.dontHaveIt) &&
            payload.haveIt != payload.dontHaveIt
        ) {
            contains = payload.haveIt
                ? Constants.MATCHES.CONTAINED
                : Constants.MATCHES.NOT_CONTAINED;
        }

        const matchesAttributes = buildMatchesAttributes(payload);
        const matches = buildMatchesFilter(
            contains,
            null,
            stores,
            matchesAttributes
        );

        currentUserFilter.matches = matches;

        let comparisonPrices = [];

        if (payload.cheaper) {
            comparisonPrices.push(Constants.COMPARISON.CHEAPER);
        }

        if (payload.expensive) {
            comparisonPrices.push(Constants.COMPARISON.EXPENSIVE);
        }

        if (payload.same) {
            comparisonPrices.push(Constants.COMPARISON.SAME);
        }

        if (comparisonPrices.length > 0) {
            currentUserFilter.comparisonPrices = comparisonPrices;
        }

        if (payload.sort) {
            currentUserFilter.sort = {
                field: payload.sort.field,
                order: payload.sort.order,
            };
            if (payload.sort.field === Constants.SortValues.PRODUCT_RELEVANCE) {
                currentUserFilter.sort["brandIds"] = brandIds;
                currentUserFilter.sort["favoriteId"] = payload.favoriteId;
            }

            const sortType = OperatorUtils.currentCategoryIsOperator()
                ? Constants.SubStoreType.RETAILER_POSTPAID
                : storeType;
            let formatSort = SORT_STRATEGIES[sortType];
            if (formatSort) {
                currentUserFilter.sort = formatSort(
                    currentUserFilter.sort,
                    storeType
                );
            }
        }

        if (payload.skus && payload.skus.length > 0) {
            setSkuFilter(currentUserFilter, payload, totalStoreIds);
        }

        let keyWords = payload.keyWords;
        if (
            keyWords &&
            keyWords.length > 0 &&
            Utils.getSkuArray(keyWords).length <=
                Constants.MAXIMUM_NUMBER_OF_WORDS_ALLOWED
        ) {
            currentUserFilter.keyWords = payload.keyWords;
            currentUserFilter.keywordsSearchStrategy =
                Constants.KeyWordsSearchStrategy.TEXT_MATCH;
        }

        if (payload.from || payload.to) {
            currentUserFilter.priceRange = {};
            currentUserFilter.priceRange["from"] = Utils.formatNumber(
                payload.from
            );
            currentUserFilter.priceRange["to"] =
                Utils.formatNumber(payload.to) == 0
                    ? null
                    : Utils.formatNumber(payload.to);
            currentUserFilter.priceRange["type"] =
                payload.typePriceRange || Constants.TYPE_RANGE_PRICES.CANONICAL;
        }

        if (currentUserFilter.matches && payload[Constants.FILTER_OPERATORS]) {
            currentUserFilter.matches.storeIds = [];
            const operatorsSelected = getKeys(
                "value",
                payload[Constants.FILTER_OPERATORS]
            );
            const operatorsKeys = buildOperatorKeysRequest(
                operatorsSelected,
                currentUserOperators
            );
            currentUserFilter.matches.operatorKeys = uniq(operatorsKeys);
        }

        if (payload.labels && payload.labels.length > 0) {
            const labelIds = payload.labels.map((l) => {
                const splitLabel = l.toString().split("_");
                return splitLabel[0];
            });
            currentUserFilter.labelIds = labelIds;
        }

        // removing duplicates stores
        if (
            currentUserFilter.matches &&
            Utils.isArrayWithData(currentUserFilter.matches.storeIds)
        ) {
            currentUserFilter.matches.storeIds = uniq(
                currentUserFilter.matches.storeIds
            );
        }

        if (payload.sellerType) {
            currentUserFilter.sellerType = payload.sellerType;
        }

        if (
            Utils.isArrayWithData(payload.outOfStockRanges) &&
            Utils.isArrayWithData(
                payload.applicationData.outOfStockRangesConfig
            )
        ) {
            let outOfStockRangesConfig =
                payload.applicationData.outOfStockRangesConfig;
            currentUserFilter.outOfStockRanges = outOfStockRangesConfig.filter(
                (itemConfig) => {
                    let itemKey = itemConfig.from + "_" + itemConfig.to;
                    return payload.outOfStockRanges.includes(itemKey);
                }
            );
        }

        let shippingFilter = {};
        let communeFilter = [];
        if (Utils.isArrayWithData(communes)) {
            communeFilter = communes.map((commune) => {
                return { id: commune?.id, name: commune?.label };
            });
        }
        if (payload.commune) {
            shippingFilter.communeIds = [payload.commune];
            shippingFilter.communes = communeFilter;
        } else if (payload.defaultCommune) {
            shippingFilter.communeIds = [payload.defaultCommune];
            shippingFilter.communes = communeFilter;
        } else if (defaultCommune) {
            shippingFilter.communeIds = [defaultCommune];
            shippingFilter.communes = communeFilter;
        }

        if (payload.selectedView === Constants.TYPE_VIEW_RETAIL.SHIPPING) {
            if (payload.sendCost) {
                shippingFilter.analytics = Constants.SHIPPING_TYPE.COST;
                shippingFilter.status = Constants.SHIPPING_STATUS.HAS_SHIPPING;
                shippingFilter.price = 0.0;
            }

            if (payload.sendShipment) {
                shippingFilter.analytics = Constants.SHIPPING_TYPE.SHIPMENT;
                shippingFilter.status = Constants.SHIPPING_STATUS.HAS_SHIPPING;
                shippingFilter.days = 1;
            }
        }
        if (Utils.isObjectWithData(shippingFilter)) {
            currentUserFilter.shippingFilter = shippingFilter;
        }
    }
    return currentUserFilter;
};

export const buildOperatorKeysRequest = (operatorsSelected, myOperators) => {
    return [...operatorsSelected, ...myOperators];
};

export const buildAggregationForReport = (
    currentAggregations,
    reportSelected
) => {
    currentAggregations = currentAggregations || [];
    let newAggregations = [
        Constants.AGGREGATIONS_NAMES.EXPENSIVE_CHEAPER_SAME,
        Constants.AGGREGATIONS_NAMES.HAVE_IT_MATCHES,
    ];

    if (reportSelected === Constants.TYPE_REPORT_RETAIL.LOWER_PRICE) {
        newAggregations.push(
            Constants.AGGREGATIONS_NAMES.COMPARISON_PRICE_BY_RANGE
        );
        newAggregations.push(Constants.AGGREGATIONS_NAMES.HAVE_IT_RANGE_PRICE);
    } else if (reportSelected === Constants.TYPE_REPORT_RETAIL.BRANDS) {
        newAggregations.push(Constants.AGGREGATIONS_NAMES.HAVE_IT_BRAND);
        newAggregations.push(
            Constants.AGGREGATIONS_NAMES.COMPARISON_PRICE_BY_BRAND
        );
    } else if (reportSelected === Constants.TYPE_REPORT_RETAIL.RETAIL) {
        newAggregations.push(
            Constants.AGGREGATIONS_NAMES.COMPARISON_PRICE_BY_STORE
        );
        newAggregations.push(Constants.AGGREGATIONS_NAMES.SHARED_MATCHES);
    } else if (reportSelected === Constants.TYPE_REPORT_RETAIL.OUT_OF_STOCK) {
        newAggregations.push(
            Constants.AGGREGATIONS_NAMES.OUT_OF_STOCK_RANGES_MATCHES
        );
        newAggregations.push(
            Constants.AGGREGATIONS_NAMES.OUT_OF_STOCK_RANGES_BRAND_BY_RETAILER
        );
    } else if (
        [
            Constants.RETAIL_DISCOUNTS_REPORT.ALL,
            Constants.RETAIL_DISCOUNTS_REPORT.CARD_PRICE,
            Constants.RETAIL_DISCOUNTS_REPORT.SALE_PRICE,
        ].includes(reportSelected)
    ) {
        newAggregations.push(Constants.AGGREGATIONS_NAMES.RANGES_PROMOTIONAL);
    } else if (reportSelected === Constants.TYPE_REPORT_RETAIL.SHIPPING_COST) {
        newAggregations.push(Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE);
        newAggregations.push(
            Constants.AGGREGATIONS_NAMES.SHIPPING_COST_BY_RETAIL
        );
    }

    return uniq(newAggregations);
};

export const buildAggregationForShippingReports = (reportSelected) => {
    const newAggregations = [];

    if (reportSelected === Constants.TYPE_REPORT_RETAIL.SHIPPING_COST) {
        newAggregations.push(Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE);
        newAggregations.push(
            Constants.AGGREGATIONS_NAMES.SHIPPING_COST_BY_RETAIL
        );
    }

    return uniq(newAggregations);
};

export const buildAggregationRangePrice = (body, ranges) => {
    if (!ranges || !isArray(ranges) || ranges.length === 0) {
        return;
    }
    if (
        Utils.isArrayWithData(body.aggregations) &&
        !body.aggregations.includes(
            Constants.AGGREGATIONS_NAMES.PRICE_RANGES_MATCHES
        )
    ) {
        body.aggregations.push(
            Constants.AGGREGATIONS_NAMES.PRICE_RANGES_MATCHES
        );
    }
    body.ranges = ranges;
};
