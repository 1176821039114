export const CHANGE_NEW_USER_VALUE = 'APP_CONFIG/CHANGE_NEW_USER_VALUE';
export const CHANGE_CURRENT_CANONICAL_CATEGORY = 'APP_CONFIG/CHANGE_CURRENT_CANONICAL_CATEGORY';
export const CHANGE_CURRENT_ROUTER_VIEW = 'APP_CONFIG/CHANGE_CURRENT_ROUTER_VIEW';

export function changeNewUserValue() {
  return {
    type: CHANGE_NEW_USER_VALUE
  };
}

export function changeCurrentCanonicalCategory(currentCanonicalCategory) {
  return {
    type: CHANGE_CURRENT_CANONICAL_CATEGORY,
    payload: {
      currentCanonicalCategory: currentCanonicalCategory
    }
  };
}

export function changeCurrentRouterView(currentRouterView) {
  return {
    type: CHANGE_CURRENT_ROUTER_VIEW,
    payload: {
      currentRouterView: currentRouterView
    }
  };
}