import React from "react"
import { ScrollButton } from "./ScrollButton"
import { ShippingUtils } from "@common/utils";

export const HorizontalScrollButtons = ({ element, isBrand }) => {

    const scroll = (direction, element) => {
        const maxScrollLeft = element.scrollWidth - element.clientWidth;
        const scrollAmount = 20;
        if (direction === "left") {
            element.scrollLeft = Math.max(0, element.scrollLeft - scrollAmount);
        } else if (direction === "right") {
            element.scrollLeft = Math.min(maxScrollLeft, element.scrollLeft + scrollAmount);
        }
    }

    let elemento = document.querySelector(".rt-table");
    const showButton = ShippingUtils.showButtonsForTable(elemento);

    return (
        <div className="bscroll-container" >
                {showButton ?
                <ScrollButton
                    direction="left"
                    handleClick={() => scroll("left", element)}
                    isBrand={isBrand}
                /> : null}
                {showButton ?
                <ScrollButton
                    direction="right"
                    handleClick={() => scroll("right", element)}
                />
                : null}
        </div>
    )
}
