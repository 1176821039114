import { connect } from 'react-redux';
import ShareForPrice from './ShareForPrice';
import { withRouter } from 'react-router';

const mapStateToProps = (state, ownProps) => {
    let ranges = state.dashboardBrandReducer.ranges || [];
    const value = {
        ranges: ranges,
        isFetching: state.dashboardBrandReducer.isFetching
    };
    return value;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
    };
};

export const ShareForPriceConnected = connect(mapStateToProps, mapDispatchToProps)(ShareForPrice);
export default withRouter(ShareForPriceConnected);