import fetchCurrentProductHandlers from "./fetchCurrentProduct";
import saveProductInfoHandlers from "./saveProductInfo";
import fetchSuggestedMatchesHandlers from "./fetchSuggestedMatches";
import transformIntoIdenticalHandlers from "./transformIntoIdentical";
import transformIntoRemovedHandlers from "./transformIntoRemoved";
import removeIdenticalHandlers from "./removeIdentical";
import fetchPotentialMatchesHandlers from "./fetchPotentialMatches";
import createNewMatchHandlers from "./createNewMatch";
import getAllMatchesByProductIdHandlers from "./getAllMatchesByProductId";
import canonicalFindPotentialDuplicatesHandlers from "./canonicalFindPotentialDuplicates";
import canonicalMergeHandlers from "./canonicalMerge";
import canonicalSearchByKeywordsHandlers from "./canonicalSearchByKeywords";
import canonicalTransformIntoCanonicalHandlers from "./canonicalTransformIntoCanonical";
import canonicalTransformIntoRemovedHandlers from "./canonicalTransformIntoRemoved";
import canonicalMarkAsReviewedHandlers from "./canonicalMarkAsReviewed";
import saveRelationshipProductAttributeNewLovHandlers from "./saveRelationshipProductAttributeNewLov";
import fetchHomologationAttributesHandlers from "./fetchHomologationAttributes";

export const handlers = {
    ...fetchCurrentProductHandlers,
    ...saveProductInfoHandlers,
    ...fetchSuggestedMatchesHandlers,
    ...transformIntoIdenticalHandlers,
    ...transformIntoRemovedHandlers,
    ...removeIdenticalHandlers,
    ...fetchPotentialMatchesHandlers,
    ...createNewMatchHandlers,
    ...getAllMatchesByProductIdHandlers,
    ...canonicalFindPotentialDuplicatesHandlers,
    ...canonicalMergeHandlers,
    ...canonicalSearchByKeywordsHandlers,
    ...canonicalTransformIntoCanonicalHandlers,
    ...canonicalTransformIntoRemovedHandlers,
    ...canonicalMarkAsReviewedHandlers,
    ...saveRelationshipProductAttributeNewLovHandlers,
    ...fetchHomologationAttributesHandlers
}

export const reducerHandlersCases = (state, action) => {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action)
    } else {
        return state
    }
}

export default reducerHandlersCases;