import React, { Component } from 'react';
import AttributesFormWrapper from './components/AttributesFormWrapper';
import { RetailCompassStorage, Utils, AttributesUtils } from "@common/utils/";
import PropTypes from 'prop-types';


export class AttributesTool extends Component {

    static propTypes = {
        storedAttributes: PropTypes.array.isRequired,
        attributesData: PropTypes.array.isRequired,
        urlParams: PropTypes.object.isRequired,
        isIFrame: PropTypes.bool
    };

    static defaultProps = {
        isIFrame: true
    };

    constructor(props) {
        super(props);
        this.state = {

        };
        this.onSave = this.onSave.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.setIFrame = this.setIFrame.bind(this)
    }

    bindEvent(element, eventName, eventHandler) {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }

    sendMessage(msg) {
        if (!this.props.isIFrame) {
            return;
        }
        // Make sure you are sending a string, and to stringify JSON
        window.parent.postMessage(msg, '*'); //NOSONAR
    };

    setIFrame() {
        if (!this.props.isIFrame) {
            return;
        }
        this.bindEvent(window, 'message', function (e) {
            console.log("message", e.data);
        });

        setTimeout(() => {
            let container = document.querySelector(".attributes-tool-container");
            if (container) {
                this.sendMessage('HEIGHT' + document.querySelector(".attributes-tool-container").offsetHeight);
            }
        }, 2000);
        document.body.style.backgroundColor = "white";
    }

    componentDidMount() {
        RetailCompassStorage.setAppCountryCode(this.props.countryCode);
        this.props.fetchProductAttributes(this.props.productId);
        this.props.fetchCategoryAttributes(this.props.categoryId);

        this.setIFrame();
    }

    onSave(values) {
        let { productId, contextInfo } = this.props.urlParams;
        let { userId } = Utils.getDecodedContextInfo(contextInfo);
        if (userId && productId && Utils.isObjectWithData(values)) {
            let productAttributes = AttributesUtils.formToProductAttributesFormat(values, this.props.attributesData, productId, userId);
            this.props.saveProductAttribute(productAttributes);
        }
    }

    onCancel() {
        this.sendMessage('cancel_canonical');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isSaved) {
            this.sendMessage('save_canonical');
        }
    }


    render() {

        return (<AttributesFormWrapper
            storedAttributes={this.props.storedAttributes}
            attributesData={this.props.attributesData}
            urlParams={this.props.urlParams}
            isIFrame={this.props.isIFrame}
            errorCreating={this.props.errorCreating}
            onSave={this.onSave}
            onCancel={this.onCancel}
        />)

    }
}

export default AttributesTool;
