import { connect } from 'react-redux';
import { fetchReportInfo } from './dashboardState';
import DashboardBrand from './index';
import { Utils, RetailCompassStorage } from '@common/utils';

export const mapStateToProps = (state, ownProps) => {
    let applicationReportData = {
        brands: state.dashboardBrandReducer.brands || [],
        stores: state.dashboardBrandReducer.stores || [],
    };
    return {
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory || {},
        currentRouterView: state.appConfigReducer.currentRouterView,
        categoryName: RetailCompassStorage.getCurrentCanonicalCategory().name,
        applicationReportData: applicationReportData,
        currentFilter: Utils.extractCurrentProductListFilter(state),
        isFetching: state.dashboardBrandReducer.isFetching
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchReportInfo: (params) => fetchReportInfo(dispatch, params),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBrand);