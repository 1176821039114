import {
    SHOW_LABELS_FORM,
    HIDE_LABELS_FORM
} from './ButtonNewLabelActions';

export const initialState = {
    visibleLabelsForm: false    
};

export const ButtonNewLabelStateReducer = function (state, action) {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case SHOW_LABELS_FORM:
            return Object.assign({}, state, { visibleLabelsForm: true });
        case HIDE_LABELS_FORM:
            return Object.assign({}, state, { visibleLabelsForm: false });
        default:
            return state;
    }
}
