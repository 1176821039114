import React from 'react';
import { DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RetailCompassStorage, RouterUtils, Utils } from "@common/utils/";
import classnames from 'classnames';
import { get } from "lodash";

export const DropdownMenuCategories = ({ categories, onClick, getActiveClassName, currentCanonicalCategory }) => {

    const buildLink = (currentCategory, params = {}) => {
        const isStoreTypeBrand = RetailCompassStorage.isStoreTypeBrand();
        const currentStoreId = RetailCompassStorage.getStoreId();
        const activeClassName = getActiveClassName(currentCategory, currentCanonicalCategory);

        let classNameParams = {
            "topbar__nav-link": true,
            'topbar__nav-menu-underline': get(params, 'shouldIncludeUnderlineClassName', true)
        };

        classNameParams[activeClassName] = Utils.hasValue(activeClassName);
        const className = classnames(classNameParams);

        return (
            <Link
                key={currentCategory.id}
                data-category-id={currentCategory.id}
                className={className}
                to={RouterUtils.buildCategoryPartMenuPath(currentCategory.key, isStoreTypeBrand)}
                onClick={() => onClick(currentStoreId, currentCategory)}>
                {currentCategory.name}
            </Link>
        )
    }

    const notVisibleCategories = categories.filter(item => !item.isVisible)
    if (notVisibleCategories.length == 0) return null;

    const linksNotVisibles = notVisibleCategories.map((item) => buildLink(item, { shouldIncludeUnderlineClassName: false }));
    const activeCategory = notVisibleCategories.find((item) => Utils.hasValue(getActiveClassName(item, currentCanonicalCategory)));

    const classes = classnames({
        "topbar__nav-dropdown rc-more-categories topbar__nav-menu-underline more-categories": true,
        'active': !!activeCategory
    })

    return (
        <UncontrolledDropdown key={Utils.generateUniqueID()} className={classes}>
            <DropdownToggle className="topbar__nav-dropdown-toggle">
                Más categorías <ExpandMoreIcon className="expand-more-icon" />
            </DropdownToggle>

            <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
                {
                    linksNotVisibles.map((linkItem, index) => <DropdownItem key={index}> {linkItem} </DropdownItem>)
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}
