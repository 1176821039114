import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AlertFormBasic from './AlertFormBasic';
import { createAlert, editAlert, receiveCreateAlert } from './AlertFormBasicState';
import { Utils } from "@common/utils/"
import { addNotification } from '../../../../../notification/NotificationState';


export const mapStateToProps = (state, ownProps) => {
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    let lastRequest = state.productListBrandReducer.lastSearchRequest;
    return {
        alert: state.alertFormBasicStateReducer.alert,
        isSaving: state.alertFormBasicStateReducer.isSaving,
        hasError: state.alertFormBasicStateReducer.hasError,
        errorMessage: state.alertFormBasicStateReducer.error,
        currentFilter: currentFilter,
        lastRequest: lastRequest,
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        createAlert: (params) => createAlert(dispatch, params),
        editAlert: (params) => editAlert(dispatch, params),
        addNotification: (payload) => addNotification(dispatch, payload),
        receiveCreateAlert: (payload) => receiveCreateAlert(dispatch, payload),
    };
};

export const AlertFormBasicConnected = connect(mapStateToProps, mapDispatchToProps)(AlertFormBasic);

export default withRouter(AlertFormBasicConnected);
