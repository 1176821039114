import { Constantes } from "@common/utils";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const ShippingCellTooltip = ({ props }) => {
    const { column, value, viewIndex } = props;
    const storeId = column.id === "Tu Tienda" ? column.myStoreName : column.id;
    const storeHasCommune = column.storesWithCommunes.includes(storeId);
    const tooltipId = `${column.id.replace(/\s+/g, "")}-${viewIndex}`;
    const WithoutData = process.env.PUBLIC_URL + "/img/withoutShipping1.svg";
    const greenFlag = process.env.PUBLIC_URL + "/img/greenFlag.svg";

    const getText = (storeHasCommune, column) => {
        const storeType = column.id === "Tu Tienda" ? "tu tienda" : "la tienda";
        return `No logramos conseguir el costo de Shipping de ${storeType}${
            storeHasCommune ? " en esta comuna," : ","
        } pero lo podrás verificar en nuestra siguiente extracción de datos`;
    };

    const renderShippingFreeInfo = () => {
        return (
            <div
                className={"container-free-shipping"}
            >
                <UncontrolledTooltip
                    placement="top"
                    boundariesElement="window"
                    delay={0}
                    target={tooltipId}
                >
                    Todos los productos de este competidor tienen envío gratis
                </UncontrolledTooltip>
                <img src={greenFlag} className="green-flag" />
                <span className="info-free">Full envío gratis</span>
                <span className="count-free">{value.freeShippingCount}</span>
            </div>
        );
    };

    return (
        <div>
            <span id={tooltipId} className="price-report">
                {(!storeHasCommune || value === "-") && (
                    <UncontrolledTooltip
                        placement="top"
                        boundariesElement="window"
                        delay={0}
                        target={tooltipId}
                    >
                        {getText(storeHasCommune, column)}
                    </UncontrolledTooltip>
                )}
                {!storeHasCommune ||
                value.price === "-" ||
                value.price === null ? (
                    <div className="without-info">
                        <span className="info">
                            Sin información de shipping
                        </span>
                        <img src={WithoutData} className="img-info" />
                    </div>
                ) : column.costType ===
                  Constantes.TYPE_SHIPPING_REPORT.COST_FREE ? (
                    <div
                        className={
                            value.freeShippingCount === 0
                                ? "without-free-shipping"
                                : "free-shipping"
                        }
                    >
                        <span>{value.freeShippingCount}</span>
                        <span className="free-text">
                            productos con envío gratis
                        </span>
                    </div>
                ) : column.costType ===
                      Constantes.TYPE_SHIPPING_REPORT.COST_PROM &&
                  value.price === "$0.000" ? (
                    renderShippingFreeInfo()
                ) : (
                    <div className="prom-price-container">
                        {value.price === "$0.000" ? (
                            renderShippingFreeInfo()
                        ) : (
                            <div className="all">
                                <span className="prom-price">
                                    Costo promedio{" "}
                                </span>
                                {value.price}
                                <span className="prom-count">
                                    {value.paidShippingCount} productos
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </span>
        </div>
    );
};

export default ShippingCellTooltip;
