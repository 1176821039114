import { connect } from 'react-redux';
import { StoreState } from "@common/components";
import ValidationModule from './index';
import {
    fetchProduct,
    saveProductInfo,
    getSuggestedMatchesByProductId,
    transformIntoIdentical,
    transformIntoRemoved,
    removeIdentical,
    searchPotentialMatches,
    createNewMatch,
    getAllMatchesByProductId,
    canonicalMarkAsReviewed,
    saveRelationshipProductAttributeNewLov,
    fetchHomologationAttributes
} from "./ValidationState";

export const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.validationStateReducer.isFetching,
        currentProduct: state.validationStateReducer.currentProduct,
        layoutStrategy: ownProps.layoutStrategy,
        loadSavedAttributes: ownProps.loadSavedAttributes,
        attributesSaved: state.attributesStateReducer.isSaved,
        attributesData: state.attributesStateReducer.attributes || [],
        storedAttributes: state.attributesStateReducer.storedAttributes || [],
        suggestedMatches: state.validationStateReducer.suggestedMatches || [],
        isTransformingTheMatch: state.validationStateReducer.isTransformingTheMatch,
        isRemovingTheMatch: state.validationStateReducer.isRemovingTheMatch,
        potentialMatches: state.validationStateReducer.potentialMatches || [],
        competitors: state.storeStateReducer.competitors || [],
        allMatches: state.validationStateReducer.allMatches || [],
        isCreatingNewMatch: state.validationStateReducer.isCreatingNewMatch,
        canonicalIsMarkAsReviewed: state.validationStateReducer.canonicalIsMarkAsReviewed,
        isSavingRelationshipProductAttributeNewLov: state.validationStateReducer.isSavingRelationshipProductAttributeNewLov,
        isFetchingHomologationAttributes: state.validationStateReducer.isFetchingHomologationAttributes,
        countryCode: ownProps.match.params.countryCode
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProduct: (params) => fetchProduct(dispatch, params),
        saveProductInfo: (payload) => saveProductInfo(dispatch, payload),
        getSuggestedMatchesByProductId: (productId) => getSuggestedMatchesByProductId(dispatch, productId),
        transformIntoIdentical: (params) => transformIntoIdentical(dispatch, params),
        transformIntoRemoved: (params) => transformIntoRemoved(dispatch, params),
        removeIdentical: (params) => removeIdentical(dispatch, params),
        searchPotentialMatches: (matchInput) => searchPotentialMatches(dispatch, matchInput),
        getStoreAndCompetitors: (storeId) => StoreState.getStoreAndCompetitors(dispatch, storeId),
        createNewMatch: (payload) => createNewMatch(dispatch, payload),
        getAllMatchesByProductId: (productId) => getAllMatchesByProductId(dispatch, productId),
        canonicalMarkAsReviewed: (productId, jobId, userId) => canonicalMarkAsReviewed(dispatch, productId, jobId, userId),
        saveRelationshipProductAttributeNewLov: (productId, attributeId, valueName, userId) => saveRelationshipProductAttributeNewLov(dispatch, productId, attributeId, valueName, userId),
        fetchHomologationAttributes: (productId, attributeId) => fetchHomologationAttributes(dispatch, productId, attributeId)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationModule);
