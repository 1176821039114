import React, { Component } from 'react';
import { Row, Col, Container, CardBody, Card, Button } from 'reactstrap';
import CandidatePanel from './CandidatePanel';
import CurrentStepPanel from './CurrentStepPanel';
import { PropTypes } from "prop-types";
import { AttributesUtils, Utils } from "@common/utils/";
import { buildNextStepUrl } from '../../common/utils/utils';
import AdvancePanel from './AdvancePanel';
import { remove } from 'lodash';


export class Step3 extends Component {

    static propTypes = {
        taskTitle: PropTypes.string,
        currentProduct: PropTypes.object.isRequired,
        storedAttributes: PropTypes.array,
        attributesData: PropTypes.array,
        urlParams: PropTypes.object.isRequired,
        totalSteps: PropTypes.number.isRequired,

        canonicalPotentialDuplicates: PropTypes.array,
        canonicalFindPotentialDuplicates: PropTypes.func.isRequired,
        suggestedMatches: PropTypes.array,
        canonicalIsFindingPotentialDuplicates: PropTypes.bool.isRequired,
        onFinishedTask: PropTypes.func,
        canonicalMerge: PropTypes.func.isRequired,
        contextInfo: PropTypes.object,

        isRemovingTheMatch: PropTypes.bool,
        removeIdentical: PropTypes.func,
        setDuplicateAsDiscarded: PropTypes.func,
        history: PropTypes.object,

        isFetchingSuggestedMatches: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        taskTitle: "",
        canonicalPotentialDuplicates: [],
        onFinishedTask: () => { },
        setDuplicateAsDiscarded: (product) => { console.log(product) },
        isFetchingSuggestedMatches: false
    };

    constructor(props) {
        super(props);
        this.state = {
            currentDuplicate: { stepIndex: 0 }
        };

        this.continueClick = this.continueClick.bind(this);
        this.setCurrentDuplicate = this.setCurrentDuplicate.bind(this);
        this.joinClick = this.joinClick.bind(this);
        this.canonicalFindPotentialDuplicates = this.canonicalFindPotentialDuplicates.bind(this)
    }

    componentDidMount() {
        if(!Utils.isArrayWithData(this.props.canonicalPotentialDuplicates)){
            this.canonicalFindPotentialDuplicates();
        }else{
            this.setCurrentDuplicate(0);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!Utils.isArrayWithData(prevProps.canonicalPotentialDuplicates) && Utils.isArrayWithData(this.props.canonicalPotentialDuplicates)) {
            let { canonicalPotentialDuplicates } = this.props;
            remove(canonicalPotentialDuplicates, ["discardedAsDupicate", true]);
            this.setCurrentDuplicate(0);
        }
    }

    setCurrentDuplicate(index) {
        while (index < this.props.canonicalPotentialDuplicates.length) {
            let currentDuplicate = this.props.canonicalPotentialDuplicates[index];
            if (currentDuplicate && !currentDuplicate.discardedAsDuplicate) {
                this.setState({ currentDuplicate: { ...currentDuplicate, stepIndex: index } })
                break;
            }
            index++;
        }
    }

    canonicalFindPotentialDuplicates() {
        const { currentProduct, suggestedMatches } = this.props;
        //TODO revisar esto para que se tomen en cuenta los sugeridos
        let competitorsIds = currentProduct.directMatches.map(match => match.productId);

        if (Utils.isArrayWithData(suggestedMatches)) {
            suggestedMatches.forEach(match => {
                competitorsIds.push(match.productId);
            });
        }

        let canonicalProductIdsToExclude = [currentProduct.productId];
        this.props.canonicalFindPotentialDuplicates(competitorsIds, canonicalProductIdsToExclude);
    }

    joinClick() {
        this.props.canonicalMerge(this.state.currentDuplicate);
    }

    continueClick() {
        let inx = this.state.currentDuplicate.stepIndex;
        this.props.setDuplicateAsDiscarded(this.state.currentDuplicate);
        if (inx === this.props.canonicalPotentialDuplicates.length - 1) {
            this.props.history.push(buildNextStepUrl(this.props.urlParams));
        } else {
            inx += 1;
            this.setCurrentDuplicate(inx);
            window.scrollTo(0, 0);
        }
    }

    render() {
        let { currentProduct, attributesData, storedAttributes, canonicalPotentialDuplicates } = this.props;
        currentProduct.enhancedAttributes = AttributesUtils.enhanceProductAttributes(storedAttributes, attributesData);
        let currentDuplicate = this.state.currentDuplicate;

        return (
            <Container className="approval-step approval-step--3">
                <Row>
                    <Col sm={12}>
                        <CurrentStepPanel
                            currentStep={3}
                            urlParams={this.props.urlParams}
                            showBackLink={true}
                            totalSteps={this.props.totalSteps}
                            title={<span>El producto por aprobar ¿corresponde al canónico del lado derecho?</span>}
                        />
                    </Col>
                </Row>
                <Row className="content-row">
                    <Col md={6} className="left-panel">
                        <Card className="product-panel-container">
                            <CardBody>
                                <CandidatePanel
                                    currentProduct={this.props.currentProduct}
                                    urlParams={this.props.urlParams}
                                    removeIdentical={this.props.removeIdentical}
                                    allMatches={this.props.allMatches}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    taskTitle={this.props.taskTitle}
                                />
                            </CardBody>
                        </Card>
                    </Col>

                    {
                        !this.props.canonicalIsFindingPotentialDuplicates ?
                            Utils.isArrayWithData(canonicalPotentialDuplicates) && currentDuplicate.id ?
                                <Col md={6} className="right-panel">
                                    <Card className="product-panel-container">
                                        <CardBody>
                                            <CandidatePanel
                                                currentProduct={currentDuplicate}
                                                urlParams={this.props.urlParams}                                            
                                                allMatches={currentDuplicate.allMatches || currentDuplicate.matches}

                                                attributesTitle="Atributos"
                                                title={<div><span>Canónico</span><span className="slim sub-step">{currentDuplicate.stepIndex + 1} de {canonicalPotentialDuplicates.length}</span></div>}
                                                showMarker={true}
                                                matchesActions={()=>(null)}
                                                showImageComparer={false}
                                                showUserName={false}
                                                showRetailerButton={false}
                                            />                                            
                                            <div className="duplicated__actions match__actions">
                                                <Button size={'sm'} className={"btn-outline-light action button__reject"} onClick={this.continueClick}>
                                                    No pertenece
                                                            </Button>
                                                <Button size={'sm'} className={"btn-outline-light action button__approve"} onClick={this.joinClick}>
                                                    Si, unir a este canónico
                                                            </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col> :
                                <Col md={6} className="right-panel">
                                    <Card>
                                        <CardBody>
                                            <div className="empty-card">
                                                <div className="title">0 sugerencias de canónicos ya existentes</div>
                                                <div>El sistema no encontró ningúna sugerencia automática, pero puedes buscar canónicos manualmente en el siguiente paso</div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <AdvancePanel
                                        urlParams={this.props.urlParams}
                                        linkCaption={"Siguiente paso"}
                                        advanceAction={() => { console.log("Going to Step4") }}
                                    />
                                </Col> : null
                    }

                </Row>
            </Container>
        )
    }
}
export default Step3;