import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AlertModal from './AlertModal';
import { closeAlertModal } from './AlertModalState';
import { addNotification } from '../../../notification/NotificationState';


export const mapStateToProps = (state, ownProps) => {
    return {
        alertModalState: state.alertModalStateReducer || {},
        savedAlert: state.alertFormBasicStateReducer.savedAlert,
        alertType: state.alertFormBasicStateReducer.type,
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        closeAlertModal: () => closeAlertModal(dispatch),
        addNotification: (payload) => addNotification(dispatch, payload),
    };
};

export const AlertFormConnected = connect(mapStateToProps, mapDispatchToProps)(AlertModal);

export default withRouter(AlertFormConnected);
