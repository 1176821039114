import * as Constants from '../../utils/constantes';
import PurchaseSummaryCard from "@common/components/src/ui/PurchaseSummaryCard";
import { remove, minBy, maxBy } from 'lodash';
import * as Utils from '../../utils/utils';
import { getPriceByView } from '@common/components/src/common/components/product_detail/PriceUtils';

export const setPriceCellClasses = (directMatches, averagePrice, sellerType, pricesToShow) => {
    var classes = {
        min: PurchaseSummaryCard.ContainerClassNames.CHEAPEST_OF_ALL_THE_RETAILER,
        minColor: '#50ea7b',
        halfMin: PurchaseSummaryCard.ContainerClassNames.CLOSEST_TO_THE_CHEAPEST_PRICE,
        halfMinColor: '#bbfbcd',
        halfMax: PurchaseSummaryCard.ContainerClassNames.CLOSEST_TO_THE_MOST_EXPENSIVE_PRICE,
        halfMaxColor: '#ffd0d0',
        max: PurchaseSummaryCard.ContainerClassNames.MOST_EXPENSIVE_OF_ALL_THE_RETAILERS,
        maxColor: '#ffadad',
        noStock: PurchaseSummaryCard.ContainerClassNames.WITHOUT_STOCK,
        noStockColor: '#e0e0e0',
        none: PurchaseSummaryCard.ContainerClassNames.NONE,
        noneColor: '#e0e0e0',
    };

    let matches = directMatches.filter(item => item.status === Constants.STATUS_PRODUCT.WITH_STOCK)
        .map((item) => (getPriceByView(item, pricesToShow, item.isPriceWithCard)));

    let haveAllMarket = Utils.haveAllMarketFromSellerType(sellerType);

    if (!haveAllMarket) {
        const isSeller = Utils.isSeller(sellerType);
        remove(matches, function (item) { return item.isSeller === !isSeller });
    }

    const minMatch = minBy(matches, 'price');
    const maxMatch = maxBy(matches, 'price');
    const minPrice = minMatch ? minMatch.price : null;
    const maxPrice = maxMatch ? maxMatch.price : null;

    directMatches
        .forEach(function (item) {
            let newItem = getPriceByView(item, pricesToShow, item.isPriceWithCard);
            item.priceCellClass = null;
            item.priceCellColor = null;
            if (item.status === Constants.STATUS_PRODUCT.WITHOUT_STOCK) {
                item.priceCellClass = classes.noStock;
                item.priceCellColor = classes.noStockColor;
                return;
            }
            if (matches.length === 0 && newItem.price) {
                item.priceCellClass = classes.min;
                item.priceCellColor = classes.minColor;
                return;
            }
            if (newItem.price === minPrice) {
                item.priceCellClass = classes.min;
                item.priceCellColor = classes.minColor;
            } else if (newItem.price && newItem.price === maxPrice) {
                item.priceCellClass = classes.max;
                item.priceCellColor = classes.maxColor;
            } else if (newItem.price <= averagePrice) {
                item.priceCellClass = classes.halfMin;
                item.priceCellColor = classes.halfMinColor;
            } else {
                item.priceCellClass = classes.halfMax;
                item.priceCellColor = classes.halfMaxColor;
            }
        });
}
