import { Utils } from '@common/utils';

const ACTION_BASE_NAME = 'STORES';

export const REQUEST_STORE_AND_COMPETITORS = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_STORE_AND_COMPETITORS');
export const RECEIVE_STORE_AND_COMPETITORS = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STORE_AND_COMPETITORS');
export const RECEIVE_STORE_AND_COMPETITORS_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STORE_AND_COMPETITORS_ERROR');

export const requestStoreAndCompetitorsAction = () => ({
    type: REQUEST_STORE_AND_COMPETITORS
});

export const receiveStoreAndCompetitorsAction = (data) => ({
    type: RECEIVE_STORE_AND_COMPETITORS,
    payload: data
});

export const receiveStoreAndCompetitorsErrorAction = (error) => ({
    type: RECEIVE_STORE_AND_COMPETITORS_ERROR,
    payload: {
        error: error
    }
});
