import React from "react";
import { Utils } from "@common/utils/";
import GoogleSearchButton from '../../GoogleSearchButton'
import EditField from "../../EditField";
import VariantAttributeBox from "../../VariantAttributeBox";


const ProductDescriptionValidations = ({ producto, validationsParams }) => {
    const model = producto.model || Utils.extractModelFromMatches(producto.directMatches);

    const ModelInfo = ({ value, onEdit }) => {
        const label = 'Modelo';
        const labelElem = <span className="info-lbl">{label}:</span>;
        const placeholder = "Escribe el " + label.toLowerCase();

        return <EditField
            label={labelElem}
            placeholder={placeholder}
            editAction={onEdit}
            defaultValue={value}
            fieldName={'model'}
            emptyPlaceholder={'Sin modelo'}
        />
    }

    const BrandInfo = ({ value }) => {
        return value && (
            <div className="lbl-brand">
                <span className="info-lbl">Marca:</span> <span>{value}</span>
            </div>
        )
    }

    return <div className="product-detail__card clearfix product-detail__card--validations">
        <div className="product-card__title">Canónico actual</div>
        <div className="product-container">
            <div className="product-card__description">
                <div className="lbl-name">
                    {producto.name}
                </div>
                <AttributeColorVariants product={producto} />
                <GoogleSearchButton product={producto} />
            </div>
            <div className="product-card__image">
                <img src={producto.image} onError={(img) => img.remove()} />
            </div>
        </div>
        <div className="product-info">
            <BrandInfo value={producto.brand} />
            <ModelInfo value={model} onEdit={validationsParams ? validationsParams.editAction : () => { }} />
        </div>
    </div>
}

export default ProductDescriptionValidations

export const AttributeColorVariants = ({ product }) => {
    const validatedColors = product.validatedColors || [];
    const labelColors = validatedColors.map(({ label }) => label);

    return product.isPostPago ? (
        <VariantAttributeBox canonicalVariantAttributes={labelColors} />
    ) : null
}
