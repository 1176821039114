import React, { memo } from 'react';
import { translate } from 'react-i18next';
import ButtonClose from './components/ButtonClose';

const FilterFieldLayout = ({ fieldClass = "", fieldLabel, showRemoveFilters, onClear, isMandatory = false, children }) => {
    return (
        <div className={`form__form-group filter-field ${fieldClass}`}>

            {showRemoveFilters &&
                <ButtonClose eventOnClick={onClear} />
            }

            {fieldLabel &&
                (<label className="form__form-group-label">
                    {fieldLabel}
                    {!!(isMandatory) &&
                        <span className="red-text">&nbsp;*</span>
                    }
                </label>)
            }
            <div className='form__form-group-field'>
                {children}
            </div>
        </div>
    )
}

export default memo((translate('common')(FilterFieldLayout)));