import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ChurnZero } from '@common/utils/';

export class SearchBox extends PureComponent {

    static propTypes = {
        formName: PropTypes.string.isRequired,
        onSubmit: PropTypes.func.isRequired,
        buttonText: PropTypes.string,
        hasActiveSearch: PropTypes.bool,
        onKeyPress: PropTypes.func.isRequired,
        onButtonClick: PropTypes.func.isRequired,
        onSearchReset: PropTypes.func.isRequired,
        showCategories: PropTypes.bool
    }

    static defaultProps = {
        showCategories: false,
        hasActiveSearch: false,
        buttonText: "GO"
    }

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.trackSearchEvent = this.trackSearchEvent.bind(this);
    }

    handleClick(event) {
        this.trackSearchEvent();
        this.props.onButtonClick(event);
    }

    handleSubmit(event) {
        this.trackSearchEvent();
        this.props.onSubmit(event);
    }

    handleKeyPress(event) {
        if (event.charCode === 13) this.trackSearchEvent();
        this.props.onKeyPress(event);
    }

    trackSearchEvent() {
        ChurnZero.trackEvent({ eventName: "scalibur_buscador" });
    }

    render() {
        return (
            <form className='form product-list-main-filters-form'
                onSubmit={this.handleSubmit}
                name={this.props.formName}>
                <div className='form__form-group'>
                    <div className='form__form-group-field'>
                        <Field
                            name='search'
                            className={'product-list-main-filters-search' + (this.props.hasActiveSearch ? ' product-list-main-filters-search--current' : '')}
                            component='input'
                            type='text'
                            placeholder='Escribe SKU, palabra clave o modelo'
                            onKeyPress={this.handleKeyPress}
                            onFocus={(e) => {
                                document.getElementsByClassName("start-search")[0].classList.add("reset-search--input-focused");
                            }}
                            onBlur={(e) => {
                                if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget) && e.relatedTarget.id === "compare-trigger") {
                                    e.relatedTarget.click();
                                }
                                document.getElementsByClassName("start-search")[0].classList.remove("reset-search--input-focused");
                            }}
                        />
                        <div className="icon-search">
                            <svg className="mdi-icon " width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M9.5,3C13.09,3 16,5.91 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16C5.91,16 3,13.09 3,9.5C3,5.91 5.91,3 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
                            </svg>
                        </div>
                        {this.props.hasActiveSearch ?
                            <button className='reset-search' onClick={this.props.onSearchReset} type="button">
                                <svg className="mdi-icon " width="18" height="18" viewBox="0 0 24 24">
                                    <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
                            </button>
                            : null}
                        <button className='start-search' onClick={this.handleClick} type="button">
                            {this.props.buttonText}
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}
