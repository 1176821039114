import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import { PathCategory } from "./PathCategory";
import CircularProgress from "@mui/material/CircularProgress";

const StarCategoryTooltip = ({
    isOpen,
    toggle,
    target,
    rankings = [],
    categories = new Map(),
    container,
    isLoadingStarCategories,
}) => {
    const sortedRankings = orderBy(rankings, ["ranking"], ["asc"]);

    const uniqueRanking = sortedRankings.length === 1;
    const plural = uniqueRanking ? "" : "s";

    const someWithoutUrl = sortedRankings.some((ranking) => {
        const category = categories.get(ranking.categoryId);
        return category && !category.url;
    });
    const withoutUrlStyle = someWithoutUrl ? "withoutUrl" : "";

    const Loading = () => (
        <div
            data-testid="category-tooltip-message-loading"
            className="category-tooltip__message"
        >
            <span>Cargando posicionamiento</span>
            <CircularProgress
                className="category-tooltip__message__loading"
                size={12}
            />
        </div>
    );

    const Categories = () => {
        return (
            <>
                {sortedRankings.map((ranking, index) => {
                    const category = categories.get(ranking.categoryId);
                    const key = category ? category.fullPath + index : index + ranking.ranking;
                    return (
                        <PathCategory
                            key={key}
                            category={{
                                ranking: ranking.ranking,
                                fullPath: category ? category.fullPath : "No existe",
                                url: category? category.url : "No existe",
                            }}
                            uniqueRanking={uniqueRanking}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <Popover
            data-testid="category-tooltip"
            className={`category-tooltip ${withoutUrlStyle}`}
            placement="top"
            target={target}
            isOpen={isOpen}
            toggle={toggle}
            container={container}
        >
            <PopoverBody>
                <span className="category-tooltip__desc">
                    {`¿De qué categoría${plural} es este posicionamiento?`}
                </span>
                {isLoadingStarCategories ? <Loading /> : <Categories />}
            </PopoverBody>
        </Popover>
    );
};

StarCategoryTooltip.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    target: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    container: PropTypes.string
};

export { StarCategoryTooltip };
