import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export const buildRetailHeader = (headersProps, retailProps) => {
    let { shortStoreName, storeName, storeId } = retailProps;
    return (
        <span id={"retail-" + storeId}>
            {shortStoreName ?
                <UncontrolledTooltip placement='top' boundariesElement='window' className="retailer-header-tooltip" delay={0} target={"retail-" + storeId} >
                    {storeName}
                </UncontrolledTooltip> : null}
            {shortStoreName}
        </span>
    );
}
