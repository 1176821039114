import React, { Component } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { Utils } from "@common/utils";
import { UncontrolledTooltip } from "reactstrap";

export default class CloseButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        darkBackground: PropTypes.bool,
        style: PropTypes.object,
        hoverable: PropTypes.bool,
        className: PropTypes.string,
        id: PropTypes.string,
        tooltipPlacement: PropTypes.string,
        tooltipText: PropTypes.string
    };

    static defaultProps = {
        className: "",
        darkBackground: false,
        hoverable: false,
        style: {},
        onClick: () => {
            console.log("CLOSE");
        },
        id: Utils.generateRandomId(),
        tooltipPlacement: "bottom",
        tooltipText: null
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            className,
            darkBackground,
            hoverable,
            style,
            onClick,
            id,
            tooltipPlacement,
            tooltipText
        } = this.props;
        return (
            <div
                id={id}
                style={style}
                className={
                    "close-btn " +
                    className +
                    (darkBackground ? " close-btn--dark" : "") +
                    (hoverable ? " close-btn--hoverable" : "")
                }
                onClick={onClick}
            >
                <CloseIcon />
                {tooltipText ? (
                    <UncontrolledTooltip
                        boundariesElement={"window"}
                        placement={tooltipPlacement}
                        target={id}
                        className="close-btn__tooltip"
                    >
                        {tooltipText}
                    </UncontrolledTooltip>
                ) : null}
            </div>
        );
    }
}
