import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { withRouter } from "react-router";
import { sumBy } from 'lodash';
import InteractiveCounter from "@common/components/src/ui/InteractiveCounter";
import { Utils, RouterUtils, Constantes } from '@common/utils';
import RangeText from "../common/RangeText";

export class SummaryView extends Component {

    static propTypes = {
        counterItems: PropTypes.array
    };

    static defaultProps = {
        counterItems: []
    }

    constructor(props) {
        super(props);
        this.state = {};

        this.onChangeCounterSelection = this.onChangeCounterSelection.bind(this);
    }

    onChangeCounterSelection(counter, isSelected) {
        let params = { outOfStockRanges: counter.counterKey };
        params[Constantes.URL_PARAMS.REPORT] = undefined;
        params[Constantes.URL_PARAMS.SUBREPORT] = undefined;
        RouterUtils.goToProductListTabView({ location: this.props.location, history: this.props.history, onSelectedView: this.props.onSelectedView, params })
    }

    render() {
        const { counterItems, lastFilter } = this.props;

        let counterItemsSelected = lastFilter.outOfStockRanges;
        let _counterItems = counterItems.map(item => {
            let newItem = { ...item };
            if (Utils.isArrayWithData(counterItemsSelected) && !counterItemsSelected.includes(item.counterKey)) {
                newItem.total = 0;
            }
            return newItem;
        });
        let formatPercentage = _counterItems.map(item => item.total).filter(itemTotal => itemTotal !== 0).join(' / ');
        let total = sumBy(_counterItems, item => item.total);

        return <div key={1} className='out-of-stock-report-detailed-thin' >
            
                    <Row className="title-container">
                        <Col md={12} sm={12} >
                            Todos los quiebres de stock
                        </Col>
                    </Row>
                    <Row className="subtitle-container">
                        <Col md={12} sm={12}>
                            {counterItems.map((item, index) => (
                                <RangeText key={index} iconClass={item.counterKey} text={item.label} />
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <InteractiveCounter
                                counterItems={_counterItems}
                                label={''}
                                total={total}
                                fixedLabel={total > 0 ? `${total} ${total === 1 ? 'match' : 'matches'} en total` : null}
                                onSelectItem={(item) => {
                                    this.onChangeCounterSelection(item, true);
                                }}
                                onDeselectItem={(item) => {
                                    this.onChangeCounterSelection(item, false);
                                }}
                                formatPercentage={() => {
                                    return formatPercentage;
                                }}
                                formatCounterLabel={(total, label) => {
                                    return '';
                                }}
                                progressBarTooltipConfig={{
                                    show: true,
                                    renderContent: (item) => {
                                        let totalText = item.total + ' match' + (item.total > 1 ? 'es' : '');
                                        return <div className="progress-bar-tooltip">
                                            <RangeText containerClassName='tooltip-range' iconClass={item.counterKey} text={item.label} />
                                            <RangeText containerClassName='tooltip-total' iconClass="total-of-products" text={totalText} />
                                        </div>
                                    }
                                }}
                                isReportOutOfStock={true}
                                showFilterZone={false}
                            />
                        </Col>
                    </Row>
        </div>;
    }
}

export default withRouter(SummaryView);
