import React from 'react';
import { Constantes } from '@common/utils';
import { CustomHeader } from '@common/components/';
import { configureSortComponent } from "./SortUtils";

export const buildProductHeader = (headerProps, currentSort, paginationCount) => {
    let normalizedKey = currentSort.sortKey;
    return <CustomHeader
        containerTag='div'
        className={'header-producto header-ranking'}
        headerKey={Constantes.SortKeys.PRODUCT_RELEVANCE}
        header={{ name: '', sortable: false }}
        sortDirection={currentSort.sortDirection}
        sortKey={normalizedKey}
        sortDescendingFirst={false}
        invertSortResults={false}
        onSort={() => { }}
        sortComponent={configureSortComponent(headerProps, currentSort)}
    />
}

export const buildLowerPriceHeader = (headerProps, currentSort) => {
    return <CustomHeader
        containerTag='div'
        className={''}
        headerKey={Constantes.SortKeys.MINIMUM_PRICE}
        header={{ name: 'Menor precio', sortable: true }}
        sortDirection={currentSort.sortDirection}
        sortKey={currentSort.sortKey}
        sortDescendingFirst={true}
        invertSortResults={false}
        onSort={() => { }} />
}

export const buildDaysInTheMarketHeader = (headerProps) => {
    return <CustomHeader
        containerTag='div'
        className={''}
        headerKey='DAYS_IN_THE_MARKET'
        header={{ name: 'Días en el mercado', sortable: false }}
        sortDescendingFirst={true}
        invertSortResults={false}
        onSort={() => { }} />
}