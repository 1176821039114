import PropTypes from 'prop-types';

export const AlertType = PropTypes.shape({
    id: PropTypes.number,
    "name": PropTypes.string,
    "type": PropTypes.string,
    "subType": PropTypes.string,
    "query": PropTypes.string,
    "webFilter": PropTypes.string,
    "userId": PropTypes.string,
    "alert": PropTypes.bool,
    "productAccessId": PropTypes.number
});
