/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class userUtils.js
 * @date 26 oct. 2019
 */
import { generateUniqueID, isArrayWithData } from './utils';
import { orderBy, map, isEqual, find, cloneDeep } from 'lodash';
import { getUser, getLastSelectedStoreAndCategory } from '../app/Storage';
import * as OperatorUtils from './OperatorUtils';
import packageJson from "../../../../package.json";

export const updateUserObj = (id) => {
    return processUserObj(getUser());
}


const findStore = (stores, criteria) => {
    let store = find(stores, criteria);
    if (store && store.id) {
        return cloneDeep(store);
    }
    return null;
}

/**
 * usamos cloneDeep para evitar que se modifiquen las referencias de user.stores
 * esto evita que cada vez que el usuario cambia de página, sea detectado un cambio en las propiedades 
 * internas de las tiendas y se envíe a login
 * 
 * @param {*} _user 
 */
export const processUserObj = (_user) => {
    const token = generateUniqueID();
    const user = cloneDeep(_user);
    user.token = token;
    if (!isArrayWithData(user.stores)) {
        throw new Error("invalid user. stores not found");
    }
    delete user.password;

    let currentStore = null;
    const { lastSelectedStoreId, lastSelectedCategoryId } = getLastSelectedStoreAndCategory(user.id);

    if (lastSelectedStoreId) {
        currentStore = findStore(user.stores, { id: lastSelectedStoreId });
    }
    if (!currentStore) {
        currentStore = findStore(user.stores, { main: true });
    }

    currentStore = currentStore || cloneDeep(user.stores[0]);

    user.appVersion = packageJson.version;
    if (!isArrayWithData(currentStore.categories)) {
        throw new Error("invalid user. categories not found");
    }
    const canonicalCategories = orderBy(currentStore.categories, ["order"], ["asc"]);

    let selectedCategory = cloneDeep(canonicalCategories[0]);

    if (lastSelectedCategoryId) {
        let _category = find(canonicalCategories, { id: lastSelectedCategoryId });
        if (_category && _category.id) {
            selectedCategory = cloneDeep(_category);
        }
    }

    currentStore.currentCanonicalCategory = currentStore.defaultCanonicalCategory = selectedCategory;
    currentStore.categories = canonicalCategories;
    currentStore.canonicalCategories = canonicalCategories;
    user.currentStore = currentStore;

    user.includeStores = map(currentStore.channels, (channel) => {
        return channel.id;
    });
    user.includeOperators = map(currentStore.operators, (operator) => {
        return OperatorUtils.buildOperatorKey({
            storeId: operator.id,
            operatorName: operator.operator
        });
    });

    user.currentStore.categories = map(user.currentStore.categories, (item) => {
        item.attributes = item.attributes || [];
        item.attributes = map(item.attributes, (attr) => {
            if (typeof attr.visualization === "string") {
                attr.visualization = JSON.parse(attr.visualization);
            }
            return attr;
        });
        return item;

    });
    return user;
}

export const isUserEqual = (newUser, currentUser) => {
    if (!currentUser || !newUser) return false;
    return isEqual(currentUser.stores, newUser.stores)
        && isEqual(currentUser.currentStore.configuration, newUser.currentStore.configuration);
}

export const isUserAllowed = (user) => {
    return user && user.currentStore && user.active && user.currentStore.active;
}
