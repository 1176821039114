import React, { Component } from 'react'
import AddCircle from '@material-ui/icons/AddCircle';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Constantes } from '@common/utils';

export default class ButtonNewLabel extends Component {

    static propTypes = {
        text: PropTypes.string,
        icon: PropTypes.node
    };

    static defaultProps = {
        text: "Crear nuevo label",
        icon: <AddCircle />
    }

    constructor(props) {
        super(props);

        this.state = {
            visible: true,
        };

        this.onClick = this.onClick.bind(this);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.visibleLabelsForm, nextProps.visibleLabelsForm)) {
            this.setState({ visible: !nextProps.visibleLabelsForm });
        }
    }

    onClick() {
        if (!this.props.visibleLabelsForm) {
            this.selectedView();
            this.props.showLabelsForm();
        }
    }

    selectedView() {
        if (this.props.selectedView && this.props.selectedView !== Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST) {
            this.props.onSelectedView(Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST);
        }
    }

    componentWillUnmount() {
        this.props.hideLabelsForm();
    }

    render() {
        if (this.state.visible) {
            return (<div onClick={this.onClick} className="button-container" > <span className="btn-add">
                {this.props.icon}<span>{this.props.text}</span></span></div >)
        } else {
            return null;
        }
    }
}
