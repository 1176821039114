import { connect } from 'react-redux';
import FiltersView from './FiltersView';
import { FiltersContainer } from '@common/components/';

export const mapStateToProps = (state, ownProps) => {
    let commonProps = FiltersContainer.mapStateToProps(state, ownProps);
    return {
        ...commonProps
    }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    let commonFuncts = FiltersContainer.mapDispatchToProps(dispatch, ownProps);
    return {
        ...commonFuncts
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersView);
