import React from "react";
import { Route } from "react-router-dom";
import { RetailCompassStorage } from "@common/utils/";
import { useLogout } from "@common/components";
import { Auth0Config } from "@common/auth/";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useState } from "react";
import { StoreService } from '@common/data-access';

const PrivateRoute = ({ component, ...rest }) => {
    const { isAuthenticated, user } = useAuth0();
    const { logout } = useLogout();
    const [newUser, setNewUser] = useState(null);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        async function fetchUserData() {
            try {
                if (isAuthenticated) {
                    const parseUser = await Auth0Config.parseUser(user);
                    RetailCompassStorage.setUser(parseUser);
                    setNewUser(parseUser);
                    const { currentStore } = parseUser;
                    const productAccess = await StoreService.getConfigScalibur(currentStore.id);
                    RetailCompassStorage.setProductAccessConfig(productAccess)
                }
            } catch (error) {
                console.error(error);
                setHasError(true);
            }
        }
        fetchUserData();
    }, [isAuthenticated, user]);

    if (hasError) {
        logout();
    }
    if (isAuthenticated && !newUser) {        
        return null;
    }    
    return (
        <Route
            exact
            {...rest}
            component={
                isAuthenticated
                    ? component
                    : withAuthenticationRequired(component)
            }
        />
    );
};

export default PrivateRoute;
