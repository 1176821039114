import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NewBadge extends Component {

  static propTypes = {
    style: PropTypes.object,
    text: PropTypes.string,
    type: PropTypes.string
  };

  static defaultProps = {
    style: {},
    text: "NUEVO",
    type: "inline"
  };

  constructor(props) {
    super(props);   
  }

  render() {
    return (
        <span className={"new-option-badge new-option-badge--"+this.props.type} style={this.props.style}>
            {this.props.text}
            {this.props.children}
        </span>
    );
  }
}
export default NewBadge;