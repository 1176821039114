import React from "react"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

export const ScrollButton = ({ direction, handleClick, isBrand }) => {
    const storeType = isBrand ? "brand" : "";
    return (
        <button
            onClick={handleClick}
            className={`circular-button ${direction} ${storeType}`}
            type={"outline"}>
            {direction == "left" ? (
                <KeyboardArrowLeftIcon />
            ) : (
                <KeyboardArrowRightIcon />
            )}
        </button>
    )
}