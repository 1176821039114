
/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class constantesApi.js
 * @date 13 mar. 2018
 */

const composeUrl = (base, uri) => (base + '/' + uri);

if (!process.env.NODE_ENV === 'test') {
    require('dotenv').config({ path: '.env.test' });
}

export const buildEndpoint = (serviceUri) => {
    let url = process.env.API_DOMAIN_TEST;
    let apiKey = process.env.API_KEY_TEST;

    if (process.env.REACT_APP_RC_ENV === 'production') {
        url = process.env.API_DOMAIN_PROD;
        apiKey = process.env.API_KEY_PROD;
    }

    return url + serviceUri + '?apikey=' + apiKey;
}

const API = process.env.REACT_APP_API;
const PRODUCT_SEARCH = process.env.PRODUCT_SEARCH_ENVIRONMENT;
const EXPORT_SERVICE = process.env.EXPORT_SERVICE_ENVIRONMENT;

//NAMESPACE USER-CATALOG IN JWT TOKEN
export const JWT_USER_CATALOG_NS = 'http://api.retailcompass.com/user-catalog';

//DOMAIN
export const AUTH0_TENANT_DEV_DOMAIN = 'login-dev.retailcompass.com';
export const AUTH0_TENANT_PROD_DOMAIN = 'login.retailcompass.com';

//CLIENT ID
export const AUTH0_TENANT_DEV_CLIENT_ID = 'nn7we1NWSMJML2vBJqXylXc9bnK6dDrg';
export const AUTH0_TENANT_PROD_CLIENT_ID = 'XDZ6LcuOy3yt6eVJ024f0AQYXx5Rwx2n';

export const API_ES_PRODUCT_SEARCH = buildEndpoint(`${PRODUCT_SEARCH}/v1/search/product`);
export const API_ES_FEED_PRODUCTS = buildEndpoint(`${PRODUCT_SEARCH}/v1/feeder/feed/products/`);
export const API_ES_PRODUCT_SEARCH_POTENTIAL_MATCHES = buildEndpoint(`${PRODUCT_SEARCH}/v1/search/product/match`);

export const API_PRODUCT_HISTORY_PREVIOUS = buildEndpoint('product-history/v1/price/history');
export const API_PRODUCT_HISTORY = buildEndpoint('product-history/v2/price/history');
export const API_ES_PRODUCT_SEARCH_EXPORT = buildEndpoint(`${EXPORT_SERVICE}/v2/export`);
export const API_FAVORITES_PATH = 'label-catalog/v1/favorites';
export const API_LABELS_PATH = 'label-catalog/v1/labels';

export const API_PRODUCTS = composeUrl(API, 'products');
export const API_PRODUCTS_SEARCH = composeUrl(API_PRODUCTS, 'search');
export const API_PRODUCT_ATTRIBUTE = buildEndpoint('product-attribute/v1/product_attribute/create_relationship');
export const API_PRODUCT_ATTRIBUTE_NEW_LOVS = buildEndpoint('product-attribute/v1/product_attribute/create_relationship_new_lov');
export const API_PRODUCT_ATTRIBUTES_PATH = 'product-attribute/v1/product_attribute';

export const API_PRODUCT = buildEndpoint('product-catalog/v1/product');
export const API_PRODUCT_UPDATE = buildEndpoint('product-catalog/v1/product/{productId}/update');
export const API_PRODUCT_FIND_MATCHED_FULL_PRODUCT = buildEndpoint('product-catalog/v1/product/{productId}/full');
export const API_PRODUCT_CANONICAL_POTENTIAL_DUPLICATES = buildEndpoint('product-catalog/v1/product/potential_duplicates');
export const API_PRODUCT_CANONICAL_SEARCH_BY_KEYWORDS = buildEndpoint('product-catalog/v1/product/search_canonicals');
export const API_PRODUCT_CANONICAL_TRANSFORM_INTO_REMOVED = buildEndpoint('product-catalog/v1/validation/transform_into_removed');
export const API_PRODUCT_CANONICAL_MERGE = buildEndpoint('product-catalog/v1/validation/merge');
export const API_PRODUCT_CANONICAL_TRANSFORM_INTO_CANONICAL = buildEndpoint('product-catalog/v1/validation/transform_into_canonical');
export const API_PRODUCT_CANONICAL_MARK_AS_REVIEWED = buildEndpoint('product-catalog/v1/validation/mark_as_reviewed');
export const API_PRODUCT_CANONICAL_STAR_CATEGORIES = buildEndpoint("product-catalog/v1/ranking/star_categories");
export const API_PRODUCT_CANONICAL_STAR_CATEGORIES_FULL_PATH = buildEndpoint("product-catalog/v1/ranking/star_categories_full_path");

export const API_MATCH = buildEndpoint('match-service/v1/matches');
export const API_MATCH_GET_SUGGESTED_BY_PRODUCT_ID = buildEndpoint('match-service/v1/matches/{productId}/suggested');
export const API_MATCH_TRANSFORM_INTO_IDENTICAL = buildEndpoint('match-service/v1/matches/transform_into_identical');
export const API_MATCH_TRANSFORM_INTO_REMOVED = buildEndpoint('match-service/v1/matches/transform_into_removed');
export const API_MATCH_GET_ALL_MATCHES_BY_PRODUCT_ID = buildEndpoint('match-service/v1/matches/{productId}');

export const API_STORE = buildEndpoint('store-catalog/v1/stores');
export const API_STORE_AND_COMPETITORS = API_STORE + '&fields=COMPETITORS&ids={storeId}';
export const API_PRODUCT_ACCESS = buildEndpoint('store-catalog/v1/product_access');

export const API_ALERTS_PATH = 'notification-service/v1/searches';

export const API_LABELS = composeUrl(API, 'label');
export const API_LABELS_USER = composeUrl(API_LABELS, 'get_all');

export const API_CATEGORY = composeUrl(API, 'category');
export const API_CATEGORY_STORE = composeUrl(API_CATEGORY, 'get_all');

export const CATEGORY_ACCESS = "PRICING_ONLY";

export const API_CATEGORY_TYPES = composeUrl(API, 'category_type');
export const API_CATEGORY_TYPES_ACTIVES = composeUrl(API_CATEGORY_TYPES, 'get_actives');
export const API_CATEGORY_CATALOG_ATTRIBUTES_PATH = 'category-catalog/v1/categories/attributes_with_values';
export const API_CATEGORY_CATALOG_CATEGORY_INFO_PATH = 'category-catalog/v1/categories/attributes';
export const API_CATEGORY_CATALOG_STAR_RANKING_CATEGORY = "category-catalog/v1/categories/star_ranking_category";

export const API_USERS = buildEndpoint('user-catalog/v1/users/session_information');

export const API_FETCH_HOMOLOGATION_ATTRIBUTES = buildEndpoint('homologation-attributes/v1/homologate_attribute/{productId}/{attributeId}'); // http://localhost:8080/v1/homologate_attribute/209799193/121

export const API_SHIPPING_CATALOG_PATH = buildEndpoint('shipping-catalog/v1/shipping/communes');

export const CHURN_ZERO_TOKEN_ENDPOINT = buildEndpoint('third-party-api/churnzero/token');
export const INTERCOM_TOKEN_ENDPOINT = buildEndpoint('third-party-api/intercom/token');
