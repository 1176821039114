import TagManager from 'react-gtm-module'
import { ENV } from './utils'

export const GTM_ID = process.env.REACT_APP_RC_GTM_ID
let loaded = false

export const isLoaded = () => {
    return !!window.dataLayer || (ENV.isJest() && loaded)
}

export const init = (args) => {
    if (isLoaded()) { return }

    const { gtmId, dataLayer } = args || {}
    let tagManagerArgs = { gtmId: gtmId || GTM_ID }
    if (dataLayer) {
        tagManagerArgs.dataLayer = dataLayer;
    }
    TagManager.initialize(tagManagerArgs)
    loaded = true
}
