import React from 'react';
import CircleIcon from 'mdi-react/CircleIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import { orderBy, findIndex, find, uniqBy } from 'lodash';

const DpmWithStockIcon = process.env.PUBLIC_URL + '/img/dpmWithStock.svg';
const DpmWithoutStockIcon = process.env.PUBLIC_URL + '/img/dpmWithoutStock.svg';
const DpmStockIcon = (props) => (<img className="dpm-item-my-icon" height={props.size} width={props.size} src={props.src} alt='' />)

export const STATUS_PRODUCT = {
    WITH_STOCK: 1,
    WITHOUT_STOCK: -1
};

export const COLORS = {
    RED: "#fa697d",
    BLACK: "#555555",
    GRAY: "#e0e0e0",
    GREEN: "#4ce1b6",
    GRAY_MINUS: "#999999"
};

export const isValidMyProduct = (product) => {
    if (!product || !product.price) {
        return false;
    }
    return true;
}

export const processItems = (competitorsProducts, product) => {

    // todos los competidores serán gris si `product` es null/undefined
    if (!isValidMyProduct(product)) {
        return orderBy(competitorsProducts, ['price'], ['asc']).map((item) => {
            item.color = COLORS.GRAY;
            item.withStock = true;
            item.dpmPercentage = null;
            item.priceClassName = '';
            item.storeNameClassName = '';
            if (item.status === STATUS_PRODUCT.WITHOUT_STOCK) {
                item.withStock = false;
                item.color = COLORS.GRAY_MINUS;
            }
            return item;
        });
    }

    let myProduct = { ...product, isMyPrice: true };
    let newItems = orderBy([myProduct].concat(competitorsProducts), ['price'], ['asc']);
    newItems = uniqBy(newItems, 'productId');
    let indexOfProduct = findIndex(newItems, { id: product.id });

    newItems = newItems.map((item, index) => {
        item.withStock = true;
        item.color = COLORS.BLACK;
        item.priceClassName = '';
        item.storeNameClassName = '';
        item.dpmPercentage = null;
        if (item.status === STATUS_PRODUCT.WITHOUT_STOCK) {
            item.withStock = false;
            item.color = COLORS.GRAY_MINUS;

            if (item.isMyPrice) {
                item.priceClassName = 'my-price-without-stock';
                item.storeNameClassName = 'my-store-name';
            } else {
                item.priceClassName = 'without-stock';
            }
        }
        if (item.status === STATUS_PRODUCT.WITH_STOCK) {
            if (item.isMyPrice) {
                item.priceClassName = 'my-price-with-stock';
                item.storeNameClassName = 'my-store-name';
            }

            if (!item.isMyPrice && myProduct.price === item.price) {
                item.color = COLORS.BLACK;
                item.priceClassName = 'same-price';
            } else if (index < indexOfProduct) {
                item.color = COLORS.GREEN;
                item.priceClassName = 'cheaper';
            } else if (index > indexOfProduct) {
                item.color = COLORS.RED;
                item.priceClassName = 'more-expensive';
            }
        }
        item.dpmPercentage = null;
        return item;
    });

    const lowestPriceCompetitor = find(newItems, { color: COLORS.GREEN });
    return newItems.map((item) => {
        let dpmPercentage = lowestPriceCompetitor ? (((item.price - lowestPriceCompetitor.price) / lowestPriceCompetitor.price) * 100) : null;
        if (item.status === STATUS_PRODUCT.WITHOUT_STOCK) { 
            dpmPercentage = null;
        }
        return { ...item, dpmPercentage: dpmPercentage };
    });
}

export const getIcon = (item, mySize, compSize) => {
    if (item.isMyPrice) {
        if (item.withStock) {
            return <DpmStockIcon src={DpmWithStockIcon} className="dpm-item-my-icon" size={mySize} color={item.color} />;
        }
        return <DpmStockIcon src={DpmWithoutStockIcon} size={mySize} />;
    }

    let commonProps = {
        className: ['dpm-item-competitor-icon ', item.classes].join(" "),
        size: compSize,
        color: item.color
    };

    if (item.withStock) {
        return <CircleIcon {...commonProps} />;
    }

    return <MinusIcon {...commonProps} />;
}
