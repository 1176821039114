import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Progress
} from 'reactstrap';
import { isInteger } from 'lodash';

const CounterPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    isMain: PropTypes.bool.isRequired,
    onlyForProgressBar: PropTypes.bool.isRequired,
    barClassName: PropTypes.string.isRequired,
});

const PercentageType = {
    FIXED: 'FIXED',
    TRUNCATED: 'TRUNCATED',
    NORMAL: 'NORMAL',
};


export default class ProgressCounter extends PureComponent {
    static propTypes = {
        counterItems: PropTypes.arrayOf(CounterPropType).isRequired,
        label: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        totalForCalculate: PropTypes.number.isRequired,
        onClickItem: PropTypes.func,
    };

    static defaultProps = {}

    constructor(props) {
        super(props);

        this.handleClickItem = this.handleClickItem.bind(this);
        this.formatPercentage = this.formatPercentage.bind(this);
        this.toFixed = this.toFixed.bind(this);
        this.fixPercentage = this.fixPercentage.bind(this);
        this.calculatePercentage = this.calculatePercentage.bind(this);
    }

    handleClickItem(counterItem) {
        if (this.props.onClickItem) {
            this.props.onClickItem(counterItem);
        }
    }

    toFixed(num, fixed) {
        // eslint-disable-next-line
        let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    formatPercentage(counterItem) {
        let value = 0
        if (counterItem.percentage) {
            value = counterItem.percentage;
        }

        const format = (value) => (value.toString().replace(/\./g, ',') + '%');

        if (counterItem.percentageType === PercentageType.NORMAL) {
            if (isInteger(value)) {
                return format(value);
            }
            return format(value.toFixed(1))
        }
        return format(this.toFixed(value, 1));
    }

    fixPercentage(percentage) {
        // se transforma un número de la forma 0.05495821 en 0.5
        let found = Array.from(percentage.toString(), Number).find((value) => value > 0);
        if (found) {
            return Number('0.' + found);
        }
        return percentage;
    }

    calculatePercentage(counterItems, total) {
        if (!counterItems || !counterItems.length) {
            return null;
        }
        let calculation = (part, total) => {
            return total > 0 ? ((part / total) * 100) : 0;
        }
        let hasUnfixed = counterItems.filter((counterItem) => {
            let percentage = calculation(counterItem.total, total);
            return (percentage < 0.1);
        }).length;
        if (!hasUnfixed) {
            return counterItems.map((counterItem) => {
                let percentage = calculation(counterItem.total, total);
                return {
                    ...counterItem,
                    percentage: percentage,
                    percentageType: PercentageType.NORMAL
                }
            });
        }
        // calculo de porcentaje...
        // si un porcentaje es menor que 0.1 y mayor que cero, se obtiene el primer número distinto de cero en el decimal
        // ejm: 0.0593838 -> 0.5
        // los porcentajes mayores 
        // Hice algunos ajustes pero la base parte de este algoritmo: Largest Remainder Method
        // https://stackoverflow.com/a/13483710/3537750
        let items = counterItems.map((counterItem) => {
            let percentage = calculation(counterItem.total, total);
            let percentageType = PercentageType.NORMAL;
            if (percentage > 0) {
                if (percentage < 0.1) {
                    percentage = this.fixPercentage(percentage);
                    percentageType = PercentageType.FIXED;
                } else if (percentage >= 1 && !isInteger(percentage)) {
                    percentage = parseInt(percentage);
                    percentageType = PercentageType.TRUNCATED;
                }
            }
            return {
                ...counterItem,
                percentage: percentage,
                percentageType: percentageType
            }
        });

        let base = 0.1;

        let percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
        if (percentageTotal === 100) {
            return items;
        } else if (percentageTotal > 100) {
            base = -base;
        }
        let hasItemsTruncateds = items.filter((counterItem) => counterItem.percentageType === PercentageType.TRUNCATED);
        if (!hasItemsTruncateds.length) {
            return items;
        }

        if (base > 0) {
            items = this.add(items, percentageTotal, base);
        } else {
            items = this.reducer(items, percentageTotal, base);
        }
        return items
    }

    reducer(items, percentageTotal, base) {
        while (percentageTotal > 100) {
            for (let i = 0; i < items.length; i++) {
                let counterItem = items[i];
                let percentage = counterItem.percentage;
                if (counterItem.percentageType === PercentageType.TRUNCATED) {
                    percentage += base;
                }
                items[i] = { ...counterItem, percentage: percentage };
                percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
                if (percentageTotal <= 100) {
                    break;
                }
            }
        }
        return items;
    }

    add(items, percentageTotal, base) {
        while (percentageTotal < 100) {
            for (let i = 0; i < items.length; i++) {
                let counterItem = items[i];
                let percentage = counterItem.percentage;
                if (counterItem.percentageType === PercentageType.TRUNCATED) {
                    percentage += base;
                }
                items[i] = { ...counterItem, percentage: percentage };
                percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
                if (percentageTotal >= 100) {
                    break;
                }
            }
        }
        return items;
    }

    selectCounterItems(counterItems) {
        return counterItems.filter(item => item.onlyForProgressBar);
    }

    render() {
        const { label, counterItems, total, totalForCalculate } = this.props;

        let newCounterItems = this.calculatePercentage(counterItems, total);

        if (!newCounterItems || !newCounterItems.length) {
            return null;
        }

        let counterItemsForDetail = this.calculatePercentage(this.selectCounterItems(counterItems), totalForCalculate);
        let mainItem = counterItemsForDetail.find(item => { return item.isMain; });

        return (<div className="counter-group-container space-into-progress">

            <Row className="counter-group-progress-bar clearfix">
                <div className="progressbar-label-detail">
                    <Row className="counter-group-item clearfix">
                        <div className="detail-label-counter">
                            {label}
                        </div>
                        <div className="detail-percent-counter">
                            {this.formatPercentage(mainItem)}
                        </div>
                    </Row>
                </div>
                <div className="progressbar-container">
                    <Progress className="counter-progress-bar detail-bar" multi>
                        {newCounterItems.map((counterItem, index) => {
                            return (
                                <Progress key={index}
                                    bar barClassName={counterItem.barClassName}
                                    value={counterItem.percentage}>
                                    <div className="bar-size-for-event" onClick={() => { this.handleClickItem(counterItem) }}></div>
                                </Progress>
                            );
                        })}
                    </Progress>
                </div>
                <div className="progressbar-label-count">
                    <Row className="counter-group-item">
                        <div className="detail-fraction-counter">
                            {`${mainItem.total}/${totalForCalculate}`}
                        </div>
                    </Row>

                </div>
            </Row>
        </div>);
    }

}