import React, { useState, memo } from "react";
import { Constantes } from "@common/utils";
import { SortingSelect } from "@common/components";

const ShippingSelectReport = ({ props }) => {
    const createLabel = (text) => <div className="label">{text}</div>;

    const optionsReport = [
        {
            value: 0,
            key: Constantes.TYPE_SHIPPING_REPORT.COST_PROM,
            label: createLabel("  Solo precio promedio"),
        },
        {
            value: 1,
            key: Constantes.TYPE_SHIPPING_REPORT.COST_FREE,
            label: createLabel("  Solo envío gratis"),
        },
        {
            value: 2,
            key: Constantes.TYPE_SHIPPING_REPORT.ALL,
            label: createLabel("  Precio promedio y envío gratis"),
        },
    ];

    const defaultOption = optionsReport[2];
    const [itemsSelected, setItemsSelected] = useState(() => {
        props.onSelectCostType(defaultOption.key);
        return defaultOption;
    })

    const onChange = (value) => {
        setItemsSelected(value);
        props.onSelectCostType(value.key);
    };

    return (
        <div className="shipping-select-report" data-testid="shipping-select-report">
            <SortingSelect
                overflowY="hidden"
                input={{ value: itemsSelected || optionsReport[2] }}
                name="shipping"
                placeholder="Select"
                onChange={onChange}
                options={optionsReport}
                singleValuePrefix={<span className="info-de">Ver:</span>}
                enableHandleCloseMenuOnScroll={true}
            />
        </div>
    );
};

export default memo(ShippingSelectReport);
