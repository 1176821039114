import { connect } from 'react-redux';
import Comparer from './index';
import { fetchProductsInfo, fetchHistoryInterval } from './ComparerState';
import { TableActions, HistoricActions, TableState } from '@common/components/';
import { buildProductCell } from '../product-list/components/table/CellsBuilder';
import { RetailCompassStorage, ComparerUtils } from '@common/utils';
import { changeSelectedView } from './ComparerActions';

export const mapStateToProps = (state, ownProps) => {

  const currentFilter = {
    labelsForProductList: state.labelStateReducer.labels || [],
    viewPrices: RetailCompassStorage.getPriceView()
  }

  const comparerData = ComparerUtils.getComparerData(
    state.comparerStateReducer.products,
    state.comparerStateReducer.historicData,
    ComparerUtils.orderStores(state.comparerStateReducer.stores),
    state.comparerStateReducer.view,
    [state.comparerStateReducer.startDate, state.comparerStateReducer.endDate]
  );
  const user = RetailCompassStorage.getUser();
  const storeId = RetailCompassStorage.getStoreId(user);
  const storeType = RetailCompassStorage.getStoreType(user);
  const currentCanonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory(user);
  const countryCode = RetailCompassStorage.getCountryCode(user);

  return {
    products: state.comparerStateReducer.products || [],
    stores: ComparerUtils.orderStores(state.comparerStateReducer.stores) || [],
    currentProduct: state.productListBrandReducer.currentProduct,
    productDetailOpenedProp: state.productListBrandReducer.productDetailOpened,
    historicData: state.comparerStateReducer.historicData || [],
    labels: state.labelStateReducer.labels || [],
    lastFilter: state.productListBrandReducer.lastFilter || {},
    currentFilter: currentFilter,
    storeType: storeType,
    currentCategoryId: currentCanonicalCategory.id,
    storeId: storeId,
    comparerData: comparerData,
    selectedView: state.comparerStateReducer.view,
    startDate: state.comparerStateReducer.startDate,
    endDate: state.comparerStateReducer.endDate,
    includeCardPrices: state.comparerStateReducer.includeCardPrices,
    countryCode: countryCode,
    hidePublicLabels: state.labelStateReducer.hidePublicLabels
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    buildProductCell: (params) => buildProductCell(params.row, params.currentFilter, params.optionalParams),
    fetchProductItems: (params, searchType) => TableState.fetchProductItems(dispatch, params, searchType),
    buttonBackPressedNavigate: (wasPressed) => dispatch(TableActions.buttonBackPressedNavigate(!!wasPressed)),
    updateCurrentProduct: (currentProduct) => dispatch(TableActions.updateCurrentProduct(currentProduct)),
    changeSelectedView: (view) => dispatch(changeSelectedView(view)),
    productDetailOpened: (value) => {
      dispatch(HistoricActions.ProductDetailHistoricActions.setInitialStateAction());
      dispatch(TableActions.requestProductDetailOpened(value));
    },
    fetchProductsInfo: (params) => fetchProductsInfo(dispatch, params),
    fetchHistoryInterval: (params) => fetchHistoryInterval(dispatch, params),
    validateStarCategoriesRequest: (canonicalProducts) => TableState.validateStarCategoriesRequest(dispatch, canonicalProducts),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comparer);
