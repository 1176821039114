import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CustomTable from '../../../table/CustomTable';
import * as dpmUtils from "@common/utils/src/utils/dpmUtils";
import PriceCell from './PriceCell';
import ProductCell from '../ProductCell';
import { orderBy, isEqual } from 'lodash';
import DiscountCell from "./DiscountCell";
import RetailerCell from "./RetailerCell";
import InstallmentCell from "./InstallmentCell";
import { Ranking } from "../ranking/Ranking";
import { getMinAndMaxPriceFromList, getPriceMapping, isFeeAvailableForCountry } from "./PriceUtils";
import { RetailCompassStorage, Utils, Constantes, FormatMatchedProducts } from "@common/utils/";
import { UncontrolledTooltip } from 'reactstrap';
import loadable from '@loadable/component'
import AlertCircleOutline from 'mdi-react/AlertCircleOutlineIcon';

const HistoricContainer = loadable(() => import(/* webpackChunkName: "historical-prices" */ '../../../Historic/HistoricContainer'));


const notHaveProductImg = process.env.PUBLIC_URL + '/img/ICONO_FOTO.svg';
const ImageProduct = (props) => (<img className="not-have-product-img" src={notHaveProductImg} alt='' />)


const getHeaders = ({ showInstallments, includeMarker, headersToHide, totalMatches, stores }) => {

    if (!Utils.isArray(headersToHide)) {
        headersToHide = [];
    }

    if (!RetailCompassStorage.getActiveRanking()) {
        headersToHide.push("ranking");
    }

    if(!RetailCompassStorage.isEnableCategoryRanking() || !RetailCompassStorage.isEnableProductRanking()) {
        headersToHide.push("ranking");
    }

    let markerHeader = {
        key: 'marker',
        name: '',
        sortable: false,
        className: 'marker-retail-cell',
        cellClass: 'marker-retail-cell',
        formatter: (product) => {
            if (product.isMyProduct) {
                let id = "marker-productDetail-" + product.productId;
                return <div className="marker-retail" id={id}>
                    <UncontrolledTooltip boundariesElement={"window"} placement='top'
                        target={id}
                        id="productDetailTooltip"
                        delay={0} className="detail-tooltip">
                        Tu producto
                    </UncontrolledTooltip>
                </div>;
            }
            return null;
        }
    };

    let headers = [
        {
            key: 'name',
            name: 'Producto',
            sortable: false,
            className: 'header-producto',
            cellClass: 'product-cell',
            formatter: (product) => {
                return <div>
                    <ProductCell product={product} tooltipText={product.productName || ''} isProductDetail storeType={RetailCompassStorage.getStoreType()} tooltipDelay={{ show: 0 }} />
                </div>;
            }
        },
        {
            key: 'storeName',
            name: 'Retailer',
            sortable: false,
            className: 'header-retailer',
            cellClass: 'retailer-cell',
            formatter: (product) => {
                //product.storeName = Utils.getProductStoreName(stores, product);
                return <RetailerCell product={product} />;
            }
        },
        {
            key: 'ranking',
            name: 'Posicionamiento',
            sortable: false,
            className: 'ranking-header',
            cellClass: 'ranking-cell',
            icon: <AlertCircleOutline size={15} />,
            tooltip: (
                <UncontrolledTooltip className='ranking-header-tooltip' placement='top' target='.ranking-header' container='productDetailBodyCustomTable'>
                    Acá verás la posición entre los productos de la categoría {RetailCompassStorage.getCurrentCanonicalCategory().name} equivalente a la de cada retailer.
                </UncontrolledTooltip>
            ),
            formatter: (product) => {
                return (
                    <div className="container">
                        <Ranking
                            product={product}
                            container="productDetailBodyCustomTable"
                        />
                    </div>
                );
            }
        },
        {
            key: 'installments',
            name: 'Cuotas',
            sortable: false,
            className: 'header-cuotas',
            cellClass: 'price-cell installment-cell',
            formatter: (product) => {
                return <InstallmentCell product={product} countryCode={RetailCompassStorage.getCountryCode()} />
            }
        },
        {
            key: 'discount',
            name: 'Dcto.',
            sortable: false,
            className: 'header-dscto',
            cellClass: 'price-cell discount-cell',
            formatter: (product) => {
                return <DiscountCell product={product}>-99%</DiscountCell>
            }
        },
        {
            key: 'priceWithCard',
            name: 'Tarjeta',
            sortable: false,
            className: 'header-with-card',
            cellClass: 'price-cell',
            formatter: (product) => {
                return <PriceCell product={product} priceType={Constantes.PriceType.CARD} />;
            }
        },
        {
            key: 'priceWithoutCard',
            name: 'Oferta',
            sortable: false,
            className: 'header-without-card',
            cellClass: 'price-cell',
            formatter: (product) => {
                return <PriceCell product={product} priceType={Constantes.PriceType.OFFER} />;
            }
        },
        {
            key: 'priceNormal',
            name: 'Normal',
            sortable: false,
            className: 'header-normal-price',
            cellClass: 'price-cell',
            formatter: (product) => {
                return <PriceCell product={product} priceType={Constantes.PriceType.NORMAL} />;
            }
        }
    ];

    if (includeMarker) {
        headers = [markerHeader].concat(headers);
    }

    if (!showInstallments) {
        headers = headers.filter(e => e.key !== 'installments');
    }

    if (headersToHide) {
        headers = headers.filter(e => headersToHide.indexOf(e.key) === -1);
    }

    return headers;
};

const buildRowInfo = () => {
    return (<div className="not-have-product">
        <ImageProduct />
        <div className="not-have-product-primary">
            No vendes este producto
        </div>
        <div className="not-have-product-secundary">
            Si lo tuvieras podrías ocupar el primer lugar
        </div>
    </div>);
}

const HEADERS_BUILDER_STRATEGIES = {
    [Constantes.StoreType.RETAILER]: ({ showInstallments, stores }) => getHeaders({ showInstallments, includeMarker: true, headersToHide: ["validationCell"], stores }),
    [Constantes.StoreType.BRAND]: ({ showInstallments, stores }) => getHeaders({ showInstallments, includeMarker: false, headersToHide: ["validationCell"], stores })

}

const CLASS_NAME_STRATEGIES = {
    [Constantes.StoreType.RETAILER]: {
        module: 'retail-module'
    },
    [Constantes.StoreType.BRAND]: {
        module: 'brand-module'
    },
    [Constantes.MODULES.VALIDATION_APP]: {
        module: 'validations-module'
    }
}

export default class ProductDetailBody extends PureComponent {

    static propTypes = {
        canonicalProduct: PropTypes.object,
        showRowWhenYouDoNotSellTheProduct: PropTypes.bool,
        lastFilter: PropTypes.object.isRequired,
        stores: PropTypes.array,
        tableStrategy: PropTypes.string.isRequired,
        historicConfigOptions: PropTypes.object,
        hideHistoricInfo: PropTypes.bool
    };

    static defaultProps = {
        showRowWhenYouDoNotSellTheProduct: true
    };

    constructor(props) {
        super(props);

        this.state = {
            sortKey: null,
            sortDirection: null,
        };

        this.onSort = this.onSort.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getOnRowClick = this.getOnRowClick.bind(this);
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (!isEqual(this.props.canonicalProduct, nextProps.canonicalProduct)) {
            this.setState({ sortKey: null, sortDirection: null });
        }
    }

    onSort(sortKey, sortDirection) {
        this.setState({ sortKey: sortKey, sortDirection: sortDirection });
    };

    onRowClick(event, product) {
        Utils.openInNewTab(product.url);
    }

    getOnRowClick(tableStrategy) {
        if (tableStrategy === Constantes.MODULES.VALIDATION_APP) {
            return () => { }
        }
        return this.onRowClick;
    }

    render() {
        const { canonicalProduct, showRowWhenYouDoNotSellTheProduct, tableStrategy } = this.props;
        const { sellerType } = this.props.lastFilter;

        if (!canonicalProduct) {
            return null;
        }

        let yourProduct = canonicalProduct.yourProduct || {};
        const oldDirectMatches = canonicalProduct.oldDirectMatches || [];
        let competitorsProducts = FormatMatchedProducts.buildMatchesForCanonicalProduct(canonicalProduct.allCompetitorsProducts, canonicalProduct.competitorsProducts, canonicalProduct.yoursProducts, sellerType) || [];

        let oldCompetitorsProducts = FormatMatchedProducts.buildMatchesForCanonicalProduct(oldDirectMatches, oldDirectMatches, canonicalProduct.yoursProducts, sellerType) || [];
        FormatMatchedProducts.formatStoreNameOfTheMatch(competitorsProducts, this.props.stores);
        let newItems = dpmUtils.processItems(competitorsProducts, yourProduct);

        if (newItems.length === 0) {
            return null;
        }

        //Aplicar formato de 3 precios
        newItems = newItems.map(product => { return { ...product, ...getPriceMapping(product) } });

        const { bestPriceAmongSellers, higherPriceAmongSellers } = getMinAndMaxPriceFromList(newItems);
        
        newItems = newItems.map((item) => {
            item.dpmPercentage = null;
            item.priceClassName = null;

            if (Utils.hasValue(item.priceCellClass)) {
                item.classes = item.priceCellClass;
            }
            if (Utils.hasValue(item.priceCellColor)) {
                //item.color = item.priceCellColor;
            }

            item.bestPriceAmongSellers = bestPriceAmongSellers;
            item.higherPriceAmongSellers = higherPriceAmongSellers;
            item.storeName = Utils.getProductStoreName(this.props.stores, item);
            item.canonicalId = canonicalProduct.productId;
            item.sellerMatchesByStore = Utils.getSellerMatches(canonicalProduct, item.storeId)

            return item;
        });

        // sort
        let sortKey = this.state.sortKey;
        let sortDirection = this.state.sortDirection;
        if (sortDirection && sortDirection !== 'NONE') {
            newItems = orderBy(newItems, [(data) => (data[sortKey] || '')], [sortDirection.toLowerCase()]);
        }

        let rowInfo = null;
        if (
            showRowWhenYouDoNotSellTheProduct &&
            !dpmUtils.isValidMyProduct(yourProduct)
        ) {
            rowInfo = buildRowInfo();
        }

        const countryCode = RetailCompassStorage.getCountryCode();
        const showInstallments = isFeeAvailableForCountry(countryCode);
        let headersBuilder = HEADERS_BUILDER_STRATEGIES[tableStrategy];
        const headers = headersBuilder && headersBuilder({ showInstallments, totalMatches: (canonicalProduct.directMatches ? canonicalProduct.directMatches.length : 0), stores: this.props.stores });
        let moduleClassName = CLASS_NAME_STRATEGIES[tableStrategy] && CLASS_NAME_STRATEGIES[tableStrategy].module;
        return (<div className="product-detail-body-container">
            {
                this.props.hideHistoricInfo ? null :
                    <HistoricContainer
                        canonicalProduct={canonicalProduct}
                        className="product-detail--historic"
                        key={"History-" + canonicalProduct.productId}
                        configOptions={this.props.historicConfigOptions}
                        stores={this.props.stores}
                        lastFilter={this.props.lastFilter}
                        products={competitorsProducts}
                        viewPrices={this.props.viewPrices}
                        updateViewPriceState={this.props.updateViewPriceState}
                        oldProducts={oldCompetitorsProducts}
                        countryCode={countryCode}
                    />
            }
            <div className={"product-detail-body-title " + moduleClassName}>
                Matches idénticos hoy
            </div>

            <CustomTable
                responsive={true}
                headers={headers}
                rows={newItems}
                tableIdId='productDetailBodyCustomTable'
                className={'table--bordered ' + moduleClassName}
                rowKey="productId"
                onSort={this.onSort}
                count={newItems.length}
                allRows={[]}
                rowInfo={rowInfo}
                key={canonicalProduct.productId}
                sortKeyDefault={'priceWithoutCard'}
                sortDirectionDefault={'ASC'}
                onRowClick={this.getOnRowClick(tableStrategy)}
            />

        </div>);
    }

}
