import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestCanonicalMerge = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsMerging: true
    });
}

export const receiveCanonicalMerge = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsMerging: false
    });
}

export const receiveCanonicalMergeError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsMerging: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_CANONICAL_MERGE]: requestCanonicalMerge,
    [ValidationActions.RECEIVE_CANONICAL_MERGE]: receiveCanonicalMerge,
    [ValidationActions.RECEIVE_CANONICAL_MERGE_ERROR]: receiveCanonicalMergeError,
}

export default handlers