import React, { Component } from 'react';
import { Row, Col, Container, CardBody, Card, Button } from 'reactstrap';
import CandidatePanel from './CandidatePanel';
import CurrentStepPanel from './CurrentStepPanel';
import { PropTypes } from "prop-types";
import { AttributesUtils, Utils } from "@common/utils/";
import MatchesSearch from '../../common/components/MatchesSearch';
import CloseIcon from 'mdi-react/CloseIcon';
import { findIndex } from 'lodash';
import AdvancePanel from './AdvancePanel';
import { getIdsToExcludeInSearch } from '../../common/utils/utils'

export class Step4 extends Component {

    static propTypes = {
        taskTitle: PropTypes.string,
        currentProduct: PropTypes.object.isRequired,
        storedAttributes: PropTypes.array,
        attributesData: PropTypes.array,
        urlParams: PropTypes.object.isRequired,
        totalSteps: PropTypes.number.isRequired,
        canonicalSearchByKeywords: PropTypes.func.isRequired,
        canonicalSearchResult: PropTypes.array,
        canonicalIsSearchingByKeywords: PropTypes.bool,
        onFinishedTask: PropTypes.func,
        canonicalMerge: PropTypes.func.isRequired,
        contextInfo: PropTypes.object,
        canonicalSearchResultEmptyState: PropTypes.bool,
        isRemovingTheMatch: PropTypes.bool,
        isTransformingTheMatch: PropTypes.bool,
        removeIdentical: PropTypes.func,
        competitors: PropTypes.array
    };

    static defaultProps = {
        taskTitle: "",
        onFinishedTask: () => { },
        canonicalSearchResultEmptyState: false
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedProduct: null
        };
        this.continueClick = this.continueClick.bind(this);
        this.joinClick = this.joinClick.bind(this);
        this.showProduct = this.showProduct.bind(this);
        this.onSearch = this.onSearch.bind(this)
    }

    componentDidMount() {
    }

    joinClick() {
        this.props.canonicalMerge(this.state.selectedProduct);
    }

    continueClick() {
        this.setState({ selectedProduct: null })
    }

    onSearch(keywords) {
        let { urlParams, allMatches, currentProduct } = this.props;
        this.props.canonicalSearchByKeywords(currentProduct.storeId, urlParams.categoryId, keywords, getIdsToExcludeInSearch(allMatches));
    }

    showProduct(product) {
        let selected =
        {
            ...product,
            directMatches: product.directMatches,
            enhancedAttributes: AttributesUtils.enhanceAttributesFromProductSearch(product, this.props.attributesData)
        }
        this.setState({ selectedProduct: selected })
    }

    getSearchAction = (product) => {
        return (<div className="match__actions">
            <Button size={'sm'} className={"btn-outline-light action button__revision "} onClick={() => { this.showProduct(product) }}>
                Revisar canónico
                </Button>
        </div>);
    }

    render() {
        let { currentProduct, attributesData, storedAttributes, urlParams, canonicalSearchResult } = this.props;
        currentProduct.enhancedAttributes = AttributesUtils.enhanceProductAttributes(storedAttributes, attributesData);
        let { username, userId } = Utils.getDecodedContextInfo(urlParams.contextInfo)
        let index = this.state.selectedProduct ?
            findIndex(canonicalSearchResult, ["productId", this.state.selectedProduct.productId]) + 1 : null;
        return (
            <Container className="approval-step approval-step--4">
                <Row>
                    <Col sm={12}>
                        <CurrentStepPanel
                            currentStep={4}
                            urlParams={this.props.urlParams}
                            showBackLink={true}
                            totalSteps={this.props.totalSteps}
                            title={<span>Busca si el producto por aprobar pertenece a algún canónico</span>}
                        />
                    </Col>
                </Row>
                <Row className="content-row">
                    <Col md={6} className="left-panel">
                        <Card>
                            <CardBody>
                                <CandidatePanel
                                    currentProduct={this.props.currentProduct}
                                    urlParams={this.props.urlParams}
                                    removeIdentical={this.props.removeIdentical}
                                    allMatches={this.props.allMatches}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    taskTitle={this.props.taskTitle}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="right-panel">
                        <Card className={"search-matches " + (this.state.selectedProduct ? "hidden" : "")}>
                            <CardBody>
                                <MatchesSearch
                                    userId={userId}
                                    username={username}
                                    allMatches={this.props.allMatches}
                                    competitors={this.props.competitors}
                                    canonicalProduct={this.props.currentProduct}
                                    customSearch={this.onSearch}
                                    potentialMatches={canonicalSearchResult}
                                    customActions={this.getSearchAction}
                                    autoSearch={true}
                                    isSearching={this.props}
                                    emptyStateText="Sin resultados"
                                    emptyStateTitle="No se encontró ningún producto canónico"
                                    noResults={this.props.canonicalSearchResultEmptyState}
                                    title={"BUSCADOR DE CANÓNICOS EXISTENTES"}
                                    canonicalTableItems={true}
                                    showRetailerButton={false}
                                    showImageComparer={false} 
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch} />
                                    
                            </CardBody>
                        </Card>
                        {
                            this.state.selectedProduct ?
                                <Card className="product-panel-container">
                                    <CardBody className="no-padding">
                                        <div className="close-btn" onClick={() => { this.setState({ selectedProduct: null }) }}><CloseIcon size={22} /></div>

                                        <CandidatePanel
                                            currentProduct={this.state.selectedProduct}
                                            urlParams={this.props.urlParams}                                            
                                            allMatches={this.state.selectedProduct.allMatches || this.state.selectedProduct.matches}

                                            attributesTitle="Atributos"
                                            title={<div><span>Canónico</span><span className="slim sub-step">{index} de {canonicalSearchResult.length}</span></div>}
                                            showMarker={true}
                                            matchesActions={()=>(null)}
                                            showImageComparer={false}
                                            showUserName={false}
                                            isRemovingTheMatch={this.props.isRemovingTheMatch}
                                            isTransformingTheMatch={this.props.isTransformingTheMatch}
                                        />    

                                        <div className="duplicated__actions match__actions">
                                            <Button size={'sm'} className={"btn-outline-light action button__reject"} onClick={this.continueClick}>
                                                No pertenece
                                                </Button>
                                            <Button size={'sm'} className={"btn-outline-light action button__approve"} onClick={this.joinClick}>
                                                Si, unir a este canónico
                                                </Button>
                                        </div>
                                    </CardBody>
                                </Card> : null
                        }
                        <AdvancePanel
                            urlParams={this.props.urlParams}
                            linkCaption={"No pertenece a ningún canónico, ir al siguiente paso"}
                            advanceAction={() => { console.log("Going to Step5") }}
                        />

                    </Col>
                </Row>
            </Container>
        )
    }
}
export default Step4;