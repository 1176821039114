import React, { PureComponent } from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { ChurnZero, Constantes, ModuleUtils } from "@common/utils";
import { hideEmptyStateView } from "@app/retail/src/product-list/components/EmptyStateView";

class SelectView extends PureComponent {
    static propTypes = {
        selectedView: PropTypes.string,
        modules: PropTypes.array,
        configuration: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.onSelectedView = this.onSelectedView.bind(this);
        this.getStyleSelection = this.getStyleSelection.bind(this);
        this.resetStoreAction = this.resetStoreAction.bind(this);
    }

    onSelectedView(selected) {
        this.hideLabelsForm(selected);
        this.resetStoreAction(selected);
        this.props.onSelectedView(selected, this.props.selectedView);
    }

    resetStoreAction(selected) {
        if (selected === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST 
            || selected === Constantes.TYPE_VIEW_RETAIL.SHIPPING) {
            this.props.resetStoreAction();
        }
    }

    hideLabelsForm(selected) {
        if (
            selected !== Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST &&
            this.props.visibleLabelsForm
        ) {
            this.props.hideLabelsForm();
        }
    }

    getStyleSelection(style, typeView) {
        const { selectedView } = this.props;
        if (selectedView === typeView) {
            return style + " view-selection--active";
        }
        return style;
    }

    handleSelectedView(view, selectedReport) {
        this.onSelectedView(view);
        if (Constantes.TYPE_VIEW_RETAIL.REPORT === view) {
            ChurnZero.trackEvent({ eventName: "scalibur_vista_reporte" });
        }

        const resetState = [
            Constantes.TYPE_REPORT_RETAIL.COMPETITIVENESS, 
            Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST,
            Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF
        ].includes(selectedReport);
        if (resetState) this.props.resetStoreForShipping();
    }

    render() {
        const PriceIcon = process.env.PUBLIC_URL + "/img/priceIcon.svg";
        const ShippingIcon = process.env.PUBLIC_URL + "/img/shippingIcon.svg";
        const ReportIcon = process.env.PUBLIC_URL + "/img/reportIcon.svg";
        const isActiveShipping = ModuleUtils.isActiveShippingModule(this.props.configuration, this.props.modules);

        if (!hideEmptyStateView(this.props.paginationCount,this.props.emptyStateType)) {
            return null;
        }
        const aloneStyle = isActiveShipping ? "" : "-alone";
        
        return (
            <div className="product-list-content col-md-12 col-lg-12 clearfix">
                <div>
                    <Col md={12} lg={12} xl={12} className="clearfix view-selection">
                        <div className={this.getStyleSelection(`view-selection__left${aloneStyle} list`, Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST)}
                            onClick={() => {this.handleSelectedView(Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST);}}>
                            Precios <img src={PriceIcon} className="img"/>
                        </div>
                        {isActiveShipping ? (
                            <div className={this.getStyleSelection("view-selection__middle shipping", Constantes.TYPE_VIEW_RETAIL.SHIPPING)}
                                onClick={() => {this.handleSelectedView(Constantes.TYPE_VIEW_RETAIL.SHIPPING);}}>
                                Shipping{" "}<img src={ShippingIcon} className="img" />
                            </div>
                        ) : null}
                        <div className={this.getStyleSelection(`view-selection__right${aloneStyle} report`, Constantes.TYPE_VIEW_RETAIL.REPORT)}
                            onClick={() => {this.handleSelectedView(Constantes.TYPE_VIEW_RETAIL.REPORT, this.props.selectedReport);}}>
                            Reporte <img src={ReportIcon} className="img" />
                        </div>
                    </Col>
                </div>
            </div>
        );
    }
}

export default withRouter(SelectView);
