import React from 'react';
import PurchaseSummaryCard from '@common/components/src/ui/PurchaseSummaryCard';
import { filter, isNumber, isNaN, sortBy, isEmpty } from 'lodash';
import { Constantes, Utils, GaUtils, RetailCompassStorage } from '@common/utils';
import { getDiscountBetweenPirces, getOffertPriceForDetailCell, isCardPrice, isVisibleFees, getPriceByView } from '../common/components/product_detail/PriceUtils';

export const purchaseSummaryCardOnClick = (product) => {
    GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.LINK_OUT, product.storeName, product.url);
    Utils.openInNewTab(product.url);
}

export const getValueOrDefault = (value) => {
    if (!isNumber(value) || isNaN(value)) {
        return -1;
    }
    return value;
}

export const fulfillsSellerCondition = (isSeller, sellerType) => {
    return (isSeller && sellerType.indexOf(Constantes.SELLERTYPE_FILTER.MARKETPLACE) !== -1) ||
            (!isSeller && sellerType.indexOf(Constantes.SELLERTYPE_FILTER.DIRECT) !== -1) ;
}

export const getMatchProduct = (competitorsProducts, storeId, currentFilter) => {
    let products = filter(sortBy(competitorsProducts,["price","isSeller"]), { storeId: parseInt(storeId, 10) });
    if(products && products.length > 0){
        for(let i = 0; i < products.length; i++){
            if(!currentFilter.sellerType || currentFilter.sellerType.length === 0){
                return products[i];
            }
            if(fulfillsSellerCondition(products[i].isSeller, currentFilter.sellerType)){
                return products[i];
            }
        }
    }
    return null;
}

export const buildRetailCell = (row, retailProps, currentFilter, activeRanking) => {
    const { storeId } = retailProps;
    const canonicalProduct = row.original;
    const matchProduct = getMatchProduct(canonicalProduct.competitorsProducts, storeId, currentFilter);
    if (!matchProduct) {
        return null;
    }

    const isPriceWithCard = isCardPrice(matchProduct);
    const pricesToShow = currentFilter.viewPrices;

    let priceMapping = getPriceByView(matchProduct, pricesToShow, isPriceWithCard);
    
    const containerClassName = isEmpty(matchProduct.priceCellClass) ? 'none' : matchProduct.priceCellClass;
    const containerClassNameShipping = matchProduct.priceShippingClass;
    const product = {
        id: matchProduct.productId,
        price: priceMapping.price,
        offerPrice: getOffertPriceForDetailCell(priceMapping),
        isPriceWithCard: Utils.validateIsCardPriceWithView(isPriceWithCard, pricesToShow),
        discount: getDiscountBetweenPirces({ ...matchProduct, price: priceMapping.price, offerPrice: priceMapping.offerPrice, normalPrice: priceMapping.normalPrice }),
        shippingIncluded: matchProduct.shippingIncluded || false,
        isOffer: matchProduct.isOffer || false,
        daysWithoutStock: getValueOrDefault(matchProduct.daysWithoutStock),
        daysInMarket: getValueOrDefault(matchProduct.daysInMarket),
        storeName: matchProduct.storeName,
        url: matchProduct.url,
        fee: isVisibleFees(RetailCompassStorage.getCountryCode(), priceMapping.price) ? matchProduct.fees : null,
        activeFee: isVisibleFees(RetailCompassStorage.getCountryCode(), priceMapping.price),
        countryCode: RetailCompassStorage.getStoreCountry(),
        isSeller: matchProduct.isSeller,
        absoluteRanking: matchProduct.absoluteRanking,
        storeId: matchProduct.storeId,
        canonicalId: canonicalProduct.productId,
        uniqueRanking: matchProduct.uniqueRanking,
        sellerName: matchProduct.sellerName,
        sellerMatchesByStore: Utils.getSellerMatches(canonicalProduct, matchProduct.storeId),
        highlight: matchProduct.highlight,
        shippings: matchProduct.shippings,
        rankings: matchProduct.rankings
    };

    const containerProps = {
        onClick: purchaseSummaryCardOnClick
    };

    if (currentFilter.selectedView === Constantes.TYPE_VIEW_RETAIL.SHIPPING) {
        activeRanking = false;
    }
    return <PurchaseSummaryCard
        product={product}
        containerClassName={containerClassName}
        formatPrice={Utils.formatPrice}
        containerProps={containerProps}
        activeRanking={activeRanking}
        selectedView={currentFilter.selectedView}
        containerClassNameShipping = {containerClassNameShipping}
        currentFilter={currentFilter}
    />
}
