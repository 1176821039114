/**
 * @author @author <a href="mailto:fhurtado@retailcompass.com">Jose Fary Hurtado Cuero</a>
 * @class favoriteState.js
 * @date 15 may. 2018
 */

import { findIndex } from 'lodash';
import * as FavoriteActions from './FavoriteActions';
import { RetailCompassStorage, Utils, Constantes } from "@common/utils/"
import { FavoriteService } from "@common/data-access/"

export const initialState = {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    favorite: RetailCompassStorage.getFavoriteConfig(),
    favoriteProductItems: [],
    idComponent: null
};

const DEFAULT_USER_ID = 2455;

export const fetchFavorites = (dispatch, receiveResponseCallback) => {
    const canonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
    const currentUser = RetailCompassStorage.getUser();
    const isRealUser = currentUser.isRealUser || false;
    let userId = (isRealUser ? currentUser.id : DEFAULT_USER_ID);

    let body = {
        categoryId: canonicalCategory.id,
        userId: userId,
        storeType: RetailCompassStorage.getStoreType()
    };

    if (!body.categoryId || !body.userId) {
        return;
    }

    const receiveCallback = (response) => {
        receiveResponseCallback && receiveResponseCallback(response);
        return FavoriteActions.receiveFavoritesAction({
            ...response
        });
    }

    const receiveErrorCallback = (error) => {
        receiveResponseCallback && receiveResponseCallback({});
        return FavoriteActions.receiveFavoritesErrorAction({
            ...error
        });
    }

    return FavoriteService.fetchFavorites({
        dispatch,
        data: body,
        requestCallback: FavoriteActions.requestFavoritesAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: receiveErrorCallback
    });
};

export const fetchCreate = (dispatch, payload) => {
    if (!payload || !payload.favoriteId || !payload.productIds) {
        return;
    }

    let body = {
        favoriteId: payload.favoriteId,
        productIds: payload.productIds
    };

    const receiveCallback = (response) => {
        return FavoriteActions.receiveCreateFavoriteAction({
            response: response,
            requestBody: {
                favoriteId: payload.favoriteId,
                productIds: payload.productIds
            }
        });
    }

    return FavoriteService.fetchCreate({
        dispatch,
        data: body,
        requestCallback: FavoriteActions.requestCreateFavoriteAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: FavoriteActions.receiveCreateFavoriteErrorAction
    });
};

export const fetchDeleteByFavoriteAndProductIds = (dispatch, favoriteId, productIds) => {
    if (!favoriteId || !Utils.isArrayWithData(productIds)) {
        return;
    }

    const receiveCallback = (response) => {
        return FavoriteActions.receiveDeleteFavoriteAction({
            response: response,
            requestBody: {
                favoriteId,
                productIds
            }
        });
    }

    return FavoriteService.fetchDeleteByFavoriteAndProductIds(favoriteId, productIds, {
        dispatch,
        requestCallback: FavoriteActions.requestDeleteFavoriteAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: FavoriteActions.receiveDeleteFavoriteErrorAction
    });
};


/**
 * obtenemos el favorito guardado en localStorage solo si:
 * la categoría actual corresponde a la categoría asociada al favorito
 */
export const getDefaultFavorite = () => {
    const canonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
    if (canonicalCategory && initialState.favorite && initialState.favorite.categoryId === canonicalCategory.id) {
        return initialState.favorite;
    }
    return null;
}

/**
 * Creamos un favorite product con un ID que solo será usado localmente.
 * después de crear un favorite_product no llamamos al endpoint que trae la lista de favoritos,
 * en lugar de ello los agregamos localmente.
 */
export const updateFavoriteProductItems = (favoriteProductItems, favorite, productIds) => {
    favoriteProductItems = favoriteProductItems || [];

    if (!Utils.isArrayWithData(productIds)) {
        return favoriteProductItems;
    }

    productIds = productIds.filter((productId) => findIndex(favoriteProductItems, { "productId": productId }) === -1);

    let favoriteProductItemsToAdd = productIds.map((productId) => ({ id: Constantes.INVALID_ID, productId, favorite, updated: undefined }));
    return favoriteProductItems.concat(favoriteProductItemsToAdd);
}

export const FavoriteStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case FavoriteActions.RESET_STORE:
            return Object.assign({}, initialState, { favorite: null });
        case FavoriteActions.REQUEST_FAVORITE:
            return Object.assign({}, state, { isFetching: true, favoriteProductItems: [], favorite: getDefaultFavorite() });
        case FavoriteActions.RECEIVE_FAVORITE:
            let { favoriteProducts, favorite } = action.payload;

            return Object.assign({}, state, {
                isFetching: false,
                favoriteProductItems: favoriteProducts,
                favorite: favorite
            });
        case FavoriteActions.RECEIVE_FAVORITE_ERROR:
            return Object.assign({}, state, { isFetching: false, favoriteProductItems: [], favorite: getDefaultFavorite() });
        case FavoriteActions.REQUEST_FAVORITE_CREATE:
            return Object.assign({}, state, { isCreating: true });
        case FavoriteActions.RECEIVE_FAVORITE_CREATE:
            let newFavoriteProductItems = updateFavoriteProductItems(state.favoriteProductItems, state.favorite, action.payload.requestBody.productIds);
            return Object.assign({}, state, { isCreating: false, favoriteProductItems: newFavoriteProductItems });
        case FavoriteActions.RECEIVE_FAVORITE_CREATE_ERROR:
            return Object.assign({}, state, { isCreating: false });
        case FavoriteActions.REQUEST_FAVORITE_DELETE:
            return Object.assign({}, state, { isDeleting: true });
        case FavoriteActions.RECEIVE_FAVORITE_DELETE:
            let favoriteProductItems = state.favoriteProductItems.filter((item) => !action.payload.requestBody.productIds.includes(item.productId));
            return Object.assign({}, state, {
                isDeleting: false,
                favoriteProductItems: favoriteProductItems
            });
        case FavoriteActions.RECEIVE_FAVORITE_DELETE_ERROR:
            return Object.assign({}, state, { isDeleting: false });
        case FavoriteActions.UPDATE_STATE_FAVORITE:
            return Object.assign({}, state, { idComponent: action.payload.idComponent });

        default:
            return state;
    }
};

