import * as RetailCompassStorage  from '../app/Storage'
import { ENV, generateRandomString} from '../utils/utils'
import { TrackJS } from "trackjs";
const version = (require("../../package.json")).version;

let installed = false, hasMetadata = false;
const token = "f17a05f6b6a6458981a4bdaa3dad94fc";
let correlationId = "";

export const init = () => {
    if(!installed){
        installed = TrackJS.install({ 
            token: token,
            application : getAppValue(),
            enabled: !ENV.isDevelopment(),
            console: { enabled: false, display: false},
            dependencies: true
        });
        TrackJS.addMetadata("app", getAppValue()); 
        TrackJS.addMetadata("version", version);
        window.TrackJS = TrackJS;
        correlationId = generateRandomString();
    }
    addUserData();
}

export const track = (message) => {
    if(installed){
        TrackJS.track(message || "unknown");
    }
}

export const removeUserData = () => {
    if(installed && hasMetadata){        
        TrackJS.configure({             
            userId: "unknown"
        });
        TrackJS.removeMetadata("username");
        TrackJS.removeMetadata("role");
        TrackJS.removeMetadata("empresa");
        TrackJS.removeMetadata("country");
        hasMetadata = false;
    }    
}

export const addUserData = () => {
    if(installed && RetailCompassStorage.isLoggedIn() && !hasMetadata){        
        TrackJS.configure({             
            userId: "slb-"+RetailCompassStorage.getId()
        });
        TrackJS.addMetadata("username", RetailCompassStorage.getEmail());
        TrackJS.addMetadata("role", RetailCompassStorage.getRole());
        TrackJS.addMetadata("empresa", RetailCompassStorage.getStoreName());
        TrackJS.addMetadata("country", RetailCompassStorage.getCountryCode());        
        hasMetadata = true;
    }
}

export const pageview = () => {
    if(installed){
        new Image().src = "https://usage.trackjs.com/usage.gif?token="+token+"&application="+getAppValue()+"&correlationId="+correlationId;
    }
}

export const _console = (args, severity) => {
    if(installed && args){
        let type = ['log', 'info', 'warn','debug', 'error'].indexOf(severity) !== -1 ? severity : 'log';
        TrackJS.console[type](args);
    }
}

const getAppValue = () => ("scalibur" + (ENV.isProduction() ? "-prod" : "-test"));

export const isInstalled = () => (installed);

export const TrackJSLogger = store => next => action => {
    try {
        return next(action)
    } catch (err) {
        // Something bad happened, lets log out the entire state so we can see it in the timeline
        if(installed){
            TrackJS.console.warn(store.getState());  
        }      
    }
}

