import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestTransformIntoCanonical = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsTransformingIntoCanonical: true
    });
}

export const receiveTransformIntoCanonical = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsTransformingIntoCanonical: false
    });
}

export const receiveTransformIntoCanonicalError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsTransformingIntoCanonical: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_CANONICAL_TRANSFORM_INTO_CANONICAL]: requestTransformIntoCanonical,
    [ValidationActions.RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL]: receiveTransformIntoCanonical,
    [ValidationActions.RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL_ERROR]: receiveTransformIntoCanonicalError,
}

export default handlers