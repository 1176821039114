import { head } from "lodash";
import {
    FormatMatchedProducts,
    Utils,
    Constantes
} from "@common/utils/"
import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues)
}

export const formatCurrentProduct = (matchedProducts, requestBody) => {
    let params = { storeId: null, requestBody, matchedProducts };
    let productItems = FormatMatchedProducts.formatMatchedProducts(null, params);
    return head(productItems);
}

export const requestFindMatchedFullProduct = (state, action) => {
    return updateObject(state, {
        isFetching: true,
        currentProduct: null
    });
}

export const receiveFindMatchedFullProduct = (state, action) => {
    const response = action.payload.response;
    const requestBody = action.payload.requestBody;

    let currentProduct = null;
    try {
        currentProduct = formatCurrentProduct([response.product], requestBody);
        currentProduct.isCanonicalPostPago = !!currentProduct.isPostPago;
    } catch (e) {
        console.error(e);
    }
    return updateObject(state, {
        isFetching: false,
        currentProduct: currentProduct
    });
}

export const receiveFindMatchedFullProductError = (state, action) => {
    return updateObject(state, {
        isFetching: false,
        currentProduct: null,
        error: action.payload.error
    });
}

export const receiveFindMatchedFullProductAbortError = (state, action) => {
    return updateObject(state, {
        isFetching: false,
        currentProduct: null,
    });
}

const handlers = {
    [ValidationActions.REQUEST_FIND_MATCHED_FULL_PRODUCT]: requestFindMatchedFullProduct,
    [ValidationActions.RECEIVE_FIND_MATCHED_FULL_PRODUCT]: receiveFindMatchedFullProduct,
    [ValidationActions.RECEIVE_FIND_MATCHED_FULL_PRODUCT_ERROR]: receiveFindMatchedFullProductError,
    [ValidationActions.RECEIVE_FIND_MATCHED_FULL_PRODUCT_ABORT_ERROR]: receiveFindMatchedFullProductAbortError,
}

export default handlers