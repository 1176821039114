import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { Button } from 'reactstrap';
import {Link} from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { buildNextStepUrl } from '../../common/utils/utils';

export class AdvancePanel extends Component {

    static propTypes = {
        buttonAction: PropTypes.func,
        buttonCaption: PropTypes.string,
        linkCaption: PropTypes.string,
        urlParams: PropTypes.object.isRequired,
        buttonClassName: PropTypes.string

    };

    static defaultProps = {
        buttonCaption: "Abandonar",
        buttonClassName: "red",
        advanceAction: ()=>{}
    };

    constructor(props) {
        super(props);
        this.state = {};
    }


    render(){
        let { buttonAction, buttonCaption, linkCaption, buttonClassName, urlParams } = this.props;
        return (
                    <div className="advance-panel">
                       {
                           !linkCaption ? null :
                           <Link className='advance-link' to={buildNextStepUrl(urlParams)} onClick={this.props.advanceAction}>
                                { linkCaption } <ArrowForwardIcon/>
                            </Link>
                       }
                        
                        {
                            !!buttonAction ?
                            <Button size={'sm'} className={"btn-outline-light action " + buttonClassName} onClick={this.props.buttonAction}>
                                { buttonCaption }
                            </Button>:null
                        }   
                    </div>                                  
                )
    }
}
export default AdvancePanel;