import * as Utils from "../../utils/utils";
import * as Constantes from "../../utils/constantes";
import * as SubRouterActions from "../actions/SubRouterActions";
import * as RetailCompassStorage from '../../app/Storage';


/**
 * este código pasó de ser una variable a un método porque siendo constante, 
 * devolvía el valor como resultado del llamado a `Utils.getParamFromQueryString`
 * esto generaba un problema que incluso cuando no habían parametros en la url, devolvía valores
 */
export const getInitialState = () => {

    const selectedView = Utils.getParamFromQueryString(Constantes.URL_PARAMS.SELECTED_VIEW) || Constantes.TYPE_VIEW_RETAIL.DEFAULT;
    const props = {
        selectedReport: Utils.getParamFromQueryString(Constantes.URL_PARAMS.REPORT) || undefined,
        selectedSubReport: Utils.getParamFromQueryString(Constantes.URL_PARAMS.SUBREPORT) || undefined,
        selectedView: selectedView,
    };

    if (selectedView === Constantes.TYPE_VIEW_RETAIL.SHIPPING) {
        props.shippingType = Utils.getParamFromQueryString(Constantes.URL_PARAMS.SHIPPING_TYPE) || Constantes.SHIPPING_TYPE.COST;
        props.commune = Utils.getParamFromQueryString(Constantes.URL_PARAMS.COMMUNE) || undefined;
        props.sendCost = Utils.getParamFromQueryString(Constantes.URL_PARAMS.SEND_COST) || false;
        props.sendShipment = Utils.getParamFromQueryString(Constantes.URL_PARAMS.SEND_SHIPMENT) || false;
    }

    const INITIAL_STATE_STRATEGY = {
        [Constantes.StoreType.RETAILER]: {
            ...props
        },
        [Constantes.StoreType.BRAND]: {
            ...props
        }
    }

    let state = {};
    let storeType = (RetailCompassStorage.isLoggedIn() ? RetailCompassStorage.getStoreType() : Constantes.StoreType.RETAILER)
    if (storeType) {
        state = INITIAL_STATE_STRATEGY[storeType] || {};
    }
    return state
}


export const reportChange = (dispatch, report) => {
    dispatch(SubRouterActions.reportChange(report));
};

export const subReportChange = (dispatch, subReport) => {
    dispatch(SubRouterActions.subReportChange(subReport));
};

export const subReportReset = (dispatch) => {
    dispatch(SubRouterActions.resetSubReport());
};

export const reportReset = (dispatch) => {
    dispatch(SubRouterActions.resetReport());
};

export const resetAll = (dispatch) => {
    dispatch(SubRouterActions.resetAll());
};

export const onSelectedView = (dispatch, selected) => {
    dispatch(SubRouterActions.selectionView(selected));
}

export const onSelectedShippingType = (dispatch, selected) => {
    dispatch(SubRouterActions.selectionShippingType(selected));
}

export const onSelectedCommune = (dispatch, commune) => {
    dispatch(SubRouterActions.selectionCommune(commune));
}

export const resetSelectedView = (dispatch) => {
    dispatch(SubRouterActions.resetSelectedView());
};

export const onSendCost = (dispatch, value) => {
    dispatch(SubRouterActions.sendCost(value));
}

export const onSendShipment = (dispatch, value) => {
    dispatch(SubRouterActions.sendShipment(value));
}

export const ReportStateReducer = (state, action) => {
    let initialState = undefined;
    state = state || getInitialState();
    action = action || {};
    switch (action.type) {
        case SubRouterActions.REPORT_CHANGE:
            return Object.assign({}, state, { selectedReport: action.payload.report });
        case SubRouterActions.REPORT_RESET:
            initialState = getInitialState();
            return Object.assign({}, state, { selectedReport: initialState.selectedReport });
        case SubRouterActions.SUBREPORT_CHANGE:
            return Object.assign({}, state, { selectedSubReport: action.payload.subReport });
        case SubRouterActions.SUBREPORT_RESET:
            initialState = getInitialState();
            return Object.assign({}, state, { selectedSubReport: initialState.selectedSubReport });
        case SubRouterActions.RESET_ALL:
            initialState = getInitialState();
            return Object.assign({}, state, {
                selectedSubReport: initialState.selectedSubReport,
                selectedReport: initialState.selectedReport,
                selectedView: initialState.selectedView
            });
        case SubRouterActions.SELECTION_VIEW:
            return Object.assign({}, state, { selectedView: action.payload.selectedView });
        case SubRouterActions.SELECTION_VIEW_RESET:
            initialState = getInitialState();
            return Object.assign({}, state, { selectedView: initialState.selectedView });
        case SubRouterActions.SELECTION_SHIPPING_TYPE:
            return Object.assign({}, state, { shippingType: action.payload.shippingType });
        case SubRouterActions.SELECTION_COMMUNE:
            return Object.assign({}, state, { commune: action.payload.commune });
        case SubRouterActions.SEND_COST:
            return Object.assign({}, state, { sendCost: action.payload.sendCost });
        case SubRouterActions.SEND_SHIPMENT:
            return Object.assign({}, state, { sendShipment: action.payload.sendShipment });
        default:
            return state;
    }
};
