import { sumBy } from "lodash";
import { isArrayWithData } from "./utils";
import { Utils } from "..";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import React from "react";

export const calculateTotalShipping = (communes) => {
    if (!isArrayWithData(communes)) {
        return 0;
    }
    return sumBy(communes, "count");
};

export const getShippingPrice = (price) => {
    if (price === 0) {
        return "Gratis";
    }
    return Utils.formatPrice(price);
};

export const isOnDay = (days) => {
    if (days === 0) {
        return "on-day";
    }
    return "";
};

export const showButtonsForTable = (element) => {
    if (element !== null) {
        let scrollWidth = element.scrollWidth;
        let clientWidth = element.clientWidth;
        return scrollWidth > clientWidth;
    }
    return false;
};

export const formatDataForShippingTable = (data) => {
    const regionsSet = new Set();
    const newData = [];

    for (const item of data) {
        if (item.region) {
            if (!regionsSet.has(item.region)) {
                regionsSet.add(item.region);
                newData.push(item);
            } else {
                delete item.region;
                newData.push(item);
            }
        } else {
            newData.push(item);
        }
    }

    return newData;
};

export const capitalizeRegionName = (regionName) => {
    const prepositions = ["y", "del", "de", "la", "los"];
    const words = regionName.toLowerCase().split(" ");
    const capitalizedWords = words.map((word) => {
        if (!prepositions.includes(word)) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
            return word;
        }
    });

    const cleanRegionName = capitalizedWords
        .join(" ")
        .replace(/\b[ivxlcdm]+\b|\([^)]+\)/g, "");

    return cleanRegionName;
};

export const hasCommunes = (stores) => {
    if (stores) {
        let hasValues = stores.filter((store) => {
            return store.communes.length > 0;
        });
        return hasValues.map((store) => store.name);
    }
};

export const showSkeletonForReport = (isLoading) => {
    const skeletonData = [
        { height: 100, width: "70%" },
        { height: 100, width: "70%" },
        { height: 100, width: "70%" },
        { height: 100, width: "70%" },
        { height: 100, width: "70%" },
    ];

    return (
        <Stack spacing={1}>
            {isLoading ? null : <Skeleton variant="rounded" height={120} data-testid="real-element-1"/>}
            <Skeleton variant="text" sx={{ fontSize: "2rem", marginTop: '50px' }} data-testid="real-element-2"/>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box sx={{ pt: 0.5, pr: 0.5, flex: "30%" }}>
                    <Skeleton variant="rounded" height={30} width="100%" data-testid="skeleton-1"/>
                </Box>
                <Box sx={{ pt: 0.5, pl: 0.5, flex: "70%" }}>
                    <Skeleton variant="rounded" height={100} width="100%" data-testid="skeleton-2"/>
                </Box>
            </Box>

            {skeletonData.map((data, index) => (
                <Box
                    key={index}
                    sx={{
                        pt: 0.5,
                        pl: 0.5,
                        flex: "70%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Skeleton
                        variant="rounded"
                        height={data.height}
                        width={data.width}
                    />
                </Box>
            ))}
        </Stack>
    );
};

