import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import MultiSelectField from '@common/components/src/ui/MultiSelect';
import { Utils } from '@common/utils';
import ClockIcon from 'mdi-react/ClockIcon';
import * as TableActions from '../../table/TableActions';
import { findIndex } from 'lodash';

export class SearchModelBox extends PureComponent {

    static propTypes = {
        formName: PropTypes.string.isRequired,
        onSubmit: PropTypes.func.isRequired,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        buttonText: PropTypes.string,
        hasActiveSearch: PropTypes.bool,
        onKeyPress: PropTypes.func.isRequired,
        onButtonClick: PropTypes.func.isRequired,
        onSearchReset: PropTypes.func.isRequired,
        onChangeMultiSelect: PropTypes.func.isRequired,
        showCategories: PropTypes.bool,
        models: PropTypes.array
    }

    static defaultProps = {
        showCategories: false,
        title: "SEARCH",
        subtitle: "",
        hasActiveSearch: false,
        buttonText: "GO",
        isCurrentlySearching: false,
    }

    constructor(props) {
        super(props);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSearchReset = this.onSearchReset.bind(this);
        this.optionRenderer = this.optionRenderer.bind(this);
        this.onChangeMultiSelect = this.onChangeMultiSelect.bind(this);
        this.filterOptions = this.filterOptions.bind(this);
        this.state = {
            isCurrentlySearching: false,
            isMagnifierVisible: !Utils.isArrayWithData(this.props.models),
            isResetSearchVisible: Utils.isArrayWithData(this.props.models),
            isSearchButtonClicked: true
        }
    }

    setCurrentlySearching(value) {
        this.setState({
            isCurrentlySearching: value
        })
    }

    setMagnifierVisible(value) {
        this.setState({
            isMagnifierVisible: value
        })
    }

    setResetSearchVisible(value) {
        this.setState({
            isResetSearchVisible: value
        })
    }

    setSearhcButtonClicked(value) {
        this.setState({
            isSearchButtonClicked: value
        })
        this.props.dispatch(TableActions.updateModelSearchDone(value));
    }

    onButtonClick() {
        this.setSearhcButtonClicked(true);
        this.setCurrentlySearching(false);
        this.setMagnifierVisible(false);
        this.props.onButtonClick();
    }

    onSearchReset() {
        this.setSearhcButtonClicked(false);
        this.setCurrentlySearching(false);
        this.setMagnifierVisible(true);
        this.setResetSearchVisible(false);
        this.props.onSearchReset();
    }

    onChangeMultiSelect(field, values) {
        if (!field) {
            return;
        }
        let lengthLast = this.props.currentFilter.models?.length ?? 0;
        let lengthActual = values.length;
        this.props.onChangeMultiSelect(field, values);

        if (lengthActual < lengthLast && this.state.isSearchButtonClicked || lengthActual === 0) {
            this.setSearhcButtonClicked(this.state.isSearchButtonClicked);
            this.props.onButtonClick();
            this.setCurrentlySearching(false);
        }
        else {
            this.setCurrentlySearching(true);
            this.setSearhcButtonClicked(false);
        }
    }

    optionRenderer(option) {
        return (
            <div className='select-option-postpaid'>
                <div className='info'>
                    <div className={'mine' + (option.isMyProduct ? ' active' : '')}></div>
                    <div className='model'>{option.model}</div>
                    <div>{option.storage}</div>
                </div>
                <div className='icon'>
                    {option.searchCount > 0 && <ClockIcon size={20} />}
                </div>
            </div>
        )
    }

    filterOptions(options, filter, currentValues) {
        return options
            .filter(option => Utils.toLowerCaseWithoutSpaces(option.model)
                .includes(Utils.toLowerCaseWithoutSpaces(filter)))
            .filter(option => findIndex(currentValues, { 'value': option.value }) === -1)
            .splice(0, 8);
    }

    render() {
        return (<form className='form product-list-main-filters-form' onSubmit={this.props.onSubmit} name={this.props.formName}>
            <div className="search-title">{this.props.title}</div>
            <div className="search-subtitle">{this.props.subtitle}</div>
            <div className='form__form-group'>
                <div className='form__form-group-field postpaid'>
                    {!this.props.currentFilter?.models?.length > 0 && this.state.isMagnifierVisible ?
                        <div className="icon-search">
                            <svg className="mdi-icon " width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M9.5,3C13.09,3 16,5.91 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16C5.91,16 3,13.09 3,9.5C3,5.91 5.91,3 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
                            </svg>
                        </div> : null}

                    <Field
                        name='models'
                        className={'pl-3 product-list-main-filters-search' + (this.props.hasActiveSearch ? ' product-list-main-filters-search--current' : '')}
                        component={MultiSelectField}
                        options={this.props.models}
                        placeholder=''
                        removeSelected={true}
                        onChange={(e, values) => {
                            this.onChangeMultiSelect("models", values);
                            let hasValuesSelected = (values.length > 0 ? true : false);
                            this.setMagnifierVisible(!hasValuesSelected);
                            this.setResetSearchVisible(hasValuesSelected);
                            if (!hasValuesSelected) this.setSearhcButtonClicked(false);
                        }}
                        optionalClass={'postpaid' + (!this.props.currentFilter?.models?.length > 0 && !this.state.isResetSearchVisible ? ' postpaid-ml' : '')}
                        filterOptions={this.filterOptions}
                        optionRenderer={this.optionRenderer}
                        noResultsText='Sin resultados para ese modelo'
                    />

                    {this.state.isCurrentlySearching ?
                        <button
                            className='start-search rounded ml-1'
                            onClick={this.onButtonClick}
                            type="button">
                            {this.props.buttonText}
                        </button>
                        : null}
                    {this.state.isResetSearchVisible ?
                        <button className='reset-search-postpaid' onClick={this.onSearchReset} type="button">
                            <svg className="mdi-icon " width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
                        </button>
                        : null}
                </div>
            </div>
        </form>);
    }
}
