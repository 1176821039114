import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Notification from './Notification';
import { removeNotification } from './NotificationState';


export const mapStateToProps = (state, ownProps) => {
    return {
        notification: state.notificationStateReducer.notification || {},
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        removeNotification: () => removeNotification(dispatch)
    };
};

export const NotificationConnected = connect(mapStateToProps, mapDispatchToProps)(Notification);

export default withRouter(NotificationConnected);
