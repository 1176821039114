import React, { useState, useEffect, memo } from "react";
import { Constantes, OperatorUtils, Utils } from "@common/utils/";
import CustomTable from '../../../../table/CustomTable';
import OperatorCell from "./cells/OperatorCell";
import ColorCell from "./cells/ColorCell";
import MarkerCell from "./cells/MarkerCell";
import PriceContractCell from "./cells/PriceContractCell";
import { flatten, forEach, isEqual, isNull, map, sortBy, uniq } from "lodash";
import { useFilters } from "../FiltersProvider";

const PostpagoTable = ({ tableStrategy, canonicalProduct, stores, operators, idTable, responsive }) => {

    const { minPrice, postpaidOperators, colors } = useFilters();
    const [rows, setRows] = useState([]);
    const addMarkerHeader = ![
        Constantes.StoreType.BRAND,
        Constantes.SubStoreType.BRAND_POSTPAID
    ].includes(tableStrategy);

    useEffect(() => {
        setRows(buildRows(stores));
    }, [canonicalProduct, minPrice, postpaidOperators, colors]);

    let headers = [
        {
            key: 'operator',
            name: 'Operador',
            sortable: false,
            className: 'marked-col operator-column',
            cellClass: 'marked-col',
            formatter: (item) => <OperatorCell operator={item.store} />
        },
        {
            key: 'color',
            name: 'Color',
            className: 'pl-20',
            sortable: false,
            formatter: (item) => <ColorCell colores={item.colores} />
        }
    ];

    forEach(Constantes.TYPE_CONTRACTS, contrato => {
        headers.push({
            key: contrato.label,
            name: contrato.label,
            className: 'price-column',
            sortable: false,
            formatter: (item) => <PriceContractCell productos={item.productos} typeContract={contrato.label} />
        })
    });

    if (addMarkerHeader) {
        const markerHeader = {
            key: 'marker',
            name: '',
            sortable: false,
            className: 'marker-retail-cell',
            cellClass: 'marker-retail-cell',
            formatter: (item) => <MarkerCell store={item.store} />
        };

        headers = [markerHeader].concat(headers);
    }

    const buildRows = (listStores) => {
        const competitorsProducts = canonicalProduct.allCompetitorsProducts;
        return listStores.map((store, index) => {
            const productos = filterAndprocessProducts(competitorsProducts, store);

            const row = {
                index: index,
                store: buildStore(store),
                productos: productos,
                colores: getListColors(productos),
                url: getUrl(competitorsProducts, store.storeId),
            };
            return Utils.isArrayWithData(productos) ? row : null;
        }).filter(item => !isNull(item));
    }

    const filterAndprocessProducts = (products, store) => {
        let productos = filterByStore(products, store);
        productos = filterByPlanPrice(productos);
        productos = filterByOperators(productos);
        productos = productos.map(producto => buildProduct(producto));
        productos = filterByColors(productos);
        productos = divideProductsByColorAndTypeContract(productos);
        return productos;
    }

    const filterByStore = (productos, store) => {
        return OperatorUtils.filterByStore(productos, store.storeId, store.operatorKey);
    }
    
    const filterByPlanPrice = (productos) => {
        return minPrice ? productos.filter(producto => producto.planPrice >= minPrice) : productos;
    }
    
    const filterByOperators = (productos) => {
        return OperatorUtils.filterByOperators(productos, stores, operators, postpaidOperators);
    }

    const filterByColors = (productos) => {
        return productos.filter(producto => !isNull(producto.color))
            .filter(producto => Utils.isArrayWithData(colors) ? colors.includes(producto.color) : true);
    }

    const divideProductsByColorAndTypeContract = (productos) => {
        const productosByColor = divideProductsByColor(productos);
        return divideProductsByTypeContract(productosByColor);
    }

    const divideProductsByColor = (productos) => {
        const colores = getListColors(productos);

        let listItems = colores.map(color => {
            const prodsFitereds = productos.filter(producto => isEqual(producto.color, color));
            return {
                color: color,
                productos: prodsFitereds
            }
        });

        return sortItems(listItems, ['color']);
    }

    const divideProductsByTypeContract = (productosByColor) => {
        return productosByColor.map(item => {
            const list = flatten(
                map(Constantes.TYPE_CONTRACTS, contrato => {
                    let productsByTypeContract = item.productos.filter(producto => isEqual(contrato.label, producto.tipoContrato));
                    productsByTypeContract = sortItems(productsByTypeContract, ['planPrice', 'price']);

                    return {
                        typeContract: contrato.label,
                        productos: productsByTypeContract
                    };
                })
            );

            return {
                color: item.color,
                typeContracts: list
            }
        });
    }

    const getListColors = (productos) => {
        return uniq(productos.map(p => p.color));
    }

    const getUrl = (productos, storeId) => {
        let producto = productos.find(product => isEqual(product.storeId, parseInt(storeId)));

        return producto?.url ?? "";
    }

    const sortItems = (listItems = [], itemsToSort = []) => {
        return sortBy(listItems, itemsToSort);
    }

    const buildProduct = (producto) => {
        return {
            id: producto.id,
            planPrice: producto.planPrice,
            price: producto.price,
            color: OperatorUtils.getValueAttribute(producto, Constantes.MATCH_ATTRIBUTES.COLOR),
            tipoContrato: OperatorUtils.getValueAttribute(producto, Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE),
            operator: OperatorUtils.getValueAttribute(producto, Constantes.MATCH_ATTRIBUTES.OPERATOR),
            daysWithoutStock: producto.daysWithoutStock,
            status: producto.status,
        }
    }

    const buildStore = (store) => {
        return {
            id: store.storeId,
            name: store.operatorName,
            isYourRetail: store.isYourRetail,
            operatorKey: store.operatorKey,
            visibleOperatorName: store.visibleOperatorName,
            isOperator: store.operator
        }
    }

    const onRowClick = (e, item) => {
        Utils.openInNewTab(item.url);
    }

    return (
        <div className={rows.length > 0 ? 'with-height-table' : null}>
            <CustomTable
                headers={headers}
                rows={rows}
                tableIdId={idTable}
                responsive={responsive}
                className={'table--bordered'}
                rowKey="productId"
                count={rows.length}
                allRows={[]}
                key={canonicalProduct.productId}
                showMessageEmpty={true}
                onRowClick={onRowClick}
            />
        </div>
    )
}

export default memo(PostpagoTable)