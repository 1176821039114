import React, { PureComponent } from "react";
import DatePicker from "react-datepicker-easydev";
import PropTypes from "prop-types";
import moment from "moment";
import CalendarIcon from "mdi-react/CalendarIcon";
import { isEqual } from "lodash";

class IntervalDatePickerField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onButtonClick: PropTypes.func,
        minDate: PropTypes.object.isRequired,
        maxDate: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            startDate: props.start,
            endDate: props.end,
            requestedStartDate: props.start,
            requestedEndDate: props.end,
            minDate: props.minDate,
            maxDate: props.maxDate,
            showButton: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    handleChange({ startDate, endDate }) {
        startDate = startDate
            ? startDate.utcOffset(0).startOf("day")
            : this.state.startDate;
        endDate = endDate
            ? endDate.utcOffset(0).endOf("day")
            : this.state.endDate;

        if (startDate.isAfter(endDate)) {
            endDate = startDate;
        }
        if (
            startDate.isBefore(this.props.minDate) ||
            startDate.isAfter(this.props.maxDate)
        ) {
            startDate = this.state.minDate;
        }
        if (
            endDate.isAfter(this.props.maxDate) ||
            endDate.isBefore(this.props.minDate)
        ) {
            endDate = this.state.maxDate;
        }

        let showButton =
            startDate.format("YYYY-MM-DD") !==
                this.state.requestedStartDate.format("YYYY-MM-DD") ||
            endDate.format("YYYY-MM-DD") !==
                this.state.requestedEndDate.format("YYYY-MM-DD");
        this.setState({ startDate, endDate, showButton });
        this.props.onChange({ start: startDate, end: endDate });
    }

    handleButtonClick() {
        if (
            !this.state.startDate.isBefore(this.state.minDate) &&
            !this.state.endDate.isAfter(this.state.maxDate)
        ) {
            this.props.onButtonClick({
                start: this.state.startDate,
                end: this.state.endDate
            });
            this.setState({
                showButton: false,
                requestedEndDate: this.state.endDate,
                requestedStartDate: this.state.startDate
            });
        }
    }

    handleChangeStart = startDate => this.handleChange({ startDate });

    handleChangeEnd = endDate => this.handleChange({ endDate });

    componentDidUpdate(prevProps, prevState) {
        let { start, end } = this.props;
        let state = {};
        if (!isEqual(prevProps.start, start)) {
            state.startDate = start;
        }
        if (!isEqual(prevProps.end, end)) {
            state.endDate = end;
        }
        if (!isEqual(this.props.minDate, this.state.minDate)) {
            state.minDate = this.props.minDate;
        }
        if (!isEqual(this.props.maxDate, this.state.maxDate)) {
            state.maxDate = this.props.maxDate;
        }
        if (Object.keys(state).length) {
            this.setState(state);
        }
    }

    render() {
        let maxDate = this.props.maxDate || moment();
        let minDate =
            this.props.minDate ||
            (this.props.maxDate
                ? cloneDeep(maxDate).subtract(1, "year")
                : moment().subtract(1, "year"));

        return (
            <div className="date-picker date-picker--interval">
                <div className="interval-field">
                    <span className="interval-field__label">Desde</span>
                    <div style={{ display: "flex" }}>
                        <DatePicker
                            selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeStart}
                            dateFormat="DD/MM/YYYY"
                            placeholderText=""
                            disabledKeyboardNavigation={true}
                            minDate={minDate}
                            maxDate={maxDate}
                            onFocus={this.onInputFocus}
                        />
                        <div className="form__form-group-icon">
                            <CalendarIcon />
                        </div>
                    </div>
                </div>
                <div className="interval-field">
                    <span className="interval-field__label">Hasta</span>
                    <div style={{ display: "flex" }}>
                        <DatePicker
                            selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEnd}
                            dateFormat="DD/MM/YYYY"
                            placeholderText=""
                            disabledKeyboardNavigation={true}
                            minDate={minDate}
                            maxDate={maxDate}
                            onFocus={this.onInputFocus}
                        />
                        <div className="form__form-group-icon">
                            <CalendarIcon />
                        </div>
                    </div>
                </div>
                {this.props.onButtonClick ? (
                    <button
                        className={
                            "interval-button" +
                            (!this.state.showButton ? " hidden" : "")
                        }
                        onClick={this.handleButtonClick}
                    >
                        Actualizar Fechas
                    </button>
                ) : null}
            </div>
        );
    }
}

const renderIntervalDatePickerField = props => {
    return (
        <IntervalDatePickerField
            {...props.input}
            start={props.start}
            end={props.end}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onButtonClick={props.onButtonClick}
        />
    );
};

renderIntervalDatePickerField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func
    }).isRequired,
    start: PropTypes.object,
    end: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    onButtonClick: PropTypes.func
};

export default renderIntervalDatePickerField;
