import { get } from "lodash";
import { StoreService } from '@common/data-access';
import * as StoreActions from './StoreActions';

export const initialState = {
    isFetching: false,
    store: null,
    competitors: [],
    hasError: false
};

export const receiveCallback = (storeId) => {
    return (response) => {
        return StoreActions.receiveStoreAndCompetitorsAction({
            response: response,
            requestBody: {
                storeId
            }
        })
    }
};

export const getStoreAndCompetitors = (dispatch, storeId) => {
    if (!storeId) {
        return;
    }
    return StoreService.getStoreAndCompetitors(storeId, {
        dispatch,
        requestCallback: StoreActions.requestStoreAndCompetitorsAction,
        receiveCallback: receiveCallback(storeId),
        receiveErrorCallback: StoreActions.receiveStoreAndCompetitorsErrorAction
    })
}

export const extractResponse = (responseBody) => {
    let store = get(responseBody, '[0]', null);
    let competitors = [];
    if (store) {
        competitors = get(store, 'competitors', []).filter(competitorItem => competitorItem.active);
        delete store['competitors'];
    }
    return {
        store: store,
        competitors
    }
}

export const StoreStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case StoreActions.REQUEST_STORE_AND_COMPETITORS:
            return Object.assign({}, state, {
                isFetching: true,
                competitors: [],
                store: null,
                hasError: false
            });
        case StoreActions.RECEIVE_STORE_AND_COMPETITORS:
            let { store, competitors } = extractResponse(action.payload.response);
            return Object.assign({}, state, {
                isFetching: false,
                competitors,
                store,
                hasError: false
            });
        case StoreActions.RECEIVE_STORE_AND_COMPETITORS_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                competitors: [],
                store: null,
                hasError: true
            });

        default:
            return state;
    }
};

