import * as ValidationActions from "../ValidationActions";
import { Utils } from "@common/utils/";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestCanonicalSearchByKeywords = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsSearchingByKeywords: true,
        canonicalSearchResult: [],
        canonicalSearchResultEmptyState: false
    });
}

export const receiveCanonicalSearchByKeywords = (state, action) => {
    const response = action.payload.response;
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsSearchingByKeywords: false,
        canonicalSearchResult: response,
        canonicalSearchResultEmptyState: !Utils.isArrayWithData(response)
    });
}

export const receiveCanonicalSearchByKeywordsError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsSearchingByKeywords: false,
        canonicalSearchResult: [],
        canonicalSearchResultEmptyState: true
    });
}

const handlers = {
    [ValidationActions.REQUEST_CANONICAL_SEARCH_BY_KEYWORDS]: requestCanonicalSearchByKeywords,
    [ValidationActions.RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS]: receiveCanonicalSearchByKeywords,
    [ValidationActions.RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS_ERROR]: receiveCanonicalSearchByKeywordsError,
}

export default handlers