import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestAllMatches = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: true,
        allMatches: []
    });
}

export const receiveAllMatches = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: false,
        allMatches: action.payload.allMatches
    });
}

export const receiveAllMatchesError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: false,
        allMatches: []
    });
}

const handlers = {
    [ValidationActions.REQUEST_ALL_MATCHES]: requestAllMatches,
    [ValidationActions.RECEIVE_ALL_MATCHES]: receiveAllMatches,
    [ValidationActions.RECEIVE_ALL_MATCHES_ERROR]: receiveAllMatchesError,
}

export default handlers