import React, { useRef, useEffect } from "react";

const MarkerCell = ({ store }) => {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = 0;

            setTimeout(() => {
                if (ref.current) {
                    const td = ref.current.parentElement;
                    let height = td.clientHeight;
                    if (!height) height = td.style.height.replace("px", "");
                    ref.current.style.height = `${height}px`;
                }
            }, 1);
        }
    })

    return (
        store && store.isYourRetail ?
            <div ref={ref} className="marker-retail" id={"marker-store-" + store.id} />
            : null
    )
}

export default MarkerCell