import React from 'react';
import { get } from 'lodash';
import { Utils, RetailCompassStorage, RouterUtils } from '@common/utils';
import { DaysInfo, DescriptionWithTooltip, MinimumPrice, SimpleProductCellWithRedux } from '@common/components/';

export const buildProductCell = (row, currentFilter, optionalParams) => {
    const canonicalProduct = row.original;
    const showModelTooltip = get(optionalParams, 'showModelTooltip', true);
    const showRankingInfo = get(optionalParams, 'showRankingInfo', false);

    const product = {
        ...canonicalProduct,
        attributeValues: Utils.buildAttributesArray(canonicalProduct.attributes, RetailCompassStorage.getCurrentCanonicalCategory().attributes),
        labels: currentFilter.labelsForProductList,
        imageUrl: canonicalProduct.image,
        model: canonicalProduct.model || Utils.extractModelFromMatches(canonicalProduct.directMatches),
        showEditLink: RetailCompassStorage.isAdmin(),
        editLinkProps: { url: RouterUtils.getValidationEditPath(canonicalProduct.id, canonicalProduct.categoriesPaths) },
        showModelTooltip,
        showRankingInfo,
        productName: canonicalProduct.name,
        rankingInfo: RankingInfo,
        productInfo: ProductInfo,
        markerProductClass: 'my-product-marker--mine-brand-shipping'
    }
    return <SimpleProductCellWithRedux {...product} />
}

export const buildLowerPriceCell = (row) => {
    const canonicalProduct = row.original;
    return Utils.formatPrice(canonicalProduct.minimumPrice);
}

const RankingInfo = ({ minimumPrice }) => (
    <div className="info-extra-container">
        <div className="info-section">
            <MinimumPrice minimumPrice={minimumPrice} />
        </div>
    </div>
);

const ProductInfo = (props) => {
    const { productName, model, showModelTooltip, created, brand, color, randomId } = props;
    const classModel = 'model' + (Utils.hasValue(model) ? ' ' : ' empty');
    const idModel = `model-${randomId}`;
    const idProduct = "product-name-" + randomId;

    return (
        <div className='main-info'>
            <div className="model-container">
                {color ? <div className="circle" style={{ background: color }}></div> : null}
                <DescriptionWithTooltip description={model} showTooltip={showModelTooltip} classNames={classModel} id={idModel} />
            </div>
            <div className='name'>
                <DescriptionWithTooltip description={productName} showTooltip={true} classNames={null} id={idProduct} />
            </div>
            <div className="brand">{brand}</div>
            <DaysInfo createdDate={created} showIcon={true} />
        </div>
    )
}