import { connect } from 'react-redux';
import { get } from 'lodash';
import { OutOfStockReportCommon } from '@common/utils';
import SummaryView from './index';
import * as CommonContainer from "../../common/common";

export const mapStateToProps = (state, ownProps) => {
    let commonProps = CommonContainer.mapStateToProps(state, ownProps);
    let outOfStockReportSummary = get(state.productListBrandReducer, 'outOfStockReportSummary', {});
    let counterItems = OutOfStockReportCommon.buildCounterItemsConfig(outOfStockReportSummary);

    return {
        ...commonProps,
        counterItems
    }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    let commonFuncts = CommonContainer.mapDispatchToProps(dispatch, ownProps);
    return {
        ...commonFuncts
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
