import { connect } from 'react-redux';
import ShareForStores from './ShareForStores';
import { withRouter } from 'react-router';

const mapStateToProps = (state, ownProps) => {
    let stores = state.dashboardBrandReducer.stores || [];
    const value = {
        stores: stores,
        isFetching: state.dashboardBrandReducer.isFetching
    };
    return value;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
    };
};

export const ShareForStoresConnected = connect(mapStateToProps, mapDispatchToProps)(ShareForStores);

export default withRouter(ShareForStoresConnected);