import React, { memo } from "react";

const TotalSummary = ({ productCount, shippingCount }) => {
    let productsTitle = "PRODUCTOS EN TOTAL";
    if (productCount == 0 || productCount == 1) {
        productsTitle = "PRODUCTO EN TOTAL";
    }

    let shippingTitle = "MATCHES CON INFORMACIÓN DE SHIPPING";
    if (shippingCount == 0 || shippingCount == 1) {
        shippingTitle = "MATCH CON INFORMACIÓN DE SHIPPING";
    }

    return (
        <div className="total-summary">
            <div>
                <span id={`products-${productCount}`}>{`${productCount} ${productsTitle}`}</span>
            </div>
            <div>
                <span id={`shipping-${shippingCount}`}>{`${shippingCount} ${shippingTitle}`}</span>
            </div>
        </div>
    );
};

export default memo(TotalSummary);
