import { Utils } from '@common/utils/';

const ACTION_BASE_NAME = "NOTIFICATION";

//NOTIFICATION ACTION
export const ADD_NOTIFICATION = Utils.composeActionName(ACTION_BASE_NAME, 'ADD_NOTIFICATION');
export const REMOVE_NOTIFICATION = Utils.composeActionName(ACTION_BASE_NAME, 'REMOVE_NOTIFICATION');

export const addNotificationAction = (payload) => ({
    type: ADD_NOTIFICATION,
    payload
});
export const removeNotificationAction = () => ({
    type: REMOVE_NOTIFICATION
});

