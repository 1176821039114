import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import ProgressCounter from '@common/components/src/ui/ProgressCounter';
import { RetailCompassStorage, ReduxFormsUtils, GaUtils, Constantes } from "@common/utils/"
import { join, max, filter, sumBy, isNumber } from 'lodash';

export default class ShareForPrice extends PureComponent {

    static propTypes = {
        ranges: PropTypes.array.isRequired,
        isFetching: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
    }

    calculateMaxSizeBar(ranges) {
        let values = [];
        ranges.map(value => {
            values.push(value.total);
        });
        return max(values);
    }

    buildFilter(range) {
        if (isNumber(range.from) && isNumber(range.to)) {
            return { from: range.from, to: range.to };
        } else if (isNumber(range.to)) {
            return { to: range.to };
        } else {
            return { from: range.from };
        }
    }

    builNameRange(range) {
        if (isNumber(range.from) && isNumber(range.to)) {
            return join([this.formatThousandsSeparators(range.from), this.formatThousandsSeparators(range.to)], " - ");
        } else if (isNumber(range.from)) {
            return `+${this.formatThousandsSeparators(range.from)}`;
        } else {
            return `* - ${this.formatThousandsSeparators(range.to)}`;
        }
    }

    formatThousandsSeparators(value) {
        if (!isNumber(value)) {
            return '-';
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    extractDetailBrandByRange(range, totalSizeBar) {
        const name = this.builNameRange(range);
        const priceFilter = this.buildFilter(range);
        const brands = range.brands;
        const myConfigBrands = RetailCompassStorage.getBrandIds();

        let myBrands = filter(brands, (value) => {
            return myConfigBrands.includes(value.brandId);
        });
        let otherBrands = filter(brands, (value) => {
            return !myConfigBrands.includes(value.brandId);
        });

        const totalMyBrands = sumBy(myBrands, 'count');
        const totalOtherBrands = range.total - totalMyBrands;
        const totalDefault = totalSizeBar - (totalMyBrands + totalOtherBrands);

        let barMainClass = 'color-main-progress';
        if (totalOtherBrands === 0) {
            barMainClass += ' color-main-progress-radius';
        }

        const myBrandIds = myBrands.map(e => e.brandId).join(",");
        const otherBrandIds = otherBrands.map(e => e.brandId).join(",");

        const brandsToExit = [
            { id: myBrandIds, total: totalMyBrands, barClassName: barMainClass, isMain: true, onlyForProgressBar: true, filter: priceFilter, totalProducts: range.total },
            { id: otherBrandIds, total: totalOtherBrands, barClassName: 'color-secundary-progress', isMain: false, onlyForProgressBar: true, filter: priceFilter, totalProducts: range.total },
            { id: "default", total: totalDefault, barClassName: 'color-default-progress progress-without-event', isMain: false, onlyForProgressBar: false, totalProducts: range.total },
        ];

        return {
            name: name,
            brands: brandsToExit,
            total: range.total,
            order: range.order
        };
    }

    goToProductList(params) {
        this.props.history.push({
            pathname: 'list',
            search: ReduxFormsUtils.converterFormFieldsToUrlSearchParams(params),
            state: { from: this.props.location, needRerender: true }
        });
    }

    onClickLinkEvent = (item) => {
        const myConfigBrands = RetailCompassStorage.getBrandIds();
        let params = { ...item.filter };
        let totalProducts = item.totalProducts;
        if (item.isMain) {
            params.brands = myConfigBrands.map((id) => ({ value: id }));
            totalProducts = item.total;
        }
        GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.DASHBOARD, Constantes.GA_ACTION_EVENTS.PRICE_SHARE, RetailCompassStorage.getCurrentCanonicalCategory().name, totalProducts);
        this.goToProductList(params);
    }

    render() {

        const { ranges } = this.props;
        if (!ranges || ranges.length == 0) {
            return null;
        }

        const totalSizeBar = this.calculateMaxSizeBar(ranges);
        let rangesForDraw = ranges.map(value => this.extractDetailBrandByRange(value, totalSizeBar));
        return (
            <div>
                <div className='counter-label bold-title-share-retailer'>SHARE POR PRECIO MÍNIMO</div>
                {rangesForDraw.map((value, index) => {
                    const counterItems = value.brands;
                    if (!counterItems || counterItems.length === 0) {
                        return null;
                    }
                    const totalForCalculate = value.total;
                    return (
                        <ProgressCounter
                            key={index}
                            label={value.name}
                            total={totalSizeBar}
                            totalForCalculate={totalForCalculate}
                            counterItems={counterItems}
                            onClickItem={(item) => { this.onClickLinkEvent(item) }} />
                    );
                })}
            </div>
        );
    }
}
