import React, { useState, useRef } from "react";
import { Utils, Constantes, GaUtils } from "@common/utils";
import { StarCategoryTooltip } from "./StarCategoryTooltip";
import MoreVert from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { orderBy } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";


const Ranking_ = ({
    product,
    container,
    starCategories,
    isLoadingStarCategories
}) => {
    
    if (starCategories.size === 0) return <WitoutRanking />;
    
    const { statusProduct, rankings } = product;
    const hasStock = statusProduct === Constantes.STATUS_PRODUCT.WITH_STOCK;

    if (!hasStock || !Utils.isArrayWithData(rankings)) return <WitoutRanking />;

    const bestRanking = getBestRanking(rankings);
    const uniqueRanking = rankings.length == 1;
    
    if (isLoadingStarCategories) return <WitoutRanking />;
    return (
        <WithRanking
            absoluteRanking={bestRanking.ranking}
            uniqueRanking={uniqueRanking}
            showTooltip={true}
            container={container}
            categories={starCategories}
            rankings={rankings}
            isLoadingStarCategories={isLoadingStarCategories}
        />
    );
};

const getBestRanking = (rankings) => {
    const sortedRankings = orderBy(rankings, ["ranking"], ["asc"]);
    return sortedRankings[0];
}

const SimplifiedRanking = ({ ranking }) => {
    if (!ranking) return <WitoutRanking />;
    return (
        <WithRanking
            absoluteRanking={ranking}
            uniqueRanking={true}
            showTooltip={false}
            container={undefined}
            rankings={[]}
            categories={[]}
        />
    );
};

const WithRanking = ({
    absoluteRanking,
    uniqueRanking,
    showTooltip,
    container,
    categories,
    rankings,
    isLoadingStarCategories
}) => {
    const idRef = useRef(`id-${Utils.generateRandomString()}`);
    const [isOpen, setOpen] = useState(false);
    const uniqueClass = uniqueRanking ? "unique" : "";
    const activeClass = isOpen || !showTooltip ? "active" : "";
    const rankingClass = Utils.assignRankingClass(absoluteRanking, isLoadingStarCategories);
    const className = `noselect ranking ${uniqueClass} ${rankingClass} ${activeClass}`;
   
    const handleClick = event => {
        event.stopPropagation();
        setOpen(true);
        GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.RANKING, Constantes.GA_ACTION_EVENTS.RANKING_TOOLTIP_CLICK)
    };

    const toggle = event => {
        event.stopPropagation();
        setOpen(current => !current);
    };

    const Loading = () => (
        <div className="loading">
            <CircularProgress
                className="category-tooltip__message__loading"
                size={8}
            />
        </div>    
    );

    return (
        <>
            <div
                data-testid="ranking"
                id={idRef.current}
                className={className}
                onClick={handleClick}
            >
                {isLoadingStarCategories ? <Loading /> : <span>{absoluteRanking}</span>}
                {!uniqueRanking && (
                    <MoreVert
                        data-testid={"multi-category"}
                        className="ranking__more-icon"
                    />
                )}
            </div>
            {showTooltip && (
                <StarCategoryTooltip
                    isOpen={isOpen}
                    toggle={toggle}
                    target={idRef.current}
                    container={container}
                    categories={categories}
                    rankings={rankings}
                    isLoadingStarCategories={isLoadingStarCategories}
                />
            )}
        </>
    );
};

Ranking_.propTypes = {
    product: PropTypes.object.isRequired,
    starCategories: PropTypes.object.isRequired,
    container: PropTypes.string
};

const WitoutRanking = () => <span className="without-ranking"> - </span>;

const mapStateToProps = state => {
    const {
        starCategories,
        isLoadingStarCategories
    } = state.productListBrandReducer;
    return { starCategories, isLoadingStarCategories };
};

const Ranking = connect(mapStateToProps, null)(Ranking_);

export { Ranking, SimplifiedRanking };
