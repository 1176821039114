import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../scss/app.scss";
import {
    TrackJS,
    Intercom,
    ChurnZero,
    GaUtils
} from "@common/utils/";
import { Notification } from "@common/components";
import Router from "./Router";

class App extends Component {
    componentDidMount() {
        TrackJS.init();
    }

    UNSAFE_componentWillMount() {
        Intercom.load();
    }

    UNSAFE_componentWillUpdate() {
        Intercom.boot();
        TrackJS.addUserData();
        ChurnZero.init();
        GaUtils.init();
    }

    render() {
        return (
            <div>
                <Notification />
                <Router />
            </div>
        );
    }
}

export default App;
