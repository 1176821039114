import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Label from '../label/Label';
import withLabelsList from './withLabelsList';

export class LabelsList extends Component {

    static propTypes = {
        showAll: PropTypes.bool,
        filterLabels: PropTypes.array,
        labels: PropTypes.array,
        onLabelSelection: PropTypes.func.isRequired,
        onLabelRemoval: PropTypes.func.isRequired,
        isActive: PropTypes.func.isRequired,
        applyHover: PropTypes.bool,
    };

    static defaultProps = {
        applyHover: true
    }

    constructor(props) {
        super(props);

        this.onLabelSelection = this.onLabelSelection.bind(this);
    }

    onLabelSelection(item) {
        // le decimos al HoC que el label que fue clickeado por usuario no permanezca seleccionado
        this.props.onLabelSelection(item, false);
    }

    render() {
        let labels = this.props.labels;
        return (
            <div className={"labels-list-form"} >
                {
                    labels.map((item) => {
                        return (<Label
                            key={"label-form" + item.id}
                            label={item}
                            showCloseIcon={false}
                            selected={this.props.isActive(item)}
                            mode="form"
                            onLabelSelection={this.onLabelSelection}
                            onLabelRemoval={this.props.onLabelRemoval}
                            applyHover={this.props.applyHover}
                        ></Label>);
                    })
                }
            </div>
        )
    }
}
export default withLabelsList(LabelsList)