import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router";
import {
  ShippingReportTable,
  HorizontalScrollButtons,
} from "@common/components";
import { Constantes } from "@common/utils";
import ShippingSelectReport from "@common/components/src/shipping/ShippingCostReport/shippingSelectReport";
import { ShippingUtils } from "@common/utils/";

export const ShippingCostReport = (props) => {
  const { storeType } = props;
  const isBrand = storeType === Constantes.StoreType.BRAND ? true : false;
  const [element, setElement] = useState(null);
  
  useEffect(() => {
    if (!props.isLoading) {
      const element = document.getElementsByClassName("rt-table")[0];
      setElement(element);
    }
  }, [props.isLoading]);

  const isLoading = [props.isLoading, props.isLoadingShippingCost].includes(true);
  return (
    <div>
      {props.data?.length ? (
        <div className="shipping-cost-report">
          <Row>
            <Col md={12} sm={12} className="container-title-cost">
              <div className="titles-container">
                <span className="title">
                  Competitividad de Shipping por Marca
                </span>
                <span className="subtitle">
                  Elige los datos que necesitas evaluar, puedes ver el costo
                  promedio y el envío gratis de cada región, comuna y competidor
                </span>
              </div>
              <ShippingSelectReport props={props} />
            </Col>
          </Row>

          <Row>
            <Col md={12} sm={12} id="table">
              {isLoading ? (
                ShippingUtils.showSkeletonForReport(isLoading)
              ) : (
                <>
                <HorizontalScrollButtons element={element} isBrand={isBrand}/>
                  <ShippingReportTable
                    columns={props.columns}
                    data={props.data}
                    showPagination={false}
                    minRows={0}
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      ) : (
        ShippingUtils.showSkeletonForReport(false)
      )}
    </div>
  );
};

export default withRouter(ShippingCostReport);

