import React, { Component } from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import CloseIcon from "mdi-react/CloseIcon";
import { translate } from "react-i18next";
import { isNumber, orderBy, isEqual } from "lodash";
import { Row, Col } from "reactstrap";

const RangePropType = PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
    count: PropTypes.number,
    order: PropTypes.number.isRequired,
});

class TextFieldRange extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        placeholderFrom: PropTypes.string,
        placeholderTo: PropTypes.string,
        from: PropTypes.number,
        to: PropTypes.number,
        ranges: PropTypes.arrayOf(RangePropType),
        maxLength: PropTypes.number,
        onChangeListener: PropTypes.func,
        applyFilterListener: PropTypes.func.isRequired,
        removeFilterListener: PropTypes.func.isRequired,
        withExtraInformation: PropTypes.bool,
        showInputs: PropTypes.bool,
    };

    static defaultProps = {
        showInputs: true,
    };

    constructor(props) {
        super(props);
        this.from = React.createRef();
        this.to = React.createRef();

        this.state = {
            isFilterDefault: true,
            isFilterApplied: true,
            isFilterSelected: false,
            from: null,
            to: null,
            filterSelected: {},
            isOtherEvent: false,
            isRemoveEvent: false,
            isFilter: false,
        };
        this.applyFilter = this.applyFilter.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.state.isFilter) {
            this.setState({ isFilterApplied: true, isFilter: false });
            return;
        }
        let prevFrom = this.convertToNumber(prevProps.from);
        let from = this.convertToNumber(this.props.from);
        let prevTo = this.convertToNumber(prevProps.to);
        let to = this.convertToNumber(this.props.to);

        if (!isEqual(from, prevFrom) && !isEqual(to, prevTo)) {
            this.setState({
                isFilterApplied: true,
                isFilterDefault: !from && !to,
                from: from,
                to: to,
                filterSelected: { from: from, to: to },
                isOtherEvent: true,
            });
        } else if (!isEqual(from, prevFrom)) {
            this.setState({
                isFilterApplied: true,
                isFilterDefault: !from && !to,
                from: from,
                filterSelected: { from: from, to: to },
                isOtherEvent: true,
            });
        } else if (!isEqual(to, prevTo)) {
            this.setState({
                isFilterApplied: true,
                isFilterDefault: !from && !to,
                to: to,
                filterSelected: { to: to },
                isOtherEvent: true,
            });
        }

        if (prevFrom && !from) {
            this.from.current.state.value = "";
            this.from.current.state.numAsString = "";
        }

        if (prevTo && !to) {
            this.to.current.state.value = "";
            this.to.current.state.numAsString = "";
        }

        if (this.state.isRemoveEvent) {
            this.setState({ isFilterDefault: true, isRemoveEvent: false });
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    onChangeEventFrom = (values) => {
        if (this.state.isOtherEvent) {
            this.setState({ isOtherEvent: false });
            return;
        }
        const from = values.floatValue;
        this.setState({
            isFilterDefault: false,
            isFilterApplied: false,
            from: from,
        });
        if (this.props.onChangeListener) {
            this.props.onChangeListener(from, this.state.to);
        }
    };

    onChangeEventTo = (values) => {
        if (this.state.isOtherEvent) {
            this.setState({ isOtherEvent: false });
            return;
        }
        const to = values.floatValue;
        this.setState({
            isFilterDefault: false,
            isFilterApplied: false,
            to: to,
        });
        if (this.props.onChangeListener) {
            this.props.onChangeListener(this.state.from, to);
        }
    };

    getValue(stateComponent) {
        const { numAsString } = stateComponent;
        if (numAsString) {
            return numAsString;
        }
        return null;
    }

    formatThousandsSeparators(value) {
        if (!isNumber(value)) {
            return "-";
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    handleClickFilter = (range) => {
        let { from, to } = range;
        if (range.from && !range.to) {
            to = null;
        } else if (range.to && !range.from) {
            from = null;
        }
        this.setState({
            isFilterApplied: true,
            isFilterDefault: false,
            from: from,
            to: to,
            filterSelected: range,
            isOtherEvent: true,
        });
        if (this.props.applyFilterListener) {
            this.props.applyFilterListener(from, to);
        }
    };

    onKeyUpEvent = (e) => {
        const event = e.nativeEvent;
        if (event.keyCode === 13) {
            event.preventDefault();
            this.applyFilter();
        }
    };

    applyFilter = () => {
        if (!this.props.applyFilterListener) {
            return;
        }
        const { from, to } = this.state;

        if (
            from &&
            to &&
            this.convertToNumber(from) > this.convertToNumber(to)
        ) {
            this.setState({
                isFilterApplied: true,
                from: to,
                to: from,
                filterSelected: {
                    from: this.convertToNumber(to),
                    to: this.convertToNumber(from),
                },
                isOtherEvent: true,
                isFilter: true,
            });
            this.props.applyFilterListener(to, from);
        } else if (!from && !to) {
            this.setState({
                isFilterApplied: true,
                isFilterDefault: true,
                filterSelected: {
                    from: this.convertToNumber(from),
                    to: this.convertToNumber(to),
                },
                isFilter: true,
            });
            this.props.applyFilterListener(from, to);
        } else {
            this.setState({
                isFilterApplied: true,
                filterSelected: {
                    from: this.convertToNumber(from),
                    to: this.convertToNumber(to),
                },
                isFilter: true,
            });
            this.props.applyFilterListener(from, to);
        }
    };

    convertToNumber(value) {
        if (!value) {
            return null;
        }
        if (value === "0") {
            return 0;
        }
        return Number(value);
    }

    removeFilter = () => {
        if (!this.props.removeFilterListener) {
            return;
        }
        this.setState({
            isFilterDefault: true,
            isFilterApplied: true,
            from: null,
            to: null,
            filterSelected: {},
            isRemoveEvent: true,
        });
        this.props.removeFilterListener(null, null);
    };

    isEqualFilter(range, rangeSelected) {
        let { from, to } = range;
        if (!from) {
            from = null;
        }
        if (!to) {
            to = null;
        }
        return (
            ((!from && !rangeSelected.from) ||
                isEqual(from, rangeSelected.from)) &&
            ((!to && !rangeSelected.to) || isEqual(to, rangeSelected.to))
        );
    }

    buildRange(range) {
        const { withExtraInformation } = this.props;
        if (range.from && range.to) {
            return `${this.formatThousandsSeparators(
                range.from
            )} - ${this.formatThousandsSeparators(range.to)} ${
                withExtraInformation ? "(" + range.count + ")" : ""
            }`;
        } else if (range.from) {
            return `Desde ${this.formatThousandsSeparators(range.from)} ${
                withExtraInformation ? "(" + range.count + ")" : ""
            }`;
        } else if (range.to) {
            return `Hasta ${this.formatThousandsSeparators(range.to)} ${
                withExtraInformation ? "(" + range.count + ")" : ""
            }`;
        } else {
            return "";
        }
    }

    buildRangesForSelect(ranges) {
        return (
            <div className="group-range-for-filter">
                {orderBy(ranges, ["order"], ["asc"]).map((range, index) => {
                    const isFilter = this.isEqualFilter(
                        range,
                        this.state.filterSelected
                    );
                    let classLabel = "range-label";
                    let classBackground = "range-background";
                    if (isFilter) {
                        classLabel += " range-label-selected";
                        classBackground = "range-background-selected";
                    }
                    const rangeForView = this.buildRange(range);

                    return (
                        <div key={index} className={classBackground}>
                            <div
                                className={classLabel}
                                onClick={() => {
                                    this.handleClickFilter(range);
                                }}
                            >
                                {rangeForView}
                            </div>
                            {isFilter ? (
                                <button
                                    className="close"
                                    onClick={() => {
                                        this.removeFilter();
                                    }}
                                >
                                    <CloseIcon color="#999999" size="18px" />
                                </button>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { label, ranges, maxLength, placeholderFrom, placeholderTo } =
            this.props;
        const { from, to } = this.state;
        const size = maxLength ? maxLength : 13;
        let colorTextField = "text-field-retail";
        if (this.state.isFilterDefault) {
            colorTextField += " text-field-color-default";
        }

        return (
            <div style={{ width: "100%" }}>
                <Row>
                    <Col xs={10} className="main-label-range">
                        {label}
                    </Col>

                    {!this.state.isFilterDefault ? (
                        <Col xs={2} className="text-right">
                            <button type="button" className="close">
                                <CloseIcon
                                    onClick={this.removeFilter}
                                    className="close"
                                    size={18}
                                />
                            </button>
                        </Col>
                    ) : null}
                </Row>

                {this.props.showInputs && (
                    <div className="counter-group-container group-range">
                        <div className="counter-group-item">
                            <div className="counter-item-label">Desde</div>
                            <NumberFormat
                                ref={this.from}
                                maxLength={size}
                                placeholder={placeholderFrom}
                                thousandSeparator="."
                                decimalSeparator=","
                                className={colorTextField}
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={0}
                                value={from}
                                onValueChange={this.onChangeEventFrom}
                                onKeyPress={this.onKeyUpEvent}
                            />
                        </div>
                        <div className="minus-for-range"></div>
                        <div className="counter-group-item">
                            <div className="counter-item-label">Hasta</div>
                            <NumberFormat
                                ref={this.to}
                                maxLength={size}
                                placeholder={placeholderTo}
                                thousandSeparator="."
                                decimalSeparator=","
                                className={colorTextField}
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={0}
                                value={to}
                                onValueChange={this.onChangeEventTo}
                                onKeyPress={this.onKeyUpEvent}
                            />
                            {!this.state.isFilterApplied ? (
                                <div
                                    className="apply-filter-range"
                                    onClick={() => this.applyFilter()}
                                >
                                    Aplicar
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}

                {ranges && ranges.length > 0
                    ? this.buildRangesForSelect(ranges)
                    : null}
            </div>
        );
    }
}

export default translate("common")(TextFieldRange);
