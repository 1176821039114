import { getAsJson } from './AbstractService';
import { ConstantesApi } from '@common/utils';

export const fetchCommunesNames = async (params) => {
    const ids = params.data.map(commune => `&communeIds=${commune.id}`); 
    const list = ids.join('');
    
    return getAsJson({
        api: `${ConstantesApi.API_SHIPPING_CATALOG_PATH}${list}`,
        ...params
    });
};