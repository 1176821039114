import React, { memo } from 'react';
import { connect } from 'react-redux';
import TableView from './TableView'
import { TableContainer } from '@common/components/';
import { Constantes } from '@common/utils';
import * as HeadersBuilder from './HeadersBuilder';
import * as CellsBuilder from './CellsBuilder';

const buildBaseHeaders = (props) => {
    const { currentFilter, paginationCount } = props;
    let currentSort = currentFilter.sort || Constantes.DEFAULT_SORT_BRAND;

    const headers = [
        {
            key: Constantes.SortKeys.PRODUCT_RELEVANCE,
            name: 'Productos',
            // we ignore the sort of react-table because we have a custom sort
            sortable: false,
            // sort props
            headerClassName: 'custom-table-header-column-sortable custom-table-header-mini-product-sheet',
            sortDirection: currentSort.sortDirection,
            // the name is different because `sortable` is reserved by react-table
            isSortable: false,
            className: 'header-producto',
            resizable: false,
            accessor: "product",
            width: 570,
            fixed: 'left',
            Header: (headerProps) => HeadersBuilder.buildProductHeader(headerProps, currentSort, paginationCount),
            Cell: (row) => CellsBuilder.buildProductCell(row, currentFilter, { showRankingInfo: false })
        }
    ];

    return headers;
}

export const mapStateToProps = (state, ownProps) => {
    let commonProps = TableContainer.mapStateToProps(state, ownProps);
    let baseHeaders = buildBaseHeaders(commonProps);
    let retailsHeaders = TableContainer.buildRetailersHeaders(commonProps.retailItems, commonProps.currentFilter, commonProps.activeRanking);
    return {
        ...commonProps,
        headers: baseHeaders.concat(retailsHeaders),
    }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    let commonFuncts = TableContainer.mapDispatchToProps(dispatch, ownProps);
    return {
        ...commonFuncts
    };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(TableView));
