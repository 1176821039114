import CounterView from './CounterView';
import { connect } from 'react-redux';
import { Utils } from '@common/utils';
import { fetchProductItems } from '../table/TableState';

const mapStateToProps = (state, ownProps) => {
    let currentFilter = Utils.extractCurrentProductListFilter(state);

    return {
        labelHaveIt: 'PRODUCTOS EN TOTAL',
        labelHaveItSingular: 'PRODUCTO EN TOTAL',
        labelMatches: 'MATCHES EN TU TIENDA',
        labelMatchesSingular: 'MATCH EN TU TIENDA',
        myAggregations: state.productListBrandReducer.myAggregations,
        total: state.productListBrandReducer.total,
        currentFilter: currentFilter,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params) => fetchProductItems(dispatch, params),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CounterView);