import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Constantes, Utils, GaUtils } from "@common/utils/";
import HistoricSummary from './HistoricSummary';
import CustomizedChart from './CustomizedChart';
import { FilterRangeDate } from '../common/components/FilterRangeDate';

export default class HistoricDatedChart extends PureComponent {

    static propTypes = {
        title: PropTypes.string,
        startDate: PropTypes.object.isRequired,
        endDate: PropTypes.object.isRequired,
        onDateFilter: PropTypes.func.isRequired,
        onPriceFilter: PropTypes.func.isRequired,
        rightSummary: PropTypes.object,
        leftSummary: PropTypes.object,
        chart: PropTypes.object.isRequired,
    };

    static defaultProps = {
        title: "",
        leftSummary: {},
        chart: { emptyHistory: true }
    }

    constructor(props) {
        super(props);
        this.state = {
            toggleButtonFirstTrackIgnored: false
        };
        this.onChangedToggleButton = this.onChangedToggleButton.bind(this);
        this.onChangeViewPrice = this.onChangeViewPrice.bind(this);
    }

    computeRightSummaryContainerClass() {
        let { rightSummary, leftSummary } = this.props

        if (rightSummary && rightSummary.forceBorder) {
            return '';
        }

        return (!Utils.isObjectWithData(leftSummary) ? 'no-border' : '');
    }

    onChangedToggleButton(value) {
        this.props.onPriceFilter(value);
        if (this.state.toggleButtonFirstTrackIgnored) {
            this.trackGA(!!value ? Constantes.GA_LABELS_EVENTS.COMPARE.ON : Constantes.GA_LABELS_EVENTS.COMPARE.OFF);
        } else {
            this.setState({ toggleButtonFirstTrackIgnored: true });
        }
    }

    onChangeViewPrice(itemSelected, props) {
        let eventLabel = itemSelected.value.join("&").toLowerCase();
        this.trackGA(eventLabel);
        this.props.onChangeViewPrice(itemSelected, props);
    }

    trackGA(label) {
        GaUtils.trackGAEvent(this.props.categoryEvents, Constantes.GA_ACTION_EVENTS.PRICES, label, null, null);
    }

    render() {
        const { rightSummary, leftSummary, startDate, endDate, onDateFilter } = this.props
        const ViewPricesComponent = this.props.viewPricesComponent;

        return (<div>
            <div className="historic-title">{this.props.title}</div>
            <div className="historic-header">
                <FilterRangeDate
                    startDate={startDate}
                    endDate={endDate}
                    onDateFilter={onDateFilter}
                    categoryEvent={this.props.categoryEvents}
                />

                <div className="historic-counts">
                    <div>
                        {Utils.isObjectWithData(leftSummary) &&
                            <div className={"historic-header--section "}>
                                <HistoricSummary
                                    historicPrice={leftSummary.historicData.price}
                                    historicStore={leftSummary.historicData.storeName}
                                    historicDate={leftSummary.historicData.date}
                                    historicIsMine={leftSummary.historicData.isMyProduct}
                                    historicIsSeller={leftSummary.historicData.isSeller}
                                    historicSellerName={leftSummary.historicData.sellerName}
                                    title="Retailers seleccionados"
                                    periodPrice={leftSummary.periodData.price}
                                    periodStore={leftSummary.periodData.storeName}
                                    periodDate={leftSummary.periodData.date}
                                    periodIsMine={leftSummary.periodData.isMyProduct}
                                    periodIsSeller={leftSummary.periodData.isSeller}
                                    periodSellerName={leftSummary.periodData.sellerName}
                                    period={"fechas seleccionadas"}
                                    isFetchingPeriod={leftSummary.isFetchingPeriod}
                                    isFetchingHistoric={leftSummary.isFetchingHistoric}
                                    periodIsCardPrice={leftSummary.periodData.isCardPrice}
                                    historicIsCardPrice={leftSummary.historicData.isCardPrice}
                                />
                            </div>
                        }
                        {Utils.isObjectWithData(rightSummary) &&
                            <div className={"historic-header--section "}>
                                <HistoricSummary
                                    containerClassName={this.computeRightSummaryContainerClass()}
                                    historicTitle={rightSummary.title}
                                    historicPrice={rightSummary.historicData.price}
                                    historicStore={rightSummary.historicData.storeName}
                                    historicDate={rightSummary.historicData.date}
                                    historicIsMine={rightSummary.historicData.isMyProduct}
                                    historicIsSeller={rightSummary.historicData.isSeller}
                                    historicSellerName={rightSummary.historicData.sellerName}
                                    title="Todos los retailers"
                                    periodPrice={rightSummary.periodData.price}
                                    periodStore={rightSummary.periodData.storeName}
                                    periodDate={rightSummary.periodData.date}
                                    periodIsMine={rightSummary.periodData.isMyProduct}
                                    periodIsSeller={rightSummary.periodData.isSeller}
                                    periodSellerName={rightSummary.periodData.sellerName}
                                    period={"fechas seleccionadas"}
                                    isFetchingPeriod={rightSummary.isFetchingPeriod}
                                    isFetchingHistoric={rightSummary.isFetchingHistoric}
                                    periodIsCardPrice={rightSummary.periodData.isCardPrice}
                                    historicIsCardPrice={rightSummary.historicData.isCardPrice}
                                />
                            </div>
                        }
                    </div>
                    {ViewPricesComponent &&
                        <div className="price-view ml-auto">
                            <ViewPricesComponent
                                {...this.props}
                                onChangeViewPrice={this.onChangeViewPrice}
                            ></ViewPricesComponent>
                        </div>
                    }
                </div>
            </div>
            <div className="historic-chart-container">
                <CustomizedChart
                    key={this.props.chart.key}
                    chartSeries={this.props.chart.series}
                    isFetchingHistoricData={this.props.chart.isFetchingHistoricData}
                    emptyHistory={this.props.chart.emptyHistory}
                    tooltipFormatter={this.props.chart.tooltipFormatter}
                    tooltipClassName={this.props.chart.tooltipClassName}
                />
            </div>
        </div>)
    }
}
