import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import LabelGroup from '@common/components/src/ui/LabelGroup';
import { Constantes, Utils } from '@common/utils/'
import { UncontrolledTooltip } from 'reactstrap';

export default class ProductCell extends PureComponent {

    static propTypes = {
        product: PropTypes.object.isRequired,
        storeType: PropTypes.string.isRequired,
        isProductDetail: PropTypes.bool,
        tooltipText: PropTypes.string,
        tooltipDelay: PropTypes.object
    };

    static defaultProps = {
        isProductDetail: false,
        tooltipText: '',
        tooltipDelay: { show: 1500 }
    };

    constructor(props) {
        super(props);

        this.state = {
            randomId: Utils.generateRandomId({ prefix: "tooltip", connector: "-" }),
        };

        this.productMarkerRef = React.createRef();
        this.calculatePublished = this.calculatePublished.bind(this);

        this.renderProductDetailStrategyRetail = this.renderProductDetailStrategyRetail.bind(this);
        this.renderProductDetailStrategyBrand = this.renderProductDetailStrategyBrand.bind(this);

        this.getTooltip = this.getTooltip.bind(this);

        this.renderProductDetailStrategies = {
            [Constantes.StoreType.RETAILER]: this.renderProductDetailStrategyRetail,
            [Constantes.StoreType.BRAND]: this.renderProductDetailStrategyBrand
        }
    }

    calculatePublished() {
        const { product } = this.props;
        if (!product || !product.created) {
            return null;
        }

        let daysText = "";

        if (this.props.isProductDetail) {
            daysText = " en " + product.storeName;
        }

        const days = Utils.getDaysFromToday(product.created);

        if (days === 0) {
            return 'Desde hoy' + daysText;
        } else if (days === 1) {
            return days + ' día' + daysText;
        }
        return days + ' días' + daysText;
    }

    renderProductList() {
        if (this.props.isProductDetail) {
            return null;
        }
        const { product } = this.props;
        return (<div className="product-cell-product-list">
            <div className='product-cell-product-name'>
                {product.name}
            </div>
            <div className='product-cell-product-brand'>
                {product.brand}
            </div>
            <div className="product-cell-product-sku-published-container">
                <div className={'product-cell-product-sku'}>
                    {product.sku ? 'SKU: ' + product.sku : ''}
                </div>
                <div className='product-cell-product-published'>
                    {this.calculatePublished()}
                </div>
            </div>
        </div>);
    }

    getTooltip(tooltipText, tooltipDelay, target, showTooltip = false) {
        if (tooltipText.length > 76 && showTooltip) {
            return <UncontrolledTooltip placement='top' delay={tooltipDelay} target={target} container={'inline'}>
                {tooltipText}
            </UncontrolledTooltip>
        }

        return null;
    };

    renderProductDetailStrategyBrand() {
        if (!this.props.isProductDetail) {
            return null;
        }
        const { product, tooltipText, tooltipDelay } = this.props;

        return (<div className="product-cell-product-detail brand-module">
            <div className='product-cell-product-name' id={this.state.randomId}>
                {product.name}
                {this.getTooltip(tooltipText, tooltipDelay, this.state.randomId, true)}
            </div>
            <div className='product-cell-product-model'>
                Modelo: {product.model}
            </div>
            <div className='product-cell-product-sku'>
                {product.sku ? 'SKU: ' + product.sku : ''}
            </div>
            <div className='product-cell-product-published'>
                {this.calculatePublished()}
            </div>

        </div>);
    }

    renderProductDetailStrategyRetail() {
        if (!this.props.isProductDetail) {
            return null;
        }
        const { product, tooltipText, tooltipDelay } = this.props;
        return (<div className="product-cell-product-detail retail-module">
            <div className={'product-cell-product-sku ' + (product.isMyProduct ? 'product-cell-product-sku--bold' : '')}>
                {'SKU: ' + product.sku}
            </div>
            <div className='product-cell-product-model'>
                {'Modelo: ' + product.model}
            </div>
            <div className='product-cell-product-name' id={this.state.randomId}>
                {product.name}
                {this.getTooltip(tooltipText, tooltipDelay, this.state.randomId, true)}
            </div>
            <div className='product-cell-product-published'>
                {this.calculatePublished()}
            </div>

        </div>);
    }

    renderProductDetail() {
        if (!this.props.isProductDetail) {
            return null;
        }
        const { storeType } = this.props;
        const renderContent = this.renderProductDetailStrategies[storeType];
        return renderContent && renderContent();
    }

    render() {
        const { product, isProductDetail } = this.props;
        return (
            <div className='product-cell-container'>
                <Row>
                    {!isProductDetail ?
                        <div className='product-cell-product-image'>
                            <img src={product.image} alt="" />
                        </div>
                        : null}
                    <div className='product-cell-product-summary'>

                        {this.renderProductList()}
                        {this.renderProductDetail()}

                        <div className='product-cell-product-labels'>
                            {product.labels && product.labels.length ?
                                <LabelGroup labelItems={product.labels} limit={Math.floor(Math.random() * product.labels.length) + 0} />
                                : null}
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}
