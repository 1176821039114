import { connect } from 'react-redux';
import { Utils } from '@common/utils';
import DetailedView from './index';
import * as CommonContainer from "../../common/common";

export const mapStateToProps = (state, ownProps) => {
    let commonProps = CommonContainer.mapStateToProps(state, ownProps);
    let currentFilter = Utils.extractCurrentProductListFilter(state);

    let newStores = commonProps.stores;
    if (Utils.isArrayWithData(currentFilter.stores)) {
        newStores = Utils.filterEntities(commonProps.stores, commonProps.lastFilter.stores, 'storeId');
    }

    return {
        ...commonProps,
        stores: newStores
    }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    let commonFuncts = CommonContainer.mapDispatchToProps(dispatch, ownProps);
    return {
        ...commonFuncts
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedView);
