import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import { UncontrolledTooltip } from "reactstrap";
import { Utils } from "@common/utils";

export default class FavoriteButton extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        onMouseOut: PropTypes.func,
        onMouseOver: PropTypes.func,
        isChecked: PropTypes.bool,
        tooltipText: PropTypes.string,
        checkedTooltipText: PropTypes.string,
        tooltipDelay: PropTypes.object,
        favoriteObject: PropTypes.object.isRequired
    };

    static defaultProps = {
        onClick: () => {},
        onMouseOut: () => {},
        onMouseOver: () => {},
        isChecked: false,
        tooltipText: "",
        checkedTooltipText: "",
        tooltipDelay: { show: 1500 }
    };

    constructor(props) {
        super(props);
        this.state = {
            randomId: Utils.generateRandomId({ prefix: "fav", connector: "-" }),
            isChecked: this.props.isChecked
        };
        this.onFavoriteClick = this.onFavoriteClick.bind(this);
        this.onFavoriteMouseOver = this.onFavoriteMouseOver.bind(this);
        this.onFavoriteMouseOut = this.onFavoriteMouseOut.bind(this);
    }

    onFavoriteMouseOver(e) {
        e.stopPropagation();
        this.props.onMouseOver({
            ...this.props.favoriteObject,
            event: { ...e },
            componentId: this.state.randomId,
            isChecked: this.props.isChecked
        });
    }

    onFavoriteMouseOut(e) {
        e.stopPropagation();
        this.props.onMouseOut({
            ...this.props.favoriteObject,
            event: { ...e },
            componentId: this.state.randomId,
            isChecked: this.props.isChecked
        });
    }

    onFavoriteClick(e) {
        if (e) {
            e.stopPropagation();
        }
        let newState = { isChecked: !this.state.isChecked };
        this.setState(newState);
        this.props.onClick({
            ...this.props.favoriteObject,
            event: { ...e },
            componentId: this.state.randomId,
            ...newState
        });
    }

    render() {
        const {
            id,
            tooltipText,
            tooltipDelay,
            checkedTooltipText
        } = this.props;
        return (
            <div
                itemProp={id}
                className={
                    "favorite-button " + (this.state.isChecked ? "checked" : "")
                }
                id={this.state.randomId}
                onClick={this.onFavoriteClick}
                onMouseOver={this.onFavoriteMouseOver}
                onMouseOut={this.onFavoriteMouseOut}
            >
                {tooltipText.length > 0 && !this.state.isChecked ? (
                    <StarBorder
                        className="star star--border"
                        id={this.state.randomId + "-border"}
                    />
                ) : null}
                {tooltipText.length > 0 && !this.state.isChecked ? (
                    <UncontrolledTooltip
                        boundariesElement={"window"}
                        placement="top"
                        delay={tooltipDelay}
                        target={this.state.randomId + "-border"}
                    >
                        {tooltipText}
                    </UncontrolledTooltip>
                ) : null}

                {checkedTooltipText.length > 0 && this.state.isChecked ? (
                    <Star
                        className="star star--filled"
                        id={this.state.randomId + "-filled"}
                    />
                ) : null}
                {checkedTooltipText.length > 0 && this.state.isChecked ? (
                    <UncontrolledTooltip
                        boundariesElement={"window"}
                        placement="top"
                        delay={tooltipDelay}
                        target={this.state.randomId + "-filled"}
                    >
                        {checkedTooltipText}
                    </UncontrolledTooltip>
                ) : null}
            </div>
        );
    }
}
