import { Constantes, PostpaidDB } from '..';
import {
    sortBy, uniqWith, isEqual, unionWith, orderBy, findKey, find, flatten, differenceWith, minBy, isNumber, map, some, isEmpty, uniq, isNil
} from 'lodash';
import * as RouterUtils from './routerUtils'
import * as RetailCompassStorage from '../app/Storage'
import * as Utils from './utils'

export const currentCategoryIsOperator = (user) => {
    const currentCategory = RetailCompassStorage.getCurrentCanonicalCategory(user);
    if (currentCategory && currentCategory.key) {
        return RouterUtils.isCategoryTypeOperator(currentCategory.key);
    } else {
        return false;
    }
}

export const mapStores = (aggregations, formatStores, stateStores) => {
    const stores = unionStores(formatStores, stateStores);
    return stores.map((storeItem) => mapOperatorAttributes(storeItem));
}

const unionStores = (oldItems, newItems) => {
    let items = unionWith(oldItems, newItems, isSameStore);
    return uniqWith(items, isSameStore);
}

const isSameStore = (item1, item2) => {
    const idItem1 = buildOperatorKey(item1);
    const idItem2 = buildOperatorKey(item2);
    return idItem1 === idItem2;
}

const mapOperatorAttributes = (store) => {
    store.visibleOperatorName = store.operator
        ? store.operatorName
        : store.operatorName + " " + store.name;
    store.operatorKey = buildOperatorKey(store);
    return store;
}

export const sortStoreAggregations = (storeAggregations) => {
    const ORDER_OPERATORS = RetailCompassStorage.getIncludeOperators();
    const myStoreId = RetailCompassStorage.getStoreId();

    const sortByIndex = sortBy(storeAggregations, function (store) {
        return ORDER_OPERATORS.indexOf(buildOperatorKey(store));
    });
    const sortById = sortBy(sortByIndex, function (store) {
        return myStoreId === parseInt(store.storeId) ? 0 : 1;
    });

    return sortById;
}

export const buildInitialOrderMap = (stores) => {
    let initialOrderMap = {};
    stores.forEach((store) => {
        initialOrderMap[buildOperatorKey(store)] = store.initialOrder;
    })
    return initialOrderMap;
}

export const orderByInitialOrder = (stores, initialOrderMap) => {
    if (initialOrderMap && Utils.isArrayWithData(Object.keys(initialOrderMap))) {
        return orderBy(stores, (store) => {
            return initialOrderMap[buildOperatorKey(store)];
        }, 'asc');
    }
    return stores;
}

export const buildOperatorKey = (store) => {
    return store.storeId + "-" + store.operatorName;
}

export const getKeyValueContractByLabel = (label) => {
    const key = findKey(Constantes.TYPE_CONTRACTS, { 'label': label });
    return key ? Constantes.TYPE_CONTRACTS[key].key : null;
}

export const getLabelByContractKey = (key) => {
    const _key = findKey(Constantes.TYPE_CONTRACTS, { 'key': key });
    return _key ? Constantes.TYPE_CONTRACTS[_key].label : null;
}

export const getRangesForFilterRangePlanPrice = () => {
    return [
        { from: null, to: 9999, count: 0, order: 1 },
        { from: 10000, to: 14999, count: 0, order: 2 },
        { from: 15000, to: 19999, count: 0, order: 3 },
        { from: 20000, to: 24999, count: 0, order: 4 },
        { from: 25000, to: 29999, count: 0, order: 5 },
        { from: 30000, to: null, count: 0, order: 6 }
    ]
}

export const getValuesFromMatchAttributes = (matchAttributeType) => {
    let values = [];
    const matchAttributes = RetailCompassStorage.getCurrentCategoryMatchAttributes();
    const attribute = matchAttributes
        .filter(i => i.id === matchAttributeType)
        .shift();
    if (attribute) {
        const visualization = JSON.parse(attribute.visualization);
        values = visualization.listingStrategy.values
            .sort((a, b) => {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
            })
            .map((item) => {
                return {
                    ...item,
                    id: item.value.replace(/\s/g, "_").toLowerCase(),
                    label: item.value,
                }
            })
    }
    return values;
}

export const getOperatorForFilter = (operators) => {
    if (!Utils.hasValue(operators)) return [];
    return operators.map(operator => {
        const items = operator.split("-");
        const label = Utils.capitalize(items[1]) + (items.length > 2
            ? " " + Utils.capitalize(items[2])
            : "");
        const value = items[0] + "-" + items[1];
        return { label: label, value: value, queryParam: operator }
    });
}

export const getModelsAndStorageForFilter = (postpaidSearchOptions) => {
    let ModelsAndStorageItems = [];
    postpaidSearchOptions.forEach(_model => {
        const model = _model.model;
        if (model !== "") {
            _model.storages.forEach(_storage => {
                const storage = _storage.storage;
                const isMyProduct = _storage.stores
                    .includes(RetailCompassStorage.getStoreId());

                ModelsAndStorageItems.push({
                    value: model + "|" + storage + "|" + isMyProduct,
                    label: model + " - " + storage,
                })
            });
        }
    });
    return ModelsAndStorageItems;
}

export const getModelsForRequest = (modelsParam) => {
    if (!Utils.isArrayWithData(modelsParam)) return null;

    let models = [];
    modelsParam.forEach(m => {
        let parts = m.value.split("|");
        let model = parts[0];
        let storage = parts[1];

        let item = models.find(el => el.model === model);
        if (item === undefined) {
            models.push({ model, storages: [storage] });
        } else {
            item.storages.push(storage);
        }
    });
    return { payload: { models }, strategy: 'POSTPAID' };
}

export const isMyCanonicalProduct = (canonicalProduct, storeId) => {
    if (Utils.isArrayWithData(canonicalProduct.directMatchedStores)) {
        return canonicalProduct.directMatchedStores.includes(storeId);
    } else {
        return false;
    }
}

export const isMyCanonicalProductBrand = (match, myBrandIds = []) => {
    if (!Utils.isObjectWithData(match)) return false;
    return myBrandIds.includes(match.parentBrandId);
}

export const isMyProduct = (match) => {
    const storeId = RetailCompassStorage.getStoreId();
    return match.storeId == storeId;
}

export const isMyProductBrand = (match, myBrandIds = []) => {
    if (!Utils.isObjectWithData(match)) return false;
    return myBrandIds.includes(match.brandParentId);
}

export const filterByStore = (competitorsProducts, storeId, operatorKey) => {
    let products = competitorsProducts
        .filter(e => isEqual(e.storeId, parseInt(storeId)))
        .filter(e => e.isOperator !== 1 ? isEqual(operatorKey, e.operatorKey) : true)
        .filter(e => Utils.isArrayWithData(e.attributes[Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE]));
    return sortBy(products, ["daysWithoutStock", "planPrice", "price"]);
}

export const filterByOperators = (productos, stores, operators, postpaidOperators) => {
    let myOperators = getMyOperatorKeys(stores);
    return productos
        .filter(e => postpaidOperators.find(po => po.value === e.operatorKey) !== undefined || postpaidOperators.length === 0 || myOperators.indexOf(e.operatorKey) >= 0);
}

export const getValueAttribute = (product, attribute) => {
    return product.attributes && product.attributes[attribute] ? product.attributes[attribute][0] : null;
}

export const saveModelsSearch = (models) => {
    if (Utils.isArrayWithData(models)) {
        models.forEach(item => {
            const spl = item.value.split("|");
            const model = spl[0];
            const storage = spl[1];
            PostpaidDB.putSearch({ model, storage });
        });
    }
}

export const mapAndSortSuggestionOptions = async (models) => {
    if (!Utils.isArrayWithData(models)) return [];

    const searches = await PostpaidDB.getSearchesForSuggestions();
    const mappedModels = models.map(modelItem => {
        const splitArray = modelItem.value.split("|");
        const model = splitArray[0];
        const storage = splitArray[1];
        const isMyProduct = splitArray[2] == 'true';
        const search = find(searches, { model, storage });

        return {
            ...modelItem,
            model,
            storage,
            intStorage: parseInt(storage.match(/\d+/)[0]),
            searchCount: search && search.searchCount
                ? search.searchCount
                : 0,
            isMyProduct: isMyProduct,
        }
    });

    const orderByStorage = orderBy(mappedModels, ['intStorage'], ['asc']);
    const orderByModel = orderBy(orderByStorage, ['model'], ['asc']);
    return orderBy(orderByModel, ['searchCount'], ['desc']);
}

export const getProductsByMinPlanPrice = (products) => {
    if (!Utils.isArrayWithData(products)) {
        return [];
    }

    let items = products.filter(e => isNumber(e.planPrice));

    items = flatten(
        map(Constantes.TYPE_CONTRACTS, contrato => {
            let newList = items.filter(producto => isEqual(contrato.label, getValueAttribute(producto, Constantes.MATCH_ATTRIBUTES.CONTRACT_TYPE)));
            const withStock = some(newList, ['status', 1]);
            if (withStock) newList = newList.filter(e => isEqual(e.status, 1));
            return removeProductsDiferentsByStore(newList);
        })
    );

    return items;
}

export const removeProductsDiferentsByStore = (products) => {
    let stores = getStoreAndOperatorFromProducts(products);

    return flatten(
        stores.map(store => {
            let productByStore = filterByStore(products, store.storeId, store.operatorKey);
            productByStore = getDifferenceProductsByPrice(productByStore, "planPrice");
            return getDifferenceProductsByPrice(productByStore, "price");
        })
    );
}

const getStoreAndOperatorFromProducts = (products) => {
    let listStores = products.map(prod => {
        return {
            storeId: prod.storeId,
            operatorKey: prod.isOperator != 1 ? prod.operatorKey : null
        }
    });
    return uniqWith(listStores, isEqual);
}

const getDifferenceProductsByPrice = (products, price) => {
    return differenceWith(products, [minBy(products, [price])], function (a, b) { return a[price] > b[price] });
}

export const getColorsByStatus = (products) => {
    if (isEmpty(products)) {
        return [];
    }
    const withoutStock = some(products, ['status', 1]);
    let _products = products.filter(e => !withoutStock ? true : isEqual(e.status, 1))
        .filter(e => !isEmpty(e.attributes[Constantes.MATCH_ATTRIBUTES.COLOR]));
    return getColors(_products);
}

export const getColors = (products) => {
    if (isEmpty(products)) {
        return [];
    }
    return uniq(products.map(e => getValueAttribute(e, Constantes.MATCH_ATTRIBUTES.COLOR)));
}

export const getStoreTypeBasedInCategory = (storeType) => {
    if (currentCategoryIsOperator() && storeType !== Constantes.MODULES.VALIDATION_APP) {
        return isEqual(storeType, Constantes.StoreType.BRAND)
            ? Constantes.SubStoreType.BRAND_POSTPAID
            : Constantes.SubStoreType.RETAILER_POSTPAID
    }

    return storeType;
}

/*
    Se anexa de esta forma el atributo de COLOR para la categoria Postpago,
    ya que no se ve necesario configurarla desde la BD porque aun no se tiene pensado crear un filtro para este atributo.

    Se espera que en un futuro esto pueda cambiar.
*/
export const addColorAttribute = (attributes) => {
    if (!isNil(attributes)) {
        attributes.push({ id: 121, visualization: { visible: { value: true } } });
    }
}

export const getMyOperatorKeys = (stores) => {
    let currOperator = stores.filter(store => store.isYourRetail === true);
    if (Utils.isArrayWithData(currOperator))
        return currOperator.map(op => op.operatorKey);
    else
        return [];
}

export const isBrandOperator = () => {
    const storeType = RetailCompassStorage.getStoreType();
    return currentCategoryIsOperator() && isEqual(storeType, Constantes.StoreType.BRAND)
}

export const isRetailOperator = () => {
    const storeType = RetailCompassStorage.getStoreType();
    return currentCategoryIsOperator() && isEqual(storeType, Constantes.StoreType.RETAILER)
}