import { Utils, SubRouterState, Constantes, RetailCompassStorage } from '@common/utils';
import * as FavoriteState from '../../../favorite/FavoriteState'
import * as AlertModalState from '../../../alerts/components/AlertModal/AlertModalState'
import * as ButtonNewLabelActions from '../../../labels/label-new-button/ButtonNewLabelActions'
import * as LabelsState from '../../../labels/LabelsState'
import * as FavoriteActions from '../../../favorite/FavoriteActions'
import * as LabelsActions from '../../../labels/LabelsActions'
import * as TableState from '../../../table/TableState'
import * as TableActions from '../../../table/TableActions'
import * as ComparerActions from '../../../../../../app/brands/src/comparer/ComparerActions'

export const mapStateToProps = (state, ownProps) => {
    const selectedView = state.subRouterStateReducer.selectedView;
    let applicationData = {
        brands: state.productListBrandReducer.brands || [],
        stores: state.productListBrandReducer.stores || [],
        labels: state.labelStateReducer.labels || [],
        storeId: RetailCompassStorage.getStoreId()
    };

    let currentFilter = Utils.extractCurrentProductListFilter(state);
    
    let subReport = state.subRouterStateReducer.selectedSubReport;
    if (selectedView === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST || Constantes.TYPE_VIEW_RETAIL.SHIPPING) {
        subReport = null;
    } else if (selectedView === Constantes.TYPE_VIEW_RETAIL.REPORT) {
        currentFilter.paginationCurrentPage = 1;
    }
    return {
        paginationCount: state.productListBrandReducer.paginationCount || 0,
        shippingCount: state.productListBrandReducer.shippingCount || 0,
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory || {},
        currentRouterView: state.appConfigReducer.currentRouterView,
        reduxFormInitialValues: state.productListBrandReducer.reduxFormInitialValues || {},
        applicationData: applicationData,
        lastSearchRequest: state.productListBrandReducer.lastSearchRequest,
        alertModalState: state.alertModalStateReducer || {},
        emptyStateType: state.productListBrandReducer.emptyStateType,
        alternativeRequestSearchFilter: state.productListBrandReducer.alternativeRequestSearchFilter,
        currentFilter: currentFilter,
        hasProducts: state.productListBrandReducer.hasProducts,
        isFetching: state.productListBrandReducer.isFetching,
        lastFilter: state.productListBrandReducer.lastFilter,
        isDownloading: state.productListBrandReducer.isDownloading,
        favoriteProductItems: state.favoriteStateReducer.favoriteProductItems,
        favorite: state.favoriteStateReducer.favorite,
        visibleLabelsForm: state.buttonNewLabelStateReducer.visibleLabelsForm,
        labels: state.labelStateReducer.labels || [],
        mustForceProductListUpdate: state.productListBrandReducer.mustForceProductListUpdate,
        myAggregations: state.productListBrandReducer.myAggregations,
        total: state.productListBrandReducer.total,
        stores: state.productListBrandReducer.stores || [],
        backPressedNavigate: state.productListBrandReducer.backPressedNavigate || false,
        selectedView: selectedView,
        subReport: subReport,
        brands: state.productListBrandReducer.brands || [],
        activeRanking: state.productListBrandReducer.activeRanking,
        myStore: state.productListBrandReducer.myStore
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params) => TableState.fetchProductItems(dispatch, params),
        resetStoreAction: () => {
            dispatch(TableActions.resetStoreAction());
            dispatch(FavoriteActions.resetStoreAction());
            dispatch(LabelsActions.resetStoreAction());
            dispatch(ComparerActions.resetStoreAction());
        },
        downloadFile: (params) => TableState.downloadFile(dispatch, params),
        fetchFavorites: (receiveResponseCallback) => FavoriteState.fetchFavorites(dispatch, receiveResponseCallback),
        showAlertModal: () => AlertModalState.showAlertModal(dispatch),
        hideLabelsForm: () => dispatch(ButtonNewLabelActions.hideLabelsForm()),
        showLabelsForm: () => dispatch(ButtonNewLabelActions.showLabelsForm()),
        resetSelection: () => LabelsState.resetProductsLabel(dispatch),
        fetchListLabels: (params) => LabelsState.fetchListLabels(dispatch, params),
        buttonBackPressedNavigate: (wasPressed) => dispatch(TableActions.buttonBackPressedNavigate(!!wasPressed)),
        resetSubRouterState: () => {
            SubRouterState.resetSelectedView(dispatch);
            SubRouterState.subReportReset(dispatch);
            SubRouterState.reportReset(dispatch);
        },
        dispatchSubRouterState: (selectedView, report, subReport) => {
            SubRouterState.onSelectedView(dispatch, selectedView);
            SubRouterState.reportChange(dispatch, report);
            SubRouterState.subReportChange(dispatch, subReport);
        }
    };
};
