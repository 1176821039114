import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import ProgressCounter from "@common/components/src/ui/ProgressCounter";
import { RetailCompassStorage, GaUtils, Constantes } from "@common/utils/"
import { join, sumBy, max, orderBy } from 'lodash';

const TOTAL_MY_BRANDS = "TOTAL_MY_BRANDS";
const TOTAL_OTHER_BRANDS = "TOTAL_OTHER_BRANDS";

export default class ShareForStores extends PureComponent {
    static propTypes = {
        stores: PropTypes.array.isRequired,
        isFetching: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);

        this.goToProductList = this.goToProductList.bind(this);
    }

    calculateMaxSizeBar(stores) {
        let values = [];
        for (let i = 0; i < stores.length; i++) {
            values.push(sumBy(stores[i].aggregations.brandsShare, 'count'));
        }
        return max(values);
    }

    extractDetailBrandByStore(store, totalSizeBar) {
        const brandsShare = store.aggregations.brandsShare;
        let brands = [];
        if (!brandsShare || brandsShare.length == 0) {
            return {
                id: store.storeId,
                name: store.name,
                order: 0,
                brands: brands
            };
        }
        const myBrands = RetailCompassStorage.getBrandIds();
        let mapBrands = new Map();
        mapBrands.set(TOTAL_MY_BRANDS, { ids: [], count: 0 });
        mapBrands.set(TOTAL_OTHER_BRANDS, { ids: [], count: 0 });

        for (let i = 0; i < brandsShare.length; i++) {
            const brandId = brandsShare[i].brandId;
            const count = brandsShare[i].count;
            if (myBrands.includes(brandsShare[i].brandId)) {
                mapBrands.get(TOTAL_MY_BRANDS).ids.push(brandId);
                mapBrands.get(TOTAL_MY_BRANDS).count = mapBrands.get(TOTAL_MY_BRANDS).count + count;
            } else {
                mapBrands.get(TOTAL_OTHER_BRANDS).ids.push(brandId);
                mapBrands.get(TOTAL_OTHER_BRANDS).count = mapBrands.get(TOTAL_OTHER_BRANDS).count + count;
            }
        }
        const totalMybrands = mapBrands.get(TOTAL_MY_BRANDS).count;
        const totalOtherbrands = mapBrands.get(TOTAL_OTHER_BRANDS).count;
        const totalDefault = totalSizeBar - (totalMybrands + totalOtherbrands);
        const totalCalculate = totalMybrands + totalOtherbrands;

        let barMainClass = 'color-main-progress';
        if (totalOtherbrands === 0) {
            barMainClass += ' color-main-progress-radius';
        }

        brands = [
            { id: join(mapBrands.get(TOTAL_MY_BRANDS).ids), total: totalMybrands, barClassName: barMainClass, isMain: true, onlyForProgressBar: true, storeId: store.storeId },
            { id: join(mapBrands.get(TOTAL_OTHER_BRANDS).ids), total: totalOtherbrands, barClassName: 'color-secundary-progress', isMain: false, onlyForProgressBar: true, storeId: store.storeId },
            { id: "default", total: totalDefault, barClassName: 'color-default-progress progress-without-event', isMain: false, onlyForProgressBar: false },
        ];

        return {
            id: store.storeId,
            name: store.name,
            order: totalCalculate,
            brands: brands
        };
    }

    goToProductList(search) {
        this.props.history.push({
            pathname: 'list',
            search: search,
            state: { from: this.props.location }
        });
    }

    trackGA(totalProducts) {
        GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.DASHBOARD, Constantes.GA_ACTION_EVENTS.RETAIL_SHARE, RetailCompassStorage.getCurrentCanonicalCategory().name, totalProducts);
    }

    render() {
        const { stores } = this.props;
        if (!stores || stores.length == 0) {
            return null;
        }
        const totalSizeBar = this.calculateMaxSizeBar(stores);
        let storesForDraw = stores.map(value => this.extractDetailBrandByStore(value, totalSizeBar));
        storesForDraw = orderBy(storesForDraw, ['order'], ['desc']);
        return (
            <div>
                <div className='counter-label bold-title-share-retailer'>SHARE POR RETAILER</div>
                {storesForDraw.map((value, index) => {
                    const counterItems = value.brands;
                    if (!counterItems || counterItems.length == 0) {
                        return null;
                    }
                    const totalForCalculate = counterItems[0].total + counterItems[1].total;
                    let onClick = (item) => {
                        let search = 'stores=' + item.storeId;
                        if (item.isMain) {
                            search = search + '&brands=' + item.id;
                        }
                        this.trackGA(item.isMain ? item.total : totalForCalculate);
                        this.goToProductList(search);
                    };
                    return (
                        <ProgressCounter
                            key={index}
                            label={value.name}
                            total={totalSizeBar}
                            totalForCalculate={totalForCalculate}
                            counterItems={counterItems}
                            onClickItem={onClick} />
                    );
                })}
            </div>
        );
    }
}
