import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CreditCardIcon from "mdi-react/CreditCardOutlineIcon";
import { Constantes, Utils } from "@common/utils/";
import { SellerNameButton } from "../common/components/SellerNameButton";
import { feeAbbreviation } from "../common/components/product_detail/PriceUtils";
import { Ranking } from "../common/components/ranking/Ranking";
import { ShippingButton } from "../common/components/ShippingButton";
import { isEqual } from "lodash";
import { UncontrolledTooltip } from "reactstrap";

const ProductPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.number,
    isPriceWithCard: PropTypes.bool,
    shippingIncluded: PropTypes.bool,
    isOffer: PropTypes.bool,
    daysInMarket: PropTypes.number,
    daysWithoutStock: PropTypes.number,
    isSeller: PropTypes.bool,
    sellerName: PropTypes.string,
    sellerMatchesByStore: PropTypes.object,
    storeName: PropTypes.string,
    url: PropTypes.string,
    shippings: PropTypes.object,
}).isRequired; 

const ContainerClassNames = {
    CHEAPEST_OF_ALL_THE_RETAILER: "cheapest-of-all-the-retailers",
    CLOSEST_TO_THE_CHEAPEST_PRICE: "closest-to-the-cheapest-price",
    CLOSEST_TO_THE_MOST_EXPENSIVE_PRICE: "closest-to-the-most-expensive-price",
    MOST_EXPENSIVE_OF_ALL_THE_RETAILERS: "most-expensive-of-all-the-retailers",
    WITHOUT_STOCK: "without-stock",
    WITHOUT_SHIPPING: "without-shipping",
    NONE: "none"
};

const ContainerClassNamePropValues = [
    ContainerClassNames.CHEAPEST_OF_ALL_THE_RETAILER,
    ContainerClassNames.CLOSEST_TO_THE_CHEAPEST_PRICE,
    ContainerClassNames.CLOSEST_TO_THE_MOST_EXPENSIVE_PRICE,
    ContainerClassNames.MOST_EXPENSIVE_OF_ALL_THE_RETAILERS,
    ContainerClassNames.WITHOUT_STOCK,
    ContainerClassNames.WITHOUT_SHIPPING,
    ContainerClassNames.NONE
];

export default class PurchaseSummaryCard extends PureComponent {
    static propTypes = {
        product: ProductPropType,
        containerClassName: PropTypes.oneOf(ContainerClassNamePropValues)
            .isRequired,
        formatPrice: PropTypes.func.isRequired,
        containerProps: PropTypes.object,
        activeRanking: PropTypes.bool,
        selectedView: PropTypes.string,
        containerClassNameShipping: PropTypes.oneOf(ContainerClassNamePropValues).isRequired,
        currentFilter: PropTypes.object
    };

    static defaultProps = {};

    static ContainerClassNames = ContainerClassNames;

    constructor() {
        super();
        this.state = {};
        this.getStyleShipping = this.getStyleShipping.bind(this);
        this.hasShipping = this.hasShipping.bind(this);
    }

    hasShipping() {
        const { currentFilter } = this.props;
        if (!currentFilter) {
            return false;
        }
        return currentFilter.shippingType === Constantes.SHIPPING_TYPE.COST 
            || currentFilter.shippingType === null 
            || currentFilter.shippingType === undefined;
    }

    getStyleShipping(shippings) {
        if (!shippings) {
            return "product-without-shipping";
        }

        if(isEqual(shippings, {}) || shippings.status === 3 || shippings.status === 4){
            return "product-without-shipping";
        } else if (shippings.status === 2) {
            return "commune-without-shipping";
        }
        return "";
    }
    

    render() {
        const { product, containerProps, selectedView } = this.props;
        let { containerClassName } = this.props;
        let { containerClassNameShipping } = this.props;

        const withoutStock =
            ContainerClassNames.WITHOUT_STOCK === containerClassName;

        product.statusProduct = withoutStock
            ? Constantes.STATUS_PRODUCT.WITHOUT_STOCK
            : Constantes.STATUS_PRODUCT.WITH_STOCK;

        if (product.highlight) {
            containerClassName = containerClassName + " cell-highlight";
        }

        if (this.props.activeRanking) {
            containerClassName = containerClassName + " active-ranking";
        }

        let feeClassName = null;
        if (product.activeFee) {
            containerClassName = containerClassName + " active-fee";
            feeClassName = Utils.assignFeeClass(product.fee);
        }

        let containerClassConfig = {
            "purchase-summary-card-container": true
        };
        containerClassConfig[containerClassName] = true;

        let priceClass = classNames({
            price: true,
            clearfix: true
        });

        let priceContainerClass = classNames({
            "price-container": true
        });

        let _containerProps = { ...containerProps };
        if (_containerProps.onClick) {
            _containerProps.onClick = _containerProps.onClick.bind(
                undefined,
                product
            );
            containerClassConfig["cursor-pointer"] = true;
        }

        let containerClass = classNames(containerClassConfig);
        _containerProps.className = containerClass;

        let daysWithoutStock = Utils.daysWithoutStockToText(
            product.daysWithoutStock
        );
        const mkpKey =
            Utils.buildKey(Constantes.MKP_PREFIX, product.id, null, null) +
            Utils.getRandomInt(999);
        const sellers = product.sellerMatchesByStore
            ? product.sellerMatchesByStore
            : null;

        const shippingActive = selectedView === Constantes.TYPE_VIEW_RETAIL.SHIPPING ? true : false;
        let filter = ""
        if (shippingActive && this.hasShipping()) {
            filter = "active-cost";
        } else {
            filter = "active-shipment";
        }

        const WithoutStockShippingIcon = process.env.PUBLIC_URL + '/img/withoutStockShipping.svg';
        let withoutShipping = this.getStyleShipping(product.shippings);
        
        return (  
            <div {..._containerProps}>
                <div 
                    className={ (shippingActive ? "shipping-indicator-container" :
                        "marketplace-indicator-container" +
                        (this.props.activeRanking ? " active-ranking" : "") +
                        (this.props.activeFee ? " active-fee" : "") )
                    }
                    key={mkpKey}
                >
                    {product.isSeller && (
                        <div className={(shippingActive ? "shipping-with-marketplace" : "marketplace-button-indicator")}>
                            <SellerNameButton
                                id={mkpKey}
                                placement="right"
                                trigger="click"
                                iconName="seller-icon"
                                product={sellers}
                                storeName={product.storeName}
                                sellerName={product.sellerName}
                            />
                        </div>
                    )}
                </div>

                <div className={priceContainerClass + (shippingActive ? " active-shipping" : "")}>
                    <div className={priceClass + (withoutStock && shippingActive && product.isSeller ? " shipping-without-stock-and-seller" : (withoutStock && shippingActive ? " shipping-without-stock" : ""))}>
                        <span className="amount">
                            {product.price
                                ? this.props.formatPrice(product.price)
                                : "-"}
                        </span>
                        {product.isPriceWithCard && (
                            <span className="with-card icon">
                                {" "}
                                <CreditCardIcon size={12} />{" "}
                            </span>
                        )}
                    </div>
                </div>

                {withoutStock && !shippingActive && (
                    <div className={"stock-text-container"}>
                        <div className="without-stock-text">
                            {daysWithoutStock && <div>{daysWithoutStock}</div>}
                            <div>sin stock</div>
                        </div>
                    </div>
                )}

                {!withoutStock && product.offerPrice && (
                    <div className={"price-offer-container" + (shippingActive ? " active-shipping" : "")}>
                        {this.props.formatPrice(product.offerPrice)}
                    </div>
                )}

                {!withoutStock && this.props.activeRanking ? (
                    <div className={"ranking-container"}>
                        <Ranking product={product} />
                    </div>
                ) : (
                    product.discount && !shippingActive && (
                        <div className={"discount-container"}>
                            {" "}
                            {product.discount}%{" "}
                        </div>
                    )
                )}

                {!withoutStock && product.fee && (
                    <div
                        className={"without-interest-container " + feeClassName}
                    >
                        {feeAbbreviation(product.countryCode) + product.fee}
                    </div>
                )}

                {!withoutStock && shippingActive && (
                    <div className={"shipping-button-container" + withoutShipping}>
                        <ShippingButton
                            placement="right"
                            trigger="click"
                            iconName="shipping-icon"
                            className={containerClassNameShipping}
                            product={product}
                            classShipping={withoutShipping}
                            id={'tooltip-' + product.id}
                            filter={filter}
                        />
                    </div>
                )}

                {withoutStock && shippingActive && (
                    <div className={"stock-text-container" + " active-shipping"}>
                        <div className="without-stock-text">
                            {daysWithoutStock && <div>{daysWithoutStock}</div>}
                        <div>sin stock</div>
                        <div>
                        <img src={WithoutStockShippingIcon} className={(product.isSeller ? "img-with-seller" : "img")} id={"tooltip-" + product.id}/>
                        <UncontrolledTooltip placement='top' boundariesElement='window' delay={0} target={"tooltip-" + product.id} >
                            <div className="without-shipping-tooltip">Te mostraremos la info de Shipping una vez que el producto vuelva a tener stock</div>
                        </UncontrolledTooltip>
                        </div>
                    </div>
                </div>
                )}
            </div>
        );
    }
}
