import React, { Component } from 'react';
import { find, orderBy, get } from "lodash";
import { PropTypes } from "prop-types";
import { CustomTable, SortingSelect } from '@common/components';
import ValidationCell from './ValidationCell';
import IdenticalActions from "./IdenticalActions";
import { Utils, Constantes } from "@common/utils/"
import moment from 'moment';
import NewColorModal from './NewColorModal';
import ColorAssignmentConfirmModal from './ColorAssignmentConfirmModal';
import { change } from 'redux-form';

export const IdenticalMatchesTableStrategy = {
    IDENTICAL: 'IDENTICAL',
    IDENTICAL_VARIANTS: 'IDENTICAL_VARIANTS',
    VARIANTS: 'VARIANTS'
};

const INVALID_NEW_COLOR_ID = -123456789;

const BUILD_NAME_STRATEGY = {
    [IdenticalMatchesTableStrategy.IDENTICAL]: (total) => Utils.getCountText(total, "MATCH IDÉNTICO", "MATCHES IDÉNTICOS"),
    [IdenticalMatchesTableStrategy.IDENTICAL_VARIANTS]: (total) => Utils.getCountText(total, "MATCH IDÉNTICO", "MATCHES IDÉNTICOS"),
    [IdenticalMatchesTableStrategy.VARIANTS]: (total) => Utils.getCountText(total, "NUEVA VARIANTE DE COLOR", "NUEVAS VARIANTES DE COLOR")
}

const BUILD_MATCHES_STRATEGY = {
    [IdenticalMatchesTableStrategy.IDENTICAL]: (matches) => matches,
    [IdenticalMatchesTableStrategy.IDENTICAL_VARIANTS]: (matches) => matches.filter(match => !!match.hasValidatedColors),
    [IdenticalMatchesTableStrategy.VARIANTS]: (matches) => matches.filter(match => !match.hasValidatedColors)
}

const isValidMatchProduct = (matchProduct) => (matchProduct && matchProduct.productId);

const BUILD_RIGHT_ACTIONS_STRATEGY = {
    [IdenticalMatchesTableStrategy.IDENTICAL]: (params) => null,
    [IdenticalMatchesTableStrategy.IDENTICAL_VARIANTS]: (params) => {
        const { matchProduct } = params;

        if (!isValidMatchProduct(matchProduct)) {
            return null;
        }

        let color = !!matchProduct.hasValidatedColors && matchProduct.validatedColors.map(color => color.name).join(", ");

        return <div className="variants">
            <div className="color-found identical">
                Color encontrado: <span className="color">{color || 'Sin color'}</span>
            </div>
        </div>
    },
    [IdenticalMatchesTableStrategy.VARIANTS]: (params) => {
        let { matchProduct, onChangeSelectedColor, selectedColor, options } = params;

        if (!isValidMatchProduct(matchProduct)) {
            return null;
        }

        const color = !!matchProduct.hasUnvalidatedColors && matchProduct.unvalidatedColors.map(color => color.name).join(", ");
        selectedColor = selectedColor || {};
        let selectedValue = find(options, { key: selectedColor });

        return <div className="variants">
            <div className="color-found">
                Color encontrado: <span className="color">{color || 'Sin color'}</span>
            </div>

            <div className="select-color">
                <SortingSelect
                    closeOnScroll={false}
                    onChange={onChangeSelectedColor}
                    enableHandleCloseMenuOnScroll={false}
                    input={{ value: selectedValue }}
                    defaultValue={null}
                    name="layout-colors-select"
                    containerClassName='layout-color-select'
                    placeholder="Selecciona a qué color corresponde"
                    options={options} />
            </div>
        </div>
    }
}

export class IdenticalMatchesTable extends Component {

    static propTypes = {
        currentProduct: PropTypes.object.isRequired,
        isRemovingTheMatch: PropTypes.bool,
        isTransformingTheMatch: PropTypes.bool,
        allMatches: PropTypes.array.isRequired,
        removeIdentical: PropTypes.func,
        customActions: PropTypes.func,
        showImageComparer: PropTypes.bool,
        comparerLeftTitle: PropTypes.string,
        comparerRightTitle: PropTypes.string,
        comparerThumbnailMainTitle: PropTypes.string,
        onChangeSelectedColor: PropTypes.func,
        dispatch: PropTypes.func,
        strategy: PropTypes.oneOf([
            IdenticalMatchesTableStrategy.IDENTICAL,
            IdenticalMatchesTableStrategy.IDENTICAL_VARIANTS,
            IdenticalMatchesTableStrategy.VARIANTS
        ]).isRequired,
        countryCode: PropTypes.string
    };

    static defaultProps = {
        removeIdentical: () => { },
        isRemovingTheMatch: false,
        isTransformingTheMatch: false,
        showImageComparer: false,
        comparerRightTitle: "Match idéntico: ",
        strategy: IdenticalMatchesTableStrategy.IDENTICAL
    };

    constructor(props) {
        super(props);
        this.state = {
            openNewColorModal: false,
            openColorAssignmentConfirmModal: false,
            selectedMatchProduct: null,
            containerKey: this.generateKey(),
            selectedColor: null
        };

        this.containerRef = React.createRef();

        this.getActions = this.getActions.bind(this);
        this.toggleNewColorModal = this.toggleNewColorModal.bind(this);
        this.changeFieldValue = this.changeFieldValue.bind(this);
        this.changeColorNameField = this.changeColorNameField.bind(this);
        this.generateKey = this.generateKey.bind(this);
        this.toggleColorAssignmentConfirmModal = this.toggleColorAssignmentConfirmModal.bind(this);
        this.getValidationCell = this.getValidationCell.bind(this);
        this.formatIdenticalMatches = this.formatIdenticalMatches.bind(this);
    }

    generateKey() {
        return 'IMT-' + Utils.generateUniqueID();
    }

    toggleNewColorModal(matchProduct, selectedColor) {
        let openNewColorModal = !this.state.openNewColorModal;

        if (openNewColorModal) {
            selectedColor = { name: get(matchProduct, 'unvalidatedColors[0].name', '') }
        } else {
            selectedColor = null;
        }

        this.setState({
            openNewColorModal: openNewColorModal,
            selectedMatchProduct: matchProduct,
            selectedColor: selectedColor
        });

        this.changeColorNameField(get(selectedColor, 'name', ''));

        if (!openNewColorModal) {
            this.setState({ containerKey: this.generateKey() });
        }
    }

    toggleColorAssignmentConfirmModal(matchProduct, selectedColor) {
        let openColorAssignmentConfirmModal = !this.state.openColorAssignmentConfirmModal;

        this.setState({
            openColorAssignmentConfirmModal: openColorAssignmentConfirmModal,
            selectedMatchProduct: matchProduct,
            selectedColor: selectedColor
        });

        if (!openColorAssignmentConfirmModal) {
            this.setState({ containerKey: this.generateKey() });
        }
    }

    changeColorNameField(value) {
        this.changeFieldValue(Constantes.FORM_NAMES.NEW_COLOR_FORM, 'colorName', value);
    }

    changeFieldValue(formName, field, value) {
        this.props.dispatch(change(formName, field, value))
    }

    getActions(product) {
        if (this.props.customActions && Utils.isType(this.props.customActions, "function")) {
            return this.props.customActions(product);
        }

        const identicalActionsProps = {
            showRemoveButton: true,
            isRemovingTheMatch: this.props.isRemovingTheMatch,
            isTransformingTheMatch: this.props.isTransformingTheMatch,
            btnClass: "btn__sm",
            handleRemoveClick: this.props.removeIdentical
        }

        return <IdenticalActions {...identicalActionsProps} product={product} />
    }

    getValidationCell(product) {
        const { currentProduct, strategy } = this.props;
        const buildRightActionsFunc = BUILD_RIGHT_ACTIONS_STRATEGY[strategy];
        product = Utils.validateCountryWithoutCentsAndRoundPrices(product, this.props.countryCode);

        const getProductActions = () => {
            return product.sourceId === Constantes.MATCH_SOURCE_IDS.ORIGINAL ?
                <div className="original-tag">Match original</div> :
                this.getActions(product)
        }

        const getRightActions = () => {
            if (Utils.isFunction(buildRightActionsFunc)) {
                return buildRightActionsFunc(
                    {
                        matchProduct: product,
                        canonicalProduct: currentProduct,
                        onChangeSelectedColor: (selectedColor) => {
                            if (selectedColor.value === INVALID_NEW_COLOR_ID) {
                                this.toggleNewColorModal(product, selectedColor);
                            } else {
                                this.toggleColorAssignmentConfirmModal(product, selectedColor);
                            }
                        },
                        selectedColor: undefined,
                        options: currentProduct.validatedColors.concat([
                            {
                                value: INVALID_NEW_COLOR_ID,
                                key: INVALID_NEW_COLOR_ID,
                                label: 'Es un nuevo color'
                            }
                        ])
                    }
                )
            }
            return null;
        }

        return (
            <ValidationCell
                product={product}
                priceType={Constantes.PriceType.MIN_PRICE}
                showImageComparer={this.props.showImageComparer}
                comparerLeftTitle={this.props.comparerLeftTitle}
                comparerRightTitle={this.props.comparerRightTitle}
                comparerThumbnailMainTitle={this.props.comparerThumbnailMainTitle}
                productActions={getProductActions()}
                rightActions={getRightActions}
            />
        )
    }

    formatIdenticalMatches(directMatches) {
        const { allMatches } = this.props;
        let result = [];
        if (Utils.isArrayWithData(directMatches)) {
            result = directMatches.map(o => {
                let match = find(allMatches, ["competitorId", o.productId]) || {};
                let updatedTimeStamp = moment(match.updated, Constantes.DATE_FORMATS.MATCH_UPDATED_FORMAT);
                match.updatedDateTime = updatedTimeStamp.format("X");
                return { ...match, ...o }
            })
        }
        return result;
    }

    render() {
        let { currentProduct, strategy } = this.props;
        const buildNameFunc = BUILD_NAME_STRATEGY[strategy];
        const buildMatchesFunc = BUILD_MATCHES_STRATEGY[strategy];
        let matches = [];

        if (!Utils.isObjectWithData(currentProduct)) {
            return null;
        }

        currentProduct.identicalMatches = this.formatIdenticalMatches(currentProduct.directMatches);

        if (Utils.isArrayWithData(currentProduct.identicalMatches)) {
            matches = orderBy(currentProduct.identicalMatches.map(o => ({ ...o, canonicalProduct: currentProduct })), ["updatedDateTime"], ["desc"]);
        }

        matches = buildMatchesFunc ? buildMatchesFunc(matches) : [];

        const headers = [{
            key: 'validationCell',
            name: buildNameFunc && buildNameFunc(matches.length),
            sortable: false,
            className: 'header-min-price',
            cellClass: 'validation-cell',
            formatter: (product) => this.getValidationCell(product)
        }];

        return (
            <div ref={this.containerRef} key={this.state.containerKey}>
                {this.state.openNewColorModal &&
                    <NewColorModal
                        openModal={this.state.openNewColorModal}
                        toggleModal={this.toggleNewColorModal}
                        matchProduct={this.state.selectedMatchProduct}
                        createNewColor={this.props.createNewColor}
                        selectedColor={this.state.selectedColor}
                    />
                }

                {this.state.openColorAssignmentConfirmModal &&
                    <ColorAssignmentConfirmModal
                        openModal={this.state.openColorAssignmentConfirmModal}
                        toggleModal={this.toggleColorAssignmentConfirmModal}
                        matchProduct={this.state.selectedMatchProduct}
                        createNewColor={this.props.createNewColor}
                        selectedColor={this.state.selectedColor}
                    />
                }

                <CustomTable
                    responsive={true}
                    headers={headers}
                    rows={matches}
                    tableIdId='validationsTable--matches'
                    className={'table--bordered validations-module'}
                    rowKey="productId"
                    onSort={() => { }}
                    count={matches.length}
                    allRows={[]}
                    rowInfo={null}
                    key={currentProduct.productId}
                    sortKeyDefault={'validationCell'}
                    sortDirectionDefault={'ASC'}
                    onRowClick={() => { }}
                />
            </div>
        )
    }
}
export default IdenticalMatchesTable;
