/**
 * @author @author <a href="mailto:fhurtado@retailcompass.com">Jose Fary Hurtado Cuero</a>
 * @class homologationAttributes.js
 * @date 24 mar. 2021
 */

import { getAsJson } from './AbstractService';
import { ConstantesApi, Utils } from '@common/utils';


export const fetchHomologationAttributes = (productId, attributeId, params) => {
    if (!Utils.isObjectWithData(params)) {
        throw new Error('invalid data to save');
    }
    if (!productId) {
        throw new Error('invalid productId to save');
    }
    if (!attributeId) {
        throw new Error('invalid attributeId to save');
    }

    return getAsJson({
        api: ConstantesApi.API_FETCH_HOMOLOGATION_ATTRIBUTES.replace("{productId}", productId).replace('{attributeId}', attributeId),
        ...params
    });
};
