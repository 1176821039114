import React from 'react';
import PropTypes from 'prop-types';

export const ShareReportTooltip = (props) => {
    return <div className={'range-item ' + (props.containerClass || '')}>
        <table>
            <tbody>
                <tr>
                    <td className="first-column">
                        <div className={"icon-" + props.iconClass} />
                    </td>
                    <td className="midle-column">
                        <div className="text">{props.text}</div>
                    </td>
                    <td className={props.percentage !== '' ? "last-column" : "last-column-empty"}>
                        <div className="text">{props.percentage}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div >;
}

ShareReportTooltip.defaultProps = {
    percentage: ''
}

ShareReportTooltip.propTypes = {
    iconClass: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    percentage: PropTypes.string,
}

export default ShareReportTooltip;