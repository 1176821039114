import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Constantes } from "@common/utils/";


class NoFormSearchBox extends PureComponent {

    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        buttonText: PropTypes.string,
        onInputKeyPress: PropTypes.func,
        onInputChange: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        onSearchReset: PropTypes.func,
        showCategories: PropTypes.bool,
        defaultValue: PropTypes.string,
        searchOnEnter: PropTypes.bool,
        className: PropTypes.string,
        eraseOnReset: PropTypes.bool,
        autoSearch: PropTypes.bool
    }

    static defaultProps = {
        showCategories: false,
        title: "SEARCH",
        subtitle: "",
        hasActiveSearch: false,
        buttonText: "GO",
        onInputKeyPress:()=>{},
        onInputChange:()=>{},
        defaultValue: "",
        placeholder:"",
        eraseOnReset: false,
        autoSearch: false
    }

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.defaultValue
        }
        this.onChange = this.onChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onResetSearchClick = this.onResetSearchClick.bind(this);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    onChange(e){
        this.setState({ inputValue: e.target.value });
        if(this.props.onInputChange){
            this.props.onInputChange(e);
        }
    }

    onSearchClick(e){
        if(this.state.inputValue){
            this.setState({ hasActiveSearch: true });
            this.props.onSearch(this.state.inputValue)
        }   
    }


    onKeyPressed(e){
        if( this.props.searchOnEnter && e.key === Constantes.KEYBOARD.KEYS.ENTER && !!this.state.inputValue ){
            this.onSearchClick({});
        }
        if(this.props.onInputKeyPress){
            this.props.onInputKeyPress(e, this.state.inputValue);
        }
    }

    onResetSearchClick(){
        let lastSearch = this.state.inputValue;
        if(this.props.onSearchReset){
            this.props.onSearchReset(lastSearch);
        }
        this.setState({ 
            hasActiveSearch: false, 
            inputValue:this.props.eraseOnReset ? '': this.state.inputValue 
        });
        
    }

    componentDidMount(){
        if(this.props.autoSearch){
            this.onSearchClick({})
        }
    }

    render() {
        return (<div className={'form product-list-main-filters-form '+this.props.className}>
            <div className="search-title">{this.props.title}</div>
            <div className="search-subtitle">{this.props.subtitle}</div>
            <div className='form__form-group'>
                <div className='form__form-group-field'>
                    <input
                        name='search'
                        className={'product-list-main-filters-search' + (this.state.hasActiveSearch ? ' product-list-main-filters-search--current' : '')}
                        type='text'
                        placeholder={this.props.placeholder}
                        value={this.state.inputValue}
                        onKeyPress={this.onKeyPressed}
                        onFocus={(e) => {
                            document.getElementsByClassName("start-search")[0].classList.add("reset-search--input-focused");
                        }}
                        onBlur={(e) => {
                            document.getElementsByClassName("start-search")[0].classList.remove("reset-search--input-focused");
                        }}
                        onChange={ this.onChange }
                    />
                    <div className="icon-search">
                        <svg className="mdi-icon " width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M9.5,3C13.09,3 16,5.91 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16C5.91,16 3,13.09 3,9.5C3,5.91 5.91,3 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
                        </svg>
                    </div>
                    {this.state.hasActiveSearch ?
                        <button className='reset-search' onClick={this.onResetSearchClick} type="button">
                            <svg className="mdi-icon " width="18" height="18" viewBox="0 0 24 24">
                                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
                        </button>
                        : null}
                    <button className='start-search' onClick={this.onSearchClick} type="button">
                        {this.props.buttonText}
                    </button>
                </div>
            </div>
        </div>);
    }
}


export default NoFormSearchBox;