/**
 * @author @author <a href="mailto:fhurtado@retailcompass.com">Jose Fary Hurtado Cuero</a>
 * @class favoriteActions.js
 * @date 15 may. 2018
 */

import { Utils } from '@common/utils/';


const ACTION_BASE_NAME = 'FAVORITES';

export const RESET_STORE = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_STORE');

export const REQUEST_FAVORITE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_FAVORITE');
export const RECEIVE_FAVORITE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FAVORITE');
export const RECEIVE_FAVORITE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FAVORITE_ERROR');

export const REQUEST_FAVORITE_CREATE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_FAVORITE_CREATE');
export const RECEIVE_FAVORITE_CREATE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FAVORITE_CREATE');
export const RECEIVE_FAVORITE_CREATE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FAVORITE_CREATE_ERROR');

export const REQUEST_FAVORITE_DELETE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_FAVORITE_DELETE');
export const RECEIVE_FAVORITE_DELETE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FAVORITE_DELETE');
export const RECEIVE_FAVORITE_DELETE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FAVORITE_DELETE_ERROR');
export const UPDATE_STATE_FAVORITE = Utils.composeActionName(ACTION_BASE_NAME, 'UPDATE_STATE_FAVORITE');

export const requestFavoritesAction = () => ({
    type: REQUEST_FAVORITE
});

export const receiveFavoritesAction = (data) => ({
    type: RECEIVE_FAVORITE,
    payload: { ...data }
});

export const receiveFavoritesErrorAction = (error) => ({
    type: RECEIVE_FAVORITE_ERROR,
    payload: {
        error: error
    }
});

export const requestCreateFavoriteAction = () => ({
    type: REQUEST_FAVORITE_CREATE
});

export const receiveCreateFavoriteAction = (data) => ({
    type: RECEIVE_FAVORITE_CREATE,
    payload: { ...data }
});

export const receiveCreateFavoriteErrorAction = (error) => ({
    type: RECEIVE_FAVORITE_CREATE_ERROR,
    payload: {
        error: error
    }
});

export const requestDeleteFavoriteAction = () => ({
    type: REQUEST_FAVORITE_DELETE
});

export const receiveDeleteFavoriteAction = (data) => ({
    type: RECEIVE_FAVORITE_DELETE,
    payload: { ...data }
});

export const receiveDeleteFavoriteErrorAction = (error) => ({
    type: RECEIVE_FAVORITE_DELETE_ERROR,
    payload: {
        error: error
    }
});

export const resetStoreAction = () => ({
    type: RESET_STORE
});

export const updateStateFavoriteAction = (data) => ({
    type: UPDATE_STATE_FAVORITE,
    payload: { ...data }
});