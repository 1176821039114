import { connect } from 'react-redux';
import MainFilters from './MainFilters';

import { FavoriteState, TableState } from '@common/components';
import { Utils, RetailCompassStorage, SubRouterState } from '@common/utils';

export const mapStateToProps = (state, ownProps) => {
    return {
        currentFilter: Utils.extractCurrentProductListFilter(state),
        categoryName: RetailCompassStorage.getCurrentCanonicalCategory().name,
        lastExecutedFilter: state.productListBrandReducer.lastFilter,
        selectedView: state.subRouterStateReducer.selectedView,
        searchType: state.productListBrandReducer.searchType,
        searchTypeToRepeat: state.productListBrandReducer.searchTypeToRepeat
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params, searchType) => TableState.fetchProductItems(dispatch, params, searchType),
        fetchFavorites: () => FavoriteState.fetchFavorites(dispatch),
        onSelectedView: (selected) => SubRouterState.onSelectedView(dispatch, selected)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainFilters);
