import { unionBy, get } from "lodash";
import {
    Utils
} from "@common/utils/"
import * as ValidationActions from "../ValidationActions";
import { addMatch } from "../../common/utils/utils";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestCreateNewMatch = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isCreatingNewMatch: true
    });
}

export const removeMatchFromPotentialMatches = (potentialMatches, match) => {
    if (Utils.isArrayWithData(potentialMatches) && match && match.productId) {
        potentialMatches = potentialMatches.filter(item => item.productId !== match.productId);
    }
    return potentialMatches;
}

export const receiveCreateNewMatch = (state, action) => {
    const requestBody = action.payload.requestBody;
    const match = requestBody.match;
    const matchEntity = requestBody.matchEntity;

    return updateObject(state, {
        hasError: false,
        error: null,
        isCreatingNewMatch: false,
        currentProduct: addMatch(state.currentProduct, match),
        potentialMatches: removeMatchFromPotentialMatches(state.potentialMatches, match),
        allMatches: unionBy([matchEntity], get(state, 'allMatches', []), 'competitorId')
    });
}

export const receiveCreateNewMatchError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isCreatingNewMatch: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_CREATE_NEW_MATCH]: requestCreateNewMatch,
    [ValidationActions.RECEIVE_CREATE_NEW_MATCH]: receiveCreateNewMatch,
    [ValidationActions.RECEIVE_CREATE_NEW_MATCH_ERROR]: receiveCreateNewMatchError,
}

export default handlers