import React, { PureComponent } from 'react';
import { Utils, Constantes } from '@common/utils/';
import PropTypes from 'prop-types';
import ArrowDownThickIcon from 'mdi-react/ArrowDownThickIcon';

class ComparerVariationCard extends PureComponent {

  static propTypes = {
    history: PropTypes.array.isRequired,
    onClick: PropTypes.func
  };

  static defaultProps = {
    history: [],
    onClick: () => { }
  };

  constructor(props) {
    super(props);
    this.getDifference = this.getDifference.bind(this);
  }

  getDifference() {
    const history = this.props.history
      .filter(item => item.status === Constantes.STATUS_PRODUCT.WITH_STOCK);
    if (!Utils.isArrayWithData(history) || history.length < 2) return

    const finalPrice = history[1].price;
    const initialPrice = history[0].price;
    if (!finalPrice || !initialPrice) return

    const difference = finalPrice - initialPrice;
    return {
      percentage: parseInt(((difference) * 100 / initialPrice).toFixed(0)),
      value: difference
    }
  }

  render() {
    let diff = this.getDifference();
    return (
      <div className="compare-variation-card" onClick={this.props.onClick}>
        {
          diff && diff.percentage !== 0 &&
          <React.Fragment>
            <div>
              <ArrowDownThickIcon
                className={diff.percentage > 0 ? "icon red" : "icon green"}
              />
              <span className="text">{Math.abs(diff.percentage)}%</span>
            </div>
            <div>
              <span className="text">
                {diff.value > 0 ? "+ " : "- "}{Utils.formatPrice((diff.value < 0) ? diff.value * -1 : diff.value)}
              </span>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default ComparerVariationCard;
