import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';
import { EdgeUtils, Utils } from "@common/utils/";
import classnames from 'classnames'

export const skuStoreMapType = PropTypes.shape({
    "sku": PropTypes.string.isRequired,
    "storeName": PropTypes.string.isRequired
});

export default class RCPopover extends Component {

    static propTypes = {
        placement: PropTypes.string,
        popoverBody: PropTypes.any.isRequired,
        className: PropTypes.string,
        startOpen: PropTypes.bool,
        onClose: PropTypes.func,
        closeOnMouseLeave: PropTypes.bool
    };

    static defaultProps = {
        placement: 'top',
        startOpen: false,
        closeOnMouseLeave: true
    }

    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: props.startOpen,
        }

        this.timeoutClosePopover = null;
        this.containerRef = null;
        this.popoverRef = null;

        this.setContainerRef = element => {
            if (element) {
                this.containerRef = element;
            }
        };

        this.setPopoverRef = element => {
            if (element) {
                this.popoverRef = element;
            }
        };

        this.cancelTimeoutClosePopover = this.cancelTimeoutClosePopover.bind(this);
        this.createTimeoutClosePopover = this.createTimeoutClosePopover.bind(this);
        this.openPopover = this.openPopover.bind(this);
        this.closePopover = this.closePopover.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('scroll', this.handleClickOutside, true);
        if (this.state.popoverOpen) {
            this.openPopover();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('scroll', this.handleClickOutside, true);
        this.cancelTimeoutClosePopover();
    }


    handleClickOutside = event => {
        if (this.popoverRef && !this.popoverRef._popover.contains(event.target)) {
            this.closePopover();
        }
    }

    openPopover() {
        this.setState({ popoverOpen: true })
    }

    closePopover(e) {
        if (this.state.popoverOpen) {
            this.setState({ popoverOpen: false })
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    createTimeoutClosePopover(time) {
        if (this.state.popoverOpen) {
            time = time || 200;
            this.cancelTimeoutClosePopover();
            this.timeoutClosePopover = setTimeout(() => {
                this.setState({ popoverOpen: false, })
            }, time);
        }
    }

    cancelTimeoutClosePopover() {
        if (this.timeoutClosePopover) {
            clearTimeout(this.timeoutClosePopover);
        }
    }

    render() {
        const { children, popoverBody, placement, className } = this.props;

        let classNameConfig = { 'rc-popover-container__popover': true, 'edge-browser': EdgeUtils.isEdge() };
        if (Utils.hasValue(className)) {
            classNameConfig[className] = true;
        }

        return (
            <div className='rc-popover-container'
                onClick={this.openPopover}
                ref={this.setContainerRef}
                onMouseLeave={this.props.closeOnMouseLeave ? this.createTimeoutClosePopover : () => { }}>
                {children}
                {this.containerRef ?
                    <Popover
                        className={classnames(classNameConfig)}
                        ref={this.setPopoverRef}
                        placement={placement}
                        target={this.containerRef}
                        isOpen={this.state.popoverOpen}
                        onMouseEnter={this.props.closeOnMouseLeave ? this.cancelTimeoutClosePopover : () => { }}
                        onMouseLeave={this.props.closeOnMouseLeave ? this.closePopover : () => { }}
                        container='body'
                        delay={0}>
                        <PopoverBody className="rc-popover-container__popover-body">
                            {popoverBody}
                        </PopoverBody>
                    </Popover>
                    : null
                }
            </div>
        )
    }
}
