import React, { useState, useRef, useEffect, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { Utils } from '@common/utils/';

const RangeSlider = ({ ranges = [], header, onChange }) => {

    const [currentVal, setCurrentVal] = useState(0);
    const [minVal, setMinVal] = useState(0);
    const [maxVal, setMaxVal] = useState(100);
    const range = useRef(null);

    // Hook que inicializa los parametros del slider
    useEffect(() => {
        const minValue = 0;
        setMinVal(minValue);
        setCurrentVal(minValue);
        const maxValue = ranges.length - 1;
        setMaxVal(maxValue);
    }, [ranges])

    const getPercent = useCallback(
        (value) => Math.round(((value - minVal) / ((maxVal) - minVal)) * 100),
        [minVal, maxVal]
    );

    // Hook para modificar el progreso del slider.
    useEffect(() => {
        const minPercent = getPercent(currentVal);
        const maxPercent = 100;

        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
    }, [currentVal, getPercent]);

    // hook para desatar evento onChange cuando cambia el valor
    useEffect(() => {
        Utils.isArrayWithData(ranges) && onChange(ranges[currentVal].value);
    }, [currentVal]);

    return (
        <div className="range-container">
            <div className="form__form-group-label">
                <div>{header}</div>

                <div className="subHeader">
                    <span>Desde</span>
                </div>
            </div>

            <div className="w-100">
                <input type="range"
                    min={minVal}
                    max={maxVal}
                    value={currentVal}
                    className="thumb"
                    onChange={(event) => setCurrentVal(Number(event.target.value))} />

                <div className="slider">
                    <div className="slider__track" />
                    <div ref={range} className="slider__range" />
                    <div className="slider_tags_container">
                        <ul className="ranges">
                            {
                                ranges.map((item, index) =>
                                    <li className="slider__mark" key={index + 1} onClick={() => setCurrentVal(index)}>
                                        <span>{item.label}</span>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

RangeSlider.propTypes = {
    ranges: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default memo(RangeSlider)