/* eslint-disable no-undef */
import React, { Component } from 'react';
import { Col, Container, Card, CardBody, Row } from 'reactstrap';
import {
    Hotjar,
    Delighted,
    Constantes,
    GaUtils,
    Utils,
    RouterUtils,
    ComparerUtils,
    RetailCompassStorage,
    TableStateUtils
} from "@common/utils/";
import {
  WhereAmINav, ProductDetail, ComparerProductCard, ComparerVariationCard,
  PriceUtils
} from '@common/components'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { isEqual, find } from 'lodash';
import RadioButtonField from '@common/components/src/ui/RadioButton';
import ButtonSwitch from "@common/components/src/ui/ButtonSwitch";
import HistoricContainer from './historic/HistoricContainer';
import moment from 'moment';

export class Comparer extends Component {

  static propTypes = {
    products: PropTypes.array.isRequired,
    currentFilter: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedComparer: { view: Constantes.COMPARER_TYPE.AVG_PRICE, value: true },
      pivot: this.getPivot(props.products)
    };

    this.buildRow = this.buildRow.bind(this);
    this.selectedTypeComparer = this.selectedTypeComparer.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.bodyClick = this.bodyClick.bind(this);
    this.getPath = this.getPath.bind(this);
    this.fetchInfo = this.fetchInfo.bind(this);
    this.fetchInterval = this.fetchInterval.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.bodyClick);
    window.addEventListener('focus', this.handleFocus);
    Hotjar.load();
    Delighted.load();
    this.fetchInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.products, this.props.products)) {
      if (Utils.isArrayWithData(this.props.products)) {
        this.setState({ pivot: this.getPivot(this.props.products) })
        const canonicalProducts = this.props.products.map(p => p.original);
        this.props.validateStarCategoriesRequest(canonicalProducts);
      }
    }
    if (!isEqual(this.props.products, prevProps.products)) {
      this.fetchInterval({ start: this.props.startDate, end: this.props.endDate, includeCardPrices: this.props.includeCardPrices, })
    }
    return !isEqual(this.props.currentFilter, prevProps.currentFilter);
  }

  handleFocus = e => {
    let storeId = RetailCompassStorage.getStoreId();
    let currentCanonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
    if (!storeId) {
      window.location.reload();
    }
    //si se cambia de tienda en otro tab se refresca la página para que tome la información del LocalStorage
    const baseUrl = RouterUtils.buildModuleViewPath(RouterUtils.MODULES_VIEWS.LIST);
    if ((storeId && this.props.storeId !== storeId)
      || this.props.currentCategoryId !== currentCanonicalCategory.id) {
      window.location.href = baseUrl
    }
  };

  fetchInterval(params) {
    if (params.type !== Constantes.COMPARER_CALLBACK_TYPE.INCLUDE_CARD_PRICES) {
      const matchesProductIds = ComparerUtils.getProductIdsForHistoric(this.props.products, {})
      if (Utils.isArrayWithData(matchesProductIds)) {
        this.props.fetchHistoryInterval({ productIds: matchesProductIds, dates: [params.start, params.end], includeCardPrices: params.includeCardPrices });
      }
    }
  }

  fetchInfo() {
    Utils.onUrlChange(this.props.history.location.pathname + this.props.history.location.search);
    let productIds = Utils.getParamFromQueryString('productIds');
    this.props.fetchProductsInfo({ isFirtsRequest: true });
    if (Utils.hasValue(productIds)) {
      this.props.fetchProductsInfo({
        productIds: Utils.stringToArray(productIds),
        isFirtsRequest: false
      });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.bodyClick);
    window.removeEventListener('focus', this.handleFocus);
  }

  getPath(e) {
    return e.path || (e.composedPath && e.composedPath() || EdgeUtils.alternativePath(e.target));
  }

  bodyClick(e) {
    let path = this.getPath(e);
    if (path && this.props.productDetailOpenedProp) {
      let excludeNodes = ['.comparing-table-container', '.product-detail-container'];
      let clickWasInExcludeNode = excludeNodes.filter((item) => path.indexOf(document.querySelector(item)) !== -1).length > 0;
      let clickWasInSlidePaneClose = path.indexOf(document.querySelector('.slide-pane__close')) !== -1;

      if (!clickWasInExcludeNode || clickWasInSlidePaneClose) {
        this.props.productDetailOpened && this.props.productDetailOpened(false);
        this.props.updateCurrentProduct(null);
      }
    }
  }

  getPivot(products) {
    let pivot = { id: "", key: null };
    if (!Utils.isArrayWithData(products)) {
      return pivot;
    }
    pivot = this.props.products[0].original;
    pivot = { id: String(pivot.productId), key: 0 };
    return pivot;
  }

  selectedTypeComparer(params) {
    this.setState({ selectedComparer: params });
    this.props.changeSelectedView(params);
    this.trackSelectedComparer(params);
  }

  trackSelectedComparer(params) {
    let label = '';
    switch (params.view) {
      case Constantes.COMPARER_TYPE.MIN_PRICE:
        label = Constantes.GA_LABELS_EVENTS.COMPARE.BEST_PRICE;
        break;
      case Constantes.COMPARER_TYPE.AVG_PRICE:
        label = Constantes.GA_LABELS_EVENTS.COMPARE.AVG_PRICE;
        break;
      case Constantes.COMPARER_TYPE.RETAILERS:
        label = params.title;
        break;
    }
    this.trackGA(Constantes.GA_ACTION_EVENTS.COMPARE.VIEW, label);
  }

  trackSelectedPivot() {
    this.trackGA(Constantes.GA_ACTION_EVENTS.COMPARE.PIVOT, null);
  }

  trackGA(action, label) {
    GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.COMPARE, action, label, null, null);
  }

  onRowSelected(product, currentFilter) {
    let originalProduct = product.original;
    let shouldUpdateCurrentProduct = true;

    if (originalProduct && this.props.currentProduct && this.props.currentProduct.productId === originalProduct.productId) {
      shouldUpdateCurrentProduct = false;
    }

    if (shouldUpdateCurrentProduct) {
      this.props.updateCurrentProduct(originalProduct);
      this.props.productDetailOpened(true);
      TableStateUtils.trackProductDetailEvent(originalProduct);
    }
  }

  buildTitle(totalProducts) {
    let appendPlural = totalProducts && totalProducts > 1 ? 'S' : '';
    return (<span>{totalProducts} PRODUCTO{appendPlural}</span>)
  }

  buildHeader(totalProducts) {
    const dates = [moment(this.props.startDate).utcOffset(0), moment(this.props.endDate).utcOffset(0)];
    const headers = ComparerUtils.buildTableHeaders(dates, Constantes.DATE_FORMATS.DAY_MONTH_YEAR);
    return (<thead>
      <tr>
        <th className="marker-header"></th>
        <th className="product-header" colSpan="2">
          {this.buildTitle(totalProducts)}
        </th>
        {
          headers.map((header) => {
            return (
              <th className="week-header" key={Utils.generateRandomId() + "-semana-" + header.week}>
                <span className="main">{header.date}</span>
                <span className="legend">Semana {header.week}</span>
              </th>
            );
          })
        }
        <th className="variation-header" key="VariacionTotal">
          <span className="main">Variación total</span>
          <span className="legend">Entre fechas</span>
        </th>
      </tr>
    </thead>);
  }

  buildRow(product, currentFilter, key) {
    let history = this.props.comparerData[key] || [];
    let pivotHistory = this.props.comparerData[this.state.pivot.key];
    if (currentFilter.labelsForProductList) {
      currentFilter.labelsForProductList.forEach(e => e.showPopoverRemove = false);
    }
    if (!product.original.model) {
      product.original.model = product.original.alternativeModel;
    }

    const competitorsProducts = product.original;
    let storeId = parseInt(this.state.selectedComparer.value);
    const sellerMatches = Utils.getSellerMatches(competitorsProducts, storeId);
      
    return (
      <tr key={product.original.id} className={isEqual(history, pivotHistory) ? "pivot" : ""}>
        <td className={"marker-cell " + (product.original.isMyProduct ? "marker-cell--mine" : "")}></td>
        <td className={"radiobutton-cell"}>
          {
            <RadioButtonField
              label={""}
              radioValue={String(product.original.productId)}
              defaultChecked={false}
              className={'colored-click'}
              input={{
                name: 'comparer-pivot',
                value: this.state.pivot.id,
                onChange: (value) => {
                  this.setState({ pivot: { id: value, key: key } });
                  this.trackSelectedPivot();
                }
              }}
            />
          }
        </td>
        <td className="product-cell" onClick={() => { this.onRowSelected(product, currentFilter) }}>
          {this.props.buildProductCell({ row: product, currentFilter, optionalParams: { showModelTooltip: false, showRankingInfo: false } })}
        </td>
        {
          Utils.isArrayWithData(history) ?
            history
              .sort((o1, o2) => {
                const date1 = moment(o1.date).utcOffset(0);
                const date2 = moment(o2.date).utcOffset(0);
                return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
              })
              .map((item, key) => {
                let pivot = find(pivotHistory, ['date', item.date]) || {};
                let pivotPrice = pivot.price
                if (pivot.status === Constantes.STATUS_PRODUCT.WITHOUT_STOCK) {
                  pivotPrice = null;
                }

                const pricesView = RetailCompassStorage.getPriceView()
                const isCardPrice = Utils.isPaymentTypeCard(item.paymentType)
                const priceMapping = PriceUtils.getPriceByView(item.priceMapping, pricesView, isCardPrice)
                return (
                  <td className={"week-cell " + (key === 0 ? "week-cell--today" : "")} key={item.canonicalProductId + "week" + key}>
                    {
                      <ComparerProductCard
                        price={item.price}
                        pivotPrice={pivotPrice}
                        showDifference={this.state.pivot.id !== String(item.canonicalProductId)}
                        retailers={item.retailers}
                        onClick={(e) => { this.onRowSelected(product, currentFilter) }}
                        status={item.status}
                        paymentType={item.paymentType}
                        fees={item.fees}
                        isRetailView={item.isRetailView}
                        priceMapping={priceMapping || {}}
                        showCardIcon={item.showCardIcon}
                        isSeller={item.isSeller}
                        sellerName={item.sellerName}
                        productId={item.productId+key}
                        applyToPriceView={item.applyToPriceView}
                        sellerMatchesByStore={sellerMatches}
                        storeName={this.state.selectedComparer.title}
                        storeId={storeId}
                      />
                    }
                  </td>
                );
              }) : null
        }
        <td key={"variation" + key}>
          {
            < ComparerVariationCard
              history={history}
              onClick={(e) => { this.onRowSelected(product, currentFilter) }}
            />
          }
        </td>
      </tr>
    );
  }

  getBackPath() {
    if (!this.props.location.state || !this.props.location.state.from) {
      return RouterUtils.buildModuleViewPath(RouterUtils.MODULES_VIEWS.LIST);
    }
    const search = this.props.location.state.from.search;
    const pathname = this.props.location.state.from.pathname;
    let searchObject = Object.fromEntries(new URLSearchParams(search));
    delete searchObject.viewPrices;
    const newSearch = new URLSearchParams(searchObject).toString();
    const backTo = String(pathname) + '?' + newSearch;
    if (backTo.indexOf(RouterUtils.MODULES_VIEWS.LIST) !== -1
      && backTo.indexOf(RouterUtils.MODULES.BRAND) !== -1) {
      return backTo;
    }
  }

  render() {
    let { products, currentFilter, buttonBackPressedNavigate, stores } = this.props;
    const activeProduct = this.props.currentProduct ? this.props.currentProduct : null;
    const totalProducts = products.length;

    return (
      <div className="wrapper wrapper--full-width clearfix comparer-view">
        <Container>
          <Row>
            <div className="where-am-i-nav">
              <WhereAmINav
                currentViewText={"Comparador"}
                showGoBackToLink={true}
                goBackToPath={this.getBackPath()}
                goBackToText="listado"
                customTitleText="Histórico de productos"
                key={"comparer"}
                onClick={() => { buttonBackPressedNavigate(true); }}
              />
            </div>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col sm={12} className="button-switch-col-container">
              <ButtonSwitch
                elements={
                  [
                    {
                      id: 1,
                      "name": Constantes.COMPARER_TYPE.AVG_PRICE,
                      "type": "button",
                      "title": "Precio medio",
                      "value": Constantes.SortValues.AVERAGE_PRICE_BRAND
                    },
                    {
                      id: 2,
                      "name": Constantes.COMPARER_TYPE.MIN_PRICE,
                      "type": "button",
                      "title": "Menor precio",
                      "value": Constantes.SortValues.MINIMUM_PRICE,
                    },
                    {
                      id: 3,
                      "name": Constantes.COMPARER_TYPE.RETAILERS,
                      "type": "select",
                      "title": "Retailers",
                      "options": stores
                    }
                  ]
                }
                onSelection={(key, value, title, separatorWord) => {
                  this.selectedTypeComparer({ view: key, value: value, title: title, separatorWord: separatorWord });
                }} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="comparing-historic-col-container">
              <HistoricContainer
                className="comparing-historic-container"
                selectedComparer={this.state.selectedComparer}
                countryCode={this.props.countryCode}
                changeFilterCallback={(params) => this.fetchInterval({ ...params, start: params.start.format(), end: params.end.format() })}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody className="comparing-table-container">

                  <ProductDetail
                    isOpen={this.props.productDetailOpenedProp}
                    onClose={() => {
                      this.props.productDetailOpened(false);
                      this.props.updateCurrentProduct(null);
                    }}
                    allowLabelRemoval={false}
                    producto={activeProduct}
                    labels={this.props.labels}
                    dispatch={this.props.dispatch}
                    fetchProductItems={this.props.fetchProductItems}
                    tableStrategy={this.props.storeType}
                    canonicalProduct={activeProduct}
                    showRowWhenYouDoNotSellTheProduct={false}
                    stores={this.props.stores}
                    currentFilter={currentFilter}
                    lastFilter={{}}
                    className="product-detail-pane-comparer"
                    hidePublicLabels={this.props.hidePublicLabels}
                  />
                  <table className="comparing-table">
                    {this.buildHeader(totalProducts)}
                    <tbody>
                      {
                        products.map((product, key) => {
                          return this.buildRow(product, currentFilter, key)
                        })
                      }
                    </tbody>
                  </table>
                  <div className="comparer-note">
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
export default withRouter(Comparer);
