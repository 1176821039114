import React, { PureComponent, Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Utils, TableStateUtils } from "@common/utils";
import PaginationHelper from '../common/PaginationHelper';
import * as WithGenericTableView from '../common/hoc/withGenericTableView';
import ProductDetail from '../common/components/product_detail/ProductDetail';
import PostPagoDetail from '../common/components/postpago_detail/PostpagoDetail';
import { isEqual } from 'lodash';
import ProductSelectionSummaryContainer from '../labels/label-summary/ProductSelectionSummaryContainer';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable, { ReactTableDefaults } from 'react-table';
import "react-table/react-table.css";
import '../scss/components/StickyHeaderTable.scss';

const notRetailerHeaderBaseWidthPx = 215; // ancho base de las columnas iniciales de la tabla (no retailers)
const productHeaderProportion = 1.6; // proporcion del ancho de la columna producto (productHeaderProportion * notRetailerHeaderBaseWidthPx) 
const notProductHeaderProportion = 0.4; // proporcion del ancho de las otras columnas que no son retailer (notProductHeaderProportion * notRetailerHeaderBaseWidthPx) 
const selectionSummaryClass = "selection-header";
// esto es lo que popularmente se conoce como un hack...
// el componente resultante del hoc `withFixedColumns` tiene una lógica muy compleja
// dicha lógica hace que el rendimiento se vea afectado si el componente es actualizado varias veces...
// el metodo TableView#render se ejecuta varias veces y por tal motivo el componente tiene que volver a crearse.
// al sobreescribir el metodo `shouldComponentUpdate` sólo con el `retailItems`, `productItems` y `favoriteProductItems`...
// nos estamos asegurando que cuando el usuario abra el detalle del producto, la tabla no se vuelva a actualizar...
// y como consecuencia de eso, la lógica interna no se ejecute y evite generar problemas de rendimiento.
export function HoC(WrappedComponent) {
    return class extends Component {

        mapProduct = (product) => {
            return {
                productId: product.productId,
                competitorsProducts: product.competitorsProducts,
            }
        }

        shouldComponentUpdate(nextProps) {
            const isEqualProductItems = isEqual(
                nextProps.productItems.map(this.mapProduct),
                this.props.productItems.map(this.mapProduct)
            );

            /* cuando termina de consultar a product-search y los productItems 
            son los mismos del estado anterior se actualiza el componente para 
            realizar nuevamente calculos o validaciones, como por ejemplo los 
            giones del filtro contractType en las celdas de precio. */
            const isSameProductItems = this.props.isFetching
                && !nextProps.isFetching
                && isEqualProductItems;

            const defaultValidation = !nextProps.isFetching && (
                !isEqual(nextProps.retailItems, this.props.retailItems)
                || !isEqualProductItems
                || !isEqual(nextProps.paginationCount, this.props.paginationCount)
                || !isEqual(nextProps.activeRanking, this.props.activeRanking)
                || !isEqual(nextProps.currentFilter, this.props.currentFilter)
            );

            if (isSameProductItems || defaultValidation) {
                return true;
            }
            return false;
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}
const ReactTableFixedColumns = HoC(withFixedColumns(ReactTable));

const tableId = 'brandTableViewCustomTable';
const fixedHeadersTableClass = 'fixed-headers-table';

export class Table extends PureComponent {
    summaryTopPosition = 0;
    summaryHeight = 42;

    componentDidMount() {
        window.addEventListener('scroll', this.setFixedHeader);
        window.addEventListener('resize', this.calculateWidths);
        let tableContainer = document.querySelector("." + fixedHeadersTableClass + " .ReactTable .rt-table");
        if (!tableContainer) return;
        tableContainer.addEventListener('scroll', this.horizontalScrollEvent);
        this.calculateWidths();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.setFixedHeader);
        window.removeEventListener('resize', this.calculateWidths);
        let tableContainer = document.querySelector("." + fixedHeadersTableClass + " .ReactTable .rt-table");
        if (!tableContainer) return;
        tableContainer.removeEventListener('scroll', this.horizontalScrollEvent);
        this.props.resetProductsLabel();
    }

    setFixedHeader(e) {
        let stickyHeaderClass = "sticky-header";
        let table = document.querySelector("." + fixedHeadersTableClass);
        if (!table) return;
        let tablePosition = table.getBoundingClientRect().y || table.getBoundingClientRect().top;
        let header = table.querySelector(".rt-tr");
        let topbarNav = document.querySelector(".topbar--navigation");
        let topbarHeight = topbarNav ? topbarNav.offsetHeight : 0;

        if (tablePosition < topbarHeight) {
            //tablePosition debe estar fuera de pantalla (arriba) para pasar a ser sticky                        
            header.style.top = (topbarHeight - tablePosition) + "px";
            header.classList.add(stickyHeaderClass);
        } else {
            header.removeAttribute("style");
            header.classList.remove(stickyHeaderClass);
        }
    }

    horizontalScrollEvent(e) {
        let fixedElementsSelector = ".rthfc-th-fixed-left-last, .rthfc-td-fixed-left-last, .selection-summary";

        if (e.currentTarget.scrollLeft > 0) {
            Utils.setClassBySelector("right-shadow", fixedElementsSelector, true);
        } else {
            Utils.setClassBySelector("right-shadow", fixedElementsSelector, false);
        }
    }

    componentDidUpdate() {
        this.calculateWidths();
        Utils.resetHorizontalScroll("." + fixedHeadersTableClass + " .ReactTable .rt-table");
    }

    calculateWidths() {
        //Calculo de anchos de las celdas en la tabla con headers y columnas fijos no scrolleables
        const minWidth = notRetailerHeaderBaseWidthPx;
        let table = document.querySelector("." + fixedHeadersTableClass + " .ReactTable");
        if (!table) return;
        let header = table.querySelector(".header-retail");
        if (!header) return;
        let fixedCellCount = document.querySelectorAll(".rthfc-th-fixed").length;
        let retailsWidth = header.offsetWidth * (this.props.headers.length - fixedCellCount);
        let width = ((table.offsetWidth - retailsWidth) / fixedCellCount).toFixed(0);
        if (width < minWidth) width = minWidth;
        let cells = document.querySelectorAll(".rthfc-th-fixed, .rthfc-td-fixed");
        let count = 0;
        cells.forEach(function (cell) {
            //Se calcula el ancho de cada celda (header y no header) se usa el ancho base y proporciones definidas al inicio, para customizar deben agregarse mas variables y casos.
            let offsetWidth = width - 15;
            let prefWidth = count === 0 ?
                (fixedCellCount === 1 ?
                    (530 - 15)
                    : offsetWidth * productHeaderProportion)
                : offsetWidth * notProductHeaderProportion;
            let left = count === 0 ? 0 : count === 1 ? offsetWidth * productHeaderProportion : offsetWidth * (productHeaderProportion + (notProductHeaderProportion * (count - 1)));
            cell.style.left = left + "px";
            cell.style.flex = prefWidth + " 0 auto";
            cell.style.width = prefWidth + "px";
            cell.style.maxWidth = prefWidth + "px";
            count++;
            if (count === fixedCellCount) count = 0;
        });
    }

    static propTypes = {
        ...WithGenericTableView.genericPropTypes,
        productDetailOpenedProp: PropTypes.bool,
        detailPaneClassName: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
        this.setFixedHeader = this.setFixedHeader.bind(this);
        this.calculateWidths = this.calculateWidths.bind(this);
        this.horizontalScrollEvent = this.horizontalScrollEvent.bind(this);
        this.onChangeCheckAll = this.onChangeCheckAll.bind(this);
        this.onSelectCancellation = this.onSelectCancellation.bind(this);
        this.extractProductIds = this.extractProductIds.bind(this);
    }

    onRowClick(event, rowItem) {
        if (Utils.isDevEnvironment()) console.info("onRowClick > rowItem: ", rowItem);

        if (!this.props.isCheckboxRowSelection(event.target) &&
            !this.props.areSameProduct(this.props.currentProduct, rowItem) &&
            !this.props.isPurchaseSummaryCard(event.target)) {
            this.props.productDetailOpened(true);
            this.props.updateCurrentProduct(rowItem);
            TableStateUtils.trackProductDetailEvent(rowItem);
        }
    }

    extractProductIds() {
        let productItems = this.props.productItems || []
        let ids = productItems.map((item) => item.productId)
        return ids;
    }
    onSelectCancellation() {
        this.props.resetProductsLabel();
    }

    onChangeCheckAll({ allSelected, partialSelection }) {
        //partial selection mode pending
        if (allSelected) {
            this.props.removeProductsToLabel(this.extractProductIds());
        } else {
            this.props.addProductsToLabel(this.extractProductIds())
        }
    }

    onCloseDetail=(refresh) => {
        this.props.productDetailOpened(false);
        this.props.deselectCurrentProductRow();
        this.props.updateCurrentProduct(null);
        if (refresh) this.props.fetchProductItems(this.props.currentFilter)
    }

    render() {
        this.props.onBeforeRender && this.props.onBeforeRender();
        const activeProduct = this.props.currentProduct ? this.props.currentProduct : null;

        const productDetailProps = {
            isOpen: this.props.productDetailOpenedProp,
            onClose: this.onCloseDetail,
            producto: activeProduct,
            labels: this.props.labels,
            dispatch: this.props.dispatch,
            fetchProductItems: this.props.fetchProductItems,
            currentFilter: this.props.currentFilter,
            tableStrategy: this.props.storeType,
            canonicalProduct: activeProduct,
            showRowWhenYouDoNotSellTheProduct: false,
            stores: this.props.stores,
            lastFilter: this.props.lastFilter,
            hidePublicLabels: this.props.hidePublicLabels
        }

        return (
            <Col md={12} lg={12} className="product-list-table-container">
                <Card>
                    <CardBody>
                        {activeProduct !== null && isEqual(activeProduct.postpaid, 1) ?
                        <PostPagoDetail 
                            {...productDetailProps} />
                         :
                         <ProductDetail 
                            {...productDetailProps} />
                         }
                        <div className={fixedHeadersTableClass} style={{ margin: 0 }}>

                            <ProductSelectionSummaryContainer
                                parentSelector={".custom-table-header-mini-product-sheet"}
                                overlappedHeadersSelector={".rthfc-th-fixed"}
                                onSelectCancellation={this.onSelectCancellation}
                                onAllSelected={this.onChangeCheckAll}
                                className={selectionSummaryClass}
                                shouldDisplay={TableStateUtils.shouldShowProductSelectionSummary(this.props)}
                            />

                            <ReactTableFixedColumns
                                isFetching={this.props.isFetching}
                                currentFilter={this.props.currentFilter}
                                activeRanking={this.props.activeRanking}
                                currentProduct={this.props.activeProduct}
                                productDetailOpenedProp={this.props.productDetailOpenedProp}
                                data={this.props.productItems}
                                alertModalState={this.props.alertModalState}
                                retailItems={this.props.retailItems}
                                favoriteProductItems={this.props.favoriteProductItems}
                                productItems={this.props.productItems}
                                paginationCount={this.props.paginationCount}
                                selectedProducts={this.props.selectedProducts}
                                labels={this.props.labels}
                                isOpenLabelEditModal={this.props.isOpenLabelEditModal}
                                shouldReloadTableFromLabelDeletion={this.props.shouldReloadTableFromLabelDeletion}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    let canonicalProduct = rowInfo.original;
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            this.onRowClick(e, canonicalProduct);
                                            if (handleOriginal) {
                                                handleOriginal();
                                            }
                                        },
                                        style: { textAlign: 'center' }
                                    };
                                }}
                                column={{
                                    ...ReactTableDefaults.column,
                                    sortMethod: this.props.onSort
                                }}
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            if (column.isSortable) {
                                                let nextSortDirection = (column.sortDirection === 'ASC' ? 'DESC' : 'ASC');
                                                this.props.onSort(column.key, nextSortDirection);
                                            }
                                            if (handleOriginal) {
                                                handleOriginal();
                                            }
                                        }
                                    };
                                }}
                                columns={this.props.headers}
                                defaultPageSize={20}
                                minRows={0}
                                className="-highlight product-list-table product-list-table-operator"
                                id="fixedHeaderTable"
                                sortable={false}
                                loadingText={null}
                                noDataText={null}
                                PaginationComponent={(props, b, c) => {
                                    if (!this.props.paginationCount) {
                                        return null;
                                    }

                                    return <PaginationHelper
                                        count={this.props.paginationCount}
                                        currentPage={this.props.paginationCurrentPage}
                                        pageSize={this.props.pageSize}
                                        onChangePage={this.props.onChangePage}
                                        allowSetCurrentPage />
                                }}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

const TableView = WithGenericTableView.withGenericTableView(Table, tableId);
export default TableView;
