import { connect } from 'react-redux';
import CheckboxLabel from './CheckboxLabel';
import { addProductsToLabel, removeProductsToLabel } from '../LabelsState';
import { showLabelsForm } from '../label-new-button/ButtonNewLabelActions';
import { Constantes, RetailCompassStorage} from '@common/utils';

const shouldTriggerLabelsForm = {
    [Constantes.StoreType.RETAILER]: true ,
    [Constantes.StoreType.PROVIDER]: false
}; 

export const mapStateToProps = (state, ownProps) => {    
    const storeType = RetailCompassStorage.getStoreType()
    return {
        selectedProducts: state.labelStateReducer.newLabel.selectedProducts,
        shouldTriggerLabelsForm: shouldTriggerLabelsForm[storeType]
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        addProductsToLabel: (products) => addProductsToLabel(dispatch, products),
        removeProductsToLabel: (products) => removeProductsToLabel(dispatch, products),
        showLabelsForm: () => dispatch(showLabelsForm())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxLabel);