
import React, { createContext, useContext, useReducer } from 'react'
import FilterReducer, { initialFilters } from './FiltersReducer';

const FiltersContext = createContext();

const FilterProvider = ({ children }) => {

    const [filters, dispatch] = useReducer(FilterReducer, initialFilters)

    return (
        <FiltersContext.Provider value={[filters, dispatch]}>
            {children}
        </FiltersContext.Provider>
    )
}

const useFilters = () => useContext(FiltersContext)[0];

const useFilterDispatch = () => useContext(FiltersContext)[1];

export { FiltersContext, useFilters, useFilterDispatch }
export default FilterProvider