import React from 'react';
import * as HistoricUtils from "../../Historic/historicUtils"
import { DateRangePicker } from "./DateRangePicker"
import { RangeDateOptions } from "./RangeDateOptions"
import FilterFieldLayout from "../FilterFieldLayout"

export const FilterRangeDate = ({ startDate, endDate, onDateFilter, categoryEvent }) => {
    return (
        <div className="historic-chart-filters">
            <div className={"form historic-date-range"}>
                <FilterFieldLayout fieldLabel="Rango de fechas" fieldClass="date-range-picker-container">
                    <div className="date-range-picker">
                        <RangeDateOptions
                            start={startDate}
                            end={endDate}
                            onSelectOption={onDateFilter}
                            options={HistoricUtils.RANGE_OPTIONS}
                            categoryEvent={categoryEvent}
                        />
                        <DateRangePicker
                            start={startDate}
                            end={endDate}
                            onButtonClick={onDateFilter}
                            categoryEvent={categoryEvent}
                        />
                    </div>
                </FilterFieldLayout>
            </div>
        </div>
    )
}