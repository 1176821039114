import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const request = (state, action) => {
    return updateObject(state, {
        isSavingRelationshipProductAttributeNewLov: true,
        hasErrorSavingRelationshipProductAttributeNewLov: false
    });
}

export const receiveSuccess = (state, action) => {
    return updateObject(state, {
        isSavingRelationshipProductAttributeNewLov: false,
        hasErrorSavingRelationshipProductAttributeNewLov: false
    });
}

export const receiveError = (state, action) => {
    return updateObject(state, {
        isSavingRelationshipProductAttributeNewLov: false,
        hasErrorSavingRelationshipProductAttributeNewLov: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS]: request,
    [ValidationActions.RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS]: receiveSuccess,
    [ValidationActions.RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS_ERROR]: receiveError
}

export default handlers