import React from 'react';

export const ProductMarker = ({ isMyProduct, hideMarker, id, classNames }) => {

    let classMarker = "my-product-marker"
    if (isMyProduct && !hideMarker)
        classMarker += " my-product-marker--mine " + classNames

    return (
        <div id={id} className={classMarker}></div>
    )
}