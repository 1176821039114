import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import { get } from "lodash";
import { translate } from "react-i18next";
import DownloadIcon from "mdi-react/DownloadIcon";
import PropTypes from "prop-types";
import {
    RetailCompassStorage,
    GaUtils,
    Constantes,
    Intercom,
    IntercomConstants,
    ChurnZero
} from "@common/utils/";
import { withRouter } from "react-router";

export class DownloadExcel extends PureComponent {
    static propTypes = {
        downloadFile: PropTypes.func.isRequired,
        lastSearchRequest: PropTypes.object,
        paginationCount: PropTypes.number,
        isDownloading: PropTypes.bool,
        className: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.downloadProductList = this.downloadProductList.bind(this);
    }

    downloadProductList(e) {
        let searchRequest = {
            ...this.props.lastSearchRequest,
            size: this.props.paginationCount,
            page: 0
        };
        this.props.downloadFile({
            searchRequest: searchRequest,
            moduleType: RetailCompassStorage.getStoreType(),
            userId: RetailCompassStorage.getUser().id
        });
        this.trackEvent();
    }

    trackEvent() {
        const pathname = window.location.pathname;
        const url = `${pathname}/${Constantes.GA_CATEGORY_EVENTS.DOWNLOAD_SHEET}`;
        const category = RetailCompassStorage.getCurrentCanonicalCategory();

        GaUtils.trackGAPage(url);
        ChurnZero.trackEvent({
            eventName: "scalibur_descarga_excel",
            customFields: { categoria: category.name }
        });
        Intercom.trackEvent(IntercomConstants.EVENTS.DOWNLOADED_EXCEL);
    }

    render() {
        return (
            <Button
                size={"sm"}
                className={
                    "icon export-button-container export-button " +
                    get(this.props, "className", "")
                }
                disabled={this.props.isDownloading}
                outline
                onClick={this.downloadProductList}
            >
                <DownloadIcon />
                Descargar en excel
            </Button>
        );
    }
}

export default withRouter(translate("common")(DownloadExcel));
