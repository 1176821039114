import React from 'react';
import { StoreService } from '@common/data-access';
import { Constantes, RetailCompassStorage } from "@common/utils";
import { ItemMenu } from './MenuItem';
import { flatten, sortBy, uniq } from 'lodash';
import { useEffectOnce } from 'react-use';

export const Menu = () => {

    const stores = RetailCompassStorage.getTotalStores();
    const [apps, setApps] = React.useState([])

    useEffectOnce(() => {
        const getApps = async () => {
            const ids = stores.map(store => store.id)
            const _apps = await StoreService.getAvailableApps(ids)

            const allowedApps = uniq(
                flatten(stores.map((s) => s.allowedApps))
            )

            _apps.forEach((app) => {
                app.enabled = allowedApps.includes(app.internalName)
                app.active = app.internalName === Constantes.APP_NAME
            })
            setApps(_apps)
        }

        getApps();
    })

    return (
        <div className='menu-options'>
            {
                sortBy(apps, ["order"]).map((item, index) =>
                    <ItemMenu item={item} key={index} />
                )
            }
        </div>
    )
}