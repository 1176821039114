import React, { PureComponent } from 'react';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEqual, some } from 'lodash';
import { translate } from 'react-i18next';
import queryString from 'qs';
import { WhereAmINav } from '../WhereAmINav';
import {
    Hotjar, Utils, TrackJS, ReduxFormsUtils, Constantes, Delighted, IntercomListener,
    RetailCompassStorage, RouterUtils, ClientSuccess
} from '@common/utils/'
import AlertModal from '../../../alerts/components/AlertModal'
import LabelSynchronization from '../../../labels/LabelSynchronization'
import BellIcon from 'mdi-react/BellIcon';

const filterElementsContainerClassName = "filter-fields-container";

export class ProductListGeneric extends PureComponent {

    static propTypes = {
        currentCanonicalCategory: PropTypes.object,
        currentRouterView: PropTypes.string,
        applicationData: PropTypes.object,
        currentFilter: PropTypes.object,
        fetchProductItems: PropTypes.func.isRequired,
        resetStoreAction: PropTypes.func.isRequired,
        hasProducts: PropTypes.bool,
        lastSearchRequest: PropTypes.object,
        reduxFormInitialValues: PropTypes.object,
        lastFilter: PropTypes.object,
        paginationCount: PropTypes.number.isRequired,
        isDownloading: PropTypes.bool,
        fetchFavorites: PropTypes.func,
        visibleLabelsForm: PropTypes.bool,
        resetSelection: PropTypes.func.isRequired,
        mainFiltersContainer: PropTypes.elementType,
        emptyStateView: PropTypes.elementType.isRequired,
        filtersContainer: PropTypes.elementType.isRequired,
        myAggregations: PropTypes.object,
        mainContainerClassName: PropTypes.string,
        selectView: PropTypes.elementType,
        contentContainer: PropTypes.elementType.isRequired,
        buttonBackPressedNavigate: PropTypes.func.isRequired,
        tableContainerClassName: PropTypes.string,
        hideBackLink: PropTypes.bool,
        dispatchSubRouterState: PropTypes.func,
        resetSubRouterState: PropTypes.func,
        showWhereAmINavComponent: PropTypes.bool
    };

    static defaultProps = {
        mainFiltersContainer: null,
        showWhereAmINavComponent: true
    };

    constructor(props) {
        super(props);
        this.state = { needRerender: false, summaryBrandKey: this.generateSummaryBrandKey() };
        this.buttonAlertDOM = null;
        this.scrollFiltersDOM = null;
        this.fetchProductItems = this.fetchProductItems.bind(this);
        this.generateSummaryBrandKey = this.generateSummaryBrandKey.bind(this);
        this.showAlertModal = this.showAlertModal.bind(this);
        this.doFiltersOverflow = this.doFiltersOverflow.bind(this);
        this.handleAlertShadow = this.handleAlertShadow.bind(this);
        this.onCloseLabelsForm = this.onCloseLabelsForm.bind(this);
        this.receiveFavoriteResponseCallback = this.receiveFavoriteResponseCallback.bind(this);
        this.processNavigationChanges = this.processNavigationChanges.bind(this);
        this.dispatchSubRouterStateChanges = this.dispatchSubRouterStateChanges.bind(this);
        this.historyEventListener = this.historyEventListener.bind(this);
        this.resetCounters = this.resetCounters.bind(this);
        this.resetReports = this.resetReports.bind(this);
        this.shouldLoadFavoriteService = this.shouldLoadFavoriteService.bind(this);
        this.isAlternativeRequestSearch = this.isAlternativeRequestSearch.bind(this);
    }

    dispatchSubRouterStateChanges(urlSearchParams) {
        let report = urlSearchParams[Constantes.URL_PARAMS.REPORT];
        let subReport = urlSearchParams[Constantes.URL_PARAMS.SUBREPORT];
        let selectedView = urlSearchParams[Constantes.URL_PARAMS.SELECTED_VIEW];
        this.props.dispatchSubRouterState && this.props.dispatchSubRouterState(selectedView, report, subReport);
    }

    historyEventListener(location, action) {
        if (action === 'POP') {
            let urlSearchParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
            let selectedView = urlSearchParams[Constantes.URL_PARAMS.SELECTED_VIEW];

            if (selectedView === this.props.selectedView) {
                this.props.buttonBackPressedNavigate(true);
            } else if (Object.keys(urlSearchParams) <= 0) {
                if (this.props.selectedView === Constantes.TYPE_VIEW_RETAIL.DEFAULT) {
                    this.props.buttonBackPressedNavigate(true);
                } else {
                    this.props.resetSubRouterState();
                }
            } else {
                this.dispatchSubRouterStateChanges(urlSearchParams);
            }
        }
    }

    UNSAFE_componentWillMount() {
        this.unlisten = this.props.history.listen(this.historyEventListener);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleAlertShadow);
        window.removeEventListener('focus', this.handleFocus);
        this.unlisten && this.unlisten();
    }

    componentDidMount() {
        this.setState({
            needRerender: this.props.history.location && this.props.history.location.state && this.props.history.location.state.needRerender
        });
        Hotjar.load();
        Delighted.load();
        Utils.onUrlChange(this.props.history.location.pathname + this.props.history.location.search);
        if (RetailCompassStorage.isTrackClientSuccessRequired()) {
            ClientSuccess.track();
            RetailCompassStorage.setTrackClientSuccessRequired(null);
        }
        window.addEventListener('resize', this.handleAlertShadow);
        window.addEventListener('focus', this.handleFocus);
    }

    handleFocus = e => {
        let storeId = RetailCompassStorage.getStoreId();
        let currentCanonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
        if (!storeId) {
            window.location.reload();
        }
        //si se cambia de tienda en otro tab se refresca la página para que tome la información del LocalStorage
        const baseUrl = RouterUtils.buildModuleViewPath(RouterUtils.MODULES_VIEWS.LIST);
        if ((storeId && this.props.applicationData.storeId !== storeId)
            || this.props.currentCanonicalCategory.id !== currentCanonicalCategory.id) {
            window.location.href = baseUrl
        }
    };

    fetchProductItems(params, resetCurrentPage) {
        let currentFilter = { ...this.props.currentFilter, ...params };
        if (resetCurrentPage) {
            currentFilter.paginationCurrentPage = 1;
        }
        if (this.props.applicationData) {
            currentFilter.applicationData = this.props.applicationData;
        }
        if (this.props.subReport) {
            currentFilter.subReport = this.props.subReport;
        }
        this.props.fetchProductItems(currentFilter);
    }

    selectedViewChanged(prevProps) {
        return (this.props.selectedView === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST &&
            prevProps.selectedView === Constantes.TYPE_VIEW_RETAIL.REPORT);
    }

    receiveFavoriteResponseCallback(response, reduxFormInitialValues) {
        let favoriteId = null;
        if (response && response.favorite && response.favorite.id) {
            favoriteId = response.favorite.id;
        }
        this.fetchProductItems({ ...reduxFormInitialValues, reduxFormInitialValues: reduxFormInitialValues, favoriteId: favoriteId });
    }

    resetCounters() {
        let params = {}
        params[Constantes.URL_PARAMS.SAME] = undefined;
        params[Constantes.URL_PARAMS.CHEAPER] = undefined;
        params[Constantes.URL_PARAMS.EXPENSIVE] = undefined;
        params[Constantes.URL_PARAMS.HAVE_IT] = undefined;
        params[Constantes.URL_PARAMS.DONT_HAVE_IT] = undefined;
        return params;
    }

    resetReports() {
        let params = {}
        params[Constantes.URL_PARAMS.REPORT] = undefined;
        params[Constantes.URL_PARAMS.SUBREPORT] = undefined;
        return params;
    }

    shouldLoadFavoriteService(currentCanonicalCategoryChanged, currentRouterViewChanged) {
        if (!this.props.currentFilter.favoriteId) {
            return true;
        }
        return some([
            currentCanonicalCategoryChanged, currentRouterViewChanged], Boolean
        );
    }

    shouldLoadLabelsService(currentCanonicalCategoryChanged, currentRouterViewChanged) {
        return some([
            currentCanonicalCategoryChanged, currentRouterViewChanged], Boolean
        );
    }

    processNavigationChanges(prevProps) {
        let currentRouterViewChanged = !isEqual(this.props.currentRouterView, prevProps.currentRouterView);
        let currentCanonicalCategoryChanged = !isEqual(this.props.currentCanonicalCategory, prevProps.currentCanonicalCategory);
        let backPressedNavigate = (this.props.backPressedNavigate && !prevProps.backPressedNavigate);
        let selectedViewChanged = !isEqual(this.props.selectedView, prevProps.selectedView);

        // if the category changed and so did the current view, we will give priority to changing the category
        if (currentCanonicalCategoryChanged && selectedViewChanged) {
            selectedViewChanged = false;
        }

        let navigationChanged = some([
            currentCanonicalCategoryChanged, currentRouterViewChanged,
            backPressedNavigate, selectedViewChanged], Boolean
        );

        if (navigationChanged) {
            this.setState({ needRerender: false, summaryBrandKey: this.generateSummaryBrandKey() });

            // when the user changes the tab or press back button, we must not reset the application states
            if (!selectedViewChanged) {
                ReduxFormsUtils.restoreState(this.props.dispatch, {});
                if (!backPressedNavigate) {
                    this.props.resetStoreAction();
                }
            }

            let urlSearchParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
            let reduxFormInitialValues = ReduxFormsUtils.converterUrlSearchParamsToFormFields(urlSearchParams);

            // the idea of this is that even if it does not exist in `reduxFormInitialValues`, add the key to be replaced in currentFilter
            reduxFormInitialValues[Constantes.URL_PARAMS.SELECTED_VIEW] = reduxFormInitialValues[Constantes.URL_PARAMS.SELECTED_VIEW];
            reduxFormInitialValues[Constantes.URL_PARAMS.REPORT] = reduxFormInitialValues[Constantes.URL_PARAMS.REPORT];
            reduxFormInitialValues[Constantes.URL_PARAMS.SUBREPORT] = reduxFormInitialValues[Constantes.URL_PARAMS.SUBREPORT];

            // we ignore report and subReport url params if the view was changed by the user
            if (selectedViewChanged) {
                if (prevProps.selectedView === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST) {
                    reduxFormInitialValues = { ...reduxFormInitialValues, ...this.resetCounters() }
                }
                reduxFormInitialValues[Constantes.URL_PARAMS.SELECTED_VIEW] = this.props.selectedView;
            }

            // if the `favoriteId` already exists or current category changed, we avoid calling the favorites service again
            let shouldLoadFavoriteService = this.shouldLoadFavoriteService(currentCanonicalCategoryChanged, currentRouterViewChanged);

            if (shouldLoadFavoriteService) {
                this.props.fetchFavorites((response) => this.receiveFavoriteResponseCallback(response, reduxFormInitialValues));
            } else {
                this.fetchProductItems({ ...reduxFormInitialValues, reduxFormInitialValues: reduxFormInitialValues });
            }

            let shouldLoadLabelsService = this.shouldLoadLabelsService(currentCanonicalCategoryChanged, currentRouterViewChanged);
            // it's not required to call to labels-endpoint when the user changes the current view
            if (shouldLoadLabelsService) {
                this.props.fetchListLabels({ categoryId: this.props.currentCanonicalCategory.id });
            }
        }
    }

    isAlternativeRequestSearch(lastFilter, currentFilter) {
        let isAlternativeRequestSearch;

        if ((Utils.isNullOrUndefined(lastFilter.search) && Utils.isNullOrUndefined(currentFilter.search))
            || (Utils.isNullOrUndefined(lastFilter.searchType) && Utils.isNullOrUndefined(currentFilter.searchType))) {

            isAlternativeRequestSearch = false;
        } else {
            const isSameSearch = lastFilter.search === currentFilter.search;
            const isSameSearchType = lastFilter.searchType === currentFilter.searchType;
            isAlternativeRequestSearch = isSameSearch && !isSameSearchType;
        }

        return isAlternativeRequestSearch;
    }

    componentDidUpdate(prevProps) {
        this.processNavigationChanges(prevProps);

        // initialize the redux form if the user has an active filter.
        if (Utils.getObjectKeysSize(this.props.reduxFormInitialValues) > 0 &&
            !isEqual(this.props.reduxFormInitialValues, prevProps.reduxFormInitialValues)) {
            ReduxFormsUtils.restoreState(this.props.dispatch, this.props.reduxFormInitialValues);
        }

        // this happens when the api was called:
        // each time the API was called, we updated the URL ...
        // ... search parameters and included the new filters.
        if (prevProps.isFetching && !this.props.isFetching) {
            const lastFilter = { ...this.props.lastFilter };
            const currentFilter = ReduxFormsUtils.converterUrlSearchParamsToFormFields(queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }));
            
            const isAlternativeRequestSearch = this.isAlternativeRequestSearch(lastFilter, currentFilter)
            const nextUrlSearchParams = ReduxFormsUtils.converterFormFieldsToUrlSearchParams(lastFilter);
            const currentUrlSearchParams = ReduxFormsUtils.converterFormFieldsToUrlSearchParams(currentFilter);

            if (!isAlternativeRequestSearch && currentUrlSearchParams !== nextUrlSearchParams) {
                this.props.history.push({ search: nextUrlSearchParams });
                Utils.onUrlChange(this.props.history.location.pathname + this.props.history.location.search);
            } else {
                /* si no se trata de una busqueda alternativa se hace 
                replace para reflejar cambios en la url, por ejemplo cuando 
                searchType cambia de SKUS a KEYWORDS */
                this.props.history.replace({ search: nextUrlSearchParams });
            }
        }

        if (!prevProps.mustForceProductListUpdate && this.props.mustForceProductListUpdate) {
            this.fetchProductItems({});
        }
    }

    generateSummaryBrandKey() {
        return 'summaryBrand-' + Utils.generateUniqueID();
    }
    showAlertModal(e) {
        this.buttonAlertDOM.blur();
        this.props.showAlertModal();
    }

    doFiltersOverflow() {
        let container = document.querySelector("#sideBarScrollbar");
        let content = document.querySelector("#sideBarScrollbar ." + filterElementsContainerClassName);
        if (!content || !container) {
            return false;
        }
        return container.offsetHeight - content.offsetHeight < 0;
    }

    handleAlertShadow() {
        let alert = document.querySelector(".filters-alert-container");
        if (alert && alert.classList) {
            if (this.doFiltersOverflow()) {
                alert.classList.add("filters-alert-container--uppershadow");
            } else {
                alert.classList.remove("filters-alert-container--uppershadow");
            }
        }
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        TrackJS.track({ error: error, info: info, filter: { ...this.props.currentFilter } });
        TrackJS._console({ ...this.props.currentFilter });
    }

    onCloseLabelsForm() {
        this.props.resetSelection();
        this.props.hideLabelsForm();
    }

    render() {
        const { emptyStateType, alertModalState, t, currentCanonicalCategory, history, hideBackLink } = this.props;
        let MainFiltersContainer = this.props.mainFiltersContainer;
        let EmptyStateView = this.props.emptyStateView;
        let FiltersContainer = this.props.filtersContainer;
        let SelectView = this.props.selectView;
        let ContentContainer = this.props.contentContainer;
        let filterApplied = Utils.hasFilterAppliedExcludeMainFilter(this.props.currentFilter, false);
        let sidebarClass = classNames({
            'sidebar': true,
            'sidebar--collapse': false
        });
        return (
            <div className={'container__wrap product-list-wrapper ' + (this.props.mainContainerClassName || '')}>

                <LabelSynchronization />
                <IntercomListener locationSearch={this.props.history.location.search} />

                <Container className="product-list-filters-container">
                    {alertModalState.isOpen ?
                        <AlertModal />
                        : null}

                    {this.props.showWhereAmINavComponent &&
                        <WhereAmINav
                            showGoBackToLink={!hideBackLink}
                            currentViewText={t('dashboard')}
                            goBackToText={t('dashboard').toLowerCase()}
                            goBackToPath={'dashboard'}
                            t={t}
                            history={history}
                            key={"list-where-am-i"}
                        />}

                    <div className="product-list-filters">
                        <div className={sidebarClass}>
                            <div className='sidebar__back' onClick={() => { }} />
                            <div className={"sidebar__scroll scroll" + (filterApplied ? " filterApplied" : "")} id="sideBarScrollbar" style={{ overflowY: "auto" }} >
                                <div className='sidebar__wrapper sidebar__wrapper--desktop'>
                                    <FiltersContainer elementsContainerClassName={filterElementsContainerClassName} />
                                </div>
                            </div>
                            {filterApplied ?
                                <div className={"filters-alert-container" + (this.doFiltersOverflow() ? " filters-alert-container--uppershadow" : "")}>
                                    <button type="button" ref={(buttonDOM) => { this.buttonAlertDOM = buttonDOM; }} onClick={this.showAlertModal} className="btn btn-outline-secondary btn-sm btn-show-alerts"  >
                                        <BellIcon size="14" /> Crear alerta con estos filtros
                                    </button>
                                </div>
                                : null}
                        </div>
                    </div>
                    {MainFiltersContainer && <MainFiltersContainer />}

                    {SelectView ? <SelectView /> : null}

                    {!emptyStateType ?
                        <div className={this.props.mainContainerClassName || ""}>
                            <ContentContainer {...this.props} />
                        </div>
                        :
                        <EmptyStateView
                            restoreInitialState={this.props.restoreInitialState}
                            deactivateAllFilters={this.props.deactivateAllFilters}
                            restoreLastState={this.props.restoreLastState}
                            lastFilter={this.props.lastFilter}
                            lastFilterWithProducts={this.props.lastFilterWithProducts}
                            currentFilter={this.props.currentFilter}
                            totalFavorites={this.props.favoriteProductItems.length}
                            paginationCount={this.props.paginationCount}
                            emptyStateType={this.props.emptyStateType}
                            isRestoringLastState={this.props.isRestoringLastState}
                            isRestoringInitialState={this.props.isRestoringInitialState}
                            sort={this.props.sort}
                        />}

                </Container>
            </div>
        )
    }
}

export default (translate('common')(ProductListGeneric));
