import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import ColorSample from '../label-color/ColorSample';
import { LabelsUtils, Utils, Constantes, Intercom, IntercomConstants } from '@common/utils/';
import LabelsList from '../label-list/FormLabelsList';
import { Tooltip, Row, Col } from 'reactstrap';
import { isEqual } from 'lodash';
import ButtonNewLabel from '../label-new-button/ButtonNewLabel'
import LabelsFormCheckbox from './LabelsFormCheckbox'
import AddLabelProductModal from '../modals/AddLabelProductModal';

export default class LabelsForm extends Component {
    static propTypes = {
        name: PropTypes.string,
        totalSelectedProducts: PropTypes.number,
        currentCanonicalCategory: PropTypes.object.isRequired,
        createLabel: PropTypes.func.isRequired,
        patchLabel: PropTypes.func.isRequired,
        selectedProducts: PropTypes.array,
        productItems: PropTypes.array,
        errorMessage: PropTypes.string,
        hasError: PropTypes.bool,
        isCreating: PropTypes.bool,
        isCreated: PropTypes.bool,
        labels: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            name: this.validateName(props.name || ""),
            isPublic: false,
            hasInvalidProductsOrName: false,
            showPopup: false,
            currentLabel: undefined,
            showFormNewLabel: true,
            forceFormNewLabel: false,
            color: this.getNextLabelColor(),
            popupMessage: this.defaultPopupMessage(),
            buttonClicked: false,
            showModalLabel: false
        }
        this.getValidatedState = this.getValidatedState.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleIsPublicChange = this.handleIsPublicChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getNextLabelColor = this.getNextLabelColor.bind(this);
        this.buildBody = this.buildBody.bind(this);
        this.buildPatchBody = this.buildPatchBody.bind(this);
        this.onLabelSelected = this.onLabelSelected.bind(this);
        this.handleSubmitCreate = this.handleSubmitCreate.bind(this);
        this.handleSubmitPatch = this.handleSubmitPatch.bind(this);
        this.defaultPopupMessage = this.defaultPopupMessage.bind(this);
        this.onCloseLabelsForm = this.onCloseLabelsForm.bind(this);
        this.validateName = this.validateName.bind(this);
        this.newLabelOnclick = this.newLabelOnclick.bind(this);
        this.checkShowFormNewLabel = this.checkShowFormNewLabel.bind(this);
        this.submitWithEnter = this.submitWithEnter.bind(this);
        this.onClickConfirmationModal = this.onClickConfirmationModal.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.getLabelsFiltered = this.getLabelsFiltered.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.checkShowFormNewLabel();
    }

    componentDidUpdate(prevProps) {
        const newLabels = this.props.labels;
        const oldLabels = prevProps.labels;
        if (Utils.isArrayWithData(newLabels) && !isEqual(newLabels, oldLabels)) {
            this.checkShowFormNewLabel();
        } else if (Utils.isArrayWithData(oldLabels) && !Utils.isArrayWithData(newLabels)) {
            // it happens when a user deletes a label
            this.checkShowFormNewLabel();
        }
    }

    getNextLabelColor() {
        return LabelsUtils.getNextLabelColor(this.props.labels)
    }

    defaultPopupMessage() {
        return "Recuerda ponerle nombre al label y seleccionar productos"
    }

    checkShowFormNewLabel() {
        this.setState({
            showFormNewLabel: !Utils.isArrayWithData(this.props.labels),
            color: this.getNextLabelColor()
        })
    }

    newLabelOnclick() {
        this.setState({
            forceFormNewLabel: true
        })
    }

    componentDidMount() {
        this.setState(this.getValidatedState(this.state.name, this.props.totalSelectedProducts));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let state = this.getValidatedState(nextProps.name || this.state.name, nextProps.totalSelectedProducts);
        this.setState(state);

        //check error and validation error
        if (nextProps.hasError && !state.hasInvalidProductsOrName) {
            this.setState({
                popupMessage: "Ya tienes un label creado con el mismo nombre",
                showPopup: true,
            })
        }
        //check if saved ok
        if (nextProps.isCreated && this.props.isCreating) {
            //todo
            this.onCloseLabelsForm();
            this.setState({ forceFormNewLabel: false, showFormNewLabel: false, buttonClicked: false, name: "" });
        }
    }

    onCloseLabelsForm() {
        this.props.resetSelection();
    }

    getValidatedState(name, total) {
        let hasInvalidProductsOrName = total < 1 || !name || !name.trim();
        return {
            name: this.validateName(name),
            hasInvalidProductsOrName,
            popupMessage: this.defaultPopupMessage(),
            showPopup: false
        };
    }

    validateForm(nextProps) {
        let hasInvalidProductsOrName = nextProps.totalSelectedProducts < 1 || this.state.name.length < 1;
        //set next color if null
        if (!this.state.color || !this.state.color.className) {
            this.setState({
                color: this.getNextLabelColor()
            })
        }
        this.setState({
            hasInvalidProductsOrName: hasInvalidProductsOrName,

        })
        return hasInvalidProductsOrName;
    }

    validateName(name) {
        if (!name || !name.trim()) return "";
        if (name.length >= 21) {
            return name.substring(0, 21);
        }
        return name;
    }

    handleNameChange(event) {
        if (!event || !event.target) return;
        let name = event.target.value;
        if ((Constantes.LABEL_FORM_REGEX_VALIDATION.IS_HTML_TAG.test(name))) {
            name = "";
        }
        if (name.length < 21) {
            this.setState(this.getValidatedState(name, this.props.totalSelectedProducts));
        }

    }

    handleIsPublicChange(checked) {
        this.setState({
            isPublic: checked
        })
    }

    buildBody() {
        let body = {
            categoryId: this.props.currentCanonicalCategory.id,
            name: this.state.name.trim(),
            color: this.state.color.className,
            productIds: this.props.selectedProducts,
            accessType: this.state.isPublic ? "PUBLIC" : "PRIVATE",
        };
        return body;
    }

    buildPatchBody() {
        let body = {
            labelId: this.state.currentLabel.labelId,
            productIds: this.props.selectedProducts
        };
        return body;
    }

    onLabelSelected(labelsSelected) {
        if (!Utils.isArrayWithData(labelsSelected) || !Utils.isArrayWithData(this.props.selectedProducts)) {
            return;
        }

        this.setState({
            currentLabel: labelsSelected[0]
        })

        if (labelsSelected[0].accessType === Constantes.ACCESS_TYPES_LABEL.PUBLIC) {
            this.setState({ showModalLabel: true });
        } else {
            setTimeout(() => {
                this.handleSubmit();
            })
        }
    }

    onClickCancel() {
        this.setState({ showModalLabel: false })
    }

    onClickConfirmationModal() {
        this.handleSubmit();
        this.setState({ showModalLabel: false });
    }

    handleSubmitCreate() {
        Intercom.trackEvent(IntercomConstants.EVENTS.CREATED_LABEL);
        if (!this.state.buttonClicked) {
            this.setState({ buttonClicked: true })
        }
        let hasInvalidProductsOrName = this.validateForm(this.props);
        if (hasInvalidProductsOrName) return;
        this.props.createLabel(this.buildBody());
        this.props.resetSelection();
        this.props.hideLabelsForm();
    }

    handleSubmitPatch() {
        this.props.patchLabel(this.buildPatchBody());
    }

    handleSubmit(e) {
        if (this.state.showFormNewLabel || this.state.forceFormNewLabel) {
            this.handleSubmitCreate();
        } else {
            this.handleSubmitPatch();
        }
    }

    submitWithEnter(e) {
        if (e.key === 'Enter') {
            this.handleSubmit(e);
            e.preventDefault();
        }
    }

    getLabelsFiltered() {
        let labels = this.props.labels;
        if (!labels) labels = []
        return labels.filter(label => LabelsUtils.validateHidePublicLabel(this.props.hidePublicLabels, label));
    }

    render() {
        let { showFormNewLabel, forceFormNewLabel, showModalLabel } = this.state;
        return (
            <div className="labels-form-container">
                {
                    !forceFormNewLabel && !showFormNewLabel ?
                        <div>
                            <Row>
                                <Col xs="9">
                                    <div><strong>Tus Labels</strong></div>
                                    <div>Selecciona los productos y el label que le quieres asignar</div>
                                </Col>
                            </Row>

                            <Row className="labels-list-and-add-label-button">
                                <Col xs={12}>
                                    <div className="button-new-label-container">
                                        <ButtonNewLabel visibleLabelsForm={false} showLabelsForm={this.newLabelOnclick} hideLabelsForm={() => { }} />
                                    </div>

                                    <LabelsList
                                        labels={this.getLabelsFiltered()}
                                        applyHover={Utils.isArrayWithData(this.props.selectedProducts)}
                                        filterLabels={[]}
                                        selectionType='single'
                                        onLabelSelection={this.onLabelSelected}
                                        onLabelRemoval={(items) => { }}
                                    />
                                    {showModalLabel && <AddLabelProductModal onCancel={this.onClickCancel} onConfirm={this.onClickConfirmationModal} />}
                                </Col>
                            </Row>

                        </div>
                        :

                        <form onSubmit={this.handleSubmit}>
                            <div className="title-container">
                                <div className="title">Crear label</div>
                                <div className="subtitle">Selecciona los productos que quieres agregar al label</div>
                            </div>
                            <div className="form-container form">
                                <div className="input-container">
                                    <Label for="label-name">Nombre label</Label>
                                    <input name="label-name" id="label-name" className="text-field-retail text-field-color-default" value={this.state.name} onChange={this.handleNameChange}
                                        onKeyPress={this.submitWithEnter} />
                                    {this.state.color && this.state.color.color ?
                                        <ColorSample color={this.state.color.color} size={18} />
                                        : null}
                                </div>

                                <div className="public-container" >
                                    <LabelsFormCheckbox
                                        isPublic={this.state.isPublic}
                                        onChangeValue={this.handleIsPublicChange}
                                    />
                                </div>

                                <div className="button-container" >
                                    <button id="tooltipWrapper"
                                        className={"retail-btn " + (this.state.hasInvalidProductsOrName || this.props.isCreating || this.state.showPopup ? "disabled" : "")}
                                        onClick={this.handleSubmit}
                                        type="button"
                                    >Crear y asignar label</button>
                                </div>
                                <Tooltip flip={0} className="tooltip-labels" placement='top-start' target={"tooltipWrapper"} isOpen={this.state.showPopup || (this.state.buttonClicked && this.state.hasInvalidProductsOrName)} >
                                    {this.state.popupMessage}
                                </Tooltip>
                            </div>
                        </form>
                }
            </div>
        )
    }
}
