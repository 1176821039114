import { TextStyleValues } from "./Colors";

export const PriceStyles = {
    bestPrice: {
    },
    higherPrice: {
    },
    lowerProductPrice: {
    },
    normalPrice: {
        textDecoration: TextStyleValues.decorationPriceNotAvailable
    }
};
