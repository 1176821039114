import { Utils } from '@common/utils';

const ACTION_BASE_NAME = 'VALIDATION';

export const REQUEST_FIND_MATCHED_FULL_PRODUCT = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_FIND_MATCHED_FULL_PRODUCT');
export const RECEIVE_FIND_MATCHED_FULL_PRODUCT = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FIND_MATCHED_FULL_PRODUCT');
export const RECEIVE_FIND_MATCHED_FULL_PRODUCT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FIND_MATCHED_FULL_PRODUCT_ERROR');
export const RECEIVE_FIND_MATCHED_FULL_PRODUCT_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FIND_MATCHED_FULL_PRODUCT_ABORT_ERROR');

export const REQUEST_PRODUCT_SEARCH_POTENTIAL_MATCHES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_PRODUCT_SEARCH_POTENTIAL_MATCHES');
export const RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES');
export const RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES_ERROR');

export const REQUEST_SAVE_PRODUCT_INFO = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_SAVE_PRODUCT_INFO');
export const RECEIVE_SAVE_PRODUCT_INFO = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_SAVE_PRODUCT_INFO');
export const RECEIVE_SAVE_PRODUCT_INFO_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_SAVE_PRODUCT_INFO_ERROR');

export const REQUEST_SUGGESTED_MATCHES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_SUGGESTED_MATCHES');
export const RECEIVE_SUGGESTED_MATCHES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_SUGGESTED_MATCHES');
export const RECEIVE_SUGGESTED_MATCHES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_SUGGESTED_MATCHES_ERROR');

export const REQUEST_TRANSFORM_INTO_IDENTICAL = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_TRANSFORM_INTO_IDENTICAL');
export const RECEIVE_TRANSFORM_INTO_IDENTICAL = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_TRANSFORM_INTO_IDENTICAL');
export const RECEIVE_TRANSFORM_INTO_IDENTICAL_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_TRANSFORM_INTO_IDENTICAL_ERROR');

export const REQUEST_TRANSFORM_INTO_REMOVED = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_TRANSFORM_INTO_REMOVED');
export const RECEIVE_TRANSFORM_INTO_REMOVED = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_TRANSFORM_INTO_REMOVED');
export const RECEIVE_TRANSFORM_INTO_REMOVED_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_TRANSFORM_INTO_REMOVED_ERROR');

export const REQUEST_REMOVE_IDENTICAL = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_REMOVE_IDENTICAL');
export const RECEIVE_REMOVE_IDENTICAL_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_REMOVE_IDENTICAL_ERROR');
export const RECEIVE_REMOVE_IDENTICAL = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_REMOVE_IDENTICAL');

export const REQUEST_CREATE_NEW_MATCH = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CREATE_NEW_MATCH');
export const RECEIVE_CREATE_NEW_MATCH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_NEW_MATCH');
export const RECEIVE_CREATE_NEW_MATCH_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_NEW_MATCH_ERROR');

export const REQUEST_ALL_MATCHES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_ALL_MATCHES');
export const RECEIVE_ALL_MATCHES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_ALL_MATCHES');
export const RECEIVE_ALL_MATCHES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_ALL_MATCHES_ERROR');

export const REQUEST_CANONICAL_POTENTIAL_DUPLICATES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CANONICAL_POTENTIAL_DUPLICATES');
export const RECEIVE_CANONICAL_POTENTIAL_DUPLICATES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_POTENTIAL_DUPLICATES');
export const RECEIVE_CANONICAL_POTENTIAL_DUPLICATES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_POTENTIAL_DUPLICATES_ERROR');

export const REQUEST_CANONICAL_SEARCH_BY_KEYWORDS = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CANONICAL_SEARCH_BY_KEYWORDS');
export const RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS');
export const RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS_ERROR');

export const REQUEST_CANONICAL_TRANSFORM_INTO_REMOVED = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CANONICAL_TRANSFORM_INTO_REMOVED');
export const RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED');
export const RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED_ERROR');

export const REQUEST_CANONICAL_MERGE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CANONICAL_MERGE');
export const RECEIVE_CANONICAL_MERGE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_MERGE');
export const RECEIVE_CANONICAL_MERGE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_MERGE_ERROR');

export const REQUEST_CANONICAL_TRANSFORM_INTO_CANONICAL = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CANONICAL_TRANSFORM_INTO_CANONICAL');
export const RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL');
export const RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL_ERROR');

export const REQUEST_CANONICAL_MARK_AS_REVIEWED = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CANONICAL_MARK_AS_REVIEWED');
export const RECEIVE_CANONICAL_MARK_AS_REVIEWED = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_MARK_AS_REVIEWED');
export const RECEIVE_CANONICAL_MARK_AS_REVIEWED_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CANONICAL_MARK_AS_REVIEWED_ERROR');

export const REQUEST_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS');
export const RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS');
export const RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS_ERROR');

export const REQUEST_FETCH_HOMOLOGATION_ATTRIBUTES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_FETCH_HOMOLOGATION_ATTRIBUTES');
export const RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES');
export const RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES_ERROR');

export const DISCARD_POTENTIAL_DUPLICATE = Utils.composeActionName(ACTION_BASE_NAME, 'DISCARD_POTENTIAL_DUPLICATE');


export const requestSaveProductInfoAction = (data) => ({
    type: REQUEST_SAVE_PRODUCT_INFO,
    payload: { ...data }
});

export const receiveSaveProductInfoAction = (data) => ({
    type: RECEIVE_SAVE_PRODUCT_INFO,
    payload: { ...data }
});

export const receiveSaveProductInfoErrorAction = (error) => ({
    type: RECEIVE_SAVE_PRODUCT_INFO_ERROR,
    payload: {
        error: error
    }
});

export const requestFindMatchedFullProductAction = () => ({
    type: REQUEST_FIND_MATCHED_FULL_PRODUCT
});

export const receiveFindMatchedFullProductAction = (data) => ({
    type: RECEIVE_FIND_MATCHED_FULL_PRODUCT,
    payload: { ...data }
});

export const receiveFindMatchedFullProductErrorAction = (error) => ({
    type: RECEIVE_FIND_MATCHED_FULL_PRODUCT_ERROR,
    payload: {
        error: error
    }
});

export const receiveFindMatchedFullProductAbortErrorAction = (payload) => ({
    type: RECEIVE_FIND_MATCHED_FULL_PRODUCT_ABORT_ERROR,
    payload: { ...payload }
});

export const requestProductSearchPotentialMatchesAction = () => ({
    type: REQUEST_PRODUCT_SEARCH_POTENTIAL_MATCHES
});

export const receiveProductSearchPotentialMatchesAction = (data) => ({
    type: RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES,
    payload: {
        ...data
    }
});

export const receiveProductSearchPotentialMatchesErrorAction = (error) => ({
    type: RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES_ERROR,
    payload: {
        error: error
    }
});

export const requestSuggestedMatchesAction = (data) => ({
    type: REQUEST_SUGGESTED_MATCHES,
    payload: { ...data }
});

export const receiveSuggestedMatchesAction = (data) => ({
    type: RECEIVE_SUGGESTED_MATCHES,
    payload: {
        suggestedMatches: data
    }
});

export const receiveSuggestedMatchesErrorAction = (error) => ({
    type: RECEIVE_SUGGESTED_MATCHES_ERROR,
    payload: {
        error: error
    }
});

export const requestTransformIntoIdenticalAction = (data) => ({
    type: REQUEST_TRANSFORM_INTO_IDENTICAL,
    payload: { ...data }
});

export const receiveTransformIntoIdenticalAction = (data) => ({
    type: RECEIVE_TRANSFORM_INTO_IDENTICAL,
    payload: {
        ...data
    }
});

export const receiveTransformIntoIdenticalErrorAction = (error) => ({
    type: RECEIVE_TRANSFORM_INTO_IDENTICAL_ERROR,
    payload: {
        error: error
    }
});

export const requestTransformIntoRemovedAction = (data) => ({
    type: REQUEST_TRANSFORM_INTO_REMOVED,
    payload: { ...data }
});

export const receiveTransformIntoRemovedAction = (data) => ({
    type: RECEIVE_TRANSFORM_INTO_REMOVED,
    payload: {
        ...data
    }
});

export const receiveTransformIntoRemovedErrorAction = (error) => ({
    type: RECEIVE_TRANSFORM_INTO_REMOVED_ERROR,
    payload: {
        error: error
    }
});

export const requestRemoveIdenticalAction = (data) => ({
    type: REQUEST_REMOVE_IDENTICAL,
    payload: { ...data }
});

export const receiveRemoveIdenticalAction = (data) => ({
    type: RECEIVE_REMOVE_IDENTICAL,
    payload: {
        ...data
    }
});

export const receiveRemoveIdenticalErrorAction = (error) => ({
    type: RECEIVE_REMOVE_IDENTICAL_ERROR,
    payload: {
        error: error
    }
});

export const requestCreateNewMatchAction = (data) => ({
    type: REQUEST_CREATE_NEW_MATCH,
    payload: { ...data }
});

export const receiveCreateNewMatchAction = (data) => ({
    type: RECEIVE_CREATE_NEW_MATCH,
    payload: { ...data }
});

export const receiveCreateNewMatchErrorAction = (error) => ({
    type: RECEIVE_CREATE_NEW_MATCH_ERROR,
    payload: {
        error: error
    }
});


export const requestAllMatchesAction = (data) => ({
    type: REQUEST_ALL_MATCHES,
    payload: { ...data }
});

export const receiveAllMatchesAction = (data) => ({
    type: RECEIVE_ALL_MATCHES,
    payload: {
        allMatches: data
    }
});

export const receiveAllMatchesErrorAction = (error) => ({
    type: RECEIVE_ALL_MATCHES_ERROR,
    payload: {
        error: error
    }
});

export const requestCanonicalPotentialDuplicatesAction = () => ({
    type: REQUEST_CANONICAL_POTENTIAL_DUPLICATES
});

export const receiveCanonicalPotentialDuplicatesAction = (data) => ({
    type: RECEIVE_CANONICAL_POTENTIAL_DUPLICATES,
    payload: {
        ...data
    }
});

export const receiveCanonicalPotentialDuplicatesErrorAction = (error) => ({
    type: RECEIVE_CANONICAL_POTENTIAL_DUPLICATES_ERROR,
    payload: {
        error: error
    }
});

export const requestCanonicalSearchByKeywordsAction = () => ({
    type: REQUEST_CANONICAL_SEARCH_BY_KEYWORDS
});

export const receiveCanonicalSearchByKeywordsAction = (data) => ({
    type: RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS,
    payload: {
        ...data
    }
});

export const receiveCanonicalSearchByKeywordsErrorAction = (error) => ({
    type: RECEIVE_CANONICAL_SEARCH_BY_KEYWORDS_ERROR,
    payload: {
        error: error
    }
});

export const requestCanonicalTransformIntoRemovedAction = () => ({
    type: REQUEST_CANONICAL_TRANSFORM_INTO_REMOVED
});

export const receiveCanonicalTransformIntoRemovedAction = (data) => ({
    type: RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED,
    payload: {
        ...data
    }
});

export const receiveCanonicalTransformIntoRemovedErrorAction = (error) => ({
    type: RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED_ERROR,
    payload: {
        error: error
    }
});

export const requestCanonicalTransformIntoCanonicalAction = () => ({
    type: REQUEST_CANONICAL_TRANSFORM_INTO_CANONICAL
});

export const receiveCanonicalTransformIntoCanonicalAction = (data) => ({
    type: RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL,
    payload: {
        ...data
    }
});

export const receiveCanonicalTransformIntoCanonicalErrorAction = (error) => ({
    type: RECEIVE_CANONICAL_TRANSFORM_INTO_CANONICAL_ERROR,
    payload: {
        error: error
    }
});

export const requestCanonicalMergeAction = () => ({
    type: REQUEST_CANONICAL_MERGE
});

export const receiveCanonicalMergeAction = (data) => ({
    type: RECEIVE_CANONICAL_MERGE,
    payload: {
        ...data
    }
});

export const receiveCanonicalMergeErrorAction = (error) => ({
    type: RECEIVE_CANONICAL_MERGE_ERROR,
    payload: {
        error: error
    }
});

export const discardPotentialDuplicateAction = (data) => ({
    type: DISCARD_POTENTIAL_DUPLICATE,
    payload: { product: data }
});

export const requestCanonicalMarkAsReviewedAction = () => ({
    type: REQUEST_CANONICAL_MARK_AS_REVIEWED
});

export const receiveCanonicalMarkAsReviewedAction = (data) => ({
    type: RECEIVE_CANONICAL_MARK_AS_REVIEWED,
    payload: {
        ...data
    }
});

export const receiveCanonicalMarkAsReviewedErrorAction = (error) => ({
    type: RECEIVE_CANONICAL_MARK_AS_REVIEWED_ERROR,
    payload: {
        error: error
    }
});


export const requestCreateProductAttributeNewLovsAction = () => ({
    type: REQUEST_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS
});

export const receiveCreateProductAttributeNewLovsAction = (data) => ({
    type: RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS,
    payload: {
        ...data
    }
});

export const receiveCreateProductAttributeNewLovsErrorAction = (data) => ({
    type: RECEIVE_CREATE_PRODUCT_ATTRIBUTE_NEW_LOVS_ERROR,
    payload: {
        ...data
    }
});

export const requestFetchHomologationAttributesAction = () => ({
    type: REQUEST_FETCH_HOMOLOGATION_ATTRIBUTES
});

export const receiveFetchHomologationAttributesAction = (data) => ({
    type: RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES,
    payload: {
        ...data
    }
});

export const receiveFetchHomologationAttributesErrorAction = (data) => ({
    type: RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES_ERROR,
    payload: {
        ...data
    }
});