import { connect } from "react-redux";
import HistoricView from "./HistoricView";
import * as HistoricState from "@common/components/src/Historic/HistoricState";
import {
    changeSelectedDateRange,
    changeIncludeCardPrices
} from "../ComparerActions";
import { ViewPricesUtil } from "@common/components";

const {
    fetchHistoricData,
    fetchLowerPriceFromMaxHistoricPeriodSelectedStores,
    fetchLowerPriceBetweenSelectedDatesSelectedStores,
    fetchLowerPriceFromMaxHistoricPeriodAllStores,
    fetchLowerPriceBetweenSelectedDatesAllStores,
    fetchLowerPriceFromMaxHistoricPeriodYourProduct,
    fetchLowerPriceBetweenSelectedDatesYourProduct
} = HistoricState.ComparerHistoricState;

const mapStateToProps = (state, ownProps) => {
    return {
        viewPricesComponent: ViewPricesUtil.configureViewPriceComponent,
        className: ownProps.className,
        selectedComparer: ownProps.selectedComparer,

        products: state.comparerStateReducer.products || [],
        stores: state.comparerStateReducer.stores || [],

        lastFilter: ownProps.lastFilter || {},
        historicData: state.comparerHistoricReducer.historicData,

        lowerPriceFromMaxHistoricPeriodSelectedStores: state.comparerHistoricReducer.lowerPriceFromMaxHistoricPeriodSelectedStores,
        lowerPriceBetweenSelectedDatesSelectedStores: state.comparerHistoricReducer.lowerPriceBetweenSelectedDatesSelectedStores,
        lowerPriceFromMaxHistoricPeriodAllStores: state.comparerHistoricReducer.lowerPriceFromMaxHistoricPeriodAllStores,
        lowerPriceBetweenSelectedDatesAllStores: state.comparerHistoricReducer.lowerPriceBetweenSelectedDatesAllStores,
        lowerPriceFromMaxHistoricPeriodYourProduct: state.comparerHistoricReducer.lowerPriceFromMaxHistoricPeriodYourProduct,
        lowerPriceBetweenSelectedDatesYourProduct: state.comparerHistoricReducer.lowerPriceBetweenSelectedDatesYourProduct,
        emptyHistory: state.comparerHistoricReducer.emptyHistory,

        isFetchingHistoricData: state.comparerHistoricReducer.isFetchingHistoricData,
        isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: state.comparerHistoricReducer.isFetchingLowerPriceFromMaxHistoricPeriodYourProduct,
        isFetchingLowerPriceBetweenSelectedDatesYourProduct: state.comparerHistoricReducer.isFetchingLowerPriceBetweenSelectedDatesYourProduct,

        isFetchingLowerPriceBetweenSelectedDatesAllStores: state.comparerHistoricReducer.isFetchingLowerPriceBetweenSelectedDatesAllStores,
        isFetchingLowerPriceFromMaxHistoricPeriodAllStores: state.comparerHistoricReducer.isFetchingLowerPriceFromMaxHistoricPeriodAllStores,
        isFetchingLowerPriceBetweenSelectedDatesSelectedStores: state.comparerHistoricReducer.isFetchingLowerPriceBetweenSelectedDatesSelectedStores,
        isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: state.comparerHistoricReducer.isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores,

        isFetchingCounters: state.comparerHistoricReducer.isFetchingLowerPriceBetweenSelectedDatesAllStores ||
            state.comparerHistoricReducer.isFetchingLowerPriceFromMaxHistoricPeriodAllStores ||
            state.comparerHistoricReducer.isFetchingLowerPriceBetweenSelectedDatesSelectedStores ||
            state.comparerHistoricReducer.isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchHistoricData: (params) => fetchHistoricData(dispatch, params),
        fetchLowerPriceFromMaxHistoricPeriodSelectedStores: (productIds) => fetchLowerPriceFromMaxHistoricPeriodSelectedStores(dispatch, productIds),
        fetchLowerPriceBetweenSelectedDatesSelectedStores: (productIds) => fetchLowerPriceBetweenSelectedDatesSelectedStores(dispatch, productIds),
        fetchLowerPriceFromMaxHistoricPeriodAllStores: (params) => fetchLowerPriceFromMaxHistoricPeriodAllStores(dispatch, params),
        fetchLowerPriceBetweenSelectedDatesAllStores: (params) => fetchLowerPriceBetweenSelectedDatesAllStores(dispatch, params),
        fetchLowerPriceFromMaxHistoricPeriodYourProduct: (yourProductId) => fetchLowerPriceFromMaxHistoricPeriodYourProduct(dispatch, yourProductId),
        fetchLowerPriceBetweenSelectedDatesYourProduct: (yourProductId) => fetchLowerPriceBetweenSelectedDatesYourProduct(dispatch, yourProductId),
        changeSelectedDateRange: (dateRange) => dispatch(changeSelectedDateRange(dateRange)),
        changeIncludeCardPrices: (includeCardPrices) => dispatch(changeIncludeCardPrices(includeCardPrices))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoricView);
