import React from 'react';
import CustomModal from "@common/components/src/ui/CustomModal";
import { Button } from 'reactstrap';
import AnnouncementIcon from '@material-ui/icons/Announcement';

const LabelModal = ({ title, subtitle, description, confirmAction, onCancel, onConfirm, className }) => {
   
    return (
        <CustomModal
            isOpen={true}
            className={`modal-label-public ${className}`}
            toggle={onCancel}
            body={
                <div>
                    <AnnouncementIcon className={'info-icon'} />
                    <div className="title">
                        {title}
                    </div>

                    <div className='description'>
                        {description}
                    </div>

                    <div className="subtitle">
                        {subtitle}
                    </div>
                    <div className="buttons">
                        <Button size={'sm'} className={"icon cancel-button"} onClick={onCancel}>
                            Cancelar
                        </Button>
                        <Button size={'sm'} className={"icon confirm-button"} onClick={onConfirm}>
                            {confirmAction}
                        </Button>
                    </div>
                </div>
            }>
        </CustomModal>
    )
}

export default LabelModal;
