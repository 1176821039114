import React, { Component } from 'react';
import { Row, Col, Container, CardBody, Card, Button } from 'reactstrap';
import ProductPanel from './ProductPanel';
import CurrentStepPanel from './CurrentStepPanel';
import AdvancePanel from './AdvancePanel';
import { PropTypes } from "prop-types";
import { getNameToShow } from '../../common/utils/utils';
import { RouterUtils } from "@common/utils/";



export class Step1 extends Component {

    static propTypes = {
        taskTitle: PropTypes.string,
        currentProduct: PropTypes.object.isRequired,
        storedAttributes: PropTypes.array,
        attributesData: PropTypes.array,
        urlParams: PropTypes.object.isRequired,
        totalSteps: PropTypes.number.isRequired,
        categoryName: PropTypes.string.isRequired,
        onFinishedTask: PropTypes.func,
        canonicalTransformIntoRemoved: PropTypes.func.isRequired,
        contextInfo: PropTypes.object,
        isApprovable: PropTypes.bool
    };

    static defaultProps = {
        taskTitle: "",
        onFinishedTask: () => { },
        contextInfo: {},
        isApprovable: false
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.mistakenCategoryAction = this.mistakenCategoryAction.bind(this);
        this.advanceAction = this.advanceAction.bind(this);
    }

    mistakenCategoryAction() {
        let { currentProduct, contextInfo } = this.props;
        let productId = currentProduct.productId;
        let { userId, jobId } = contextInfo

        this.props.canonicalTransformIntoRemoved(productId, jobId, userId);
    }

    advanceAction() {
    }

    render() {
        let { currentProduct, categoryName, urlParams } = this.props;
        return (
            <Container className="approval-step approval-step--1">
                <Row>
                    <Col sm={12}>
                        <CurrentStepPanel
                            currentStep={1}
                            totalSteps={this.props.totalSteps}
                            title={<span>¿ El producto por aprobar, corresponde a la categoría <span className="italic">{categoryName}</span> ?</span>}
                            showBackLink={false}
                        />
                    </Col>
                </Row>
                <Row className="content-row">
                    <Col md={6}>
                        <Card>
                            <CardBody className="no-padding">
                                <ProductPanel
                                    product={currentProduct}
                                    title="Producto por aprobar"
                                    subtitle={this.props.taskTitle}
                                    attributesTitle="Atributos sugeridos"
                                    nameToShow={getNameToShow(urlParams.contextInfo)}
                                    showRetailerButton={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="right-panel">
                        {
                            this.props.isApprovable ?
                                <AdvancePanel
                                    urlParams={urlParams}
                                    linkCaption={"Si, siguiente paso"}
                                    buttonCaption={"No"}
                                    buttonAction={this.mistakenCategoryAction}
                                    advanceAction={this.advanceAction}
                                /> :
                                <Card>
                                    <CardBody className="no-padding">
                                        <div className="empty-card">
                                            <div className="title">¡Ups!</div>
                                            <div>Identificamos que este producto no tiene un match original, para terminar de revisarlo haz click en el botón verde para ir a la plataforma antigua.</div>
                                            <Button size={'sm'} className={"btn-outline-light action button__finish"} onClick={() => { window.open(RouterUtils.buildWebappProductDetailPath(currentProduct.productId)) }}>
                                                Abrir en la otra plataforma
                                        </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default Step1;