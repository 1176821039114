import { connect } from "react-redux";
import ShippingCostReport from "./index";
import { shippingCostReportContainer } from "@common/components/";

export const mapStateToProps = (state, ownProps) => {
    const common = shippingCostReportContainer.mapStateToProps(state, ownProps);

    return {
        ...common,
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    const common = shippingCostReportContainer.mapDispatchToProps(
        dispatch,
        ownProps
    );

    return {
        ...common,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingCostReport);
