import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Utils, Constantes} from "@common/utils/";
import { Button } from 'reactstrap';
import SearchIcon from '@material-ui/icons/Search';
import queryString from 'qs';

export default class GoogleSearchButton extends Component {

    static propTypes = {
        product: PropTypes.object,
        className: PropTypes.string,
        maxSearchTerms: PropTypes.number,
        forcedSearchTerms: PropTypes.array
    };

    static defaultProps = {
        className: "",
        maxSearchTerms: 4,
        forcedSearchTerms: []
    };

    constructor(props) {
        super(props);
        this.state = {
        }
       this.searchClick = this.searchClick.bind(this)
    }

    searchClick(params){        
        let query = {
            q: params.join(" ")
        }
        let url = [Constantes.EXTERNAL_SEARCH_URL.GOOGLE,queryString.stringify(query)].join("?");
        Utils.openInNewTab(url);
    }


    render() {
        const {product, className, maxSearchTerms, forcedSearchTerms} = this.props;
        return( <Button size={'sm'} outline={true} className={"icon btn-google-search "+className} outline onClick={(e)=>this.searchClick(Utils.getSearchTerms(product,maxSearchTerms,[...forcedSearchTerms, Constantes.SEARCH_TERMS.SPECS]))}>
                    <SearchIcon />Buscar en Google
                </Button> );
    }
}
