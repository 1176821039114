import React from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import FiltersContainer from './components/filters/FiltersContainer';
import MainFiltersContainer from './components/mainFilters/MainFiltersContainer';
import EmptyStateView from './components/EmptyStateView';
import {
  ProductList as ProductListGeneric,
  SummaryBrandContainer,
  SelectViewContainer
} from '@common/components';
import TableContainer from './components/table/TableContainer'
import ContentContainer from './components/content/ContentContainer';

const SummaryBrandContainerCard = (props) => (
  <Card className="product-list-summary product-list-summary--brand" >
    <CardBody className="product-list-summary-counter product-list-summary-counter--unclickable">
      <SummaryBrandContainer />
    </CardBody>
  </Card>
);


export const ProductListBrand = (props) => {
  return <ProductListGeneric
    {...props}
    emptyStateView={EmptyStateView}
    mainFiltersContainer={MainFiltersContainer}
    filtersContainer={FiltersContainer}
    tableContainer={TableContainer}
    summaryCard={SummaryBrandContainerCard}
    selectView={SelectViewContainer}
    contentContainer={ContentContainer}
    hideBackLink={true}
  />
}

export default (translate('common')(ProductListBrand));