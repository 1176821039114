import React, { Component } from 'react'
import PropTypes from 'prop-types';

export default class ColorSample extends Component {

    static propTypes = {
        color: PropTypes.string.isRequired,
        size: PropTypes.number
    }

    static defaultProps = {
        size: 24
    }

    render(){
        return (<div className="color-sample" style={{
            backgroundColor: this.props.color,
            height: this.props.size+"px",
            width: this.props.size+"px",
            borderRadius: (this.props.size/2)+"px"
        }}></div>);
    }

}