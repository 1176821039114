import { connect } from 'react-redux';
import { Utils } from '@common/utils';
import { SubRouterState } from '@common/utils';

export const extractBrandsResult = (brandResult) => {
    if (!brandResult) {
        return [];
    }
    return brandResult.data;
}

export const mapStateToProps = (state, ownProps) => {
    let stores = state.productListBrandReducer.stores || [];
    let brands = state.productListBrandReducer.brands || [];
    let shareBrand = extractBrandsResult(state.productListBrandReducer.brandsResult);
    let shareByPriceRanges = Utils.getValueOrDefault(state.productListBrandReducer.shareByPriceRanges, []);
    let shouldRender = Utils.isArrayWithData(stores) || Utils.isArrayWithData(shareByPriceRanges);

    return {
        stores: stores,
        brands: brands,
        paginationCount: Utils.getValueOrDefault(state.productListBrandReducer.paginationCount, 0),
        shareByPriceRanges: shareByPriceRanges,
        shareBrand: shareBrand,
        lastFilter: state.productListBrandReducer.lastFilter || {},
        shouldRender: shouldRender
    };
}

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        onSelectedView: (selected) => SubRouterState.onSelectedView(dispatch, selected)
    };
};

export const getComponentConnected = (Component) => {
    return connect(mapStateToProps, mapDispatchToProps)(Component)
};