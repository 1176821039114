import React, { useState, useEffect, memo } from 'react'
import { useFilterDispatch } from '../FiltersProvider';
import { actionsFilter } from '../FiltersReducer';
import Select from 'react-select';
import FilterFieldLayout from '../../../FilterFieldLayout';

const PostpagoColorFilter = ({ itemList }) => {
    const dispatch = useFilterDispatch();
    const [itemsSelected, setItemsSelected] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const listColors = buildOptionsField(itemList);
        setOptions(listColors);
    }, [itemList])

    const onChange = (values) => {
        const colors = values.map(item => item.value);
        setItemsSelected(colors);
        dispatch({ type: actionsFilter.UPDATE_COLORS, payload: colors });
    }

    const buildOptionsField = (colores) => {
        return colores.map(color => {
            return { label: color, value: color }
        })
    }

    return (
        <div className="form">
            <FilterFieldLayout
                showRemoveFilters={itemsSelected.length > 0}
                onClear={() => onChange([])}
                fieldLabel={"Colores"}>
                <Select
                    multi
                    name={"colores"}
                    value={itemsSelected}
                    onChange={onChange}
                    options={options}
                    clearable={false}
                    className={"form__form-group-select filters-container--colors"}
                    closeOnSelect={false}
                    removeSelected={true}
                    placeholder={"Todos"}
                    noResultsText={"No hay registros"}
                />
            </FilterFieldLayout>
        </div>
    )
}

export default memo(PostpagoColorFilter)