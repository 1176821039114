import React from 'react';
import { find } from 'lodash';
import { Constantes, commonSortUtils } from '@common/utils';
import { SortingSelect } from '@common/components/';

export const configureSortComponent = (headerProps, currentSort) => {
    const sortOptions = [
        //Se invierten las direcciones de los sort por fecha para que los resultados se muestren correctamente
        {
            value: 0,
            sortKey: Constantes.SortKeys.PRODUCT_RELEVANCE,
            label: "Relevantes primero",
            sortDirection: Constantes.SORT_TYPE.DESC
        },
        {
            value: 1,
            sortKey: Constantes.SortKeys.CREATED_DATE,
            label: "Días en el mercado de menor a mayor",
            sortDirection: Constantes.SORT_TYPE.DESC
        },
        {
            value: 2,
            sortKey: Constantes.SortKeys.CREATED_DATE,
            label: "Días en el mercado de mayor a menor",
            sortDirection: Constantes.SORT_TYPE.ASC
        },
        {
            value: 3,
            sortKey: Constantes.SortKeys.MINIMUM_PRICE,
            label: "Menor precio de menor a mayor",
            sortDirection: Constantes.SORT_TYPE.ASC
        },
        {
            value: 4,
            sortKey: Constantes.SortKeys.MINIMUM_PRICE,
            label: "Menor precio de mayor a menor",
            sortDirection: Constantes.SORT_TYPE.DESC
        }
    ]

    let selectedValue = find(sortOptions, { sortKey: currentSort.sortKey, sortDirection: currentSort.sortDirection })
    return <SortingSelect
        enableHandleCloseMenuOnScroll={true}
        overflowY="hidden"
        input={{ value: selectedValue || sortOptions[0] }}
        name="product-column-sort"
        placeholder="select"
        onChange={(sort) => commonSortUtils.onChangeSortingSelect(sort, headerProps, sortOptions)}
        options={sortOptions}
        singleValuePrefix='Ordenar por: '
    />;
}
