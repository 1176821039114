import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { chunk, findIndex } from "lodash";
import { Utils } from "@common/utils/";

const MonoOptionButtonGroupPropType = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    isSuggested: PropTypes.bool
});

export default class MonoOptionButtonGroup extends PureComponent {
    static propTypes = {
        rangeItems: PropTypes.arrayOf(MonoOptionButtonGroupPropType).isRequired,
        totalItemsByLine: PropTypes.number.isRequired,
        onChange: PropTypes.func,
        buttonLayout: PropTypes.bool,
        selectedItem: PropTypes.oneOfType([
            MonoOptionButtonGroupPropType,
            PropTypes.any
        ])
    };

    static defaultProps = {
        totalItemsByLine: 5
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedItem: props.selectedItem,
            totalItemsByLine: props.totalItemsByLine,
            rangeItems: props.rangeItems || [],
            isFilterApplied: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.computeClassName = this.computeClassName.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
    }

    handleClick(rangeItem) {
        let selectedItem = {};
        if (Utils.isObjectWithData(this.state.selectedItem)) {
            selectedItem = this.state.selectedItem;
        } else if (Utils.isObjectWithData(this.props.selectedItem)) {
            selectedItem = this.props.selectedItem;
        }
        let newSelectedItem = rangeItem || {};
        if (selectedItem.id === rangeItem.id) {
            newSelectedItem = {};
        }
        this.props.onChange(newSelectedItem, rangeItem);
        this.setState({
            selectedItem: newSelectedItem
        });
    }

    removeFilter(ignoreOnChange) {
        this.setState({
            selectedRangeItems: []
        });

        if (!ignoreOnChange && this.props.onChange) {
            this.props.onChange([]);
        }
    }

    computeClassName(rangeItem) {
        let selectedItem =
            this.props.selectedItem || this.state.selectedItem || {};
        if (selectedItem.id === rangeItem.id) {
            return "selection-type--unique";
        } else if (rangeItem.isSuggested) {
            return "item--suggested";
        }
        return "";
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.selectedItem &&
            this.state.selectedItem &&
            this.state.selectedItem.id
        ) {
            this.setState({ selectedItem: nextProps.selectedItem });
        }
    }

    render() {
        const { rangeItems, totalItemsByLine } = this.state;
        const { buttonLayout, title } = this.props;
        if (rangeItems.length <= 0) {
            return null;
        }
        const _rangeItems = rangeItems.map((item, index) => ({
            ...item,
            index: index
        }));
        const suggestedInx = findIndex(_rangeItems, [
            "value",
            this.props.suggestedItem.value
        ]);
        if (suggestedInx !== -1) {
            _rangeItems[suggestedInx].isSuggested = true;
        }
        const screenSizeGroupItems = chunk(_rangeItems, totalItemsByLine);
        return (
            <div
                className={
                    !buttonLayout
                        ? "screen-size-picker-container"
                        : "button-layout-container"
                }
            >
                {title ? <div className="title-label"> {title} </div> : null}
                <div className="table group">
                    <div>
                        {screenSizeGroupItems.map((groupItems, groupIndex) => {
                            return (
                                <div key={groupIndex} className="line">
                                    {groupItems.map((group, index) => {
                                        if (group.isFake) {
                                            return (
                                                <div
                                                    key={index}
                                                    className="item-fake"
                                                >
                                                    a
                                                </div>
                                            );
                                        }
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    this.handleClick({
                                                        ...group
                                                    });
                                                }}
                                                className="item"
                                            >
                                                <div
                                                    className={
                                                        "item-label-wrapper " +
                                                        this.computeClassName(
                                                            group
                                                        )
                                                    }
                                                >
                                                    <span className="label">
                                                        {group.label}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
