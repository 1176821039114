import { filter, includes, min, uniqBy, orderBy, find, sum, isEqual } from 'lodash';
import * as FormatRetail from "./retail/format-retail"
import * as FormatBrand from "./brand/format-brand"
import * as FormatOperator from "./operator/format-operator"
import { getPaymentTypeFees } from './common/payment';
import { setPriceCellClasses } from './common/price-cell';
import * as Constants from '../utils/constantes';
import * as Utils from '../utils/utils';
import * as RetailCompassStorage from '../app/Storage';
import * as OperatorUtils from '../utils/OperatorUtils';
import { getPriceByView, getPriceMapping } from '@common/components/src/common/components/product_detail/PriceUtils';
import { Constantes } from '..';
import { setPriceShippingCellClasses } from './common/price-shipping-cell';
import { setDaysShippingCellClasses } from './common/days-shipping-cell';

const STRAGEGY = {
    [Constants.StoreType.RETAILER]: FormatRetail.formatMatchedProducts,
    [Constants.StoreType.BRAND]: FormatBrand.formatMatchedProducts,
}

/**
 * @param {*} storeType tipo de tienda - RETAILER | PROVIDER
 * @param {*} params {
 * storeId: tienda del usuario logueado
 * userBrandItems: marcas asociadas a la tienda del usuario logueado
 * matchedProducts: respuesta de product search
 * requestBody: body de la petición realizada por el usuario con datos adicionales
 * }
 */
export const formatMatchedProducts = (storeType, params) => {
    let matchedProducts = _formatMatchedProducts({ ...params, storeType });
    const strategyType = OperatorUtils.currentCategoryIsOperator()
        ? Constants.SubStoreType.RETAILER_POSTPAID
        : storeType;
    const format = STRAGEGY[strategyType];

    if (Utils.isFunction(format)) {
        matchedProducts = format({ ...params, matchedProducts });
    }

    return matchedProducts;
}

const _formatMatchedProducts = (params) => {
    const { matchedProducts, requestBody } = params;

    const user = RetailCompassStorage.getUser();
    const isBrand = RetailCompassStorage.isStoreTypeBrand(user);
    const isRetailer = RetailCompassStorage.isStoreTypeRetailer(user);
    const countryCode = RetailCompassStorage.getCountryCode(user);
    const brandsId = RetailCompassStorage.getBrandIds(user);
    const strategyType = OperatorUtils.currentCategoryIsOperator(user) ? Constants.SubStoreType.RETAILER_POSTPAID : params.storeType;

    const haveAllMarket = Utils.haveAllMarketFromSellerType(requestBody.sellerType);
    const storeIds = Utils.getStoresOrEmpty(requestBody);
    const pricesToShow = (params.currentFilter ? params.currentFilter.viewPrices : null) || RetailCompassStorage.getPriceView();
    const isPostpago = isEqual(strategyType, Constantes.SubStoreType.RETAILER_POSTPAID);

    const newParams = {
        isPostpago,
        isRetailer,
        isBrand,
        brandsId,
        countryCode,
        storeIds,
        requestBody,
        strategyType,
        pricesToShow,
        haveAllMarket,
        storeId: params.storeId,
        matchedProducts: matchedProducts || [],
        currentFilter: params.currentFilter || {},
        isSeller: !haveAllMarket ? Utils.isSeller(requestBody.sellerType) : undefined
    };
    return matchedProducts.map((canonico) => mapMatchedProduct(canonico, newParams));
}

const mapMatchedProduct = (canonico, params) => {
    let canonicalProduct = {
        ...canonico,
        competitorsProducts: [],
        isMyProduct: false
    };

    if (Utils.isArrayWithData(canonico.directMatches)) {
        const { isPostpago } = params;
        let competitorsProducts = getListCompetitorProducts(canonico.directMatches, params);

        canonicalProduct = isPostpago
            ? FormatOperator.mapCanonicalProductPostpago(canonicalProduct, competitorsProducts, params)
            : mapCanonicalProduct(canonicalProduct, competitorsProducts, params);
    }
    return canonicalProduct;
}

const getListCompetitorProducts = (directMatches, params) => {
    return directMatches.filter(match => filtersDefault(match, params));
}

export const mapCanonicalProduct = (canonicalProduct, competitorsProducts, params) => {
    const { requestBody, storeIds, currentFilter } = params;
    competitorsProducts = competitorsProducts.map(match => formatCompetitorProduct(match, params));
    competitorsProducts = orderMatches(competitorsProducts, params.requestBody);
    const allCompetitorsProducts = competitorsProducts;

    if (requestBody.allowedToFilterDirectMatches && Utils.isArrayWithData(storeIds)) {
        competitorsProducts = competitorsProducts.filter((match) => includes(storeIds, match.storeId));
    }

    let _competitorsProducts = competitorsProducts;
    const sellerMatchesByStore = (_competitorsProducts) => {
        let sellerMatches = _competitorsProducts.filter((match) => (isEqual(match.isSeller, true) && !Utils.isNullOrUndefined(match.sellerName)))
        return sellerMatches;
    }
    competitorsProducts = uniqBy(competitorsProducts, 'storeId');
 
    canonicalProduct = {
        ...canonicalProduct,
        ...getAditionalData(competitorsProducts, params.pricesToShow),
        competitorsProducts: competitorsProducts,
        daysInMarket: Utils.getDaysFromToday(canonicalProduct.created),
        allCompetitorsProducts: allCompetitorsProducts,
        sellerMatchesByStore: sellerMatchesByStore(_competitorsProducts),
        currentFilter: currentFilter
    }
    
    setPriceCellClasses(canonicalProduct.competitorsProducts, canonicalProduct.averagePrice, requestBody.sellerType, params.pricesToShow);

    const communeId = currentFilter.commune ? currentFilter.commune : currentFilter.defaultCommune;
    if (currentFilter.shippingType === Constantes.SHIPPING_TYPE.SHIPMENT) {
        setDaysShippingCellClasses(canonicalProduct.competitorsProducts, communeId)

    } else {
        setPriceShippingCellClasses(canonicalProduct.competitorsProducts, communeId); 

    }
    return canonicalProduct;
}

const formatCompetitorProduct = (match, params) => {
    const isPriceWithCard = Utils.isPaymentTypeCard(match.paymentType);
    let highlight = false;
    let prices = getPriceMapping(match);
    prices = Utils.validateCountryWithoutCentsAndRoundPrices(prices, params.countryCode);
    match = { ...match, ...prices };

    if (isMatchOutOfStockRangesFilter(match, params)
        || isMatchRankingFilter(params.requestBody, match)) {
        highlight = true;
    }

    return {
        ...match,
        id: match.productId,
        status: match.statusProduct,
        isPriceWithCard,
        priceWithCard: isPriceWithCard ? match.price : null,
        priceWithoutCard: !isPriceWithCard ? match.price : null,
        fees: getPaymentTypeFees(match.paymentType),
        name: match.productName,
        daysWithoutStock: getDaysWithoutStock(match),
        highlight: highlight,
        absoluteRanking: match.absoluteRanking || null,
        uniqueRanking: Boolean(match.uniqueRanking),
        rankings: match.rankings || [],//[{categoryId: 2598, ranking: 12, reportDate: new Date()},{categoryId: 220191, ranking: 15, reportDate: new Date()}],
        daysInMarket: Utils.getDaysFromToday(match.created)
    };
}

const filtersDefault = (match, params) => {
    const { haveAllMarket, isSeller } = params;

    return availabilityFilters(match, params)
        && (!haveAllMarket ? isEqual(match.isSeller, isSeller) : true)
}

/* Warning: this logic is also implemented in Scalibur, product-search and export-service, 
any change must be replicated in all of them */
const availabilityFilters = (match, params) => {
    const { requestBody } = params;
    const isActiveOnlyAvailableFilter = requestBody.onlyAvailableProducts;
    const isActiveRangesFilter = !!requestBody.outOfStockRanges;
    let isMatchOnlyAvailableFilter = true
    let isMatchRangesFilter = false

    if (isActiveOnlyAvailableFilter) {
        isMatchOnlyAvailableFilter = isMatchOnlyAvailableProductFilter(requestBody, match)
    }
    if (isActiveRangesFilter) {
        isMatchRangesFilter = isMatchOutOfStockRangesFilter(match, params)
    }

    return (!isActiveOnlyAvailableFilter && !isActiveRangesFilter)
        || (isMatchOnlyAvailableFilter || isMatchRangesFilter)
}

const getAditionalData = (competitorsProducts, pricesToShow) => {
    let averagePrice = 0;
    let minimumPrice = 0;
    let absoluteRanking = 0;

    const competitorsPrices = competitorsProducts
        .filter((item) => (item.status === Constants.STATUS_PRODUCT.WITH_STOCK))
        .map((item) => getPriceByView(item, pricesToShow, item.isPriceWithCard).price);

    const rankings = competitorsProducts
        .filter(
            item =>
                item.status === Constants.STATUS_PRODUCT.WITH_STOCK &&
                item.absoluteRanking > 0
        )
        .map(item => item.absoluteRanking);

    if (competitorsPrices.length > 0) {
        averagePrice = competitorsPrices.reduce((a, b) => (a + b), 0) / competitorsPrices.length;
        minimumPrice = min(competitorsPrices);
    }

    if (Utils.isArrayWithData(rankings)) {
        absoluteRanking = calculateAbsoluteRanking(rankings);
    }

    return {
        averagePrice: Utils.roundNumber(averagePrice, 0),
        minimumPrice: Utils.roundNumber(minimumPrice, 0),
        absoluteRanking: Utils.roundNumber(absoluteRanking, 0),
    }
}

export const buildMatchesForCanonicalProduct = (allMatches, matchesFiltered, yoursProducts, sellerTypes) => {

    if (!Utils.haveAllMarketFromSellerType(sellerTypes)) {
        return matchesFiltered;
    }

    const storesSelected = matchesFiltered.map((match) => match.storeId);
    const yoursProductsIds = Utils.isArrayWithData(yoursProducts) ? yoursProducts.map((product) => product.productId) : [];

    let matchesToExit = filter(allMatches, (match) => (includes(storesSelected, match.storeId)));

    matchesToExit.forEach((match) => {
        match.isMyProduct = false;
        if (includes(yoursProductsIds, match.productId)) {
            match.isMyProduct = true;
            match.isMyPrice = true;
            match.storeName = match.isSeller ? 'Tu Mkp' : 'Tu tienda'; 
        }
    });

    return buildUniqueProductByStoreInMarket(matchesToExit);
}

export const formatStoreNameOfTheMatch = (matches, stores) => {
    if (!Utils.isArrayWithData(stores)) {
        return;
    }
    matches.forEach((match) => {
        if (!match.isMyProduct && match.isSeller
            && match.realStoreId && match.realStoreId !== match.storeId) {
            const store = find(stores, ['storeId', '' + match.storeId]);
            if (store) {
                match.storeName = Utils.getProductStoreName(stores, match);
            }
        }
    });
}

export const buildUniqueProductByStoreInMarket = (matches) => {
    let matchesMarketPlace = uniqBy(filter(matches, ['isSeller', true]), 'storeId');
    let matchesDirectSell = uniqBy(filter(matches, ['isSeller', false]), 'storeId');

    return [...matchesMarketPlace, ...matchesDirectSell];
}

export const calculateAbsoluteRanking = (rankings) => {
    let total = sum(rankings);
    if (total === 0) {
        return 0;
    }
    return (total / rankings.length);
}

export const getDaysWithoutStock = (match) => {
    /* we assume that when a product has a status equal to -1, it is not updated...
    therefore, we calculate the days without stock, using the `updated` field as criteria */
    let daysWithoutStock = -1;
    if (match.statusProduct === Constants.STATUS_PRODUCT.WITHOUT_STOCK) {
        daysWithoutStock = Utils.getDaysFromToday(match.updated);
    }
    return daysWithoutStock;
}

const hasStock = (match) => {
    return match.statusProduct === Constants.STATUS_PRODUCT.WITH_STOCK
}

const isMatchRankingFilter = (requestBody, match) => {
    const ranking = getBestRanking(match.rankings);
    return (
        requestBody &&
        requestBody.rankingFilter &&
        hasStock(match) &&
        ranking > 0 &&
        ranking <= requestBody.rankingFilter.top
    );
}

const getBestRanking = (rankings) => {
    if (!Utils.isArrayWithData(rankings)) { return 0; }
    const sortedRankings = orderBy(rankings, ["ranking"], ["asc"]);
    return sortedRankings[0].ranking;
}

const isMatchOnlyAvailableProductFilter = (requestBody, match) => {
    return requestBody.onlyAvailableProducts && hasStock(match)
}

const isMatchOutOfStockRangesFilter = (match, params) => {
    const { requestBody, isRetailer } = params;
    let isMatch = false;

    if (isRetailer
        && requestBody.onlyAvailableProducts
        && match.storeId !== requestBody.storeIdObserver
        && match.statusProduct === Constants.STATUS_PRODUCT.WITHOUT_STOCK) {
        return false;

    } else {
        const daysWithoutStock = getDaysWithoutStock(match) + 1;
        if (match.statusProduct === Constants.STATUS_PRODUCT.WITHOUT_STOCK
            && requestBody.outOfStockRanges
            && daysWithoutStock >= 0) {

            isMatch = requestBody.outOfStockRanges
                .some(range => (daysWithoutStock >= range.from && daysWithoutStock <= range.to));
        }
    }

    return isMatch;
}

export const orderMatches = (matches, requestBody) => {
    const { sellerType, rankingFilter } = requestBody || {};
    const haveAllMarket = Utils.haveAllMarketFromSellerType(sellerType);
    let iteratees;
    let orders;
    if (haveAllMarket) {
        iteratees = ["storeId", "statusProduct", "price"];
        orders = ["desc", "desc", "asc"];
        if (rankingFilter) {
            iteratees.push("absoluteRanking");
            orders.push("asc");
        } else {
            iteratees.push("updated");
            orders.push("desc");
        }
    } else {
        const isSeller = Utils.isSeller(sellerType)
        const sellerOrder = isSeller ? 'desc' : 'asc'
        iteratees = ['storeId', 'isSeller', 'statusProduct', 'price', 'updated'];
        orders = ["desc", sellerOrder, "desc", "asc", "desc"];
    }
    return orderBy(matches, iteratees, orders)
}
