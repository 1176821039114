import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Constantes, Utils } from "@common/utils";
import * as dpmUtils from '@common/utils/src/utils/dpmUtils';
import { isNumber } from 'lodash';
import { getPriceByPriceType, getPriceStyle, getShouldAddDaysWithoutStock } from "./PriceUtils";

export default class PriceCell extends PureComponent {

    static propTypes = {
        product: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { product, priceType } = this.props;

        let price = priceType ? getPriceByPriceType(product, priceType) : product.price;

        if (!price) {
            return <div></div>;
        }

        if (!dpmUtils.isValidMyProduct(product)) {
            return null;
        }

        let dpm = null;
        if (isNumber(product.dpmPercentage) && product.dpmPercentage !== 0) {
            dpm = product.dpmPercentage.toFixed(1).replace(".", ",") + '%';
        }

        let priceStyle = getPriceStyle(product, priceType);

        const priceFormattedPrice = Utils.formatPrice(price);

        const shouldAddDaysWithoutStock = getShouldAddDaysWithoutStock(product, priceType);

        return (<div
            className={'product-detail-body--price-cell' + (product.status === Constantes.STATUS_PRODUCT.WITHOUT_STOCK ? " product-detail-body--price-cell--no-stock" : "")}
            style={shouldAddDaysWithoutStock ? { paddingTop: 18 } : {}}
        >

            {dpm && dpm.length ?
                <div className={"dpm" + (product.status === Constantes.STATUS_PRODUCT.WITHOUT_STOCK ? ' without-stock' : '')}>
                    {dpm}
                </div> : null}

            <div className={"price " + product.priceClassName}>
                <span style={priceStyle}>{priceFormattedPrice}</span>
            </div>

            {shouldAddDaysWithoutStock ?
                <div className="without-stock-text">
                    {Utils.daysWithoutStockToText(product.daysWithoutStock)} <br />
                    sin stock
                    </div> : null}
        </div>
        );
    }
}
