import React from 'react';
import { Utils } from '@common/utils/';
import classNames from 'classnames';

export const Dpm = ({ dpmValue }) => {
    const dpm = Utils.formatNumber(dpmValue);

    function computeDPMClasses(dpm) {
        if (dpm === null) {
            return '';
        }
        return classNames({
            'dpm': true,
            'negative': dpm < 0,
            'positive': dpm > 0,
            'zero': dpm === 0,
        });
    }

    return (
        <div className="dpm-value">
            {dpm !== null ?
                <span className={computeDPMClasses(dpmValue)}>
                    DPM {Utils.roundNumber(dpmValue, 1)}%
                </span>
                : null
            }
        </div>
    )
}