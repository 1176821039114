import React from "react";

const OperatorCell = ({ operator }) => {

    return (
        <div className="operator-cell">
            <span>
                {operator.isOperator && operator.isYourRetail ? "Tu tienda" : operator.visibleOperatorName}
            </span>
        </div>
    )
}

export default OperatorCell