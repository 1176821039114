import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { withRouter, generatePath } from 'react-router';
import { Utils, AttributesUtils, Constantes } from "@common/utils/"
import ImageProductComparer from "./ImageProductComparer";
import Highlighter from "react-highlight-words";
import { get } from "lodash";

import { RetailerButton } from "@common/components";

export class ValidationCell extends PureComponent {

    static propTypes = {
        product: PropTypes.object,
        showApproveButton: PropTypes.bool,
        showRejectButton: PropTypes.bool,
        isTransformingTheMatch: PropTypes.bool,
        approveClick: PropTypes.func,
        rejectClick: PropTypes.func,
        showImageComparer: PropTypes.bool,
        isCanonical: PropTypes.bool,
        comparerLeftTitle: PropTypes.string,
        comparerRightTitle: PropTypes.string,
        comparerThumbnailMainTitle: PropTypes.string,
        showRetailerButton: PropTypes.bool,
        showViewCanonicalProductLink: PropTypes.bool,
        rightActions: PropTypes.func
    };

    static defaultProps = {
        showApproveButton: false,
        showRejectButton: false,
        isTransformingTheMatch: false,
        showImageComparer: false,
        isCanonical: true,
        comparerLeftTitle: "Producto Canónico",
        comparerRightTitle: "Candidato a Match: ",
        showRetailerButton: true,
        showViewCanonicalProductLink: false,
        rightActions: undefined
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    getModel({ brand, model }) {
        brand = Utils.isType(brand, "string") ? brand.toUpperCase() : null;
        if (brand && model) {
            return [brand, "/", model].join(" ");
        }
        return brand || model || "";
    }

    buildLeftActionsContent(product, showRetailerButton) {
        if (showRetailerButton) {
            return <RetailerButton
                price={product.price}
                storeName={product.storeName}
                isSeller={!!product.isSeller}
                url={product.url}
                statusProduct={product.statusProduct}
            />;
        }

        if (Utils.isArrayWithData(product.enhancedAttributes)) {
            return (
                <div className="attributes">
                    {product.enhancedAttributes.map(o =>
                        <span key={"attr-search-" + Utils.generateRandomString()}>
                            {AttributesUtils.buildAttributeValue(o)}
                        </span>
                    )}
                </div>
            )
        }
        return null;
    }

    getColorValidated(product) {
        const attributes = product.attributes
        let colorAttribute = attributes.filter(a => a.id === Constantes.ATTRIBUTES_ID.COLOR)
        let colorValue = get(colorAttribute, "[0].values", [])
        return colorValue.map(v => { return v.name }).join(", ")
    }

    render() {
        const { product, showViewCanonicalProductLink, rightActions } = this.props;
        let productUrl = null;

        if (showViewCanonicalProductLink && Utils.isArrayWithData(product.associatedCanonicalProducts)) {
            productUrl = generatePath(this.props.match.path, { ...this.props.match.params, productId: product.associatedCanonicalProducts[0] });
        }

        return (
            <div className={'validation-cell__container' + (!Utils.productHasStock(product) ? " validation-cell__container--no-stock" : "")}>
                <Row>
                    {
                        this.props.showImageComparer ?
                            <ImageProductComparer productActions={this.props.productActions}
                                product={product}
                                canonicalProduct={{ productName: product.canonicalProduct.name, isCanonical: this.props.isCanonical, ...product.canonicalProduct }}
                                className="validation-cell__img"
                                leftTitle={this.props.comparerLeftTitle}
                                rightTitle={this.props.comparerRightTitle}
                                thumbnailMainTitle={this.props.comparerThumbnailMainTitle} /> :
                            <div className="validation-cell__img">
                                <img src={product.image} alt="" />
                            </div>
                    }
                    <div className='validation-cell__info'>
                        <div className="upper">
                            <div className='left'>
                                <div className='info__name'>
                                    {this.props.highlightTerms && this.props.highlightTerms.length > 0 ?
                                        <Highlighter
                                            highlightClassName="highlighted-terms"
                                            searchWords={this.props.highlightTerms}
                                            autoEscape={true}
                                            textToHighlight={product.productName}
                                        /> :
                                        product.productName
                                    }
                                </div>
                                <div className='info__model'>
                                    {this.props.highlightTerms && this.props.highlightTerms.length > 0 ?
                                        <Highlighter
                                            highlightClassName="highlighted-terms"
                                            searchWords={this.props.highlightTerms}
                                            autoEscape={true}
                                            textToHighlight={this.getModel(product)}
                                        /> :
                                        this.getModel(product)
                                    }
                                </div>
                            </div>

                            {Utils.isFunction(rightActions) ?
                                <div className='right right-actions'>
                                    {rightActions(product)}
                                </div> : null
                            }
                        </div>

                        <div className="lower">
                            <div className="validation-cell__actions">
                                <div className="retailer-button-container">
                                    {this.buildLeftActionsContent(product, this.props.showRetailerButton)}

                                    {showViewCanonicalProductLink && Utils.hasValue(productUrl) ?
                                        <div className="show-canonical-button" onClick={() => Utils.openInNewTab(productUrl)}>
                                            Ver canónico
                                        </div> : null}
                                </div>
                                {this.props.productActions}
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default withRouter(ValidationCell);