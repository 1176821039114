import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import CloseIcon from 'mdi-react/CloseIcon';

let notificationRU = null;
let notificationLU = null;
let notificationTC = null;

const notificationType = PropTypes.shape({
    "color": PropTypes.string,
    "title": PropTypes.string,
    "message": PropTypes.string,
    "position": PropTypes.string,
    "duration": PropTypes.number
});

export class Notification extends PureComponent {

    static propTypes = {
        notification: notificationType
    };

    constructor(props) {
        super(props);
        this.showNotification = this.showNotification.bind(this);
        this.buildNotification = this.buildNotification.bind(this);
        this.validNotification = this.validNotification.bind(this);
        this.createInstanceNotification = this.createInstanceNotification.bind(this);

    }
    createInstanceNotification(callback) {
        NotificationSystem.newInstance({ closeIcon: <CloseIcon size="13" /> }, callback);
    }
    componentDidMount() {
        this.createInstanceNotification(n => notificationRU = n)
        this.createInstanceNotification(n => notificationLU = n)
        this.createInstanceNotification(n => notificationTC = n)
    }

    validNotification(nextProps) {
        if (!nextProps.notification) return false;
        if (!nextProps.notification.color) return false;
        if (!nextProps.notification.message) return false;
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.validNotification(nextProps)) return;

        let position = nextProps.notification.position || 'right-up';
        let duration = nextProps.notification.duration || 5;
        let color = nextProps.notification.color;
        this.showNotification({ notification: this.buildNotification(nextProps.notification), position, duration, color })
        this.props.removeNotification();
    }

    componentWillUnmount() {
        if (notificationRU) notificationRU.destroy();
        if (notificationTC) notificationTC.destroy();
        if (notificationLU) notificationLU.destroy();
    }
    buildNotification(notification) {
        return (<div className={`notification notification--${notification.color}`}>
            {notification.title ? <h5 className="notification__title bold-text">{notification.title}</h5> : null}
            <p className="notification__message">{notification.message}</p>
        </div>);
    }

    showNotification({ notification, position, duration, color }) {
        switch (position) {
            case 'left-up':
                notificationLU.notice({
                    content: notification,
                    duration: duration || 5,
                    closable: true,
                    style: { top: 0, left: 240 },
                    className: position + " " + color,
                });
                break;
            case 'right-up':
                notificationRU.notice({
                    content: notification,
                    duration: duration || 5,
                    closable: true,
                    style: { top: 0, left: 'calc(100vw - 100%)' },
                    className: position + " " + color,
                });
                break;
            default:
                notificationTC.notice({
                    content: notification,
                    duration: duration || 5,
                    closable: true,
                    style: { top: 0, left: 0 },
                    className: position + " " + color,
                });
                break;
        }
    };

    render() { return (<div></div>) };
}

export default Notification;
