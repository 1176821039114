import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Progress,
    UncontrolledTooltip
} from 'reactstrap';
import { isInteger, get } from 'lodash';
import CloseButton from './CloseButton';
import { Utils, Constantes } from "@common/utils"

const CounterPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    barClassName: PropTypes.string.isRequired,
    percentageClassName: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    counterKey: PropTypes.string,
    currentReport: PropTypes.string,
});
const PercentageType = {
    FIXED: 'FIXED',
    TRUNCATED: 'TRUNCATED',
    NORMAL: 'NORMAL',
};

const ProgressBarTooltipConfigPropType = PropTypes.shape({
    show: PropTypes.bool.isRequired,
    renderContent: PropTypes.func,
}).isRequired;

export default class Counter extends PureComponent {
    static propTypes = {
        counterItems: PropTypes.arrayOf(CounterPropType).isRequired,
        label: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        onSelectItem: PropTypes.func.isRequired,
        onDeselectItem: PropTypes.func.isRequired,
        formatPercentage: PropTypes.func,
        formatCounterLabel: PropTypes.func,
        showFilterZone: PropTypes.bool.isRequired,
        barContainerClassName: PropTypes.string,
        onContainerClick: PropTypes.func,
        containerClassName: PropTypes.string,
        barContainerStyles: PropTypes.object,
        showProgressBarZone: PropTypes.bool.isRequired,
        progressBarTooltipConfig: ProgressBarTooltipConfigPropType
    };

    static defaultProps = {
        showFilterZone: true,
        showProgressBarZone: true,
        barContainerClassName: '',
        containerClassName: '',
        progressBarTooltipConfig: {
            show: false,
            renderContent: null
        }
    }

    constructor(props) {
        super(props);

        this.handleClickItem = this.handleClickItem.bind(this);
        this.formatPercentage = this.formatPercentage.bind(this);
        this.toFixed = this.toFixed.bind(this);
        this.fixPercentage = this.fixPercentage.bind(this);
        this.calculatePercentage = this.calculatePercentage.bind(this);
        this.formatCounterLabel = this.formatCounterLabel.bind(this);
        this.handleClickContainer = this.handleClickContainer.bind(this);

        this.progressBarRefs = {};

    }

    handleClickItem(counterItem, e) {
        e.stopPropagation();
        if (!counterItem.isSelected) {
            this.props.onSelectItem(counterItem);
        } else {
            this.props.onDeselectItem(counterItem);
        }
    }

    toFixed(num, fixed) {
        // eslint-disable-next-line
        let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    /**
     * usado para darle formato al porcentaje que se ubica en la parte superior de la sub-barra
     * 
     * @param {*} counterItem 
     * @param {*} index indica la posición den la que se `counterItem` dentro de `this.props.counterItems`
     */
    formatPercentage(counterItem, index) {        
        if (this.props.formatPercentage) {
            return this.props.formatPercentage(counterItem, index);
        }

        let value = 0
        if (counterItem.percentage) {
            value = counterItem.percentage;
        }

        const format = (value) => (value.toString().replace(/\./g, ',') + '%');

        if (counterItem.percentageType === PercentageType.NORMAL) {
            if (isInteger(value)) {
                return format(value);
            }
            return format(value.toFixed(1))
        }
        return format(this.toFixed(value, 1));
    }

    fixPercentage(percentage) {
        // se transforma un número de la forma 0.05495821 en 0.5
        let found = Array.from(percentage.toString(), Number).find((value) => value > 0);
        if (found) {
            return Number('0.' + found);
        }
        return percentage;
    }

    calculatePercentage() {
        const { counterItems, total } = this.props;
        if (!counterItems || !counterItems.length) {
            return null;
        }
        let calculation = (part, total) => {
            return total > 0 ? ((part / total) * 100) : 0;
        }
        let hasUnfixed = counterItems.filter((counterItem) => {
            let percentage = calculation(counterItem.total, total);
            return (percentage < 0.1);
        }).length;
        if (!hasUnfixed) {
            return counterItems.map((counterItem) => {
                let percentage = calculation(counterItem.total, total);
                return {
                    ...counterItem,
                    percentage: percentage,
                    percentageType: PercentageType.NORMAL,
                    progressBarClass: ''
                }
            });
        }
        // calculo de porcentaje...
        // si un porcentaje es menor que 0.1 y mayor que cero, se obtiene el primer número distinto de cero en el decimal
        // ejm: 0.0593838 -> 0.5
        // los porcentajes mayores 
        // Hice algunos ajustes pero la base parte de este algoritmo: Largest Remainder Method
        // https://stackoverflow.com/a/13483710/3537750
        let items = counterItems.map((counterItem) => {
            let percentage = calculation(counterItem.total, total);
            let progressBarClass = '';
            let percentageType = PercentageType.NORMAL;
            if (percentage > 0) {
                if (percentage < 0.1) {
                    percentage = this.fixPercentage(percentage);
                    percentageType = PercentageType.FIXED;
                    progressBarClass = 'progress-bar-with-min-width';
                } else if (percentage >= 1 && !isInteger(percentage)) {
                    percentage = parseInt(percentage);
                    percentageType = PercentageType.TRUNCATED;
                }
            }
            return {
                ...counterItem,
                percentage: percentage,
                percentageType: percentageType,
                progressBarClass: progressBarClass
            }
        });

        let base = 0.1;

        // si el porcentaje de todos los elementos ya es 100, se evita procesar los elementos
        let percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
        if (percentageTotal === 100) {
            return items;
        } else if (percentageTotal > 100) {
            base = -base;
        }

        // si ningún elemento fue truncado, significa que el while siguiente nunca terminará de ejecutarse...
        // con esta validación evitamos un posible ciclo infinito
        let hasItemsTruncateds = items.filter((counterItem) => counterItem.percentageType === PercentageType.TRUNCATED);
        if (!hasItemsTruncateds.length) {
            return items;
        }

        if (base > 0) {
            items = this.add(items, percentageTotal, base);
        } else {
            items = this.reducer(items, percentageTotal, base);
        }

        // a los elementos que fueron truncados se les suma 0.1 hasta que obtengamos un valor de 100


        return items
    }

    reducer(items, percentageTotal, base) {
        while (percentageTotal > 100) {
            for (let i = 0; i < items.length; i++) {
                let counterItem = items[i];
                let percentage = counterItem.percentage;
                if (counterItem.percentageType === PercentageType.TRUNCATED) {
                    percentage += base;
                }
                items[i] = { ...counterItem, percentage: percentage };
                percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
                if (percentageTotal <= 100) {
                    break;
                }
            }
        }
        return items;
    }

    add(items, percentageTotal, base) {
        while (percentageTotal < 100) {
            for (let i = 0; i < items.length; i++) {
                let counterItem = items[i];
                let percentage = counterItem.percentage;
                if (counterItem.percentageType === PercentageType.TRUNCATED) {
                    percentage += base;
                }
                items[i] = { ...counterItem, percentage: percentage };
                percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
                if (percentageTotal >= 100) {
                    break;
                }
            }
        }
        return items;
    }

    formatCounterLabel(total, label) {
        if (this.props.formatCounterLabel) {
            return this.props.formatCounterLabel(total, label);
        }
        return total + ' ' + label;
    }

    handleClickContainer(e) {
        e.stopPropagation();
        if (this.props.onContainerClick) {
            this.props.onContainerClick();
        }
    }

    formatHaveItPercentage() {
        let total = this.props.counterItems.reduce((prev, cur) => prev + cur.total, 0);
        let haveIt = this.props.counterItems.find(e => e.counterKey === "haveIt");
        
        if(haveIt !== undefined){
            return Utils.roundNumber(haveIt.total / total * 100, 0) + "%"
        }else{
            return '0%'
        }        
    }

    setProgressBarRefs = (counterItems) => {
        counterItems && counterItems.forEach((counterItem, index) => {
            let refKey = counterItem.counterKey || index;
            this.progressBarRefs[refKey] = React.createRef();
        });
    };

    render() {
        const { label, total, showFilterZone, barContainerClassName, containerClassName,
            barContainerStyles, showProgressBarZone, progressBarTooltipConfig, fixedLabel, isReportBrand, currentReport, isReportOutOfStock } = this.props;
        let newCounterItems = this.calculatePercentage();
        
        if (!newCounterItems || !newCounterItems.length) {
            return null;
        }

        this.setProgressBarRefs(newCounterItems);

        const size = fixedLabel ? newCounterItems.length + 1 : newCounterItems.length;
        const colSize = (size > 4) ? 3 : 12 / size;
        const isLast = (index, size) => (!(size === 1 || index + 1 !== size));
        const computeContainerItemClass = (index, size) => {
            let classes = 'counter-item counter-ui';
            if (isLast(index, size)) {
                classes += ' counter-item--last';
            }
            if (index === 0) {
                classes += ' counter-item--first';
            }
            return classes;
        };

        const getClickableClass = counterItem => {
            if (get(counterItem, 'enableCounterPercentageClick', true)) {
                return 'counter-item-percentage-clickable';
            }

            return '';
        };

        return (<div className={"interactive-counter-group-container" + (isReportBrand ? "-thin" : "") + " retail-counters " + containerClassName} onClick={(e) => this.handleClickContainer(e)}>
            
            <Row>
                <Col>
                    <div className="counter-label">
                        {this.formatCounterLabel(total, label)}
                    </div>
                </Col>
                <Col>
                {isReportBrand && (currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL || currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE) ?
                    newCounterItems.map((counterItem, index) => {
                        return (
                            <Col key={index} className="pr-0">
                                <div className={computeContainerItemClass(index, size)}>
                                    <div className={`counter-item-percentage ${counterItem.percentageClassName} ${getClickableClass(counterItem)} float-right`}
                                        onClick={(e) => {
                                            get(counterItem, 'enableCounterPercentageClick', true) && this.handleClickItem(counterItem, e)
                                        }}>
                                        {this.formatPercentage(counterItem, index)}
                                    </div>
                                </div>
                            </Col>
                        );
                    })
                :<></>}
                {isReportOutOfStock ?
                    <Col className="pr-0">
                        <div>
                            <div className={`counter-item-percentage float-right`}>
                                {fixedLabel}
                            </div>
                        </div>
                    </Col>
                :<></>}
                </Col>
            </Row>
            
            {total > 0 ? <div>
                <Row className="counter-group-item percentages">
                    {isReportBrand || isReportOutOfStock?
                    <Fragment>
                        <Col className={`counter-item-left-side${this.props.isReportOutOfStock ? "-wide" : ""}`}>
                            {currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL || currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE ?
                                <Col className="pr-0">
                                    <div className={`counter-item-percentage ${this.props.counterItems[0].percentageClassName} ${getClickableClass(this.props.counterItems[0])}`}
                                        onClick={(e) => {
                                            get(this.props.counterItems[0], 'enableCounterPercentageClick', true) && this.handleClickItem(this.props.counterItems[0], e)
                                        }}>
                                        {this.formatHaveItPercentage()}
                                    </div>
                                </Col>
                                :
                                newCounterItems.map((counterItem, index) => {
                                    return (
                                        <Col key={index} className="pr-0">
                                            <div className={computeContainerItemClass(index, size)}>
                                                <div className={`counter-item-percentage ${counterItem.percentageClassName} ${getClickableClass(counterItem)}`}
                                                    onClick={(e) => {
                                                        get(counterItem, 'enableCounterPercentageClick', true) && this.handleClickItem(counterItem, e)
                                                    }}>
                                                    { index === 0 ? this.formatPercentage(counterItem, index) : null }
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })
                            }
                        </Col>
                        <Col className="counter-progress-bar-right-side">
                        {showProgressBarZone ?
                            <Row className="counter-ui counter-group-progress-bar">
                                <Col xs={12} sm={12} lg={12}>
                                    <Progress className={"counter-progress-bar " + barContainerClassName} multi style={barContainerStyles || {}}>
                                        {newCounterItems.map((counterItem, index) => {
                                            let refKey = counterItem.counterKey || index;
                                            return (
                                                <Progress key={index} bar barClassName={counterItem.progressBarClass + ' ' + counterItem.barClassName} value={counterItem.percentage} striped={counterItem.striped || false}>
                                                    <div className="bar-size-for-event"
                                                        onClick={(e) => { this.handleClickItem(counterItem, e) }}
                                                        ref={this.progressBarRefs[refKey]}></div>

                                                    {progressBarTooltipConfig.show && this.progressBarRefs[refKey] ?
                                                        <UncontrolledTooltip placement='top' boundariesElement='window'
                                                            delay={0} target={this.progressBarRefs[refKey]} >
                                                            {progressBarTooltipConfig.renderContent(counterItem)}
                                                        </UncontrolledTooltip> : null}

                                                </Progress>
                                            );
                                        })}
                                    </Progress>
                                </Col>
                            </Row> : null}
                        </Col>
                    </Fragment>
                     :
                    <Fragment>
                        {newCounterItems.map((counterItem, index) => {
                            return (
                                <Col key={index} xs={colSize}>
                                    <div className={computeContainerItemClass(index, size)}>
                                        <div className={`counter-item-percentage ${counterItem.percentageClassName} ${getClickableClass(counterItem)}`}
                                            onClick={(e) => {
                                                get(counterItem, 'enableCounterPercentageClick', true) && this.handleClickItem(counterItem, e)
                                            }}>
                                            {this.formatPercentage(counterItem, index)}
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Fragment>}
                    
                    {fixedLabel && !isReportOutOfStock ?
                        <Col xs={colSize}>
                            <div className={computeContainerItemClass(0, size)}>
                                <div className='counter-item-percentage fixed-label'> {fixedLabel} </div>
                            </div>
                        </Col>
                        : null}
                </Row>

                {showProgressBarZone && !isReportBrand && !isReportOutOfStock?
                    <Row className="counter-ui counter-group-progress-bar">
                        <Col xs={12} sm={12} lg={12}>
                            <Progress className={"counter-progress-bar " + barContainerClassName} multi style={barContainerStyles || {}}>
                                {newCounterItems.map((counterItem, index) => {
                                    let refKey = counterItem.counterKey || index;
                                    return (
                                        <Progress key={index} bar barClassName={counterItem.progressBarClass + ' ' + counterItem.barClassName} value={counterItem.percentage} striped={counterItem.striped || false}>
                                            <div className="bar-size-for-event"
                                                onClick={(e) => { this.handleClickItem(counterItem, e) }}
                                                ref={this.progressBarRefs[refKey]}></div>

                                            {progressBarTooltipConfig.show && this.progressBarRefs[refKey] ?
                                                <UncontrolledTooltip placement='top' boundariesElement='window'
                                                    delay={0} target={this.progressBarRefs[refKey]} >
                                                    {progressBarTooltipConfig.renderContent(counterItem)}
                                                </UncontrolledTooltip> : null}

                                        </Progress>
                                    );
                                })}
                            </Progress>
                        </Col>
                    </Row> : null}

                {showFilterZone ? <Row className="counter-group-item counter-filter-zone">
                    {newCounterItems.map((counterItem, index) => {
                        return (<Col key={index} className={"counter-filter-button " + (counterItem.isSelected ? "counter-filter-button--active" : "")}
                            xs={{ size: 4, offset: index === 1 && newCounterItems.length === 2 ? 4 : 0 }}
                            onClick={(e) => { this.handleClickItem(counterItem, e) }}>
                            <div className='counter-item counter-ui'>
                                <div className={"counter-item-total " + counterItem.barClassName + "--text"}>{counterItem.total}</div>
                                <div className="counter-item-label">{counterItem.label}</div>
                            </div>
                            <div className="counter-remove">
                                <CloseButton onClick={() => { }} />
                            </div>
                        </Col>);
                    })}
                </Row> : null}
                        
            </div> : null}
        </div>);
    }
}
