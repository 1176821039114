import React, { useState, useEffect } from 'react';
import CustomModal from "@common/components/src/ui/CustomModal";
import PostPagoDetailBody from './PostPagoDetailBody';
import ProductDetailHeader from '../product_detail/ProductDetailHeader';
import { Utils } from "@common/utils/";

export default function PostPagoDetail(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [refreshOnClose, setRefreshOnClose] = useState(false);

    const toggle = () => {
        if (isOpen) {
            props.onClose(refreshOnClose);
        }
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (isOpen !== props.isOpen) {
            setIsOpen(props.isOpen);
        }
    })

    const onRemoveLabel = (label) => {
        setRefreshOnClose(true);
    }

    return (
        Utils.isObjectWithData(props.producto) ?
            <CustomModal
                isOpen={isOpen}
                toggle={toggle}
                wrapperClassName="product-detail-modal"
                className={"product-detail " + props.className}
                body={
                    <div className="product-detail-container">
                        <ProductDetailHeader
                            producto={props.producto}
                            labels={props.labels}
                            dispatch={props.dispatch}
                            fetchProductItems={props.fetchProductItems}
                            currentFilter={props.currentFilter}
                            tableStrategy={props.tableStrategy}
                            showPopoverRemove={props.allowLabelRemoval}
                            onRemoveLabel={onRemoveLabel}
                            hidePublicLabels={props.hidePublicLabels}
                        />
                        <div className="product-detail__table">
                            <PostPagoDetailBody
                                currentFilter={props.currentFilter}
                                canonicalProduct={props.producto}
                                stores={props.stores}
                                tableStrategy={props.tableStrategy}
                            />
                        </div>
                    </div>
                }
            />
            : null
    );
}

PostPagoDetail.defaultProps = {
    onClose: () => { },
    labels: [],
    producto: {},
    allowLabelRemoval: true,
    className: ""
}
