import React, { Component } from 'react'
import PropTypes from 'prop-types';
import CustomModal from "@common/components/src/ui/CustomModal";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import { Utils } from '@common/utils'
import { get } from 'lodash';

export class NewColorModal extends Component {

    static propTypes = {
        openModal: PropTypes.bool,
        container: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
        toggleModal: PropTypes.func,
        createNewColor: PropTypes.func,
        matchProduct: PropTypes.object,
        selectedColor: PropTypes.object
    };

    static defaultProps = {
        openModal: false,
        matchProduct: null
    }

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            colorName: get(this.props.selectedColor, 'name', ''),
            wasChanged: false
        }

        this.enterWithoutEffect = this.enterWithoutEffect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeColorName = this.changeColorName.bind(this);
    }

    changeColorName(newValue) {
        this.setState({ colorName: newValue });
    }

    enterWithoutEffect(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    handleSubmit(params) {
        let colorName = this.state.colorName;

        if (Utils.hasValue(colorName) && colorName.length > 2 && colorName.length < 200) {
            this.props.createNewColor && this.props.createNewColor(this.props.matchProduct, { name: colorName });
        }
    }

    render() {
        return (<form className="form new-color-form" onSubmit={this.handleSubmit}>
            <CustomModal
                container={this.props.container}
                header={
                    <span className="title">Es un nuevo color</span>
                }
                body={
                    <div className="new-color-body">
                        <div className="new-color--title">Elige el nombre que quieres usar:</div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Si quieres puedes dejar el que viene...</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="colorName"
                                    component="input"
                                    type="text"
                                    placeholder=""
                                    className="form-control input-value"
                                    onKeyDown={this.enterWithoutEffect}
                                    onChange={(event, newValue) => this.changeColorName(newValue)}
                                />
                            </div>
                        </div>
                    </div>
                }
                footer={
                    <div className="actions">
                        <Button onClick={undefined} className="btn-cancel" color="link" onClick={() => { this.props.toggleModal() }} >
                            Cancelar
                        </Button>

                        <Button disabled={false} onClick={() => { this.handleSubmit() }} className="btn btn-success btn-ok" >
                            OK
                        </Button>
                    </div>
                }
                isOpen={this.props.openModal}
                toggle={this.props.toggleModal}
                className="new-color-modal"
            />

        </form>
        )
    }
}

export default reduxForm({
    form: 'newColorForm', // a unique identifier for this form
})(NewColorModal);
