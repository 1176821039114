/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class utils.js
 * @date 13 mar. 2018
 */

import React from 'react'
import moment from 'moment';
import 'moment/locale/es';
import {
    find, orderBy, difference, min, isNumber, uniqBy, isEqual, isString, get, forEach, filter, minBy
} from 'lodash';
import {
    SortKeys, SortValues, LOCALES, URL_PARAMS, SELLERTYPE_FILTER, UserType,
    STATUS_PRODUCT
} from './constantes';
import { trackGAPage } from './gaUtils';
import { pageview as TrackJSPageview } from '../scripts/track';
import queryString from 'qs';
import * as Constantes from '../utils/constantes'
import * as Utils from '../utils/utils'
import { getPaymentTypeCard } from '../matched-products/common/payment'
import * as PriceUtils from "../../../components/src/common/components/product_detail/PriceUtils";
import * as RetailCompassStorage from '../app/Storage'
import * as OperatorUtils from '../utils/OperatorUtils'
import classNames from "classnames";

export const isFunction = (value) => (typeof value === "function");

export const stringToArray = (value, separator) => {
    if (!value || value.trim().length <= 0) {
        return [];
    }
    separator = separator || ',';
    return value.split(separator);
}

export const isArray = (value) => (value instanceof Array);

export const isArrayWithData = (value) => (value && Array.isArray(value) && value.length > 0);

export const contains = (list, value) => (list.indexOf(value) > -1);

export const normalizeList = function (list) {
    if (!isArray(list)) {
        return null;
    }

    var newList = list.filter(function (item) {
        return item && item !== "null" && item + "".trim().length > 0;
    });
    return newList;
};

export const composeActionName = (stateName, actionName) => (stateName + '/' + actionName);

export const equals = (objectItem, prop, to) => {
    if (getPropertyByKey(objectItem, prop) === to) {
        return true;
    }
    return false;
}

export const getPropertyByKey = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (!!k && !!o && k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export const hasValue = (string) => {
    return !!(string && string.length > 0);
}

export const isNull = (any) => {
    return any === null;
}

export const isNullOrUndefined = (any) => {
    return any === null || any === undefined;
}

export const formatNumber = (number) => {
    if (isNaN(number)) {
        return null;
    }
    if (number == '0') {
        return 0;
    }
    return Number(number);
}

export const ENV = {
    isDevelopment: () => (!process.env.REACT_APP_RC_ENV || process.env.REACT_APP_RC_ENV === 'local'),
    isTest: () => (process.env.REACT_APP_RC_ENV === 'test'),
    isJest: () => (process.env.REACT_APP_RC_ENV === 'jest'),
    isProduction: () => (process.env.REACT_APP_RC_ENV === 'production'),
};

export const WORKERS = {
    SERVICE_WORKER: "serviceWorker"
}

export const generateUniqueID = () => {
    return (new Date().valueOf() + "-" + Math.random().toString(36).substr(2, 10)).toUpperCase();
};

export const generateRandomString = () => {
    return Math.random().toString(13).replace('0.', '');
}

export const extractCurrentProductListFilter = (appState) => {
    const { form, productListBrandReducer, favoriteStateReducer, subRouterStateReducer } = appState;
    const { isModelSearchDone } = productListBrandReducer || {};
    let currentFilter = { applicationData: {} };
    if (form.filtersForm && form.filtersForm.values) {
        currentFilter = form.filtersForm.values;
    }
    if (form.productListMainFiltersForm && form.productListMainFiltersForm.values) {
        const mainFilterForm = form.productListMainFiltersForm.values;
        currentFilter.skus = mainFilterForm.skus;
        currentFilter.keyWords = mainFilterForm.keyWords;
        currentFilter.search = mainFilterForm.search;
        currentFilter.searchType = mainFilterForm.searchType;
        if (isModelSearchDone || !Utils.isArrayWithData(mainFilterForm.models))
            currentFilter.models = mainFilterForm.models;
    }
    if (form.dashboardForm && form.dashboardForm.values) {
        const dashboardForm = form.dashboardForm.values;
        currentFilter.search = dashboardForm.search;
    }
    if (productListBrandReducer) {
        currentFilter.applicationData = {
            brands: productListBrandReducer.brands || [],
            stores: productListBrandReducer.stores || []
        };
        currentFilter.paginationCurrentPage = productListBrandReducer.paginationCurrentPage;
        currentFilter.sort = productListBrandReducer.sort;
        currentFilter.dynamicAttributes = productListBrandReducer.dynamicAttributes;
    }
    if (favoriteStateReducer && favoriteStateReducer.favorite) {
        currentFilter.favoriteId = favoriteStateReducer.favorite.id;
    }
    if (subRouterStateReducer) {
        currentFilter[URL_PARAMS.REPORT] = subRouterStateReducer.selectedReport;
        currentFilter[URL_PARAMS.SUBREPORT] = subRouterStateReducer.selectedSubReport;
        currentFilter[URL_PARAMS.SELECTED_VIEW] = subRouterStateReducer.selectedView;
        currentFilter[URL_PARAMS.SHIPPING_TYPE] = subRouterStateReducer.shippingType;
    }
    return currentFilter;
}

export const convertStringBoolean = (value) => {
    if (value === undefined || value === "undefined") {
        return false;
    }
    if (value instanceof Boolean) {
        return value;
    }
    return value === "true";
}

export const getCurrentSideFiltersCount = (formState) => {
    if (formState && formState.filtersForm && formState.filtersForm.values) {
        var values = Object.assign({}, formState.filtersForm.values);
        delete values.search;
        delete values.skus;
        delete values.keyWords;
        Object.keys(values).forEach(function (item) {
            if (!values[item] || (Array.isArray(values[item]) && values[item].length === 0)) {
                delete values[item];
            }
        });
        //Descarto filtro "Los que tengo y no tengo" y campo de paginación
        var count = parseInt(values.productAvailability) === 0 ?
            Object.keys(values).length - 2 : Object.keys(values).length - 1;
        return count > 0 ? count : 0;
    }
    return 0;
}

export const getSkuArray = (skuString) => {
    if (isString(skuString)) {
        return skuString.trim().replace(/[^\w\-|(_.^/)]+/g, ",").split(",");
    }
    return [];
}

export const openInNewTab = (url) => {
    let win = window.open(url, '_blank');
    if (win) {
        win.focus();
    }
}
export const buildAlert = (color, title, message) => {
    return { color, title, message };
}

export const getUpperOffset = () => {
    let nav = document.getElementsByClassName("topbar--navigation")[0];
    return nav ? nav.offsetHeight : 0;
}

export const formatPrice = (price, localeCode = LOCALES.GERMANY) => {
    if (price && typeof price === "number")
        return price.toLocaleString(localeCode, {});
    return null;
}

export const getDaysFromToday = (date) => {
    if (!moment(date).isValid()) {
        return null;
    }

    const today = moment(new Date()).startOf('day');
    const created = moment(date).startOf('day');
    const days = today.diff(created, 'days');
    return days;
}

export const daysWithoutStockToText = (days) => {
    if (!isNumber(days) || days < 0) {
        return null;
    }
    /* para los días sin stock se tiene en cuenta el día actual, por lo tanto se incrementa en 1 */
    days++;
    let daysText = '';
    if (days === 1) {
        daysText = 'día'
    } else {
        daysText = 'días'
    }
    return days + ' ' + daysText;
}

export const extractFieldFromMatches = (directMatches, field) => {
    if (!isArrayWithData(directMatches)) {
        return null;
    }
    var fieldValue = "";
    directMatches.some(function (item) {
        if (item[field] && item[field].length > 0) {
            fieldValue = item[field];
            return true;
        }
    });
    return fieldValue;
}

export const extractValidatedAttributesFromMatches = (directMatches, attributeId) => {
    return extractAttributesFromMatchesByCriteria(directMatches, attributeId, extractValidatedAttributes)
}

export const extractUnvalidatedAttributesFromMatches = (directMatches, attributeId) => {
    return extractAttributesFromMatchesByCriteria(directMatches, attributeId, extractUnvalidatedAttributes)
}

export const extractAttributesFromMatchesByCriteria = (directMatches, attributeId, extractorFunc) => {
    if (!isArrayWithData(directMatches)) {
        return [];
    }

    let valuesResult = [];

    directMatches.map(match => {
        let values = extractorFunc(match, attributeId);
        valuesResult = uniqBy(valuesResult.concat(values), 'id');
    });

    return valuesResult.map(currentValue => ({ ...currentValue, value: currentValue.id, key: currentValue.id, label: currentValue.name }));
}


export const extractValidatedAttributes = (match, attributeId) => {
    return extractAttributesByCriteria(match, attributeId, currentValue => currentValue.userId !== Constantes.SUGGESTED_ATTRIBUTES_USER_ID);
}


export const extractUnvalidatedAttributes = (match, attributeId) => {
    return extractAttributesByCriteria(match, attributeId, currentValue => currentValue.userId === Constantes.SUGGESTED_ATTRIBUTES_USER_ID);
}

export const extractAttributesByCriteria = (match, attributeId, filterFunc) => {
    if (!match || !isArrayWithData(match.attributes)) {
        return [];
    }

    let valuesResult = [];

    match.attributes.forEach(currentAttribute => {
        if (currentAttribute.id === attributeId) {
            let values = currentAttribute.values && currentAttribute.values.filter(filterFunc) || [];
            valuesResult = uniqBy(valuesResult.concat(values), 'id');
        }
    })

    return uniqBy(valuesResult, 'id');
}

export const extractModelFromMatches = (directMatches) => {
    return extractFieldFromMatches(directMatches, Constantes.PRODUCT_FIELDS.MODEL);
}

export const buildAttributesArray = (productAttributes, categoryAttributes, forcedVisibility) => {
    let multiValuedAttributes = [];
    iterateObjectAsArray(productAttributes, (item, key) => {
        if (isArray(item)) {
            item.forEach(o => {
                multiValuedAttributes.push({
                    id: parseInt(key),
                    value: o
                })
            })
        }
    });
    var attributes = [];
    multiValuedAttributes.forEach(function (o) {
        let attr = find(categoryAttributes, { "id": o.id });
        if (attr) {
            let value = o.value;
            if (productAttributes[o.id].length > 0 && !attributeHasHiddenValue(value, attr.visualization)) {
                let visualization = attr.visualization || {}, visibility = true;
                if (isType(visualization, "string")) {
                    visualization = JSON.parse(visualization);
                }
                if (isType(forcedVisibility, "boolean")) {
                    visibility = forcedVisibility;
                } else {
                    visibility = visualization ? isAttributeVisibleFromCountryCodeAndUserType(visualization.visible) : false
                }
                attributes.push(
                    {
                        id: o.id,
                        value: value,
                        unit: visualization.unit || "",
                        visible: visibility,
                        order: visualization.order || 0,
                        validationOrder: visualization.validationOrder || 0
                    }
                );
            }
        }

    });
    return attributes;
}

export const attributeHasHiddenValue = (value, visualization) => {
    return visualization && visualization.hiddenValues && visualization.hiddenValues.indexOf(value) !== -1;
}

export const isAttributeVisibleFromCountryCodeAndUserType = (visibleParam) => {
    let isVisibleAttribute = false;
    if (visibleParam === null || visibleParam === undefined) return isVisibleAttribute;

    if (typeof visibleParam === "object") {
        if (visibleParam.value !== null && visibleParam.value !== undefined) {
            const defaultValue = JSON.parse(visibleParam.value);
            const countryCode = RetailCompassStorage.getCountryCode();
            let customMap = [];
            if (isArrayWithData(visibleParam.custom)) {
                customMap = visibleParam.custom
                    .filter(item => item.countryCode.toLowerCase() === countryCode.toLowerCase())
                    .map(item => JSON.parse(item.value));
            }
            isVisibleAttribute = isArrayWithData(customMap) ? customMap[0] : defaultValue;
        }
    } else {
        // garantizar retrocompatibilidad con la implementación anterior a APP-4688 cuando el parametro no era un objeto
        isVisibleAttribute = JSON.parse(visibleParam);
    }

    // requerimiento solicitado en APP-5293 (ocultar solo para clientes)
    const isValidUserType = ![UserType.CLIENT, UserType.CLIENT_ADMIN].includes(RetailCompassStorage.getUserType());
    return isVisibleAttribute || isValidUserType;
}

export const getStoresOrEmpty = (requestBody) => {
    if (requestBody &&
        requestBody.matches &&
        requestBody.matches.storeIds &&
        isArray(requestBody.matches.storeIds)) {
        return requestBody.matches.storeIds;
    }
    return [];
}


export const firstChars = (text, chars) => {
    if (typeof text !== "string" || isNaN(chars)) return null;
    if (text.length <= chars) return text.toUpperCase();
    let newText = text.toUpperCase().slice(0, chars);
    if (newText.replace(" ", "").length === newText.length) {
        return newText;
    }
    return text.toUpperCase().slice(0, (chars + (chars - newText.replace(" ", "").length)));
}

export const capitalize = (text) => {
    if (typeof text !== "string") return null;
    if (text.length <= 0)
        return "";

    return text.split(" ")
        .map(e => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase())
        .join(" ");
}

export const roundNumber = (rnum, rlength) => {
    var newnumber = Math.round(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
    return newnumber;
}

export const getObjectKeysSize = (object) => {
    if (!object || typeof object !== "object") {
        return 0;
    }
    return Object.keys(object).length;
}

export const normalizeSortKey = (sortKey) => {
    switch (sortKey) {
        case SortKeys.CREATED_DATE:
            return SortValues.CREATED_DATE;
        case SortKeys.PRODUCT_RELEVANCE:
            return SortValues.PRODUCT_RELEVANCE;
        case SortKeys.LOWER_PRICE:
            return SortValues.LOWER_PRICE;
        case SortKeys.HIGHER_PRICE:
            return SortValues.HIGHER_PRICE;
        case SortKeys.DPM:
            return SortValues.DPM;
        case SortKeys.PRICE:
            return SortValues.PRICE;
        case SortKeys.AVERAGE_PRICE_BRAND:
            return SortValues.AVERAGE_PRICE_BRAND;
        case SortKeys.MINIMUM_PRICE:
            return SortValues.MINIMUM_PRICE;
        case SortKeys.RANKING_POSITION:
            return SortValues.RANKING_POSITION;
        default:
            return null;
    }
}

export const setClassBySelector = (newClass, selector, add) => {
    let elements = document.querySelectorAll(selector);
    elements.forEach(function (element) {
        if (add) {
            element.classList.add(newClass);
        } else {
            element.classList.remove(newClass);
        }
    });
}

export const resetHorizontalScroll = (selector) => {
    let container = document.querySelector(selector);
    if (!container) return;
    container.scrollLeft = 0;
}

export const extractPropsFromApplicationData = (applicationData) => {
    applicationData = applicationData || {};

    let allStoreIdsItems = [];
    let allStoreItems = applicationData.stores;
    
    if (applicationData.stores) {
        allStoreIdsItems = applicationData.stores.map((store) => parseInt(store.storeId));
    }

    return { allStoreIdsItems, allStoreItems };
}

export const hasFilterApplied = (filterObj, keys) => {
    return _hasFilterApplied(filterObj, keys, undefined);
}

export const hasFilterAppliedExcludeMainFilter = (filterObj, keys) => {
    if (OperatorUtils.currentCategoryIsOperator()) return false;
    return _hasFilterApplied(filterObj, keys, ['skus', 'keyWords']);
}

const _hasFilterApplied = (filterObj, keys, keysToExclude) => {
    if (!filterObj) {
        return false;
    }

    if (!keys || !Array.isArray(keys)) {
        keys = ["brands", "from", "keyWords", "screenSizeItems", "skus",
            "stores", "to", "favorites", "labels", "sellerType", "rankingFilter",
            "outOfStockRanges", "onlyAvailableProducts"];

        if (keysToExclude) {
            keys = keys.filter((item) => !keysToExclude.includes(item))
        }
    }
    let keysArray = Object.keys(filterObj);
    for (let i = 0; i < keysArray.length; i++) {
        let key = keysArray[i];
        if (keys.indexOf(key) !== -1 && filterObj[key] && filterObj[key].toString().length > 0 && key !== "dynamicAttributes") {
            return true;
        }
    }

    //Se deja los atributos para una segunda instancia por rendimiento
    if (filterObj.dynamicAttributes && filterObj.dynamicAttributes.length > 0) {
        let dynamicAttributes = filterObj.dynamicAttributes;
        for (let i = 0; i < dynamicAttributes.length; i++) {
            if (dynamicAttributes[i].selectedData && dynamicAttributes[i].selectedData.length > 0) {
                return true;
            }
        }
    }

    return false;
}

export const iterateObjectAsArray = (object, callback) => {
    Object.keys(object).forEach(function (key) { callback(object[key], key) });
}

export const onUrlChange = (url, params) => {
    trackGAPage(url, params);
    TrackJSPageview();
}
export const getParamFromQueryString = (key) => {
    let urlSearchParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
    return urlSearchParams[key];
}

export const updateQueryStringParam = (key, value) => {
    let { location } = window;
    var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
        urlQueryString = document.location.search,
        newParam = key + '=' + value,
        params = '?' + newParam;

    // If the "search" string exists, then build params from it
    if (urlQueryString) {
        let updateRegex = new RegExp('([?&])' + key + '[^&]*');
        let removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');

        if (!hasValue(value) || !(value && hasValue(value.trim()))) { // Remove param if value is empty
            params = urlQueryString.replace(removeRegex, "$1");
            params = params.replace(/[&;]$/, "");
        } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
            params = urlQueryString.replace(updateRegex, "$1" + newParam);
        } else { // Otherwise, add it to end of query string
            params = urlQueryString + '&' + newParam;
        }

    }

    //clear empty params
    if (params === "?" || (!urlQueryString && !hasValue(value))) {
        params = "";
    }
    window.history.replaceState({}, "", baseUrl + params);
};

export const calculatePublished = (date) => {
    let days = getDaysFromToday(date);
    if (days === 0) {
        return 'Hoy';
    } else if (days === 1) {
        return days + ' día'
    }
    return days + ' días';
}

export const coalesce = (items) => {
    if (isArrayWithData(items)) {
        return items.find(hasValue);
    }
    return null;
}

export const findYourProduct = (competitorsProducts) => {
    return competitorsProducts && competitorsProducts.find((item) => item.isMyProduct);
}

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
}

export const getValueOrDefault = (value, defaultValue) => {
    return value || defaultValue;
}

export const buildKey = (prefix, dynamicValue, suffix, separator) => {
    return [prefix, dynamicValue, suffix].join(separator || "-");
};

export const getMkpVisibleValueFromCurrentCountry = () => {
    let user = RetailCompassStorage.getUser();
    let mkpConfig = user.currentStore.configuration ? user.currentStore.configuration.filters.mkp : null;
    if (!mkpConfig || mkpConfig.visible === null || mkpConfig.visible === undefined) {
        return false;
    }
    let currentCountryCode = RetailCompassStorage.getCountryCode();
    let defaultValue = JSON.parse(mkpConfig.visible);
    let customMap = [];
    if (isArrayWithData(mkpConfig.custom)) {
        customMap = mkpConfig.custom
            .filter(item => item.country.toLowerCase() === currentCountryCode.toLowerCase())
            .map(item => JSON.parse(item.visible));
    }
    return isArrayWithData(customMap) ? customMap[0] : defaultValue;
}

export const haveAllMarketFromSellerType = (sellerTypes) => {
    return !Utils.isArrayWithData(sellerTypes)
        || (contains(sellerTypes, SELLERTYPE_FILTER.DIRECT)
            && contains(sellerTypes, SELLERTYPE_FILTER.MARKETPLACE));
}

export const isSeller = (sellerTypes) => {
    if (!Utils.isArrayWithData(sellerTypes) || sellerTypes.length > 1) {
        return null;
    }
    return sellerTypes[0] === SELLERTYPE_FILTER.MARKETPLACE ? true : false;
}

export const filterEntities = (items, filteredItems, itemKey) => {
    return items && items.filter((item) => {
        if (isArrayWithData(filteredItems)) {
            return !!find(filteredItems, (filteredItem) => parseInt(filteredItem[itemKey]) === parseInt(item[itemKey]));
        }
        return true;
    });
}

export const isObjectWithData = (obj) => {
    return !!obj && typeof obj === "object" && Object.keys(obj).length > 0;
}

export const getEncodedContextInfo = (categoriesPaths) => {

    let user = RetailCompassStorage.getUser();
    let currentCanonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
    let response = {
        userId: parseInt(user.id),
        username: user.nickname,
        canonicalStoreId: currentCanonicalCategory.storeId,
        rolName: user.role.toUpperCase(),
        categoriesPaths: categoriesPaths
    };
    return encodeURIComponent(btoa(JSON.stringify(response)))
}

export const getDecodedContextInfo = (contextInfo) => {
    try {
        return contextInfo && JSON.parse(atob(decodeURIComponent(contextInfo)))
    } catch (e) {
        console.error("error parsing contextInfo: ", contextInfo, e)
    }
    return {};
}

export const productHasStock = (product = {}, field = "statusProduct") => {
    return product[field] && product[field] === STATUS_PRODUCT.WITH_STOCK;
}

export const getSearchTerms = (canonicalProduct, maxTerms = 4, customTerms = []) => {
    let terms = [], productsToCheck = [{ ...canonicalProduct }, ...(canonicalProduct.directMatches || [])]; // incluir el canonico entre los productos a revisar
    productsToCheck.forEach(item => terms.push(...[item.model, item.brand, (item.productName || item.name)].filter(o => !!o))); // construir un array con modelo marca y nombre de los productos del array anterior
    let str = terms.join(" ").replace(/ +(?= )/g, '').replace(/[^0-9A-Za-zñÑ -.]/gi, ''); // limpiar la cadena conservando alfanumericos, ñ espacios puntos y guiones
    let mainTerms = [canonicalProduct.model, canonicalProduct.brand].filter(o => !!o).map(o => o.toUpperCase()) // array adicional con terminos prioritarios en mayusculas
    let searchTerms = {};

    str.split(" ").forEach(o => { //iterar cada palabra
        let O = o.toUpperCase(); // todo a mayuscula para comparar 
        if (customTerms.join(" ").toUpperCase().indexOf(O) === -1) { //verificar que la palabra actual no se encuentre en los terminos a forzar       
            let points = mainTerms.join(" ").indexOf(O) !== -1 && O != parseInt(O) ? 3 : 1 // si coincide con palabras prioritarias y no es numero 3 puntos, si no solo 1
            let obj = searchTerms[O] ?
                { count: searchTerms[O].count + points, word: O } : { count: points, word: O };
            searchTerms[O] = obj;
        }
    })

    searchTerms = orderBy(searchTerms, ["count", "word.length", o => (o.word != parseInt(o.word))], ["desc", "desc", "desc"]); // se ordena por puntos, por extension de la palabra y tipo string
    let termsArray = [];
    maxTerms = Math.abs(maxTerms - customTerms.length) // Del maximo permitido se reducen los terminos a forzar
    for (let i = 0; i < maxTerms; i++) {
        if (searchTerms[i]) {
            termsArray.push(searchTerms[i].word) // se agregan las palabras al array a devolver
        }
    }
    return [...termsArray, ...customTerms]; // se incluyen los terminos a forzar al final
}

export const isType = (value, type = "") => {
    return typeof value === type;
}

export const getOriginalMatch = (product = {}) => {
    let original = find(product.directMatches, ["sourceId", Constantes.MATCH_SOURCE_IDS.ORIGINAL]);
    return original || {};
}

export const getCountText = (count = 0, singularText = "", pluralText = "") => {
    return [String(count), count === 1 ? singularText : pluralText].filter(o => !!o).join(" ");
}

export const buildMatchParams = (match, user, productId, shouldBeRemovedFromAssociatedCanonicals) => {
    user = user || {};
    if (isType(user, "string")) {
        user = getDecodedContextInfo(user)
    }
    return {
        data: {
            productId: productId,
            competitorId: match.productId,
            sourceId: Constantes.MATCH_SOURCE_IDS.VALIDATOR,
            userId: user.userId,
            username: user.username,
            jobId: user.jobId,
            shouldBeRemovedFromAssociatedCanonicals: shouldBeRemovedFromAssociatedCanonicals || false
        },
        match: match
    };
}
export const isValidContextInfo = (contextInfo) => {
    if (!contextInfo || !isType(contextInfo, "string")) {
        return false;
    }
    let info = {};
    try { info = getDecodedContextInfo(contextInfo) } catch (e) { return false }
    return difference(Constantes.MANDATORY_CONTEXT_INFO_KEYS, Object.keys(info)).length === 0;
}

export const urlParamsString = (jsonObj) => {
    if (!!jsonObj && typeof jsonObj === "object") {
        return Object.keys(jsonObj).map(function (k) {
            return k + '=' + jsonObj[k]
        }).join('&');
    }
    return "";
}

export const getPrice = (prices, paymentType, includeCardPrice = true, pricesToShow) => {
    if (!prices) return null;

    const isPriceWithCard = Utils.isPaymentTypeCard(paymentType);
    let priceMapping = { ...prices, ...PriceUtils.getPriceMapping({ ...prices, paymentType: paymentType }) };

    if (Utils.isArrayWithData(pricesToShow)) {
        priceMapping = PriceUtils.getPriceByView(priceMapping, pricesToShow, isPriceWithCard);
    }

    const { price, normalPrice, offerPrice } = priceMapping;
    if (includeCardPrice) {
        return min([price, normalPrice, offerPrice]);
    } else {
        const matchMap = {
            isPriceWithCard: isPriceWithCard,
            price: price,
            normalPrice: normalPrice,
            offerPrice: offerPrice
        }

        if (PriceUtils.isCardPrice(matchMap)) {
            return min([normalPrice, offerPrice]);
        } else {
            return min([price, normalPrice, offerPrice]);
        }
    }
}

export const checkCardPrice = (paymentType, prices, pricesToShow) => {
    if (isArrayWithData(pricesToShow) && !pricesToShow.includes("ALL")) {
        return false;
    }
    const { price, normalPrice, offerPrice } = prices;
    const matchMap = {
        isPriceWithCard: isPaymentTypeCard(paymentType),
        price: price,
        normalPrice: normalPrice,
        offerPrice: offerPrice
    }
    return PriceUtils.isCardPrice(matchMap);
}

export const getProductStoreName = (stores, product = {}) => {
    if (Utils.isMyProduct(product)) {
        return (product.isSeller ? "Tu Mkp" : "Tu tienda");
    }
    if (!!product.parentMkpStoreId) {
        let store = find(stores, { storeId: String(product.parentMkpStoreId) }) || {};
        return store.name ? addMkpSuffix(store.name) : product.storeName;
    }
    return product.storeName;
}

export const addMkpSuffix = (name = "") => {
    for (let i = 0; i < Constantes.IGNORE_MKP_SUFFIX_WHEN_CONTAINS.length; i++) {
        if (name.toUpperCase().indexOf(Constantes.IGNORE_MKP_SUFFIX_WHEN_CONTAINS[i]) !== -1) {
            return name;
        }
    }
    return name + " Mkp";
}

export const isMyProduct = (product) => product.storeId === RetailCompassStorage.getStoreId();

export const RankingClassNames = {
    GREEN: 'green',
    YELLOW: 'yellow',
    RED: 'red',
    GRAY: 'gray'
};

export const assignRankingClass = (ranking, isLoadingStarCategories) => {
    if (!ranking || ranking <= 0) {
        return "";
    }
    let className = "";
    if (isLoadingStarCategories) {
        className = RankingClassNames.GRAY;
    } else if (ranking <= 60) {
        className = RankingClassNames.GREEN;
    } else if (ranking >= 61 && ranking <= 120) {
        className = RankingClassNames.YELLOW;
    } else {
        className = RankingClassNames.RED;
    }
    return className;
}

export const FeeClassNames = {
    GREEN: 'green',
    YELLOW: 'yellow',
    RED: 'red'
};

export const assignFeeClass = (fee) => {
    if (!fee || fee <= 0) {
        return "";
    }
    let className = "";
    if (fee >= 12) {
        className = FeeClassNames.GREEN;
    } else if (fee >= 4 && fee <= 11) {
        className = FeeClassNames.YELLOW;
    } else {
        className = FeeClassNames.RED;
    }
    return className;
}

export const isDevEnvironment = () => {
    return ENV.isTest() || ENV.isDevelopment();
}

export const getLabelColor = (listColores) => {
    const label = listColores > 1 ? "Colores" : "Color";
    return listColores + " " + label;
}

export const validateIsCardPriceWithView = (isPriceWithCard, pricesToShow) => {
    return isPriceWithCard && pricesToShow.includes("ALL");
}

export const isPaymentTypeCard = (paymentType) => {
    return isEqual(getPaymentTypeCard(paymentType), Constantes.PaymentType.CARD);
}

export const toLowerCaseWithoutSpaces = (str) => {
    return isString(str)
        ? str.replace(/\s+/g, '').toLowerCase()
        : ''
}

const CustomImg = ({ className, size, src }) => {
    return (
        <img className={className}
            height={size}
            width={size}
            src={src}
            alt=''
            style={{ width: size, height: size }} />
    )
}

export const getIcon = (src, size = 158) => (
    <CustomImg src={src}
        className="empty-state-icon"
        size={size} />
);

export const validateCountryWithoutCentsAndRoundPrices = (prices = {}, countryCode) => {
    forEach(Constantes.PriceType, key => prices[key] = validateCountryWithoutCentsAndRoundPrice(prices[key], countryCode));
    return prices;
}

export const validateCountryWithoutCentsAndRoundPrice = (price, countryCode) => {
    if (Constantes.COUNTRIES_WITHOUT_CENTS.includes(countryCode)) {
        price = Utils.forceRoundNumber(price);
    }
    return price;
}

export const forceRoundNumber = (number) => {
    if (number && number.toString().includes(".")) {
        number = number.toString();
        number = parseInt(number.substring(0, number.indexOf("."))) + 1;
    }
    return number;
}

export const shouldIgnoreRoute = () => {
    const pathname = get(window, "location.pathname", "");
    const ROUTES_TO_IGNORE = ['/attributes/', '/validation/'];
    return ROUTES_TO_IGNORE.filter(route => pathname.includes(route)).length > 0
}

export const getSellerMatches = (competitorsProducts, storeId) => {
    if (competitorsProducts.sellerMatchesByStore) {
        let sellerMatches = competitorsProducts.sellerMatchesByStore;

        sellerMatches = sellerMatches.filter((match) => (isEqual(match.storeId, storeId)))
            .map((item) => {
                return {
                    imageUrl: item.image,
                    url: item.url,
                    statusProduct: item.statusProduct,
                    sellerName: item.sellerName,
                    price: item.price,
                    normalPrice: item.normalPrice,
                    discount: PriceUtils.getDiscountBetweenPirces({ price: item.price, offerPrice: item.offerPrice, normalPrice: item.normalPrice }),
                    daysInMarket: item.daysInMarket,
                    sku: item.sku,
                    dpmValue: item.dpmValue,
                    brand: item.brand,
                    model: item.model,
                }
            });

        const priceItemsWithStock = filter(sellerMatches, (item) => { return item.statusProduct === Constantes.STATUS_PRODUCT.WITH_STOCK })
        const priceItemsWithoutStock = filter(sellerMatches, (item) => { return item.statusProduct === Constantes.STATUS_PRODUCT.WITHOUT_STOCK })
        const minPriceItemWithStock = minBy(priceItemsWithStock, 'price') || priceItemsWithStock[0];
        const minPriceItemWithoutStock = minBy(priceItemsWithoutStock, 'price') || priceItemsWithoutStock[0];

        const productCanonical = {
            productId: competitorsProducts.productId,
            sellerMatches: sellerMatches,
            storeId: storeId,
            dpmValue: competitorsProducts.dpmValue,
            yourProduct: competitorsProducts.yourProduct ? true : false,
            bestSellerPrice: minPriceItemWithStock ? minPriceItemWithStock : minPriceItemWithoutStock,
            isMyProduct: competitorsProducts.isMyProduct,
        };

        return productCanonical;
    }
}

export const buildDaysInfo = (day) => {
    if (day <= 0) {
        return "Desde hoy en el mercado";
    } else if (day === 1) {
        return day + " día en el mercado";
    } else {
        return day + " días en el mercado";
    }
};

export const generateRandomId = (modifiers = {}) => {
    let id = [];
    if (modifiers.prefix) {
        id.push(modifiers.prefix);
    }
    id.push(
        Math.random()
            .toString(13)
            .replace("0.", "")
    );
    if (modifiers.suffix) {
        id.push(modifiers.suffix);
    }
    return id.join(modifiers.connector || "");
}; 

export const getMinPriceSeller = (sellers) => {
    const priceItemsWithStock = filter(sellers, (item) => { return item.statusProduct === Constantes.STATUS_PRODUCT.WITH_STOCK })
    const minPriceItem = minBy(priceItemsWithStock, 'price') || priceItemsWithStock[0];
    const minPriceSeller = minPriceItem ? minPriceItem.sellerName : null;
    return minPriceSeller;
}

export const computeDPMClasses = (dpm) => {
    if (dpm === null) {
        return "";
    }
    return classNames({
        dpm: true,
        negative: dpm < 0,
        positive: dpm > 0,
        zero: dpm === 0
    });
};

export const renameStoreName = (storeName) => {
    return (storeName.replace("(MKP)", "")
        .replace("MKP", "")
        .replace(" AR", "")
        .replace(" CL", "")
        .replace(" PE", "")
        .replace(" MX", "")
        .replace(" CO", ""))
}
export const getAbsoluteRankingValue = (absoluteRanking, boolRanking, bestRanking, isLoadingStarCategories, categories) => {
    if (isLoadingStarCategories) {
        return absoluteRanking;
    }
    if (isEqual(categories, [])) {
        return null;
    }
    else {
        return boolRanking ? absoluteRanking : bestRanking;
    }
};

export const getSaturdayDate = () => {
    const init = moment().startOf('day').utc();
    const saturday = init.clone().startOf('week').subtract(30, 'days').format();
    return saturday;
}

export const getShippingDays = (shippingDays) => {
    if (isEqual(shippingDays, 1)) {
        return shippingDays + " día";
    } 
    else if (isEqual(shippingDays, 0)) {
        return "En el día";
    }
    else {
        return shippingDays + " días";
    }
}

export const getBaseAggregationsType = (subStoreType, storeType, report, currentCategoryIsOperator) => {
    if (currentCategoryIsOperator) {
        return subStoreType;
    }
    else if (report === Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST) {
        return Constantes.StoreType.SHIPPING_RETAILER;
    }
    return storeType;

}