import {
    Utils, Constantes
} from "@common/utils/"

import { uniq, remove } from 'lodash';


export const removeMatchFromSuggestedMatches = (suggestedMatches, match) => {
    if (Utils.isArrayWithData(suggestedMatches) && match && match.productId) {
        suggestedMatches = suggestedMatches.filter(item => item.productId !== match.productId);
    }
    return suggestedMatches;
}

export const addMatch = (currentProduct, match) => {
    currentProduct.competitorsProducts = currentProduct.competitorsProducts || [];
    currentProduct.allCompetitorsProducts = currentProduct.allCompetitorsProducts || [];
    currentProduct.directMatches = currentProduct.directMatches || [];
    if (match && match.productId) {
        match.updated = new Date().getTime();
        match.statusMatch = Constantes.MATCH_STATUS_IDS.IDENTICAL;
        match.competitorId = match.productId;
        currentProduct.competitorsProducts.push(match);
        currentProduct.allCompetitorsProducts.push(match);
        currentProduct.directMatches.push(match);
    }
    return currentProduct;
}

export const addMatchToRemoved = (currentProduct, match) => {
    currentProduct.removedMatches = currentProduct.removedMatches || [];
    if (match && match.productId) {
        match.updated = new Date().getTime();
        match.statusMatch = Constantes.MATCH_STATUS_IDS.REMOVED;
        currentProduct.removedMatches.push(match);
    }
    return currentProduct;
}

export const recoverFromRemoved = (currentProduct, match) => {
    let product = currentProduct;
    let removed = product.removedMatches;
    let recovered = remove(removed, ["productId", match.competitorId])
    if (Utils.isArrayWithData(recovered)) {
        recovered = recovered[0];
        recovered.status = Constantes.MATCH_STATUS_IDS.IDENTICAL;
        product = addMatch(product, recovered);
        product.removedMatches = removed;
    }
    return product;
}

export const buildStepUrl = (step, urlParams) => {
    let { categoryId, productId, countryCode, contextInfo } = urlParams;
    return ["", "validation", "approval", countryCode, categoryId, productId, contextInfo, step].join("/");
}

export const buildNextStepUrl = (urlParams) => {
    let { step } = urlParams;
    step = parseInt(step);
    step = isNaN(step) ? 2 : step + 1;
    return buildStepUrl(step, urlParams);
}

export const buildPreviousStepUrl = (urlParams) => {
    let { step } = urlParams;
    step = parseInt(step);
    step = isNaN(step) || step <= 1 ? 1 : step - 1;
    return buildStepUrl(step, urlParams);
}

export const getNameToShow = (contextInfo) => {
    let name = getFieldFromContext(Constantes.CONTEXT_INFO_KEYS.USERNAME, contextInfo);
    if (Utils.isType(name, "string")) {
        return name.split("@")[0];
    }
    return "";
}

export const getFieldFromContext = (field, contextInfo) => {
    let info = Utils.getDecodedContextInfo(contextInfo);
    if (field && Object.keys(info).indexOf(field) !== -1) {
        return info[field]
    }
    return undefined
}

export const getIdsToExcludeInSearch = (matches) => {
    let competitorsIds = [];
    if (Utils.isArrayWithData(matches)) {
        competitorsIds = matches.map(item => item.competitorId);
    }
    return uniq(competitorsIds);
}

export const productIsApprovable = (product = {}) => {
    return Utils.isArrayWithData(product.directMatches);
}

export const setColorsProps = (currentProduct) => {
    currentProduct.validatedColors = Utils.extractValidatedAttributesFromMatches(currentProduct.directMatches, Constantes.ATTRIBUTES_ID.COLOR);
    currentProduct.directMatches = currentProduct.directMatches.map(match => {
        let validatedColors = Utils.extractValidatedAttributes(match, Constantes.ATTRIBUTES_ID.COLOR);
        let hasValidatedColors = Utils.isArrayWithData(validatedColors);

        let unvalidatedColors = Utils.extractUnvalidatedAttributes(match, Constantes.ATTRIBUTES_ID.COLOR);
        let hasUnvalidatedColors = Utils.isArrayWithData(unvalidatedColors);
        return {
            ...match,
            isSeller: !!match.isSeller,
            validatedColors,
            hasValidatedColors,

            unvalidatedColors,
            hasUnvalidatedColors
        };
    })
}