import React from "react"
import { Utils } from "@common/utils/"

export const Sku = ({ sku }) => {
    return (
        <div className={'sku' + (Utils.hasValue(sku) ? ' ' : ' empty')}>
            {Utils.hasValue(sku) ? 'SKU: ' + sku : 'No lo tienes'}
        </div>
    )
}
