import { connect } from 'react-redux';
import EditLabelsButton from './EditLabelsButton';
import { fetchDelete, updateAccessType, fetchListLabels } from "../LabelsState";
import { Utils } from '@common/utils';
import {
    disableForceOpenModal, disableShouldReloadTable, disableOpenEditModal,
    enableOpenEditModal
} from '../LabelsActions';
import { fetchProductItems } from '../../table/TableState';


export const mapStateToProps = (state, ownProps) => {
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    let applicationData = {
        brands: state.productListBrandReducer.brands || [],
        stores: state.productListBrandReducer.stores || [],
        labels: state.labelStateReducer.labels || []
    };
    currentFilter.applicationData = applicationData;


    return {
        labels: state.labelStateReducer.labels,
        hidePublicLabels: state.labelStateReducer.hidePublicLabels,
        isDeleting: state.labelStateReducer.isDeleting,
        isDeleted: state.labelStateReducer.isDeleted,
        hasError: state.labelStateReducer.hasError,
        errorMessage: state.labelStateReducer.error,
        forceOpenEditModal: state.labelStateReducer.forceOpenEditModal,
        shouldReloadTable: state.labelStateReducer.shouldReloadTable,
        currentFilter: currentFilter
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        deleteLabel: params => fetchDelete(dispatch, params),
        disableForceOpenModal: () => dispatch(disableForceOpenModal()),
        disableShouldReloadTable: () => dispatch(disableShouldReloadTable()),
        disableOpenEditModal: () => dispatch(disableOpenEditModal()),
        enableOpenEditModal: () => dispatch(enableOpenEditModal()),
        fetchProductItems: params => fetchProductItems(dispatch, params),
        updateAccessType: params => updateAccessType(dispatch, params),
        fetchListLabels: params => fetchListLabels(dispatch, params)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLabelsButton);
