import React, { Component } from 'react';
import PropTypes from 'prop-types';


class VariantAttributeBox extends Component {
    
    static propTypes = {
        canonicalVariantAttributes: PropTypes.array,        
    };

    static defaultProps = {
        canonicalVariantAttributes: []
    };

    constructor(props) {
        super(props);        
        this.builVariants = this.builVariants.bind(this)
    }

    builVariants() {
        return this.props.canonicalVariantAttributes && this.props.canonicalVariantAttributes.join(", ")
    }
    
    render() {
        return (
            <div className="variant-attribute">
                <strong>Colores:</strong> {this.builVariants()} 
            </div>
        )
    }

}

export default VariantAttributeBox