import React, { useEffect, useState } from 'react'
import PostpagoColorFilter from './PostpagoColorFilter';
import PostpagoPriceRangeFilter from './PostpagoPriceRangeFilter';
import PostpagoOperatorFilter from './PostpagoOperatorFilter';
import { OperatorUtils } from '@common/utils/';

const PostpagoFilterSection = ({ currentFilter, canonicalProduct, stores }) => {
    const [colores, setColores] = useState([]);

    useEffect(() => {
        let listColors = OperatorUtils.getColors(canonicalProduct.allCompetitorsProducts);
        listColors.sort();
        setColores(listColors);
    }, [canonicalProduct.allCompetitorsProducts])

    return (
        <div className="filter-section">
            <PostpagoOperatorFilter
                currentFilter={currentFilter}
                stores={stores}
                labelText="Operadores"
            />
            <PostpagoColorFilter itemList={colores} />
            <PostpagoPriceRangeFilter />
        </div>
    )
}

export default PostpagoFilterSection