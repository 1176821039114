import { KeyboardArrowRight, Update } from "@material-ui/icons";
import React, { useRef } from "react";
import { useState } from "react";
import { SimplifiedRanking } from "./Ranking";

const PathCategory = ({ category, uniqueRanking }) => {
    const ref = useRef(null);
    const [isHovering, setHovering] = useState(false);
    const { fullPath, url, ranking } = category;
    const paths = fullPath.split(">");

    const handleClick = event => {
        event.stopPropagation();
        if (url) window.open(url, "_blank").focus();
    };

    const getElement = () => {
        if (isHovering && !url) {
            return (
                <div
                    data-testid="category-tooltip-message-update"
                    className="category-tooltip__message"
                >
                    <span>Categoría no publicada</span>
                    <Update className="category-tooltip__message__update" />
                </div>
            );
        }

        if (isHovering || uniqueRanking) {
            return (
                <div className="category-tooltip__path__action__btn">
                    <span data-testid="category-tooltip-btn">Ir</span>
                    <KeyboardArrowRight className="category-tooltip__arrow" />
                </div>
            );
        }

        return <SimplifiedRanking ranking={ranking} />;
    };

    return (
        <div
            ref={ref}
            data-testid={"category-tooltip-path"}
            className="category-tooltip__path"
            onClick={handleClick}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <div className="category-tooltip__path__items">
                {paths.map((path, index) => (
                    <PathItem
                        key={path + index}
                        path={path.trim()}
                        isLast={index === paths.length - 1}
                    />
                ))}
            </div>
            <div className="category-tooltip__path__action">{getElement()}</div>
        </div>
    );
};

const PathItem = ({ path, isLast }) => {
    return isLast ? (
        <span className="category-tooltip__path--bold">{path}</span>
    ) : (
        <>
            {path}
            <KeyboardArrowRight className="category-tooltip__arrow" />
        </>
    );
};

export { PathCategory };
