import React from 'react'
import LanguageIcon from '@material-ui/icons/Language';
import BackspaceOutlined from '@material-ui/icons/BackspaceOutlined';
import { UncontrolledTooltip } from 'reactstrap';

export default function EditLabelsManageButton ({isPublic, id}) {

    return (
        <div>
            <div
                className={'manage-button ' + (isPublic ? 'private' : 'public')}
                id={id}    
            >
                {
                    isPublic ? 
                    <BackspaceOutlined /> :
                    <LanguageIcon />
                }

                Hacer {isPublic ? 'privado' : 'público'}

            </div>
            <UncontrolledTooltip 
                placement={'top-end'} 
                target={id}
                delay={0}
                modifiers={{preventOverflow: {enabled: false}}}
                offset={30}
                className={"label-tooltip"}
                >
                {isPublic 
                    ? 'Restringe el uso de este label a tu equipo' 
                    : 'Comparte la información de este label con todo tu equipo'
                }
            </UncontrolledTooltip>
        </div>
    )
}