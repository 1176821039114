import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
    RouterUtils,
    ReduxFormsUtils,
    Intercom,
    IntercomConstants,
    GaUtils,
    Constantes,
    ChurnZero
} from "@common/utils/";
import { ReactComponent as CompareBarChartIcon } from './custom_icons/char-bar.svg';
import * as TableActions from '../../table/TableActions';

class CompareButton extends Component {

  static propTypes = {
    selectedProducts: PropTypes.array,
    maxProductsToCompare: PropTypes.number,
    resetStoreAction: PropTypes.func,
    dispatch: PropTypes.func
  };

  static defaultProps = {
    selectedProducts: [],
    maxProductsToCompare: 30
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonClass: '',
      clicked: false,
      showPopup: false,
      tooltipParams: {
        className: "",
        message: ""
      },
    };

    this.onClick = this.onClick.bind(this)
    this.isMaxProductsExceeded = this.isMaxProductsExceeded.bind(this)
    this.configureTooltipParams = this.configureTooltipParams.bind(this)
    this.goToCompareView = this.goToCompareView.bind(this)
    this.closeTooltipMessage = this.closeTooltipMessage.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.trackEvent = this.trackEvent.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    let domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ clicked: false, tooltipParams: {}, showPopup: false });
    }
  }

  isEmptySelectProducts() {
    return this.props.selectedProducts.length === 0;
  }

  isMaxProductsExceeded() {
    return this.props.selectedProducts.length > this.props.maxProductsToCompare;
    //true, not valid (?)
  }

  componentDidUpdate() {
    if (this.state.showPopup && !this.isMaxProductsExceeded()) {
      this.setState({ clicked: false, showPopup: false });
    }
  }

  closeTooltipMessage() {
    this.setState({ buttonClass: 'normalize-focus' });
  }

  onMouseEnter() {
    this.setState({ buttonClass: '' });
  }

  configureTooltipParams() {
    if (this.props.selectedProducts.length > this.props.maxProductsToCompare) {
      return {
        className: "tooltip-alert",
        message: this.props.maxProductsToCompare + " productos es la cantidad máxima para comparar"
      };
    }
  }

  goToCompareView() {
    this.props.history.push({
      pathname: RouterUtils.MODULES_VIEWS.COMPARE,
      search: ReduxFormsUtils.converterFormFieldsToUrlSearchParams({ productIds: this.props.selectedProducts }),
      state: { from: this.props.location }
    });
  }

  onClick() {
    if (this.isMaxProductsExceeded()) {
      this.setState({ clicked: true, tooltipParams: this.configureTooltipParams(), showPopup: true });
    } else if (this.isEmptySelectProducts()) {
      GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.COMPARE, Constantes.GA_ACTION_EVENTS.COMPARE.NO_PRODUCTS, null, null, null);
      this.props.dispatch(TableActions.updateComparerTooltip(true));
    } else {
      if (this.props.resetStoreAction) {
        this.props.resetStoreAction();
      }
      this.trackEvent()
      this.goToCompareView();
    }
  }

  trackEvent() {
     Intercom.trackEvent(IntercomConstants.EVENTS.USED_PRICE_COMPARATOR);
     ChurnZero.trackEvent({ eventName: "scalibur_comparador_historico" });
  }

  render() {
    return (
      <div className="compare-button-container">
        <Button size={'sm'} outline={true} className={"icon compare-button " + this.state.buttonClass} onClick={this.onClick} id="compare-trigger" name="compare-trigger" onMouseLeave={this.closeTooltipMessage} onMouseEnter={this.onMouseEnter} >
          <CompareBarChartIcon /> Comparar productos
          {this.props.children}
        </Button>
        <Tooltip boundariesElement={".download-section"} flip={0} className={"tooltip-rc " + this.state.tooltipParams.className} placement='bottom-end' target={"compare-trigger"} isOpen={this.state.showPopup} >
          {this.state.tooltipParams.message}
        </Tooltip>
      </div>
    );
  }
}
export default withRouter(CompareButton);
