import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { Utils } from '@common/utils/';
import { refreshUpdateDate } from './LabelsState';
import { SubRouterState } from '@common/utils';

/**
 * este componente no renderiza contenido, tan solo...
 * escucha y reacciona a eventos que son iniciados por el usuario. 
 * Una petición que soncroniza los labels que han sido actualizados localmente...
 * será lanzada cada vez que ocurra uno de los siguientes eventos:
 * 
 * 1. La página es recargada
 * 2. Cambia la categoría o sub-vista 
 * 3. Un producto del listado es seleccionado 
 * 4. El usuario deja la página web
 * 
 */
export class LabelSynchronization extends Component {

    static propTypes = {
        refreshUpdateDate: PropTypes.func
    };


    constructor(props) {
        super(props);

        this.refreshUpdateDate = this.refreshUpdateDate.bind(this);
        this.beforeunloadEventListener = this.beforeunloadEventListener.bind(this);
    }

    /**
     * intentamos llamar al api para sincronizar los labels siempre que las condiciones se cumplan.
     * el parametro `keepalive` le dice al navegador que haga una petición usando el api `navigator.sendBeacon`
     * 
     */
    refreshUpdateDate(params) {
        params = params || {};
        if (!this.props.isFetchingRefreshUpdateDate && this.props.labelsNeedToBeSynchronized) {
            this.props.refreshUpdateDate({ labels: this.props.labels, ...params });
        }
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.beforeunloadEventListener, false);
    }

    /**
     * este metodo se ejecutará si el usuario recarga la página o si cierra la pestaña.
     */
    beforeunloadEventListener(e) {
        this.refreshUpdateDate({ keepalive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforeunloadEventListener, false);

        // cuando se cambia de categoría o desde el listado al dashboard...
        // el componente será desmontado y por consiguiente intentaremos sincronizar...
        // los labels.
        this.refreshUpdateDate({ keepalive: true });
    }

    componentDidUpdate(prevProps) {

        // validamos si un producto fue seleccionado por el usuario para asignarle labels.
        let wasProductSelected = Utils.isArrayWithData(this.props.selectedProducts) && !isEqual(prevProps.selectedProducts, this.props.selectedProducts);
        if (wasProductSelected) {
            this.refreshUpdateDate();
        }
    }

    render() {
        return (
            null
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetchingRefreshUpdateDate: state.labelStateReducer.isFetchingRefreshUpdateDate,
        labels: state.labelStateReducer.labels || [],
        labelsNeedToBeSynchronized: state.labelStateReducer.labelsNeedToBeSynchronized,
        selectedProducts: state.labelStateReducer.newLabel.selectedProducts || [],
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory || {},
        currentRouterView: state.appConfigReducer.currentRouterView,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        refreshUpdateDate: (params) => refreshUpdateDate(dispatch, params),
        onSelectedView: (selected) => SubRouterState.onSelectedView(dispatch, selected)
    };
};

const LabelSynchronizationWithRedux = connect(mapStateToProps, mapDispatchToProps)(LabelSynchronization);
export default LabelSynchronizationWithRedux;
