/**
 * @author @author <a href="mailto:fhurtado@retailcompass.com">Jose Fary Hurtado Cuero</a>
 * @class favoriteService.js
 * @date 15 may. 2018
 */

import queryString from 'qs';
import { deleteAsJson, getAsJson, postAsJson } from './AbstractService';
import { ConstantesApi, GaUtils, Constantes, Utils } from '@common/utils';

export const fetchFavorites = (params) => {
    if (!params.data.categoryId) {
        throw new Error('categoryId required');
    }

    if (!params.data.userId) {
        throw new Error('userId required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_FAVORITES_PATH);


    return getAsJson({
        api: api,
        ...params
    });
};

export const fetchCreate = (params) => {
    if (!params.data.favoriteId) {
        throw new Error('favoriteId required');
    }

    if (!Utils.hasValue(params.data.productIds)) {
        throw new Error('productIds required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_FAVORITES_PATH)
    GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.FAVORITES, Constantes.GA_ACTION_EVENTS.ADD_FAVORITE);
    return postAsJson({ api: api, ...params });
};

export const fetchDelete = (favoriteProductId, params) => {
    if (!favoriteProductId) {
        throw new Error('favoriteProductId required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_FAVORITES_PATH + '/' + favoriteProductId);
    GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.FAVORITES, Constantes.GA_ACTION_EVENTS.DELETE_FAVORITE);
    return deleteAsJson({
        api: api,
        ...params
    });
};

export const fetchDeleteByFavoriteAndProductIds = (favoriteId, productIds, params) => {
    if (!favoriteId) {
        throw new Error('favoriteId required');
    }
    if (!Utils.isArrayWithData(productIds)) {
        throw new Error('productIds required');
    }

    let urlParams = queryString.stringify({ favoriteId, productIds: productIds.join(',') });
    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_FAVORITES_PATH) + '&' + urlParams;
    GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.FAVORITES, Constantes.GA_ACTION_EVENTS.DELETE_FAVORITE);
    return deleteAsJson({
        api: api,
        ...params
    });
};
