/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class matchService.js
 * @date 07 may. 2020
 */

import { getAsJson, postAsJson } from './AbstractService';
import { ConstantesApi } from '@common/utils';

export const getSuggestedMatchesByProductId = (productId, params) => {
    let endpoint = ConstantesApi.API_MATCH_GET_SUGGESTED_BY_PRODUCT_ID.replace("{productId}", productId)
    return getAsJson({
        api: endpoint,
        ...params
    });
};

export const transformIntoIdentical = (params) => {
    return postAsJson({
        api: ConstantesApi.API_MATCH_TRANSFORM_INTO_IDENTICAL,
        ...params
    });
};

export const transformIntoRemoved = (params) => {
    return postAsJson({
        api: ConstantesApi.API_MATCH_TRANSFORM_INTO_REMOVED,
        ...params
    });
};

export const createNewMatch = (params) => {
    return postAsJson({
        api: ConstantesApi.API_MATCH,
        ...params
    });
};

export const getAllMatchesByProductId = (productId, params) => {
    return getAsJson({
        api: ConstantesApi.API_MATCH_GET_ALL_MATCHES_BY_PRODUCT_ID.replace("{productId}", productId),
        ...params
    });
};
