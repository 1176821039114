import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withRouter } from "react-router";
import { Constantes, RouterUtils, SubRouterState } from "@common/utils";
import { connect } from "react-redux";
import { createGlobalState } from "react-use";

const useHoveredRegion = createGlobalState({});

const ShippingCell = ({
    history,
    location,
    onSelectedView,
    className,
    children,
    value,
    original,
}) => {
    const [hovered, setHovered] = useHoveredRegion();
    const { region, regionId, communeId } = original;
    const hasContent = !!value || !!children;
    const isFirstRow = !!region;
    const isSameRegion = hovered.regionId === regionId;
    const isSameCommune = hovered.communeId === communeId;
    const isHovered =
        (isSameRegion && isSameCommune && hasContent) ||
        (isSameRegion && isFirstRow && value === region);

    const goToShippingList = () => {
        const selectedView = Constantes.TYPE_VIEW_RETAIL.SHIPPING;
        const params = {
            [Constantes.URL_PARAMS.SHIPPING_TYPE]:
                Constantes.SHIPPING_TYPE.COST,
            [Constantes.URL_PARAMS.COMMUNE]: communeId,
        };

        RouterUtils.goToProductListTabView({
            location,
            history,
            params,
            onSelectedView,
            selectedView,
        });
    };

    const handleHover = (isHovered) => {
        if (isHovered) {
            setHovered({ regionId, communeId });
        } else {
            setHovered({});
        }
    };

    const getClassNames = () =>
        classnames(
            "cell",
            isFirstRow && "cell-first-row",
            isHovered && "cell-hover",
            className
        );

    return (
        <div
            className={getClassNames()}
            onClick={goToShippingList}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            data-testid="shipping-cell"
        >
            {children || value}
        </div>
    );
};

ShippingCell.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onSelectedView: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.element,
    value: PropTypes.string,
    original: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSelectedView: (selectedView) => {
            SubRouterState.onSelectedView(dispatch, selectedView);
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ShippingCell)
);
