import React from 'react';
import NumberFormat from 'react-number-format';
import { FilterFieldLayout, ButtonCheck } from "@common/components"
import { AttributesUtils } from "@common/utils/"
import { cloneDeep } from 'lodash';
import MonoOptionButtonGroup from '@common/components/src/ui/MonoOptionButtonGroup';
import MultiOptionButtonGroup from "./MultiOptionButtonGroup";

export const NAButton = (props) =>{   
    if (!props.visualization.unsetOption || !props.visualization.unsetOption.id) {
        return null;
    }
    let unsetValue = { id: props.visualization.unsetOption.id, value: props.visualization.unsetOption.id, valueId: props.visualization.unsetOption.id, label: props.visualization.unsetOption.label };
    return <div className="unset-container">
        <ButtonCheck 
            onActivation={() => { props.onSelection(props.attribute.id, unsetValue) }} 
            onDeactivation={() => { props.onSelection(props.attribute.id, {}) }} 
            isActivated={props.isActivated}
            className={props.isSuggested ? "highlighted":""}
        >
        {props.visualization.unsetOption.label}
        </ButtonCheck>
    </div>;
}

export const RangeComponent = (props) => {
    let value = props.defaultValue.label || props.suggestedValue.label || null;
    return(<FilterFieldLayout fieldClass={props.attribute.name} key={props.attribute.name} fieldLabel={AttributesUtils.attributeTitleFromAttribute({ ...props.attribute, visualization: props.visualization }, props.countryCode)}>
        <div className="input-attr-container">
            <NumberFormat
                maxLength={props.maxLength}
                isAllowed={(value) => !value || !value.formattedValue || (value.formattedValue > 0 && value.formattedValue <= props.maxValue)}
                allowNegative={false}
                onBlur={(event) => {
                    if (!event || !event.target) return;
                    props.onValueSelection(props.attribute.id, event.target.value, props.attribute.values) 
                    event.target.classList.remove("changed");
                }
                }
                onChange={(event)=>{
                    if (!event || !event.target) return;
                    let suggested = props.suggestedValue.label || "";   
                    if(suggested !== event.target.value){
                        event.target.classList.add("changed")
                    }else{
                        event.target.classList.remove("changed")
                    }
                }}
                fixedDecimalScale={false}
                className={"typeRange"+(value === props.suggestedValue.label?" suggested":"")}
                decimalScale={1}
                value={value}
            /> 
            <span className="suffix">{AttributesUtils.unitFromVisualization(props.visualization, props.countryCode)}</span>
        </div>        
        {NAButton({ 
            attribute: props.attribute,
            isActivated: props.isUnsetActivated,
            visualization: props.visualization,
            onSelection: props.onUnsetSelection,
            isSuggested: props.isUnsetSuggested,
        })}       
    </FilterFieldLayout>)}
;

export const ButtonGrid = (props) => {
    let values = cloneDeep(props.values);

    return <FilterFieldLayout fieldClass={props.attribute.name} key={props.attribute.name} fieldLabel={AttributesUtils.attributeTitleFromAttribute({ ...props.attribute, visualization: props.visualization })}>
        <MonoOptionButtonGroup buttonLayout={(props.attribute.type !== "number")}
        rangeItems={values}
        totalItemsByLine={props.columns}
        onChange={(item) => { props.onSelection(props.attribute.id, item, false) }}
        selectedItem={ props.defaultValue }
        suggestedItem = {props.suggestedValue}
        />
        {NAButton({
            attribute: props.attribute,
            isActivated: props.isUnsetActivated,
            isSuggested: props.isUnsetSuggested,
            visualization: props.visualization,
            onSelection: props.onUnsetSelection,
        })} 
    </FilterFieldLayout> 
}

export const ButtonGridMultiOption = (props) => {
    let values = cloneDeep(props.values);
    return <FilterFieldLayout fieldClass={props.attribute.name} key={props.attribute.name} fieldLabel={AttributesUtils.attributeTitleFromAttribute({ ...props.attribute, visualization: props.visualization })}>
        
        <MultiOptionButtonGroup buttonLayout={(props.attribute.type !== "number")}
            rangeItems={values}
            totalItemsByLine={props.columns}
            onChange={(items, item) => { props.onSelection(props.attribute.id, items, false) }}
            selectedRangeItems={ props.selectedItems }
            suggestedItems = {props.suggestedItems}
        />
        {NAButton({
            attribute: props.attribute,
            isActivated: props.isUnsetActivated,
            visualization: props.visualization,
            onSelection: props.onUnsetSelection,
        })} 
    </FilterFieldLayout> 
}
