import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import CheckBoxField from "./CheckBox";
import { remove, find, uniqBy } from 'lodash';

const checkboxItem = PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
});

export default class CheckboxList extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(checkboxItem).isRequired,
        fieldName: PropTypes.string.isRequired,
        checkedItems: PropTypes.array,
        onChange: PropTypes.func,
        checkboxClassName: PropTypes.string,
        keyName: PropTypes.string,
        checkBoxColor: PropTypes.string
    };

    static defaultProps = {
        checkedItems: [],
        checkboxColor: "#37CDA2",
        checkboxClassName: "compass",
    };

    constructor(props) {
        super(props);
        this.state = {
            checkedItems: props.checkedItems || []
        }
        this.buildCheckbox = this.buildCheckbox.bind(this)
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this)

        this.removeFilter = this.removeFilter.bind(this)
    }

    UNSAFE_componentWillMount() {

        this.setState({
            checkedItems: this.props.checkedItems
        });
    }

    onChangeCheckbox(e, item) {
        if (!e || !e.target) return;
        let checkedItems = this.props.checkedItems;
        let isChecked = e.target.checked;
        let keyName = this.props.keyName ? this.props.keyName : "value";
        let found = find(checkedItems, [keyName, item[keyName]]);

        if (isChecked && !found) {
            checkedItems.push(item)
        }

        if (!isChecked && !!found) {
            remove(checkedItems, (element) => (element[keyName] === item[keyName]))
        }

        checkedItems = uniqBy(checkedItems, keyName);

        this.setState({
            checkedItems: checkedItems
        });
        
        if (this.props.onChange) {
            this.props.onChange(checkedItems, item);
        }
    }

    buildCheckbox(item) {
        let keyName = this.props.keyName ? this.props.keyName : "value";
        let key = this.props.fieldName + "-" + item[keyName];
        let found = !!find(this.props.checkedItems, [keyName, item[keyName]]);
        return (
            <Field
                defaultChecked={false}
                value={false}
                key={key}
                name={key}
                label={item.label}
                className={this.props.checkboxClassName}
                component={CheckBoxField}
                input={{
                    value: found,
                    name: key,
                    onChange: e => this.onChangeCheckbox(e, item)
                }}
            />
        );
    }

    removeFilter(ignoreOnChange) {
        this.setState({
            checkedItems: []
        });

        if (!ignoreOnChange && this.props.onChange) {
            this.props.onChange([]);
        }
    }

    render() {
        return (
            <div className={"checkboxlist checkboxlist--" + this.props.fieldName}>
                {
                    this.props.items.map(this.buildCheckbox)
                }
            </div>);
    }
}
