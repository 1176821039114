import * as AbstractService from "./services/AbstractService"
import * as AlertService from "./services/alertService"
import * as CategoryService from "./services/categoryService"
import * as FavoriteService from "./services/favoriteService"
import * as HistoricService from "./services/historicService"
import * as LabelsService from "./services/labelsService"
import * as LoginService from "./services/loginService"
import * as ProductAttributeService from "./services/productAttributeService"
import * as ProductService from "./services/productService"
import * as ProductListService from "./services/productListService"
import * as StoreService from "./services/storeService"
import * as MatchesService from "./services/matchesService"
import * as HomologationAttributesService from "./services/homologationAttributesService"
import * as ShippingService from "./services/shippingService"

export {
    AbstractService,
    AlertService,
    CategoryService,
    FavoriteService,
    HistoricService,
    LabelsService,
    LoginService,
    ProductAttributeService,
    ProductListService,
    StoreService,
    ProductService,
    MatchesService,
    HomologationAttributesService,
    ShippingService
}