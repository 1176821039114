import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Utils } from '@common/utils/';

const undoDelay = 7000;

export default class RemovedActions extends Component {

    static propTypes = {
        showApproveButton: PropTypes.bool,
        btnClass: PropTypes.string,
        isTransformingTheMatch: PropTypes.bool,
        isRemovingTheMatch: PropTypes.bool,
        handleApproveClick: PropTypes.func,
        onActionCompleted: PropTypes.func,
        product: PropTypes.object.isRequired,
    };

    static defaultProps = {
        className: "",
        handleRemoveClick: () => { },
        isRemovingTheMatch: false,
        isTransformingTheMatch: false
    };

    constructor(props) {
        super(props);
        this.state = {
        }
        this.onApproveClick = this.onApproveClick.bind(this);
    }

    onApproveClick() {
        this.props.handleApproveClick(this.props.product);
        if (this.props.onActionCompleted) {
            this.props.onActionCompleted();
        }
    }

    render() {
        const { btnClass, isRemovingTheMatch, isTransformingTheMatch } = this.props;

        return (<div className="match__actions" key={"recover-" + Utils.generateRandomString()}>

            {
                <Button size={'sm'} className={"btn-outline-light action button__recover " + btnClass} onClick={this.onApproveClick} disabled={isRemovingTheMatch || isTransformingTheMatch}>
                    Agregar <span>a idénticos</span>
                </Button>
            }

        </div>);
    }
}
