import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { CustomTable, NoFormSearchBox } from '@common/components';
import ValidationCell from './ValidationCell';
import SuggestedActions from "./SuggestedActions";
import { Utils, Constantes } from "@common/utils/"
import { MatchInputBuilder, StoreFilterBuilder } from "../utils/matchInput";
import { MatchEntityBuilder } from "../utils/matchEntity";
import { getIdsToExcludeInSearch } from '../../common/utils/utils';

const maxTerms = 10;

const formName = "matches-search-form";

class MatchesSearch extends Component {

    static propTypes = {
        canonicalProduct: PropTypes.object.isRequired,
        potentialMatches: PropTypes.array,
        competitors: PropTypes.array,
        allMatches: PropTypes.array,
        searchPotentialMatches: PropTypes.func,
        onApproval: PropTypes.func,
        userId: PropTypes.number,
        username: PropTypes.string,
        createNewMatch: PropTypes.func.isRequired,
        customActions: PropTypes.func,
        customSearch: PropTypes.func,
        autoSearch: PropTypes.bool,
        noResults: PropTypes.bool,
        emptyStateTitle: PropTypes.string,
        emptyStateText: PropTypes.string,
        title: PropTypes.string,
        showImageComparer: PropTypes.bool,
        canonicalTableItems: PropTypes.bool,
        comparerLeftTitle: PropTypes.string,
        comparerRightTitle: PropTypes.string,
        comparerThumbnailMainTitle: PropTypes.string,
        jobId: PropTypes.number,
        isRemovingTheMatch: PropTypes.bool,
        isTransformingTheMatch: PropTypes.bool,
    };

    static defaultProps = {
        className: "",
        potentialMatches: [],
        allMatches: [],
        competitors: [],
        onApproval: () => { },
        userId: null,
        username: null,
        autoSearch: false,
        emptyStateTitle: "Sin resultados",
        emptyStateText: "No se encontró ningún producto",
        title: "BUSCADOR DE PRODUCTOS",
        searchPotentialMatches: (words) => { console.log(words) },
        canonicalTableItems: false,
        showImageComparer: true,
        comparerRightTitle: "Resultado de búsqueda: ",
        showRetailerButton: true,
        jobId: null,
        isRemovingTheMatch: false,
        isTransformingTheMatch: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            hasActiveSearch: false,
            currentSearch: null,
            inputValue: Utils.getSearchTerms(props.canonicalProduct, maxTerms, []).join(" "),
            searchedValue: null
        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.formatStoreFilters = this.formatStoreFilters.bind(this);
        this.handleOnInputKeyPress = this.handleOnInputKeyPress.bind(this);
        this.handleApproveClick = this.handleApproveClick.bind(this);
        this.handleCustomSearch = this.handleCustomSearch.bind(this);
        this.getActions = this.getActions.bind(this)
    }


    getActions(product) {
        if (this.props.customActions) {
            return this.props.customActions(product);
        }

        const searchResultActionsProps = {
            showApproveButton: true,
            isTransformingTheMatch: this.props.isTransformingTheMatch,
            isRemovingTheMatch: this.props.isRemovingTheMatch,
            
            btnClass: "btn__sm",
            handleApproveClick: this.handleApproveClick
        }

        return <SuggestedActions {...searchResultActionsProps} product={product} />
    }

    handleOnInputKeyPress(e, inputValue) {
        this.setState({ inputValue: inputValue });
    }

    getTermsToHighLight(terms) {
        if (!terms) return [];
        return terms.replace(/[^\w\s\-]/gi, '').replace(/ +(?= )/g, '').split(" ");
    }


    formatStoreFilters() {
        return this.props.competitors && this.props.competitors
            .map(item => new StoreFilterBuilder().setChildren(false).setStoreId(item.id).build());
    }

    handleOnSearch(searchText) {
        this.setState({ hasActiveSearch: true, searchedValue: searchText });

        let productIdsToExclude = getIdsToExcludeInSearch(this.props.allMatches);
        let matchInput = new MatchInputBuilder()
            .setKeyWords(searchText)
            .setProductIdsToExclude(productIdsToExclude)
            .setStoreFilters(this.formatStoreFilters())
            .build();

        this.props.searchPotentialMatches(matchInput);
    }

    handleCustomSearch(searchText) {
        this.setState({ hasActiveSearch: true, searchedValue: searchText });
        this.props.customSearch(searchText);
    }

    handleApproveClick(currentMatchProduct) {
        //TODO: Improve this with the one in approval>index.js
        const { userId, username, canonicalProduct, createNewMatch, jobId } = this.props;

        let matchEntity = new MatchEntityBuilder()
            .setProductId(canonicalProduct.productId)
            .setCompetitorId(currentMatchProduct.productId)
            .setUsername(username)
            .setProbability(null)
            .setStatus(Constantes.MATCH_STATUS_IDS.IDENTICAL)
            .setUser(userId)
            .setJobId(jobId)
            .setSourceId(Constantes.MATCH_SOURCE_IDS.VALIDATOR)
            .build();

        createNewMatch({ matchEntity, match: currentMatchProduct });
    }

    render() {
        const { potentialMatches, canonicalProduct, autoSearch } = this.props;
        return (<div className="matches-search-container">
            <NoFormSearchBox
                formName={formName}
                title={this.props.title}
                subtitle={""}
                hasActiveSearch={this.state.hasActiveSearch}
                onButtonClick={(e) => { }}
                onSearchReset={(e) => { this.setState({ hasActiveSearch: false }) }}
                showCategories={false}
                buttonText={"Buscar"}
                onSearch={this.props.customSearch ? this.handleCustomSearch : this.handleOnSearch}
                onInputKeyPress={this.handleOnInputKeyPress}
                searchOnEnter={true}
                className="matches-searchbox"
                defaultValue={Utils.getSearchTerms(this.props.canonicalProduct, maxTerms, []).join(" ")}
                autoSearch={autoSearch}
            />
            {Utils.isArrayWithData(potentialMatches) ?
                <CustomTable
                    responsive={true}
                    headers={[{
                        key: 'validationCell',
                        name: Utils.getCountText(potentialMatches.length, " RESULTADO DE BÚSQUEDA", " RESULTADOS DE BÚSQUEDA"),
                        sortable: false,
                        cellClass: 'validation-cell',
                        formatter: (product) => {
                            let currentMatchProduct = { ...product, productName: product.name, canonicalProduct: canonicalProduct, statusProduct: product.status };
                            return <ValidationCell
                                product={currentMatchProduct}
                                highlightTerms={this.getTermsToHighLight(this.state.searchedValue)}
                                priceType={Constantes.PriceType.MIN_PRICE}
                                productActions={this.getActions(currentMatchProduct)}
                                showImageComparer={this.props.showImageComparer}
                                isCanonical={this.props.canonicalTableItems}
                                comparerLeftTitle={this.props.comparerLeftTitle}
                                comparerRightTitle={this.props.comparerRightTitle}
                                comparerThumbnailMainTitle={this.props.comparerThumbnailMainTitle}
                                showRetailerButton={this.props.showRetailerButton}
                            />
                        }
                    }]}
                    rows={potentialMatches}
                    tableIdId='validationsTable--search-results'
                    className={'table--bordered validations-module'}
                    rowKey="productId"
                    onSort={() => { }}
                    count={potentialMatches.length}
                    allRows={[]}
                    rowInfo={null}
                    key={canonicalProduct.productId}
                    sortKeyDefault={'validationCell'}
                    sortDirectionDefault={'ASC'}
                    onRowClick={() => { }}
                /> : this.props.noResults ?
                    <div className="empty-card">
                        <div className="title">{this.props.emptyStateTitle}</div>
                        <div>{this.props.emptyStateText}</div>
                    </div>
                    : null
            }
        </div>);
    }
}

export default reduxForm({
    form: formName,
})(MatchesSearch);
