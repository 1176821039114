/**
 * @author @author <a href="mailto:jvillada@pricingcompass.com">Jorge Villada</a>
 * @class categoryService.js
 * @date 21 mar. 2018
 */


import { getAsJson } from './AbstractService';
import { get } from 'lodash';
import { ConstantesApi } from '@common/utils';

export const fetchByStoreByAccess = (storeId, access, params) => {
    access = access || ConstantesApi.CATEGORY_ACCESS;
    if (!storeId) {
        throw new Error('Store required');
    }
    return getAsJson({
        api: `${ConstantesApi.API_CATEGORY_STORE}/${storeId}/${access}`,
        ...params
    });
};
export const fetchAttributesWithValues = (params) => {
    if (!get(params, "categoryId")) {
        throw new Error('categoryId required');
    }
    
    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_CATEGORY_CATALOG_ATTRIBUTES_PATH + '/' + params.categoryId);
    return getAsJson({
        api: api,
        ...params
    });
};

export const fetchCategoryInfo = (params) => {
    if (!get(params, "categoryId")) {
        throw new Error('categoryId required');
    }
    
    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_CATEGORY_CATALOG_CATEGORY_INFO_PATH + '/' + params.categoryId);
    return getAsJson({
        api: api,
        ...params
    });
};
