import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CustomModal from "@common/components/src/ui/CustomModal";
import ToggleSwitch from '../../common/components/ToggleSwitch';
import {
    receiveDeleteLabelResponseCallback,
    receiveUpdateAccessTypeCallback
} from "../../labels/helpers";
import Label from '../label/Label';
import EditLabelsManageButton from './EditLabelsManageButton'
import { Constantes, LabelsUtils, RetailCompassStorage } from '@common/utils/';
import { isEqual } from 'lodash';
import DeleteLabelModal from '../modals/DeleteLabelModal';
import ChangeLabelPrivateToPublicModal from "../modals/ChangeLabelPrivateToPublicModal";
import ChangeLabelPublicToPrivateModal from "../modals/ChangeLabelPublicToPrivateModal";
import { hidePublicLabels } from '../LabelsState';

export default class EditLabelsButton extends Component {
    static propTypes = {
        labels: PropTypes.array.isRequired,
        forceOpenEditModal: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            openLabelModal: false,
            openLabelConfirm: false,
            openTypeChangeConfirm: false,
            selectedLabel: null,
            userFullName: RetailCompassStorage.getFullName(),
            changeTypeModal: null,
            tooglePublicLabels: props.hidePublicLabels,
        }
        this.toggleLabelModal = this.toggleLabelModal.bind(this);
        this.toggleLabelConfirm = this.toggleLabelConfirm.bind(this);
        this.toggleTypeChangeConfirm = this.toggleTypeChangeConfirm.bind(this);
        this.tooglePublicLabel = this.tooglePublicLabel.bind(this);
        this.selectLabelToDelete = this.selectLabelToDelete.bind(this);
        this.selectLabelToChangeType = this.selectLabelToChangeType.bind(this);
        this.deleteLabel = this.deleteLabel.bind(this);
        this.updateAccessType = this.updateAccessType.bind(this);
    }

    toggleLabelModal() {
        let toggleTo = !this.state.openLabelModal;
        this.setState({
            openLabelModal: toggleTo
        });
        if (toggleTo) {
            this.props.enableOpenEditModal();
        } else {
            this.props.disableOpenEditModal();
            this.props.disableForceOpenModal();
        }
    }

    toggleLabelConfirm() {
        this.setState({
            openLabelConfirm: !this.state.openLabelConfirm
        });
    }
    
    toggleTypeChangeConfirm() {
        this.setState({
            openTypeChangeConfirm: !this.state.openTypeChangeConfirm
        });
    }

    tooglePublicLabel() {
        this.setState({
            tooglePublicLabels: !this.state.tooglePublicLabels
        });

        setTimeout(() => {
            RetailCompassStorage.setHidePublicLabels(
                this.state.tooglePublicLabels
            );
            hidePublicLabels(
                this.props.dispatch,
                this.state.tooglePublicLabels
            );
        });
    }

    selectLabelToDelete(label) {
        this.setState({
            selectedLabel: label
        });
        this.toggleLabelConfirm();
    }

    selectLabelToChangeType(label) {
        const isOwner = isEqual(this.state.userFullName, label.userFullName);
        const privateToPublic = (
            <ChangeLabelPrivateToPublicModal
                onConfirm={this.updateAccessType}
                onCancel={this.toggleTypeChangeConfirm}
            />
        )
        const publicToPrivate = (
            <ChangeLabelPublicToPrivateModal
                onConfirm={this.updateAccessType}
                onCancel={this.toggleTypeChangeConfirm}
                ownerName={!isOwner && label.userFullName}
            />
        );
        this.setState({
            selectedLabel: label,
            changeTypeModal: label.accessType === Constantes.ACCESS_TYPES_LABEL.PUBLIC
                    ? publicToPrivate
                    : privateToPublic
        });
        this.toggleTypeChangeConfirm();
    }

    deleteLabel() {
        const _receiveDeleteLabelResponseCallback = receiveDeleteLabelResponseCallback(
            {
                dispatch: this.props.dispatch,
                fetchProductItems: this.props.fetchProductItems,
                currentFilter: this.props.currentFilter
            }
        );

        this.props.deleteLabel({ label: this.state.selectedLabel, receiveDeleteLabelResponseCallback: _receiveDeleteLabelResponseCallback });

        this.setState({
            selectedLabel: null
        });
        this.toggleLabelConfirm();
    }

    getLabelCreatedBy(label) {
        const created = moment(label.created);
        let createdBy = isEqual(this.state.userFullName, label.userFullName)
            ? "Creado por ti"
            : label.userFullName?.trim();

        if (createdBy.length > 17) {
            createdBy = createdBy.substring(0, 14) + "...";
        }

        return `${createdBy}, ${created.format("D MMM")}`;
    }

    updateAccessType() {
        const label = this.state.selectedLabel;
        const _receiveUpdateAccessTypeCallback = ({ label }) => {
            const canonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
            this.props.fetchListLabels({ categoryId: canonicalCategory.id });
            receiveUpdateAccessTypeCallback({
                dispatch: this.props.dispatch,
                fetchProductItems: this.props.fetchProductItems,
                currentFilter: this.props.currentFilter,
                label
            });
        };
        const accessType =
            label.accessType === Constantes.ACCESS_TYPES_LABEL.PUBLIC
                ? Constantes.ACCESS_TYPES_LABEL.PRIVATE
                : Constantes.ACCESS_TYPES_LABEL.PUBLIC;
        this.props.updateAccessType({
            label: { ...label, accessType },
            receiveUpdateAccessTypeCallback: _receiveUpdateAccessTypeCallback
        });
        this.setState({ selectedLabel: null });
        this.toggleTypeChangeConfirm();
    }

    render() {
        return (
            <div className="label-edition">
                <div onClick={this.toggleLabelModal}>
                    <BorderColorIcon /> Editar
                </div>
                <CustomModal
                    header={
                        <div>
                            <span className="bolder">Editar</span> labels
                        </div>
                    }
                    body={
                        <div>
                            <div className="edit-label-toggle">
                                <ToggleSwitch
                                    isOn={this.state.tooglePublicLabels}
                                    handleToggle={this.tooglePublicLabel}
                                    onColor={"#37CDA2"}
                                />
                                <p>
                                    {this.state.tooglePublicLabels
                                        ? "Mostrar"
                                        : "Ocultar"}{" "}
                                    labels públicos
                                </p>
                            </div>
                            <div className="edit-label-headers">
                                <div className="edit-label-item">
                                    <div className="label-title">Labels</div>
                                </div>
                                <div className="edit-label-manage">
                                    <div className="label-title">
                                        Administrar
                                    </div>
                                </div>
                                <div className="edit-label-created">
                                    <div className="label-title">
                                        Creado por
                                    </div>
                                </div>
                                <div className="edit-label-delete">
                                    <div className="label-title">Eliminar</div>
                                </div>
                            </div>
                            <div className="labels-container">
                                {this.props.labels
                                    .filter(label =>
                                        LabelsUtils.validateHidePublicLabel(
                                            this.state.tooglePublicLabels,
                                            label
                                        )
                                    )
                                    .map(item => {
                                        return (
                                            <div
                                                className="edit-label"
                                                key={`label-${item.id}-${item.accessType}`}
                                            >
                                                <div className="edit-label-item">
                                                    <Label
                                                        key={`label-list-${item.id}-${item.accessType}`}
                                                        label={item}
                                                        selected={false}
                                                        mode="row"
                                                        showCloseIcon={false}
                                                        onLabelSelection={null}
                                                    />
                                                </div>
                                                <div
                                                    className="edit-label-manage"
                                                    onClick={() =>
                                                        this.selectLabelToChangeType(
                                                            item
                                                        )
                                                    }
                                                >
                                                    <EditLabelsManageButton
                                                        isPublic={
                                                            item.accessType ===
                                                            Constantes
                                                                .ACCESS_TYPES_LABEL
                                                                .PUBLIC
                                                        }
                                                        id={
                                                            "manage-label-" +
                                                            item.id
                                                        }
                                                    />
                                                </div>
                                                <div className="edit-label-created">
                                                    {this.getLabelCreatedBy(
                                                        item
                                                    )}
                                                </div>
                                                <div className="edit-label-delete">
                                                    <DeleteForeverIcon
                                                        className={
                                                            "delete-button"
                                                        }
                                                        onClick={() => {
                                                            this.selectLabelToDelete(
                                                                item
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    }
                    isOpen={this.state.openLabelModal}
                    toggle={this.toggleLabelModal}
                    className="label-modal"
                />

                {this.state.openLabelConfirm && (
                    <DeleteLabelModal
                        labelType={
                            this.state.selectedLabel
                                ? this.state.selectedLabel.accessType
                                : false
                        }
                        className={"delete-modal"}
                        onConfirm={this.deleteLabel}
                        onCancel={this.toggleLabelConfirm}
                    />
                )}

                {this.state.openTypeChangeConfirm && this.state.changeTypeModal}
            </div>
        );
    }
}
