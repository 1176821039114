import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'reactstrap';
import AlertFormBasic from '../AlertForm/components/AlertFormBasic';
import { AlertType } from '../../AlertTypes';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import { Utils, Constantes } from "@common/utils/"


export class AlertModal extends PureComponent {
    static propTypes = {
        alert: AlertType,
        addNotification: PropTypes.func.isRequired,
        savedAlert: PropTypes.bool,
        alertModalState: PropTypes.shape({
            isOpen: PropTypes.bool.isRequired
        }).isRequired,
        closeAlertModal: PropTypes.func.isRequired,
        alertType: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        };
        this.toggle = this.toggle.bind(this);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.savedAlert) {
            let message = '';
            if (nextProps.alertType === Constantes.ALERT_TYPE.PRICE_CHANGE) {
                message = 'precio';
            } else if (nextProps.alertType === Constantes.ALERT_TYPE.STOCK_CHANGE) {
                message = 'stock';
            }
            this.props.addNotification(Utils.buildAlert("success-alert", "", "Creaste una alerta de cambios de " + message));
            this.props.closeAlertModal();
        }
    }
    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
        setTimeout(() => {
            if (!this.state.isOpen) this.props.closeAlertModal();
        }, 40)

    }

    render() {
        const { alertModalState } = this.props;

        const cancelButton = () => (
            <Button onClick={this.props.closeAlertModal} className="btn-link-alerts-cancel" color="link"  >
                Cancelar
            </Button>
        );
        const closeButton = () => (
            <div className="closeIconModal"><CloseIcon onClick={this.toggle} size="18" /></div>
        );

        return (
            <Modal isOpen={alertModalState.isOpen} className="modal-alerts theme-retailcompass" toggle={this.toggle} centered keyboard={false} backdrop={"static"} >
                <ModalHeader toggle={this.toggle} close={closeButton()}>
                    <strong>Crear</strong> alerta
                </ModalHeader>
                <ModalBody>
                    <Row >
                        <Col sm={12} >
                            <AlertFormBasic isModal={true}
                                external={cancelButton()} />

                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

export default AlertModal;
