import { find } from "lodash";
import { isArrayWithData } from "./utils";
import { isNullOrUndefined } from "./utils";

export const SHIPPING_MODULE = "SHIPPING";

export const isActiveShippingModule = (configuration, modules) => {
    if (isNullOrUndefined(configuration) || !configuration.shipping) {
        return false;
    }
    if (!isArrayWithData(modules)) {
        return false;
    }
    const shippingModule = find(modules, ["code", SHIPPING_MODULE]);
    return !isNullOrUndefined(shippingModule);
};

export const getCompetitorsByModule = (modules, code) => {
    if (!isArrayWithData(modules)) {
        return [];
    }
    const module = find(modules, ["code", code]);
    if (module === null || module === undefined) {
        return [];
    }
    return module.competitors;
};

export const getCompetitorsIdsByModule = (modules, code) => {
    if (!isArrayWithData(modules)) {
        return [];
    }
    const module = find(modules, ["code", code]);
    if (module === null || module === undefined) {
        return [];
    }
    return module.competitors.map((comptitor) => {
        return comptitor.id;
    });
};
