import { orderBy, find, remove, countBy, isEqual } from 'lodash';
import { LABEL_COLORS } from './constantes';
import { isArrayWithData } from './utils';
import moment from 'moment';
import { Constantes } from '..';

export const getLabelsFromResponse = (labels) => {
    if (!isArrayWithData(labels)) {
        return [];
    }
    labels.forEach((label) => {
        label.id = label.labelId;
        label.updated = moment(label.updated, moment.ISO_8601).format();

        if (isArrayWithData(label.labelProducts)) {
            label.labelProducts.forEach(plItem => {
                if (plItem.updated) {
                    plItem.updated = moment(plItem.updated, moment.ISO_8601).format();
                }
            });
            label.labelProducts = orderBy(label.labelProducts, ['updated'], ['desc']);
        }

    });
    return orderBy(labels, ['updated'], ['desc']);
}

export const hasRelationshipWithTheLabel = (labelProducts, productId) => {
    if (!isArrayWithData(labelProducts)) {
        return false;
    }
    const labelProduct = find(labelProducts, ['productId', Number(productId)]);
    if (!labelProduct) {
        return false;
    }
    return true;
}

export const removeLabelFromCurrentFilter = (currentFilter, label) => {
    if (currentFilter.labels) {
        remove(currentFilter.labels, (item) => { return label.id === item });
    }
    return currentFilter;
}

export const getNextLabelColor = (labels) => {
    if (!isArrayWithData(labels)) return LABEL_COLORS[0];

    let count = [], minCount = labels.length, countedBy = countBy(labels, "color");
    LABEL_COLORS.forEach((item) => {
        count[item.className] = countedBy[item.className] || 0;
        minCount = minCount > count[item.className] ? count[item.className] : minCount;
    });

    for (let i = 0; i < LABEL_COLORS.length; i++) {
        if (count[LABEL_COLORS[i].className] === minCount) {
            return LABEL_COLORS[i];
        }
    }

}


/**
 * cuando eliminamos un label de un producto, no es necesario llamar al API para traer...
 * el listado de labels nuevamente, es suficiente con quitar el producto del label...
 * de los datos que tenemos en redux. Con esto evitamos hacer tantas peticiones a la DB
 * 
 * @param {*} labelItems listado de labels existentes en redux
 * @param {*} labelId label a la cual se le quitará el producto
 * @param {*} productId producto a eliminar del label
 */
export const removeProductFromLabel = (labelItems, labelId, productId) => {
    if (isArrayWithData(labelItems)) {
        let labels = labelItems.map(label => {
            if (label.labelId === labelId && isArrayWithData(label.labelProducts)) {
                label.labelProducts = label.labelProducts.filter(item => item.productId !== productId);
                label.updated = moment(new Date(), moment.ISO_8601).format();
            }
            return label;
        })
        return orderBy(labels, ['updated'], ['desc']);
    }
    return [];
}

/**
 * actualiza la fecha de actualización del label y ordena por fecha...
 * para que el label actual quede en la primera posición.
 * 
 * @param {*} labelItems listado de labels existentes en redux
 * @param {*} labelId label a modificar
 */
export const refreshUpdateDateFromLabels = (labelItems, labelId) => {
    if (isArrayWithData(labelItems)) {
        let labels = labelItems.map(label => {
            if (label.labelId === labelId) {
                label.updated = moment(new Date(), moment.ISO_8601).format();
            }
            return label;
        })
        return orderBy(labels, ['updated'], ['desc']);
    }
    return [];
}

export const removeLabel = (labelItems, labelId) => {
    if (isArrayWithData(labelItems)) {
        return labelItems.filter(label => label.labelId !== labelId);
    }
    return [];
}

/**
 * Si los ordenes son distintos, significa que hubo un cambio en las posiciones de los elementos internos de las listas...
 * por lo que los labels deberían estar listos para ser enviados a guardar en la DB
 * 
 * @param {*} prevLabelItems 
 * @param {*} nextLabelItems 
 */
export const labelsNeedToBeSynchronized = (prevLabelItems, nextLabelItems) => {
    if (isArrayWithData(prevLabelItems) && isArrayWithData(nextLabelItems)) {
        let prevList = prevLabelItems.map(item => item.labelId);
        let nextList = nextLabelItems.map(item => item.labelId);

        if (!isEqual(prevList, nextList)) {
            return true;
        }
    }
    return false;
}

export const validateHidePublicLabel = (hidePublicLabel, label) => {
    return hidePublicLabel ? label.accessType !== Constantes.ACCESS_TYPES_LABEL.PUBLIC : true;
}