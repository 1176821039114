import React, { useState, useEffect, memo } from 'react'
import Select from 'react-select';

const ShippingSelect = ({ itemList, selected, onClickEvent }) => {
    const [itemSelected, setItemSelected] = useState(selected);
  
    useEffect(() => {
      const selectedOption = itemList.find((option) => option.value === selected.value);
      if (selectedOption) {
        setItemSelected(selectedOption);
      }
    }, [selected]);
  
    useEffect(() => {
      setItemSelected((prevItemSelected) => {
        const selectedOption = itemList.find((option) => option.value === prevItemSelected?.value);
        return selectedOption ? selectedOption : itemList[0];
      });
    }, [itemList]);
  
    const onChange = (value) => {
      setItemSelected(value);
      onClickEvent(value.value);
    };
  
    return (
      <div className="form">
        <Select
          value={itemSelected}
          onChange={onChange}
          options={itemList}
          clearable={false}
          className="form__form-group-select filters-container--colors"
          closeOnSelect={true}
          removeSelected={true}
          noResultsText="No hay registros"
        />
      </div>
    );
  };

export default memo(ShippingSelect)