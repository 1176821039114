import { Utils } from '@common/utils';
const ACTION_BASE_NAME = 'PRODUCT_LIST_BRAND_TABLE';

export const REQUEST_CHANGE_PAGE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CHANGE_PAGE');
export const REQUEST_BASE_SEARCH_REQUEST = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_BASE_SEARCH_REQUEST');
export const REQUEST_PRODUCT_SEARCH = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_PRODUCT_SEARCH');
export const RECEIVE_PRODUCT_SEARCH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH');
export const RECEIVE_PRODUCT_SEARCH_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_ERROR');
export const RECEIVE_PRODUCT_SEARCH_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_ABORT_ERROR');
export const REQUEST_CHANGE_LAST_FILTER = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CHANGE_LAST_FILTER');
export const REQUEST_CHANGE_ATTRIBUTE_SELECTED_DATA = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CHANGE_ATTRIBUTE_SELECTED_DATA');
export const REQUEST_CHANGE_LAST_FILTER_WITH_PRODUCTS = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CHANGE_LAST_FILTER_WITH_PRODUCTS');
export const REQUEST_CHANGE_LAST_SEARCH_REQUEST = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CHANGE_LAST_SEARCH_REQUEST');
export const RESET_STORE = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_STORE');
export const RESET_STORE_FOR_SHIPPING = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_STORE_FOR_SHIPPING');
export const UPDATE_CURRENT_PRODUCT = Utils.composeActionName(ACTION_BASE_NAME, 'UPDATE_CURRENT_PRODUCT');
export const UPDATE_SORT = Utils.composeActionName(ACTION_BASE_NAME, 'UPDATE_SORT');
export const REQUEST_PRODUCT_DETAIL_OPENED = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_PRODUCT_DETAIL_OPENED');

export const REQUEST_DOWNLOAD_EXPORT_SERVICE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_DOWNLOAD_EXPORT_SERVICE');
export const RECEIVE_DOWNLOAD_EXPORT_SERVICE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_DOWNLOAD_EXPORT_SERVICE');
export const RECEIVE_DOWNLOAD_EXPORT_SERVICE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_DOWNLOAD_EXPORT_SERVICE_ERROR');
export const RECEIVE_DOWNLOAD_EXPORT_SERVICE_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_DOWNLOAD_EXPORT_SERVICE_ABORT_ERROR');

export const SELECT_ALL_PRODUCTS = Utils.composeActionName(ACTION_BASE_NAME, 'SELECT_ALL_PRODUCTS');
export const DESELECT_ALL_PRODUCTS = Utils.composeActionName(ACTION_BASE_NAME, 'DESELECT_ALL_PRODUCTS');

export const FORCE_PRODUCT_LIST_UPDATE = Utils.composeActionName(ACTION_BASE_NAME, 'FORCE_PRODUCT_LIST_UPDATE');

export const BACK_BUTTON_PRESSED_NAVIGATE = Utils.composeActionName(ACTION_BASE_NAME, 'BACK_BUTTON_PRESSED_NAVIGATE');
export const UPDATE_ACTIVE_RANKING = Utils.composeActionName(ACTION_BASE_NAME, 'UPDATE_ACTIVE_RANKING');
export const UPDATE_COMPARER_TOOLTIP = Utils.composeActionName(ACTION_BASE_NAME, 'UPDATE_COMPARER_TOOLTIP');
export const UPDATE_SELECT_PRODUCTS_RANDOMS = Utils.composeActionName(ACTION_BASE_NAME, 'UPDATE_SELECT_PRODUCTS_RANDOMS');
export const UPDATE_MODEL_SEARCH_DONE = Utils.composeActionName(ACTION_BASE_NAME, 'UPDATE_MODEL_SEARCH_DONE');

export const REQUEST_STAR_CATEGORIES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_STAR_CATEGORIES');
export const RECEIVE_STAR_CATEGORIES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STAR_CATEGORIES');
export const RECEIVE_STAR_CATEGORIES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STAR_CATEGORIES_ERROR');
export const RECEIVE_STAR_CATEGORIES_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STAR_CATEGORIES_ABORT_ERROR');


export const REQUEST_STAR_CATEGORIES_FULL_PATH = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_STAR_CATEGORIES_FULL_PATH');
export const RECEIVE_STAR_CATEGORIES_FULL_PATH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STAR_CATEGORIES_FULL_PATH');
export const RECEIVE_STAR_CATEGORIES_ERROR_FULL_PATH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STAR_CATEGORIES_ERROR_FULL_PATH');
export const RECEIVE_STAR_CATEGORIES_ABORT_ERROR_FULL_PATH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_STAR_CATEGORIES_ABORT_ERROR_FULL_PATH');

export function updateCurrentProduct(currentProduct) {
    return {
        type: UPDATE_CURRENT_PRODUCT,
        payload: {
            currentProduct: currentProduct
        }
    }
}

export function requestChangePage(page) {
    return {
        type: REQUEST_CHANGE_PAGE,
        payload: {
            page: page
        }
    }
}

export function requestBaseSearchRequest(initialRequest) {
    return {
        type: REQUEST_BASE_SEARCH_REQUEST,
        payload: {
            ...initialRequest
        }
    }
}

export function requestChangeLastFilter(lastFilter) {
    return {
        type: REQUEST_CHANGE_LAST_FILTER,
        payload: {
            ...lastFilter
        }
    }
}
export function requestChangeAttributeSelectedData(dynamicAttributes) {
    return {
        type: REQUEST_CHANGE_ATTRIBUTE_SELECTED_DATA,
        payload: {
            ...dynamicAttributes
        }
    }
}

export function requestChangeLastSearchRequest(lastSearchRequest) {
    return {
        type: REQUEST_CHANGE_LAST_SEARCH_REQUEST,
        payload: {
            ...lastSearchRequest
        }
    }
}

export function requestChangeLastFilterWithProducts(lastFilterWithProducts) {
    return {
        type: REQUEST_CHANGE_LAST_FILTER_WITH_PRODUCTS,
        payload: {
            ...lastFilterWithProducts
        }
    }
}

export const requestProductSearchAction = () => ({
    type: REQUEST_PRODUCT_SEARCH
});

export const receiveProductSearchAction = (data) => ({
    type: RECEIVE_PRODUCT_SEARCH,
    payload: { ...data }
});

export const receiveProductSearchErrorAction = (error) => ({
    type: RECEIVE_PRODUCT_SEARCH_ERROR,
    payload: {
        error: error
    }
});

export const receiveProductSearchAbortErrorAction = (payload) => ({
    type: RECEIVE_PRODUCT_SEARCH_ABORT_ERROR,
    payload: { ...payload }
});

export const resetStoreAction = () => ({
    type: RESET_STORE
});

export const resetStoreForShippingAction = () => ({
    type: RESET_STORE_FOR_SHIPPING
});

export const updateSort = (sort) => ({
    type: UPDATE_SORT,
    payload: {
        sort: sort
    }
});

export function requestProductDetailOpened(productDetailOpened) {
    return {
        type: REQUEST_PRODUCT_DETAIL_OPENED,
        payload: {
            productDetailOpened: productDetailOpened
        }
    }
}



export const requestDownloadExportServiceAction = () => ({
    type: REQUEST_DOWNLOAD_EXPORT_SERVICE
});

export const receiveDownloadExportServiceAction = (data) => ({
    type: RECEIVE_DOWNLOAD_EXPORT_SERVICE,
    payload: { ...data }
});

export const receiveDownloadExportServiceErrorAction = (error) => ({
    type: RECEIVE_DOWNLOAD_EXPORT_SERVICE_ERROR,
    payload: {
        error: error
    }
});

export const receiveDownloadExportServiceAbortErrorAction = (payload) => ({
    type: RECEIVE_DOWNLOAD_EXPORT_SERVICE_ABORT_ERROR,
    payload: { ...payload }
});


export const mustForceProductListUpdateAction = (mustForceProductListUpdate) => ({
    type: FORCE_PRODUCT_LIST_UPDATE,
    payload: {
        mustForceProductListUpdate: mustForceProductListUpdate
    }
});

export const buttonBackPressedNavigate = (backPressedNavigate) => ({
    type: BACK_BUTTON_PRESSED_NAVIGATE,
    payload: {
        backPressedNavigate: backPressedNavigate
    }
});

export function updateActiveRanking(activeRanking) {
    return {
        type: UPDATE_ACTIVE_RANKING,
        payload: {
            activeRanking: activeRanking
        }
    }
}

export function updateComparerTooltip(value) {
    return {
        type: UPDATE_COMPARER_TOOLTIP,
        payload: {
            showComparerTooltip: value
        }
    }
}

export function updateModelSearchDone(value) {
    return {
        type: UPDATE_MODEL_SEARCH_DONE,
        payload: value
    }
}

export const requestStarCategoriesAction = () => ({
    type: REQUEST_STAR_CATEGORIES
});

export const receiveStarCategoriesAction = data => ({
    type: RECEIVE_STAR_CATEGORIES,
    payload: data
});

export const receiveStarCategoriesErrorAction = error => ({
    type: RECEIVE_STAR_CATEGORIES_ERROR,
    payload: { error }
});

export const receiveStarCategoriesAbortErrorAction = payload => ({
    type: RECEIVE_STAR_CATEGORIES_ABORT_ERROR,
    payload: { ...payload }
});


export const requestStarCategoriesFullPathAction = () => ({
    type: REQUEST_STAR_CATEGORIES_FULL_PATH
});

export const receiveStarCategoriesFullPathAction = data => ({
    type: RECEIVE_STAR_CATEGORIES_FULL_PATH,
    payload: data
});

export const receiveStarCategoriesErrorFullPathAction = error => ({
    type: RECEIVE_STAR_CATEGORIES_ERROR_FULL_PATH,
    payload: { error }
});

export const receiveStarCategoriesAbortErrorFullPathAction = payload => ({
    type: RECEIVE_STAR_CATEGORIES_ABORT_ERROR_FULL_PATH,
    payload: { ...payload }
});
