/**
 * @author @author <a href="mailto:jvillada@pricingcompass.com">Jorge Villada</a>
 * @class productAttributeService.js
 * @date 21 mar. 2018
 */


import { getAsJson, postAsJson } from './AbstractService';
import { get } from 'lodash';
import { ConstantesApi, Utils } from '@common/utils';

export const saveProductAttribute = (params) => {
    const data = get(params, "data", []);
    if (!Array.isArray(data) || !data.length) {
        throw new Error('invalid data to save');
    }
    data.forEach((item) => {
        if (!item.productId) {
            throw new Error('productId Required');
        }
        if (!item.attributeId) {
            throw new Error('attributeId Required');
        }
        if (!item.valueId) {
            throw new Error('valueId Required');
        }
    })

    params.data = { productAttributes: params.data };

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_ATTRIBUTE,
        ...params
    });
};

export const saveRelationshipProductAttributeNewLov = (params) => {
    const data = get(params, "data", {});

    if (!Utils.isObjectWithData(data)) {
        throw new Error('invalid data to save');
    }

    if (!data.productId) {
        throw new Error('productId Required');
    }

    if (!data.attributeId) {
        throw new Error('attributeId Required');
    }

    if (!data.userId) {
        throw new Error('userId Required');
    }

    if (!Utils.hasValue(data.valueName)) {
        throw new Error('valueName Required');
    }

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_ATTRIBUTE_NEW_LOVS,
        ...params
    });
};

export const fetchProductAttributes = params => {
    if (!params.productId) {
        throw new Error('productId is required');
    }

    let api = ConstantesApi.buildEndpoint(ConstantesApi.API_PRODUCT_ATTRIBUTES_PATH + '/' + params.productId);

    return getAsJson({
        api: api,
        ...params
    });
};
