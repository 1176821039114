import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Utils } from '@common/utils/';

const undoDelay = 7000;

export default class IdenticalActions extends Component {

    static propTypes = {
        showApproveButton: PropTypes.bool,
        btnClass: PropTypes.string,
        isRemovingTheMatch: PropTypes.bool,
        isTransformingTheMatch: PropTypes.bool,
        handleRemoveClick: PropTypes.func,
        onActionCompleted: PropTypes.func,
        product: PropTypes.object.isRequired,
    };

    static defaultProps = {
        className: "",
        handleRemoveClick: ()=>{},
    };

    constructor(props) {
        super(props);
        this.state = {
            waitingForUndo: false,
            undoClicked: false,
            shouldBeRemoved: false
        }
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onUndoClick = this.onUndoClick.bind(this);
    }

    onUndoClick(){
        this.setState({ undoClicked: true});
    }
    
    onRemoveClick(){
        this.props.handleRemoveClick(this.props.product);
    }

    render() {
        const { showRemoveButton, btnClass, isRemovingTheMatch, isTransformingTheMatch } = this.props;
        return(<div className="match__actions" key={"remove-"+Utils.generateRandomString()}>
            
            {
                this.state.waitingForUndo ? 
                <Button size={'sm'} className={"btn-outline-light action button__undo " + btnClass} disabled={isRemovingTheMatch || isTransformingTheMatch} onClick={this.onUndoClick}>
                    Deshacer
                </Button>
                :
                showRemoveButton && !this.state.shouldBeRemoved ? 
                <Button size={'sm'} className={"btn-outline-light action button__remove " + btnClass} disabled={isRemovingTheMatch || isTransformingTheMatch} onClick={this.onRemoveClick}>
                    Eliminar <span>match</span>
                </Button> 
                : null
            }
                
            </div>);
    }
}
