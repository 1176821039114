import * as TableState from '../../../table/TableState';
import * as TableActions from '../../../table/TableActions';
import * as FavoriteState from '../../../favorite/FavoriteState';
import { Utils } from '@common/utils';

export const mapStateToProps = (state, ownProps) => {
    let applicationData = {
        brands: state.productListBrandReducer.brands || [],
        stores: state.productListBrandReducer.stores || [],
        favoriteProductItems: state.favoriteStateReducer.favoriteProductItems || []
    };
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    currentFilter.selectedView = state.subRouterStateReducer.selectedView;
    currentFilter.communes = state.productListBrandReducer.communes;

    return {
        currentCanonicalCategory: state.appConfigReducer.currentCanonicalCategory,
        isRestoringLastState: state.productListBrandReducer.isRestoringLastState,
        isRestoringInitialState: state.productListBrandReducer.isRestoringInitialState,
        lastFilterWithProducts: state.productListBrandReducer.lastFilterWithProducts || {},
        brands: state.productListBrandReducer.brands || [],
        stores: state.productListBrandReducer.stores || [],
        ranges: state.productListBrandReducer.ranges || [],
        currentFilter: currentFilter,
        applicationData: applicationData,
        screenSizeItems: state.productListBrandReducer.screenSizeItems,
        dynamicAttributes: state.productListBrandReducer.dynamicAttributes,
        lastRequestTimestamp: state.productListBrandReducer.lastRequestTimestamp,
        favoriteProductItems: state.favoriteStateReducer.favoriteProductItems || [],
        attributes: state.productListBrandReducer.attributes,
        reduxFormInitialValues: state.productListBrandReducer.reduxFormInitialValues || {},
        elementsContainerClassName: ownProps.elementsContainerClassName,
        forceOpenEditModal: state.labelStateReducer.forceOpenEditModal,
        labels: state.labelStateReducer.labels || [],
        visibleLabelsForm: state.buttonNewLabelStateReducer.visibleLabelsForm,
        backPressedNavigate: state.productListBrandReducer.backPressedNavigate || false,
        hidePublicLabels: state.labelStateReducer.hidePublicLabels || false
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchProductItems: (params) => TableState.fetchProductItems(dispatch, params),
        fetchFavorites: () => FavoriteState.fetchFavorites(dispatch),
        requestChangeAttributeSelectedData: (dynamicAttributes) => dispatch(TableActions.requestChangeAttributeSelectedData(dynamicAttributes)),
        buttonBackPressedNavigate: () => (wasPressed) => dispatch(TableState.buttonBackPressedNavigate(!!wasPressed)),
    };
};
