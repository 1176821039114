import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Label from "./Label";

export default class LabelGroup extends PureComponent {
    static propTypes = {
        labelItems: PropTypes.array.isRequired,
        className: PropTypes.string,
        limit: PropTypes.number,
        onClickItem: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {};
        this.onClickItem = this.onClickItem.bind(this);
        this.getLabelItemsOrLimited = this.getLabelItemsOrLimited.bind(this);
        this.onClickMoreItem = this.onClickMoreItem.bind(this);
    }

    onClickItem(labelItem) {
        if (this.props.onClickItem) {
            this.props.onClickItem(labelItem);
        }
    }

    onClickMoreItem() {
        if (this.props.onClickMoreItem) {
            this.props.onClickMoreItem();
        }
    }

    getLabelItemsOrLimited() {
        const { limit, labelItems } = this.props;
        const _limit = limit || 0;
        let items = labelItems;

        if (_limit > 0) {
            items = labelItems.slice(0, _limit);
        }

        return items;
    }

    render() {
        const { className, labelItems } = this.props;
        const newLabelItems = this.getLabelItemsOrLimited();

        return (
            <div className={["label-group-container"].join(className)}>
                {newLabelItems &&
                    newLabelItems.map((labelItem, index) => {
                        return (
                            <Label
                                key={index}
                                name={labelItem.name}
                                id={labelItem.id + ""}
                                className={"label-group-label-item"}
                                onClick={() => this.onClickItem(labelItem)}
                            />
                        );
                    })}

                {newLabelItems.length !== labelItems.length ? (
                    <Label
                        name={"+" + (labelItems.length - newLabelItems.length)}
                        id={"MORE"}
                        className={"label-group-label-item-more"}
                        onClick={() => this.onClickMoreItem()}
                    />
                ) : null}
            </div>
        );
    }
}
