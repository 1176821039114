import React from "react";
import { UncontrolledTooltip } from 'reactstrap';

export const DescriptionWithTooltip = ({ description, showTooltip, classNames, id }) => {
    return (
        <span id={id} className={classNames}>
            {showTooltip && description ?
                <UncontrolledTooltip placement='top-start' boundariesElement='window' delay={0} target={id} >
                    {description}
                </UncontrolledTooltip>
                : null
            }
            {description}
        </span>
    )
}