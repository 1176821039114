import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { Utils } from "@common/utils";

const item = PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
});

export default class RankingFilter extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(item).isRequired,
        fieldName: PropTypes.string.isRequired,
        selectedValue: PropTypes.number,
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedValue: props.selectedValue
        };
        this.buildItem = this.buildItem.bind(this);
        this.onClick = this.onClick.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setState({
            selectedValue: this.props.selectedValue
        });
    }

    onClick(e, item) {
        if (!e || !e.target) return;

        const selectedValue = isEqual(item.value, this.props.selectedValue)
            ? null
            : item.value;
        this.setState({
            selectedValue: selectedValue
        });
        if (this.props.onChange) {
            this.props.onChange(selectedValue);
        }
    }

    buildItem(item) {
        let isActive = isEqual(this.props.selectedValue, item.value);
        return (
            <div
                className={"ranking-filter-item" + (isActive ? " active" : "")}
                onClick={e => this.onClick(e, item)}
                key={Utils.generateRandomId()}
            >
                {item.label}
            </div>
        );
    }

    removeFilter(ignoreOnChange) {
        this.setState({
            selectedValue: null
        });

        if (!ignoreOnChange && this.props.onChange) {
            this.props.onChange(null);
        }
    }

    render() {
        return (
            <div className="ranking-filter">
                {this.props.items.map(this.buildItem)}
            </div>
        );
    }
}
