import React from "react"
import TimeIntoCommerce from "../TimeIntoCommerce"
import LabelsProductDetail from "../../../../labels/label-list/LabelsProductDetail";
import ProductDetailTags from "../ProductDetailTags";
import ProductBadge from "../../ProductBadge";
import { Constantes } from "@common/utils/";
import { BrandModelDescription } from "./ProductDescription";
import { isEqual } from "lodash";

const ProductDescriptionPostpago = ({ producto, labels, dispatch, currentFilter, showPopoverRemove, tableStrategy, onRemoveLabel, hidePublicLabels }) => {

    const isBrandStore = () => {
        return isEqual(tableStrategy, Constantes.SubStoreType.BRAND_POSTPAID)
    }

    return (
        <div className="product-detail__card clearfix product-description">
            <div className="product-card__image">
                <img src={producto.image} onError={(img) => img.remove()} />
            </div>

            <div className="product-card__description brand">
                <ProductDetailTags attributes={producto.attributes} />
                {
                    isBrandStore() ?
                        <BrandModelDescriptionProvider producto={producto} />
                        : <BrandModelDescription producto={producto} />
                }
                <ProductBadge producto={producto} tableStrategy={tableStrategy} />
                <TimeIntoCommerce date={producto.created} />
                <div className="labels-container">
                    <LabelsProductDetail productId={producto.productId}
                        labels={labels}
                        showPopoverRemove={showPopoverRemove}
                        dispatch={dispatch}
                        currentFilter={currentFilter}
                        onRemoveLabel={onRemoveLabel}
                        hidePublicLabels={hidePublicLabels}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProductDescriptionPostpago

export const BrandModelDescriptionProvider = ({ producto }) => {
    return (
        <div id="brand_model">
            <h1> Marca: <span className="value">{producto.brand || null}</span> </h1>
            <h1> Modelo: <span className="value">{producto.model || null}</span> </h1>
        </div>
    )
}