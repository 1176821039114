import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from "@common/components/src/ui/CustomModal";
import ProductDetailBody from './ProductDetailBody';
import ProductDetailHeader from './ProductDetailHeader';
import { Utils, RetailCompassStorage } from "@common/utils/";
import { isEqual } from 'lodash';
import { onChangeViewPrice } from '../../../table/ViewPricesUtil';

class ProductDetail extends Component {

    static propTypes = {
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        producto: PropTypes.object,
        labels: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
        fetchProductItems: PropTypes.func.isRequired,
        currentFilter: PropTypes.object.isRequired,
        tableStrategy: PropTypes.string.isRequired,
        showRowWhenYouDoNotSellTheProduct: PropTypes.bool,
        stores: PropTypes.array.isRequired,
        lastFilter: PropTypes.object.isRequired,
        allowLabelRemoval: PropTypes.bool,
        className: PropTypes.string
    };

    static defaultProps = {
        onClose: ()=>{},
        labels:[],
        producto: {},
        allowLabelRemoval: true,
        className: ""
    };

    constructor(props) {
        super(props);
        
        this.state = {
            isOpen: props.isOpen,
            viewPrices: { value: props.currentFilter.viewPrices },
            refreshOnClose: false
        };
        this.toggle=this.toggle.bind(this)
        this.updateViewPriceState = this.updateViewPriceState.bind(this);
        this.onRemoveLabel = this.onRemoveLabel.bind(this);
    }

    toggle(){
        if(this.state.isOpen){
            if (this.state.viewPrices && !isEqual(this.props.currentFilter.viewPrices, this.state.viewPrices.value)) {
                onChangeViewPrice(this.state.viewPrices, this.props);
            }

            this.props.onClose(this.state.refreshOnClose);
        }
        this.setState({isOpen:!this.state.isOpen})
    }

    shouldComponentUpdate(nextProps) {
        if (!isEqual(nextProps.currentFilter.viewPrices, this.props.currentFilter.viewPrices)) {
            this.setState({
                viewPrices: {
                    value: nextProps.currentFilter.viewPrices
                }
            });
        }
        return true;
    }

    onRemoveLabel(label) {
        this.setState({ refreshOnClose: true })
    }

    componentDidUpdate(){
        if(this.state.isOpen !== this.props.isOpen){
            this.setState({isOpen: this.props.isOpen})
        }
    }

    updateViewPriceState(item) {
        RetailCompassStorage.setPriceView(item.value);
        this.setState({ viewPrices: item });
    }

    render() {
        return (
            Utils.isObjectWithData(this.props.producto) ?
                <CustomModal
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    wrapperClassName="product-detail-modal"
                    className={"product-detail " + this.props.className}
                    body={
                        <div className="product-detail-container">
                            <ProductDetailHeader
                                producto={this.props.producto}
                                labels={this.props.labels}
                                dispatch={this.props.dispatch}
                                fetchProductItems={this.props.fetchProductItems}
                                currentFilter={this.props.currentFilter}
                                tableStrategy={this.props.tableStrategy}
                                showPopoverRemove={this.props.allowLabelRemoval}
                                onRemoveLabel={this.onRemoveLabel}
                                hidePublicLabels={this.props.hidePublicLabels}
                            />
                            <div className="product-detail__table">
                                <ProductDetailBody
                                    canonicalProduct={this.props.producto}
                                    showRowWhenYouDoNotSellTheProduct={this.props.showRowWhenYouDoNotSellTheProduct}
                                    stores={this.props.stores}
                                    lastFilter={this.props.lastFilter}
                                    tableStrategy={this.props.tableStrategy}
                                    viewPrices={this.state.viewPrices.value}
                                    updateViewPriceState={this.updateViewPriceState} />
                            </div>
                        </div>
                    }
                />
                : null
        );
    }

}

export default ProductDetail;
