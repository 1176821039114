import React, { Component } from 'react';
import { find, orderBy } from "lodash";
import { PropTypes } from "prop-types";
import { CustomTable } from '@common/components';
import ValidationCell from './ValidationCell';
import RemovedActions from "./RemovedActions";
import { Utils, Constantes } from "@common/utils/"
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export class RemovedMatchesTable extends Component {

    static propTypes = {
        currentProduct: PropTypes.object.isRequired,
        isTransformingTheMatch: PropTypes.bool,
        isRemovingTheMatch: PropTypes.bool,
        allMatches: PropTypes.array.isRequired,
        handleApproveClick: PropTypes.func,
        customActions: PropTypes.func,
        showImageComparer: PropTypes.bool,
        comparerLeftTitle: PropTypes.string,
        comparerRightTitle: PropTypes.string,
        comparerThumbnailMainTitle: PropTypes.string
    };

    static defaultProps = {
        removeIdentical: () => { },
        isTransformingTheMatch: false,
        isRemovingTheMatch: false,
        comparerRightTitle: "Match eliminado: "
    };

    constructor(props) {
        super(props);
        this.state = {
            showTable: false,
            count: 0
        };
        this.getActions = this.getActions.bind(this);
        this.toggleTable = this.toggleTable.bind(this)
    }

    toggleTable() {
        this.setState({ showTable: !this.state.showTable });
    }

    getHeaderContent(count) {
        let displayControl = this.state.showTable ?
            <span className="display-control" onClick={this.toggleTable}>Ocultar <KeyboardArrowUpIcon /></span> :
            <span className="display-control" onClick={this.toggleTable}>Ver todos <KeyboardArrowDownIcon /></span>;

        return <div className="toggle-header">
            <span className="header-text">{Utils.getCountText(count, "MATCH ELIMINADO", "MATCHES ELIMINADOS")}</span>
            {count ? displayControl : null}
        </div>
    }

    getActions(product) {
        if (this.props.customActions) {
            return this.props.customActions(product);
        }

        const identicalActionsProps = {
            isTransformingTheMatch: this.props.isTransformingTheMatch,
            isRemovingTheMatch: this.props.isRemovingTheMatch,
            btnClass: "btn__sm",
            handleApproveClick: this.props.handleApproveClick,
        }

        return <RemovedActions {...identicalActionsProps} product={product} />
    }

    render() {
        let { currentProduct, allMatches } = this.props;

        if (!Utils.isObjectWithData(currentProduct)) {
            return null;
        }
        currentProduct.deletedMatches = currentProduct.removedMatches && currentProduct.removedMatches.map(o => {
            let match = find(allMatches, ["competitorId", o.productId]) || {};
            let updatedTimeStamp = moment(match.updated, Constantes.DATE_FORMATS.MATCH_UPDATED_FORMAT);
            match.updatedDateTime = updatedTimeStamp.format("X");
            return { ...match, ...o }
        });

        let matches = [];

        if (Utils.isArrayWithData(currentProduct.deletedMatches)) {
            matches = orderBy(currentProduct.deletedMatches.map(o => ({ ...o, canonicalProduct: currentProduct })), ["updatedDateTime"], ["desc"]);
        }


        return (<CustomTable
            responsive={true}
            headers={[{
                key: 'validationCell',
                name: this.getHeaderContent(matches.length),
                sortable: false,
                className: 'header-min-price',
                cellClass: 'validation-cell',
                formatter: (product) => {
                    return <ValidationCell product={product}
                        priceType={Constantes.PriceType.MIN_PRICE}
                        showImageComparer={true}
                        comparerLeftTitle={this.props.comparerLeftTitle}
                        comparerRightTitle={this.props.comparerRightTitle}
                        productActions={this.getActions(product)}
                        comparerThumbnailMainTitle={this.props.comparerThumbnailMainTitle}
                    />
                }
            }]}
            rows={matches}
            tableIdId='validationsTable--matches'
            className={'table--bordered validations-module toggle-table' + (!this.state.showTable ? " hidden-table" : "")}
            rowKey="productId"
            onSort={() => { }}
            count={matches.length}
            allRows={[]}
            rowInfo={null}
            key={currentProduct.productId}
            sortKeyDefault={'validationCell'}
            sortDirectionDefault={'ASC'}
            onRowClick={() => { }}
        />
        )
    }
}
export default RemovedMatchesTable;
