import { HistoricActionsBuilder } from './HistoricActions';
import { HistoricService } from '@common/data-access/';
import moment from 'moment';
import { RetailCompassStorage, Constantes } from '@common/utils';

export const initialState = {
    isFetchingHistoricData: false,
    error: null,
    historicData: null,
    emptyHistory: false,

    isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: false,
    lowerPriceFromMaxHistoricPeriodSelectedStores: null,
    lowerPriceFromMaxHistoricPeriodSelectedStoresError: null,

    isFetchingLowerPriceBetweenSelectedDatesSelectedStores: false,
    lowerPriceBetweenSelectedDatesSelectedStores: null,
    lowerPriceBetweenSelectedDatesSelectedStoresError: null,

    isFetchingLowerPriceFromMaxHistoricPeriodAllStores: false,
    lowerPriceFromMaxHistoricPeriodAllStores: null,
    lowerPriceFromMaxHistoricPeriodAllStoresError: null,

    isFetchingLowerPriceBetweenSelectedDatesAllStores: false,
    lowerPriceBetweenSelectedDatesAllStores: null,
    lowerPriceBetweenSelectedDatesAllStoresError: null,

    isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: false,
    lowerPriceFromMaxHistoricPeriodYourProduct: null,
    lowerPriceFromMaxHistoricPeriodYourProductError: null,

    isFetchingLowerPriceBetweenSelectedDatesYourProduct: false,
    lowerPriceBetweenSelectedDatesYourProduct: null,
    lowerPriceBetweenSelectedDatesYourProductError: null,
};

const THIRTY_DAYS = 30;

export const HistoricStateBuilder = (consumerType) => {

    if (!consumerType) {
        throw 'ConsumerType cant be null';
    }

    const HistoricActions = HistoricActionsBuilder(consumerType);
    let abortController = new window.AbortController();
    const fetchHistoricData = (dispatch, params) => {
        abortController.abort();
        abortController = new window.AbortController();
        let today = moment.utc();
        let { productIds, disableAbortController } = params;
        const to = params.to || today.format();
        const from = params.from || today.subtract(30, "days").format();
        let data = {
            productIds: productIds.join(","),
            to: to,
            from: from,
            fields: "HISTORY",
            countryCode: RetailCompassStorage.getCountryCode(),
            includeCardPrices: params.includeCardPrices
        }

        const receiveCallback = (response) => {
            if (response.history) {
                return HistoricActions.receiveProductHistoryAction(response);
            } else {
                return HistoricActions.receiveProductHistoryEmptyAction();
            }
        };

        const receiveAbortErrorCallback = (payload) => {
            return HistoricActions.receiveProductHistoryAbortErrorAction(payload);
        };

        console.warn("from h.state > disableAbortController: ", disableAbortController)
        const signal = disableAbortController ? undefined : abortController.signal;
        return HistoricService.fetchProductHistory({
            signal,
            dispatch,
            data: data,
            requestCallback: HistoricActions.requestProductHistoryAction,
            receiveCallback: receiveCallback,
            receiveErrorCallback: HistoricActions.receiveProductHistoryErrorAction,
            receiveAbortErrorCallback: receiveAbortErrorCallback
        });
    };

    let lowerPriceFromMaxHistoricPeriodSelectedStoresAbortController = new window.AbortController();
    const fetchLowerPriceFromMaxHistoricPeriodSelectedStores = (dispatch, params) => {
        const maxHistoricTimeAgo = moment().subtract(Constantes.MAX_HISTORY_TIME.value, Constantes.MAX_HISTORY_TIME.unit).toISOString();
        let data = {
            productIds: params.productIds.join(","),
            from: maxHistoricTimeAgo,
            fields: "BEST_PRICE",
            countryCode: RetailCompassStorage.getCountryCode(),
            priceToEvaluate: params.priceToEvaluate
        }

        let parameters = {
            data: data,
            lowerPriceAbortController: lowerPriceFromMaxHistoricPeriodSelectedStoresAbortController,
            requestCallback: HistoricActions.requestLowerPriceFromMaxHistoricPeriodSelectedStoresAction,
            receiveCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodSelectedStoresAction,
            receiveErrorCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodSelectedStoresErrorAction,
            receiveAbortErrorCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodSelectedStoresAbortErrorAction
        };

        return fetchLowerPrice(dispatch, parameters);
    };


    let lowerPriceBetweenSelectedDatesSelectedStoresAbortController = new window.AbortController();
    const fetchLowerPriceBetweenSelectedDatesSelectedStores = (dispatch, params) => {
        let today = moment.utc();
        const data = {
            productIds: params.productIds.join(","),
            to: params.to || today.format(),
            from: params.from || today.subtract(30, "days").format(),
            fields: "BEST_PRICE",
            countryCode: RetailCompassStorage.getCountryCode(),
            priceToEvaluate: params.priceToEvaluate
        }

        let parameters = {
            data: data,
            lowerPriceAbortController: lowerPriceBetweenSelectedDatesSelectedStoresAbortController,
            requestCallback: HistoricActions.requestLowerPriceBetweenSelectedDatesSelectedStoresAction,
            receiveCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesSelectedStoresAction,
            receiveErrorCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesSelectedStoresErrorAction,
            receiveAbortErrorCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesSelectedStoresAbortErrorAction
        };

        return fetchLowerPrice(dispatch, parameters);
    };



    let lowerPriceFromMaxHistoricPeriodAllStoresAbortController = new window.AbortController();
    const fetchLowerPriceFromMaxHistoricPeriodAllStores = (dispatch, params) => {
        const maxHistoricTimeAgo = moment().subtract(Constantes.MAX_HISTORY_TIME.value, Constantes.MAX_HISTORY_TIME.unit).toISOString();
        const data = {
            productIds: params.productIds.join(","),
            from: maxHistoricTimeAgo,
            fields: "BEST_PRICE",
            countryCode: RetailCompassStorage.getCountryCode(),
            priceToEvaluate: params.priceToEvaluate
        }

        let parameters = {
            data: data,
            lowerPriceAbortController: lowerPriceFromMaxHistoricPeriodAllStoresAbortController,
            requestCallback: HistoricActions.requestLowerPriceFromMaxHistoricPeriodAllStoresAction,
            receiveCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodAllStoresAction,
            receiveErrorCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodAllStoresErrorAction,
            receiveAbortErrorCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodAllStoresAbortErrorAction
        };

        return fetchLowerPrice(dispatch, parameters);
    };

    let lowerPriceBetweenSelectedDatesllStoresAbortController = new window.AbortController();
    const fetchLowerPriceBetweenSelectedDatesAllStores = (dispatch, params) => {
        let today = moment.utc();
        const data = {
            productIds: params.productIds.join(","),
            to: params.to || today.format(),
            from: params.from || today.subtract(30, "days").format(),
            fields: "BEST_PRICE",
            countryCode: RetailCompassStorage.getCountryCode(),
            priceToEvaluate: params.priceToEvaluate
        }

        let parameters = {
            data: data,
            lowerPriceAbortController: lowerPriceBetweenSelectedDatesllStoresAbortController,
            requestCallback: HistoricActions.requestLowerPriceBetweenSelectedDatesAllStoresAction,
            receiveCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesAllStoresAction,
            receiveErrorCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesAllStoresErrorAction,
            receiveAbortErrorCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesAllStoresAbortErrorAction
        };

        return fetchLowerPrice(dispatch, parameters);
    };


    let lowerPriceFromMaxHistoricPeriodYourProductAbortController = new window.AbortController();
    const fetchLowerPriceFromMaxHistoricPeriodYourProduct = (dispatch, params) => {
        const maxHistoricTimeAgo = moment().subtract(Constantes.MAX_HISTORY_TIME.value, Constantes.MAX_HISTORY_TIME.unit).toISOString();
        let data = {
            productIds: params.yourProductId + '',
            from: maxHistoricTimeAgo,
            fields: "BEST_PRICE",
            countryCode: RetailCompassStorage.getCountryCode(),
            priceToEvaluate: params.priceToEvaluate
        }

        let parameters = {
            data: data,
            lowerPriceAbortController: lowerPriceFromMaxHistoricPeriodYourProductAbortController,
            requestCallback: HistoricActions.requestLowerPriceFromMaxHistoricPeriodYourProductAction,
            receiveCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodYourProductAction,
            receiveErrorCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodYourProductErrorAction,
            receiveAbortErrorCallback: HistoricActions.receiveLowerPriceFromMaxHistoricPeriodYourProductAbortErrorAction
        };

        return fetchLowerPrice(dispatch, parameters);
    };

    let lowerPriceBetweenSelectedDatesYourProductAbortController = new window.AbortController();
    const fetchLowerPriceBetweenSelectedDatesYourProduct = (dispatch, params) => {
        let today = moment.utc();
        let data = {
            productIds: params.productIds + '',
            from: params.from || today.subtract(30, "days").format(),
            to: params.to || today.format(),
            fields: "BEST_PRICE",
            countryCode: RetailCompassStorage.getCountryCode(),
            priceToEvaluate: params.priceToEvaluate
        }

        let parameters = {
            data: data,
            lowerPriceAbortController: lowerPriceBetweenSelectedDatesYourProductAbortController,
            requestCallback: HistoricActions.requestLowerPriceBetweenSelectedDatesYourProductAction,
            receiveCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesYourProductAction,
            receiveErrorCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesYourProductErrorAction,
            receiveAbortErrorCallback: HistoricActions.receiveLowerPriceBetweenSelectedDatesYourProductAbortErrorAction
        };

        return fetchLowerPrice(dispatch, parameters);
    };

    const fetchLowerPrice = (dispatch, parameters) => {
        let {
            data,
            requestCallback,
            receiveCallback,
            receiveErrorCallback,
            receiveAbortErrorCallback,
            lowerPriceAbortController
        } = parameters;

        lowerPriceAbortController.abort();
        lowerPriceAbortController = new window.AbortController();

        const signal = lowerPriceAbortController.signal;
        return HistoricService.fetchProductHistory({
            signal,
            dispatch,
            data: data,
            requestCallback: requestCallback,
            receiveCallback: receiveCallback,
            receiveErrorCallback: receiveErrorCallback,
            receiveAbortErrorCallback: receiveAbortErrorCallback
        });
    };

    const HistoricStateReducer = (state, action) => {
        state = state || initialState;
        action = action || {};

        switch (action.type) {
            case HistoricActions.SET_INITIAL_STATE:
                return Object.assign({}, initialState);
            case HistoricActions.REQUEST_HISTORIC_DATA:
                return Object.assign({}, state, {
                    historicData: null,
                    isFetchingHistoricData: true,
                    emptyHistory: false
                });
            case HistoricActions.RECEIVE_HISTORIC_DATA:
                return Object.assign({}, state, {
                    isFetchingHistoricData: false,
                    historicData: action.payload,
                    emptyHistory: false
                });
            case HistoricActions.RECEIVE_HISTORIC_DATA_EMPTY:
                return Object.assign({}, state, {
                    emptyHistory: true,
                    isFetchingHistoricData: false,
                });
            case HistoricActions.RECEIVE_HISTORIC_DATA_ERROR:
                return Object.assign({}, state, {
                    isFetchingHistoricData: false,
                    emptyHistory: false
                });
            case HistoricActions.RECEIVE_HISTORIC_DATA_ABORT_ERROR:
                return Object.assign({}, state, {
                    isFetchingHistoricData: false,
                    error: action.payload.error,
                    emptyHistory: false
                });

            case HistoricActions.REQUEST_LOWER_PRICE_A_YEAR_AGO_ALL_STORES:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodAllStores: true,
                    lowerPriceFromMaxHistoricPeriodAllStores: null
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES:
                return Object.assign({}, state, {
                    lowerPriceFromMaxHistoricPeriodAllStores: action.payload,
                    isFetchingLowerPriceFromMaxHistoricPeriodAllStores: false
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodAllStores: false,
                    lowerPriceFromMaxHistoricPeriodAllStoresError: action.payload.error
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_ALL_STORES_ABORT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodAllStores: false
                });

            case HistoricActions.REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesAllStores: true,
                    lowerPriceBetweenSelectedDatesAllStores: null
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES:
                return Object.assign({}, state, {
                    lowerPriceBetweenSelectedDatesAllStores: action.payload,
                    isFetchingLowerPriceBetweenSelectedDatesAllStores: false
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesAllStores: false,
                    lowerPriceBetweenSelectedDatesAllStoresError: action.payload.error
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_ALL_STORES_ABORT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesAllStores: false
                });

            case HistoricActions.REQUEST_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: true,
                    lowerPriceFromMaxHistoricPeriodSelectedStores: null
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES:
                return Object.assign({}, state, {
                    lowerPriceFromMaxHistoricPeriodSelectedStores: action.payload,
                    isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: false
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: false,
                    lowerPriceFromMaxHistoricPeriodSelectedStoresError: action.payload.error
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_SELECTED_STORES_ABORT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodSelectedStores: false
                });

            case HistoricActions.REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesSelectedStores: true,
                    lowerPriceBetweenSelectedDatesSelectedStores: null
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES:
                return Object.assign({}, state, {
                    lowerPriceBetweenSelectedDatesSelectedStores: action.payload,
                    isFetchingLowerPriceBetweenSelectedDatesSelectedStores: false
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesSelectedStores: false,
                    lowerPriceBetweenSelectedDatesSelectedStoresError: action.payload.error
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_SELECTED_STORES_ABORT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesSelectedStores: false
                });

            case HistoricActions.REQUEST_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: true,
                    lowerPriceFromMaxHistoricPeriodYourProduct: null
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT:
                return Object.assign({}, state, {
                    lowerPriceFromMaxHistoricPeriodYourProduct: action.payload,
                    isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: false
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: false,
                    lowerPriceFromMaxHistoricPeriodYourProductError: action.payload.error
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_A_YEAR_AGO_YOUR_PRODUCT_ABORT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceFromMaxHistoricPeriodYourProduct: false
                });

            case HistoricActions.REQUEST_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesYourProduct: true,
                    lowerPriceBetweenSelectedDatesYourProduct: null
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT:
                return Object.assign({}, state, {
                    lowerPriceBetweenSelectedDatesYourProduct: action.payload,
                    isFetchingLowerPriceBetweenSelectedDatesYourProduct: false
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesYourProduct: false,
                    lowerPriceBetweenSelectedDatesYourProductError: action.payload.error
                });
            case HistoricActions.RECEIVE_LOWER_PRICE_THIRTY_DAYS_AGO_YOUR_PRODUCT_ABORT_ERROR:
                return Object.assign({}, state, {
                    isFetchingLowerPriceBetweenSelectedDatesYourProduct: false
                });

            default:
                return state;
        }
    };


    return {
        initialState,
        fetchHistoricData,
        fetchLowerPriceFromMaxHistoricPeriodSelectedStores,
        fetchLowerPriceBetweenSelectedDatesSelectedStores,
        fetchLowerPriceFromMaxHistoricPeriodAllStores,
        fetchLowerPriceBetweenSelectedDatesAllStores,
        fetchLowerPriceFromMaxHistoricPeriodYourProduct,
        fetchLowerPriceBetweenSelectedDatesYourProduct,
        fetchLowerPrice,
        HistoricStateReducer
    }
}


export const ComparerHistoricState = HistoricStateBuilder(Constantes.HISTORIC_CONSUMER_TYPE.COMPARER);
export const ProductDetailHistoricState = HistoricStateBuilder(Constantes.HISTORIC_CONSUMER_TYPE.PRODUCT_DETAIL);
