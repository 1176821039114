import React, { Component } from 'react'
import PropTypes from 'prop-types';
import CustomModal from "@common/components/src/ui/CustomModal";
import { Button } from 'reactstrap';
import { get } from 'lodash';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export class ColorAssignmentConfirmModal extends Component {

    static propTypes = {
        openModal: PropTypes.bool,
        container: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
        toggleModal: PropTypes.func,
        createNewColor: PropTypes.func,
        matchProduct: PropTypes.object,
        selectedColor: PropTypes.object
    };

    static defaultProps = {
        openModal: false,
        matchProduct: null,
        selectedColor: null
    }

    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        }

        this.enterWithoutEffect = this.enterWithoutEffect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    enterWithoutEffect(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    handleSubmit(params) {
        this.props.createNewColor && this.props.createNewColor(this.props.matchProduct, this.props.selectedColor);
    }

    render() {
        const { matchProduct } = this.props;

        let prevColor = matchProduct && !!matchProduct.hasUnvalidatedColors && matchProduct.unvalidatedColors.map(color => color.name).join(", ");
        return (<form className="form color-assignment-confirm-form" onSubmit={this.handleSubmit}>
            <CustomModal
                className="color-assignment-confirm-form-modal"
                container={this.props.container}
                body={
                    <div className="color-assignment-confirm-form-body">
                        <div>Vas a guardar el</div>
                       
                        <div className='prev-color'>{prevColor || 'Sin color'}</div>
                       
                        <div>como</div>
                       
                        <KeyboardArrowDownIcon className="icon" fontSize="large" />

                        <div className='next-color'>{get(this.props.selectedColor, 'name', '')}</div>
                    </div>
                }
                footer={
                    <div className="actions">
                        <Button onClick={undefined} className="btn-cancel" color="link" onClick={() => { this.props.toggleModal() }} >
                            Cancelar
                        </Button>

                        <Button disabled={false} onClick={() => { this.handleSubmit() }} className="btn btn-success btn-ok" >
                            OK
                        </Button>
                    </div>
                }
                isOpen={this.props.openModal}
                toggle={this.props.toggleModal}
            />

        </form>
        )
    }
}

export default ColorAssignmentConfirmModal;
