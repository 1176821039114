import * as Utils from "../../utils/utils";


const ACTION_BASE_NAME = 'REPORT_RETAIL';

export const REPORT_CHANGE = Utils.composeActionName(ACTION_BASE_NAME, 'REPORT_CHANGE');
export const REPORT_RESET = Utils.composeActionName(ACTION_BASE_NAME, 'REPORT_RESET');
export const SUBREPORT_CHANGE = Utils.composeActionName(ACTION_BASE_NAME, 'SUBREPORT_CHANGE');
export const SUBREPORT_RESET = Utils.composeActionName(ACTION_BASE_NAME, 'SUBREPORT_RESET');
export const RESET_ALL = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_ALL');
export const SELECTION_VIEW = Utils.composeActionName(ACTION_BASE_NAME, 'SELECTION_VIEW');
export const SELECTION_SHIPPING_TYPE = Utils.composeActionName(ACTION_BASE_NAME, 'SELECTION_SHIPPING_TYPE');
export const SELECTION_VIEW_RESET = Utils.composeActionName(ACTION_BASE_NAME, 'SELECTION_VIEW_RESET');
export const SELECTION_COMMUNE = Utils.composeActionName(ACTION_BASE_NAME, 'SELECTION_COMMUNE');
export const SEND_COST = Utils.composeActionName(ACTION_BASE_NAME, 'SEND_COST');
export const SEND_SHIPMENT = Utils.composeActionName(ACTION_BASE_NAME, 'SEND_SHIPMENT');

export const reportChange = (report) => ({
    type: REPORT_CHANGE,
    payload: {
        report: report
    }
});

export const subReportChange = (subReport) => ({
    type: SUBREPORT_CHANGE,
    payload: {
        subReport: subReport
    }
});

export const resetReport = () => ({
    type: REPORT_RESET
});

export const resetSubReport = () => ({
    type: SUBREPORT_RESET
});

export const resetAll = () => ({
    type: RESET_ALL
});

export const selectionView = (selectedView) => ({
    type: SELECTION_VIEW,
    payload: {
        selectedView: selectedView
    }
});

export const resetSelectedView = () => ({
    type: SELECTION_VIEW_RESET
});

export const selectionShippingType = (shippingType) => ({
    type: SELECTION_SHIPPING_TYPE,
    payload: {
        shippingType: shippingType
    }
});

export const selectionCommune = (commune) => ({
    type: SELECTION_COMMUNE,
    payload: {
        commune: commune
    }
});

export const sendCost = (value) => ({
    type: SEND_COST,
    payload: {
        sendCost: value
    }
});

export const sendShipment = (value) => ({
    type: SEND_SHIPMENT,
    payload: {
        sendShipment: value
    }
});