import React from 'react'
import CloseIcon from "mdi-react/CloseIcon";

const ButtonClose = ({ eventOnClick }) => {

    const onClick = () => {
        if (eventOnClick) eventOnClick()
    }

    return (
        <button type="button" className='close' onClick={onClick}>
            <CloseIcon className="close" size={22} />
        </button>
    )
}

export default ButtonClose