import React from "react";
import LabelModal from "./LabelModal";

const ChangeLabelPrivateToPublicModal = ({ onConfirm, onCancel }) => {
    const title = "Label público";
    const subTitle = "¿Estás seguro de realizar esta acción?";
    const description =
        "Hacer un label púbico puede simplificar mucho el trabajo en tu equipo, recuerda que todos podrán editarlo o eliminarlo";
    const confirmAction = "Sí, hacerlo público";

    return (
        <LabelModal
            title={title}
            subtitle={subTitle}
            description={description}
            confirmAction={confirmAction}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
};

export default ChangeLabelPrivateToPublicModal;
