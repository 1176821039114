/* eslint-disable no-undef */
import React from 'react'
import { RetailCompassStorage, Utils, Constantes, FormatMatchedProducts } from "@common/utils/";
import { filter, findIndex, includes, minBy, find, sortBy, remove, orderBy, some, concat, isNil, pullAt } from 'lodash';
import moment from 'moment';
import CreditCardTooltipIcon from "@material-ui/icons/CreditCard";
import { renderToString } from 'react-dom/server';
import { getPriceByView } from '../common/components/product_detail/PriceUtils'
import { SellerNameButton } from '../common/components/SellerNameButton';

export const STORE_COLORS = { [Constantes.StoreType.RETAILER]: "#5dade2", [Constantes.StoreType.BRAND]: "#555555" };
export const CHART_LINE_WIDTH = { "MY_STORE": 4, "COMPETITORS": 2 };
export const COMPETITORS_COLORS = ["#33d500", "#ff0080", "#ff9d00", "#943126", "#2fcbac", "#8b95a0", "#cad100", "#ff0000", "#ff87dc", "#008137", "#a230d3", "#2300ff", "#9a7d0a"];
export const RANGE_OPTIONS = [
    { label: "7 días", days: 7, today: moment().format("DD/MM/YYYY") },
    { label: "30 días", days: 30, today: moment().format("DD/MM/YYYY") },
    { label: "90 días", days: 90, today: moment().format("DD/MM/YYYY") },
    { label: "1 año", days: 365, today: moment().format("DD/MM/YYYY") }
];

export const isFilteringByStores = ({ configOptions, lastFilter = {}, stores = [] }) => {
    if (configOptions && configOptions.mustIgnoreStoreFilter) {
        return false;
    }
    // Si el usuario selecciona todos los retailers, debe devolver false
    // si el usuario no está filtrando retailers, debe devolver false
    // si el usuario está filtrando por uno o varios retailers, debe devolver true
    let storesInLastFilter = lastFilter.stores || [];
    if (stores.length === storesInLastFilter.length) {
        return false;
    }
    return Utils.hasValue(storesInLastFilter);
}

export const getMinMatchesToday = (filteredByStores, pricesToShow, { allCompetitorsProducts = [] }, lastFilter = {}) => {
    let matches = FormatMatchedProducts.buildUniqueProductByStoreInMarket(allCompetitorsProducts);
    let allStores = getMinPriceMatch(matches, lastFilter, pricesToShow, false);

    if (!filteredByStores) {
        return { allStores, selectedStores: allStores }
    }
    return {
        allStores,
        selectedStores: getMinPriceMatch(matches, lastFilter, pricesToShow, true)
    }
}

const getMinPriceMatch = (matches, lastFilter, pricesToShow, filteredByStores = false) => {
    const includedStores = RetailCompassStorage.getIncludeStores();
    const includeCardPrices = true;
    return minBy(filter(
        matches, o => {
            let correctSellerType = true, correctStore = true;
            if (lastFilter.sellerType && lastFilter.sellerType.length === 1) {
                correctSellerType = o.isSeller ? lastFilter.sellerType[0] === Constantes.SELLERTYPE_FILTER.MARKETPLACE
                    : lastFilter.sellerType[0] === Constantes.SELLERTYPE_FILTER.DIRECT;
            }
            if (filteredByStores) {
                correctStore = findIndex(lastFilter.stores, ["storeId", String(o.storeId)]) !== -1;
            }
            return Utils.productHasStock(o, "statusProduct") &&
                includedStores.indexOf(o.storeId) !== -1 &&
                correctSellerType && correctStore
        }
    ), o => Utils.getPrice(o, o.paymentType, includeCardPrices, pricesToShow))
}

export const buildCounterObject = (product = {}, stores = [], pricesToShow) => {
    if (!Utils.isObjectWithData(product)) {
        return {};
    }
    let isMyProduct = product.storeId === RetailCompassStorage.getStoreId();
    let isSeller = product.isSeller;
    let sellerName = product.sellerName;
    let storeName = Utils.getProductStoreName(stores, product);
    const includeCardPrices = true;

    return {
        date: moment().utcOffset(0).startOf("day").format("DD/MM/YYYY"),
        isMyProduct,
        isSeller,
        sellerName: sellerName,
        price: Utils.getPrice(product, product.paymentType, includeCardPrices, pricesToShow),
        storeName,
        isCardPrice: Utils.checkCardPrice(product.paymentType, product, pricesToShow)
    }
}

export const extractPropsFromBestPrice = (bestPrice, storeProductsMap, pricesToShow, countryCode) => {
    let price = 0;
    let storeName = '';
    let isMyProduct = false;
    let date = '';
    let isSeller = false;
    let sellerName = '';
    let isCardPrice = false;
    let model = '';
    if (bestPrice && bestPrice.productId) {
        let storeProductMap = storeProductsMap[bestPrice.productId];
        if (storeProductMap) {
            storeName = storeProductMap.storeName;
            isMyProduct = storeProductMap.isMyProduct;
            isSeller = storeProductMap.isSeller;
            sellerName = storeProductMap.sellerName;
            model = storeProductMap.model;
        }
        if (bestPrice.date) {
            date = moment.utc(bestPrice.date).format('DD/MM/YYYY');
        }
        if (bestPrice.prices) {
            const includeCardPrices = true;
            price = Utils.getPrice(bestPrice.prices, bestPrice.tipoPago, includeCardPrices);
            price = Utils.validateCountryWithoutCentsAndRoundPrice(price, countryCode);
            isCardPrice = Utils.checkCardPrice(bestPrice.tipoPago, bestPrice.prices, pricesToShow);
        }
    }
    return { price, storeName, date, isMyProduct, isSeller, sellerName, isCardPrice, model };
}

export const getLowerPrice = (isFetchingLowerPrice, isFetchingLowerPriceCompetitor, lowerPriceFromMaxHistoricPeriodStores, lowerPriceFromMaxHistoricPeriodYourProduct, storeProductsMap, pricesToShow, countryCode) => {
    // esperamos a que llegue la petición de mi producto para poder comparar cuál es el menor
    if (isFetchingLowerPrice || isFetchingLowerPriceCompetitor) {
        return extractPropsFromBestPrice({}, storeProductsMap, pricesToShow, countryCode);
    }

    if (Utils.isArrayWithData(pricesToShow)) {
        if (lowerPriceFromMaxHistoricPeriodStores) {
            const isPriceWithCard = Utils.isPaymentTypeCard(lowerPriceFromMaxHistoricPeriodStores.tipoPago);
            lowerPriceFromMaxHistoricPeriodStores.prices = getPriceByView(lowerPriceFromMaxHistoricPeriodStores.prices, pricesToShow, isPriceWithCard);
        }
        if (lowerPriceFromMaxHistoricPeriodYourProduct) {
            const isPriceWithCard = Utils.isPaymentTypeCard(lowerPriceFromMaxHistoricPeriodYourProduct.tipoPago);
            lowerPriceFromMaxHistoricPeriodYourProduct.prices = getPriceByView(lowerPriceFromMaxHistoricPeriodYourProduct.prices, pricesToShow, isPriceWithCard);
        }
    }

    // si el menor del año es mi producto, lo devolvemos como resultado
    let lowerPriceAllStores = extractPropsFromBestPrice(lowerPriceFromMaxHistoricPeriodStores, storeProductsMap, pricesToShow, countryCode);
    if (lowerPriceAllStores.isMyProduct) {
        return lowerPriceAllStores;
    }

    let lowerPricePriceYourProduct = extractPropsFromBestPrice(lowerPriceFromMaxHistoricPeriodYourProduct, storeProductsMap, pricesToShow, countryCode);

    // si el menor de mi producto es igual al menor del año, mi producto debe tener prioridad, por eso se devuelve.
    if (lowerPriceAllStores.price === lowerPricePriceYourProduct.price) {
        return lowerPricePriceYourProduct;
    } else if (lowerPriceAllStores.price === 0 && lowerPricePriceYourProduct.price > 0) {
        return lowerPricePriceYourProduct;
    }

    // finalmente, el producto con menor precio es un competidor
    return lowerPriceAllStores;
}

export const extractStoreProductMap = ({ canonicalProduct, stores }) => {

    let storeProductMap = {};
    if (!canonicalProduct) {
        return storeProductMap;
    }
    const { includeStores } = RetailCompassStorage.getUser();
    const filterStore = (product) => {
        return product && includes(includeStores, product.storeId);
    };
    const allCompetitorsProducts = filter(concat(canonicalProduct.allCompetitorsProducts, canonicalProduct.oldDirectMatches), filterStore) || [];
    const buildStoreProductMap = (storeName, storeId, isMyProduct,isSeller, sellerName, paymentType) => ({
        storeName: storeName, storeId: storeId, isMyProduct: isMyProduct, isSeller: !!isSeller, sellerName: sellerName, paymentType: paymentType, model: canonicalProduct.model
    });

    let yourStoreId = null;
    const yourProduct = canonicalProduct.yourProduct;
    if (yourProduct && yourProduct.id) {
        yourStoreId = yourProduct.storeId;
        storeProductMap[yourProduct.productId] = buildStoreProductMap(
            Utils.getProductStoreName(stores, yourProduct), yourProduct.storeId, true, yourProduct.isSeller, yourProduct.sellerName, yourProduct.paymentType
        );
    }
    for (let i = 0; i < allCompetitorsProducts.length; i++) {
        let competitor = allCompetitorsProducts[i];
        let storeName = Utils.getProductStoreName(stores, competitor);
        const isYourStore = competitor.storeId === yourStoreId;
        storeProductMap[competitor.productId] = buildStoreProductMap(storeName, competitor.storeId, isYourStore, competitor.isSeller, competitor.sellerName, competitor.paymentType);
    }

    return storeProductMap;
}

export const buildChartSeries = (startDate, endDate, chartProducts, historicData, matches, productMap, viewPrices, countryCode) => {
    let series = [], usedColors = [];
    if (!validateChartParams(chartProducts, historicData)) {
        return [];
    }
    const history = addTodaysRecords(endDate, [...historicData.history], matches);
    if (!Utils.isArrayWithData(history)) {
        return [];
    }
    do {
        const currentHistory = find(history, ["date", startDate.utcOffset(0).startOf("day").format()]) || {};
        const historyProducts = currentHistory.products || [];
        const date = parseInt(moment.utc(startDate.format()).format("x"));
        chartProducts.forEach((o) => {
            const storeProductMap = productMap[o.productId];
            const currentProduct = find(historyProducts, ["productId", o.productId]);
            if (storeProductMap) {
                let serieItemIndex = findIndex(series, { name: storeProductMap.storeName });
                let isSeller = o.isSeller;
                let serieItem = null;
                let price = null;
                let isCardPrice = false;

                if (currentProduct && currentProduct.prices && Utils.productHasStock(currentProduct, "status")) {
                    const includeCardPrices = true;
                    price = Utils.getPrice(currentProduct.prices, currentProduct.tipoPago, includeCardPrices, viewPrices);
                    price = Utils.validateCountryWithoutCentsAndRoundPrice(price, countryCode);
                    isCardPrice = Utils.checkCardPrice(currentProduct.tipoPago, currentProduct.prices, viewPrices)
                }

                if (serieItemIndex === -1) {
                    serieItemIndex = series.length;
                    let color = storeProductMap.isMyProduct ? STORE_COLORS[RetailCompassStorage.getStoreType()] : getSeriesColor(usedColors, storeProductMap);
                    let lineWidth = storeProductMap.isMyProduct ? CHART_LINE_WIDTH.MY_STORE : CHART_LINE_WIDTH.COMPETITORS;
                    serieItem = {
                        name: storeProductMap.storeName,
                        data: [],
                        lineWidth: lineWidth,
                        color: color,
                        dashStyle: isSeller ? "shortdot" : "solid",
                        marker: isSeller ? {
                            symbol: "mkpMarker",
                            states: {
                                hover: {
                                    radius: 3,
                                    radiusPlus: 3
                                }
                            }
                        } : {
                            radius: 4
                        }
                    };
                } else {
                    serieItem = series[serieItemIndex];
                }
                serieItem.data.push({ x: date, y: price, marker: { enabled: false }, isCardPrice: Utils.validateIsCardPriceWithView(isCardPrice, viewPrices) });
                series[serieItemIndex] = serieItem;
            }
        });
        startDate.add("1", "days")
    } while (startDate.isSameOrBefore(endDate))

    // se remueven las series que no tengan data en el periodo seleccionado
    remove(series, function (item) {
        return find(item.data, function (o) { return !isNil(o.y); }) === undefined;
    });

    series.forEach(function (item, inx) {
        let del = []  
        series[inx].data = sortBy(item.data, ["x"]);
        series[inx].data[0].marker.enabled = true;
        for (let i = 1; i < series[inx].data.length; i++) {
            //Se filtran productos, para mantener linea continua
            if(series[inx].data[i].x == series[inx].data[i-1].x){
                    if(isNil(series[inx].data[i-1].y)){
                        del.push(i-1)
                        continue;
                    }
                    if(isNil(series[inx].data[i].y)){
                        del.push(i)
                    }
            }                  
        }
        
        pullAt(series[inx].data, del)

        //Añadir marcador solo en espacios vacios
        for (let i = 1; i < series[inx].data.length; i++) {
            if (!series[inx].data[i].y) {
                series[inx].data[i-1].marker.enabled = true;
                continue;
            }
            if (!series[inx].data[i-1].y) {
                series[inx].data[i].marker.enabled = true;
            }
        }
        if (series[inx].data.length > 0) {
            series[inx].data[series[inx].data.length-1].marker.enabled = true;
        }
    });

    //Se extraen mis productos para ubicarlos al principio de la coleccion
    let mySerie = remove(series, function (item) {
        return item.color === STORE_COLORS[RetailCompassStorage.getStoreType()];
    });

    // Se ordena el resto de productos por nombre
    series = orderBy(series, ["name"], ["desc"]);

    //Se extraen mis productos para ubicarlos al principio de la coleccion
    if (mySerie && mySerie.length > 0) {
        mySerie.forEach((o) => { series.push(o) });
    }

    return series;
}

const validateChartParams = (chartProducts, historicData) => {
    return Utils.isArrayWithData(chartProducts)
        && Utils.isObjectWithData(historicData)
}

const addTodaysRecords = (endDate, history = [], matches = []) => {
    let date = moment().utcOffset(0).startOf("day");
    if (Utils.isArrayWithData(matches) && date.isSame(endDate, "day")) {
        let object = { date: date.format(), products: [] };
        matches.forEach(o => {
            object.products.push({
                productId: o.productId,
                status: o.statusProduct,
                storeId: o.storeId,
                tipoPago: o.paymentType,
                prices: {
                    price: o.price,
                    offerPrice: o.offerPrice,
                    normalPrice: o.normalPrice
                }
            })
        })
        return [object, ...history];
    }
    return [...history];
}

const getSeriesColor = (usedColors, storeProductMap) => {
    let foundColor = find(usedColors, ["storeId", storeProductMap.storeId]);
    if (foundColor) {
        return foundColor.color;
    }
    let inx = usedColors.length <= COMPETITORS_COLORS.length ? usedColors.length : usedColors.length - COMPETITORS_COLORS.length;
    let usedColor = { storeId: storeProductMap.storeId, color: COMPETITORS_COLORS[inx] };
    usedColors.push(usedColor);
    return usedColor.color;
}

export const setMkpMarker = () => {
    Highcharts.SVGRenderer.prototype.symbols.mkpMarker = function (x, y, w, h) {
        return [
            "M", x - 2.5, y,
            "h 11.5",
            "a 2 2 1 0 1 2 2",
            "v 2",
            "a 2 2 1 0 1 -2 2",
            "h -11.5",
            "a 2 2 1 0 1 -2 -2",
            "v -2",
            "a 2 2 1 0 1 2 -2",
            "z"];
    };
}

export const tooltipFormatter = function () {
    var str = ["<table class='historic-tooltip'><tr><td colspan='2'>"]
    str.push(moment.utc(this.x).format("DD/MM/YYYY"))
    str.push("</td></tr>");
    let points = this.points.reverse();
    let mine = remove(points, function (item) {
        return item.series.color === STORE_COLORS[RetailCompassStorage.getStoreType()]
    });
    if (mine && mine.length > 0) {
        orderBy(mine, o => (o.series.name), ["desc"]); //Tu tienda antes que Tu Mkp
        mine.forEach(o => { points.unshift(o); })
    }
    points.forEach(function (item) {
        str.push('<tr class=');
        str.push(item.series.color === STORE_COLORS[RetailCompassStorage.getStoreType()] ? '"mine">' : '"competitor">');
        str.push('<td style="padding: 0 6px 0 0; color:' + item.series.color + '">');
        str.push(item.series.name);
        str.push('</td>');
        str.push('<td style="padding: 0px;">');
        str.push(item.point.isCardPrice ? renderToString(<CreditCardTooltipIcon className="card-icon-tooltip" />) : '');
        str.push(Utils.formatPrice(item.y));
        str.push('</td>');
        str.push('</tr>');
    });
    return str.join("");
}

export const buildComparerChartSeries = (startDate, endDate, includeCardPrices, chartProducts, comparerData, countryCode) => {
    let series = [];

    if (!validateChartParams(chartProducts, comparerData)) {
        return [];
    }

    let isAtLeastOneSerieWithStock = false;
    do {
        comparerData.forEach((historyProducts) => {
            let currentProductHistory = find(historyProducts, ["date", moment.utc(startDate).format(Constantes.DATE_FORMATS.DEFAULT_FORMAT)]);
            if (currentProductHistory) {
                let currentCanonicalProduct = find(chartProducts, ["productId", currentProductHistory.canonicalProductId]);
                if (currentCanonicalProduct) {
                    let model = add3Dots(currentCanonicalProduct.model || currentCanonicalProduct.alternativeModel, 30);
                    let serieItemIndex = findIndex(series, { canonicalProductId: currentCanonicalProduct.productId });
                    let isSeller = (1 * 0); // TODO validar si para marcas en la grafica se tendrá en cuenta ya que manejamos productos canonicos
                    let serieItem = null;
                    let price = null;
                    let isCardPrice = false;

                    if (Utils.productHasStock(currentProductHistory, "status")
                        && currentProductHistory.applyToPriceView) {
                        price = currentProductHistory.price;
                        price = Utils.validateCountryWithoutCentsAndRoundPrice(price, countryCode);
                        isCardPrice = currentProductHistory.showCardIcon;
                        isAtLeastOneSerieWithStock = true;
                    }

                    if (serieItemIndex === -1) {
                        serieItemIndex = series.length;
                        let color = currentCanonicalProduct.color;
                        let lineWidth = currentCanonicalProduct.isMyProduct ? CHART_LINE_WIDTH.MY_STORE : CHART_LINE_WIDTH.COMPETITORS;
                        serieItem = {
                            canonicalProductId: currentCanonicalProduct.productId,
                            name: model,
                            data: [],
                            lineWidth: lineWidth,
                            color: color,
                            dashStyle: isSeller ? "shortdot" : "solid",
                            marker: isSeller ? {
                                symbol: "mkpMarker",
                                states: {
                                    hover: {
                                        radius: 3,
                                        radiusPlus: 3
                                    }
                                }
                            } : {
                                radius: 4
                            }
                        };
                    } else {
                        serieItem = series[serieItemIndex];
                    }

                    const date = parseInt(moment.utc(startDate).format("x"));
                    serieItem.data.push({
                        x: date, y: price,
                        marker: { enabled: false },
                        isCardPrice: isCardPrice,
                        retailers: currentProductHistory.retailers,
                        model,
                        includeCardPrices
                    });
                    series[serieItemIndex] = serieItem;
                }
            }
        });
        startDate.add("1", "days")
    } while (startDate.isSameOrBefore(endDate))

    if (!isAtLeastOneSerieWithStock) {
        return [];
    }

    series.forEach(function (item, inx) {
        series[inx].data = sortBy(item.data, ["x"]);
        series[inx].data[0].marker.enabled = true;
        for (let i = 1; i < series[inx].data.length; i++) {
            if (!series[inx].data[i - 1].y) {
                series[inx].data[i].marker.enabled = true;
            }
        }

    });

    return series.reverse();
}

const add3Dots = (string, limit) => {
    let dots = "...";
    if (string && string.length > limit) {
        string = string.substring(0, limit) + dots;
    }
    return string;
}

const hasAtLeastOneWithCreditCard = (pointItem) => {
    let atLeastOneWithCreditCard = Utils.isArrayWithData(pointItem.point.retailers) &&
        pointItem.point.retailers.filter(retailItem => retailItem.showCardIcon).length > 0;
    return pointItem.point.includeCardPrices && some([pointItem.point.isCardPrice, atLeastOneWithCreditCard], Boolean);
}


const buildRetailName = (item) => {
    let retailName = ''
    if (Utils.isArrayWithData(item.point.retailers)) {
        retailName = item.point.retailers.map(retailItem => {
            let isCardPriceItem = retailItem.showCardIcon;
            let isSeller = retailItem.isSeller;
            let sellerName = retailItem.sellerName;
            let ccIcon = isCardPriceItem ? renderToString(<CreditCardTooltipIcon className="card-icon-tooltip" />) : '';
            let sellerButton = (isSeller)  ? ': '+renderToString(
                    <SellerNameButton placement='right' iconName='seller-icon' sellerName={sellerName} showHistoric={false}/>):'';
            return (ccIcon + ' ' + retailItem.name + ' ' + sellerButton);
        }).join(', ');
    }
     return retailName;
}


export const tooltipComparerFormatter = function () {
    var str = ["<table class='historic-tooltip' style='width: auto; table-layout: fixed;'><tr><td colspan='3'>"]
    str.push(moment.utc(this.x).format("DD/MM/YYYY"))
    str.push("</td></tr>");
    let points = this.points.reverse();
    let mine = remove(points, function (item) {
        return item.series.color === STORE_COLORS[RetailCompassStorage.getStoreType()]
    });
    if (mine && mine.length > 0) {
        orderBy(mine, o => (o.series.name), ["desc"]); //Tu tienda antes que Tu Mkp
        mine.forEach(o => { points.unshift(o); })
    }
    points.forEach(function (item) {
        str.push('<tr class=');
        str.push(item.series.color === STORE_COLORS[RetailCompassStorage.getStoreType()] ? '"mine">' : '"competitor">');
        str.push('<td style="white-space: nowrap; padding: 0 24px 0 0; vertical-align: initial; color:' + item.series.color + '">');
        str.push("<span>" + item.series.name + "</span>");
        str.push('</td>');

        // si al menos un retailer tiene precio con tarjeta, debe aparecer el ícono de tarjeta en el precio
        str.push('<td style="white-space: nowrap; padding: 0px 24px 0px 0px; vertical-align: initial;">');
        let atLeastOneWithCreditCard = hasAtLeastOneWithCreditCard(item);
        str.push(atLeastOneWithCreditCard ? renderToString(<CreditCardTooltipIcon className="card-icon-tooltip" />) : '');
        str.push(Utils.formatPrice(item.y));
        str.push('</td>');

        str.push('<td style="white-space: normal; padding: 0px; vertical-align: initial; overflow-wrap: break-word;">');
       
        str.push('<span class="retail-info-container" style="overflow-wrap: break-word;">' + buildRetailName(item) + '</span>');
        str.push('</td>');

        str.push('</tr>');
    });
    return str.join("");
}
