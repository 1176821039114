import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Constantes } from "@common/utils/";
import { withRouter } from "react-router";

const EmptyStateSVG = process.env.PUBLIC_URL + '/img/ET_3.svg';
const EmptyStateWithActiveFiltersSVG = process.env.PUBLIC_URL + '/img/ET_2.svg';
const EmptyStateWithActiveFavoriteSVG = process.env.PUBLIC_URL + '/img/ET_4.svg';

const CustomImg = (props) => (<img className={props.className} height={props.size} width={props.size} src={props.src} alt='' style={{ width: props.size, height: props.size }} />)

export class EmptyStateView extends PureComponent {
    static propTypes = {
        restoreInitialState: PropTypes.func,
        restoreLastState: PropTypes.func,
        deactivateAllFilters: PropTypes.func,
        paginationCount: PropTypes.number.isRequired,
        lastFilter: PropTypes.object,
        totalFavorites: PropTypes.number,
        currentFilter: PropTypes.object,
        isRestoringLastState: PropTypes.bool,
        isRestoringInitialState: PropTypes.bool,
        lastFilterWithProducts: PropTypes.object,
        sort: PropTypes.object,
    };

    constructor() {
        super();
        this.state = {};

        this.buildEmptyStateProps = this.buildEmptyStateProps.bind(this);
        this.handleBackAction = this.handleBackAction.bind(this);
    }

    buildEmptyStateProps() {
        const { paginationCount, emptyStateType } = this.props;

        if (paginationCount !== 0 || !emptyStateType) {
            return null;
        }

        const getIcon = (src, size = 158) => (<CustomImg src={src} className="empty-state-icon" size={size} />);

        let title = 'Sin productos por aquí';
        let subtitle = 'No hay ningún producto en el mercado con estas características';
        let showInitialStateButton = false;
        let showLastStateButton = false;
        let showDeactivateAllFiltersButton = false;
        let icon = getIcon(EmptyStateSVG);
        if (emptyStateType === Constantes.EMPTY_STATE_TYPE.WITH_ACTIVE_FILTERS) {
            subtitle = <span><span>Te recomendamos volver al último listado con productos</span><br /><span>o desactivar todos los filtros y hacer una nueva búsqueda</span></span>;
            icon = getIcon(EmptyStateWithActiveFiltersSVG);
        } else if (emptyStateType === Constantes.EMPTY_STATE_TYPE.WITH_ACTIVE_FAVORITES || (emptyStateType === Constantes.EMPTY_STATE_TYPE.WITH_ACTIVE_FILTERS_FAVORITES_CANDIDATE && this.props.totalFavorites < 1)) {
            title = "Aquí irían tus favoritos";
            subtitle = <span><span>Marca tus productos para verlos de manera rápida y fácil. </span><br /><span>Puedes marcarlos haciendo click en la estrella al lado de </span><br /><span> cualquier producto en el listado.</span></span>;
            icon = getIcon(EmptyStateWithActiveFavoriteSVG, 218);
        } else if (emptyStateType === Constantes.EMPTY_STATE_TYPE.WITH_ACTIVE_FILTERS_FAVORITES_CANDIDATE && this.props.totalFavorites > 0) {
            title = 'Sin favoritos por aquí';
            subtitle = <span><span>Te recomendamos volver al último listado con productos</span><br /><span>o desactivar todos los filtros y hacer una nueva búsqueda</span></span>;
            icon = getIcon(EmptyStateWithActiveFiltersSVG);
        } else if (emptyStateType === Constantes.EMPTY_STATE_TYPE.SERVER_ERROR) {
            title = 'Algo ha ido mal';
            subtitle = 'Inténtalo de nuevo, a ver si hay más suerte';
            icon = getIcon(EmptyStateSVG);
        }


        return {
            title,
            subtitle,
            icon,
            showInitialStateButton,
            showLastStateButton,
            showDeactivateAllFiltersButton
        }
    }

    handleBackAction() {
        this.props.history.goBack();
    }

    render() {
        let emptyState = this.buildEmptyStateProps();

        if (!emptyState) {
            return null;
        }

        return (
            <Col md={12} lg={12} className="empty-state-view-container">
                <Card>
                    <CardBody className="card-body-brand">
                        <div className="icon-brand">
                            {emptyState.icon}
                        </div>
                        <div className="title">
                            {emptyState.title}
                        </div>
                        <div className="subtitle">
                            {emptyState.subtitle}
                        </div>
                        <Button size={'sm'} className="last-state-button" outline onClick={this.handleBackAction}>Volver atrás</Button>
                        <div className="message">¿Deseas agregar un producto nuevo a la app? escríbenos a <a href="mailto:soporte@retailcompass.com">soporte@retailcompass.com</a></div>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default withRouter(translate('common')(EmptyStateView));
