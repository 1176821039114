import { RetailCompassStorage, TableStateUtils, Constantes as Constants, Utils, ComparerUtils, FormatMatchedProducts } from "@common/utils/"
import * as ComparerActions from './ComparerActions';
import { ProductListService, HistoricService } from "@common/data-access/"
import { LabelsState } from '@common/components';
import { ConstantesApi } from '@common/utils';

export const initialState = {
    isFetching: false,
    brands: [],
    stores: [],
    lastFilter: {},
    lastSearchRequest: {},
    view: { view: Constants.COMPARER_TYPE.AVG_PRICE, value: true },
    startDate: ComparerUtils.DEFAULT_DATE_RANGE.startDate.format(),
    endDate: ComparerUtils.DEFAULT_DATE_RANGE.endDate.format(),
    includeCardPrices: true
}

let abortProducts = new window.AbortController();
let abortHistoric = new window.AbortController();

export const fetchHistoryInterval = (dispatch, payload) => {
    payload = payload || {};

    abortHistoric.abort();
    abortHistoric = new window.AbortController();

    let body = {
        productIds: payload.productIds,
        dates: payload.dates,
        fields: [Constants.HISTORY_SERVICE_FIELDS.HISTORY_INTERVAL],
        countryCode: RetailCompassStorage.getCountryCode()
    }

    const receiveCallback = (response) => {
        if (Utils.isArrayWithData(response.history)) {
            return ComparerActions.receiveProductHistoryAction(response);
        } else {
            return ComparerActions.receiveProductHistoryEmptyAction();
        }

    };

    const receiveAbortErrorCallback = (payloadError) => {
        return ComparerActions.receiveProductHistoryAbortErrorAction(payloadError);
    };

    const signal = abortHistoric.signal;
    return HistoricService.fetchProductHistory({
        signal,
        dispatch,
        data: body,
        requestCallback: ComparerActions.requestProductHistoryAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ComparerActions.receiveProductHistoryErrorAction,
        receiveAbortErrorCallback: receiveAbortErrorCallback
    }, ConstantesApi.API_PRODUCT_HISTORY);
}

export const fetchProductsInfo = (dispatch, payload) => {
    payload = payload || {};

    const userStoreId = RetailCompassStorage.getStoreId();
    const canonicalCategory = RetailCompassStorage.getCurrentCanonicalCategory();
    const canonicalStoreId = canonicalCategory.storeId;
    const canonicalCategoryPath = canonicalCategory.path;
    const includeStores = RetailCompassStorage.getIncludeStores();
    const brandsIds = RetailCompassStorage.getBrandIds();
    const storeType = RetailCompassStorage.getStoreType()

    let body = {
        viewType: "PRICING",
        consumer: Constants.CONSUMER.SCALIBUR,
        storeIdObserver: userStoreId,
        allowedToFilterDirectMatches: true,
        page: 0,
        size: 0,
        stores: [
            {
                storeId: canonicalStoreId,
                children: false
            }
        ],
        categoryPath: canonicalCategoryPath,
        statuses: [1, -1],
        sort: {
            field: Constants.SortValues.PRODUCT_RELEVANCE,
            brandIds: brandsIds,
            order: "DESC",
            storeType: storeType
        },
        filters: {
            brands: {
                size: 70,
                includeOthers: false,
                includeStats: false,
                groupBy: "PARENT_BRAND",
                sort: "COUNT"
            }
        },
        aggregations: [Constants.AGGREGATIONS_NAMES.COMPETITOR],
        matches: {
            contains: "ALL",
            statusId: null,
            storeIds: RetailCompassStorage.getIncludeStores()
        }
    }

    if (Utils.isArrayWithData(payload.productIds)) {
        body.productIds = payload.productIds;
        body.size = payload.productIds.length;
    }

    const receiveCallback = (response) => {
        LabelsState.fetchListLabels(dispatch, { categoryId: canonicalCategory.id });
        return ComparerActions.receiveProductSearchAction({
            response: response,
            requestBody: {
                ...body,
                includeStores: includeStores,
                isFirtsRequest: payload.isFirtsRequest
            }
        })
    };

    const receiveAbortErrorCallback = (payload) => {
        return ComparerActions.receiveProductSearchAbortErrorAction(payload);
    };

    const signal = abortProducts.signal;
    return ProductListService.fetchReportInfo({
        signal,
        dispatch,
        data: body,
        requestCallback: ComparerActions.requestProductSearchAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: ComparerActions.receiveProductSearchErrorAction,
        receiveAbortErrorCallback: receiveAbortErrorCallback
    });
}

export const formatMatchedProducts = (userBrandItems, matchedProducts, requestBody) => {
    let params = { storeId: RetailCompassStorage.getStoreId(), userBrandItems, matchedProducts, requestBody };
    return FormatMatchedProducts.formatMatchedProducts(RetailCompassStorage.getStoreType(), params);
}

export const ComparerStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case ComparerActions.RESET_STORE:
            return Object.assign({}, initialState);
        case ComparerActions.REQUEST_PRODUCT_SEARCH:
            return Object.assign({}, state, { isFetching: true });
        case ComparerActions.RECEIVE_PRODUCT_SEARCH:
            const response = action.payload.response;
            const requestBody = action.payload.requestBody;
            let stores = TableStateUtils.formatStoresFromAPIInclude(response.aggregations, requestBody.includeStores);
            const userBrandItems = RetailCompassStorage.getBrands().allBrands;
            const isFirtsRequest = requestBody.isFirtsRequest;
            if (!isFirtsRequest) {
                let productItems = [];
                try {
                    productItems = formatMatchedProducts(userBrandItems, response.matchedProducts, requestBody);
                } catch (e) {
                    console.error(e);
                }
                return Object.assign({}, state, {
                    products: ComparerUtils.convertProductsForComparator(productItems, userBrandItems),
                    isFetching: false,
                });
            }
            return Object.assign({}, state, { stores: stores, isFetching: false, });
        case ComparerActions.RECEIVE_PRODUCT_SEARCH_ERROR:
            return Object.assign({}, state, { isFetching: false });
        case ComparerActions.RECEIVE_PRODUCT_SEARCH_ABORT_ERROR:
            return Object.assign({}, state, { isFetching: false });
        case ComparerActions.REQUEST_HISTORY:
            return Object.assign({}, state, {
                historicData: null,
                isFetchingHistoricData: true,
                emptyHistory: false
            });
        case ComparerActions.RECEIVE_HISTORY:
            return Object.assign({}, state, {
                isFetchingHistoricData: false,
                historicData: action.payload.history,
                emptyHistory: false
            });
        case ComparerActions.RECEIVE_HISTORY_EMPTY:
            return Object.assign({}, state, {
                emptyHistory: true,
                isFetchingHistoricData: false,
            });
        case ComparerActions.RECEIVE_HISTORY_ERROR:
            return Object.assign({}, state, {
                isFetchingHistoricData: false,
                emptyHistory: false
            });
        case ComparerActions.RECEIVE_HISTORY_ABORT_ERROR:
            return Object.assign({}, state, {
                isFetchingHistoricData: false,
                error: action.payload.error,
                emptyHistory: false
            });
        case ComparerActions.CHANGE_SELECTED_VIEW:
            return Object.assign({}, state, {
                view: action.payload
            });

        case ComparerActions.CHANGE_SELECTED_DATE_RANGE:
            return Object.assign({}, state, {
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            });

        case ComparerActions.CHANGE_SELECTED_INCLUDE_CARD_PRICES:
            return Object.assign({}, state, {
                includeCardPrices: action.payload.includeCardPrices
            });

        default:
            return state;
    }
}
