import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {  maxBy, find, orderBy, cloneDeep, isEqual} from 'lodash';
import { AttributesUtils, Utils} from "@common/utils/"
import { RangeComponent, ButtonGrid, ButtonGridMultiOption } from "./formComponents";


export class AttributesForm extends Component {

    static propTypes = {
        attributesData: PropTypes.array,
        suggested: PropTypes.object,
        stored: PropTypes.object,
        urlParams: PropTypes.object.isRequired,
        onValuesChange: PropTypes.func
    };
    
    static defaultProps = {
        onValuesChange:()=>{}
    };

    constructor(props) {
        super(props);   
        this.state = {
            values: {},
            initialValues: {},
            suggestedValues: {}
        };
        this.maxLength = this.maxLength.bind(this);
        this.maxValue = this.maxValue.bind(this);
        this.resetValues = this.resetValues.bind(this);
    }

    maxLength(values) {
        let max = this.maxValue(values).toString();
        return max && max.length ? max.length : 3;
    }
    
    maxValue(values) {
        let max = maxBy(values, (item) => Number(item.value));
        return max && max.value ? Number(max.value) : 999;
    }
    
    getUnsetActivated = (visualization, selectedItem) => {
        if (visualization.unsetOption && selectedItem) {
            return selectedItem.value === visualization.unsetOption.id;
        }
        return false;
    }


    onValueSelection = (attrId, item) => {
        let values = cloneDeep(this.state.values)
        let element = cloneDeep(item);
        if(!Array.isArray(item)){
            element = [element]
        }
        values[attrId] = element;
        if(!Utils.isObjectWithData(values[attrId][0])){
            delete values[attrId];
        }
        this.setState({ values: values})
        this.props.onValuesChange(values);
    };

    onRangeValueSelection = (attrId, item, values, storeLabelToState = true) => {        
        let rangeValue = find(values, { "value": item })        
        let newSelection = {};
        if (rangeValue && rangeValue.id) {
            newSelection = {value: rangeValue.id, valueId: rangeValue.id, label:rangeValue.value};
        }   
        this.onValueSelection(attrId, newSelection);
    };

    componentDidUpdate(prevProps, prevState){
        let state = {};
        if( !Utils.isObjectWithData(prevState.values) && Utils.isObjectWithData(this.props.stored) && !isEqual(this.props.stored, prevProps.stored)){
            state.values = this.props.stored || {};
            state.initialValues = cloneDeep(state.values);
        }
        if( !Utils.isObjectWithData(prevState.suggestedValues) && Utils.isObjectWithData(this.props.suggested)){
            state.suggestedValues = this.props.suggested || {};           
        }
        if(Utils.isObjectWithData(state)){
            this.setState(state);
        }
    }

    resetValues = () =>{
        let { initialValues } = this.state;
        this.setState({ values: cloneDeep(initialValues)});
        this.props.onValuesChange(initialValues);
    }

    render() {
        const { urlParams} = this.props;
        const attributesData = orderBy(this.props.attributesData, (i) => {
            let visualization = JSON.parse(i.visualization);
            return visualization.validationOrder;
        });
        return ( <div className="attributes-form-container">
                    <span></span>
                    <form name="attributesForm">
                        {attributesData.map((attrItem) => {
                            
                            let visualization = JSON.parse(attrItem.visualization);
                            let values = AttributesUtils.orderAttributeValues(attrItem.values || [], visualization).map((item) => { return { id: item.id, value: item.id, valueId: item.id, label: item.value, index: -1} });             
                            
                            if (!!visualization.allowMultipleValues) {
                                
                                return ButtonGridMultiOption({
                                    selectedItems: this.state.values[attrItem.id] || [],
                                    suggestedItems: this.state.suggestedValues[attrItem.id] || [],
                                    columns: 9,
                                    countryCode: urlParams.countryCode,
                                    onSelection: this.onValueSelection,
                                    onUnsetSelection: this.onValueSelection,
                                    attribute: attrItem,
                                    visualization,
                                    values,
                                    isUnsetActivated: false
                                });
                            }
                            
                            let selectedItem = Utils.isArrayWithData(this.state.values[attrItem.id]) ? this.state.values[attrItem.id][0] : {};
                            let isUnsetActivated = this.getUnsetActivated(visualization, selectedItem);
                            let suggested = this.state.suggestedValues[attrItem.id] ? this.state.suggestedValues[attrItem.id][0] : {}
                            let isUnsetSuggested= this.getUnsetActivated(visualization, suggested);

                            if (attrItem.type === "range") {
                                return RangeComponent({
                                    maxValue: this.maxValue(attrItem.values),
                                    maxLength: this.maxLength(attrItem.values),
                                    defaultValue: selectedItem,
                                    suggestedValue: suggested,
                                    
                                    countryCode: urlParams.countryCode,
                                    onValueSelection: this.onRangeValueSelection,
                                    onUnsetSelection: this.onValueSelection,
                                    attribute: attrItem,
                                    visualization,
                                    isUnsetActivated,
                                    isUnsetSuggested
                                });
                            }
                            
                            return ButtonGrid({
                                defaultValue: selectedItem,
                                suggestedValue: suggested,
                                columns: 9,
                                countryCode: urlParams.countryCode,
                                onSelection: this.onValueSelection,
                                onUnsetSelection: this.onValueSelection,
                                attribute: attrItem,
                                visualization,
                                values,
                                isUnsetActivated,
                                isUnsetSuggested
                            });
                        })}
                    </form>
                </div>
        )
    }
}

export const AttributesFormWithRedux = reduxForm({ form: "attributesForm" })(AttributesForm);
const AttributesFormWithI18n = (translate('common',{ withRef: true})(AttributesFormWithRedux));

export default AttributesFormWithI18n;
