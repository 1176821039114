/* eslint-disable no-undef */
import * as RetailCompassStorage from "../app/Storage";
import * as Constantes from "../utils/constantes";
import { INTERCOM_TOKEN_ENDPOINT } from "../utils/constantesApi";
import { ACCEPTED_USERS } from "../utils/intercomConstants";
import { ENV } from "../utils/utils";

let loaded = false;
let isVisible = false;

export const INTERCOM_APP_ID_PROD = "qv7mcoex";
export const INTERCOM_APP_ID_TEST = "s0cuz3nv";

export const load = () => {
    window.intercomSettings = {
        app_id: ENV.isProduction()
            ? INTERCOM_APP_ID_PROD
            : INTERCOM_APP_ID_TEST,
    };
    isVisible = false;
    if (!window.Intercom) {
        (function (w, d, id, s, x) {
            function i() {
                i.c(arguments);
            }
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            s = d.createElement("script");
            s.async = 1;
            s.src = "https://widget.intercom.io/widget/" + id;
            d.head.appendChild(s);
        })(window, document, window.intercomSettings.app_id);
    }
    loaded = true;
    shutdown();
};

export async function boot() {
    if (!loaded) {
        load();
    }

    if (RetailCompassStorage.isLoggedIn()) {
        removeIntercomSettings();

        if (isAcceptedUser() || ENV.isDevelopment() || ENV.isTest()) {
            const token = await getToken(RetailCompassStorage.getId());
            window.intercomSettings = {
                app_id: ENV.isProduction()
                    ? INTERCOM_APP_ID_PROD
                    : INTERCOM_APP_ID_TEST,
                user_id: RetailCompassStorage.getId(),
                user_hash: token,
                name: RetailCompassStorage.getFullName(),
                email: RetailCompassStorage.getEmail(),
                phone: RetailCompassStorage.getPhone() || null,
                appName: Constantes.APP_NAME,
                userType: RetailCompassStorage.getUserTypeForIntercom(),
                estrategico: RetailCompassStorage.isStrategic()
                    ? "True"
                    : "False",
                userRole: RetailCompassStorage.getJobTitle(),
                current_product_type: RetailCompassStorage.getStoreTypeCode(),
                current_url: window.location.href,
                current_store_country: RetailCompassStorage.getStoreCountry(),
                current_store_name: RetailCompassStorage.getStoreInternalName(),
                current_store_id: RetailCompassStorage.getStoreId(),
                companies: [
                    {
                        company_id: RetailCompassStorage.getStoreId(),
                    },
                ],
            };
            isVisible = true;
            window.Intercom("boot", window.intercomSettings);
        }
    }
}

export const exec = (funcName) => {
    if (loaded) {
        window.Intercom(funcName);
        return true;
    }
    return false;
};

export const shutdown = () => {
    if (exec("shutdown")) {
        isVisible = false;
    }
};

export const getVisibility = () => {
    return isVisible;
};

export const removeIntercomSettings = () => {
    if (window.intercomSettings) {
        delete window.intercomSettings;
        isVisible = false;
    }
};

export const isAcceptedUser = () => {
    return (
        RetailCompassStorage.getUserType() &&
        !!ACCEPTED_USERS.find(
            (userType) =>
                userType.toLowerCase() ===
                RetailCompassStorage.getUserType().toLowerCase()
        )
    );
};

export const trackEvent = (event, metadata) => {
    if (isVisible && event) {
        if (metadata) {
            Intercom("trackEvent", event, metadata);
        } else {
            Intercom("trackEvent", event);
        }
    }
};

async function getToken(userId) {
    const url = `${INTERCOM_TOKEN_ENDPOINT}&userId=${userId}`;
    return fetch(url, {
        method: "GET",
    })
        .then((response) => response.json())
        .then((data) => data.token);
}
