
export class MatchEntityBuilder {
    setProductId(productId) {
        this.productId = productId;
        return this;
    }
    setCompetitorId(competitorId) {
        this.competitorId = competitorId;
        return this;
    }
    setUsername(username) {
        this.username = username;
        return this;
    }
    setProbability(probability) {
        this.probability = probability;
        return this;
    }
    setStatus(status) {
        this.status = status;
        return this;
    }
    setUser(user) {
        this.user = user;
        return this;
    }
    setJobId(jobId) {
        this.jobId = jobId;
        return this;
    }
    setSourceId(sourceId) {
        this.sourceId = sourceId;
        return this;
    }
    build() {
        return {
            productId: this.productId,
            competitorId: this.competitorId,
            username: this.username,
            probability: this.probability,
            status: this.status,
            user: this.user,
            jobId: this.jobId,
            sourceId: this.sourceId
        }
    }
}