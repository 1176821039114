/**
 * @author @author <a href="mailto:jvillada@retailcompass.com">Jorge Villada</a>
 * @class labelsActions.js
 * @date 27 ago. 2018
 */

import { Utils } from '@common/utils/';

const ACTION_BASE_NAME = 'LABELS';

export const RESET_STORE = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_STORE');

//TEMPORAL PRODUCTS in LABEL
export const ADD_PRODUCTS_TO_LABEL = Utils.composeActionName(ACTION_BASE_NAME, 'ADD_PRODUCTS_TO_LABEL');
export const REMOVE_PRODUCTS_LABEL = Utils.composeActionName(ACTION_BASE_NAME, 'REMOVE_PRODUCTS_LABEL');
export const CHANGE_CATEGORY = Utils.composeActionName(ACTION_BASE_NAME, 'CHANGE_CATEGORY');
export const RESET_PRODUCTS_LABEL = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_PRODUCTS_LABEL');

//GET LABELS CATEGORY
export const REQUEST_LABELS_USER = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABELS_USER');
export const RECEIVE_LABELS_USER = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABELS_USER');
export const RECEIVE_LABELS_USER_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABELS_USER_ERROR');
//GET products label
export const REQUEST_LABEL_PRODUCTS = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABEL_PRODUCTS');
export const RECEIVE_LABEL_PRODUCTS = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_PRODUCTS');
export const RECEIVE_LABEL_PRODUCTS_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_PRODUCTS_ERROR');

//create labels with products
export const REQUEST_LABEL_CREATE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABEL_CREATE');
export const RECEIVE_LABEL_CREATE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_CREATE');
export const RECEIVE_LABEL_CREATE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_CREATE_ERROR');

//add products to label
export const REQUEST_LABEL_PATCH = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABEL_PATCH');
export const RECEIVE_LABEL_PATCH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_PATCH');
export const RECEIVE_LABEL_PATCH_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_PATCH_ERROR');
//add products to label
export const REQUEST_LABEL_PUT = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABEL_PUT');
export const RECEIVE_LABEL_PUT = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_PUT');
export const RECEIVE_LABEL_PUT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_PUT_ERROR');

//control modal open
export const DISABLE_OPEN_EDIT_MODAL = Utils.composeActionName(ACTION_BASE_NAME, 'DISABLE_OPEN_EDIT_MODAL');
export const ENABLE_OPEN_EDIT_MODAL = Utils.composeActionName(ACTION_BASE_NAME, 'ENABLE_OPEN_EDIT_MODAL');



//delete label
export const REQUEST_LABEL_DELETE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABEL_DELETE');
export const RECEIVE_LABEL_DELETE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_DELETE');
export const RECEIVE_LABEL_DELETE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_DELETE_ERROR');

//delete label of product
export const REQUEST_LABEL_OF_PRODUCT_DELETE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABEL_OF_PRODUCT_DELETE');
export const RECEIVE_LABEL_OF_PRODUCT_DELETE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_OF_PRODUCT_DELETE');
export const RECEIVE_LABEL_OF_PRODUCT_DELETE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_OF_PRODUCT_DELETE_ERROR');

export const FORCE_LABELS_LIST_UPDATE = Utils.composeActionName(ACTION_BASE_NAME, 'FORCE_LABELS_LIST_UPDATE');
//disable force open edit modal
export const DISABLE_FORCE_OPEN_MODAL = Utils.composeActionName(ACTION_BASE_NAME, 'DISABLE_FORCE_OPEN_MODAL');

export const DISABLE_SHOULD_RELOAD_TABLE = Utils.composeActionName(ACTION_BASE_NAME, 'DISABLE_SHOULD_RELOAD_TABLE');

export const FILTER_LABEL_SELECTED = Utils.composeActionName(ACTION_BASE_NAME, 'FILTER_LABEL_SELECTED');

export const CHANGE_LABELS_NEED_TO_BE_SYNCHRONIZED = Utils.composeActionName(ACTION_BASE_NAME, 'CHANGE_LABELS_NEED_TO_BE_SYNCHRONIZED');

export const REQUEST_LABEL_REFRESH_UPDATE_DATE = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_LABEL_REFRESH_UPDATE_DATE');
export const RECEIVE_LABEL_REFRESH_UPDATE_DATE = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_REFRESH_UPDATE_DATE');
export const RECEIVE_LABEL_REFRESH_UPDATE_DATE_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_LABEL_REFRESH_UPDATE_DATE_ERROR');
export const HIDE_PUBLIC_LABELS = Utils.composeActionName(ACTION_BASE_NAME, 'HIDE_PUBLIC_LABELS');

export const addProductsToLabel = (products) => ({
    type: ADD_PRODUCTS_TO_LABEL,
    payload: {
        products
    }
});
export const removeProductsToLabel = (products) => ({
    type: REMOVE_PRODUCTS_LABEL,
    payload: {
        products
    }
});
export const changeCategory = () => ({
    type: CHANGE_CATEGORY
});
export const resetProductsLabel = () => ({
    type: RESET_PRODUCTS_LABEL
});


export const requestLabelsUserAction = () => ({
    type: REQUEST_LABELS_USER
});

export const receiveLabelsUserAction = (data) => ({
    type: RECEIVE_LABELS_USER,
    payload: { ...data }
});

export const receiveLabelsUserErrorAction = (error) => ({
    type: RECEIVE_LABELS_USER_ERROR,
    payload: {
        error: error
    }
});

export const requestLabelsProductsAction = () => ({
    type: REQUEST_LABEL_PRODUCTS
});

export const receiveLabelsProductsAction = (data) => ({
    type: RECEIVE_LABEL_PRODUCTS,
    payload: { ...data }
});

export const receiveLabelsProductsErrorAction = (error) => ({
    type: RECEIVE_LABEL_PRODUCTS_ERROR,
    payload: {
        error: error
    }
});

export const requestLabelCreateAction = () => ({
    type: REQUEST_LABEL_CREATE
});

export const receiveLabelCreateAction = (data) => ({
    type: RECEIVE_LABEL_CREATE,
    payload: { ...data }
});

export const receiveLabelCreateErrorAction = (error) => ({
    type: RECEIVE_LABEL_CREATE_ERROR,
    payload: {
        error: error
    }
});

export const requestLabelPatchAction = () => ({
    type: REQUEST_LABEL_PATCH
});

export const receiveLabelPatchAction = (data) => ({
    type: RECEIVE_LABEL_PATCH,
    payload: { ...data }
});

export const receiveLabelPatchErrorAction = (error) => ({
    type: RECEIVE_LABEL_PATCH_ERROR,
    payload: {
        error: error
    }
});

export const requestLabelPutAction = () => ({
    type: REQUEST_LABEL_PUT
});

export const receiveLabelPutAction = (data) => ({
    type: RECEIVE_LABEL_PUT,
    payload: { ...data }
});

export const receiveLabelPutErrorAction = (error) => ({
    type: RECEIVE_LABEL_PUT_ERROR,
    payload: {
        error: error
    }
});
export const requestLabelDeleteAction = () => ({
    type: REQUEST_LABEL_DELETE
});

export const receiveLabelDeleteAction = (data) => ({
    type: RECEIVE_LABEL_DELETE,
    payload: { ...data }
});

export const receiveLabelDeleteErrorAction = (error) => ({
    type: RECEIVE_LABEL_DELETE_ERROR,
    payload: {
        error: error
    }
});

export const requestLabelOfProductDeleteAction = () => ({
    type: REQUEST_LABEL_OF_PRODUCT_DELETE
});

export const receiveLabelOfProductDeleteAction = (data) => ({
    type: RECEIVE_LABEL_OF_PRODUCT_DELETE,
    payload: { ...data }
});

export const receiveLabelOfProductDeleteErrorAction = (error) => ({
    type: RECEIVE_LABEL_OF_PRODUCT_DELETE_ERROR,
    payload: {
        error: error
    }
});

export const mustForceLabelsListUpdateAction = (mustForceLabelsListUpdate) => ({
    type: FORCE_LABELS_LIST_UPDATE,
    payload: {
        mustForceLabelsListUpdate: mustForceLabelsListUpdate
    }
});

export const disableForceOpenModal = () => ({
    type: DISABLE_FORCE_OPEN_MODAL
});

export const disableShouldReloadTable = () => ({
    type: DISABLE_SHOULD_RELOAD_TABLE
});

export const disableOpenEditModal = () => ({
    type: DISABLE_OPEN_EDIT_MODAL
});

export const enableOpenEditModal = () => ({
    type: ENABLE_OPEN_EDIT_MODAL
});

/**
 * cuando el usuario selecciona un label desde el filtro lateral izquierdo, 
 * se despacha esta acción, indicando el label que fue seleccionado.
 * 
 * @param {*} label seleccionado por el usuario como filtro
 */
export const filterLabelSelectedAction = (label) => ({
    type: FILTER_LABEL_SELECTED,
    payload: {
        label
    }
});

/**
 * acción para saber si el listado de labels necesita o no ser enviado a la DB...
 * para actuzar las fechas de actualización.
 * 
 * @param {*} isNeeded 
 */
export const changeLabelsNeedToBeSynchronizedAction = (isNeeded) => ({
    type: CHANGE_LABELS_NEED_TO_BE_SYNCHRONIZED,
    payload: {
        isNeeded: isNeeded
    }
});

export const requestLabelRefreshUpdateDateAction = () => ({
    type: REQUEST_LABEL_REFRESH_UPDATE_DATE
});

export const receiveLabelRefreshUpdateDateAction = (data) => ({
    type: RECEIVE_LABEL_REFRESH_UPDATE_DATE,
    payload: { ...data }
});

export const receiveLabelRefreshUpdateDateErrorAction = (error) => ({
    type: RECEIVE_LABEL_REFRESH_UPDATE_DATE_ERROR,
    payload: {
        error: error
    }
});

export const resetStoreAction = () => ({
    type: RESET_STORE
});

export const REQUEST_UPDATE_ACCESS_TYPE = Utils.composeActionName(
    ACTION_BASE_NAME,
    "REQUEST_UPDATE_ACCESS_TYPE"
);
export const RECEIVE_UPDATE_ACCESS_TYPE = Utils.composeActionName(
    ACTION_BASE_NAME,
    "RECEIVE_UPDATE_ACCESS_TYPE"
);
export const RECEIVE_UPDATE_ACCESS_TYPE_ERROR = Utils.composeActionName(
    ACTION_BASE_NAME,
    "RECEIVE_UPDATE_ACCESS_TYPE_ERROR"
);

export const requestUpdateAccessType = () => ({
    type: REQUEST_UPDATE_ACCESS_TYPE
});

export const receiveUpdateAccessType = data => ({
    type: RECEIVE_UPDATE_ACCESS_TYPE,
    payload: { ...data }
});

export const receiveUpdateAccessTypeError = error => ({
    type: RECEIVE_UPDATE_ACCESS_TYPE_ERROR,
    payload: {
        error: error
    }
});

export const hidePublicLabelsAction = (hidePublicLabels) => ({
    type: HIDE_PUBLIC_LABELS,
    payload: {
        hidePublicLabels: hidePublicLabels
    }
});
