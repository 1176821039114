import "abortcontroller-polyfill";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import App from "./app/App";
import ScrollToTop from "./app/ScrollToTop";
import store from "./store/store";
import { config as i18nextConfig } from "./translations/index";
import { createRoot } from "react-dom/client";
const { registerObserver } = require("react-perf-devtool");

window.observer = registerObserver();
i18next.init(i18nextConfig);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </I18nextProvider>
        </BrowserRouter>
    </Provider>
);
