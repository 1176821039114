import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from "@common/components/src/ui/ToggleButton";
import FilterFieldLayout from '../common/FilterFieldLayout';
import { UncontrolledTooltip } from 'reactstrap';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { Constantes, GaUtils, RetailCompassStorage } from '@common/utils';

export default class RankingSwitch extends Component {

    static propTypes = {
        onToggle: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onChangedToggleButton = this.onChangedToggleButton.bind(this);
    }

    onChangedToggleButton(value) {
        RetailCompassStorage.setActiveRanking(value);
        GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.RANKING, Constantes.GA_ACTION_EVENTS.RANKING_SWITCH_CLICK, null, value ? 1 : 0);
        this.props.onToggle(value);
    }

    render() {
        const value = RetailCompassStorage.getActiveRanking();
        return (
            <div className="ranking-switch-container">
                <FilterFieldLayout fieldLabel="Ver posicionamiento" fieldClass="ranking-switch">
                    <ToggleButton
                        callOnComponentDidMount={false}
                        defaultChecked={false}
                        input={{
                            value: value,
                            checked: value,
                            name: "enable-ranking",
                            onChange: this.onChangedToggleButton
                        }}
                    />
                </FilterFieldLayout>

                <div className="ranking-alert">
                    <div id="ranking-alert"><AlertCircleOutlineIcon size={17} /></div>
                    <UncontrolledTooltip
                        className='ranking-header-tooltip'
                        placement='top'
                        target="ranking-alert">
                        Al activarlo, verás la posición de cada producto en la categoría de su propio retailer.
                    </UncontrolledTooltip>
                </div>
            </div>
        )
    }
}
