import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { AttributesUtils, Utils} from "@common/utils/";
import AttributesForm  from './AttributesForm';
import { Popover, PopoverBody, Alert } from 'reactstrap';
import { cloneDeep } from 'lodash';

export class AttributesFormWrapper extends Component {

    static propTypes = {
        storedAttributes: PropTypes.array,
        attributesData: PropTypes.array, 
        urlParams: PropTypes.object.isRequired,
        isIFrame: PropTypes.bool,
        errorCreating: PropTypes.bool,
        onCancel: PropTypes.func,
        onSave: PropTypes.func

    };

    static defaultProps = {
        isIFrame: true,
        errorCreating: false,
        onCancel: ()=>{},
        onSave: (v)=>{console.log(v)}
    };

    constructor(props) {
        super(props);
        this.state = {
            somethingHasChanged: false,
            openTooltip: false,
            verb: 'Agregar',
            btnVerb: 'Crear',
            allSelected: false,
            values:{}
        };
        this.onValuesChange = this.onValuesChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.attrForm = React.createRef();
    }

    onValuesChange(values){
        this.setState({ 
            somethingHasChanged: true, 
            allSelected: Object.keys(values).length === this.props.attributesData.length,
            values: values
        })
    }

    onSaveClick(){
        if(this.state.allSelected && this.state.somethingHasChanged){
            this.props.onSave(this.state.values)
            this.setState({ initialValues: this.state.values, somethingHasChanged: false })
        }else{
            this.setState({ openTooltip: true })
        }
    }

    onCancelClick(){
        this.attrForm.current.wrappedInstance.ref.wrappedInstance.wrapped.resetValues()
        this.setState({ somethingHasChanged: false, openTooltip: false })
        this.props.onCancel();        
    }

    componentDidUpdate(prevProps, prevState){
        let verb = "Actualizar", formValues = AttributesUtils.buildAttributesFormElements( this.props.storedAttributes || [], this.props.attributesData);
        if( Utils.isObjectWithData(formValues.stored) && this.state.verb!== verb){
            this.setState({ verb: verb, btnVerb: verb})
        }
    }

    render(){
        let { attributesData, storedAttributes, urlParams, isIFrame, errorCreating } = this.props;
        const formValues = AttributesUtils.buildAttributesFormElements( storedAttributes || [], attributesData);
        return (<div className={"attributes-tool-container " + (isIFrame ? "embeeded" : "")}> 
                    
                    <span className="title">
                        {   isIFrame ? 
                            <span className="bolder">{this.state.verb}</span>:null
                        }
                        <span>atributos</span>
                    </span>

                    {
                        isIFrame ? 
                        <p>Ahora la herramienta te hará sugerencias de atributos en color amarillo. 
                            Si el atributo amarillo es el correcto entonces debes seleccionarlo y así poder guardar los atributos.
                        </p>
                        :null
                    }

                    <p>
                        <span className="marker marker--selected"></span>Atributo seleccionado
                        <span className="marker marker--suggested"></span>Atributo sugerido
                    </p>
                    <AttributesForm attributesData={ attributesData }
                        stored={ formValues.stored }
                        suggested={ formValues.suggested }
                        urlParams={ urlParams }
                        onValuesChange={this.onValuesChange}
                        ref = {this.attrForm}/>

                { (isIFrame && errorCreating) ?
                    <Alert color={"warning"}>
                        <p>Ocurrió un error, vuelva a intentarlo. En caso de persistir el problema, comunicarse con el administrador.</p>
                    </Alert> : null
                }

                    {
                        !this.state.somethingHasChanged && !isIFrame ? null :
                            <div className="actions">
                                <a onClick={this.onCancelClick}>Cancelar</a>
                                <button id="attrs-btn" onClick={this.onSaveClick} type="button" className={"btn btn-success" + (!this.state.allSelected || !this.state.somethingHasChanged  ? " disable" : "")}>
                                    {this.state.btnVerb} Canónico
                                </button>
                                <Popover
                                    placement="top"
                                    target="attrs-btn"
                                    isOpen={this.state.openTooltip}
                                    className="popover--danger"
                                >
                                    <PopoverBody>Te falta seleccionar el valor de un atributo</PopoverBody>
                                </Popover>
                            </div>
                    }
                </div>                                    
        )
    }
}
export default AttributesFormWrapper ;