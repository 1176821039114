import { OperatorUtils, RetailCompassStorage, Utils } from '@common/utils/';
import React, { useState, useEffect } from 'react'
import AttributeTags from '../AttributeTags';

const ProductDetailTags = ({ attributes }) => {
    const [enhancedAttributes, setEnhancedAttributes] = useState([]);

    useEffect(() => {
        let categoryAttributes = RetailCompassStorage.getCurrentCanonicalCategory().attributes;
        OperatorUtils.addColorAttribute(categoryAttributes);
        const _enhancedAttributes = Utils.buildAttributesArray(attributes, categoryAttributes);
        setEnhancedAttributes(_enhancedAttributes);
    }, [attributes])

    return <AttributeTags enhancedAttributes={enhancedAttributes} />
}

export default ProductDetailTags