import { Utils } from "@common/utils/"
import { ACTION_BASE_NAME as ALERT_ACTION_BASE_NAME } from '../../../../AlertActions';
const ACTION_BASE_NAME = Utils.composeActionName(ALERT_ACTION_BASE_NAME, 'FORM');

export const RESET_FORM = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_FORM');

//CREATE ACTION
export const REQUEST_CREATE_ALERT = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_CREATE_ALERT');
export const RECEIVE_CREATE_ALERT = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_ALERT');
export const RECEIVE_CREATE_ALERT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_CREATE_ALERT_ERROR');
//CREATE ACTION
export const REQUEST_EDIT_ALERT = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_EDIT_ALERT');
export const RECEIVE_EDIT_ALERT = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_EDIT_ALERT');
export const RECEIVE_EDIT_ALERT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_EDIT_ALERT_ERROR');

export const requestCreateAlertAction = (data) => ({
    type: REQUEST_CREATE_ALERT,
    payload: { ...data }
});
export const resetAlertForm = () => ({
    type: RESET_FORM
});

export const receiveCreateAlertAction = (data) => ({
    type: RECEIVE_CREATE_ALERT,
    payload: { ...data }
});

export const receiveCreateAlertErrorAction = (error) => ({
    type: RECEIVE_CREATE_ALERT_ERROR,
    payload: {
        error: error
    }
});

export const requestEditAlertAction = (data) => ({
    type: REQUEST_EDIT_ALERT,
    payload: { ...data }
});

export const receiveEditAlertAction = (data) => ({
    type: RECEIVE_EDIT_ALERT,
    payload: { ...data }
});

export const receiveEditAlertErrorAction = (error) => ({
    type: RECEIVE_EDIT_ALERT_ERROR,
    payload: {
        error: error
    }
});
