/**
 * @author @author <a href="mailto:jvillada@pricingcompass.com">Jorge Villada</a>
 * @class CategoryState.js
 * @date 21 mar. 2018
 */

import {
    REQUEST_UPDATE_CATEGORY,
    requestUpdateCategory,
    REQUEST_CATEGORY_STORE,
    RECEIVE_CATEGORY_STORE,
    RECEIVE_CATEGORY_STORE_ERROR,
    REQUEST_CLEAR_CATEGORY,
    requestCategoryStoreAction,
    requestClearCategory,
    receiveCategoryStoreAction,
    receiveCategoryStoreErrorAction
} from './CategoryActions';


import {
    CategoryService
} from '@common/data-access';

export const initialState = {
    currentCategory: null,
    categoriesList: [],
    error: null
};

export const updateCategory = (dispatch, id, categoria, subcats, level, parent) => {
    dispatch(requestUpdateCategory(id, categoria, subcats, level, parent));
};
export const clearCategory = (dispatch) => {
    dispatch(requestClearCategory());
};

export const fetchCategoryByStore = (dispatch, storeId, access) => {
    if (!storeId) {
        throw new Error('StoreId required');
    }

    return CategoryService.fetchByStoreByAccess(storeId, access, {
        dispatch,
        requestCallback: requestCategoryStoreAction,
        receiveCallback: receiveCategoryStoreAction,
        receiveErrorCallback: receiveCategoryStoreErrorAction
    });
};

export const CategoryStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case REQUEST_UPDATE_CATEGORY:
            return Object.assign({}, state, { currentCategory: action.payload });
        case REQUEST_CLEAR_CATEGORY:
            return Object.assign({}, state, { currentCategory: null });
        case REQUEST_CATEGORY_STORE:
            return Object.assign({}, state, { isFetching: true, categoriesList: [], error: null });
        case RECEIVE_CATEGORY_STORE:
            let data = action.payload.data;
            return Object.assign({}, state, { isFetching: false, error: null, categoriesList: data });
        case RECEIVE_CATEGORY_STORE_ERROR:
            return Object.assign({}, state, { isFetching: false, categoriesList: [], error: action.payload.error });
        default:
            return state;
    }
};
