import React, { Component } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { Utils, RetailCompassStorage } from '@common/utils/'
import { isEqual } from 'lodash';
import LabelsForm from '../../../labels/label-form/LabelsFormContainer'
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import RankingSwitch from '../../../table/RankingSwitch'

export class ProductListSummary extends Component {

    static propTypes = {
        summaryCard: PropTypes.elementType.isRequired,
        downloadSectionClassName: PropTypes.string,
        hideDownload: PropTypes.bool
    };
    static defaultProps = {
        downloadSectionClassName: ""
    };

    constructor(props) {
        super(props);
        this.state = { summaryBrandKey: this.generateSummaryBrandKey() };
        this.onCloseLabelsForm = this.onCloseLabelsForm.bind(this);
        this.generateSummaryBrandKey = this.generateSummaryBrandKey.bind(this);
    };

    componentDidUpdate(prevProps) {
        let currentRouterViewChanged = !isEqual(this.props.currentRouterView, prevProps.currentRouterView);
        let currentCanonicalCategoryChanged = !isEqual(this.props.currentCanonicalCategory, prevProps.currentCanonicalCategory);
        if (currentCanonicalCategoryChanged || currentRouterViewChanged) {
            this.setState({ summaryBrandKey: this.generateSummaryBrandKey() });
        }
    }

    onCloseLabelsForm() {
        this.props.resetSelection();
        this.props.hideLabelsForm();
    }

    generateSummaryBrandKey() {
        return 'summaryBrand-' + Utils.generateUniqueID();
    }

    render() {
        let SummaryCard = this.props.summaryCard;
        let ListOptions = this.props.listOptions;
        let ViewPricesComponent = this.props.viewPricesComponent;
        let isEnableRanking = RetailCompassStorage.getEnableRanking();

        return (
            <div className="product-list-content col-md-12 col-lg-12 clearfix">
                <div>
                    {
                        this.props.visibleLabelsForm ?
                            <Col md={12} lg={12} xl={12} className="clearfix">
                                <Card className="product-list-summary product-list-summary__labels-form" >
                                    <CardBody>
                                        <div className="close-btn common-close-icon" onClick={this.onCloseLabelsForm}>
                                            <CloseIcon size={18} />
                                        </div>
                                        <LabelsForm labels={this.props.labels} />
                                    </CardBody>
                                </Card>
                            </Col> :
                            <Col md={12} lg={12} xl={12} className="clearfix">
                                <SummaryCard
                                    {...this.props}
                                    key={this.state.summaryBrandKey}
                                    customId={this.state.summaryBrandKey}
                                    customStyle={this.props.customStyle} />

                                {!!this.props.hideDownload ?
                                    null :
                                    <Card className={"product-list-summary download-section " + this.props.downloadSectionClassName}>
                                        <CardBody>
                                            {isEnableRanking &&
                                                <div className='switch pr-5'>
                                                    <RankingSwitch
                                                        onToggle={this.props.updateActiveRanking}>
                                                    </RankingSwitch>
                                                </div>
                                            }
                                            {ViewPricesComponent &&
                                                <div className="price-view"><ViewPricesComponent {...this.props} viewPriceValue={this.props.getInitValueViewPrice(this.props)}/></div>
                                            }
                                            <ListOptions {...this.props} />
                                        </CardBody>
                                    </Card>
                                }

                            </Col>
                    }
                </div>
            </div>);
    }
}

export default withRouter(ProductListSummary);
