import React, { Component } from 'react';
import { Row, Col, Container, CardBody, Card } from 'reactstrap';
import CandidatePanel from './CandidatePanel';
import CurrentStepPanel from './CurrentStepPanel';
import AdvancePanel from './AdvancePanel';
import { PropTypes } from "prop-types";
import { AttributesUtils, Utils } from "@common/utils/";
import MatchesSearch from '../../common/components/MatchesSearch';
import SuggestedMatchesTable from '../../common/components/SuggestedMatchesTable';
import RemovedMatchesTable from '../../common/components/RemovedMatchesTable';
import EmptyTable from '../../common/components/EmptyTable';
import { getFieldFromContext } from '../../common/utils/utils';

export class Step2 extends Component {

    static propTypes = {
        taskTitle: PropTypes.string,
        currentProduct: PropTypes.object.isRequired,
        urlParams: PropTypes.object.isRequired,
        totalSteps: PropTypes.number.isRequired,
        competitors: PropTypes.array,
        searchPotentialMatches: PropTypes.func,
        handleApproveClick: PropTypes.func,
        handleRejectClick: PropTypes.func,
        createNewMatch: PropTypes.func,
        suggestedMatches: PropTypes.array,
        potentialMatchesEmptyState: PropTypes.bool.isRequired,
        removeIdentical: PropTypes.func,
        isRemovingTheMatch: PropTypes.bool,
        isTransformingTheMatch: PropTypes.bool,
    };

    static defaultProps = {
        taskTitle: ""
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let { currentProduct, attributesData, storedAttributes, urlParams } = this.props;
        currentProduct.enhancedAttributes = AttributesUtils.enhanceProductAttributes(storedAttributes, attributesData);
        let { username, userId } = Utils.getDecodedContextInfo(urlParams.contextInfo);
        return (
            <Container className="approval-step approval-step--2">
                <Row>
                    <Col sm={12}>
                        <CurrentStepPanel
                            currentStep={2}
                            totalSteps={this.props.totalSteps}
                            title={<span>Busca si el producto por aprobar tiene matches idénticos</span>}
                            urlParams={this.props.urlParams}
                            showBackLink={true}
                        />
                    </Col>
                </Row>
                <Row className="content-row">
                    <Col md={6} className="left-panel">
                        <Card>
                            <CardBody className="no-padding">
                                <CandidatePanel
                                    currentProduct={this.props.currentProduct}
                                    urlParams={this.props.urlParams}
                                    removeIdentical={this.props.removeIdentical}
                                    allMatches={this.props.allMatches}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    taskTitle={this.props.taskTitle}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="right-panel">
                        <Card className="search-matches">
                            <CardBody>
                                <MatchesSearch
                                    userId={userId}
                                    username={username}
                                    allMatches={this.props.allMatches}
                                    competitors={this.props.competitors}
                                    canonicalProduct={this.props.currentProduct}
                                    searchPotentialMatches={this.props.searchPotentialMatches}
                                    potentialMatches={this.props.potentialMatches}
                                    onApproval={this.props.handleApproveClick}
                                    createNewMatch={this.props.createNewMatch}
                                    autoSearch={true}
                                    noResults={this.props.potentialMatchesEmptyState}
                                    comparerLeftTitle={"Producto por aprobar"}
                                    comparerRightTitle={"Resultado de búsqueda: "}
                                    comparerThumbnailMainTitle={"Por aprobar"}
                                    jobId={getFieldFromContext("jobId", urlParams.contextInfo)}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                />
                            </CardBody>
                        </Card>
                        <Card className="suggested-matches">
                            <CardBody>
                                <SuggestedMatchesTable
                                    currentProduct={currentProduct}
                                    suggestedMatches={this.props.suggestedMatches}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    handleApproveClick={this.props.handleApproveClick}
                                    handleRejectClick={this.props.handleRejectClick}
                                    onEmpty={<EmptyTable title="0 CANDIDATOS A MATCH" />}
                                    comparerLeftTitle={"Producto por aprobar"}
                                    comparerRightTitle={"Candidato a match: "}
                                    comparerThumbnailMainTitle={"Por aprobar"}
                                />
                            </CardBody>
                        </Card>
                        <Card className="edit__product-detail--body matches" style={{ marginBottom: "30px" }}>
                            <CardBody className="product-detail-body-container" >
                                <RemovedMatchesTable
                                    currentProduct={this.props.currentProduct}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    handleApproveClick={this.props.handleApproveClick}
                                    allMatches={this.props.allMatches}
                                    showImageComparer={true}
                                    comparerLeftTitle={"Producto por aprobar"}
                                    comparerRightTitle={"Match eliminado: "}
                                    comparerThumbnailMainTitle={"Por aprobar"}
                                />
                            </CardBody>
                        </Card>
                        <AdvancePanel
                            urlParams={this.props.urlParams}
                            linkCaption={"Siguiente paso"}
                            advanceAction={() => { console.log("Going to Step3") }}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default Step2;