
export const getPaymentTypeFees = (paymentType) => {
    const values = processPaymentType(paymentType);
    if (!values || isNaN(values[0])) {
        return null;
    }
    return parseInt(values[0]);
}

export const getPaymentTypeCard = (paymentType) => {
    const values = processPaymentType(paymentType);
    if (!values) {
        return null;
    }
    if (values.length === 1) {
        return values[0];
    }
    return values[1];
}

export const processPaymentType = (paymentType) => {
    if (!paymentType) {
        return null;
    }
    const values = paymentType.replace(/\'/g, '').split(/(\d+)/).filter(Boolean);
    return values;
}