import * as ValidationActions from "../ValidationActions";
import { removeMatchFromSuggestedMatches, addMatch, recoverFromRemoved } from "../../common/utils/utils";
import { Constantes } from "@common/utils/"


export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestTransformIntoIdentical = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isTransformingTheMatch: true
    });
}

export const receiveTransformIntoIdentical = (state, action) => {
    const requestBody = action.payload.requestBody;
    const match = requestBody.match;
    let stateChangeFields = {}
    if ([Constantes.MATCH_STATUS_IDS.REMOVED, Constantes.MATCH_STATUS_IDS.ALSO_REMOVED].indexOf(match.statusMatch) != -1) {
        stateChangeFields = {
            currentProduct: recoverFromRemoved(state.currentProduct, match)
        };
    } else {
        stateChangeFields = {
            currentProduct: addMatch(state.currentProduct, match),
            suggestedMatches: removeMatchFromSuggestedMatches(state.suggestedMatches, match)
        }
    }
    return updateObject(state, {
        hasError: false,
        error: null,
        isTransformingTheMatch: false,
        ...stateChangeFields
    });
}

export const receiveTransformIntoIdenticalError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isTransformingTheMatch: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_TRANSFORM_INTO_IDENTICAL]: requestTransformIntoIdentical,
    [ValidationActions.RECEIVE_TRANSFORM_INTO_IDENTICAL]: receiveTransformIntoIdentical,
    [ValidationActions.RECEIVE_TRANSFORM_INTO_IDENTICAL_ERROR]: receiveTransformIntoIdenticalError,
}

export default handlers