import React, { memo } from 'react';

const ProductImage = ({ imageUrl }) => {

    return (
        <div>
            {imageUrl !== null ? <img src={imageUrl} /> : null}
        </div>
    );
}

export default memo(ProductImage)