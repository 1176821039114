import { find } from "lodash";
import * as ChurnZero from "../scripts/churn-zero";

export const onChangeSortingSelect = (sort, headerProps, sortOptions) => {
    headerProps.column.sortMethod(sort.sortKey, sort.sortDirection);
    const selectedValue = find(sortOptions, {
        sortKey: sort.sortKey,
        sortDirection: sort.sortDirection
    });
    trackEvent(selectedValue);
};

export const trackEvent = selectedValue => {
    ChurnZero.trackEvent({
        eventName: "scalibur_filtro_ordenamiento",
        customFields: { valor: selectedValue.label }
    });
};
