import React, { Component } from 'react'
import ButtonNewLabel from '../label-new-button/ButtonNewLabelContainer';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditLabelsButton from '../label-edit-button/EditLabelsButtonContainer';
import Label from '../label/Label';
import withLabelsList from './withLabelsList';
import LabelIcon from '@material-ui/icons/Label';
import { filterLabelSelectedAction } from '../LabelsActions';
import { Constantes } from '@common/utils/';

const labelHeight = 24; //Para que el transition tenga efecto sobre el max-height debe ser exacto, no unset ni numeros gigantes
const maxLabelsOnCollapsed = 4;

export class LabelsList extends Component {

    static propTypes = {
        showAll: PropTypes.bool,
        filterLabels: PropTypes.array,
        onLabelSelection: PropTypes.func.isRequired,
        onLabelRemoval: PropTypes.func.isRequired,
        labels: PropTypes.array.isRequired,
        forceOpenEditModal: PropTypes.bool,
        visibleLabelsForm: PropTypes.bool,
        dispatch: PropTypes.func,
        currentFilter: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            showAll: false,
        }

        this.toggleShowAll = this.toggleShowAll.bind(this);
        this.onLabelSelection = this.onLabelSelection.bind(this);
        this.onHidePublicLabels = this.onHidePublicLabels.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.hidePublicLabels !== this.props.hidePublicLabels && nextProps.hidePublicLabels) {
            this.onHidePublicLabels();
        }
        return true;
    }

    onHidePublicLabels() {
        let publicLabels = this.props.labels
            .filter(label => label.accessType === Constantes.ACCESS_TYPES_LABEL.PUBLIC)
            .filter(label => this.props.isActive(label));

        if (publicLabels.length > 0) {
            this.props.onListLabelRemoval(publicLabels);
        }
    }

    toggleShowAll() {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    getVisibilityButtonContent() {
        if (this.state.showAll) {
            return (<span>Ver menos <KeyboardArrowUpIcon /></span>);
        } else {
            return (<span>Ver todos <KeyboardArrowDownIcon /></span>);
        }
    }

    onLabelSelection(labelSelected) {
        this.props.dispatch(filterLabelSelectedAction(labelSelected));

        // le decimos al HoC que el label que fue clickeado por usuario permanezca seleccionado
        this.props.onLabelSelection(labelSelected, true);
    }

    render() {
        const totalLabels = this.props.labels;
        const { currentFilter } = this.props;
        const labels = totalLabels.filter(label => this.props.hidePublicLabels ? label.accessType !== Constantes.ACCESS_TYPES_LABEL.PUBLIC : true);
        const maxHeight = this.state.showAll && labels.length > 0 ?
            labels.length * labelHeight : labelHeight * maxLabelsOnCollapsed;

        return (
            <div className={"labels-filter"}>
                <div className="title-container">
                    <div className="title">
                        Labels
                    </div>
                    {totalLabels.length > 0 &&
                        <div className="edit">
                            <EditLabelsButton labels={labels} />
                        </div>
                    }
                </div>

                <div className={"labels-list"} style={{ maxHeight: maxHeight + "px" }}>
                    {
                        labels.map((item) =>
                            <Label
                                key={`label-list-${item.id}-${item.accessType}`}
                                label={item}
                                selected={this.props.isActive(item)}
                                mode="row"
                                onLabelSelection={this.onLabelSelection}
                                onLabelRemoval={this.props.onLabelRemoval}
                            />
                        )
                    }
                </div>

                <div className="add-label-button">
                    {currentFilter.selectedView !== Constantes.TYPE_VIEW_RETAIL.SHIPPING &&
                    <>{
                        labels.length > 0 ?
                            <ButtonNewLabel
                                text="Asignar label"
                                icon={<LabelIcon />} /> :
                            <ButtonNewLabel />
                    }</>}
                </div>
                {
                    labels.length > maxLabelsOnCollapsed &&
                    <div className={"visibility-button"} onClick={this.toggleShowAll}>
                        {this.getVisibilityButtonContent()}
                    </div>
                }
            </div>
        )
    }
}
export default withLabelsList(LabelsList)
