/* eslint-disable no-undef */
import { find, sortBy, get, map, orderBy, remove, isEqual } from 'lodash';
import * as Constantes from './constantes';
import * as Utils from './utils';
import { isNotPrivateRoute } from './routerUtils';
import * as RetailCompassStorage from '../app/Storage';

import { unionItems } from './TableStateUtils';

export const getAttributesVisualization = (attributes) => {
    let userAttributes = RetailCompassStorage.getCurrentCategoryAttributes();


    return userAttributes.map((attribute) => {
        //id, data, visualization,  aggregation, name
        let newAttribute = { id: attribute.id, visualization: attribute.visualization, aggregation: attribute.aggregation, name: attribute.name, type: attribute.type };
        let foundAttribute = find(attributes, ['id', attribute.id]);
        if (foundAttribute) {
            newAttribute.data = foundAttribute.data;
        }
        let data = visualizationDataFromAttribute(attribute);

        if (data) {
            newAttribute.data = data;
        }

        return newAttribute;
    })
}

export const visualizationDataFromAttribute = (attribute) => {
    let countryCode = RetailCompassStorage.getCountryCode() ? RetailCompassStorage.getCountryCode().toUpperCase() : undefined;
    //find by country
    let data = findValue(attribute.visualization.data, countryCode);

    if (data) {
        data = orderBy(data, (item) => (item.order));
    }
    return data;

}

export const formatAttributeItems = (attribute) => {
    let data = attribute.data || [];
    let format = (item, index) => (
        {
            id: index + "",
            count: item.count,
            value: item.value + "",
            label: item.value + "",
            index: index
        }
    );
    return map(sortBy(data, function (item) {
        return item.value;
    }), format);
}
export const formatAttributeRangeItems = (attribute) => {
    let data = attribute.data || [];

    const formatLabel = (item) => {
        if (!item) return;
        let desde = item.from ? item.from + " - " : "Hasta ";
        let hasta = item.to;
        if (!item.to || item.to === 9999) {
            desde = "";
            hasta = "Desde " + item.from;
        }
        return desde + hasta;
    }

    let format = (item, index) => (
        {
            id: index + "",
            value: { from: item.from, to: item.to },
            label: formatLabel(item),
            index: index
        }
    );
    return map(sortBy(data, function (item) {
        return item.value;
    }), format);
}

export const orderAttributeValues = (filterItems, visualization) => {
    return sortBy(filterItems, [(item) => {
        if (visualization && visualization.listingStrategy && visualization.listingStrategy.type) {
            let type = visualization.listingStrategy.type;

            if (type === 'SIZE') {
                return item.value.length;
            } else if (type === 'NUMBER') {
                return parseInt(item.value.replace(/\D/g, ''));
            } else if (type === 'ORDERED_VALUES' && Utils.isArrayWithData(visualization.listingStrategy.values)) {
                let foundValue = find(visualization.listingStrategy.values, ['value', item.value]);
                if (foundValue) {
                    return foundValue.order;
                }
                return null;
            }
        }
        return item.value;
    }, (item) => {
        return item.value;
    }]);
}


export const formatAttributesFromAPI = (attributesFromAPI, attributesFromState) => {
    if (!Utils.isArrayWithData(attributesFromAPI)) {
        return [];
    }


    return attributesFromAPI.map((attribute) => {
        if (!attribute) {
            return;
        }
        const isTypeRange = attribute.type && attribute.type === Constantes.AttributeType.RANGE;
        //se ordenan las valores que llegan
        let filterItems;
        if (!isTypeRange) {
            filterItems = formatAttributeItems(attribute);
        } else {
            filterItems = formatAttributeRangeItems(attribute);
        }

        let selectedData = filterItems;
        let attributeState = find(attributesFromState, { id: attribute.id });
        //se une con los atributos del state (si se está filtrando, solo llega los datos filtrados y se modifica la visibilidad de todos los atributos del filtro)
        filterItems = attributeState ? unionItems(isTypeRange ? 'label' : 'value', filterItems, attributeState.filterItems) : filterItems;
        filterItems = removeHiddenAttributeValues(orderAttributeValues(filterItems, attribute.visualization), attribute.visualization);

        //obtiene la ultima data seleccionada
        let lastData = get(attributeState, "selectedData", []);
        let isAllSelectedItems = selectedData.length === filterItems.length;
        //si ambas listas son igual, quiere decir que está seleccionado todos los items
        //para el caso inicial donde devuelve todos los items, se coloca como vacío (evitar ver todos los items seleccionados cuando recien ingresa)
        selectedData = lastData;
        //se evita modificar el mismo objeto attribute (puede afectar el comportamiento de screenSize por el campo attributesFromAPI)
        return {
            ...attribute,
            filterItems,
            isAllSelectedItems,
            selectedData,
            data: undefined
        };
    });
}


export const removeHiddenAttributeValues = (filterItems, visualization)=>{
    if(visualization && visualization.hiddenValues){
        remove(filterItems, (o)=>(visualization.hiddenValues.indexOf(o.value) !== -1));
    }    
    return filterItems;
};

export const formatDynamicAttributes = (reduxFormInitialValues, dynamicAttributes) => {
    let initialValues = {};

    if (!Utils.isArrayWithData(dynamicAttributes) ||
        !reduxFormInitialValues ||
        !Utils.isArrayWithData(reduxFormInitialValues.dynamicAttributes)) {
        return initialValues;
    }

    dynamicAttributes.forEach((attribute) => {
        let attributeInitialValue = find(reduxFormInitialValues.dynamicAttributes, { id: attribute.id });
        if (attributeInitialValue && Utils.isArrayWithData(attributeInitialValue.selectedData)) {
            attribute.selectedData = attributeInitialValue.selectedData;
            //se reeemplaza los atributos con la información de filterItems (id, index, y demas datos que no están en la url)
            attribute.selectedData = attribute.selectedData.map((item, index) => {
                let filterAttributes = get(attribute, "filterItems", []);
                let itemFound = find(filterAttributes, { value: item.value });
                if (itemFound && itemFound.id) {
                    itemFound.count = item.count;
                    return itemFound
                }
                return { ...item, index };
            });
            attribute.isAllSelectedItems = attribute.selectedData.length === attribute.filterItems.length;
        }
    });

    initialValues.dynamicAttributes = dynamicAttributes;
    return initialValues;
}

export const unitFromVisualization = (visualization, countryCodeParam) => {
    let countryCode = getCountryCode(countryCodeParam);

    if (!visualization || !visualization.unit) {
        return ""
    }
    let unit = findValue(visualization.unit, countryCode);
    if (unit) {
        return String(unit);
    }
    return "";
}


export const isOptional = (visualization, countryCodeParam) => {
    let countryCode = getCountryCode(countryCodeParam);

    let optional =  findValue(visualization.optional, countryCode);

    if (typeof optional === 'object') {        
        return optional.value;
    }
    return !!optional;
}

export const attributeKeyFromType = (item) => {
    let attribute = RetailCompassStorage.getCurrentCategoryAttribute(item.id);
    let isRange = attribute && attribute.type === Constantes.AttributeType.RANGE;
    return isRange ? "ranges" : "values";
}
export const attributeUrlSeparatorFromAttribute = (item) => {
    let isRange = item && item.type === Constantes.AttributeType.RANGE;
    return isRange ? "|" : ","
}
export const transformAttributeUrlValue = (item, value, encode) => {
    let isRange = item && item.type === Constantes.AttributeType.RANGE;
    if (encode) {
        return (isRange ? JSON.stringify(value) : value)
    }
    return (isRange ? JSON.parse(value) : value)
}
export const attributeComponentKeyNameFromType = (item) => {
    let isRange = item && item.type === Constantes.AttributeType.RANGE;
    return isRange ? "label" : "value"
}

const getCountryCode = countryCode => {
    return countryCode ? countryCode : RetailCompassStorage.getCountryCode();
}

export const attributeTitleFromAttribute = (attribute, countryCodeParam) => {
    let countryCode = getCountryCode(countryCodeParam);

    let title = findValue(attribute.visualization.title, countryCode);
    if (title) {
        return title;
    }
    return "";
}

export const buildAttributeValue = (visualization) => {
    let countryCode = RetailCompassStorage.getCountryCode();
    if (!visualization) {
        return "";
    }
    if (!visualization.unit) {
        return visualization.value;
    }
    let unit = findValue(visualization.unit, countryCode) || "";
    let separator = visualization.unit.separator || "";
    if (unit) {
        return [visualization.value, separator, String(unit)].join("");
    }
    return visualization.value;
}

export const findValue = (objectToSearch, countryCode) => {
    if (!objectToSearch || !objectToSearch.value) {
        return undefined;
    }
    let value = objectToSearch.value;
    let custom = find(objectToSearch.custom, ['countryCode', countryCode]);

    if (custom && custom.value) {
        return custom.value;
    }
    return value;
}

export const syncSelectedDataFromAttributes = (dynamicAttributes, attrsWithNewSelectedData) => {
    if (Utils.isArrayWithData(dynamicAttributes) && Utils.isArrayWithData(attrsWithNewSelectedData)) {
        dynamicAttributes = dynamicAttributes.map((currentDynamicAttribute) => {
            let currentAttrWithNewSelectedData = find(attrsWithNewSelectedData, ['name', currentDynamicAttribute.name]);
            if (currentAttrWithNewSelectedData) {
                currentDynamicAttribute.selectedData = currentAttrWithNewSelectedData.selectedData;
            }
            return currentDynamicAttribute;
        })
    }
    return dynamicAttributes;
}

export const buildAttributesFormElements = ( values = [] , data = []) => {
    let attributeValues = { stored:{}, suggested:{}} 
    values.forEach( o => {
        let foundData = find(data, ["id", o.attributeId]);
        
        if(!!foundData){
            
            let foundValue =  find(foundData.values, ["id", o.valueId]);
            let obj = {
                id: o.valueId,
                value: o.valueId,
                label: foundValue ? foundValue.value : "No aplica" ,
                isSuggested: o.userId === Constantes.SUGGESTED_ATTRIBUTES_USER_ID,
                valueId: o.valueId
            };
            if( obj.isSuggested ){
                attributeValues.suggested[o.attributeId] = 
                    Utils.isObjectWithData(attributeValues.suggested[o.attributeId]) ? [...attributeValues.suggested[o.attributeId], obj]:[obj];
            }else{
                attributeValues.stored[o.attributeId] = 
                    Utils.isObjectWithData(attributeValues.stored[o.attributeId]) ? [...attributeValues.stored[o.attributeId], obj]:[obj];
            }
        }        
    });  
    return attributeValues;
}

export const getSuggestedAttributes = (attributes = []) =>{
    return remove(attributes, [ 'userId' , Constantes.SUGGESTED_ATTRIBUTES_USER_ID])
}

export const enhanceProductAttributes = (stored, data, keepUnset = false) => {
    if(!Utils.isArrayWithData(stored) || !Utils.isArrayWithData(data)){
        return [];
    }
    let attributes = stored.filter(oItem => !!find( data, ["id", oItem.attributeId])).map((o) => { 
        let dataItem = find( data, ["id", o.attributeId]);        
        let visualization = Utils.isType(dataItem.visualization, "object") ? dataItem.visualization : JSON.parse(dataItem.visualization);
        let valueItem = find( dataItem.values, ["id", o.valueId]) || visualization.unsetOption;
        if( valueItem ){
            return{
                valueId: o.valueId,
                id: o.attributeId,
                isSuggested: o.userId === Constantes.SUGGESTED_ATTRIBUTES_USER_ID,
                value: valueItem.value,   
                unit: visualization.unit || "",
                visible: isNotPrivateRoute() ? true : Utils.isAttributeVisibleFromCountryCodeAndUserType(visualization.visible),
                order: visualization.order || 0,
                validationOrder: visualization.validationOrder || 0,
                hasUnsetValue: isEqual(visualization.unsetOption, valueItem)

            }
        }        
    });
    if( !keepUnset ){
        remove( attributes, ["hasUnsetValue", true])
    }
    return attributes;
}

export const formToProductAttributesFormat = (values, attributesData, productId, userId) => {
    let productAttributes = [];
    if(Utils.isObjectWithData(values) && Utils.isArrayWithData(attributesData)){
        Utils.iterateObjectAsArray(values,(arr, key) => {
            let visualization = find(attributesData,["id", parseInt(key)]) || {};            
            arr.forEach(o=>{
                productAttributes.push({
                    productId: parseInt(productId),
                    userId: parseInt(userId),
                    attributeId: parseInt(key),
                    valueId: parseInt(o.valueId),
                    producer: 'SCALIBUR_ATTRIBUTES_TOOL',
                    allowMultipleValues: !!visualization.allowMultipleValues
                });
            })
        }); 
    }    
    return productAttributes;
}

export const formatAttributesAsStoredAttributes = (product , attributesData , userId) => (
    Object.keys(product.attributes).map((key)=>{
        let data = find( attributesData, ["id", parseInt(key)]) || { values: []};
        let value = find( data.values, {"value": product.attributes[key][0]}) || {};
        return{
            productId: product.id,
            attributeId: parseInt(key),
            valueId: value.id,
            userId: userId
        }
    })
)
 
export const enhanceAttributesFromProductSearch = (product, attributesData, userId) => {
    if(!product || !Utils.isObjectWithData(product.attributes)){
        return [];
    }
    let formattedAttributes = formatAttributesAsStoredAttributes(product, attributesData, userId);
    return enhanceProductAttributes( formattedAttributes, attributesData);
};

export const enhanceAttributesToProductSet = (productSet, attributesData, userId) => (
    productSet.map( o =>(
        { 
            ...o,
            enhancedAttributes: enhanceAttributesFromProductSearch(o, attributesData, userId)
        }
    ))
)
