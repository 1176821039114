import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import CloseIcon from 'mdi-react/CloseIcon';

export class DynamicAttributeFieldLayout extends Component {
    static propTypes = {
        fieldClass: PropTypes.string.isRequired,
        fieldLabel: PropTypes.string,
        showRemoveFilters: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.removeFilter = this.removeFilter.bind(this);
        this.isRemoveFilterValid = this.isRemoveFilterValid.bind(this);
        this.state = {};
        this.childRef = {};
        this.timeoutIdRef = null;
    }
    componentDidUpdate() {
        this.childRef = get(this, "props.children.ref.current", {});
    }
    isRemoveFilterValid() {
        return !!(this.childRef && this.childRef.removeFilter);
    }
    componentDidMount() {
        this.timeoutIdRef = setTimeout(() => {
            this.setState({ childRef: get(this, "props.children.ref.current", {}) })
        })
    }
    componentWillUnmount(){
        clearTimeout(this.timeoutIdRef); 
    }

    removeFilter() {
        if (this.isRemoveFilterValid()) this.childRef.removeFilter();
    }

    render() {
        const { fieldClass, fieldLabel, showRemoveFilters } = this.props;
        return (
            <div className={`form__form-group filter-field ${fieldClass}`}>
                <Row>
                    <Col xs={10}>{fieldLabel ? <label className='form__form-group-label'>{fieldLabel}</label> : null}</Col>
                    {showRemoveFilters && this.isRemoveFilterValid() ? <Col xs={2} className="text-right" >
                        <button type="button" className="close">
                            <CloseIcon onClick={this.removeFilter} className="close" size={18} />
                        </button>
                    </Col> : null}
                </Row>

                <div className='form__form-group-field'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default (translate('common')(DynamicAttributeFieldLayout));
