import React from 'react';
import { Utils } from '@common/utils/';
import { connect } from 'react-redux';
import { fetchDelete, fetchDeleteProductOfLabel } from '../../../labels/LabelsState';
import { fetchProductItems } from '../../../table/TableState';
import SimpleProductCell from './SimpleProductCell';
import { receiveDeleteLabelResponseCallback, handleRemoveProductLabelClick } from '../../../labels/helpers';

const mapStateToProps = (state, ownProps) => {
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    let applicationData = {
        brands: state.productListBrandReducer.brands || [],
        stores: state.productListBrandReducer.stores || [],
        labels: state.labelStateReducer.labels || []
    };
    currentFilter.applicationData = applicationData;
    return {
        ...ownProps,
        labels: state.labelStateReducer.labels || [],
        totalSelectedProducts: state.labelStateReducer.newLabel.selectedProducts.length,
        selectedProducts: state.labelStateReducer.newLabel.selectedProducts,
        favoriteProductItems: state.favoriteStateReducer.favoriteProductItems,
        currentFilter: currentFilter,
        activeRanking: state.productListBrandReducer.activeRanking,
        isFetching: state.productListBrandReducer.isFetching,
        hidePublicLabels: state.labelStateReducer.hidePublicLabels
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        handleRemoveProductLabelClick: (label) => handleRemoveProductLabelClick({
            label,
            dispatch,
            fetchProductItems: (params) => fetchProductItems(dispatch, params),
            currentFilter: ownProps.currentFilter,
            removeLabel: (label) => fetchDelete(dispatch, label),
            removeProductFromLabel: (labelId, productId) => fetchDeleteProductOfLabel(dispatch, labelId, productId),
            productId: ownProps.productId,
            receiveDeleteLabelResponseCallback
        })
    };
};

export const SimpleProductCellWithRedux = connect(mapStateToProps, mapDispatchToProps)(SimpleProductCell);