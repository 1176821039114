import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Col, Row, Button, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { includes, remove, isObject, isEmpty, join, get } from 'lodash';
import NumberFormat from 'react-number-format';
import { AlertType } from '../../../../AlertTypes';
import {
    RetailCompassStorage,
    TableStateUtils,
    Utils,
    Constantes,
    Intercom,
    IntercomConstants,
    ChurnZero
} from "@common/utils/";

import RadioButtonField from "@common/components/src/ui/RadioButton";
import CheckBoxField from "@common/components/src/ui/CheckBox";

export class AlertFormBasic extends Component {

    static propTypes = {
        alert: AlertType,
        external: PropTypes.node,
        currentCanonicalCategory: PropTypes.object,
        currentFilter: PropTypes.object,
        createAlert: PropTypes.func,
        editAlert: PropTypes.func,
        errorMessage: PropTypes.string,
        isModal: PropTypes.bool
    };

    static defaultProps = {
        alert: {}
    }

    constructor(props) {
        super(props);
        this.state = props.alert || {};
        this.state.type = this.state.type || Constantes.ALERT_TYPE.PRICE_CHANGE
        this.state.subType = this.state.subType || this.priceChangeAsArraySubTypes();
        this.state.hasInvalidSubType = false;
        this.state.alertNotificationErrorSent = false;
        this.includeAlertSubType = this.includeAlertSubType.bind(this);
        this.onChangeSubType = this.onChangeSubType.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.resetErrors = this.resetErrors.bind(this);
        this.handleMethod = this.handleMethod.bind(this);
        this.validateSubType = this.validateSubType.bind(this);
        this.buildCheckBoxSubType = this.buildCheckBoxSubType.bind(this);
        this.buildRadioButtonType = this.buildRadioButtonType.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.priceChangeAsArraySubTypes = this.priceChangeAsArraySubTypes.bind(this);
        this.handlePriceVariation = this.handlePriceVariation.bind(this);
        this.stockChangeAsArraySubTypes = this.stockChangeAsArraySubTypes.bind(this);
    }

    showError(nextProps) {
        this.props.addNotification(Utils.buildAlert("danger", "Error Creando alerta", nextProps.errorMessage));
        this.setState({
            alertNotificationErrorSent: true,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.hasError && !this.state.alertNotificationErrorSent) {
            this.props.receiveCreateAlert(this.buildBody());
        }
    }

    includeAlertSubType(alertType) {
        return includes(this.state.subType, alertType);
    }

    priceChangeAsArraySubTypes() {
        return [Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.PRICE_CHANGE].PRICE_INCREASE, Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.PRICE_CHANGE].PRICE_DECREASE];
    }

    stockChangeAsArraySubTypes() {
        return [Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.STOCK_CHANGE].OUT_STOCK, Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.STOCK_CHANGE].WITH_STOCK];
    }

    onChangeType(type) {
        let newSubType = [];
        if (type === Constantes.ALERT_TYPE.PRICE_CHANGE) {
            newSubType = this.priceChangeAsArraySubTypes();
        } else if (type === Constantes.ALERT_TYPE.STOCK_CHANGE) {
            newSubType = this.stockChangeAsArraySubTypes();
        }
        this.setState({
            priceVariation: ''
        })
        this.setState({ type: type, subType: newSubType });
        this.resetErrors();
    }

    onChangeSubType(event, subType) {
        if (!isObject(event)) return;
        const target = event.target;
        const checked = target.checked;
        let subTypes = this.state.subType || [];
        if (checked && !this.includeAlertSubType(subType)) {
            subTypes.push(subType);
        }
        if (!checked && this.includeAlertSubType(subType)) {
            subTypes = remove(subTypes, item => item !== subType);
        }
        this.setState({
            subType: subTypes
        })
        setTimeout(() => {
            this.validateSubType();
        }, 40)

    }

    validateSubType() {
        let validSubType = true;
        if (isEmpty(this.state.subType)) {
            validSubType = false;
        }
        this.setState({
            hasInvalidSubType: !validSubType
        })
        return validSubType;
    }

    validateForm() {
        let invalidForm = true;
        invalidForm = this.validateSubType();

        return invalidForm;
    }

    buildBody() {
        let body = { ...this.state };
        const categoryName = get(this, "props.currentCanonicalCategory.name", "Categoría sin nombre")
        if (body.type === Constantes.ALERT_TYPE.PRICE_CHANGE) {
            body.name = "Cambios de precio en " + categoryName
        } else if (body.type === Constantes.ALERT_TYPE.STOCK_CHANGE) {
            body.name = "Cambios de stock en " + categoryName
        }


        if (body.type === Constantes.ALERT_TYPE.PRICE_CHANGE && body.priceVariation) {
            body.priceVariation = body.priceVariation / 100;
        }

        body.categoryId = this.props.currentCanonicalCategory.id;
        //se cambia de array a separado por coma
        body.subType = join(body.subType, ",");
        let searchRequest = {
            ...this.props.lastRequest,
            size: 1000,
            page: 0,
            keyWords: undefined,
            keywordsSearchStrategy: undefined,
            sort: this.buildDefaultSort()
        };

        // después de la primera agregación que trae todo el conjunto de datos necesarios para llenar los filtros de información,
        // no es necesario que en cada petición enviemos agregaciones de atributos porque esa información ya la obtuvimos en la primera petición.
        // ese es el motivo por el cual debemos agregar aquí las agragciones de atributos y cualquier otra que no se incluya en la primera petición.
        searchRequest.aggregations = TableStateUtils.getAttributesAggregationsForAlertService();

        // se cambia el consumer que usará notificacion-service a product-search
        // SCALIBUR -> SCALIBUR_ALERTS
        searchRequest.consumer = Constantes.CONSUMER.SCALIBUR_ALERTS

        body.query = JSON.stringify(searchRequest);
        body.webFilter = this.props.history.location.pathname + this.props.history.location.search;
        body.productAccessId = Constantes.PRODUCT_ACCESS_APPS.SCALIBUR
        return body;
    }

    handlePriceVariation(event) {
        if (!event || !event.target) return;
        this.setState({ priceVariation: event.target.value });
    }

    buildDefaultSort() {
        let sort = {
            field: "PRODUCT_RELEVANCE",
            order: "DESC",
            brandIds: RetailCompassStorage.getBrandIds(),
            favoriteId: this.props.currentFilter && this.props.currentFilter.favoriteId,
            storeType: RetailCompassStorage.getStoreType()
        };
        return sort;
    }

    resetErrors() {
        this.setState({
            hasInvalidSubType: false,
            alertNotificationErrorSent: false,
        })
    }
    handleMethod(method) {
        if (!method) return;
        if (!this.validateForm()) return;
        method(this.buildBody());
        this.resetErrors();
    }

    handleCreate() {
        this.handleMethod(this.props.createAlert)
    }
    handleEdit() {
        this.handleMethod(this.props.editAlert)
    }

    handleSubmit() {
        if (!this.state.id) {
            Intercom.trackEvent(IntercomConstants.EVENTS.CREATED_ALERT);
            ChurnZero.trackEvent({ eventName: "scalibur_alerta_creada" });
            return this.handleCreate();
        }
        this.handleEdit();
    }

    buildCheckBoxSubType(label, subType) {
        return <CheckBoxField
            defaultChecked={false}
            value={false}
            label={label}
            className='compass'
            input={{
                value: this.includeAlertSubType(subType),
                name: subType,
                onChange: (event) => {
                    this.onChangeSubType(event, subType)
                }
            }}
        />
    }

    buildRadioButtonType(config) {
        let { label, type, className } = config;
        let currentType = this.state.type;
        return <RadioButtonField
            label={label}
            radioValue={type}
            defaultChecked={false}
            className={'colored-click ' + className}
            input={{
                name: 'Constantes.ALERT_TYPE',
                value: currentType,
                onChange: (type) => {
                    this.onChangeType(type);
                }
            }}
        />
    }

    render() {
        const { isModal, external, isSaving } = this.props;

        let isPriceChange = (this.state.type === Constantes.ALERT_TYPE.PRICE_CHANGE);
        let isStockChange = (this.state.type === Constantes.ALERT_TYPE.STOCK_CHANGE);

        return (
            <div >
                <Row>
                    <Col xs={"12"} >
                        <Row className="text-left">
                            <Col xs={!isModal ? "6" : "12"} className={"alertForm--body--form " + (isModal ? "alertForm--body--form--modal" : "")}>

                                <Row>
                                    <Col xs={6}>
                                        <div className="alertForm--body--title" >
                                            <span>Tipo de alerta</span>
                                        </div>

                                        <div className="alertForm--body--row15" >
                                            {this.buildRadioButtonType({
                                                label: "Cambios de precio",
                                                type: Constantes.ALERT_TYPE.PRICE_CHANGE,
                                                className: 'alert-type-price-change'
                                            })}
                                        </div>
                                        <div className="alertForm--body--row10">
                                            {this.buildRadioButtonType({
                                                label: "Cambios de stock",
                                                type: Constantes.ALERT_TYPE.STOCK_CHANGE,
                                                className: 'alert-type-stock-change'
                                            })}
                                        </div>

                                    </Col>

                                    <Col xs={6}>
                                        <div className="alertForm--body--title" >
                                            <span id="tooltipWrapper">
                                                {isPriceChange ? 'Tipo de variación' : null}
                                                {isStockChange ? 'Tipo de stock' : null}
                                            </span>
                                        </div>

                                        <Tooltip className="tooltip-modal" placement='top-start' target={"tooltipWrapper"} isOpen={this.state.hasInvalidSubType} >
                                            Selecciona al menos una opción
                                        </Tooltip>

                                        {isPriceChange ? <div>
                                            <div className="alertForm--body--row15">
                                                {this.buildCheckBoxSubType("Subidas de precio", Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.PRICE_CHANGE].PRICE_INCREASE)}
                                            </div>
                                            <div className="alertForm--body--row10">
                                                {this.buildCheckBoxSubType("Bajadas de precio", Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.PRICE_CHANGE].PRICE_DECREASE)}
                                            </div>
                                            <div className="alertForm--body--row30">
                                                <h3 className="alertForm--body--title alertForm--body--stretch">Variación de precios <span className="alertForm--body--title--optional">(Opcional)</span></h3>
                                            </div>
                                            <div className="alertForm--body--row2 alertForm--body--stretch alertForm--body--textHelp">
                                                Escribe el porcentaje de variación de precio
                                            </div>
                                            <div className="alertForm--body--row10">
                                                <div className='alertForm--body--from'>
                                                    <div className="alertForm--body--from--label">Desde</div>
                                                    <div className="alertForm--body--from--input">
                                                        <NumberFormat
                                                            maxLength={6}
                                                            isAllowed={(value) => !value || !value.formattedValue || (value.floatValue <= Constantes.ALERT_PRICE_CHANGE_MAX_VALUE && value.floatValue > 0)}
                                                            allowNegative={false}
                                                            value={this.state.priceVariation} onChange={this.handlePriceVariation}
                                                            fixedDecimalScale={false}
                                                            decimalScale={1}
                                                        />
                                                        <span className="alertForm--body--from--input--suffix">%</span>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="alertForm--body--row10 alertForm--body--textDescription">
                                                Cada vez que un producto tenga una variación de
                                                precio o la sobrepase te avisaremos. Si no ingresas
                                                una cifra te avisaremos de todas las variaciones
                                            </div>

                                        </div> : null}

                                        {isStockChange ? <div>
                                            <div className="alertForm--body--row15">
                                                {this.buildCheckBoxSubType("Sin stock", Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.STOCK_CHANGE].OUT_STOCK)}
                                            </div>
                                            <div className="alertForm--body--row10">
                                                {this.buildCheckBoxSubType("Con stock", Constantes.ALERT_SUBTYPE[Constantes.ALERT_TYPE.STOCK_CHANGE].WITH_STOCK)}
                                            </div>
                                            <div className="alertForm--body--row10 alertForm--body--info">
                                                Vigilaremos los cambios de stock y te avisaremos cuando cumpla más de 24 horas con stock o sin stock
                                            </div>
                                        </div> : null}
                                    </Col>
                                </Row>

                            </Col>
                            {!isModal ?
                                <Col xs={"6"}>
                                    {this.props.children}
                                </Col>
                                : null}
                        </Row>

                    </Col>
                    <Col xs={{ size: 8, offset: 4 }} className="text-right">
                        {external}
                        <Button disabled={isSaving || this.state.hasInvalidSubType} onClick={() => { this.handleSubmit() }} className="btn btn-success btn-alerts" >
                            Ok, crear alerta
                            </Button>
                    </Col>
                </Row>


            </div>
        );
    }
}

export default reduxForm({
    form: 'alertFormBasic', // a unique identifier for this form
})(AlertFormBasic);
