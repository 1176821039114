import * as ValidationActions from "../ValidationActions";
import { removeMatchFromSuggestedMatches, addMatchToRemoved } from "../../common/utils/utils";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const addMatch = (currentProduct, match) => {
    currentProduct.competitorsProducts = currentProduct.competitorsProducts || [];
    currentProduct.allCompetitorsProducts = currentProduct.allCompetitorsProducts || [];
    currentProduct.directMatches = currentProduct.directMatches || [];
    if (match && match.productId) {
        match.updated = new Date().getTime();
        currentProduct.competitorsProducts.push(match);
        currentProduct.allCompetitorsProducts.push(match);
        currentProduct.directMatches.push(match);
    }
    return currentProduct;
}

export const requestTransformIntoRemoved = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isTransformingTheMatch: true
    });
}

export const receiveTransformIntoRemoved = (state, action) => {
    const requestBody = action.payload.requestBody;
    const match = requestBody.match;
    return updateObject(state, {
        hasError: false,
        error: null,
        isTransformingTheMatch: false,
        suggestedMatches: removeMatchFromSuggestedMatches(state.suggestedMatches, match),
        currentProduct: addMatchToRemoved(state.currentProduct, match)
    });
}

export const receiveTransformIntoRemovedError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isTransformingTheMatch: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_TRANSFORM_INTO_REMOVED]: requestTransformIntoRemoved,
    [ValidationActions.RECEIVE_TRANSFORM_INTO_REMOVED]: receiveTransformIntoRemoved,
    [ValidationActions.RECEIVE_TRANSFORM_INTO_REMOVED_ERROR]: receiveTransformIntoRemovedError,
}

export default handlers