import { get, find } from 'lodash';
import * as RetailCompassStorage from '../app/Storage';
import * as Constantes from '../utils/constantes';

const STRATEGIES = {
    [Constantes.StoreType.BRAND]: (summary) => _buildCounterItemsConfig(summary),
    [Constantes.StoreType.RETAILER]: (summary) => _buildCounterItemsConfig(summary)
}

export const buildCounterItemsConfig = (outOfStockReportSummary) => {
    let storeType = RetailCompassStorage.getStoreType();
    let func = STRATEGIES[storeType];
    return func && func(outOfStockReportSummary);
}

export const buildCounterBrandItemsConfig = (brand, outOfStockRangesBrand) => {
    const from0To1 = find(get(outOfStockRangesBrand, '0_1', { brands: [] }).brands, ['brandId', brand.brandId]);
    const from2To3 = find(get(outOfStockRangesBrand, '2_3', { brands: [] }).brands, ['brandId', brand.brandId]);
    const from4To30 = find(get(outOfStockRangesBrand, '4_30', { brands: [] }).brands, ['brandId', brand.brandId]);
    return _buildConfig(from0To1, from2To3, from4To30, 'outStock');
}

/**
 * construye los contadores para el resumen.
 * se deja en esta función por si más adelante se requiere agregar más rangos o si se desea configurar 
 * desde base de datos.
 * 
 * @param {*} outOfStockReportSummary agregación de rangos de precios formateada
 */
const _buildCounterItemsConfig = (outOfStockReportSummary) => {
    const from0To1 = get(outOfStockReportSummary, '0_1', {});
    const from2To3 = get(outOfStockReportSummary, '2_3', {});
    const from4To30 = get(outOfStockReportSummary, '4_30', {});

    return _buildConfig(from0To1, from2To3, from4To30, 'outOfStock');
}

const _buildConfig = (from0To1, from2To3, from4To30, key) => {
    return [
        {
            id: 1,
            counterKey: '0_1',
            label: "Solo hoy",
            barClassName: 'counter-from-0-to-1',
            percentageClassName: 'counter-from-0-to-1-percentage',
            isSelected: false,
            enableCounterPercentageClick: false,
            total: get(from0To1, key, 0),
            entity: from0To1,
            range: {
                from: 0,
                to: 1,
                order: 1
            }
        },
        {
            id: 2,
            counterKey: '2_3',
            label: "2 a 3 días",
            barClassName: 'counter-from-2-to-3',
            percentageClassName: 'counter-from-2-to-3-percentage',
            isSelected: false,
            enableCounterPercentageClick: false,
            total: get(from2To3, key, 0),
            entity: from2To3,
            range: {
                from: 2,
                to: 3,
                order: 2
            }
        },
        {
            id: 3,
            counterKey: '4_30',
            label: "4 a 30 días",
            barClassName: 'counter-from-4-to-30',
            percentageClassName: 'counter-from-4-to-30-percentage',
            isSelected: false,
            enableCounterPercentageClick: false,
            total: get(from4To30, key, 0),
            entity: from4To30,
            range: {
                from: 4,
                to: 30,
                order: 3
            }
        },
    ];
}
