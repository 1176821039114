/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class routerUtils.js
 * @date 26 feb. 2019
 */
import {invert, remove} from 'lodash';
import * as RetailCompassStorage  from '../app/Storage'
import * as Utils  from '../utils/utils'
import queryString from 'qs';
import * as Constantes from "./constantes";

export const MODULES = {
    BRAND: '/brand',
    RETAIL: '/retail',
    VALIDATION: '/validation',
}

export const NOT_PRIVATE_MODULE_PATHS = [ MODULES.VALIDATION ]

export const MODULES_LIST = [
    MODULES.BRAND,
    MODULES.RETAIL,
    MODULES.VALIDATION
];

export const MODULES_VIEWS = {
    LIST: 'list',
    ALERT: 'alert',
    DASHBOARD: 'dashboard',
    COMPARE: 'compare',
    EDIT: 'edit'
}

export const MODULES_VIEWS_LIST = [
    MODULES_VIEWS.LIST,
    MODULES_VIEWS.COMPARE,
    MODULES_VIEWS.EDIT
];

export const WEBAPP = {
    URI: "http://app.retailcompass.com",
    MODULES:{
        PRODUCT_DETAIL:{
            URI: "product.do",
            PARAMS:["product_id"],
            KEY:  "PRODUCT_DETAIL"
        }
    }
};


export const getModulePath = (isStoreTypeBrand) => {
    return isStoreTypeBrand ? MODULES.BRAND : MODULES.RETAIL
}

export const buildCategoryPartMenuPath = (categoryKey, isStoreTypeBrand) => {
    const modulePath = getModulePath(isStoreTypeBrand);
    if (isStoreTypeBrand) {
        return modulePath + '/' + categoryKey + '/list'
    }
    return modulePath + '/' + categoryKey + '/list'
}
export const buildAlertPath = (isStoreTypeBrand) => {
    const modulePath = getModulePath(isStoreTypeBrand);
    return modulePath + '/alerts'
}

export const buildCategoryPartMenuPathByModule = (categoryKey, moduleKey) => {
    const isStoreTypeBrand = moduleKey === MODULES.BRAND;
    const modulePath = getModulePath(isStoreTypeBrand);
    return modulePath + '/' + categoryKey + '/list'
}


export const buildModuleViewPath = (moduleView) => {
    const modulePath = getModulePath(RetailCompassStorage.isStoreTypeBrand());
    let viewKey = (invert(MODULES_VIEWS))[String(moduleView).toLowerCase()];
    let categoryKey = RetailCompassStorage.getCurrentCanonicalCategory().key;
    if(viewKey && categoryKey){
        return [modulePath,categoryKey, MODULES_VIEWS[viewKey]].join("/");
    }
    return null;
}

export const updateLocationSearch = ({ location, history, searchParams, needRerender }) => {
    let urlParsed = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
    urlParsed = { ...urlParsed, ...searchParams }
    const stringified = queryString.stringify(urlParsed);
    history.push({ search: stringified, state: { from: location, needRerender: !!needRerender } });
}

export const goToProductListTabView = ({
    location,
    history,
    onSelectedView,
    params,
    selectedView,
}) => {
    const view = selectedView || Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST;
    onSelectedView(view);

    const searchParams = { ...params };
    searchParams[Constantes.URL_PARAMS.SELECTED_VIEW] = view;
    updateLocationSearch({
        location,
        history,
        searchParams,
        needRerender: true,
    });
};

export const buildWebappPath = (module, params) => {
    let url = [WEBAPP.URI];
    let _module = WEBAPP.MODULES[module] || "";
    if(_module){
        let _params = "";
        if(params){
            remove(params, (o,key)=>{
                return Object.keys(_module.PRODUCT_DETAIL.PARAMS).indexOf(key) === -1; 
            })
            _params = queryString.stringify(params);
        }        
        url.push([_module.URI,_params].join("?"));
    }
    return url.join("/");    
}

export const buildWebappProductDetailPath = (productId) =>{
    return buildWebappPath(WEBAPP.MODULES.PRODUCT_DETAIL.KEY, {[WEBAPP.MODULES.PRODUCT_DETAIL.PARAMS[0]]:productId});
}

export const simpleBuildPath = (module, view, orderedParams) => {
    return [ module, view, ...orderedParams ].join("/");
}

export const getValidationEditPath = (productId, categoriesPaths) => {
    return simpleBuildPath(MODULES.VALIDATION, MODULES_VIEWS.EDIT, [
        RetailCompassStorage.getCountryCode(),
        RetailCompassStorage.getCurrentCanonicalCategory().id,
        productId,
        Utils.getEncodedContextInfo(categoriesPaths)
    ]);
}

export const getValidationEditPathWithParams = (params) => {
    let { countryCode, categoryId, productId, contextInfo } = params;
    return simpleBuildPath(MODULES.VALIDATION, MODULES_VIEWS.EDIT, [
        countryCode,
        categoryId,
        productId,
        contextInfo
    ]);
}

export const isNotPrivateRoute = () => {
    for(let i = 0 ; i < NOT_PRIVATE_MODULE_PATHS.length; i++){
        if(window.location.pathname.indexOf(NOT_PRIVATE_MODULE_PATHS[i]) === 0){
            return true;
        }
    }
    return false;
}

export const isCategoryTypeOperator = (categoryKey) => {
    const category = RetailCompassStorage.getCanonicalCategoryByKey(categoryKey);
    if (!category) return false;
    return Constantes.CANONICAL_CATEGORY_VISUAL_TYPE.POSTPAID === category.visualType;
}
