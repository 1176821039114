import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestTransformIntoRemoved = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsTransformingIntoRemoved: true
    });
}

export const receiveTransformIntoRemoved = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsTransformingIntoRemoved: false
    });
}

export const receiveTransformIntoRemovedError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        canonicalIsTransformingIntoRemoved: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_CANONICAL_TRANSFORM_INTO_REMOVED]: requestTransformIntoRemoved,
    [ValidationActions.RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED]: receiveTransformIntoRemoved,
    [ValidationActions.RECEIVE_CANONICAL_TRANSFORM_INTO_REMOVED_ERROR]: receiveTransformIntoRemovedError,
}

export default handlers