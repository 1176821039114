import { ADD_NOTIFICATION, addNotificationAction, REMOVE_NOTIFICATION, removeNotificationAction } from './NotificationActions';

export const initialState = {
    notification: null
};
export const addNotification = (dispatch, payload) => {
    return dispatch(addNotificationAction(payload));
};
export const removeNotification = (dispatch, payload) => {
    return dispatch(removeNotificationAction(payload));
};

export const NotificationReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {

        case ADD_NOTIFICATION:
            return Object.assign({}, state, { notification: action.payload });
        case REMOVE_NOTIFICATION:
            return Object.assign({}, state, { notification: null });
        default:
            return state;
    }
};

