import React from "react";
import LabelsProductDetail from "../../../../labels/label-list/LabelsProductDetail";
import TimeIntoCommerce from "../TimeIntoCommerce";
import ProductDetailTags from "../ProductDetailTags";
import ProductBadge from "../../ProductBadge";

const ProductDescription = ({ producto, labels, dispatch, currentFilter, showPopoverRemove, tableStrategy, onRemoveLabel, hidePublicLabels }) => {

    return (
        <div className="product-detail__card clearfix product-description">
            <div className="product-card__image">
                <img alt="" onError={(img) => { if (img && img.remove) img.remove() }} src={producto.image} />
            </div>

            <div className="product-card__description brand">
                <ProductDetailTags attributes={producto.attributes} />
                <BrandModelDescription producto={producto} />
                <ProductBadge producto={producto} tableStrategy={tableStrategy} />
                <TimeIntoCommerce date={producto.created} />
                <div className="labels-container">
                    <LabelsProductDetail productId={producto.productId}
                        labels={labels}
                        showPopoverRemove={showPopoverRemove}
                        dispatch={dispatch}
                        currentFilter={currentFilter}
                        onRemoveLabel={onRemoveLabel}
                        hidePublicLabels={hidePublicLabels}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProductDescription

export const BrandModelDescription = ({ producto }) => {
    return (
        <h1 id="brand_model">
            <span>
                Marca: <span className="value">{producto.brand || null}</span> /
                Modelo: <span className="value">{producto.model || null}</span>
            </span>
        </h1>
    )
}