import React, { useState, useEffect, memo } from 'react';
import { useFilterDispatch } from '../FiltersProvider';
import Select from 'react-select';
import { actionsFilter } from '../FiltersReducer';
import { OperatorUtils } from '@common/utils/'
import FilterFieldLayout from '../../../FilterFieldLayout';

const PostpagoOperatorFilter = ({ labelText, currentFilter, stores }) => {
    const [allOperators, setAllOperators] = useState([]);
    const [selectedOperators, setSelectedOperators] = useState([]);


    const dispatch = useFilterDispatch();
    useEffect(() => {
        let filterOperators = [];
        let myOperators = OperatorUtils.getMyOperatorKeys(stores);
        stores.forEach(operator => {
            if (myOperators.indexOf(operator.operatorKey) == -1) {
                filterOperators.push({
                    value: operator.operatorKey,
                    label: operator.visibleOperatorName,
                    queryParam: operator.operatorKey
                });
            }
        });
        setAllOperators(filterOperators);
        onChangeOperators(currentFilter.operators ?? []);
    }, [])

    const onChangeOperators = (values) => {
        setSelectedOperators(values);
        dispatch({ type: actionsFilter.UPDATE_OPERATORS, postpaidOperators: values });
    }

    return (
        <div className="form">
            <FilterFieldLayout
                showRemoveFilters={selectedOperators.length > 0}
                onClear={() => onChangeOperators([])}
                fieldLabel={labelText}>
                <Select
                    multi
                    name="postpaid-operators"
                    value={selectedOperators}
                    onChange={onChangeOperators}
                    options={allOperators}
                    clearable={false}
                    className="form__form-group-select filters-container--operators"
                    closeOnSelect={false}
                    removeSelected={true}
                    placeholder={"Todos"}
                    noResultsText="No hay registros"
                />
            </FilterFieldLayout>
        </div>
    )
}

export default memo(PostpagoOperatorFilter);