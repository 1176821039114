import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { isEqual, includes } from 'lodash';
import { Constantes, RetailCompassStorage, Utils } from '@common/utils';
import * as TableActions from '../../table/TableActions'

export const genericPropTypes = {
    fetchProductItems: PropTypes.func.isRequired,
    paginationCount: PropTypes.number.isRequired,
    paginationCurrentPage: PropTypes.number.isRequired,
    productItems: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    currentFilter: PropTypes.object.isRequired,
    lastFilter: PropTypes.object.isRequired,
    updateCurrentProduct: PropTypes.func.isRequired,
    currentProduct: PropTypes.object,
    stores: PropTypes.array,

    productDetailOpenedProp: PropTypes.bool,
    productDetailOpened: PropTypes.func,
    currentSideFiltersCount: PropTypes.number,
    deactivateAllFilters: PropTypes.func,
    updateSort: PropTypes.func,
    scrollableHeader: PropTypes.bool,
    isDownloading: PropTypes.bool,
    favoriteProductItems: PropTypes.array,

    shouldReloadTableFromLabelDeletion: PropTypes.bool,
    isOpenLabelEditModal: PropTypes.bool
};

export const withGenericTableView = (WrappedComponent, tableId) => {
    return class GenericTableView extends PureComponent {
        static propTypes = {
            ...genericPropTypes
        };

        constructor(props) {
            super(props);

            this.state = {
                selectedRows: [],
                rows: [],
                sortKey: null,
                sortDirection: null,
                pageSize: 20,
                pager: null,
                allRows: [],
                isSortable: true,
            };
            this.onRowSelected = this.onRowSelected.bind(this);
            this.onRowDeselected = this.onRowDeselected.bind(this);
            this.onSort = this.onSort.bind(this);
            this.onRowsAllSelected = this.onRowsAllSelected.bind(this);
            this.onRowsAllDeselected = this.onRowsAllDeselected.bind(this);
            this.onChangePage = this.onChangePage.bind(this);

            this.setFixedHeader = this.setFixedHeader.bind(this);
            this.validSearchFieldValue = this.validSearchFieldValue.bind(this);
            this.filtersCollisionWarning = this.filtersCollisionWarning.bind(this);
            this.isCheckboxRowSelection = this.isCheckboxRowSelection.bind(this);
            this.areSameProduct = this.areSameProduct.bind(this);
            this.isPurchaseSummaryCard = this.isPurchaseSummaryCard.bind(this);
        };

        componentDidMount() {
            if (this.props.scrollableHeader) {
                window.addEventListener('scroll', this.setFixedHeader);
                window.addEventListener('resize', this.correctFixedHeader);
            }
        }

        componentWillUnmount() {
            if (this.props.scrollableHeader) {
                window.removeEventListener('scroll', this.setFixedHeader);
                window.removeEventListener('resize', this.correctFixedHeader);
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {

            const lastFilter = this.props.lastFilter;
            const nextFilter = nextProps.lastFilter;

            // la selección de productos debe reiniciarse cuando el usuario cambia algún filtro...
            // excepto la página actual
            if (!isEqual({ ...lastFilter, page: null }, { ...nextFilter, page: null })) {
                this.setState({ selectedRows: [] });
            }

            if (isEqual(lastFilter.keyWords, nextFilter.keyWords)) {
                this.setState({ isSortable: true });
                if (nextFilter.sort) {
                    this.onSort(nextFilter.sort.field, nextFilter.sort.order);
                }
            } else {
                this.setState({ isSortable: false });
            }
        }

        onRowSelected(row, rows) {
            this.setState({ selectedRows: rows });
        };

        onRowDeselected(row, rows) {
            this.setState({ selectedRows: rows });
        };

        onSort(sortKey, sortDirection) {
            let field = Utils.normalizeSortKey(sortKey);
            if (field) {
                let sort = { field: field, order: sortDirection };
                if (sortDirection === 'NONE') {
                    sort = null;
                    this.props.updateSort && this.props.updateSort(null);
                } else {
                    this.props.updateSort && this.props.updateSort({ ...sort, sortKey, sortDirection });
                }

                //cuando hay una modificacion en el sort se regresa a la primera pagina
                this.props.fetchProductItems({
                    ...this.props.currentFilter, paginationCurrentPage: Constantes.DEFAULT_PRODUCT_LIST_FILTER.paginationCurrentPage, sort: sort
                }, Constantes.SearchType.SORT, this.state.isSortable);
            }

            this.setState({ sortKey: sortKey, sortDirection: sortDirection });

            if (RetailCompassStorage.isEnableProductRanking() && sortKey == Constantes.SortKeys.RANKING_POSITION && !this.props.activeRanking) {
                RetailCompassStorage.setActiveRanking(true);
                this.props.dispatch(TableActions.updateActiveRanking(true));
            }
        };

        onChangePage(pager) {
            this.setState({ pager: pager });

            if (pager.currentPage !== this.props.paginationCurrentPage) {
                let currentFilter = { ...this.props.currentFilter, paginationCurrentPage: pager.currentPage };
                this.props.fetchProductItems(currentFilter, Constantes.SearchType.PAGINATION);
            }
        };

        onRowsAllSelected() {
            this.setState({ selectedRows: this.props.productItems, allRows: this.props.productItems });
        }

        onRowsAllDeselected() {
            this.setState({ selectedRows: [], allRows: [] });
        }

        deselectCurrentProductRow() {
            let element = document.getElementsByClassName("custom-table-row--active");
            if (element.length > 0) {
                element[0].classList.remove("custom-table-row--active");
            }
        }

        /**
        * Busca si el elemento clickeado corresponde al checkbox que permite seleccionar las filas
        */
        isCheckboxRowSelection(target) {
            if (!target || target.localName === 'td') {
                return false;
            }
            if (includes(target.className, 'custom-table-row-selection')) {
                return true;
            }
            if (!target.parentNode) {
                return false;
            }
            return this.isCheckboxRowSelection(target.parentNode);
        }

        /**
        * Busca si el elemento clickeado corresponde a la tarjeta de resumen de compra
        */
        isPurchaseSummaryCard(target) {
            if (!target || target.className === 'rt-td') {
                return false;
            }
            if (includes(target.className, 'purchase-summary-card-container')) {
                return true;
            }
            if (!target.parentNode) {
                return false;
            }
            return this.isPurchaseSummaryCard(target.parentNode);
        }

        areSameProduct(currentProduct, rowItem) {
            if (currentProduct && rowItem && currentProduct.productId === rowItem.productId) {
                return true;
            }
            return false;
        }

        validSearchFieldValue(lastFilter) {
            const { skus, keyWords } = lastFilter;
            return (skus && skus.length > 0) || (keyWords && keyWords.length > 0);
        }

        filtersCollisionWarning() {
            if (this.validSearchFieldValue(this.props.lastFilter) &&
                this.props.currentSideFiltersCount > 0 && this.props.paginationCount > 0) {
                return (
                    <div className="hidden-items-warning clearfix">
                        <div className="hidden-items-warning__left">
                            <div className="title">Ojo con el listado</div>
                            <div className="message">Es posible que algunos productos no esten visibles porque hay filtros activos</div>
                        </div>
                        <div className="hidden-items-warning__right">
                            <Button size={'sm'} className="deactivate-all-filters-button" outline onClick={this.props.deactivateAllFilters}>
                                Desactivar Filtros
                            </Button>
                        </div>
                    </div>
                );
            }
            return;
        }

        setFixedHeader(e) {
            let table = document.getElementById(tableId);

            if (!table || table.childElementCount < 2) {
                return;
            }

            let offset = Utils.getUpperOffset() + table.childNodes[0].offsetHeight;
            if (table.getBoundingClientRect().top <= offset && !table.classList.contains("fixed-header-table")
                && table.childNodes[0].childElementCount > 0 && table.childNodes[1].childElementCount > 0) {
                let widths = [];
                table.childNodes[0].childNodes[0].childNodes.forEach(function (item, inx) {
                    widths.push(item.offsetWidth);
                });
                table.classList.add("fixed-header-table");
                table.childNodes[0].childNodes[0].childNodes.forEach(function (item, inx) {
                    item.style.width = widths[inx] + "px";
                });
                table.childNodes[1].childNodes[0].childNodes.forEach(function (item, inx) {
                    item.style.width = widths[inx] + "px";
                });
            }
            else if (table.getBoundingClientRect().top > offset && table.classList.contains("fixed-header-table")) {
                table.classList.remove("fixed-header-table");
            }
        }

        correctFixedHeader(e) {
            setTimeout(function () {
                let table = document.getElementById(tableId);

                if (!table) {
                    return;
                }

                if (table.classList.contains("fixed-header-table")) {
                    table.childNodes[0].childNodes[0].childNodes.forEach(function (item, inx) {
                        item.style.width = "auto";
                        table.childNodes[1].childNodes[0].childNodes[inx].style.width = "auto";
                        item.style.width = table.childNodes[1].childNodes[0].childNodes[inx].offsetWidth + "px";
                    });
                }
            }, 100);
        }

        render() {
            const otherProps = {
                filtersCollisionWarning: this.filtersCollisionWarning,
                onSort: this.onSort,
                onRowSelected: this.onRowSelected,
                onRowDeselected: this.onRowDeselected,
                selectedRows: this.state.selectedRows,
                onRowsAllSelected: this.onRowsAllSelected,
                allRows: this.state.allRows,
                onRowsAllDeselected: this.onRowsAllDeselected,
                pageSize: this.state.pageSize,
                onChangePage: this.onChangePage,
                deselectCurrentProductRow: this.deselectCurrentProductRow,
                isCheckboxRowSelection: this.isCheckboxRowSelection,
                areSameProduct: this.areSameProduct,
                isPurchaseSummaryCard: this.isPurchaseSummaryCard
            };

            return <WrappedComponent {...this.props} {...otherProps} />
        }
    }
}
