import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TopbarWithNavigation from "./TopbarWithNavigation";
import SidebarMobile from "./SidebarMobile";

class RetailCompassLayout extends PureComponent {
    static propTypes = {
        changeMobileSidebarVisibility: PropTypes.func.isRequired
    };

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        const { changeMobileSidebarVisibility } = this.props;

        return (
            <div>
                <TopbarWithNavigation
                    renderTopbarNav={this.props.renderTopbarNav}
                    renderUserSection={this.props.renderUserSection}
                    changeMobileSidebarVisibility={
                        changeMobileSidebarVisibility
                    }
                />
                <SidebarMobile
                    renderSidebarContent={this.props.renderSidebarContent}
                />
            </div>
        );
    }
}

export default connect(state => {
    return {};
})(RetailCompassLayout);
