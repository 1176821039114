import { validateIfMatchIsMyProductByBrandsIds } from '../common/match';
import * as Utils from '../../utils/utils';

export const formatMatchedProducts = (params) => {
    let { userBrandItems, matchedProducts } = params;
    userBrandItems = userBrandItems || [];
    matchedProducts = matchedProducts || [];

    return matchedProducts.map((item) => {
        let canonicalProduct = { ...item };

        if (Utils.isArrayWithData(canonicalProduct.competitorsProducts)) {
            canonicalProduct.competitorsProducts = canonicalProduct.competitorsProducts.map((match) => {
                return {
                    ...match,
                    isMyProduct: validateIfMatchIsMyProductByBrandsIds(userBrandItems, match)
                }
            });

            canonicalProduct.isMyProduct = validateIfMatchIsMyProductByBrandsIds(userBrandItems, canonicalProduct);
            if (!canonicalProduct.isMyProduct) {
                canonicalProduct.isMyProduct = canonicalProduct.competitorsProducts.filter((item) => item.isMyProduct).length > 0;
            }
        }
        return {
            ...canonicalProduct
        }
    });
}