import React from 'react'
import LabelModal from './LabelModal'
import { Constantes } from '@common/utils/'

const buildTitle = (isPublicModal) => {
    if (isPublicModal) {
        return <>Estás eliminando un label público</>;
    } else {
        return <>Estás eliminando un label</>;
    }
}

const buildDescription = (isPublicModal) => {
    if (isPublicModal) {
        return <>Todos tus compañeros que lo usan perderán<br/>la información de este label, verifica que el<br/>creador esté totalmente de acuerdo.</>;;
    } else {
        return <></>;
    }
}

const DeleteLabelModal = ({ labelType, onConfirm, onCancel }) => {

    const isPublicModal = labelType === Constantes.ACCESS_TYPES_LABEL.PUBLIC;
    const title = buildTitle(isPublicModal);
    const description = buildDescription(isPublicModal);
    const subTitle = "¿Estás seguro de realizar esta acción?";
    const confirmAction = "Sí, eliminar";    

    return (
        <LabelModal
            title={title}
            className={isPublicModal ? "danger" : ""}
            subtitle={subTitle}
            description={description}
            confirmAction={confirmAction}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
}

export default DeleteLabelModal;
