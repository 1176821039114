import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';

export default class TopbarWithNavigation extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    logoDestination: PropTypes.string
  };

  render() {
    const { changeMobileSidebarVisibility } = this.props;

    return (
      <div className='topbar topbar--navigation'>
        <div className='topbar__wrapper'>
          <div className='topbar__left'>
            <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
            {this.props.logoDestination ? 
              <Link className='topbar__logo' to='/dashboard_default' />
              : 
              <div className='topbar__logo'></div>
            }            
          </div>
          <div className="topbar__right-section">
            {this.props.renderTopbarNav()}
            <div className='topbar__right'>
              {this.props.renderUserSection()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
