import { Constantes, ModuleUtils, RetailCompassStorage } from '@common/utils';
import classnames from 'classnames';
import { uniqBy } from "lodash";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { change } from 'redux-form';
import { OutOfStockReportDetailed, OutOfStockReportSummary } from './out-of-stock-report/index';
import ShareBrand from './share-report/ShareBrand';
import ShareReport from './share-report/ShareReport';
import ShippingCostReport from './shipping-cost-report/indexContainer';

class ReportView extends Component {

    static propTypes = {
        selectedReport: PropTypes.string,
        selectedSubReport: PropTypes.string,
        reportChange: PropTypes.func,
        subReportChange: PropTypes.func,
        subReportReset: PropTypes.func,
        reportReset: PropTypes.func,
        resetAll: PropTypes.func,
        selectedView: PropTypes.string.isRequired,
        fetchProductItems: PropTypes.func.isRequired,
        brands: PropTypes.array.isRequired,
        dispatch: PropTypes.func.isRequired,
        modules: PropTypes.array,
        configuration: PropTypes.object
    };

    constructor(props) {
        super(props)
        this.state = {};

        this.toggle = this.toggle.bind(this);
        this.getOutOfStockParams = this.getOutOfStockParams.bind(this);
        this.fetchProductItems = this.fetchProductItems.bind(this);
    }

    getOutOfStockParams() {
        let params = {};
        let myBrandIds = RetailCompassStorage.getBrandIds();
        let brandItems = this.props.brands.filter(item => myBrandIds.includes(item.brandId));
        params.brands = brandItems;
        params[Constantes.URL_PARAMS.SUBREPORT] = Constantes.TYPE_REPORT_RETAIL.OUT_OF_STOCK;
        return params;
    }

    toggle(selectedReportTab) {
        if (this.props.selectedReport !== selectedReportTab) {
            this.props.reportChange(selectedReportTab);
            this.props.subReportChange(undefined);
            let searchParams = { ...this.props.currentFilter };
            searchParams[Constantes.URL_PARAMS.REPORT] = selectedReportTab;
            searchParams[Constantes.URL_PARAMS.SUBREPORT] = undefined;

            // every time a user enters the report, their brands will automatically be loaded
            if (selectedReportTab === Constantes.TYPE_REPORT_RETAIL.OUT_OF_STOCK) {
                searchParams = { ...searchParams, ...this.getOutOfStockParams() }
                this.props.dispatch(change(Constantes.FORM_NAMES.FILTERS_FORM, Constantes.FIELD_NAMES.BRANDS, uniqBy(searchParams.brands, 'brandId')));
            }

            const resetState = [
                this.props.selectedReport,
                selectedReportTab,
            ].includes(Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST);
            
            this.fetchProductItems(searchParams, resetState);
        }
    }

    fetchProductItems(params, resetState = false) {
        /* resetear el estado implica volver a hacer la petición inicial
        a product-search, pero es la única forma de asignar correctamente
        los competidores al cambiar entre pricing/shipping */
        if (resetState) this.props.resetStoreForShipping();
        
        this.props.fetchProductItems(params);
    }

    render() {
        const isActiveShipping = ModuleUtils.isActiveShippingModule(this.props.configuration, this.props.modules); 
        return (
            <Col md={12} className="report-tabs brands">
                <Card>
                    <CardBody>
                        <div className="tabs tabs--bordered-top">
                            <div className="tabs__wrap">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.props.selectedReport === Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF })}
                                            onClick={() => { this.toggle(Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF); }}>
                                            Share of shelf
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.props.selectedReport === Constantes.TYPE_REPORT_RETAIL.OUT_OF_STOCK })}
                                            onClick={() => { this.toggle(Constantes.TYPE_REPORT_RETAIL.OUT_OF_STOCK); }}>
                                            Sin stock
                                        </NavLink>
                                    </NavItem>
                                    {isActiveShipping &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.props.selectedReport === Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST })}
                                            onClick={() => { this.toggle(Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST); }}>
                                            Costo shipping
                                        </NavLink>
                                    </NavItem>
                                    }
                                </Nav>

                                <TabContent className="tab-content-reports" activeTab={this.props.selectedReport}>
                                    <TabPane tabId={Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF}>
                                        {/* {this.props.productListSummaryView} */}
                                        <Card className="tab-report container">
                                            <CardBody>
                                                <Row style={{ marginTop: 20 }}>
                                                    <Col className="haveit-dont-haveit-sub-report" md={12} sm={12} lg={6} >
                                                        <ShareBrand
                                                            currentReport={Constantes.TYPE_REPORT_BRAND.SHARE_BY_BRAND}
                                                            title='Share por marca'
                                                            containerClassName='share-by-retail-containter'
                                                            subtitle='Cantidad de productos por marca' />
                                                    </Col>
                                                    <Col className="haveit-dont-haveit-sub-report" md={12} sm={12} lg={6} >
                                                        <ShareReport
                                                            currentReport={Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL}
                                                            title='Share por retailer'
                                                            containerClassName='share-by-retail-containter'
                                                            subtitle='Tus productos en cada retailer' />
                                                    </Col>
                                                </Row>
                                                {/* <Row style={{ marginTop: 48 }}>
                                                    
                                                </Row> */}

                                                <Row style={{ marginTop: 40 }}>
                                                    <Col className="haveit-dont-haveit-sub-report" md={12} sm={12} lg={6} >
                                                        <ShareReport
                                                            currentReport={Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE}
                                                            title='Share por menor precio'
                                                            containerClassName='share-by-lower-prices-containter'
                                                            subtitle='Tus productos por menor precio' />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                    <TabPane tabId={Constantes.TYPE_REPORT_RETAIL.OUT_OF_STOCK} className="out-of-stock-tab-pane">

                                        <Card className="tab-report container">
                                            <CardBody>
                                                <Row style={{ marginTop: 20 }}>
                                                    <Col className="out-of-stock-detailed-sub-report" md={12} sm={12} >
                                                        <OutOfStockReportSummary />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <Card className="tab-report container">
                                            <CardBody>
                                                <Row style={{ marginTop: 40 }}>
                                                    <Col className="out-of-stock-detailed-sub-report" md={12} sm={12} >
                                                        <OutOfStockReportDetailed />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                    <TabPane tabId={Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST} className="shipping-cost-tab-pane">
                                        <Card className="tab-report container">
                                            <CardBody>
                                                <Row style={{ marginTop: 20 }}>
                                                    <Col className="shipping-cost-report" md={12} sm={12} >
                                                        <ShippingCostReport /> 
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default withRouter(ReportView);
