import { connect } from 'react-redux';
import ProductListBrandView from './index';
import { ProductListContainer } from '@common/components/';

export const mapStateToProps = (state, ownProps) => {
    let commonProps = ProductListContainer.mapStateToProps(state, ownProps);
    return {
        ...commonProps,
        selectedProducts: state.labelStateReducer.newLabel.selectedProducts
    }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    let commonFuncts = ProductListContainer.mapDispatchToProps(dispatch, ownProps);
    return {
        ...commonFuncts
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListBrandView);
