
import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { RetailCompassStorage, Constantes, Utils } from "@common/utils/"
import PropTypes from 'prop-types';
import { find, uniqBy, map } from 'lodash';
import InteractiveBarCounter from '@common/components/src/ui/InteractiveBarCounter';
import { change } from 'redux-form';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';

/**
 * El componente consta de 4 parametros
 * @param label Obligatorio, describe la etiqueta que tendra de titulo el componente
 * @param values Obligatorio, arreglo de los valores que devuelve el servicio sin ningun filtro que lo afecte
 * @param valuesFiltered No obligatorio, arreglo de los valores que devuelve el servicio afectado por los filtros
 * @param brandsFiltered No obligatorio, arreglo de los ids de las marcas filtradas
 * 
 * Los atributos values y valuesFiltered deben tener la siguiente estructura que es la que devuelve el servicio:
 *  @see values = [ { 'brandId': 55, 'brandName': 'Samsung','count': 24 } ]
 */
export default class SummaryBrandView extends Component {

    static propTypes = {
        label: PropTypes.string.isRequired,
        values: PropTypes.array.isRequired,
        valuesFiltered: PropTypes.array,
        brandsFiltered: PropTypes.array,
        isOnlyFilterBrand: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool,
        summariesConfig: PropTypes.object
    };

    static defaultProps = {
        summariesConfig: { summary1: {}, summary2: {} }
    }

    constructor(props) {
        super(props);
        this.state = { dimensions: {} };

        this.fetchProductItems = this.fetchProductItems.bind(this);
        this.formatOtherBrandObjs = this.formatOtherBrandObjs.bind(this);
        this.onlyYoursOnClick = this.onlyYoursOnClick.bind(this);
        this.isFilteringByBrands = this.isFilteringByBrands.bind(this);
        this.getShareOfBrandsData = this.getShareOfBrandsData.bind(this);
        this.requestResults = this.requestResults.bind(this);
        this.configureResizeSensor = this.configureResizeSensor.bind(this);

        this.container = null;
        this.resizeSensorInstance = null;

        this.setContainerRef = element => {
            this.container = element;
            this.configureResizeSensor();
        };
    }

    componentWillUnmount() {
        if (this.resizeSensorInstance) {
            this.resizeSensorInstance.detach();
        }
    }

    fetchProductItems(params) {
        let currentFilter = { ...this.props.currentFilter, ...params };
        currentFilter.paginationCurrentPage = 1;
        this.props.fetchProductItems(currentFilter);
    }

    getShareOfBrandsData() {
        let data = this.props.values;
        let valuesFiltered = this.props.valuesFiltered;
        let counterItems = [];
        let total = 0, myTotal = 0, showProportions = false;
        let myBrands = RetailCompassStorage.getBrandIds();
        data.forEach((item) => {

            let filtered;
            if (valuesFiltered.length === 0) {
                filtered = { count: item.count };
            } else {
                filtered = valuesFiltered.length > 0 ? find(valuesFiltered, ['brandId', item.brandId]) : { count: item.count }
            }
            let counterObj = {
                id: item.brandId,
                value: item.count,
                filteredValue: filtered ? filtered.count : 0,
                label: item.brandName,
                onClick: (obj) => {
                    let currentFilter = { ...this.props.currentFilter };
                    currentFilter.brands = currentFilter.brands || [];
                    const originalBrand = this.props.values.find((valItem) => valItem.value === item.value);
                    if (originalBrand) {
                        currentFilter.brands = currentFilter.brands.concat(originalBrand);
                    }
                    this.requestResults(currentFilter);
                },
                onRemoveClick: (obj) => {
                    let currentFilter = { ...this.props.currentFilter };
                    currentFilter.brands = currentFilter.brands || [];
                    currentFilter.brands = currentFilter.brands.filter((valItem) => valItem.value !== item.value);
                    this.requestResults(currentFilter);
                }
            };
            counterItems.push(counterObj);
            if (!showProportions && counterObj.filteredValue != counterObj.value) {
                showProportions = true;
            }
            total += filtered ? filtered.count : 0;
            if (myBrands.indexOf(item.brandId) !== -1) {
                myTotal += filtered ? filtered.count : 0;
            }
        });

        return {
            summary1: {
                value: total
            },
            summary2: {
                value: myTotal
            },
            counterItems: counterItems,
            myItemIds: myBrands,
            total: total,
            showProportions: showProportions
        }
    }

    requestResults(currentFilter) {
        currentFilter.brands = uniqBy(currentFilter.brands, 'brandId');
        this.props.dispatch(change(Constantes.FORM_NAMES.FILTERS_FORM, 'brands', currentFilter.brands));
        this.fetchProductItems({ ...currentFilter });
    }

    formatOtherBrandObjs(objs) {
        return map(objs, function (obj) {
            return {
                brandId: obj.id,
                value: obj.id + "",
                label: obj.label
            }
        });
    }

    isFilteringByBrands() {
        let brandsIncurrentFilter = this.props.currentFilter.brands || [];
        if (brandsIncurrentFilter.length === 0) {
            return false;
        }
        return true;
    }

    onlyYoursOnClick() {
        let { summariesConfig } = this.props;
        const myBrands = RetailCompassStorage.getBrandIds();
        if (!this.isFilteringByBrands() && Utils.isArrayWithData(myBrands)) {
            let currentFilter = { ...this.props.currentFilter, brands: myBrands };
            currentFilter.brands = this.props.values.filter((valItem) => {
                let myBrandItemFound = myBrands.find((myBrandValue) => valItem.brandId + '' === myBrandValue + '');
                if (myBrandItemFound) {
                    return true;
                }
                return false;
            });

            if (summariesConfig.summary2 && summariesConfig.summary2.onClick) {
                summariesConfig.summary2.onClick(currentFilter);
            } else {
                this.requestResults(currentFilter);
            }
        }
    }

    configureResizeSensor() {
        let counterGroupContainerElement = this.container;
        if (counterGroupContainerElement) {
            this.resizeSensorInstance = new ResizeSensor(counterGroupContainerElement, () => {
                this.setState({
                    dimensions: {
                        width: counterGroupContainerElement.offsetWidth,
                        height: counterGroupContainerElement.offsetHeight,
                    },
                });
            });
        }
    }

    calculateTotalOfBrandsToShow() {
        const MAX_BRANDS_TO_SHOW = 16;
        const ALL_AND_ONLY_YOURS_WIDTH = 120;
        const BRAND_BRANDS_WIDTH = 58;

        let totalChars = MAX_BRANDS_TO_SHOW;
        if (this.state.dimensions.width > 0) {
            totalChars = Math.floor((this.state.dimensions.width - ALL_AND_ONLY_YOURS_WIDTH) / BRAND_BRANDS_WIDTH);
        }
        if (totalChars > MAX_BRANDS_TO_SHOW) {
            totalChars = MAX_BRANDS_TO_SHOW;
        }
        return totalChars;
    }

    render() {
        const { values, summariesConfig } = this.props;

        if (!Utils.isArrayWithData(values)) {
            return null;
        }

        let shareOfBrands = this.getShareOfBrandsData();
        let totalOfBrandsToShow = this.calculateTotalOfBrandsToShow();
        
        return (
            <div className="counter-group-container brand" ref={this.setContainerRef}>
                <Row className="counter-group-item">
                    <InteractiveBarCounter
                        title={"Share por marca"}
                        summary1={{
                            value: shareOfBrands.summary1.value,
                            legend: "Todos",
                            url: "",
                            isLocked: true,
                            onClick: () => { },
                            ...summariesConfig.summary1
                        }}
                        summary2={{
                            value: shareOfBrands.summary2.value,
                            legend: "Solo tuyos",
                            url: "",
                            onClick: this.onlyYoursOnClick,
                            isLocked: !(!this.isFilteringByBrands() && Utils.isArrayWithData(RetailCompassStorage.getBrandIds())),
                            ...summariesConfig.summary2
                        }}
                        counterItems={shareOfBrands.counterItems}
                        myItemsIds={shareOfBrands.myItemIds}
                        maxBars={totalOfBrandsToShow}
                        total={shareOfBrands.total}
                        onOthersClick={(objs) => {
                            let currentFilter = { ...this.props.currentFilter };
                            let formattedObjs = this.formatOtherBrandObjs(objs);
                            currentFilter.brands = currentFilter.brands ? [].concat(formattedObjs, currentFilter.brands) : formattedObjs;
                            currentFilter.brands = uniqBy(currentFilter.brands, 'brandId');
                            this.requestResults(currentFilter);
                        }}
                        onOthersRemoveClick={(objs) => {
                            let currentFilter = { ...this.props.currentFilter };
                            let objsIds = map(objs, (item) => item.id);
                            currentFilter.brands = currentFilter.brands || [];
                            currentFilter.brands = currentFilter.brands.filter((valItem) => !objsIds.includes(valItem.brandId));
                            this.requestResults(currentFilter);
                        }}
                        othersLabel={"Otras"}
                        selectedItemsIds={this.props.brandsFiltered || []}
                        showProportions={shareOfBrands.showProportions}
                        removeAllText={"Quitar filtro"}
                        onRemoveAllClick={() => {
                            let currentFilter = { ...this.props.currentFilter };
                            currentFilter.brands = [];
                            this.requestResults(currentFilter);
                        }}
                    />
                </Row>
            </div>);
    }
}
