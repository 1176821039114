import React from 'react';
import PropTypes from 'prop-types';

export const RangeText = (props) => {
    return <div className={'range-item ' + (props.containerClass || '')}>
        <span className={"icon-" + props.iconClass}>●</span>
        <span className="text">{props.text}</span>
    </div >;
}

RangeText.propTypes = {
    iconClass: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default RangeText;