import * as ShippingActions from './ShippingActions';

export const initialState = {
    currentShipping: null,
    shippingCommunes: [],
    costType: null,
    error: null
};

export const onSelectCostType = (dispatch, selected) => {
    dispatch(ShippingActions.selectionCostType(selected));
}

export const ShippingStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {
        case ShippingActions.REQUEST_SHIPPING_COMMUNES:
            return Object.assign({}, state, { isFetching: true, shippingCommunes: [], error: null });
        case ShippingActions.RECEIVE_SHIPPING_COMMUNES:
            let data = action.payload.data;
            return Object.assign({}, state, { isFetching: false, error: null, shippingCommunes: data });
        case ShippingActions.SELECTION_COST_TYPE:
            return Object.assign({}, state, { costType: action.payload.costType });
        case ShippingActions.RECEIVE_SHIPPING_COMMUNES_ERROR:
            return Object.assign({}, state, { isFetching: false, shippingCommunes: [], error: action.payload.error });
        default:
            return state;
    }
};