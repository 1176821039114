import { connect } from 'react-redux';
import ProductSelectionSummary from './ProductSelectionSummary';

export const mapStateToProps = (state, ownProps) => {
    return {
        totalSelectedProducts: state.labelStateReducer.newLabel.selectedProducts.length,
        selectedProducts: state.labelStateReducer.newLabel.selectedProducts,
        productItems: state.productListBrandReducer.productItems || [],
    };
};

 export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelectionSummary);