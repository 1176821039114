/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class constantes.js
 * @date 13 mar. 2018
 */

export const ModuleType = {
    PRICING: "pricing"
};

export const UserType = {
    ADMIN: "admin",
    CLIENT: "customer",
    CLIENT_ADMIN: "client_admin"
};

export const StoreType = {
    RETAILER: "RETAILER",
    BRAND: "PROVIDER",
    SHIPPING_RETAILER: "SHIPPING_RETAILER",
    SHIPPING_PROVIDER: "SHIPPING_PROVIDER"
};

export const SubStoreType = {
    RETAILER_POSTPAID: "RETAILER_POSTPAID",
    BRAND_POSTPAID: "PROVIDER_POSTPAID"
};

export const MODULES = {
    RETAIL_APP: "RETAIL_APP",
    BRAND_APP: "BRAND_APP",
    VALIDATION_APP: "VALIDATION_APP"
};

export const FILTER_SHIPPING_OPTIONS = {
    SHIPPING_TYPE: "SHIPPING_TYPE",
    COMMUNES: "COMMUNES",
    SEND: "SEND"
}

export const SHIPPING_STATUS = {
    HAS_SHIPPING: "HAS_SHIPPING",
    HAS_NOT_SHIPPING: "HAS_NOT_SHIPPING",
    ERROR: "ERROR",
    PRODUCT_NOT_FOUND: "PRODUCT_NOT_FOUND"
}

export const SHIPPING_TYPE = {
    COST: "COST",
    SHIPMENT: "SHIPMENT"
}

export const HOST = {
    LOCALHOST: "localhost"
};

export const FILE_TYPES = {
    EXCEL: ".xls",
    EXCEL_XLSX: ".xlsx"
};

export const PaymentType = {
    CARD: "T",
    NORMAL: "N"
};

export const PriceType = {
    CARD: "price",
    OFFER: "offerPrice",
    NORMAL: "normalPrice",
    MIN_PRICE: "minPrice"
};

export const STATUS_PRODUCT = {
    WITH_STOCK: 1,
    WITHOUT_STOCK: -1,
    FOR_APPROVAL: -6
};

export const PRODUCT_AVAILABILITY = {
    ONLY_I_HAVE: "1",
    ONLY_I_NOT_HAVE: "-1",
    ALL: "0"
};

export const PRODUCT_AVAILABILITY_DEFAULT = PRODUCT_AVAILABILITY.ALL;

export const MAXIMUM_NUMBER_OF_WORDS_ALLOWED = 7;
export const APP_NAME = "SCALIBUR";

export const ALERT_PRICE_CHANGE_MAX_VALUE = 9999.99;

export const SUGGESTED_ATTRIBUTES_USER_ID = 8;

export const INVALID_ID = -2147483648;

export const MATCHES = {
    CONTAINED: "CONTAINED",
    NOT_CONTAINED: "NOT_CONTAINED",
    ALL: "ALL"
};

export const COMPARISON = {
    EXPENSIVE: "EXPENSIVE",
    CHEAPER: "CHEAPER",
    SAME: "SAME"
};

export const LABEL_FORM_REGEX_VALIDATION = {
    IS_HTML_TAG: /<[a-z/][\s\S]*>/i
};

export const ALERT_TYPE = {
    PRICE_CHANGE: "PRICE_CHANGE",
    STOCK_CHANGE: "STOCK_CHANGE"
};
export const URL_PARAMS = {
    SELECTED_VIEW: "selectedView",
    REPORT: "report",
    SUBREPORT: "subReport",
    SEARCH_TYPE: "searchType",
    HAVE_IT: "haveIt",
    DONT_HAVE_IT: "dontHaveIt",
    SAME: "same",
    CHEAPER: "cheaper",
    EXPENSIVE: "expensive",
    SHIPPING_TYPE: "shippingType",
    COMMUNE: "commune",
    SEND_COST: "sendCost",
    SEND_SHIPMENT: "sendShipment",
};
export const ALERT_SUBTYPE = {
    [ALERT_TYPE.PRICE_CHANGE]: {
        PRICE_INCREASE: "PRICE_INCREASE",
        PRICE_DECREASE: "PRICE_DECREASE"
    },
    [ALERT_TYPE.STOCK_CHANGE]: {
        WITH_STOCK: "WITH_STOCK",
        OUT_STOCK: "OUT_STOCK"
    }
};

export const PRODUCT_ACCESS_APPS = {
    PHOENIX: 1,
    SCALIBUR: 2
};

export const ATTRIBUTES = {
    SCREEN_SIZES_INCHES: 18,
    STORAGE: 75
};

export const EMPTY_STATE_TYPE = {
    GENERIC: "GENERIC",
    WITH_ACTIVE_FILTERS: "WITH_ACTIVE_FILTERS",
    WITH_ACTIVE_FILTERS_FAVORITES_CANDIDATE:
        "WITH_ACTIVE_FILTERS_FAVORITES_CANDIDATE",
    WITH_ACTIVE_FAVORITES: "WITH_ACTIVE_FAVORITES",
    SERVER_ERROR: "SERVER_ERROR"
};

export const DEFAULT_PRODUCT_LIST_FILTER = {
    productAvailability: PRODUCT_AVAILABILITY_DEFAULT,
    paginationCurrentPage: 1
};

export const FORM_NAMES = {
    FILTERS_FORM: "filtersForm",
    PRODUCT_LIST_MAIN_FILTERS_FORM: "productListMainFiltersForm",
    NEW_COLOR_FORM: "newColorForm"
};

export const SearchType = {
    SKUS: "SKUS",
    KEYWORDS: "KEYWORDS",
    GENERIC: "GENERIC",
    NONE: "NONE",
    PAGINATION: "PAGINATION",
    SORT: "SORT",
    MODELS: "MODELS"
};

export const SortKeys = {
    CREATED_DATE: "name",
    PRODUCT_RELEVANCE: "productRevelance",
    PRICE: "price",
    LOWER_PRICE: "minPrices",
    DPM: "dpm",
    HIGHER_PRICE: "maxPrices",
    AVERAGE_PRICE_BRAND: "averagePriceBrand",
    MINIMUM_PRICE: "minimumPrice",
    RANKING_POSITION: "ranking_position"
};
export const AttributeType = {
    RANGE: "range",
    STRING: "string",
    NUMBER: "number"
};

export const SortValues = {
    CREATED_DATE: "CREATED_DATE",
    PRODUCT_RELEVANCE: "PRODUCT_RELEVANCE",
    SHIPPING_RELEVANCE: "SHIPPING_RELEVANCE",
    LOWER_PRICE: "COMPETITOR_LOWER_PRICE",
    HIGHER_PRICE: "COMPETITOR_HIGHER_PRICE",
    DPM: "DPM_MATCHES",
    PRICE: "COMPETITOR",
    AVERAGE_PRICE_BRAND: "AVERAGE_PRICE_BRAND",
    MINIMUM_PRICE: "MINIMUM_PRICE",
    RANKING_POSITION: "RANKING_POSITION"
};

export const KeyWordsSearchStrategy = {
    LIKE: "LIKE",
    TEXT_MATCH: "TEXT_MATCH"
};

export const DEFAULT_SORT_BRAND = {
    sortKey: SortKeys.PRODUCT_RELEVANCE,
    sortDirection: "DESC"
};

export const SORT_TYPE = {
    ASC: "ASC",
    DESC: "DESC",
    NONE: "NONE"
};

export const GA_CATEGORY_EVENTS = {
    DASHBOARD: "Dashboard",
    LINK_OUT: "link_out",
    DOWNLOAD_SHEET: "Download_sheet",
    FAVORITES: "Favorite",
    COMPARE: "Compare",
    PRODUCT_PAGE: "Product_page",
    PRODUCT_LIST: "Product_list",
    UNIFIED_MENU: "UNIFIED_MENU",
    RANKING: "RANKING",
    PERFOMANCE: "PERFOMANCE"
};

export const GA_ACTION_EVENTS = {
    OUT_OF_STOCK: "Out_of_stock",
    BRAND_SHARE: "Brand_share",
    RETAIL_SHARE: "Retail_share",
    PRICE_SHARE: "Price_share",
    EXCEL: "Excel",
    ADD_FAVORITE: "Add_favorite",
    DELETE_FAVORITE: "delete_favorite",
    PRICES: "Prices",
    COMPARE: {
        VIEW: "View",
        DATES: "Dates",
        CARD_PRICES: "Card_prices",
        PIVOT: "Pivot",
        NO_PRODUCTS: "No_products"
    },
    RANKING_SWITCH_CLICK: "RANKING_SWITCH_CLICK",
    RANKING_TOOLTIP_CLICK: "RANKING_TOOLTIP_CLICK",
    LOAD_TIME_SERVICE: "LOAD_TIME_SERVICE",
    LOAD_TIME_INIT: "LOAD_TIME_INIT",
};

export const GA_LABELS_EVENTS = {
    COMPARE: {
        BEST_PRICE: "Best_price",
        AVG_PRICE: "Avg_price",
        MANUAL: "Manual",
        BUTTON: "Button",
        ON: "On",
        OFF: "Off"
    },
    PRODUCT_LIST: {
        ALL: "all",
        OFFER_AND_NORMAL: "offer&normal",
        NORMAL: "normal"
    },
    MENU_BAR_LEFT: "MENU_BAR_LEFT"
};

export const LABEL_COLORS = [
    { className: "rc-light-blue", color: "#aecbfa", hover: "#c7dcfe" },
    { className: "rc-light-purple", color: "#d7aefb", hover: "#e6ccfe" },
    { className: "rc-light-green", color: "#CAF790", hover: "#DBFFAD" },
    { className: "rc-light-pink", color: "#FDD4F0", hover: "#FEE5F6" },
    { className: "rc-light-red", color: "#FB8F84", hover: "#FCB8B0" },
    { className: "rc-light-lily", color: "#E3D7FE", hover: "#EEE6FF" },
    { className: "rc-green", color: "#6CD793", hover: "#9CDEB4" },
    { className: "rc-yellow", color: "#FFF475", hover: "#FFF8A8" },
    { className: "rc-blue", color: "#98D6E4", hover: "#A5EAF8" },
    { className: "rc-light-yellow", color: "#FDEDC1", hover: "#FEF4DA" },
    { className: "rc-cyan", color: "#A5FAEB", hover: "#C0FCF1" },
    { className: "rc-orange", color: "#F7BC33", hover: "#FDD575" },
    { className: "rc-sky-blue", color: "#CBF0F8", hover: "#DBF8FE" },
    { className: "rc-dark-pink", color: "#EE90B2", hover: "#FBA1C3" },
    { className: "rc-beige", color: "#FADDB4", hover: "#FEE9CA" }
];

export const COUNTRIES_CODE = {
    ARGENTINA: "AR",
    BRASIL: "BR",
    CHILE: "CL",
    COLOMBIA: "CO",
    MEXICO: "MX",
    PERU: "PE"
};

export const AGGREGATIONS_NAMES = {
    COMPETITOR: "COMPETITOR",
    PRICE_RANGES_MATCHES: "PRICE_RANGES_MATCHES",
    BRAND_SHARE_CANONICAL: "BRAND_SHARE_CANONICAL",
    EXPENSIVE_CHEAPER_SAME: "EXPENSIVE_CHEAPER_SAME",
    HAVE_IT: "HAVE_IT",
    HAVE_IT_MATCHES: "HAVE_IT_MATCHES",
    COMPARISON_PRICE_BY_STORE: "COMPARISON_PRICE_BY_STORE",
    HAVE_IT_RANGE_PRICE: "HAVE_IT_RANGE_PRICE",
    COMPARISON_PRICE_BY_RANGE: "COMPARISON_PRICE_BY_RANGE",
    COMPARISON_PRICE_BY_BRAND: "COMPARISON_PRICE_BY_BRAND",
    HAVE_IT_BRAND: "HAVE_IT_BRAND",
    SHARED_MATCHES: "SHARED_MATCHES",
    MATCHES_BY_STORE: "MATCHES_BY_STORE",
    RANGES_PROMOTIONAL: "RANGES_PROMOTIONAL",
    OUT_OF_STOCK_RANGES_MATCHES: "OUT_OF_STOCK_RANGES_MATCHES",
    OUT_OF_STOCK_RANGES_BRAND_BY_RETAILER:
        "OUT_OF_STOCK_RANGES_BRAND_BY_RETAILER",
    MATCHES_BY_OPERATOR: "MATCHES_BY_OPERATOR",
    POSTPAID_SEARCH_OPTIONS: "POSTPAID_SEARCH_OPTIONS",
    COMMUNE_INTO_MATCHES:  "COMMUNE_INTO_MATCHES",
    SHIPPING_BY_COMMUNE: "SHIPPING_BY_COMMUNE",
    SHIPPING_COST_BY_RETAIL: "SHIPPING_COST_BY_RETAIL",
};

export const TYPE_VIEW_RETAIL = {
    REPORT: "REPORT",
    PRODUCT_LIST: "PRODUCT_LIST",
    SHIPPING: "SHIPPING",
    DEFAULT: "PRODUCT_LIST"
};

export const TYPE_REPORT_RETAIL = {
    CRACK: "CRACK_STOCK",
    COMPETITIVENESS: "COMPETITIVENESS",
    RETAIL: "RETAIL",
    BRANDS: "BRANDS",
    LOWER_PRICE: "LOWER_PRICE",
    DISCOUNTS: "DISCOUNTS",
    OUT_OF_STOCK: "OUT_OF_STOCK",
    SHARE_OF_SHELF: "SHARE_OF_SHELF",
    SHIPPING_COST: "SHIPPING_COST",
};

export const TYPE_SHIPPING_REPORT = {
    COST_PROM: "COST_PROM",
    ALL: "ALL",
    COST_FREE: "COST_FREE",
};

export const TYPE_REPORT_BRAND = {
    SHARE_BY_RETAIL: "SHARE_BY_RETAIL",
    SHARE_BY_LOWER_PRICE: "SHARE_BY_LOWER_PRICE",
    SHARE_BY_BRAND: "SHARE_BY_BRAND"
};

export const RETAIL_VIEW_REPORT = {
    CRACK: TYPE_REPORT_RETAIL.CRACK,
    COMPETITIVENESS: TYPE_REPORT_RETAIL.COMPETITIVENESS,
    DEFAULT: TYPE_REPORT_RETAIL.COMPETITIVENESS
};

export const RETAIL_DISCOUNTS_REPORT = {
    ALL: "ALL_PRICES",
    CARD_PRICE: "CARD_PRICE",
    SALE_PRICE: "SALE_PRICE"
};

export const PROMOTIONAL_TYPE = {
    ALL: "ALL",
    CARD: "CARD",
    OFFER: "OFFER"
};

export const RETAIL_VIEW_SUBREPORT = {
    //reportes de competitividad
    [RETAIL_VIEW_REPORT.COMPETITIVENESS]: {
        RETAIL: TYPE_REPORT_RETAIL.RETAIL,
        BRANDS: TYPE_REPORT_RETAIL.BRANDS,
        LOWER_PRICE: TYPE_REPORT_RETAIL.LOWER_PRICE,
        DEFAULT: TYPE_REPORT_RETAIL.RETAIL
    },
    //reportes de quiebres de stock
    [RETAIL_VIEW_REPORT.CRACK]: {}
};

export const LOCALES = {
    GERMANY: "de-DE"
};
export const USER_TYPE_CODE = {
    ADMIN: "ADMIN",
    CUSTOMER: "CUSTOMER",
    USER: "USER",
    CLIENT_ADMIN: "CLIENT_ADMIN",
    VALIDATOR: "VALIDATOR"
};

export const CLIENT_SUCCESS = {
    ACCEPTED_USERS: [
        USER_TYPE_CODE.USER,
        USER_TYPE_CODE.CLIENT_ADMIN,
        USER_TYPE_CODE.CUSTOMER
    ],
    MAP: {
        LOGIN_SUCCESS: "loginSuccess",
        API_REQUEST: "apiRequest"
    }
};

export const searchFields = {
    main: "search",
    skus: "skus",
    keyWords: "keyWords",
    searchType: "searchType",
    models: "models"
};

export const DATE_FORMATS = {
    DAY_MONTH: "DD MMMM",
    DEFAULT_FORMAT: "YYYY-MM-DD",
    MATCH_UPDATED_FORMAT: "DD-MM-YYYY hh:mm:ss",
    DAY_MONTH_YEAR: "DD/MM/YYYY"
};

export const HISTORY_SERVICE_FIELDS = {
    HISTORY_INTERVAL: "HISTORY_INTERVAL"
};

export const COMPARER_TYPE = {
    MIN_PRICE: "MIN_PRICE",
    AVG_PRICE: "AVG_PRICE",
    RETAILERS: "RETAILERS"
};

export const COMPARER_CALLBACK_TYPE = {
    RANGE: "RANGE",
    INCLUDE_CARD_PRICES: "INCLUDE_CARD_PRICES"
};

export const COMPARER_CONFIG = {
    WEEKS_DISPLAY: 2
};

export const SELLERTYPE_FILTER = {
    MARKETPLACE: "MARKETPLACE",
    DIRECT: "DIRECT"
};

export const MKP_PREFIX = "MKP";

export const FIELD_NAME_MKP_FILTER = "sellerType";

export const FIELD_NAME_RANKING_FILTER = "rankingFilter";
export const DEFAULT_RANKING_FILTER_ITEMS = [
    { label: "TOP 5", value: 5 },
    { label: "TOP 10", value: 10 },
    { label: "TOP 30", value: 30 }
];

export const FIELD_NAMES = {
    OUT_OF_STOCK_RANGES: "outOfStockRanges",
    BRANDS: "brands",
    ONLY_AVAILABLE_PRODUCTS: "onlyAvailableProducts",
    CONTRACT_TYPE: "contractType",
    STORES: "stores",
};

export const REPORT_KEYS = {
    HAVE_IT: "haveIt",
    DONT_HAVE_IT: "dontHaveIt"
};

export const EXTERNAL_SEARCH_URL = {
    GOOGLE: "http://www.google.com/search"
};

export const SEARCH_TERMS = {
    SPECS: "Especificaciones"
};

export const PRODUCT_FIELDS = {
    BRAND: "brand",
    NAME: "name",
    MODEL: "model"
};

export const KEYBOARD = {
    KEYS: {
        ENTER: "Enter"
    }
};

export const MATCH_SOURCE_IDS = {
    VALIDATOR: 2,
    ORIGINAL: 6
};

export const MATCH_STATUS_IDS = {
    IDENTICAL: 3,
    REMOVED: -3,
    ALSO_REMOVED: -2
};

export const CONTEXT_INFO_KEYS = {
    USER_ID: "userId",
    USERNAME: "username",
    CANONICAL_STORE_ID: "canonicalStoreId",
    ROLE_NAME: "rolName",
    ROLE_ID: "rolId",
    TASK_NAME: "taskName"
};

export const MANDATORY_CONTEXT_INFO_KEYS = [
    CONTEXT_INFO_KEYS.USER_ID,
    CONTEXT_INFO_KEYS.USERNAME,
    CONTEXT_INFO_KEYS.CANONICAL_STORE_ID,
    CONTEXT_INFO_KEYS.ROLE_NAME
];

export const PRODUCERS = {
    SCALIBUR_VALIDATION: "SCALIBUR_VALIDATION"
};

export const MAX_HISTORY_TIME = {
    unit: "months",
    value: 14
};

export const IGNORE_MKP_SUFFIX_WHEN_CONTAINS = ["MERCADOLIBRE"];

export const HISTORIC_CONSUMER_TYPE = {
    COMPARER: "COMPARER",
    PRODUCT_DETAIL: "PRODUCT_DETAIL"
};

export const CANONICAL_CATEGORY_VISUAL_TYPE = {
    NORMAL: 1,
    POSTPAID: 2
};

export const TYPE_CONTRACTS = {
    PORTABILIDAD: { key: 2, label: "Portabilidad" },
    RENOVACION: { key: 3, label: "Renovación" },
    LINEA_NUEVA: { key: 1, label: "Línea nueva" }
};

export const TYPE_RANGE_PRICES = {
    CANONICAL_POSTPAID: "CANONICAL_POSTPAID",
    CANONICAL: "CANONICAL"
};

export const MATCH_ATTRIBUTES = {
    OPERATOR: 119,
    CONTRACT_TYPE: 120,
    COLOR: 121
};

export const FILTER_OPERATORS = "operators";

export const ATTRIBUTES_ID = {
    COLOR: 121
};

export const SUB_STORE_TYPE_BY_STORE_TYPE = {
    [StoreType.BRAND]: SubStoreType.BRAND_POSTPAID,
    [StoreType.RETAILER]: SubStoreType.RETAILER_POSTPAID
};

export const ICONS_EMPTY_STATES = {
    EMPTY_STATE_SVG: `${process.env.PUBLIC_URL}/img/ET_3.svg`,
    EMPTY_STATE_WITH_ACTIVE_FILTERS_SVG: `${process.env.PUBLIC_URL}/img/ET_2.svg`,
    EMPTY_STATE_WITH_ACTIVE_FAVORITE_SVG: `${process.env.PUBLIC_URL}/img/ET_4.svg`
};

export const COUNTRIES_WITHOUT_CENTS = [COUNTRIES_CODE.COLOMBIA];

export const CONSUMER = {
    SCALIBUR: "SCALIBUR",
    SCALIBUR_ALERTS: "SCALIBUR_ALERTS",
    SCALIBUR_SHIPPING: "SCALIBUR_SHIPPING",
    POST_PAID: "POST_PAID",
};

export const ACCESS_TYPES_LABEL = {
    PRIVATE: 1,
    PUBLIC: 2
};

export const ACCESS_TYPES_LABEL_STRING = {
    1: "PRIVATE",
    2: "PUBLIC"
};
