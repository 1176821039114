import { connect } from 'react-redux';
import Approval from './index';
import { AttributesState } from '@app/attributes';
import { StoreState } from "@common/components";
import {
    fetchProduct,
    getSuggestedMatchesByProductId,
    transformIntoIdentical,
    transformIntoRemoved,
    removeIdentical,
    searchPotentialMatches,
    createNewMatch,
    getAllMatchesByProductId,
    canonicalFindPotentialDuplicates,
    canonicalSearchByKeywords,
    canonicalTransformIntoRemoved,
    canonicalMerge,
    canonicalTransformIntoCanonical
} from "../validation/ValidationState";

import { discardPotentialDuplicateAction } from "../validation/ValidationActions";
//Layouts only

export const mapStateToProps = (state, ownProps) => {
    return {
        //general
        isFetching: state.validationStateReducer.isFetching,
        currentProduct: state.validationStateReducer.currentProduct,
        attributesData: state.attributesStateReducer.attributes || [],
        categoryInfo: state.attributesStateReducer.categoryInfo || [],
        storedAttributes: state.attributesStateReducer.storedAttributes,
        urlParams: ownProps.match.params,
        isTransformingTheMatch: state.validationStateReducer.isTransformingTheMatch,


        //step1
        canonicalIsTransformingIntoRemoved: state.validationStateReducer.canonicalIsTransformingIntoRemoved,

        //step2 & ahead
        potentialMatches: state.validationStateReducer.potentialMatches || [],
        allMatches: state.validationStateReducer.allMatches || [],
        competitors: state.storeStateReducer.competitors || [],
        suggestedMatches: state.validationStateReducer.suggestedMatches || [],
        potentialMatchesEmptyState: state.validationStateReducer.potentialMatchesEmptyState,
        isRemovingTheMatch: state.validationStateReducer.isRemovingTheMatch,

        //step3
        canonicalPotentialDuplicates: state.validationStateReducer.canonicalPotentialDuplicates,
        canonicalIsFindingPotentialDuplicates: state.validationStateReducer.canonicalIsFindingPotentialDuplicates,
        canonicalIsMerging: state.validationStateReducer.canonicalIsMerging,
        isFetchingSuggestedMatches: state.validationStateReducer.isFetchingSuggestedMatches,

        //step4
        canonicalSearchResult: state.validationStateReducer.canonicalSearchResult,
        canonicalIsSearchingByKeywords: state.validationStateReducer.canonicalIsSearchingByKeywords,
        canonicalSearchResultEmptyState: state.validationStateReducer.canonicalSearchResultEmptyState,

        //step4
        canonicalIsTransformingIntoCanonical: state.validationStateReducer.canonicalIsTransformingIntoCanonical

    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        fetchCategoryAttributes: (categoryId) => AttributesState.fetchCategoryAttributes(dispatch, categoryId),
        fetchProductAttributes: (productId) => AttributesState.fetchProductAttributes(dispatch, productId),
        fetchCategoryInfo: (categoryId) => AttributesState.fetchCategoryInfo(dispatch, categoryId),
        fetchProduct: (params) => fetchProduct(dispatch, params),

        //step2
        searchPotentialMatches: (matchInput) => searchPotentialMatches(dispatch, matchInput),
        createNewMatch: (payload) => createNewMatch(dispatch, payload),
        getStoreAndCompetitors: (storeId) => StoreState.getStoreAndCompetitors(dispatch, storeId),
        transformIntoIdentical: (params) => transformIntoIdentical(dispatch, params),
        transformIntoRemoved: (params) => transformIntoRemoved(dispatch, params),
        removeIdentical: (params) => removeIdentical(dispatch, params),
        getSuggestedMatchesByProductId: (productId) => getSuggestedMatchesByProductId(dispatch, productId),
        getAllMatchesByProductId: (productId) => getAllMatchesByProductId(dispatch, productId),


        //step1
        canonicalTransformIntoRemoved: (productId, jobId, userId) => canonicalTransformIntoRemoved(dispatch, productId, jobId, userId),

        //step3
        canonicalFindPotentialDuplicates: (competitorsIds, canonicalProductIdsToExclude) => canonicalFindPotentialDuplicates(dispatch, competitorsIds, canonicalProductIdsToExclude),
        canonicalMerge: (mergeCanonicalInput) => canonicalMerge(dispatch, mergeCanonicalInput),
        setDuplicateAsDiscarded: (duplicate) => dispatch(discardPotentialDuplicateAction(duplicate)),

        //step4
        canonicalSearchByKeywords: (storeId, categoryId, keywords, canonicalProductIdsToExclude) => canonicalSearchByKeywords(dispatch, storeId, categoryId, keywords, canonicalProductIdsToExclude),

        //step5
        saveProductAttribute: (payload) => AttributesState.saveProductAttribute(dispatch, payload),
        canonicalTransformIntoCanonical: (productId, jobId, userId) => canonicalTransformIntoCanonical(dispatch, productId, jobId, userId)

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Approval);
