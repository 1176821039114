import { createStore, applyMiddleware } from 'redux';
import rootReducer, { initialState } from './reducers';
import  middlewares from './middlewares';

const store = (window.devToolsExtension
  ? window.devToolsExtension()(createStore)
  : createStore)(rootReducer, initialState, applyMiddleware(
    ...middlewares,
  ));

export default store;
