import React from "react";
import {
    isArray,
    find,
    unionBy,
    uniqBy,
    sortBy,
    isEqual,
    some,
    filter,
    orderBy,
    includes,
    sumBy,
    get,
    remove,
    first,
    capitalize
} from "lodash";
import * as Constants from "./constantes";
import * as Utils from "./utils";
import * as RetailCompassStorage from "../app/Storage";
import * as OperatorUtils from "./OperatorUtils";
import * as GaUtils from "./gaUtils";
import * as IntercomConstants from "./intercomConstants";
import * as ChurnZero from "../scripts/churn-zero";
import * as Intercom from "../scripts/intercom";

export const isAvailable = product => {
    return (
        product &&
        (product.hasOwnProperty("status")
            ? product.status == Constants.STATUS_PRODUCT.WITH_STOCK
            : product.statusProduct == Constants.STATUS_PRODUCT.WITH_STOCK)
    );
};

export const setMinMaxAttributes = product => {
    /*  Agrego en el objeto de producto:
            minPrices (Array): Productos con precio mínimo
            maxPrices (Array): Productos con precio máximo
            pricesParams (Obj):
                mineIsCheapest (bool): Mi producto tiene precio mínimo
                mineIsMostExpensive (bool): Mi producto tiene precio máximo
                minPriceAvailable (bool): Al menos un producto con precio mínimo tiene stock
                maxPriceAvilable (bool): Al menos un producto con precio máximo tiene stock */

    var ownProduct = isAvailable(product.yourProduct)
        ? product.yourProduct
        : false;

    var products = filter(product.competitorsProducts, function(o) {
        return isAvailable(o);
    });
    var min = [],
        max = [],
        rowParams = {
            mineIsCheapest: false,
            mineIsMostExpensive: false,
            minPriceAvailable: false,
            maxPriceAvailable: false
        };

    switch (products.length) {
        case 0: // No hay competidores con este producto
            if (ownProduct) {
                min = [ownProduct];
                rowParams = Object.assign(rowParams, {
                    mineIsCheapest: true,
                    minPriceAvailable: true
                });
            }
            break;
        case 1: // Hay un competidor con este producto
            if (ownProduct) {
                if (ownProduct.price == products[0].price) {
                    min = [ownProduct, ...products];
                    rowParams = Object.assign(rowParams, {
                        mineIsCheapest: true,
                        minPriceAvailable:
                            isAvailable(ownProduct) || isAvailable(products[0])
                    });
                } else if (ownProduct.price > products[0].price) {
                    min = products;
                    max = [ownProduct];
                    rowParams = Object.assign(rowParams, {
                        minPriceAvailable: isAvailable(products[0]),
                        maxPriceAvailable: isAvailable(ownProduct),
                        mineIsMostExpensive: true
                    });
                } else {
                    max = products;
                    min = [ownProduct];
                    rowParams = Object.assign(rowParams, {
                        minPriceAvailable: isAvailable(ownProduct),
                        maxPriceAvailable: isAvailable(products[0]),
                        mineIsCheapest: true
                    });
                }
            } else {
                min = products;
                rowParams.minPriceAvailable = isAvailable(products[0]);
            }
            break;
        default:
            // Hay dos o más competidores con este producto
            var competitorsProducts = ownProduct
                ? [ownProduct, ...products]
                : products;
            competitorsProducts = sortBy(competitorsProducts, ["price"]);
            rowParams = Object.assign(rowParams, {
                minPriceAvailable: isAvailable(competitorsProducts[0]),
                maxPriceAvailable: isAvailable(
                    competitorsProducts[competitorsProducts.length - 1]
                )
            });
            //Inicializo con los valores minimos y maximos del array ordenado y lo recorro incluyendo los otros precios que sean iguales para cada uno
            min = [competitorsProducts[0]];
            max =
                competitorsProducts[competitorsProducts.length - 1].price >
                competitorsProducts[0].price
                    ? [competitorsProducts[competitorsProducts.length - 1]]
                    : max;

            for (
                let i = 1, j = competitorsProducts.length - 2;
                i < competitorsProducts.length;
                i++, j--
            ) {
                if (competitorsProducts[i].price == min[0].price) {
                    min.push(competitorsProducts[i]);
                    rowParams.minPriceAvailable =
                        isAvailable(competitorsProducts[i]) ||
                        rowParams.minPriceAvailable;
                }
                if (
                    max.length > 0 &&
                    competitorsProducts[j].price == max[0].price
                ) {
                    max.push(competitorsProducts[j]);
                    rowParams.maxPriceAvailable =
                        isAvailable(competitorsProducts[j]) ||
                        rowParams.maxPriceAvailable;
                }
            }
            if (ownProduct) {
                rowParams.mineIsCheapest =
                    min.length > 0 && ownProduct.price == min[0].price;
                rowParams.mineIsMostExpensive =
                    max.length > 0 && ownProduct.price == max[0].price;
            }
            break;
    }
    product.minPrices = min;
    product.maxPrices = max;
    product.pricesParams = rowParams;
};

export const buildMatchesFilter = (
    contains,
    statusId,
    storeIds,
    attributes
) => ({
    contains: contains,
    statusId: statusId,
    storeIds: storeIds,
    attributes: attributes
});

export const setSkuFilter = (currentUserFilter, payload, totalStoreIds) => {
    const storeId = RetailCompassStorage.getStoreId();
    const matches = currentUserFilter.matches;
    if (matches) {
        currentUserFilter.matches = {
            ...matches,
            skus: payload.skus,
            statusId: matches.statusId || null,
            contains: matches.contains || Constants.MATCHES.CONTAINED,
            storeIds:
                matches.storeIds && matches.storeIds.length > 0
                    ? [...matches.storeIds, storeId]
                    : [...totalStoreIds, storeId]
        };
    } else {
        currentUserFilter.matches = {
            ...buildMatchesFilter(
                Constants.MATCHES.CONTAINED,
                null,
                [storeId, ...totalStoreIds],
                []
            ),
            skus: payload.skus
        };
    }
};

export const getKeys = (valueKey, values) => {
    if (!valueKey || !values) return;

    const keys = values.map((item) =>
        isNaN(item[valueKey]) ? item[valueKey] : parseFloat(item[valueKey], 10)
    );

    return keys || [];
};

export const isAllowedFilterDirectMatches = payload => {
    if (!payload) {
        return true;
    }

    let allowedToFilterDirectMatches = true;

    if (
        payload.productAvailability ===
            Constants.PRODUCT_AVAILABILITY.ONLY_I_NOT_HAVE ||
        payload.productAvailability ===
            Constants.PRODUCT_AVAILABILITY.ONLY_I_HAVE
    ) {
        let storeIds = getKeys("storeId", payload.stores);
        allowedToFilterDirectMatches = isArray(storeIds) && storeIds.length > 0;
    }

    return allowedToFilterDirectMatches;
};

export const unionItems = (itemKey, oldItems, newItems) => {
    let items = unionBy(oldItems, newItems, itemKey);
    return uniqBy(items, itemKey);
};

export const formatYourAggregations = aggregations => {
    let haveIt = 0;
    let dontHaveIt = 0;
    let moreExpensive = 0;
    let cheaper = 0;
    let same = 0;

    if (aggregations) {
        let storeId = RetailCompassStorage.getStoreId();
        let yourAgregations = find(aggregations, {
            storeId: storeId.toString()
        });

        if (yourAgregations && yourAgregations.aggregations) {
            haveIt = yourAgregations.aggregations.haveIt;
            dontHaveIt = yourAgregations.aggregations.dontHaveIt;
            moreExpensive = yourAgregations.aggregations.moreExpensive || 0;
            cheaper = yourAgregations.aggregations.cheaper || 0;
            same = yourAgregations.aggregations.same || 0;
        }
    }

    return {
        haveIt: haveIt,
        dontHaveIt: dontHaveIt,
        moreExpensive: moreExpensive,
        cheaper: cheaper,
        same: same
    };
};

export const formatAttributeInchesFromAPI = attributesFromAPI => {
    let screenSizeItems = [];
    if (attributesFromAPI) {
        let attributes =
            find(attributesFromAPI, function(attribute) {
                return attribute.id == Constants.ATTRIBUTES.SCREEN_SIZES_INCHES;
            }) || [];

        attributes = attributes.data || [];

        screenSizeItems = sortBy(attributes, function(attribute) {
            return attribute.value;
        }).map((item, index) => ({
            id: index + "",
            value: item.value + "",
            label: item.value + '"',
            index: index
        }));
    }
    return screenSizeItems;
};

export const getEmptyStateType = (
    response,
    lastFilter,
    ignoreEmptyState,
    formattedProducts
) => {
    const currentFilter = { ...lastFilter, paginationCurrentPage: null };
    const defaultFilter = {
        ...Constants.DEFAULT_PRODUCT_LIST_FILTER,
        paginationCurrentPage: null
    };
    const hasData =
        (Utils.isArrayWithData(response.matchedProducts) &&
            Utils.isArrayWithData(formattedProducts)) ||
        (!Utils.isArrayWithData(response.matchedProducts) &&
            response.total !== 0);

    if (hasData || ignoreEmptyState) {
        return null;
    }

    if (isEqual(defaultFilter, currentFilter)) {
        return Constants.EMPTY_STATE_TYPE.GENERIC;
    }
    const isFilteringByStores =
        Utils.hasValue(currentFilter.stores) &&
        currentFilter.applicationData &&
        currentFilter.applicationData.stores &&
        currentFilter.stores.length !==
            currentFilter.applicationData.stores.length;
    const isFilteringByRange =
        (currentFilter.to && Utils.formatNumber(currentFilter.to) > 0) ||
        (currentFilter.from && Utils.formatNumber(currentFilter.from) > 0);
    const isFilteringByFavorites =
        !Number.isNaN(currentFilter.favoriteId) && currentFilter.favorites;
    const isFilteringByBrands = Utils.hasValue(currentFilter.brands);
    const isFilteringByProductAvailability =
        currentFilter.productAvailability &&
        currentFilter.productAvailability !==
            Constants.PRODUCT_AVAILABILITY_DEFAULT;
    const isFilteringByScreenSizeItems = Utils.hasValue(
        currentFilter.screenSizeItems
    );
    //TODO agregar atributos

    const isFilteringByKeywords = Utils.hasValue(currentFilter.keyWords);
    const isFilteringBySkus = Utils.hasValue(currentFilter.skus);

    const atLeastOneActiveFilter = some(
        [
            isFilteringByStores,
            isFilteringByRange,
            isFilteringByBrands,
            isFilteringByProductAvailability,
            isFilteringByScreenSizeItems
        ],
        Boolean
    );

    const atLeastOneActiveMainFilter = some([
        isFilteringByKeywords,
        isFilteringBySkus
    ]);
    const atLeastOneActiveFilterOrMainFilter =
        atLeastOneActiveFilter && atLeastOneActiveMainFilter;
    if (isFilteringByFavorites) {
        if (atLeastOneActiveFilter || atLeastOneActiveMainFilter) {
            return Constants.EMPTY_STATE_TYPE
                .WITH_ACTIVE_FILTERS_FAVORITES_CANDIDATE;
        }
        return Constants.EMPTY_STATE_TYPE.WITH_ACTIVE_FAVORITES;
    }
    if (atLeastOneActiveFilterOrMainFilter) {
        return Constants.EMPTY_STATE_TYPE.WITH_ACTIVE_FILTERS;
    }
    return Constants.EMPTY_STATE_TYPE.GENERIC;
};

export const formatBrandsFromAPI = brandsFromAPI => {
    if (!brandsFromAPI || !isArray(brandsFromAPI.data)) {
        return [];
    }
    return brandsFromAPI.data.map(item => {
        item.brandName = item.brandName ? item.brandName : "Sin marca";
        return {
            ...item,
            value: item.brandId + "",
            label: item.brandName
        };
    });
};

export const formatCommunesFromAPI = (aggregations, storeId) => {
    if (!isArray(aggregations)) {
        return [];
    }
    const storeAggregation = first(filter(aggregations, ["storeId", `${storeId}`]));
    
    if (!storeAggregation || !storeAggregation.aggregations) {
        return [];
    }
    const communes = storeAggregation.aggregations.communes;
    if (!isArray(communes)) {
        return [];
    }
    return communes.map(commune => {
        return {
            ...commune,
            value: commune.id,
            label: capitalize(commune.name)
        }
    });
}

export const sortAggregationRetail = (storeAggregations) => {
    let order = storeAggregations 
        && storeAggregations.length > 0 
        && storeAggregations[0].order ? "asc" : "desc";
    return orderBy(
        storeAggregations,
        entityItem => {
            if (entityItem.order) {
                return entityItem.order;
            }
            return get(entityItem, "aggregations.haveIt", 0);
        },
        order
    );
}

const SORT_STRATEGIES = {
    [Constants.StoreType.RETAILER]: storeAggregations => {
        return sortAggregationRetail(storeAggregations);
    },
    [Constants.SubStoreType.RETAILER_POSTPAID]: storeAggregations => {
        return OperatorUtils.sortStoreAggregations(storeAggregations);
    },
    [Constants.StoreType.SHIPPING_RETAILER]: storeAggregations => {
        return sortAggregationRetail(storeAggregations);
    },
};

export const sortStores = (storeAggregations, storeType) => {
    if (!Utils.isArrayWithData(storeAggregations)) return [];

    const strategyType = OperatorUtils.currentCategoryIsOperator()
        ? Constants.SubStoreType.RETAILER_POSTPAID
        : storeType;
    const customSort = SORT_STRATEGIES[strategyType];
    if (customSort) {
        return customSort(storeAggregations);;
    }

    // default sort
    return orderBy(
        storeAggregations,
        storeItem => {
            if (
                storeItem.aggregations &&
                Utils.isArrayWithData(storeItem.aggregations.brandsShare)
            ) {
                return sumBy(
                    storeItem.aggregations.brandsShare,
                    brandShareItem => brandShareItem.count
                );
            }
            return Number.MIN_SAFE_INTEGER;
        },
        "desc"
    );
};

export const formatStoresFromAPI = (storeId, storesFromAPI, excludedStores) => {
    if (!isArray(storesFromAPI)) {
        return [];
    }
    const excludedStoresHasValues = Utils.isArrayWithData(excludedStores);

    return sortStores(storesFromAPI)
        .filter(item => {
            let currentStoreIdInt = parseInt(item.storeId);
            if (storeId === currentStoreIdInt) {
                return false;
            } else if (
                excludedStoresHasValues &&
                includes(excludedStores, currentStoreIdInt)
            ) {
                return false;
            }
            return true;
        })
        .map(item => {
            return {
                ...item,
                value: item.storeId + "",
                label: item.name
            };
        });
};

export const formatStoresFromAPIInclude = (
    storesFromAPI,
    entitiesIncluded,
    storeType,
    shouldOrder
) => {
    if (!isArray(storesFromAPI)) {
        return [];
    }
    const entitiesHasValues = Utils.isArrayWithData(entitiesIncluded);
    const stores = shouldOrder
        ? sortStores(storesFromAPI, storeType)
        : storesFromAPI;
    return stores
        .filter(store => {
            const id = OperatorUtils.currentCategoryIsOperator()
                ? OperatorUtils.buildOperatorKey(store)
                : parseInt(store.storeId);
            return entitiesHasValues && includes(entitiesIncluded, id);
        })
        .map((item, index) => {
            if (shouldOrder) {
                item.initialOrder = index;
            }
            return {
                ...item,
                value: item.storeId + "",
                label: item.name,
                cheaper: get(item, "aggregations.cheaper", null),
                same: get(item, "aggregations.same", null),
                moreExpensive: get(item, "aggregations.moreExpensive", null),
                haveIt: get(item, "aggregations.haveIt", 0),
                dontHaveIt: get(item, "aggregations.dontHaveIt", 0),
                shared: get(item, "aggregations.shareIt", 0)
            };
        });
};

export const formatRangesMatchesFromAPI = (storeId, brands, rangesFromAPI) => {
    if (!isArray(rangesFromAPI)) {
        return [];
    }
    let store = find(rangesFromAPI, ["storeId", storeId.toString()]);
    if (
        !store ||
        !store.aggregations ||
        !isArray(store.aggregations.rangesPriceMatches)
    ) {
        return [];
    }
    completeBrandNames(store.aggregations.rangesPriceMatches, brands);
    store.aggregations.rangesPriceMatches.map(range => {
        range.count = range.total;
    });
    return orderBy(store.aggregations.rangesPriceMatches, ["order"], ["asc"]);
};

export const completeBrandNames = (ranges, brands) => {
    ranges.forEach(range => {
        range.brands.forEach(brand => {
            const brandFinded = find(brands, ["brandId", brand.brandId]);
            brand.brandName = brandFinded ? brandFinded.brandName : "";
        });
    });
};

export const extractBrandIdsFromRequest = request => {
    if (!request) {
        return null;
    }
    return request.brandIds;
};

export const getAttributesAggregations = () => {
    let attributes = RetailCompassStorage.getCurrentCategoryAttributes();
    let aggregations = [];
    remove(attributes, item => !item);
    attributes.forEach(item => {
        if (
            item.aggregation &&
            item.aggregation.length > 0 &&
            Utils.isAttributeVisibleFromCountryCodeAndUserType(
                item.visualization.visible
            )
        )
            aggregations.push(item.aggregation);
    });
    return aggregations;
};

export const getAttributesAggregationsForExportService = () => {
    return [
        Constants.AGGREGATIONS_NAMES.COMPETITOR,
        ...getAttributesAggregations()
    ];
};

export const getAttributesAggregationsForAlertService = () => {
    return [
        Constants.AGGREGATIONS_NAMES.COMPETITOR,
        ...getAttributesAggregations()
    ];
};

export const getAttributesAggregationsForProductSearch = storeType => {
    let aggs = {
        [Constants.StoreType.BRAND]: [
            Constants.AGGREGATIONS_NAMES.HAVE_IT,
            Constants.AGGREGATIONS_NAMES.BRAND_SHARE_CANONICAL,
            ...getAttributesAggregations()
        ],
        [Constants.StoreType.RETAILER]: [
            Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE,
            Constants.AGGREGATIONS_NAMES.HAVE_IT,
            ...getAttributesAggregations()
        ],
        [Constants.StoreType.RETAILER_POSTPAID]: [
            Constants.AGGREGATIONS_NAMES.MATCHES_BY_OPERATOR,
            Constants.AGGREGATIONS_NAMES.POSTPAID_SEARCH_OPTIONS,
            ...getAttributesAggregations()
        ],
        [Constants.StoreType.SHIPPING_PROVIDER]: [
            Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE,
            Constants.AGGREGATIONS_NAMES.COMMUNE_INTO_MATCHES,
            ...getAttributesAggregations()
        ],
        [Constants.StoreType.SHIPPING_RETAILER]: [
            Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE,
            Constants.AGGREGATIONS_NAMES.COMMUNE_INTO_MATCHES,
            ...getAttributesAggregations()
        ],
    };

    return aggs[storeType];
};

export const verifyAttributesPermissions = body => {
    if (!body.attributes) return;
    let attrs = [];
    body.attributes.forEach(function(attr) {
        let userAttr = RetailCompassStorage.getCurrentCategoryAttribute(
            attr.id
        );
        if (
            userAttr &&
            userAttr.visualization &&
            Utils.isAttributeVisibleFromCountryCodeAndUserType(
                userAttr.visualization.visible
            )
        ) {
            attrs.push(attr);
        }
    });
    body.attributes = attrs;
};

export const shouldShowProductSelectionSummary = props => {
    let bySelection =
        props.selectedProducts && props.selectedProducts.length > 0;
    let byLabelsForm = props.visibleLabelsForm;
    return byLabelsForm || bySelection;
};

export const formatShareByPriceRanges = (aggregations, userBrandIdItems) => {
    return _formatRangePriceReport(aggregations, "rangesPriceMatches").map(
        priceRangesItem => {
            priceRangesItem.shareReportInfo = {
                total: 0,
                haveIt: { total: 0, brandIds: [] },
                dontHaveIt: { total: 0, brandIds: [] }
            };
            let brandsItems = get(priceRangesItem, "brands", []);

            for (let i = 0; i < brandsItems.length; i++) {
                let brandItem = brandsItems[i];
                let key = userBrandIdItems.includes(brandItem.brandId)
                    ? "haveIt"
                    : "dontHaveIt";
                let total = brandItem.count || 0;

                priceRangesItem.shareReportInfo[key].total += total;
                priceRangesItem.shareReportInfo[key].brandIds.push(
                    brandItem.brandId
                );
            }

            priceRangesItem.shareReportInfo.total =
                priceRangesItem.shareReportInfo.haveIt.total +
                priceRangesItem.shareReportInfo.dontHaveIt.total;
            return priceRangesItem;
        }
    );
};

export const formatRangePriceReport = aggregation => {
    return _formatRangePriceReport(aggregation, "rangesReport");
};

const _formatRangePriceReport = (aggregation, reportKey) => {
    let ranges = get(aggregation, reportKey, []);
    return orderBy(ranges, ["order"], ["asc"]).map(range => {
        return {
            order: range.order,
            haveIt: range.haveIt,
            dontHaveIt: range.dontHaveIt,
            moreExpensive: range.moreExpensive,
            same: range.same,
            cheaper: range.cheaper,
            label: formatRangeLabel(range.from, range.to),
            from: range.from,
            to: range.to,
            haveItStore: get(aggregation, "shareIt", 0),
            brands: range.brands
        };
    });
};

//TODO En Jenkins - Tests no considera Locale, usando coma en vez de punto para miles, buscar motivo
export const formatRangeLabel = (
    from,
    to,
    localeCode,
    labelSeparator = " - "
) => {
    let labelFrom = from ? Utils.formatPrice(from, localeCode) : "0";
    let labelTo = to ? Utils.formatPrice(to, localeCode) : "";

    if (labelFrom === "0") {
        labelFrom = "Hasta";
        labelSeparator = " ";
    } else if (!labelTo) {
        labelTo = labelFrom;
        labelFrom = "Desde";
        labelSeparator = " ";
    }

    return labelFrom + labelSeparator + labelTo;
};

export const mapHaveItBrand = (brand, response, storeId) => {
    let storeAggregation = find(response.aggregations, {
        storeId: String(storeId)
    });
    if (storeAggregation) {
        let haveItBrands = storeAggregation.aggregations.haveItBrands;
        let brand_ = find(haveItBrands, { brandId: brand.brandId });
        if (brand_) {
            brand = {
                ...brand,
                haveIt: brand_.haveIt,
                dontHaveIt: brand_.dontHaveIt,
                haveItStore: get(storeAggregation, "aggregations.shareIt", 0)
            };
        } else {
            brand = { ...brand, haveIt: null, dontHaveIt: null };
        }
    }
    return brand;
};

export const mapComparisonPriceBrands = (brand, response, storeId) => {
    let storeAggregation = find(response.aggregations, {
        storeId: String(storeId)
    });
    if (storeAggregation) {
        let comparisonPriceBrands =
            storeAggregation.aggregations.comparisonPriceBrands || [];
        let itemFound = find(comparisonPriceBrands, { brandId: brand.brandId });
        if (itemFound) {
            brand = {
                ...brand,
                cheaper: itemFound.cheaper,
                same: itemFound.same,
                moreExpensive: itemFound.moreExpensive
            };
        } else {
            brand = { ...brand, cheaper: 0, same: 0, moreExpensive: 0 };
        }
    }
    return brand;
};

export const orderByInitialOrderProp = (stores, initialOrderMap) => {
    if (
        initialOrderMap &&
        Utils.isArrayWithData(Object.keys(initialOrderMap))
    ) {
        return orderBy(
            stores,
            entityItem => {
                return initialOrderMap[entityItem.storeId];
            },
            "asc"
        );
    }
    return stores;
};

/**
 * extrae la información de productos por marcas existentes en los retailers y
 * los coloca en el nivel principal de la tienda
 * @param {*} store
 * @param {*} aggregations
 * @param {*} userBrandIdItems
 */
export const mapBrandShareInfo = (store, aggregations, userBrandIdItems) => {
    store.shareReportInfo = {
        total: 0,
        haveIt: { total: 0, brandIds: [] },
        dontHaveIt: { total: 0, brandIds: [] }
    };
    userBrandIdItems = userBrandIdItems || [];
    let storeAggregation = find(aggregations, {
        storeId: String(store.storeId)
    });

    if (
        storeAggregation &&
        storeAggregation.aggregations &&
        Utils.isArrayWithData(storeAggregation.aggregations.brandsShare)
    ) {
        let brandShareItems = storeAggregation.aggregations.brandsShare;
        for (let i = 0; i < brandShareItems.length; i++) {
            let brandShareItem = brandShareItems[i];
            let total = brandShareItem.count || 0;
            let key = userBrandIdItems.includes(brandShareItem.brandId)
                ? "haveIt"
                : "dontHaveIt";
            store.shareReportInfo[key].total += total;
            store.shareReportInfo[key].brandIds.push(brandShareItem.brandId);
        }
        store.shareReportInfo.total =
            store.shareReportInfo.haveIt.total +
            store.shareReportInfo.dontHaveIt.total;
    }

    return store;
};

export const buildRangesPromotionalData = stores => {
    if (!Utils.isArrayWithData(stores)) return { chartData: [] };
    let countByRanges = getRangesItems();
    let maxCount = 0;
    let topDiscounts = [];
    let items = stores.map(store => {
        let chartData = store.aggregations.rangesPromotional || [];
        let total = 0,
            noDiscountTotal = 0;
        chartData.forEach(o => {
            total += o.total;
            if (o.from === null && parseInt(o.to) === 0) {
                noDiscountTotal = o.total;
            }
            if (o.total < maxCount) {
                maxCount = o.total;
            }
        });

        let data = buildChartData(store, countByRanges, maxCount, topDiscounts);
        return {
            storeId: parseInt(store.storeId),
            storeName: store.name,
            total: total,
            discountedTotal: total - noDiscountTotal,
            data: data.items,
            myStore: RetailCompassStorage.getStoreId() == store.storeId
        };
    });
    return {
        chartData: orderBy(
            items,
            ["myStore", "discountedTotal", "total"],
            ["desc", "desc", "desc"]
        ),
        maxCount,
        topDiscounts
    };
};

export const getRangesPromotional = () => {
    return [
        {
            from: null,
            to: 0,
            order: 1,
            monoValue: true,
            tooltip: "Sin descuento",
            color: "#d8d8d8"
        },
        { from: 1, to: 5, order: 2 },
        { from: 6, to: 10, order: 3 },
        { from: 11, to: 15, order: 4 },
        { from: 16, to: 20, order: 5 },
        { from: 21, to: 25, order: 6 },
        { from: 26, to: 30, order: 7 },
        { from: 31, to: 35, order: 8 },
        { from: 36, to: 40, order: 9 },
        { from: 41, to: 45, order: 10 },
        { from: 46, to: 50, order: 11 },
        { from: 51, to: 55, order: 12 },
        { from: 56, to: 60, order: 13 },
        { from: 61, to: 65, order: 14 },
        { from: 66, to: 70, order: 15 },
        { from: 71, to: null, order: 16, label: ">70%", tooltip: "Más de 70%" }
    ];
};

const getRangesItems = () => {
    return getRangesPromotional().map((item, inx) => {
        item.id = inx;
        item.value = 0;
        if (item.monoValue) {
            if (item.from === null) {
                item.label = item.label || item.to + "%";
                item.tooltip = item.tooltip || item.to + "%";
            } else {
                item.label = item.label || item.from + "%";
                item.tooltip = item.ltooltip || item.from + "%";
            }
        } else {
            if (item.to === null) {
                item.label = item.label || "≥" + item.from + "%";
                item.tooltip = item.tooltip || "Más de " + item.to + "%";
            } else if (item.from === null) {
                item.label = item.label || "≤" + item.to + "%";
                item.tooltip = item.tooltip || "Menos de " + item.to + "%";
            } else {
                item.label = item.label || item.to + "%";
                item.tooltip =
                    item.tooltip || item.from + "% a " + item.to + "%";
            }
        }
        return item;
    });
};

const buildChartData = (store, ranges, maxCount, topDiscounts) => {
    let countByRanges = JSON.parse(JSON.stringify(ranges));
    let rangesPromotional = store.aggregations.rangesPromotional
        ? store.aggregations.rangesPromotional
        : [];
    rangesPromotional.forEach((item, inx) => {
        let range = find(
            countByRanges,
            o => o.from === item.from || o.to === item.to
        );
        if (range) {
            range.value = item.total;
            range.tooltipContent = (
                <div className="tooltip-content">
                    <span>
                        {range.value}{" "}
                        {range.value === 1 ? "producto" : "productos"}
                    </span>
                    <span className="tooltip-legend">{range.tooltip}</span>
                </div>
            );
        }
    });
    return { items: countByRanges, maxCount, topDiscounts };
};

export const getPromotionalType = subReport => {
    let promotionalType;
    switch (subReport) {
        case Constants.RETAIL_DISCOUNTS_REPORT.ALL:
            promotionalType = Constants.PROMOTIONAL_TYPE.ALL;
            break;
        case Constants.RETAIL_DISCOUNTS_REPORT.CARD_PRICE:
            promotionalType = Constants.PROMOTIONAL_TYPE.CARD;
            break;
        case Constants.RETAIL_DISCOUNTS_REPORT.SALE_PRICE:
            promotionalType = Constants.PROMOTIONAL_TYPE.OFFER;
            break;
    }
    return promotionalType;
};

const getOutOfStockReportSummary = outOfStockRanges => {
    let summary = {};
    outOfStockRanges.forEach(item => {
        let key = [item.from, item.to].join("_");
        summary[key] = {
            rom: item.from,
            to: item.to,
            order: item.order,
            outOfStock: item.outOfStock
        };
    });
    return summary;
};

export const mapOutOfStockReportSummary = (
    store,
    aggregations,
    userBrandIdItems
) => {
    store.outOfStockReportSummary = undefined;
    let storeAggregation = find(aggregations, {
        storeId: String(store.storeId)
    });
    if (
        storeAggregation &&
        storeAggregation.aggregations &&
        Utils.isArrayWithData(
            storeAggregation.aggregations.outOfStockRangesMatches
        )
    ) {
        store.outOfStockReportSummary = getOutOfStockReportSummary(
            storeAggregation.aggregations.outOfStockRangesMatches
        );
    }
    return store;
};

export const formatOutOfStockReportSummary = (
    myAggregations,
    userBrandIdItems
) => {
    if (
        myAggregations &&
        Utils.isArrayWithData(myAggregations.outOfStockRangesMatchesSummary)
    ) {
        return getOutOfStockReportSummary(
            myAggregations.outOfStockRangesMatchesSummary
        );
    }
    return undefined;
};

export const formatOutOfStockBrandsByRetailer = myAggregations => {
    if (
        myAggregations &&
        Utils.isArrayWithData(myAggregations.outOfStockRangesBrand)
    ) {
        return getOutOfStockBrandsByRetailer(
            myAggregations.outOfStockRangesBrand
        );
    }
    return undefined;
};

export const mapOutOfStockBrandsByRetailer = (store, aggregations) => {
    store.outOfStockRangesBrand = undefined;
    let storeAggregation = find(aggregations, {
        storeId: String(store.storeId)
    });
    if (
        storeAggregation &&
        storeAggregation.aggregations &&
        Utils.isArrayWithData(
            storeAggregation.aggregations.outOfStockRangesBrand
        )
    ) {
        store.outOfStockRangesBrand = getOutOfStockBrandsByRetailer(
            storeAggregation.aggregations.outOfStockRangesBrand
        );
    }
    return store;
};

const getOutOfStockBrandsByRetailer = outOfStockRanges => {
    let summary = {};
    outOfStockRanges.forEach(item => {
        const key = [item.from, item.to].join("_");
        summary[key] = { from: item.from, to: item.to, brands: [] };
        if (Utils.isArrayWithData(item.brands)) {
            item.brands.forEach(brand => {
                summary[key].brands.push(brand);
            });
        }
    });
    return summary;
};

export const buildMatchesAttributes = payload => {
    let attributes = [];
    if (payload.contractType && Utils.isArrayWithData(payload.contractType)) {
        attributes.push({
            id: Constants.MATCH_ATTRIBUTES.CONTRACT_TYPE,
            values: payload.contractType
        });
    }
    return attributes;
};

export const trackProductDetailEvent = product => {
    if (!product) return;
    const pathname = window.location.pathname;
    const url = pathname.replace("list", "product/") + product.productId;
    GaUtils.trackGAPage(url);
    Intercom.trackEvent(IntercomConstants.EVENTS.VIEWED_PRODUCT_DETAIL);
    ChurnZero.trackEvent({ eventName: "scalibur_detalle_producto" });
};

export const BASE_AGGREGATIONS = {
    [Constants.StoreType.BRAND]: [
        Constants.AGGREGATIONS_NAMES.BRAND_SHARE_CANONICAL
    ],
    [Constants.StoreType.RETAILER]: [
        Constants.AGGREGATIONS_NAMES.EXPENSIVE_CHEAPER_SAME,
        Constants.AGGREGATIONS_NAMES.HAVE_IT,
        Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE
    ],
    [Constants.SubStoreType.RETAILER_POSTPAID]: [
        Constants.AGGREGATIONS_NAMES.MATCHES_BY_OPERATOR,
        Constants.AGGREGATIONS_NAMES.POSTPAID_SEARCH_OPTIONS,
        ...getAttributesAggregations()
    ],
    [Constants.StoreType.SHIPPING_PROVIDER]: [
        Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE,
        Constants.AGGREGATIONS_NAMES.SHIPPING_BY_COMMUNE
    ],
    [Constants.StoreType.SHIPPING_RETAILER]: [
        Constants.AGGREGATIONS_NAMES.MATCHES_BY_STORE,
        Constants.AGGREGATIONS_NAMES.SHIPPING_BY_COMMUNE
    ],
};

export function isShippingView(selectedView, selectedReport) {
    const isListViewSelected =
        selectedView === Constants.TYPE_VIEW_RETAIL.SHIPPING;
    
    const isReportViewSelected =
        selectedView === Constants.TYPE_VIEW_RETAIL.REPORT &&
        selectedReport === Constants.TYPE_REPORT_RETAIL.SHIPPING_COST;
    
    return isListViewSelected || isReportViewSelected;
}
