import React, { useState, useEffect } from "react";
import { Constantes, Utils } from "@common/utils/";

const ProductBadge = ({ producto, tableStrategy = Constantes.StoreType.RETAILER }) => {
    const [productBadges, setProductBadges] = useState({});

    useEffect(() => {
        function getRetailBadges(product = {}) {
            let yourProduct = product.yourProduct || {};
            
            const badges = {
                sku: Utils.isObjectWithData(yourProduct) ? yourProduct.sku : null,
                name: yourProduct.productName || product.name
            }
            setProductBadges(badges);
        }

        getRetailBadges(producto);
    }, [producto]);

    const showProductName = () => {
        const availableStrategy = [
            Constantes.StoreType.BRAND,
            Constantes.StoreType.RETAILER
        ];

        return availableStrategy.includes(tableStrategy);
    }

    const showSku = () => {
        const availableStrategy = [
            Constantes.StoreType.RETAILER,
            Constantes.SubStoreType.RETAILER_POSTPAID,
        ];

        return availableStrategy.includes(tableStrategy);
    }

    return (
        <>
            {showSku() &&
                <h1 id="sku">
                    {productBadges.sku ?
                        "SKU: " + productBadges.sku
                        : <span className="no-sku">No lo tienes</span>
                    }
                </h1>
            }
            {showProductName() && <h2 className="name">{productBadges.name}</h2>}
        </>
    )
}

export default ProductBadge