import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Progress } from 'reactstrap';
import { isInteger, isEmpty } from 'lodash';

const items = PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    barClassName: PropTypes.string.isRequired,
    isMain: PropTypes.bool.isRequired,
});

const mainItems = PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(items).isRequired
});

const PercentageType = {
    FIXED: 'FIXED',
    TRUNCATED: 'TRUNCATED',
    NORMAL: 'NORMAL',
};

export default class CounterPercent extends PureComponent {
    static propTypes = {
        counterItems: PropTypes.arrayOf(mainItems),
        mainLabel: PropTypes.string.isRequired,
        secundaryLabel: PropTypes.string,
        total: PropTypes.number.isRequired,
        onClickItem: PropTypes.func,
    };

    handleClickItem(counterItem) {
        if (this.props.onClickItem) {
            this.props.onClickItem(counterItem);
        }
    }

    toFixed(num, fixed) {
        // eslint-disable-next-line
        let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    formatPercentage(counterItem) {
        let value = 0;
        if (counterItem.percentage) {
            value = counterItem.percentage;
        }

        const format = (value) => (value.toString().replace(/\./g, ',') + '%');

        if (counterItem.percentageType === PercentageType.NORMAL) {
            if (isInteger(value)) {
                return format(value);
            }
            return format(value.toFixed(1))
        }
        return format(this.toFixed(value, 1));
    }

    calculatePercentage(counterItems) {
        if (!counterItems || !counterItems.length) {
            return null;
        }
        let calculation = (part, total) => {
            return total > 0 ? ((part / total) * 100) : 0;
        }
        let hasUnfixed = counterItems.filter((counterItem) => {
            let percentage = calculation(counterItem.count, counterItem.total);
            return (percentage < 0.1);
        }).length;
        if (!hasUnfixed) {
            return counterItems.map((counterItem) => {
                let percentage = calculation(counterItem.count, counterItem.total);
                return {
                    ...counterItem,
                    percentage: percentage,
                    percentageType: PercentageType.NORMAL
                }
            });
        }
        let items = counterItems.map((counterItem) => {
            let percentage = calculation(counterItem.count, counterItem.total);
            let percentageType = PercentageType.NORMAL;
            if (percentage > 0) {
                if (percentage < 0.1) {
                    percentage = this.fixPercentage(percentage);
                    percentageType = PercentageType.FIXED;
                } else if (percentage >= 1 && !isInteger(percentage)) {
                    percentage = parseInt(percentage);
                    percentageType = PercentageType.TRUNCATED;
                }
            }
            return {
                ...counterItem,
                percentage: percentage,
                percentageType: percentageType
            }
        });

        let base = 0.1;

        let percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
        if (percentageTotal === 100) {
            return items;
        } else if (percentageTotal > 100) {
            base = -base;
        }
        let hasItemsTruncateds = items.filter((counterItem) => counterItem.percentageType === PercentageType.TRUNCATED);
        if (!hasItemsTruncateds.length) {
            return items;
        }

        if (base > 0) {
            items = this.add(items, percentageTotal, base);
        } else {
            items = this.reducer(items, percentageTotal, base);
        }
        return items
    }

    reducer(items, percentageTotal, base) {
        while (percentageTotal > 100) {
            for (let i = 0; i < items.length; i++) {
                let counterItem = items[i];
                let percentage = counterItem.percentage;
                if (counterItem.percentageType === PercentageType.TRUNCATED) {
                    percentage += base;
                }
                items[i] = { ...counterItem, percentage: percentage };
                percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
                if (percentageTotal <= 100) {
                    break;
                }
            }
        }
        return items;
    }

    add(items, percentageTotal, base) {
        while (percentageTotal < 100) {
            for (let i = 0; i < items.length; i++) {
                let counterItem = items[i];
                let percentage = counterItem.percentage;
                if (counterItem.percentageType === PercentageType.TRUNCATED) {
                    percentage += base;
                }
                items[i] = { ...counterItem, percentage: percentage };
                percentageTotal = items.reduce((accumulator, counterItem) => accumulator + counterItem.percentage, 0);
                if (percentageTotal >= 100) {
                    break;
                }
            }
        }
        return items;
    }


    fixPercentage(percentage) {
        let found = Array.from(percentage.toString(), Number).find((value) => value > 0);
        if (found) {
            return Number('0.' + found);
        }
        return percentage;
    }

    buildBar(items, index) {
        let newCounterItems = this.calculatePercentage(items);
        if (!newCounterItems || !newCounterItems.length) {
            return null;
        }

        const size = newCounterItems.length;
        const colSize = (size >= 3) ? 4 : 8 / size;
        return (<div key={index} className="space-counter">
            <Row className="counter-group-item">
                {newCounterItems.map((counterItem, index) => {
                    if (counterItem.isMain) {
                        return (<Col key={index}
                            xs={colSize}>
                            <div className='counter-item percent-without-event'>
                                <div className="percent-item-percentage">{this.formatPercentage(counterItem)}</div>
                                <div className="percent-item-label-main">{counterItem.label}</div>
                            </div>
                        </Col>);
                    } else {
                        const valueOutStock = counterItem.total - counterItem.count;
                        const total = counterItem.total;
                        return (<Col key={index}
                            xs={colSize}>
                            <div className='counter-item percent-without-event'>
                                <div className="percent-item-label-secundary">{`${valueOutStock} / ${total}`}</div>
                            </div>
                        </Col>);
                    }
                })}
                <Col key={index}
                    xs={colSize}>
                    <div className='counter-item percent-without-event'>
                        <div className="percent-item-label-secundary"></div>
                    </div>
                </Col>
            </Row>
            <Row className="counter-group-progress-bar space-progress">
                <Col xs={12} sm={12} lg={12}>
                    <Progress className="counter-progress-bar size-progres-bar" multi>
                        {newCounterItems.map((counterItem, index) => {
                            return (
                                <Progress key={index}
                                    bar barClassName={counterItem.barClassName}
                                    value={counterItem.percentage}>
                                    <div className="bar-size-for-event" onClick={() => { this.handleClickItem(counterItem) }}></div>
                                </Progress>
                            );
                        })}
                    </Progress>
                </Col>
            </Row>
        </div>);
    }

    render() {
        const { mainLabel, secundaryLabel, total, counterItems } = this.props;
        if (!counterItems || counterItems.length === 0) {
            return null;
        }
        return (<div className="counter-group-container">
            <div className='counter-item percent-without-event space-title'>
                <div className="percent-main-label">{total} {mainLabel}</div>
                {isEmpty(secundaryLabel) ? null :
                    <div className="percent-scundary-label">{secundaryLabel}</div>}
            </div>
            {counterItems.map((counterItem, index) => {
                return this.buildBar(counterItem.items, index);
            })}
        </div>);
    }
}
