/**
 * @author @author <a href="mailto:cruiz@pricingcompass.com">Carlos Ruiz</a>
 * @class productService.js
 * @date 28 apr. 2020
 */


import { postAsJson, getAsJson } from './AbstractService';
import { get } from 'lodash';
import { ConstantesApi } from '@common/utils';

export const saveProductInfo = (productId, params) => {
    const data = get(params, "data", undefined);
    if (!data) {
        throw new Error('invalid data to save');
    }
    if (!data.modelo) {
        throw new Error('model Required');
    }
    if (!productId) {
        throw new Error('productId Required');
    }

    let endpoint = ConstantesApi.API_PRODUCT_UPDATE.replace("{productId}", productId)
    return postAsJson({
        api: endpoint,
        ...params
    });
};

export const findMatchedProductById = (productId, params) => {
    if (!productId) {
        throw new Error('productId Required');
    }

    let endpoint = ConstantesApi.API_PRODUCT_FIND_MATCHED_FULL_PRODUCT.replace("{productId}", productId)
    return getAsJson({
        api: endpoint,
        ...params
    });
};

export const canonicalTransformIntoRemoved = (params) => {
    validateTransformIntoProps(params.data);

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_TRANSFORM_INTO_REMOVED,
        ...params
    });
};

export const canonicalMerge = (params) => {
    vaidateValues(['userId', 'fromProductId', 'toProductId', 'username', 'sourceId'], params.data);

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_MERGE,
        ...params
    });
};

export const canonicalTransformIntoCanonical = (params) => {
    validateTransformIntoProps(params.data);

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_TRANSFORM_INTO_CANONICAL,
        ...params
    });
};

export const canonicalFindPotentialDuplicates = (params) => {
    vaidateValues(["competitorsIds"], params.data);

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_POTENTIAL_DUPLICATES,
        ...params
    });
};

export const canonicalSearchByKeywords = (params) => {
    vaidateValues(["storeId", "categoryId", "keywords"], params.data);

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_SEARCH_BY_KEYWORDS,
        ...params
    });
};

export const canonicalMarkAsReviewed = (params) => {
    validateTransformIntoProps(params.data);

    return postAsJson({
        api: ConstantesApi.API_PRODUCT_CANONICAL_MARK_AS_REVIEWED,
        ...params
    });
};

export const validateTransformIntoProps = (payload) => {
    vaidateValues(['productId', 'userId'], payload);
}

export const vaidateValues = (props, payload) => {
    if (!payload) {
        throw new Error('invalid data');
    }
    props && props.forEach(fieldItem => {
        let fieldValue = payload[fieldItem];
        if (!fieldValue) {
            throw new Error(fieldItem + ' is required');
        }
    });
}