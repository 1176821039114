import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Constantes } from '@common/utils';

export class ScrollToTop extends PureComponent {

  componentDidUpdate(prevProps) {
    if (this.props.selectedView !== Constantes.TYPE_VIEW_RETAIL.REPORT) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    return this.props.children
  }
}

export const mapStateToProps = (state, ownProps) => {
  return {
    selectedView: state.subRouterStateReducer.selectedView
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

const ScrollToTopConnected = connect(mapStateToProps, mapDispatchToProps)(ScrollToTop);
export default withRouter(ScrollToTopConnected);