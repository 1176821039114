import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestFetchHomologationAttributes = (state, action) => {
    return updateObject(state, {
        isFetchingHomologationAttributes: true,
        hasErrorFetchingHomologationAttributes: false
    });
}

export const receiveFetchHomologationAttributes = (state, action) => {
    return updateObject(state, {
        isFetchingHomologationAttributes: false,
        hasErrorFetchingHomologationAttributes: false
    });
}

export const receiveFetchHomologationAttributesError = (state, action) => {
    return updateObject(state, {
        isFetchingHomologationAttributes: false,
        hasErrorFetchingHomologationAttributes: true
    });
}

export const handlers = {
    [ValidationActions.REQUEST_FETCH_HOMOLOGATION_ATTRIBUTES]: requestFetchHomologationAttributes,
    [ValidationActions.RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES]: receiveFetchHomologationAttributes,
    [ValidationActions.RECEIVE_FETCH_HOMOLOGATION_ATTRIBUTES_ERROR]: receiveFetchHomologationAttributesError
}

export default handlers