import React, { PureComponent } from 'react';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { Utils } from '@common/utils';
import { unionBy } from 'lodash';

export const genericPropTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    categoryChanged: PropTypes.func,
    currentFilter: PropTypes.object,
    paginationCount: PropTypes.number,
    lastExecutedFilter: PropTypes.object,
    forceRepeatSearch: PropTypes.bool,
    searchType: PropTypes.string,
    searchTypeToRepeat: PropTypes.string
  };


export const withGenericMainFilter = (WrappedComponent, formName) => {
    return class GenericMainFilter extends PureComponent {
        static propTypes = genericPropTypes;

        constructor(props) {
            super(props);
            this.wrappedComponentInstance = null;
            this.fetchProductItems = this.fetchProductItems.bind(this);
            this.onKeyPress = this.onKeyPress.bind(this);
            this.buildCurrentFilter = this.buildCurrentFilter.bind(this);
            this.onChangeMultiSelect = this.onChangeMultiSelect.bind(this);
            this.changeFieldValue = this.changeFieldValue.bind(this);
            this.startSearchClick = this.startSearchClick.bind(this);
        };

        proc(wrappedComponentInstance) {
          this.wrappedComponentInstance = wrappedComponentInstance;
        }
        
        fetchProductItems(params, resetCurrentPage) {
          let currentFilter = this.buildCurrentFilter(params);
          if (resetCurrentPage) {
              currentFilter.paginationCurrentPage = 1;
          }

          if (this.props.applicationData) {
              currentFilter.applicationData = this.props.applicationData;
          }
          this.props.fetchProductItems(currentFilter);
        }
        
        changeFieldValue(field, value) {
          this.props.dispatch(change(formName, field, value))
        }
        
        //se encarga de concatenar los atributos activos con los nuevos (evitar sobrescribir)
        buildFilterDynamicAttributes(params) {
          return unionBy(params.dynamicAttributes || [], this.props.currentFilter.dynamicAttributes || [], 'id')
        }

        buildCurrentFilter(params) {
          params.lastFilterField = Utils.hasValue(params.lastFilterField) ? params.lastFilterField : null;
          return { dynamicAttributes: this.buildFilterDynamicAttributes(params), ...this.props.currentFilter, ...params };
        }

        onKeyPress(e) {
          e.persist();
          if (e.charCode === 13) {
            e.preventDefault();
            this.wrappedComponentInstance.searchFieldSubmit();
          }
        }

        onChangeMultiSelect(field, values) {
          if (!field) {
              return;
          }
          this.changeFieldValue(field, values);
          
          if(!Utils.isArrayWithData(values)){
            this.wrappedComponentInstance.searchFieldSubmit();
          }
        }
      
        startSearchClick(e) {
          this.wrappedComponentInstance.searchFieldSubmit();
        }

        render() {

            const otherProps = {
                hasActiveFilter: this.hasActiveFilter,
                resetSearch: this.resetSearch ,
                startSearchClick: this.startSearchClick,
                onKeyPress: this.onKeyPress ,
                buildCurrentFilter: this.buildCurrentFilter ,
                changeFieldValue: this.changeFieldValue,
                onChangeMultiSelect: this.onChangeMultiSelect,
                currentFilter: this.props.currentFilter,
                ref: this.proc.bind(this)
            };

            return <WrappedComponent {...this.props} {...otherProps} />
        }
    }
}

