import * as AttributesActions from './AttributesActions';
import { CategoryService, ProductAttributeService } from '@common/data-access/';
import { orderBy, remove } from 'lodash';
import { Utils } from '@common/utils';

export const initialState = {
    isFetching: false,
    error: null,
    productAttribute: null,
    isSaving: false,
    isSaved: false,
    errorCreating: false,
    hasError: false,
    attributes: null,
    storedAttributes: null,
    isFetchingCategoryInfo: false,
    categoryInfo: null
};



export const fetchCategoryAttributes = (dispatch, categoryId) => {

    return CategoryService.fetchAttributesWithValues({
        dispatch,
        categoryId: categoryId,
        requestCallback: AttributesActions.requestAttributesCategoryAction,
        receiveCallback: AttributesActions.receiveAttributesCategoryAction,
        receiveErrorCallback: AttributesActions.receiveAttributesCategoryErrorAction
    })
}

export const fetchCategoryInfo = (dispatch, categoryId) => {

    return CategoryService.fetchCategoryInfo({
        dispatch,
        categoryId: categoryId,
        requestCallback: AttributesActions.requestCategoryInfoAction,
        receiveCallback: AttributesActions.receiveCategoryInfoAction,
        receiveErrorCallback: AttributesActions.receiveCategoryInfoErrorAction
    })
}

export const buildBodyProductAttribute = (data) => {
    if (!Array.isArray(data) || !data.length) {
        throw new Error('invalid data to save');
    }
    data.forEach((item) => {
        if (!item.productId) {
            throw new Error('productId Required');
        }
        if (!item.attributeId) {
            throw new Error('attributeId Required');
        }
        if (!item.valueId) {
            throw new Error('valueId Required');
        }
        if (!item.userId) {
            throw new Error('userId Required');
        }
    });

    return data;
}

export const saveProductAttribute = (dispatch, payload) => {
    let data = buildBodyProductAttribute(payload);
    return ProductAttributeService.saveProductAttribute({
        dispatch,
        data,
        requestCallback: AttributesActions.requestCreateProductAttributeAction,
        receiveCallback: AttributesActions.receiveCreateProductAttributeAction,
        receiveErrorCallback: AttributesActions.receiveCreateProductAttributeErrorAction
    })
}

export const fetchProductAttributes = (dispatch, payload) => {
    const productId = payload;
    return ProductAttributeService.fetchProductAttributes({
        dispatch,
        productId,
        requestCallback: AttributesActions.requestProductAttributesAction,
        receiveCallback: AttributesActions.receiveProductAttributesAction,
        receiveErrorCallback: AttributesActions.receiveProductAttributesErrorAction
    })
};

export const AttributesStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};
    switch (action.type) {
        case AttributesActions.REQUEST_ATTRIBUTES_CATEGORY:
            return Object.assign({}, state, { isFetching: true, hasError: false });
        case AttributesActions.RECEIVE_ATTRIBUTES_CATEGORY_ERROR:
            return Object.assign({}, state, {
                error: action.payload.error,
                hasError: true,
                isFetching: false
            });
        case AttributesActions.RECEIVE_ATTRIBUTES_CATEGORY:
            let attributes = action.payload;

            // ordering attributes   
            if (Utils.isArrayWithData(attributes)) {
                attributes = orderBy(attributes, (item) => {
                    let visualization = item.visualization;

                    if (!visualization) {
                        return null;
                    }

                    if (typeof visualization === "string") {
                        visualization = JSON.parse(visualization);
                    }
                    return visualization.validationOrder;
                }).map((item) => {
                    let visualization = item.visualization ? JSON.parse(item.visualization) : false;
                    if (visualization && visualization.unsetOption && visualization.unsetOption.id) {
                        remove(item.values, (o) => (o.id === visualization.unsetOption.id));
                    }
                    return item;
                });
            }
            return Object.assign({}, state, {
                isFetching: false,
                hasError: false,
                attributes: attributes
            });
        case AttributesActions.REQUEST_CREATE_PRODUCT_ATTRIBUTE:
            return Object.assign({}, state, { isSaving: true, hasError: false, error: null, isSaved: false, errorCreating: false });
        case AttributesActions.RECEIVE_CREATE_PRODUCT_ATTRIBUTE_ERROR:
            return Object.assign({}, state, {
                error: action.payload.error,
                hasError: true,
                isSaved: false,
                errorCreating: true,
                isSaving: false
            });
        case AttributesActions.RECEIVE_CREATE_PRODUCT_ATTRIBUTE:
            return Object.assign({}, state, {
                isSaving: false,
                isSaved: true,
                errorCreating: false,
                hasError: false,
                error: null,
                productAttribute: action.payload.response
            });
        case AttributesActions.RECEIVE_PRODUCT_ATTRIBUTES:
            return {
                ...state,
                storedAttributes: action.payload.productAttributes
            };
        case AttributesActions.RECEIVE_PRODUCT_ATTRIBUTES_ERROR:
        case AttributesActions.REQUEST_PRODUCT_ATTRIBUTES:
            return {
                ...state,
                storedAttributes: null
            };
        case AttributesActions.REQUEST_CATEGORY_INFO:
            return Object.assign({}, state, { isFetchingCategoryInfo: true, hasError: false });
        case AttributesActions.RECEIVE_CATEGORY_INFO_ERROR:
            return Object.assign({}, state, {
                error: action.payload.error,
                hasError: true,
                isFetchingCategoryInfo: false
            });
        case AttributesActions.RECEIVE_CATEGORY_INFO:             
            return Object.assign({}, state, {
                isFetchingCategoryInfo: false,
                hasError: false,
                categoryInfo: action.payload
            });
        default:
            return state;
    }
};

