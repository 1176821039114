import * as ValidationActions from "../ValidationActions";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestSaveProductInfo = (state, action) => {
    return updateObject(state, {
        isSaving: true,
        hasError: false,
        error: null,
        isSaved: false,
        errorSaving: false
    });
}

export const receiveSaveProductInfo = (state, action) => {
    return updateObject(state, {
        isSaving: false,
        isSaved: true,
        errorSaving: false,
        hasError: false,
        error: null
    });
}

export const receiveSaveProductInfoError = (state, action) => {
    return updateObject(state, {
        error: action.payload.error,
        hasError: true,
        isSaved: false,
        errorSaving: true,
        isSaving: false
    });
}

const handlers = {
    [ValidationActions.REQUEST_SAVE_PRODUCT_INFO]: requestSaveProductInfo,
    [ValidationActions.RECEIVE_SAVE_PRODUCT_INFO]: receiveSaveProductInfo,
    [ValidationActions.RECEIVE_SAVE_PRODUCT_INFO_ERROR]: receiveSaveProductInfoError,
}

export default handlers