import React, { memo, useState, useEffect } from "react";
import { flatten, isEqual, isNull, some, sortBy } from "lodash";
import { RetailCompassStorage, Utils } from "@common/utils/";

const PriceContractCell = ({ productos, typeContract }) => {

    const [finalProducts, setFinalProducts] = useState([]);

    useEffect(() => {
        const prodsFiltered = getProductsFilteredByTypeContract(productos);
        setFinalProducts(prodsFiltered);
    }, [productos]);

    const getProductWithMinPrice = (products = []) => {
        const someWithStock = some(products, ['status', 1]);
        if (someWithStock) products = products.filter(p => p.status === 1);
        let productsOrdered = sortBy(products, ["planPrice", "price"]);
        return Utils.isArrayWithData(productsOrdered) ? productsOrdered[0] : null;
    }

    const getProductsFilteredByTypeContract = (listItems) => {
        let list = [];

        if (Utils.isArrayWithData(listItems)) {
            list = flatten(listItems.map(color => color.typeContracts
                .filter(tc => isEqual(tc.typeContract, typeContract))
                .map(tc => tc.productos)
                .map(getProductWithMinPrice)
            ))
        }
        return list;
    }

    const showComponentByProduct = (p) => {
        if (isNull(p)) {
            return <EmptyCell />
        }

        return p.status === 1
            ? <PricePostpagoCell producto={p} />
            : <ProductWithoutStock daysWithoutStock={p.daysWithoutStock} />
    }

    return (
        <div className="p-10">
            {finalProducts.map((p, index) =>
                <div className="price-cell" key={index}>
                    {showComponentByProduct(p)}
                </div>
            )}
        </div>
    )
}

export default memo(PriceContractCell)

const EmptyCell = () => {
    return (
        <div className="price-empty">
            <span>-</span>
        </div>
    )
}

const PricePostpagoCell = ({ producto }) => {
    const [isStoreTypeBrand, setIsStoreTypeBrand] = useState();

    useEffect(() => {
        setIsStoreTypeBrand(RetailCompassStorage.isStoreTypeBrand());
    }, []);

    return (
        <>
            {Utils.formatPrice(isStoreTypeBrand ? producto.price : producto.planPrice)}
            <span className="separator">/</span>
            {Utils.formatPrice(isStoreTypeBrand ? producto.planPrice : producto.price)}
        </>
    )
}

const ProductWithoutStock = ({ daysWithoutStock }) => {

    if (isNull(daysWithoutStock)) {
        return null;
    }

    return (
        <span className="without-stock">
            {Utils.daysWithoutStockToText(daysWithoutStock)} sin stock
        </span>
    )
}