import { Utils } from '@common/utils/';

const ACTION_BASE_NAME = 'DASHBOARD_BRAND';


export const REQUEST_PRODUCT_SEARCH = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_PRODUCT_SEARCH');
export const RECEIVE_PRODUCT_SEARCH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH');
export const RECEIVE_PRODUCT_SEARCH_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_ERROR');
export const RECEIVE_PRODUCT_SEARCH_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_ABORT_ERROR');

export const requestProductSearchAction = () => ({
    type: REQUEST_PRODUCT_SEARCH
});

export const receiveProductSearchAction = (data) => ({
    type: RECEIVE_PRODUCT_SEARCH,
    payload: { ...data }
});

export const receiveProductSearchErrorAction = (error) => ({
    type: RECEIVE_PRODUCT_SEARCH_ERROR,
    payload: {
        error: error
    }
});

export const receiveProductSearchAbortErrorAction = (payload) => ({
    type: RECEIVE_PRODUCT_SEARCH_ABORT_ERROR,
    payload: { ...payload }
});