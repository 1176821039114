import React from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row } from 'reactstrap';
import { withRouter } from "react-router";
import { get } from "lodash";
import InteractiveCounter from "@common/components/src/ui/InteractiveCounter";
import { Utils, Constantes } from '@common/utils';
import * as CommonUtils from '../common/common';
import { withGenericReport } from "../common/withGenericReport";
import ShareReportTooltip from "./common/ShareReportTooltip";

const AVAILABLE_REPORTS = [
    Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL,
    Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE
];

const COUNTERS_KEYS = {
    WITHOUT_RESULTS: 'WITHOUT_RESULTS'
};

const filterCounters = (counterItem, total, haveIt) => {
    if (counterItem.counterKey === Constantes.REPORT_KEYS.HAVE_IT) {
        return haveIt > 0;
    } else if (counterItem.counterKey === Constantes.REPORT_KEYS.DONT_HAVE_IT) {
        return haveIt !== total;
    }
    return true;
}

export const buildCounterItems = ({ total, dontHaveIt, haveIt, entityItem }) => {
    let counterItems = [
        {
            id: 1,
            label: '',
            total: haveIt,
            isSelected: false,
            percentageClassName: 'counter-share-report-have-it-percentage',
            entity: entityItem,
            counterKey: Constantes.REPORT_KEYS.HAVE_IT,
            barClassName: 'counter-share-report-have-it',
            enableCounterPercentageClick: false
        },
        {
            id: 2,
            label: '',
            total: dontHaveIt,
            isSelected: false,
            percentageClassName: 'counter-share-report-dont-have-it-percentage',
            entity: entityItem,
            counterKey: Constantes.REPORT_KEYS.DONT_HAVE_IT,
            barClassName: 'counter-share-report-dont-have-it',
            enableCounterPercentageClick: false
        }
    ].filter((counterItem) => filterCounters(counterItem, total, haveIt));

    // se debe mostrar nombre de retail y 0 en caso de que el usuario haya filtrado por entities
    if (!Utils.isArrayWithData(counterItems)) {
        counterItems = [{
            id: 100,
            barClassName: '',
            percentageClassName: '',
            counterKey: COUNTERS_KEYS.WITHOUT_RESULTS,
            label: '',
            isSelected: false,
            total: 0
        }];
    }

    return counterItems;
}

const buildCounterItem = (items, totalGreater, currentReport, goToProductList) => {
    return items.map((entityItem, index) => {
        let haveIt = get(entityItem, 'shareReportInfo.haveIt.total', 0);
        let dontHaveIt = get(entityItem, 'shareReportInfo.dontHaveIt.total', 0);
        let total = get(entityItem, 'shareReportInfo.total', 0);
        let counterItems = buildCounterItems({ total, dontHaveIt, haveIt, entityItem });

        let maxWidth = totalGreater <= 0 ? 100 : (100 * total) / totalGreater;
        let barContainerStyles = { maxWidth: maxWidth.toFixed(2) + '%' };
        return <InteractiveCounter
            key={index}
            counterItems={counterItems}
            showProgressBarZone={total > 0}
            containerClassName={(total === 0 ? 'empty' : '')}
            barContainerStyles={barContainerStyles}
            barContainerClassName={haveIt === 0 ? 'counter-empty-data' : ''}
            showFilterZone={false}
            label={entityItem.label}
            // por definición de negocio, este componente no usa el campo total pero es necesario pasar un valor positivo
            // para que renderice la barra incluso cuando los contadores son 0
            total={1}
            onContainerClick={() => {
                goToProductList({ entity: entityItem });
            }}
            onSelectItem={(item) => {
                goToProductList(item);
            }}
            onDeselectItem={(item) => {
            }}
            formatPercentage={(counterItem) => {
                if (counterItem.counterKey === COUNTERS_KEYS.WITHOUT_RESULTS) {
                    return 0;
                } else if (counterItem.counterKey === Constantes.REPORT_KEYS.HAVE_IT) {
                    if (haveIt === 0 && total === 0) {
                        return 0;
                    }
                    return haveIt + ' de ' + total;
                } else if (counterItem.counterKey === Constantes.REPORT_KEYS.DONT_HAVE_IT) {
                    if (haveIt <= 0) {
                        return haveIt + ' de ' + total;
                    }
                }
                return '';
            }}
            formatCounterLabel={(total, label) => {
                if (!total) {
                    return null;
                }
                return label;
            }}
            isReportBrand={(currentReport) => {
                return currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_BRAND ||
                currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE ||
                currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL;
            }}
            currentReport={currentReport}
            progressBarTooltipConfig={{
                show: true,
                renderContent: (item) => {
                    let totalText = item.counterKey ==="haveIt" ? 
                        item.total + ' producto' + (item.total > 1 ? 's' : '') + ' tuyos' :
                        item.entity.shareReportInfo.total + ' producto' + (item.entity.shareReportInfo.total > 1 ? 's' : '') + ' en total';
                    let perc = item.counterKey ==="haveIt" ? 
                    Utils.roundNumber(item.total / item.entity.shareReportInfo.total * 100, 0) + "%": '';
                    
                    return <div className="progress-bar-tooltip">
                        <ShareReportTooltip containerClassName='tooltip-range' iconClass={item.barClassName} text={
                            currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_LOWER_PRICE ? entityItem.label : item.entity.name} percentage={perc} />
                        <ShareReportTooltip containerClassName='tooltip-total' iconClass="total-of-products" text={totalText} />
                    </div>
                }
            }}
        />    
    });
}

export const interactiveCounterBuilder = ({ entityItems, goToProductList, containerClassName, totalGreater, currentReport, collapseToogle, getCollapseIsOpen }) => {
    if (!Utils.isArrayWithData(entityItems)) {
        return null;
    }

    containerClassName = containerClassName || '';
    totalGreater = totalGreater || 0;

    let orderedEntityItems = []
    if (currentReport === Constantes.TYPE_REPORT_BRAND.SHARE_BY_RETAIL){
        orderedEntityItems = [...entityItems.sort((a, b) => {
            if(a.shareReportInfo === undefined || b.shareReportInfo === undefined){
                return 0;
            }
            if (a.shareReportInfo.haveIt.total < b.shareReportInfo.haveIt.total) {
                return 1;
            }
            if (a.shareReportInfo.haveIt.total > b.shareReportInfo.haveIt.total) {
                return -1;
            }
            return 0;
        })];
    }else {
        orderedEntityItems = [...entityItems];
    }

    let entityItemsRes = [];
    if(entityItems.length > 10){
        let orderedFirstTen = [...orderedEntityItems.slice(0, 10)];
        let orderedLastItems = [...orderedEntityItems.slice(10, orderedEntityItems.length)];

        entityItemsRes.push(buildCounterItem(orderedFirstTen, totalGreater, currentReport, goToProductList));

        entityItemsRes.push(<Collapse  isOpen={getCollapseIsOpen()} key="colapsible-brand-action-last">{
            buildCounterItem([...orderedLastItems], totalGreater, currentReport, goToProductList)
        }</Collapse>)

        entityItemsRes.push(
            <div key="colapsible-brand" className="load-more-container" >
                <div onClick={collapseToogle} className="load-more-action">
                    Ver todos los retailers
                    { getCollapseIsOpen() ?
                    <svg width="18" height="18" viewBox="0 0 24 24" style={{fill:"#999999"}}><path d="M12 6.879L4.939 13.939 7.061 16.061 12 11.121 16.939 16.061 19.061 13.939z"></path></svg>
                    :
                    <svg width="18" height="18" viewBox="0 0 24 24" style={{fill:"#999999"}}><path d="M16.939 7.939L12 12.879 7.061 7.939 4.939 10.061 12 17.121 19.061 10.061z"></path></svg>
                    }
                    
                </div>
            </div>);
    }else {
        entityItemsRes = buildCounterItem(entityItems, totalGreater, currentReport, goToProductList);
    }
    
    return [...entityItemsRes];
}

const ShareReport = (props) => {
    if (!props.shouldRender) {
        return null;
    }

    return (
        <div className={'with-generic-report-container haveit-dont-haveit-container ' + (props.containerClassName || '')}>
            <Row className="title-container">
                <Col md={12} sm={12} >
                    {props.title}
                </Col>
            </Row>

            <Row className="subtitle-container">
                <Col md={12} sm={12}>
                    {props.subtitle}
                </Col>
            </Row>

            {props.interactiveCounterRender(props)}
        </div>
    );
}

ShareReport.propTypes = {
    currentReport: PropTypes.oneOf(AVAILABLE_REPORTS),
    interactiveCounterRender: PropTypes.func.isRequired,
    goToProductList: PropTypes.func.isRequired,
    containerClassName: PropTypes.string
}

let ShareReportWithGenericReport = withGenericReport(ShareReport, interactiveCounterBuilder);
export default CommonUtils.getComponentConnected(withRouter(ShareReportWithGenericReport));
