import ShippingFilter from './ShippingFilter';
import { connect } from 'react-redux';
import { Constantes, RetailCompassStorage, Utils, SubRouterState } from '@common/utils';
import { fetchProductItems } from '../table/TableState';

export const mapStateToProps = (state, ownProps) => {
    let communes = state.productListBrandReducer.communes || [];
    let currentFilter = Utils.extractCurrentProductListFilter(state);
    currentFilter.defaultCommune = currentFilter.defaultCommune ? currentFilter.defaultCommune : RetailCompassStorage.getDefaultCommune();
    currentFilter.communes = communes;

    return {
        defaultShippingType: Constantes.SHIPPING_TYPE.COST,
        defaultCommune: state.productListBrandReducer.defaultCommune,
        currentFilter: currentFilter,
        communes: communes
    };
}

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        onSelectShippingType: (shippingType) => SubRouterState.onSelectedShippingType(dispatch, shippingType),
        onSelectCommune: (commune) => SubRouterState.onSelectedCommune(dispatch, commune),
        onSendCost: (value) => SubRouterState.onSendCost(dispatch, value),
        onSendShipment: (value) => SubRouterState.onSendShipment(dispatch, value),
        fetchProductItems: (params) => fetchProductItems(dispatch, params),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingFilter);