/**
 * @author @author <a href="mailto:fhurtado@pricingcompass.com">Jose Fary Hurtado Cuero</a>
 * @class storeService.js
 * @date 21 mar. 2018
 */


import { find } from 'lodash';
import { getAsJson } from './AbstractService';
import { ConstantesApi, Constantes, Utils } from '@common/utils';

export const getStoreAndCompetitors = (storeId, params) => {
    if (!storeId) {
        throw new Error('Store required');
    }

    let endpoint = ConstantesApi.API_STORE_AND_COMPETITORS.replace("{storeId}", storeId)
    return getAsJson({
        api: endpoint,
        ...params
    });
};

export const getAvailableApps = async (storeIds) => {
    return getAsJson({
        api: ConstantesApi.API_PRODUCT_ACCESS,
        skipDispatch: true,
        ...(storeIds ? { data: { storeIds: storeIds } } : undefined)
    });
};

export const getConfigScalibur =  async (storeId) => {
    const endpoint = ConstantesApi.API_PRODUCT_ACCESS + "&fields=PRODUCT_ACCESS_MODULE_COMPETITORS";
    const productAcceses = await getAsJson({
        api: endpoint,
        skipDispatch: true,
        ...(storeId ? { data: { storeId: storeId } } : undefined)
    });
    if (!Utils.isArrayWithData(productAcceses)) {
        return {};
    }
    const productAccess = find(productAcceses, ['internalName', Constantes.APP_NAME])
    return productAccess;
}