import React from "react";

const ColorCell = ({ colores = [] }) => {

    return (
        colores ? <div className="p-10 pl-20">
            {colores.map(col =>
                <div className="color-cell" key={col}>{col}</div>
            )}
        </div>
            : null
    )
}

export default ColorCell