import React from 'react';
import { Utils } from '@common/utils/';

export const MinimumPrice = ({ minimumPrice }) => {
    return (
        <div className="medium-price">
            {"M.P"}
            <span className="min-price-value">
                {Utils.formatPrice(minimumPrice)}
            </span>
        </div>
    )
}
