import React, {useState} from "react";
import PropTypes from 'prop-types';
import { Col, Row, Collapse } from 'reactstrap';
import { withRouter } from "react-router";
import { sumBy, orderBy } from "lodash";
import InteractiveCounter from '@common/components/src/ui/InteractiveCounter';
import { Utils, RouterUtils, OutOfStockReportCommon, Constantes } from '@common/utils';
import RangeText from "../common/RangeText";

export const buildCounterItems = ({ entityItem }) => {
    let counterItems = OutOfStockReportCommon.buildCounterItemsConfig(entityItem.outOfStockReportSummary);

    return counterItems;
}

export const getTotalGreater = (stores) => {
    let store = orderBy(stores, currentStore => currentStore.totalGreaterPerStore, 'desc')[0];
    return store.totalGreaterPerStore;
}

const orderByStockRanges = (stores) => {
    return stores.sort((a, b) => {
        if (a.outOfStockReportSummary === undefined || b.outOfStockReportSummary === undefined) return 0;

        // Sort by "Solo Hoy"
        if (a.outOfStockReportSummary["0_1"].outOfStock < b.outOfStockReportSummary["0_1"].outOfStock) return 1;
        if (a.outOfStockReportSummary["0_1"].outOfStock > b.outOfStockReportSummary["0_1"].outOfStock) return -1;

        // Sort by "2 a 3 días"
        if (a.outOfStockReportSummary["2_3"].outOfStock < b.outOfStockReportSummary["2_3"].outOfStock) return 1;
        if (a.outOfStockReportSummary["2_3"].outOfStock > b.outOfStockReportSummary["2_3"].outOfStock) return -1;

        // Sort by "4 a 30 días"
        if (a.outOfStockReportSummary["4_30"].outOfStock < b.outOfStockReportSummary["4_30"].outOfStock) return 1;
        if (a.outOfStockReportSummary["4_30"].outOfStock > b.outOfStockReportSummary["4_30"].outOfStock) return -1;
        return 0;
    });
}

export const getOrderedStores = (stores, lastFilter) => {
    let counterItemsSelected = lastFilter.outOfStockRanges;

    let _counterItems = [];
    if (counterItemsSelected !== undefined && counterItemsSelected !== null){
        _counterItems = stores.map(item => {
            let newItem = { ...item };
            if (newItem.outOfStockReportSummary !== undefined && counterItemsSelected.length > 0){
                let stockProps = Object.getOwnPropertyNames(newItem.outOfStockReportSummary);
                stockProps.forEach(s => {
                    if (counterItemsSelected.indexOf(s) === -1) {
                        newItem.outOfStockReportSummary[s].outOfStock = 0;
                    }
                });
            }
            return newItem;
        });    
    }else{
        _counterItems = [...stores];
    }
    
    let emptyStockStores = [..._counterItems.filter(e => e.outOfStockReportSummary === undefined)];
    let dataStockStores =  [..._counterItems.filter(e => e.outOfStockReportSummary !== undefined)];

    let orderedStores = orderByStockRanges(dataStockStores);
    return [...orderedStores, ...emptyStockStores];
}

export const onContainerClick = (entityItem, goToProductList) => {
    // se obtienen los contadores iniciales para poder ir al listado y que todos queden seleccionados en el filtro de sin stock
    let originalCounters = OutOfStockReportCommon.buildCounterItemsConfig({});
    let params = {};
    params.outOfStockRanges = originalCounters.map(item => item.counterKey).join(',');
    params.stores = entityItem.storeId;
    goToProductList(params);
}

const buildCounterItem = (items, totalGreater, goToProductList) => {
    return items.map((entityItem, index) => {
        let counterItems = entityItem.counterItems;
        let total = sumBy(counterItems, currentCounter => currentCounter.total);
        let maxWidth = totalGreater <= 0 ? 100 : (100 * total) / totalGreater;
        let barContainerStyles = { maxWidth: maxWidth.toFixed(2) + '%' };
        
        return <InteractiveCounter
            key={index}
            counterItems={counterItems}
            showProgressBarZone={total > 0}
            containerClassName={(total === 0 ? 'empty' : '')}
            barContainerStyles={barContainerStyles}
            barContainerClassName={''}
            showFilterZone={false}
            label={entityItem.label}
            // por definición de negocio, este componente no usa el campo total pero es necesario pasar un valor positivo
            // para que renderice la barra incluso cuando los contadores son 0
            total={1}
            onContainerClick={() => onContainerClick(entityItem, goToProductList)}
            onSelectItem={(item) => {
                let params = { outOfStockRanges: item.counterKey, stores: entityItem.storeId };
                goToProductList(params);
            }}
            onDeselectItem={(item) => {
            }}
            fixedLabel={entityItem.totalGreaterPerStore > 0 ? `${entityItem.totalGreaterPerStore} ${entityItem.totalGreaterPerStore === 1 ? 'producto' : 'productos'} en total` : null}
            formatPercentage={(counterItem, index) => {
                // nos aseguramos que sólo el primer elemento de la barra sea el que contenga la información
                // de resumen de
                if (index > 0) {
                    return null;
                }

                if (total === 0) {
                    return 0;
                }

                // debe existir un resumen de la forma `total_rango_1 / total_rango_2 / total_range_n`
                return counterItems.map(item => item.total).filter(itemTotal => itemTotal != 0).join(' / ');
            }}
            formatCounterLabel={(total, label) => {
                return (!!total ? label : null);
            }}
            progressBarTooltipConfig={{
                show: true,
                renderContent: (item) => {
                    let totalText = item.total + ' producto' + (item.total > 1 ? 's' : '');
                    return <div className="progress-bar-tooltip">
                        <RangeText containerClassName='tooltip-range' iconClass={item.counterKey} text={item.label} />
                        <RangeText containerClassName='tooltip-total' iconClass="total-of-products" text={totalText} />
                    </div>
                }
            }}
            isReportOutOfStock={true}
        />
    });
}

export const interactiveCounterRender = ({ stores, goToProductList, lastFilter, collapseToogle, isOpen}) => {
    if (!Utils.isArrayWithData(stores)) {
        return null;
    }

    // se le asigna `counterItems` procesado para facilitar el calculo de `totalGreater`
    let _stores = stores.map((entityItem) => {
        let selectedItems = lastFilter.outOfStockRanges;
        let counterItems = buildCounterItems({ entityItem }).filter(item => {
            if (Utils.isArrayWithData(selectedItems)) {
                return selectedItems.includes(item.counterKey);
            }
            return true;
        });
        let totalGreaterPerStore = sumBy(counterItems, (item) => item.total);
        return { ...entityItem, counterItems, totalGreaterPerStore };
    });
    
    let totalGreater = getTotalGreater(_stores);
    let orderedStores = getOrderedStores(_stores, lastFilter);
    let entityItemsRes = [];

    if(orderedStores.length > 10){
        let orderedFirstTen = [...orderedStores.slice(0, 10)];
        let orderedLastItems = [...orderedStores.slice(10, orderedStores.length)];

        entityItemsRes.push(buildCounterItem(orderedFirstTen, totalGreater, goToProductList));

        entityItemsRes.push(<Collapse isOpen={isOpen} key="colapsible-brand-action-last">{
            buildCounterItem([...orderedLastItems], totalGreater, goToProductList)
        }</Collapse>)

        entityItemsRes.push(
            <div key="colapsible-brand" className="load-more-container" >
                <div onClick={collapseToogle} className="load-more-action">
                    Ver todos los retailers
                    { isOpen ?
                    <svg width="18" height="18" viewBox="0 0 24 24" style={{fill:"#999999"}}><path d="M12 6.879L4.939 13.939 7.061 16.061 12 11.121 16.939 16.061 19.061 13.939z"></path></svg>
                    :
                    <svg width="18" height="18" viewBox="0 0 24 24" style={{fill:"#999999"}}><path d="M16.939 7.939L12 12.879 7.061 7.939 4.939 10.061 12 17.121 19.061 10.061z"></path></svg>
                    }
                    
                </div>
            </div>);
    }else{
        entityItemsRes = buildCounterItem(orderedStores, totalGreater, goToProductList);
    }

    return entityItemsRes;
}

export const goToProductList = (props) => {
    let { location, history, onSelectedView, params } = props;
    params[Constantes.URL_PARAMS.REPORT] = undefined;
    params[Constantes.URL_PARAMS.SUBREPORT] = undefined;
    RouterUtils.goToProductListTabView({ location: location, history: history, onSelectedView: onSelectedView, params })
}

export const DetailedView = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const collapseToogle = () => setIsOpen(!isOpen);

    let counterItemsConfig = OutOfStockReportCommon.buildCounterItemsConfig({});

    return <div className='out-of-stock-report-detailed-thin'>

        <Row className="title-container">
            <Col md={12} sm={12} >
                Productos sin stock por retailer
            </Col>
        </Row>

        <Row className="subtitle-container">
            <Col md={12} sm={12}>
                {counterItemsConfig.map((item, index) => (
                    <RangeText key={index} iconClass={item.counterKey} text={item.label} />
                ))}
            </Col>
        </Row>

        {interactiveCounterRender({ ...props, 
            goToProductList: (params) => goToProductList({ ...props, params: params }), collapseToogle, isOpen })}

    </div >;
}

DetailedView.propTypes = {
    stores: PropTypes.array,
    lastFilter: PropTypes.object
}

export default withRouter(DetailedView);
