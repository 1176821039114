import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { Utils } from '@common/utils';
import EditIcon from '@material-ui/icons/Edit';
import { Constantes } from "@common/utils/"


class EditField extends Component {

    static propTypes = {
        containerClassName: PropTypes.string,
        inputName: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        defaultValue: PropTypes.string,
        placeholder: PropTypes.string,
        buttonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        editAction: PropTypes.func.isRequired, 
        fieldName: PropTypes.string.isRequired,
        emptyPlaceholder: PropTypes.string,
        maxChars: PropTypes.number
    };

    static defaultProps = {
        containerClassName: "",
        inputName: Utils.generateUniqueID(),
        defaultValue: "",
        placeholder: "",
        isOpen: false,
        maxChars: 100
      };
    
    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.defaultValue,
            initialInputValue: props.defaultValue,
            saveEnabled: !!props.defaultValue,
            isOpen: props.isOpen
        }
        this.onChangeInput = this.onChangeInput.bind(this)
        this.onEditClick = this.onEditClick.bind(this)
        this.onSaveClick = this.onSaveClick.bind(this)
        this.onKeyPressed = this.onKeyPressed.bind(this)

    }

    onSaveClick(){
        let inputValue = this.state.inputValue ? this.state.inputValue.trim() : "";
        if(inputValue && inputValue !== this.state.initialInputValue){
            this.props.editAction({[this.props.fieldName]: inputValue});
        }        
        this.setState({initialInputValue: inputValue, isOpen: false});
    }

    onEditClick(){
        this.setState({isOpen: true});
    }

    onChangeInput(e){
        let { maxChars } = this.props;
        let value = e.target.value === " " ? "" : e.target.value;
        this.setState({ inputValue: value, saveEnabled: !!value })
    }

    onKeyPressed(e){
        if( e.key === Constantes.KEYBOARD.KEYS.ENTER && !!this.state.inputValue ){
            this.onSaveClick();
        }
    }
    

    render(){
        let { containerClassName, inputName, label, placeholder, buttonContent, editAction, fieldName} = this.props;
        
            return(<div className={containerClassName+" edit-field form"}>
            {label ? 
                <div className="edit-field__label">
                    <span onClick={!this.state.isOpen ? ()=>{this.setState({isOpen: true})} : ()=>{}}>{label}</span>
                </div>
            :null}    

            {this.state.isOpen ?
                <div className="edit-field--open">
                    <Input name={inputName} id={inputName} placeholder={placeholder} className="edit-field__input" onChange={this.onChangeInput} value={this.state.inputValue} onKeyPress={this.onKeyPressed}/> 
                {buttonContent ? 
                    <button disabled={!this.state.saveEnabled} 
                    className={"btn edit-field__button"} 
                    onClick={() => { editAction({[fieldName]: this.state.inputValue})}} 
                    id={"btn-"+inputName} name={"btn-"+inputName}>
                        {buttonContent}
                    </button>
                :
                    <div    className={ "edit-field__save " + ( this.state.saveEnabled ? "" : "disabled" ) }  
                            onClick={this.onSaveClick} >
                        Guardar
                    </div>
                }
                </div>:
                <div className="edit-field--closed" onClick={()=>{this.setState({isOpen: true})}}>
                    { this.state.initialInputValue ?
                        <span className="saved-value">{this.state.initialInputValue}</span>:
                        <span className="saved-value empty">{this.props.emptyPlaceholder}</span>
                    }
                    
                    <div className="open-button">
                        <EditIcon/>
                    </div>
                </div>
            }       
                
        </div>)
        
    }

}
export default EditField;


