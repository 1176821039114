import { Utils } from '@common/utils';
const ACTION_BASE_NAME = 'SHIPPING_TABLE';

export const REQUEST_SHIPPING_COMMUNES = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_SHIPPING_COMMUNES');
export const RECEIVE_SHIPPING_COMMUNES = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_SHIPPING_COMMUNES');
export const SELECTION_COST_TYPE = Utils.composeActionName(ACTION_BASE_NAME, 'SELECTION_COST_TYPE');
export const RECEIVE_SHIPPING_COMMUNES_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_SHIPPING_COMMUNES_ERROR');

export const requestShippingCommunesAction = () => ({
    type: REQUEST_SHIPPING_COMMUNES
});

export const receiveShippingCommunesAction = (data) => ({
    type: RECEIVE_SHIPPING_COMMUNES,
    payload: {
        data: data
    }
});

export const receiveShippingCommunesErrorAction = (error) => ({
    type: RECEIVE_SHIPPING_COMMUNES_ERROR,
    payload: {
        error: error
    }
});

export const selectionCostType = (selected) => ({
    type: SELECTION_COST_TYPE,
    payload: {
        costType: selected
    }
});