import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import ShippingSelect from "./ShippingSelect";
import { change } from "redux-form";
import { Constantes } from "@common/utils/";
import PropTypes from "prop-types";
import { find } from "lodash";

class ShippingFilter extends PureComponent {
    static propTypes = {
        defaultShippingType: PropTypes.string.isRequired,
        defaultCommune: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.fetchProductItems = this.fetchProductItems.bind(this);
        this.getStyleSelection = this.getStyleSelection.bind(this);
        this.getTitleButton = this.getTitleButton.bind(this);
        this.onCLickShippingFilter = this.onCLickShippingFilter.bind(this);
        this.getShippingType = this.getShippingType.bind(this);
        this.getCommunesFilter = this.getCommunesFilter.bind(this);
        this.getCommuneSelected = this.getCommuneSelected.bind(this);
        this.getSendFilter = this.getSendFilter.bind(this);
        
    }

    getTitleButton() {
        let { shippingType } = this.props.currentFilter;
        if (!shippingType) {
            return "Envío gratis";
        }
        if (shippingType === Constantes.SHIPPING_TYPE.COST) {
            return "Envío gratis";
        }
        return "Envío en 1 día";
    }

    getStyleSelection(style, typeView) {
        let { shippingType } = this.props.currentFilter;
        if (!shippingType) {
            shippingType = this.props.defaultShippingType;
        }
        if (shippingType === typeView) {
            return style + " select";
        }
        return style;
    }

    getShippingType(filterOption, value) {
        if (Constantes.FILTER_SHIPPING_OPTIONS.SHIPPING_TYPE === filterOption) {
            this.props.onSelectShippingType(value);
            this.props.onSendCost(false);
            this.props.onSendShipment(false);
            this.setState({ activeSend: false });
            return {
                shippingType: value,
                sendShipment: false,
                sendCost: false,
            };
        }
    }

    getCommunesFilter(filterOption, value) {
        if (Constantes.FILTER_SHIPPING_OPTIONS.COMMUNES === filterOption) {
            this.props.onSelectCommune(value);
            return {
                commune: value,
            };
        }
    }

    getSendFilter(filterOption) {
        let { sendShipment, sendCost, shippingType } = this.props.currentFilter;
        if (Constantes.FILTER_SHIPPING_OPTIONS.SEND === filterOption) {
            const shippingTypeAux = shippingType ? shippingType : Constantes.SHIPPING_TYPE.COST;
            const values = {
                shippingType: shippingTypeAux,
                sendShipment:
                    shippingTypeAux === Constantes.SHIPPING_TYPE.SHIPMENT
                        ? !sendShipment
                        : false,
                sendCost:
                    shippingTypeAux === Constantes.SHIPPING_TYPE.COST
                        ? !sendCost
                        : false,
            };
            this.props.onSendCost(values.sendCost);
            this.props.onSendShipment(values.sendShipment);
            return values;
        }
    }

    onCLickShippingFilter(filterOption, value) {
        const valuesShippingType = this.getShippingType(filterOption, value);
        const valuesCommunes = this.getCommunesFilter(filterOption, value);
        const valuesSendShipping = this.getSendFilter(filterOption);

        let currentFilter = {
            ...this.props.currentFilter,
            ...valuesShippingType,
            ...valuesCommunes,
            ...valuesSendShipping,
        };
        this.fetchProductItems(currentFilter, {
            ...valuesShippingType,
            ...valuesCommunes,
            ...valuesSendShipping,
        });
    }

    fetchProductItems(currentFilter, fields) {
        Object.keys(fields).forEach((key) => {
            this.props.dispatch(
                change(Constantes.FORM_NAMES.FILTERS_FORM, key, fields[key])
            );
        });
        currentFilter.paginationCurrentPage = 1;
        this.props.fetchProductItems(currentFilter);
    }

    getCommuneSelected() {
        const { commune } = this.props.currentFilter;
        const { communes } = this.props;
        let communeSelected = null;
        if (commune) {
            communeSelected = find(communes, ["id", parseInt(commune)]);
        } else {
            communeSelected = this.props.defaultCommune;
        }

        return communeSelected;
    }

    render() {
        const { communes, currentFilter } = this.props;
        const commune = this.getCommuneSelected();
        const { sendCost, sendShipment } = currentFilter;
        return (
            <>
                <div>
                    <div className="shipping-switch">
                        <span className="span-info">
                            Información de Shipping
                        </span>
                        <div className="container-switch">
                            <div
                                className={this.getStyleSelection(
                                    "btn-cost",
                                    Constantes.SHIPPING_TYPE.COST
                                )}
                                onClick={() => {
                                    this.onCLickShippingFilter(
                                        Constantes.FILTER_SHIPPING_OPTIONS
                                            .SHIPPING_TYPE,
                                        Constantes.SHIPPING_TYPE.COST
                                    );
                                }}
                            >
                                <span>Costo</span>
                            </div>
                            <div
                                className={this.getStyleSelection(
                                    "btn-shipment",
                                    Constantes.SHIPPING_TYPE.SHIPMENT
                                )}
                                onClick={() => {
                                    this.onCLickShippingFilter(
                                        Constantes.FILTER_SHIPPING_OPTIONS
                                            .SHIPPING_TYPE,
                                        Constantes.SHIPPING_TYPE.SHIPMENT
                                    );
                                }}
                            >
                                <span>Tiempo</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="select-commune">
                    <span>Comuna</span>
                    <ShippingSelect
                        itemList={communes}
                        selected={commune}
                        onClickEvent={(comunes) => {
                            this.onCLickShippingFilter(
                                Constantes.FILTER_SHIPPING_OPTIONS.COMMUNES,
                                comunes
                            );
                        }}
                    />
                </div>
                <div className="container-btn-free-shipment">
                    <button
                        className={sendCost || sendShipment ? "btn-free-shipment-active" : "btn-free-shipment"}
                        onClick={() => {
                            this.onCLickShippingFilter(
                                Constantes.FILTER_SHIPPING_OPTIONS.SEND
                            );
                        }}
                    >
                        {this.getTitleButton()}
                    </button>
                </div>
            </>
        );
    }
}

export default withRouter(ShippingFilter);
