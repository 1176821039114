import { SHOW_MODAL, showModalAction, CLOSE_MODAL, closeModalAction } from './AlertModalActions';
import { resetAlertForm } from '../AlertForm/components/AlertFormBasic/AlertFormBasicState';

export const initialState = {
    isOpen: false
};
export const showAlertModal = (dispatch) => {
    dispatch(resetAlertForm());
    return dispatch(showModalAction());
};
export const closeAlertModal = (dispatch) => {
    return dispatch(closeModalAction());
};

export const AlertModalStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};

    switch (action.type) {

        case SHOW_MODAL:
            return Object.assign({}, state, { isOpen: true });
        case CLOSE_MODAL:
            return Object.assign({}, state, {
                isOpen: false
            });
        default:
            return state;
    }
};

