import common_en from './en/common.json';
import common_es from './es/common.json';
import common_pt from './pt/common.json';

export default {
  en: {
    common: common_en,
  },
  es: {
    common: common_es,
  },
  pt: {
    common: common_pt
  }
};
