import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

export default class Label extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        className: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {};
        this.onClick = this.onClick.bind(this);
    }

    onClick() {}

    render() {
        const { className, name } = this.props;
        return (
            <Badge className={className} onClick={this.onClick}>
                {name}
            </Badge>
        );
    }
}
