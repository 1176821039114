import React from "react";
import ShippingCell from "./ShippingCell";
import ShippingCellTooltip from "./ShippingCellTooltip";
import { Constantes } from "@common/utils";

const ShippingPriceCell = (props) => {
    const { original, value, column } = props;
    const prices = Object.values(original)
          .filter(value => typeof value === 'object' && value !== null)
          .map(store => store.price)
          .filter(price => price !== '-')
          .filter(price => price !== '$0.000');

    const containsOnlyDigits = (str) => {
        return /^\d+$/.test(str);
    };

    const filterPrice = prices
        .filter((item) => containsOnlyDigits(item) || item.startsWith("$"))
        .filter((item) => item !== "-")
        .map((item) => item.replace("$", ""))
    
    const minProm = Math.min(...filterPrice);
    const maxProm = Math.max(...filterPrice);

    const freeShippingCount = Object.values(original)
            .filter(value => typeof value === 'object' && value !== null)
            .map(store => store.freeShippingCount)
            .filter(item => item !== undefined)
            .filter(item => item !== 0)

    const maxFreeShippingCount = Math.max(...freeShippingCount);

    const getColorClass = (prom) => {
        if (!prom) return "";

        const value = prom.replace("$", "").toString();
        if (value == minProm) {
            return column.costType === Constantes.TYPE_SHIPPING_REPORT.COST_PROM ? "prom--green" : "price--green";
        } else if (value == maxProm) {
            return  column.costType === Constantes.TYPE_SHIPPING_REPORT.COST_PROM ? "prom--red" : "price--red";
        } else if (value == "-") {
            return "table-without-data";
        } else if (value == "0.000") {
            return column.costType === Constantes.TYPE_SHIPPING_REPORT.COST_PROM ? "" : "price--free";
        } else {
            return column.costType === Constantes.TYPE_SHIPPING_REPORT.ALL ? "price--white" : "";
        }
    };

    const getColorClassShipmentFree = (value) => {
        if (value.freeShippingCount == maxFreeShippingCount) {
            return "shipment--green";
        }
        else if(value.price == "-") {
            return "table-without-data";
        }
        else {
            return "";
        }
    }

    const getShippingFree = (value) => {
        if (value.freeShippingCount == 0) {
            return (<div className="shipment-free">no hay productos con envío gratis</div>);
        }
        else if (value.freeShippingCount >= 1) {
            return value.freeShippingCount === maxFreeShippingCount && value.paidShippingCount == 0 ? "" : (<div className={`products-free ${colorClassShipmentFree}`}> <span className="count">{value.freeShippingCount}</span>productos envío gratis</div>);
        }
    }

    const colorClass = getColorClass(value.price);
    const colorClassShipmentFree = getColorClassShipmentFree(value);
    const renderShippingCell = () => {
        switch (column.costType) {
            case Constantes.TYPE_SHIPPING_REPORT.COST_PROM:
                return (
                    <div className={`prom ${colorClass}`}>
                        <ShippingCellTooltip props={props} /> 
                    </div>
                );
            case Constantes.TYPE_SHIPPING_REPORT.COST_FREE:
                return (
                    <div className={`prom ${colorClassShipmentFree}`}>
                        <ShippingCellTooltip props={props} />
                    </div>
                );
            default:
                return (
                    <div className="price">
                        <div className={`${colorClass}`}>
                            <ShippingCellTooltip props={props}/> 
                        </div>
                        <div>{getShippingFree(value)}</div>
                    </div>
                );
        }
    };
    
    const children = renderShippingCell();
    
    return (
        <ShippingCell
            className="price-cell"
            children={children}
            original={original}
            data-testid="shipping-cell"
        />
    );
};

export default ShippingPriceCell;
