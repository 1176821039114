import React, { PureComponent } from 'react';
import { Constantes } from '@common/utils'
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

export class ContentView extends PureComponent {

    static propTypes = {
        getReportView: PropTypes.func.isRequired,
        getProductListView: PropTypes.func.isRequired,
        getShippingView: PropTypes.func.isRequired,
        selectedView: PropTypes.string.isRequired,
        hasAccessShipping: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.getSelectedView = this.getSelectedView.bind(this);
    }

    getSelectedView() {
        let { selectedView } = this.props;
        if (selectedView === Constantes.TYPE_VIEW_RETAIL.REPORT) {
            return this.props.getReportView();
        } else if (selectedView === Constantes.TYPE_VIEW_RETAIL.PRODUCT_LIST) {
            return this.props.getProductListView();
        } else if (selectedView === Constantes.TYPE_VIEW_RETAIL.SHIPPING) {
            return this.props.hasAccessShipping ? this.props.getShippingView(): null;
        }
        return null;
    }

    render() {
        return this.getSelectedView();
    }
}


export default withRouter(ContentView);