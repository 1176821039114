import * as TableActions from "./TableActions";
import {
    RetailCompassStorage,
    AttributesUtils,
    BuildProductSearchFilters,
    FormatMatchedProducts,
    FormatReduxFormInitialValues,
    TableStateUtils,
    OperatorUtils,
    Constantes as Constants,
    Utils,
    OutOfStockReportCommon,
    Constantes,
    ModuleUtils,
    ShippingUtils,
    GaUtils
} from "@common/utils/";
import { ProductListService, ShippingService } from "@common/data-access";
import { find, isEmpty } from "lodash";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { isValidOption } from "./ViewPricesUtil";
import * as ShippingActions from "../shipping/ShippingActions";

export const initialState = {
    isFetching: false,
    paginationCurrentPage: 1,
    paginationCount: 0,
    shippingCount: 0,
    productItems: [],
    isRestoringLastState: false,
    alternativeRequestSearchFilter: false,
    emptyStateType: null,
    lastRequestTimestamp: new Date().getTime(),
    isRestoringInitialState: false,
    lastFilterWithProducts: false,
    brands: [],
    stores: [],
    models: [],
    attributes: [],
    dynamicAttributes: [],
    screenSizeItems: [],
    lastFilter: {},
    lastSearchRequest: {},
    sort: null,
    reduxFormInitialValues: {},
    brandIds: null,
    hasProducts: false,
    brandsResult: null,
    productDetailOpened: false,
    currentProduct: null,
    isDownloading: false,
    initialRequest: null,
    mustForceProductListUpdate: false,
    myAggregations: null,
    total: null,
    forceRepeatSearch: false,
    searchType: Constants.GENERIC,
    searchTypeToRepeat: Constants.NONE,
    sellerType: [],
    activeRanking: RetailCompassStorage.getActiveRanking(),
    showComparerTooltip: false,
    isModelSearchDone: false,
    isLoadingStarCategories: false,
    starCategories: {},
};

// the first request is used to load all brands and all retailers...
// when the first request is completed, a following request is made to obtain the list of products
let isFirstRequest = true;

// helper for testing
export const setFirstRequest = (isFirst) => {
    isFirstRequest = isFirst;
};

export const forceAbortController = () => {
    abortController && abortController.abort();
};

const getFavoriteIdOrNull = (payload) => {
    let favoriteId = null;
    if (payload.favoriteId) {
        favoriteId = payload.favoriteId;
    }
    return favoriteId;
};

export const configureSearch = (search, searchType) => {
    let response = { skus: undefined, keyWords: undefined, search, searchType };
    if (search) {
        if (searchType === Constants.SearchType.SKUS) {
            response.skus = Utils.getSkuArray(search);
        } else if (searchType === Constants.SearchType.KEYWORDS) {
            response.keyWords = search;
        }
    }
    return response;
};

/**
 * la agregación recibe la configuración total de los rangos para construir el resumen.
 * inicialmente esta función solo será usada cuando estamos en reportes
 */
export const buildOutOfStockAggregationRanges = () => {
    let config = OutOfStockReportCommon.buildCounterItemsConfig({});
    return config && config.map((item) => item.range);
};

export const differenceListViews = (selectedView) => {
    return (
        selectedView &&
        selectedView !== Constants.TYPE_VIEW_RETAIL.PRODUCT_LIST &&
        selectedView !== Constants.TYPE_VIEW_RETAIL.SHIPPING
    );
};

export const consumerByViewAndStoreType = (selectedView, selectedReport, subStoreType) => {
    const isShippingViewSelected = TableStateUtils.isShippingView(selectedView, selectedReport);
    if (isShippingViewSelected) return Constantes.CONSUMER.SCALIBUR_SHIPPING;
    
    const isPostpaidViewSelected =
        subStoreType === Constantes.SubStoreType.BRAND_POSTPAID ||
        subStoreType === Constantes.SubStoreType.RETAILER_POSTPAID;
    if (isPostpaidViewSelected) return Constantes.CONSUMER.POST_PAID;
    
    return Constantes.CONSUMER.SCALIBUR;
}

export const storeTypeByView = (storeType, selectedView, selectedReport) => {
    const isShippingViewSelected = TableStateUtils.isShippingView(selectedView, selectedReport);

    if (isShippingViewSelected) {
        if (storeType === Constantes.StoreType.BRAND) {
            return Constantes.StoreType.SHIPPING_PROVIDER;
        } else if (storeType === Constantes.StoreType.RETAILER) {
            return Constantes.StoreType.SHIPPING_RETAILER;
        }
    } else {
        return storeType;
    }
};

export const defaultSort = (selectedView, isFirstRequest) => {
    if (isFirstRequest) {
        return Constants.SortValues.PRODUCT_RELEVANCE;
    }
    if (selectedView === Constants.TYPE_VIEW_RETAIL.SHIPPING) {
        return Constants.SortValues.SHIPPING_RELEVANCE;
    }
    return Constants.SortValues.PRODUCT_RELEVANCE;
};

//this method back to ids of the stores icnludes on the view
export const buildEntitiesInclude = (isOperator, selectedView, report) => {
    let entities = RetailCompassStorage.getIncludeStores();
    const isShippingViewSelected = TableStateUtils.isShippingView(selectedView, report);

    if (isOperator) {
        entities = RetailCompassStorage.getIncludeOperators();
    } else if (isShippingViewSelected) {
        entities = ModuleUtils.getCompetitorsIdsByModule(
            RetailCompassStorage.getModulesAccessConfig(),
            ModuleUtils.SHIPPING_MODULE
        );
    }

    return entities;
};

export const getSubReport = (subReport, storeType) => {
    if (subReport) {
        return subReport;
    }
    if (storeType === Constantes.StoreType.RETAILER) {
        return Constantes.TYPE_REPORT_RETAIL.RETAIL;
    } else if (storeType === Constantes.StoreType.BRAND) {
        return Constantes.TYPE_REPORT_RETAIL.BRANDS;
    }
};

export const getReportForViews = (payload, storeType) => {
    if (
        storeType === Constantes.StoreType.BRAND &&
        payload.selectedView === Constants.TYPE_VIEW_RETAIL.PRODUCT_LIST
    ) {
        payload.report = Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF;
        payload.subReport = Constantes.TYPE_REPORT_RETAIL.SHARE_OF_SHELF;
    }

    if (
        storeType === Constantes.StoreType.RETAILER &&
        payload.selectedView === Constants.TYPE_VIEW_RETAIL.PRODUCT_LIST
    ) {
        payload.report = Constantes.TYPE_REPORT_RETAIL.COMPETITIVENESS;
        payload.subReport = Constantes.TYPE_REPORT_RETAIL.RETAIL;
    }

    if (
        storeType === Constantes.StoreType.SHIPPING_PROVIDER ||
        storeType === Constantes.StoreType.SHIPPING_RETAILER
    ) {
        payload.report = Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST;
        payload.subReport = Constantes.TYPE_REPORT_RETAIL.SHIPPING_COST;
    }
};

let abortController = new window.AbortController();
export const fetchProductItems = (dispatch, payload) => {
    const startTime = GaUtils.printTimeToUse();
    let isFirstRequestAlternative = isFirstRequest;
    let outOfStockRangesConfig = buildOutOfStockAggregationRanges();
    payload = payload || {};
    payload.applicationData = payload.applicationData || {};
    payload.applicationData.outOfStockRangesConfig = outOfStockRangesConfig;
    payload = {
        ...payload,
        ...configureSearch(payload.search, payload.searchType),
    };
    // parametros que fueron obtenidos por url y posteriormente convertidos
    // al formato requerido por redux-form y `payload`
    payload.reduxFormInitialValues = payload.reduxFormInitialValues || {};
    let includeReduxFormInitialValues =
        Object.keys(payload.reduxFormInitialValues).length > 0;
    let alternativeRequestSearchFilter =
        !!payload.alternativeRequestSearchFilter;

    abortController.abort();
    abortController = new window.AbortController();
    const selectedView = payload.selectedView;
    const userStoreId = RetailCompassStorage.getStoreId();
    const canonicalCategory =
        RetailCompassStorage.getCurrentCanonicalCategory();
    const canonicalStoreId = canonicalCategory.storeId;
    const canonicalCategoryPath = canonicalCategory.path;
    const brandsIds = RetailCompassStorage.getBrandIds();
    const ranges = canonicalCategory.rangesPrice;
    const userId = RetailCompassStorage.getUser()
        ? RetailCompassStorage.getUser().id
        : null;
    const storeType = storeTypeByView(RetailCompassStorage.getStoreType(), selectedView, payload.report);
    const currentCategoryIsOperator = OperatorUtils.currentCategoryIsOperator();
    const entitiesIncluded = buildEntitiesInclude(currentCategoryIsOperator, selectedView, payload.report);
    
    const subStoreType = currentCategoryIsOperator ? Constants.SubStoreType.RETAILER_POSTPAID : null;
    const baseAggregationsType = currentCategoryIsOperator ? subStoreType : storeType;

    // se obtiene el default report para las vistas: product list y shipping
    getReportForViews(payload, storeType);

    payload.typePriceRange = currentCategoryIsOperator
        ? Constantes.TYPE_RANGE_PRICES.CANONICAL_POSTPAID
        : Constantes.TYPE_RANGE_PRICES.CANONICAL;

    if (
        currentCategoryIsOperator &&
        isEmpty(payload[Constantes.FILTER_OPERATORS])
    ) {
        payload.disablePuttingOperatorKeysInUrl = true;
        payload[Constantes.FILTER_OPERATORS] = entitiesIncluded.map((e) => {
            return { value: e };
        });
    }

    let body = {
        consumer: consumerByViewAndStoreType(selectedView, payload.report, subStoreType),
        viewType: "PRICING",
        storeIdObserver: userStoreId,
        page: 0,
        size: 20,
        stores: [
            {
                storeId: canonicalStoreId,
                children: false,
            },
        ],
        categoryPath: canonicalCategoryPath,
        statuses: [1, -1],
        sort: {
            field: defaultSort(selectedView, isFirstRequest),
            order: Constants.SORT_TYPE.DESC,
            brandIds: brandsIds,
            favoriteId: getFavoriteIdOrNull(payload),
        },
        shippingFilter: {},
        filters: {
            brands: {
                size: 70,
                includeOthers: false,
                includeStats: false,
                groupBy: "PARENT_BRAND",
                sort: "COUNT",
            },
        },
        userId: userId,
        userTimeZoneID: momentTimezone.tz.guess(true),
        // por ahora, esta es la única agregación que debe enviarse luego de la primera petición...
        // es usada para poder cargar el grafico de productos en el mercado cada vez que el usuario activa un filtro.
        aggregations: TableStateUtils.BASE_AGGREGATIONS[baseAggregationsType], 
        // se agregó para poder enviar información especifica para las agregaciones
        // de momento siempre enviaremos la configuración de rangos de stock
        aggregationsFiltersInput: { outOfStockRanges: outOfStockRangesConfig },
        storeType: storeType,
        onlyAvailableProducts: isFirstRequest
            ? false
            : payload.onlyAvailableProducts,
    };

    if (!isFirstRequest && !currentCategoryIsOperator) {
        if (
            Utils.isArrayWithData(payload.viewPrices) &&
            isValidOption(payload.viewPrices)
        ) {
            body.behaviour = { prices: payload.viewPrices };
        } else {
            body.behaviour = { prices: RetailCompassStorage.getPriceView() };
        }
    }

    let sortType = storeType;

    if (currentCategoryIsOperator) {
        sortType = Constantes.SUB_STORE_TYPE_BY_STORE_TYPE[storeType];
        body.subStoreType = Constantes.SUB_STORE_TYPE_BY_STORE_TYPE[storeType];
        if (Utils.isArrayWithData(payload.models) && !isFirstRequest)
            body.search = OperatorUtils.getModelsForRequest(payload.models);
    }

    let formatSort = BuildProductSearchFilters.SORT_STRATEGIES[sortType];
    if (formatSort) {
        body.sort = formatSort(body.sort, storeType);
    }

    if (Utils.isArrayWithData(ranges)) {
        body.ranges = ranges;
    }

    if (payload.report === Constants.TYPE_REPORT_RETAIL.DISCOUNTS) {
        body.aggregationsFiltersInput = {
            rangesPromotional: TableStateUtils.getRangesPromotional(),
            promotionalType: TableStateUtils.getPromotionalType(
                payload.subReport
            ),
        };
    }

    if (payload.report === Constants.TYPE_REPORT_RETAIL.SHIPPING_COST) {
        if (payload.communes?.length) {
            const ids = payload.communes.map((commune) => commune.id);
            body.aggregationsFiltersInput = {
                communes: ids,
            };
        }
    }

    if (payload.rankingFilter) {
        body.rankingFilter = {
            type: "TOP",
            top: payload.rankingFilter,
        };
    }

    body.first = isFirstRequest;
    dispatch(TableActions.requestBaseSearchRequest(body));

    const { allStoreIdsItems, allStoreItems } =
        Utils.extractPropsFromApplicationData(payload.applicationData);

    if (
        !isFirstRequest &&
        !Utils.isArrayWithData(payload.stores) &&
        Utils.isArrayWithData(allStoreItems)
    ) {
        payload.disablePuttingStoreIdInUrl = true;
        payload.stores = allStoreItems;
    }

    let currentUserFilter = {};

    if (isFirstRequest) {
        // it is not necessary to load the products.
        body.size = 0;

        if (!currentCategoryIsOperator) {
            body.aggregations =
                TableStateUtils.getAttributesAggregationsForProductSearch(
                    storeType
                );
            BuildProductSearchFilters.buildAggregationRangePrice(body, ranges);
        }
    } else {
        let buildAggregationForReport =
            BuildProductSearchFilters.REPORT_AGGREGATIONS_STRATEGIES[storeType];
        if (
            Utils.isFunction(buildAggregationForReport) &&
            differenceListViews(selectedView)
        ) {
            body.size = 0;
            body.aggregations = buildAggregationForReport(
                body.aggregations,
                getSubReport(payload.subReport, storeType)
            );
        }

        let communeFilter = [];
        if (TableStateUtils.isShippingView(selectedView, payload.report)) {
            if (Utils.isArrayWithData(payload.communes)) {
                if (payload.commune) {
                    let commune = payload.communes.filter(
                        (commune) => commune.id === payload.commune
                    );
                    communeFilter.push(commune[0]);
                } else {
                    let commune = payload.communes.filter(
                        (commune) => commune.id === payload.defaultCommune
                    );
                    communeFilter.push(commune[0]);
                }
            }
        }

        currentUserFilter = BuildProductSearchFilters.buildProductSearchFilters(
            payload,
            allStoreIdsItems,
            brandsIds,
            storeType,
            userStoreId,
            RetailCompassStorage.getMyOperators(),
            RetailCompassStorage.getDefaultCommune(),
            communeFilter
        );
    }

    dispatch(TableActions.requestChangeLastFilter(payload));
    dispatch(TableActions.mustForceProductListUpdateAction(false));
    dispatch(TableActions.buttonBackPressedNavigate(false));

    body = { ...body, ...currentUserFilter };
    let allowedToFilterDirectMatches = Utils.getStoresOrEmpty(body).length > 0;

    if (body.page === 0) {
        dispatch(
            TableActions.requestChangePage(initialState.paginationCurrentPage)
        );
    } else {
        dispatch(TableActions.requestChangePage(payload.paginationCurrentPage));
    }
    const receiveCallback = (response) => {
        let ignoreEmptyState = true;

        if (isFirstRequest) {
            isFirstRequest = false;
            response.total = 0;
            let storesFirstRequest = TableStateUtils.formatStoresFromAPIInclude(
                response.aggregations,
                entitiesIncluded,
                storeType,
                true
            );
            const communesFirst = TableStateUtils.formatCommunesFromAPI(
                response.aggregations,
                userStoreId
            );
            payload.applicationData.stores = storesFirstRequest;
            payload.defaultCommune = Utils.isArrayWithData(communesFirst)
                ? communesFirst[0].id
                : null;
            payload.communes = communesFirst;

            fetchProductItems(dispatch, payload);
            fetchShipping(dispatch, communesFirst);
            
        } else {
            ignoreEmptyState = false;
            if (response.total === 0 && Utils.isArrayWithData(payload.skus)) {
                return fetchProductItems(dispatch, {
                    ...payload,
                    skus: [],
                    search: payload.search,
                    searchType: Constants.SearchType.KEYWORDS,
                    alternativeRequestSearchFilter: true,
                });
            }
        }

        if (response.total !== 0) {
            dispatch(TableActions.requestChangeLastFilterWithProducts(payload));
        }

        return TableActions.receiveProductSearchAction({
            startTime: startTime,
            ignoreEmptyState: ignoreEmptyState,
            alternativeRequestSearchFilter: alternativeRequestSearchFilter,
            response: response,
            requestBody: {
                ...body,
                allowedToFilterDirectMatches: allowedToFilterDirectMatches,
                includeReduxFormInitialValues: includeReduxFormInitialValues,
                reduxFormInitialValues: payload.reduxFormInitialValues,
                isFirstRequest: isFirstRequestAlternative,
                entitiesIncluded: entitiesIncluded,
            },
        });
    };

    const receiveAbortErrorCallback = (payload) => {
        return TableActions.receiveProductSearchAbortErrorAction(payload);
    };

    if (body.keyWords && body.keyWords.length > 0) {
        delete body.sort;
    }
    dispatch(TableActions.requestChangeLastSearchRequest(body));
    const signal = abortController.signal;

    TableStateUtils.verifyAttributesPermissions(body);

    return ProductListService.fetchProductItems({
        signal,
        dispatch,
        data: body,
        requestCallback: TableActions.requestProductSearchAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: TableActions.receiveProductSearchErrorAction,
        receiveAbortErrorCallback: receiveAbortErrorCallback,
    });
};

export const fetchShipping = (dispatch, payload) => {
    return ShippingService.fetchCommunesNames({
        dispatch,
        data: payload,
        requestCallback: ShippingActions.requestShippingCommunesAction,
        receiveCallback: ShippingActions.receiveShippingCommunesAction,
        receiveErrorCallback: ShippingActions.receiveShippingStoreErrorAction
    });
}

export const formatMatchedProducts = (
    userBrandItems,
    matchedProducts,
    requestBody,
    currentFilter
) => {
    let params = {
        storeId: RetailCompassStorage.getStoreId(),
        userBrandItems,
        matchedProducts,
        requestBody,
        currentFilter,
    };
    return FormatMatchedProducts.formatMatchedProducts(
        RetailCompassStorage.getStoreType(),
        params
    );
};

const getAttributeSelectedData = ({ payload }, { dynamicAttributes }) =>
    dynamicAttributes.map((item) => {
        if (item.id === payload.id) {
            item.selectedData = payload.selectedData;
        }
        return item;
    });

let downloadAbortController = new window.AbortController();
export const downloadFile = (dispatch, payload) => {
    downloadAbortController.abort();
    downloadAbortController = new window.AbortController();

    const canonicalCategory =
        RetailCompassStorage.getCurrentCanonicalCategory();

    let searchRequest = payload.searchRequest;
    searchRequest.aggregations =
        TableStateUtils.getAttributesAggregationsForExportService();

    const size = searchRequest.size;
    const currentDate = moment().format("YYYY-MM-DD");
    const filename =
        canonicalCategory.name + "_" + currentDate + "_" + size + "productos";

    let body = {
        searchRequest: JSON.stringify(searchRequest),
        moduleType: payload.moduleType,
        subStoreType: payload.searchRequest.subStoreType,
        // se envía para poder usar cuotas en el caso de AR.
        countryCode: RetailCompassStorage.getCountryCode(),
        // se envía para validar el tipo de usuario en la visibilidad de atributos.
        userId: payload.userId,
        userTimeZoneID: momentTimezone.tz.guess(true),
        enableProductRanking: RetailCompassStorage.isEnableProductRanking(),
        onlyAvailableProducts: payload.searchRequest.onlyAvailableProducts,
        outOfStockRanges: JSON.stringify(
            payload.searchRequest.outOfStockRanges
        ),
        storeType: payload.searchRequest.storeType,
        shippingFilter: JSON.stringify(payload.searchRequest.shippingFilter),
    };
    const signal = downloadAbortController.signal;
    return ProductListService.fetchDownload({
        signal,
        dispatch,
        data: body,
        filename: filename,
        requestCallback: TableActions.requestDownloadExportServiceAction,
        receiveCallback: TableActions.receiveDownloadExportServiceAction,
        receiveErrorCallback:
            TableActions.receiveDownloadExportServiceErrorAction,
        receiveAbortErrorCallback:
            TableActions.receiveDownloadExportServiceAbortErrorAction,
    });
};

let starCategoriesController = new window.AbortController();
export const getStarCategories = (dispatch, payload) => {
    starCategoriesController.abort();
    starCategoriesController = new window.AbortController();
    const { categoriesIds } = payload;
    const body = { categoriesIds };
    
    const receiveCallback = (payload) => {
        return TableActions.receiveStarCategoriesFullPathAction(payload);
    };
    
    return ProductListService.fetchStarCategoriesFullPath({
        dispatch,
        signal: starCategoriesController.signal,
        data: body,
        requestCallback: TableActions.requestStarCategoriesFullPathAction,
        receiveCallback: receiveCallback,
        receiveErrorCallback: TableActions.receiveStarCategoriesErrorFullPathAction,
        receiveAbortErrorCallback:
            TableActions.receiveStarCategoriesAbortErrorFullPathAction,
    });
};

export const validateStarCategoriesRequest = (dispatch, canonicalProducts) => {
    const isRankingAllowded = RetailCompassStorage.isEnableProductRanking();
    if (isRankingAllowded && canonicalProducts.length) {
        let categoriesIds = new Set();
        canonicalProducts.forEach((canonical) => {
            const directMatchesFilter = canonical.directMatches.filter(hasRanking);
            directMatchesFilter.forEach((match) => {
                match.rankings.forEach((ranking) => {
                    categoriesIds.add(ranking.categoryId);
                });
            });
        });
        if (categoriesIds.size) {
            getStarCategories(dispatch, { categoriesIds: Array.from(categoriesIds) });
        }
    }
};

const hasRanking = (match) =>
    match.statusProduct === Constantes.STATUS_PRODUCT.WITH_STOCK &&
    Utils.isArrayWithData(match.rankings);

export const buildInitialOrderMap = (stores) => {
    let initialOrderMap = {};
    stores.forEach((item) => {
        initialOrderMap[item.storeId] = item.initialOrder;
    });
    return initialOrderMap;
};

export const joinAndMapStoresCommon = (
    aggregations,
    formatStores,
    stateStores,
    userBrandIdsItems
) => {
    let listStores = TableStateUtils.unionItems(
        "storeId",
        formatStores,
        stateStores
    );
    return mapAggregations(listStores, aggregations, userBrandIdsItems);
};

export const mapAggregations = (
    listStores,
    aggregations,
    userBrandIdsItems
) => {
    return listStores
        .map((storeItem) =>
            TableStateUtils.mapBrandShareInfo(
                storeItem,
                aggregations,
                userBrandIdsItems
            )
        )
        .map((storeItem) =>
            TableStateUtils.mapOutOfStockReportSummary(
                storeItem,
                aggregations,
                userBrandIdsItems
            )
        )
        .map((storeItem) =>
            TableStateUtils.mapOutOfStockBrandsByRetailer(
                storeItem,
                aggregations
            )
        );
};

const convertStarCategoryMap = (starCategoriesArray) => {
    if (!Utils.isArrayWithData(starCategoriesArray)) {
        return new Map();
    }
    let starCategoriesMap = new Map();
    starCategoriesArray.forEach(starCategory => {
        starCategoriesMap.set(starCategory.id, { fullPath: starCategory.fullPath, url: starCategory.url});
    });
    return starCategoriesMap;
}

const trackLoadTime = (startTime, endTime) => {
    const time = endTime - startTime;
    GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.PERFOMANCE, Constantes.GA_ACTION_EVENTS.LOAD_TIME_SERVICE, null, Math.round(time));
    
    const initTime = GaUtils.initLoadTime();
    if (initTime > 0) {
        GaUtils.trackGAEvent(Constantes.GA_CATEGORY_EVENTS.PERFOMANCE, Constantes.GA_ACTION_EVENTS.LOAD_TIME_INIT, null, Math.round(initTime));
    }
}

export const TableStateReducer = (state, action) => {
    state = state || initialState;
    action = action || {};
    switch (action.type) {
        case TableActions.RESET_STORE:
            setFirstRequest(true);
            return Object.assign({}, initialState);
        case TableActions.RESET_STORE_FOR_SHIPPING:
            setFirstRequest(true);
            return Object.assign(
                {},
                { ...initialState, dynamicAttributes: state.dynamicAttributes }
            );
        case TableActions.REQUEST_PRODUCT_SEARCH:
            return Object.assign({}, state, {
                isFetching: true,
                currentProduct: null,
                productDetailOpened: false,
            });
        case TableActions.RECEIVE_PRODUCT_SEARCH:
            const response = action.payload.response;
            const requestBody = action.payload.requestBody;
            const ignoreEmptyState = action.payload.ignoreEmptyState;
            const alternativeRequestSearchFilter =
                action.payload.alternativeRequestSearchFilter &&
                action.payload.searchType === Constants.SearchType.KEYWORDS;
            const storeId = RetailCompassStorage.getStoreId();
            const userBrandItems = RetailCompassStorage.getBrands().allBrands;
            const storeType = RetailCompassStorage.getStoreType();
            const shouldOrder = requestBody.isFirstRequest;
            const userBrandIdsItems = userBrandItems.map(
                (element) => element.id
            );
            const currentCategoryIsOperator =
                OperatorUtils.currentCategoryIsOperator();
            const rangesPromotionalData =
                TableStateUtils.buildRangesPromotionalData(
                    response.aggregations
                );
            const entitiesIncluded = requestBody.entitiesIncluded; 

            let newStores = TableStateUtils.formatStoresFromAPIInclude(
                response.aggregations,
                entitiesIncluded,
                storeType,
                shouldOrder
            );
            let newBrands = TableStateUtils.formatBrandsFromAPI(
                response.filters.brands
            );
            let brands = TableStateUtils.unionItems(
                "brandId",
                state.brands,
                newBrands
            )
                .map((item) =>
                    TableStateUtils.mapHaveItBrand(item, response, storeId)
                )
                .map((item) =>
                    TableStateUtils.mapComparisonPriceBrands(
                        item,
                        response,
                        storeId
                    )
                );

            const newCommunes = TableStateUtils.formatCommunesFromAPI(
                response.aggregations,
                storeId
            );
            const communes = TableStateUtils.unionItems(
                "id",
                state.communes,
                newCommunes
            );

            /* se guarda en initialOrderMap el orden inicial para poder recuperarlo cada vez que 
            el usuario realiza una petición y así poder ordenar cada tienda en su orden */
            let initialOrderMap;
            let stores = [];
            let newModels = [];
            let models = [];
            let mappingAggregations = [];
            const myStoresFormatted =
                TableStateUtils.formatStoresFromAPIInclude(
                    response.aggregations,
                    [storeId],
                    storeType,
                    shouldOrder
                );
            if (currentCategoryIsOperator) {
                stores = OperatorUtils.mapStores(
                    response.aggregations,
                    newStores,
                    state.stores
                );
                mappingAggregations = OperatorUtils.mapStores(
                    response.aggregations,
                    myStoresFormatted,
                    []
                );
                initialOrderMap = shouldOrder
                    ? OperatorUtils.buildInitialOrderMap(newStores)
                    : state.initialOrderMap;
                stores = OperatorUtils.orderByInitialOrder(
                    stores,
                    initialOrderMap
                );
                if (response.filters.postpaidSearchOptions) {
                    newModels = OperatorUtils.getModelsAndStorageForFilter(
                        response.filters.postpaidSearchOptions
                    );
                }
                models = TableStateUtils.unionItems(
                    "value",
                    state.models,
                    newModels
                );
            } else {
                stores = joinAndMapStoresCommon(
                    response.aggregations,
                    newStores,
                    state.stores,
                    userBrandIdsItems
                );
                mappingAggregations = joinAndMapStoresCommon(
                    response.aggregations,
                    myStoresFormatted,
                    [],
                    userBrandIdsItems
                );
                initialOrderMap = shouldOrder
                    ? buildInitialOrderMap(newStores)
                    : state.initialOrderMap;
                stores = TableStateUtils.orderByInitialOrderProp(
                    stores,
                    initialOrderMap
                );
            }

            const myStore = find(mappingAggregations, [
                "storeId",
                String(storeId),
            ]);
            let ranges = TableStateUtils.unionItems(
                "order",
                state.ranges,
                TableStateUtils.formatRangesMatchesFromAPI(
                    storeId,
                    brands,
                    response.aggregations
                )
            );
            let attributesVisualization =
                AttributesUtils.getAttributesVisualization(
                    response.filters.attributes
                );
            let dynamicAttributes = AttributesUtils.formatAttributesFromAPI(
                attributesVisualization,
                state.dynamicAttributes
            );
            dynamicAttributes = AttributesUtils.syncSelectedDataFromAttributes(
                dynamicAttributes,
                requestBody.reduxFormInitialValues &&
                    requestBody.reduxFormInitialValues.dynamicAttributes
            );
            let screenSizeItems = TableStateUtils.unionItems(
                "id",
                state.screenSizeItems,
                TableStateUtils.formatAttributeInchesFromAPI(
                    response.filters.attributes
                )
            );
            const hasProducts = response.total > 0;
            let reduxFormInitialValues = {};
            let myAggregations = find(response.aggregations, [
                "storeId",
                String(storeId),
            ]);
            myAggregations =
                myAggregations && myAggregations.aggregations
                    ? myAggregations.aggregations
                    : null;

            // este atributo se usa para los reportes de retail. usa una agregación distinta a la usada para los reportes de marca
            let pricesData =
                TableStateUtils.formatRangePriceReport(myAggregations);

            if (requestBody.includeReduxFormInitialValues) {
                reduxFormInitialValues =
                    FormatReduxFormInitialValues.formatReduxFormInitialValues(
                        requestBody.reduxFormInitialValues,
                        {
                            brands,
                            models,
                            stores,
                            screenSizeItems,
                            dynamicAttributes,
                            communes,
                        }
                    );
            }

            let shareByPriceRanges = TableStateUtils.formatShareByPriceRanges(
                myAggregations,
                userBrandIdsItems
            );
            let outOfStockReportSummary =
                TableStateUtils.formatOutOfStockReportSummary(
                    myAggregations,
                    userBrandIdsItems
                );
            let outOfStockBrandsByRetailer =
                TableStateUtils.formatOutOfStockBrandsByRetailer(
                    myAggregations
                );
            let productItems = [];

            try {
                productItems = formatMatchedProducts(
                    userBrandItems,
                    response.matchedProducts,
                    requestBody,
                    state.lastFilter
                );
            } catch (e) {
                console.error("error formatting products", e);
            }
           
            const newData = response.aggregations ? response.aggregations.map(obj => {
                const { storeId, name, aggregations } = obj;
                const { communes } = aggregations;
                return { storeId, name, communes };
            }) : [];
          
            const emptyStateType = TableStateUtils.getEmptyStateType(
                response,
                state.lastFilter,
                ignoreEmptyState,
                productItems
            );
            const defaultCommune = Utils.isArrayWithData(communes)
                ? communes[0]
                : null;
            RetailCompassStorage.setDefaultCommune(defaultCommune);

            const isLoadingShippingCost = !action.payload.requestBody.aggregations.includes(Constants.AGGREGATIONS_NAMES.SHIPPING_COST_BY_RETAIL);

            const startTime = action.payload.startTime;
            const endTime = GaUtils.printTimeToUse();
            trackLoadTime(startTime, endTime);

            return Object.assign({}, state, {
                isFetching: false,
                paginationCount: response.total,
                shippingCount: !shouldOrder
                    ? ShippingUtils.calculateTotalShipping(newCommunes)
                    : 0,
                productItems: productItems,
                emptyStateType: emptyStateType,
                alternativeRequestSearchFilter,
                brands: brands,
                lastRequestTimestamp: new Date().getTime(),
                stores: stores,
                myStore: myStore,
                brandsResult: response.filters.brands,
                hasProducts: hasProducts,
                brandIds: TableStateUtils.extractBrandIdsFromRequest(
                    action.payload.requestBody
                ),
                reduxFormInitialValues: reduxFormInitialValues,
                ranges: ranges,
                screenSizeItems: screenSizeItems,
                dynamicAttributes: dynamicAttributes,
                myAggregations: myAggregations,
                total: response.total,
                pricesData: pricesData,
                sellerType: requestBody.sellerType,
                initialOrderMap,
                shareByPriceRanges: shareByPriceRanges,
                rangesPromotionalData: rangesPromotionalData,
                outOfStockReportSummary: outOfStockReportSummary,
                outOfStockBrandsByRetailer: outOfStockBrandsByRetailer,
                models: models,
                communes: communes,
                defaultCommune: defaultCommune,
                shippingTable: newData,
                isLoadingShippingCost: isLoadingShippingCost,
            });
        case TableActions.RECEIVE_PRODUCT_SEARCH_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                paginationCount: 0,
                emptyStateType: Constants.EMPTY_STATE_TYPE.SERVER_ERROR,
                productItems: [],
                error: action.payload.error,
            });

        case TableActions.RECEIVE_PRODUCT_SEARCH_ABORT_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                currentProduct: null,
                productDetailOpened: false,
            });
        case TableActions.UPDATE_CURRENT_PRODUCT:
            return Object.assign({}, state, {
                currentProduct: action.payload.currentProduct,
            });
        case TableActions.REQUEST_CHANGE_PAGE:
            return Object.assign({}, state, {
                paginationCurrentPage: action.payload.page,
            });
        case TableActions.REQUEST_CHANGE_LAST_FILTER:
            return Object.assign({}, state, {
                lastFilter: { ...action.payload },
            });
        case TableActions.REQUEST_CHANGE_ATTRIBUTE_SELECTED_DATA:
            return Object.assign({}, state, {
                dynamicAttributes: getAttributeSelectedData(action, state),
            });
        case TableActions.REQUEST_CHANGE_LAST_SEARCH_REQUEST:
            return Object.assign({}, state, {
                lastSearchRequest: { ...action.payload },
            });
        case TableActions.REQUEST_CHANGE_LAST_FILTER_WITH_PRODUCTS:
            return Object.assign({}, state, {
                lastFilterWithProducts: { ...action.payload },
            });
        case TableActions.UPDATE_SORT:
            return Object.assign({}, state, { sort: action.payload.sort });
        case TableActions.REQUEST_BASE_SEARCH_REQUEST:
            let initialRequest = action.payload;
            initialRequest.matches = {
                contains: Constants.MATCHES.ALL,
                statusId: null,
                storeIds: [],
            };
            return Object.assign({}, state, {
                initialRequest: { ...initialRequest },
            });
        case TableActions.REQUEST_PRODUCT_DETAIL_OPENED:
            return Object.assign({}, state, {
                productDetailOpened: action.payload.productDetailOpened,
            });
        case TableActions.REQUEST_DOWNLOAD_EXPORT_SERVICE:
            return Object.assign({}, state, { isDownloading: true });
        case TableActions.RECEIVE_DOWNLOAD_EXPORT_SERVICE:
        case TableActions.RECEIVE_DOWNLOAD_EXPORT_SERVICE_ERROR:
        case TableActions.RECEIVE_DOWNLOAD_EXPORT_SERVICE_ABORT_ERROR:
            return Object.assign({}, state, { isDownloading: false });
        case TableActions.FORCE_PRODUCT_LIST_UPDATE:
            return Object.assign({}, state, {
                mustForceProductListUpdate:
                    action.payload.mustForceProductListUpdate,
            });
        case TableActions.BACK_BUTTON_PRESSED_NAVIGATE:
            return Object.assign({}, state, {
                backPressedNavigate: action.payload.backPressedNavigate,
            });
        case TableActions.UPDATE_ACTIVE_RANKING:
            return Object.assign({}, state, {
                activeRanking: action.payload.activeRanking,
            });
        case TableActions.UPDATE_COMPARER_TOOLTIP:
            return Object.assign({}, state, {
                showComparerTooltip: action.payload.showComparerTooltip,
            });
        case TableActions.UPDATE_MODEL_SEARCH_DONE:
            return Object.assign({}, state, {
                isModelSearchDone: action.payload,
            });
        case TableActions.REQUEST_STAR_CATEGORIES_FULL_PATH:
            return Object.assign({}, state, { isLoadingStarCategories: true });
        case TableActions.RECEIVE_STAR_CATEGORIES_FULL_PATH:
            return Object.assign({}, state, {
                isLoadingStarCategories: false ,
                starCategories: convertStarCategoryMap(action.payload),
            });
        case TableActions.RECEIVE_STAR_CATEGORIES_ERROR_FULL_PATH:
        case TableActions.RECEIVE_STAR_CATEGORIES_ABORT_ERROR_FULL_PATH:
            return Object.assign({}, state, { starCategories: new Map() });
        default:
            return state;
    }
};
