/* eslint-disable no-undef */
import { getId, getFullName, getEmail, isClientSuccessTrackable, getClientSuccessMap } from '../app/Storage';
import * as Constantes from '../utils/constantes';

const PROJECT_ID = "5b0ee7adc9e77c00012dea6d";

const API_KEY = "D6621C8B50F8C8B04B21C063CFE5A443B7DCF475333601FA666F6434D0F22112C11F8F3CD6E003EB33A8CA26CF6CE9E97721D31628349FFCCABBF23FABE3B54A12D89CDE0A575181175E5EEB9D258A8170C0A643C91F6C93DE47DC79DB681CB9";

export const SCRIPT_URL = "//cdn.clientsuccess.com/csTrack.min.js";

export const track = () => {

    if (!isClientSuccessTrackable()) {
        return;
    }

    const trackUser = () => {
        let user = getUserData();
        if (!user) {
            return;
        }
        let organization = getOrganizationData();
        window.csTrack = new CSTrack(PROJECT_ID, API_KEY);
        window.csTrack.identify({ organization, user });
        window.csTrack.trackEvent('login_success');
    }

    if (!window.csTrack) {
        try {
            load(trackUser);
        } catch (e) {
            console.error(e)
        }
    } else {
        trackUser();
    }
}

export const load = (onLoad) => {
    (function (w, d, s) {
        let head = d.getElementsByTagName('head')[0];
        let scriptTag = d.createElement('script');
        scriptTag.onload = onLoad;
        scriptTag.async = 1;
        scriptTag.src = s;
        head.appendChild(scriptTag);
    })(window, document, SCRIPT_URL)
}

export const getOrganizationData = () => {
    const data = getClientSuccessMap(Constantes.CLIENT_SUCCESS.MAP.LOGIN_SUCCESS);
    return {
        id: parseInt(data.organizationId),
        name: data.organizationName
    };
}

export const getUserData = () => {
    return {
        id: getId(),
        name: getFullName(),
        email: getEmail()
    }
}
