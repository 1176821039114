import { Utils } from '@common/utils/';

const ACTION_BASE_NAME = 'COMPARER';

export const RESET_STORE = Utils.composeActionName(ACTION_BASE_NAME, 'RESET_STORE');
export const REQUEST_PRODUCT_SEARCH = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_PRODUCT_SEARCH');
export const RECEIVE_PRODUCT_SEARCH = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH');
export const RECEIVE_PRODUCT_SEARCH_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_ERROR');
export const RECEIVE_PRODUCT_SEARCH_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_PRODUCT_SEARCH_ABORT_ERROR');

export const REQUEST_HISTORY = Utils.composeActionName(ACTION_BASE_NAME, 'REQUEST_HISTORY');
export const RECEIVE_HISTORY = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORY');
export const RECEIVE_HISTORY_EMPTY = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORY_EMPTY');
export const RECEIVE_HISTORY_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORY_ERROR');
export const RECEIVE_HISTORY_ABORT_ERROR = Utils.composeActionName(ACTION_BASE_NAME, 'RECEIVE_HISTORY_ABORT_ERROR');
export const CHANGE_SELECTED_VIEW = Utils.composeActionName(ACTION_BASE_NAME, 'CHANGE_SELECTED_VIEW');

export const CHANGE_SELECTED_DATE_RANGE = Utils.composeActionName(ACTION_BASE_NAME, 'CHANGE_SELECTED_DATE_RANGE');

export const CHANGE_SELECTED_INCLUDE_CARD_PRICES = Utils.composeActionName(ACTION_BASE_NAME, 'CHANGE_SELECTED_INCLUDE_CARD_PRICES');

export const requestProductSearchAction = () => ({
    type: REQUEST_PRODUCT_SEARCH
});

export const receiveProductSearchAction = (data) => ({
    type: RECEIVE_PRODUCT_SEARCH,
    payload: { ...data }
});

export const receiveProductSearchErrorAction = (error) => ({
    type: RECEIVE_PRODUCT_SEARCH_ERROR,
    payload: {
        error: error
    }
});

export const receiveProductSearchAbortErrorAction = (payload) => ({
    type: RECEIVE_PRODUCT_SEARCH_ABORT_ERROR,
    payload: { ...payload }
});

export const requestProductHistoryAction = () => ({
    type: REQUEST_HISTORY
});

export const receiveProductHistoryAction = (data) => ({
    type: RECEIVE_HISTORY,
    payload: { ...data }
});

export const receiveProductHistoryEmptyAction = () => ({
    type: RECEIVE_HISTORY_EMPTY,
    payload: {
        empty: true
    }
});

export const receiveProductHistoryErrorAction = (error) => ({
    type: RECEIVE_HISTORY_ERROR,
    payload: {
        error: error
    }
});

export const receiveProductHistoryAbortErrorAction = (payload) => ({
    type: RECEIVE_HISTORY_ABORT_ERROR,
    payload: { ...payload }
});

export const changeSelectedView = (payload) => {
    return {
        type: CHANGE_SELECTED_VIEW,
        payload: { ...payload }
    }
}

export const changeSelectedDateRange = (payload) => {
    return {
        type: CHANGE_SELECTED_DATE_RANGE,
        payload: { ...payload }
    }
}

export const changeIncludeCardPrices = (includeCardPrices) => {
    return {
        type: CHANGE_SELECTED_INCLUDE_CARD_PRICES,
        payload: { includeCardPrices }
    }
}

export const resetStoreAction = () => (
    {
        type: RESET_STORE
    }
);
