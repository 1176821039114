import * as ValidationActions from "../ValidationActions";
import { Utils } from "@common/utils/";

export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const requestProductSearchPotentialMatches = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: true,
        potentialMatchesEmptyState: false,      
        potentialMatches: []
    });
}

export const receiveProductSearchPotentialMatches = (state, action) => {
    const response = action.payload.response;
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: false,
        potentialMatchesEmptyState: !Utils.isArrayWithData(response), 
        potentialMatches: response
    });
}

export const receiveProductSearchPotentialMatchesError = (state, action) => {
    return updateObject(state, {
        hasError: false,
        error: null,
        isFetching: false,
        potentialMatchesEmptyState: true,
        potentialMatches: []
    });
}

export const handlers = {
    [ValidationActions.REQUEST_PRODUCT_SEARCH_POTENTIAL_MATCHES]: requestProductSearchPotentialMatches,
    [ValidationActions.RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES]: receiveProductSearchPotentialMatches,
    [ValidationActions.RECEIVE_PRODUCT_SEARCH_POTENTIAL_MATCHES_ERROR]: receiveProductSearchPotentialMatchesError
}

export default handlers