import { reset, initialize } from 'redux-form';
import queryString from 'qs';
import { converterUrlSearchDynamicAttributesToFormFields, converterFormFieldsDynamicAttributesToUrlSearchParams } from './reduxFormsAttributesUtils'
import { FILTER_OPERATORS, FORM_NAMES } from './constantes';
import { hasValue, stringToArray, getObjectKeysSize, formatNumber, isArrayWithData, getMkpVisibleValueFromCurrentCountry } from './utils';
import { getOperatorForFilter } from './OperatorUtils';

export const restoreState = (dispatch, params) => {
    params = params || {};

    dispatch(initialize(FORM_NAMES.FILTERS_FORM, { ...params }, {
        keepDirty: true,
        keepValues: false
    }));
    dispatch(initialize(FORM_NAMES.PRODUCT_LIST_MAIN_FILTERS_FORM, { ...params }, {
        keepDirty: true,
        keepValues: false
    }));
    dispatch(reset(FORM_NAMES.FILTERS_FORM));
    dispatch(reset(FORM_NAMES.PRODUCT_LIST_MAIN_FILTERS_FORM));
};

export const converterUrlSearchParamsToFormFields = (urlSearchParams) => {

    urlSearchParams = urlSearchParams || {};
    let formFields = {};

    formFields.stores = null;
    if (hasValue(urlSearchParams.stores)) {
        formFields.stores = stringToArray(urlSearchParams.stores).map((storeId) => ({ storeId: storeId + "", value: storeId + "", label: '' }));
    }
    formFields.brands = null;
    if (hasValue(urlSearchParams.brands)) {
        formFields.brands = stringToArray(urlSearchParams.brands).map((brandId) => ({ brandId: brandId, value: brandId, label: '' }));
    }
    formFields.models = null;
    if (hasValue(urlSearchParams.models)) {
        formFields.models = stringToArray(urlSearchParams.models).map((model) => ({ value: model, label: '' }));
    }
    formFields.screenSizeItems = null;
    if (hasValue(urlSearchParams.screenSizeItems)) {
        formFields.screenSizeItems = stringToArray(urlSearchParams.screenSizeItems).map((attrId) => ({ value: attrId, label: '' }));
    }
    formFields.labels = [];
    if (hasValue(urlSearchParams.labels)) {
        formFields.labels = stringToArray(urlSearchParams.labels).map((value) => (value));
    }
    formFields.sellerType = null;
    if (hasValue(urlSearchParams.sellerType) && getMkpVisibleValueFromCurrentCountry()) {
        formFields.sellerType = stringToArray(urlSearchParams.sellerType).map((itemId) => (itemId));
    }
    formFields.outOfStockRanges = null;
    if (hasValue(urlSearchParams.outOfStockRanges)) {
        formFields.outOfStockRanges = stringToArray(urlSearchParams.outOfStockRanges).map((itemId) => (itemId));
    }
    formFields.contractType = [];
    if (hasValue(urlSearchParams.contractType)) {
        formFields.contractType = stringToArray(urlSearchParams.contractType);
    }

    formFields.dynamicAttributes = converterUrlSearchDynamicAttributesToFormFields(urlSearchParams);

    formFields.skus = null;
    formFields.search = null;
    formFields.keyWords = null;
    if (hasValue(urlSearchParams.search)) {
        formFields.search = urlSearchParams.search;
    }
    if (hasValue(urlSearchParams.search) && hasValue(urlSearchParams.searchType)) {
        formFields.searchType = urlSearchParams.searchType;
    }
    if (hasValue(urlSearchParams.productAvailability)) {
        formFields.productAvailability = urlSearchParams.productAvailability;
    }
    formFields.favorites = null;
    if (urlSearchParams.favorites) {
        formFields.favorites = urlSearchParams.favorites;
    }

    formFields.haveIt = false;
    if (urlSearchParams.haveIt) {
        formFields.haveIt = true;
    }

    formFields.dontHaveIt = false;
    if (urlSearchParams.dontHaveIt) {
        formFields.dontHaveIt = true;
    }

    formFields.expensive = false;
    if (urlSearchParams.expensive) {
        formFields.expensive = true;
    }

    formFields.cheaper = false;
    if (urlSearchParams.cheaper) {
        formFields.cheaper = true;
    }

    formFields.same = false;
    if (urlSearchParams.same) {
        formFields.same = true;
    }
    if (urlSearchParams.selectedView) {
        formFields.selectedView = urlSearchParams.selectedView;
    }
    if (urlSearchParams.report) {
        formFields.report = urlSearchParams.report;
    }
    if (urlSearchParams.subReport) {
        formFields.subReport = urlSearchParams.subReport;
    }

    formFields.rankingFilter = null;
    const rankingFilter = formatNumber(urlSearchParams.rankingFilter);
    if (rankingFilter) {
        formFields.rankingFilter = urlSearchParams.rankingFilter;
    }

    formFields.from = null;
    formFields.to = null;
    const from = formatNumber(urlSearchParams.from);
    const to = formatNumber(urlSearchParams.to);
    if (from) {
        formFields.from = from;
    }
    if (to) {
        formFields.to = to;
    }

    formFields.onlyAvailableProducts = false;
    if (urlSearchParams.onlyAvailableProducts) {
        formFields.onlyAvailableProducts = true;
    }

    formFields[FILTER_OPERATORS] = null;
    if (urlSearchParams[FILTER_OPERATORS]) {
        formFields[FILTER_OPERATORS] = getOperatorForFilter(stringToArray(urlSearchParams[FILTER_OPERATORS]));
    }

    formFields.viewPrices = null;
    if (urlSearchParams.viewPrices) {
        formFields.viewPrices = urlSearchParams.viewPrices.split(",");
    }

    formFields.shippingType = null;
    if (urlSearchParams.shippingType) {
        formFields.shippingType = urlSearchParams.shippingType;
    }

    formFields.commune = null;
    if (urlSearchParams.commune) {
        formFields.commune = Number(urlSearchParams.commune);
    }

    formFields.sendCost = false;
    if (urlSearchParams.sendCost) {
        formFields.sendCost = true;
    }

    formFields.sendShipment = false;
    if (urlSearchParams.sendShipment) {
        formFields.sendShipment = true;
    }

    return formFields;
}

export const converterFormFieldsToUrlSearchParams = (formFields) => {
    formFields = formFields || {};
    let fields = {};

    if (hasValue(formFields.stores) && !formFields.disablePuttingStoreIdInUrl) {
        fields.stores = formFields.stores.map((item) => item.value).join(",");
    }
    if (hasValue(formFields.brands)) {
        fields.brands = formFields.brands.map((item) => item.value).join(",");
    }
    if (hasValue(formFields.models)) {
        fields.models = formFields.models.map((item) => item.value).join(",");
    }
    if (hasValue(formFields.screenSizeItems)) {
        fields.screenSizeItems = formFields.screenSizeItems.map((item) => item.value).join(",");
    }
    if (hasValue(formFields.sellerType)) {
        fields.sellerType = formFields.sellerType.join(",");
    }
    if (hasValue(formFields.outOfStockRanges)) {
        fields.outOfStockRanges = formFields.outOfStockRanges.join(",");
    }
    if (hasValue(formFields.labels)) {
        fields.labels = formFields.labels.map((item) => item).join(",");
    }
    if (isArrayWithData(formFields.productIds)) {
        fields.productIds = formFields.productIds.map((item) => item).join(",");
    }
    if (hasValue(formFields.contractType)) {
        fields.contractType = formFields.contractType.join(",");
    }

    fields = { ...fields, ...converterFormFieldsDynamicAttributesToUrlSearchParams(formFields) };

    if (hasValue(formFields.search)) {
        fields.search = formFields.search;
    }
    if (hasValue(formFields.search) && hasValue(formFields.searchType)) {
        fields.searchType = formFields.searchType;
    }
    if (hasValue(formFields.productAvailability)) {
        fields.productAvailability = formFields.productAvailability;
    }
    if (formFields.favorites) {
        fields.favorites = formFields.favorites;
    }

    if (formFields.haveIt) {
        fields.haveIt = true;
    }

    if (formFields.dontHaveIt) {
        fields.dontHaveIt = true;
    }

    if (formFields.expensive) {
        fields.expensive = true;
    }

    if (formFields.cheaper) {
        fields.cheaper = true;
    }

    if (formFields.same) {
        fields.same = true;
    }

    if (formFields.selectedView) {
        fields.selectedView = formFields.selectedView;
    }

    if (formFields.report) {
        fields.report = formFields.report;
    }
    if (formFields.subReport) {
        fields.subReport = formFields.subReport;
    }
    if (formFields.rankingFilter) {
        fields.rankingFilter = formFields.rankingFilter;
    }

    const from = formatNumber(formFields.from);
    const to = formatNumber(formFields.to);
    if (from) {
        fields.from = from;
    }
    if (to) {
        fields.to = to;
    }

    if (formFields.onlyAvailableProducts) {
        fields.onlyAvailableProducts = true;
    }

    if (hasValue(formFields[FILTER_OPERATORS]) && !formFields.disablePuttingOperatorKeysInUrl) {
        fields[FILTER_OPERATORS] = formFields[FILTER_OPERATORS].map(e => e.queryParam).join(",");
    }

    if (hasValue(formFields.viewPrices)) {
        fields.viewPrices = formFields.viewPrices.join(",");
    }

    if (formFields.shippingType) {
        fields.shippingType = formFields.shippingType;
    }
    
    if (formFields.commune) {
        fields.commune = formFields.commune;
    }

    if (formFields.sendCost) {
        fields.sendCost = true;
    }

    if (formFields.sendShipment) {
        fields.sendShipment = true;
    }

    if (getObjectKeysSize(fields) > 0) {
        return '?' + queryString.stringify(fields);
    }
    return '';
}
