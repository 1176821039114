import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { map, remove, orderBy, maxBy } from "lodash";
import RightIcon from "mdi-react/ChevronRightIcon";

const CounterSummaryPropType = {
    value: PropTypes.number.isRequired,
    legend: PropTypes.string.isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func
};

const CounterItemPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func
});

export default class BarCounter extends PureComponent {
    static propTypes = {
        counterItems: PropTypes.arrayOf(CounterItemPropType).isRequired,
        summary1: PropTypes.shape(CounterSummaryPropType),
        summary2: PropTypes.shape(CounterSummaryPropType),
        title: PropTypes.string,
        summaryTitle: PropTypes.string,
        barsTitle: PropTypes.string,
        maxBars: PropTypes.number.isRequired,
        myItemsIds: PropTypes.array.isRequired,
        total: PropTypes.number.isRequired,
        onOthersClick: PropTypes.func
    };

    static defaultProps = {
        onOthersClick: array => {}
    };

    constructor(props) {
        super(props);
        this.getLegend = this.getLegend.bind(this);
        this.truncateOthers = this.truncateOthers.bind(this);
    }

    getLegend(summary) {
        if (!summary.legend) {
            return <div className="legend"></div>;
        }
        return (
            <div
                className="legend"
                onClick={summary.onClick ? summary.onClick : () => {}}
            >
                {summary.url ? (
                    <a href={summary.url}>
                        {summary.legend}
                        <RightIcon />
                    </a>
                ) : (
                    summary.legend
                )}
            </div>
        );
    }

    truncateOthers() {
        let items = this.props.counterItems;
        let myIds = this.props.myItemsIds;
        let myItems = remove(items, function(item) {
            if (myIds.indexOf(item.id) !== -1) {
                item.isMine = true;
                return true;
            }
            return false;
        });
        items = orderBy(items, ["value"], ["desc"]);
        if (this.props.maxBars >= items.length + myItems.length) {
            items = items.concat(myItems);
            return orderBy(items, ["value"], ["desc"]);
        }
        let bars = items.splice(0, this.props.maxBars - myItems.length - 1);
        var othersObj = { id: 0, value: 0, label: "Otros", itemsIds: [] };
        items.forEach(function(item) {
            othersObj.value += item.value;
            othersObj.itemsIds.push(item.id);
        });
        var onOthersClick = this.props.onOthersClick;
        othersObj.onClick = function() {
            onOthersClick(othersObj.itemsIds);
        };
        bars = orderBy(
            bars.concat(myItems),
            ["value", "isMine"],
            ["desc", "asc"]
        );
        bars.push(othersObj);
        return bars;
    }

    render() {
        const getHeight = (counter, total) => {
            if (isNaN(total) || total === 0) return 0;
            return ((counter * 100) / total).toFixed(0);
        };
        const barElements = this.truncateOthers();
        let total = maxBy(barElements, "value");
        total = total ? total.value : 0;
        return (
            <div className="barcounter">
                {this.props.title ? (
                    <div className="barcounter-title">{this.props.title}</div>
                ) : null}
                <div className="barcounter-section-titles clearfix">
                    <div className="barcounter-summary-title">
                        {this.props.summaryTitle
                            ? this.props.summaryTitle
                            : null}
                    </div>
                    <div className="barcounter-bars-title">
                        {this.props.barsTitle ? this.props.barsTitle : null}
                    </div>
                </div>
                <div className="barcounter-summary-container">
                    <div className="barcounter-summary">
                        <div className="count">{this.props.summary1.value}</div>
                        {this.getLegend(this.props.summary1)}
                    </div>
                    <div className="barcounter-summary">
                        <div className="count">{this.props.summary2.value}</div>
                        {this.getLegend(this.props.summary2)}
                    </div>
                </div>
                <div className="barcounter-bars-container clearfix">
                    {map(barElements, function(item) {
                        let barStyle = {
                            height: getHeight(item.value, total) + "%"
                        };
                        let layoutStyle = {
                            cursor: item.onClick ? "pointer" : "default"
                        };
                        return (
                            <div
                                className="barcounter-bar-layout"
                                key={"bar-" + item.id}
                                style={layoutStyle}
                                onClick={item.onClick}
                            >
                                <div className="title">{item.label}</div>
                                <div className="bar-container">
                                    <div
                                        className={
                                            item.isMine
                                                ? "bar bar--mine"
                                                : "bar"
                                        }
                                        style={barStyle}
                                    ></div>
                                </div>
                                <div className="legend">{item.value}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
