import { Constantes, Utils } from "@common/utils";
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useClickAway } from "react-use";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import ProductImage from "./ProductImage";
import { isEqual, uniqBy } from "lodash";

const SellerNameButton = ({
    id,
    iconName,
    placement,
    trigger,
    container = "body",
    product,
    storeName,
    sellerName,
    showMessage,
    showHistoric
}) => {
    const [isOpen, setOpen] = useState(false);
    const ref = useRef(id);

    const randomId = Utils.generateUniqueID();
    
    useClickAway(ref, el => {
        if (el && el.target.classList.contains("message-icon-button")) {
            setOpen(false);
        }
    });

    const onRowClick =(url) => {
        Utils.openInNewTab(url);
    }

    const renderProductInfoStrategy = () => {
        return (
            <div className="main-info">
                {isEqual(product.yourProduct, true) ?
                <>
                <div
                    className={
                        "sku" + (Utils.hasValue(product.bestSellerPrice.sku) ? " " : " empty")
                    }
                >
                    {Utils.hasValue(product.bestSellerPrice.sku) ? "SKU: " + product.bestSellerPrice.sku
                        : "No lo tienes"}
                </div>
                <div className="brand-retail">
                    {product.bestSellerPrice.brand} &nbsp;
                    <span
                        className={
                            "model" +
                            (Utils.hasValue(product.bestSellerPrice.model) ? " " : " empty")
                        }
                        id={"model-" + randomId}
                    >
                        {product.bestSellerPrice.model}
                    </span>
                </div>
                </>
                : 
                <>
                <div className="brand_">
                    {product.bestSellerPrice.brand} &nbsp;
                    <span className={"model-brand" + (Utils.hasValue(product.bestSellerPrice.model) ? " " : " empty")}
                        id={"model-" + randomId}>
                        {product.bestSellerPrice.model}
                    </span>
                </div>
                <div className={"sku-brand" + (Utils.hasValue(product.bestSellerPrice.sku) ? " " : " empty")}>
                    {Utils.hasValue(product.bestSellerPrice.sku) ? "SKU: " + product.bestSellerPrice.sku
                        : "No lo tienes"}
                </div>
                </>}
                <div className="days">
                    {Utils.buildDaysInfo(product.bestSellerPrice.daysInMarket)}
                </div>
            </div>
        );
    };

    const renderPriceAndDPMStrategy = () => {
        const dpm = Utils.formatNumber(product.dpmValue);

        return (
            <div className="info-extra-container retail">
                <div className="info-section">
                    <div className="medium-price">
                        {isEqual(product.yourProduct, true) ?
                        <div className="seller-label-medium-price">
                        {isEqual(storeName, "Tu Mkp") ? "Menor precio en tu MKP" : "Menor precio en MKP " + Utils.renameStoreName(storeName)}
                        </div>
                        : <div className="seller-label-medium-price">
                        {"Menor precio en MKP " + Utils.renameStoreName(storeName)}
                        </div>}
                        <div className="seller-price-value">
                            {product.bestSellerPrice.price ? Utils.formatPrice(product.bestSellerPrice.price) : "-"}
                        </div>
                        <div className="seller-dpm-value">
                            {dpm !== null ? (
                                <span
                                    className={Utils.computeDPMClasses(
                                        product.dpmValue
                                    )}
                                >
                                    DPM {Utils.roundNumber(product.dpmValue, 1)}
                                    %
                                </span>
                            ) : null}
                        </div>  
                    </div>
                </div>
            </div>
        );
    };

    const renderProductImageStrategy = () => {
        return <ProductImage imageUrl={product.sellerMatches[0].imageUrl} />;
    };

    const toggle = event => {
        event.stopPropagation();
        setOpen(!isOpen);
    };

    const show = () => {
        setOpen(true);
    };

    const hide = () => {
        setOpen(false);
    };

    let button;
    if (isEqual(showMessage, true)) {
        switch (trigger) {
            case "hover":
                button = (
                    <button
                        className={`message-icon-button-detail ${iconName}`}
                        id={id}
                        onMouseEnter={show}
                        onMouseLeave={hide}
                    >
                        <span className="btn-message-detail">
                            Ver más sellers
                        </span>
                    </button>
                );
                break;
            case "click":
                button = (
                    <button
                        className={`message-icon-button-detail ${iconName}`}
                        id={id}
                        onClick={toggle}
                    >
                        <span className="btn-message-detail">
                            Ver más sellers
                        </span>
                    </button>
                );
                break;
            default:
                button = (
                    <button
                        className={`message-icon-button-detail default-color ${iconName}`}
                    >
                        <span className="btn-message-detail">
                            Ver más sellers
                        </span>
                    </button>
                );
                break;
        }
    } else {
        switch (trigger) {
            case "hover":
                button = (
                    <button
                        className={`message-icon-button ${iconName}`}
                        id={id}
                        onMouseEnter={show}
                        onMouseLeave={hide}
                    ></button>
                );
                break;
            case "click":
                button = (
                    <button
                        className={`message-icon-button ${iconName}`}
                        id={id}
                        onClick={toggle}
                    ></button>
                );
                break;
            default:
                button = (
                    <button
                        className={`message-icon-button default-color ${iconName}`}
                    ></button>
                );
                break;
        }
    }

    return (
        <span ref={ref} className={`message-button-container ${placement}`}>
            {button}
            {trigger && showHistoric && (
                <Popover
                    ref={ref}
                    container={container}
                    placement="top"
                    isOpen={isOpen}
                    target={id}
                    toggle={toggle}
                    className="popover-empty-seller"
                >
                    <p className="empty-seller-title">¿Quién es el seller?</p>
                    {sellerName ? (
                        <span className="historic-seller-name">
                            {sellerName}
                        </span>
                    ) : (
                        <span className="empty-seller">
                            Seller no publicado en esta web
                            <i className="icon-loading"></i>
                        </span>
                    )}
                </Popover>
            )}

            {trigger && product && (
                <>
                    {product.sellerMatches[0] ? (
                        <Popover
                            ref={ref}
                            container={container}
                            className="message-icon-button-popover"
                            placement={placement}
                            isOpen={isOpen}
                            target={id}
                            toggle={toggle}
                        >
                            <PopoverHeader>
                                <div className="message-button-popover-container">
                                    <span className="seller-vendiendo">
                                        Sellers vendiendo en: {isEqual(storeName, "Tu Mkp") ? "Tu marketplace" : Utils.renameStoreName(storeName)}
                                    </span>
                                    <div className="product-canonical-container" onClick={() => onRowClick(product.bestSellerPrice.url)}>
                                        <div className="img-canonical-container">
                                            {renderProductImageStrategy()}
                                        </div>
                                        <div className="product-canonical-info">
                                            <div className="model-info">
                                                {renderProductInfoStrategy()}
                                            </div>
                                            <div>
                                                {renderPriceAndDPMStrategy()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PopoverHeader>
                            <PopoverBody>
                                <div className="title-seller-matches">
                                    <span>Seller</span>
                                    <span className="precio">Precio</span>
                                    <span className="dscto">Dscto</span>
                                </div>
                                {uniqBy(product.sellerMatches, 'sellerName').map((item, i) => (
                                    <div key={i}>
                                        {isEqual(
                                            item.sellerName,
                                            Utils.getMinPriceSeller(product.sellerMatches)
                                        ) ? (
                                            <div className="seller-minimum-price" onClick={() => onRowClick(item.url)}>
                                                <span className="seller-name-minimun-price" >
                                                    {item.sellerName}
                                                </span>
                                                <div className="item-price-container">
                                                    <span className="item-price-seller-minimum">
                                                        {Utils.formatPrice(item.price)}
                                                    </span>
                                                    <span className="item-offer-price">
                                                        {Utils.formatPrice(item.normalPrice)}
                                                    </span>
                                                    
                                                </div>
                                                {item.discount ? (
                                                    <span className="discount-container-seller-minimum">
                                                        {item.discount}%
                                                    </span>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div className="table-seller-name" onClick={() => onRowClick(item.url)}>
                                                <span className="sellers-name">{item.sellerName}</span>
                                                {item.statusProduct === Constantes.STATUS_PRODUCT.WITH_STOCK ?
                                                <>
                                                <div className="item-price-container">
                                                    <span className="item-price">
                                                        {Utils.formatPrice(item.price)}
                                                    </span>
                                                    <span className="item-offer-price">
                                                        {Utils.formatPrice(item.normalPrice)}
                                                    </span>
                                                </div>
                                                {item.discount ? (
                                                    <span className="item-discount-container">
                                                        {item.discount}%
                                                    </span>
                                                ) : null}
                                                </>
                                                : <>
                                                <div className="item-price-container">
                                                    <span className="item-price-ws">
                                                        {Utils.formatPrice(item.price)}
                                                    </span>
                                                    <span className="item-offer-price-ws">
                                                        {Utils.formatPrice(item.normalPrice)}
                                                    </span>
                                                    <span className="item-without-stock">Sin stock</span>
                                                </div>
                                                {item.discount ? (
                                                    <span className="discount-container-ws">
                                                        {item.discount}%
                                                    </span>
                                                ) : null}
                                                </>}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </PopoverBody>
                        </Popover>
                    ) : (
                        <Popover
                            className="popover-empty-seller"
                            ref={ref}
                            container={container}
                            placement={placement}
                            isOpen={isOpen}
                            target={id}
                            toggle={toggle}
                        >
                            <p className="empty-seller-title">
                                ¿Quién es el seller?
                            </p>
                            <span className="empty-seller">
                                Seller no publicado en esta web
                                <i className="icon-loading"></i>
                            </span>
                        </Popover>
                    )}
                </>
            )}

            {!trigger && (
                <span className="static-message-content">
                    <>
                        {sellerName ? (
                            sellerName
                        ) : (
                            <span className="empty-seller">
                                Seller no publicado
                                <i className="icon-loading"></i>
                            </span>
                        )}
                    </>
                </span>
            )}
        </span>
    );
};

SellerNameButton.propTypes = {
    product: PropTypes.object,
    id: PropTypes.string,
    iconName: PropTypes.string,
    placement: PropTypes.string,
    trigger: PropTypes.string,
    storeId: PropTypes.number,
    showMessage: PropTypes.bool,
    showHistoric: PropTypes.bool
};

export { SellerNameButton };
