import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { find } from 'lodash';
import RetailCompassLayout from '@common/components/src/ui/RetailCompassLayout'
import ExitToApp from '@material-ui/icons/ExitToApp';
import Person from '@material-ui/icons/Person';
import { RetailCompassStorage, UserUtils, RouterUtils } from "@common/utils/"
import CategoryPartMenu from './CategoryPartMenu';
import { refreshUpdateDate } from '../../labels/LabelsState';
import { default as Select } from '../../table/SortingSelect';
import { Auth0Config } from "@common/auth/";
import { withAuth0 } from '@auth0/auth0-react';
import TopbarNav from "@common/components/src/ui/TopbarNav";

class Layout extends PureComponent {

  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.renderTopbarNav = this.renderTopbarNav.bind(this);
    this.renderUserSection = this.renderUserSection.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.logout = this.logout.bind(this);
    this.refreshUpdateDate = this.refreshUpdateDate.bind(this);
    this.onUsernameEnter = this.onUsernameEnter.bind(this);
    this.onUsernameLeave = this.onUsernameLeave.bind(this);
    this.onChangeSelectedStore = this.onChangeSelectedStore.bind(this);
    this.getVisibleStores = this.getVisibleStores.bind(this);

    this.state = {
      showLogoutAction: false,
      selectedStore: null
    }
  }

  isLoggedIn() {
    return RetailCompassStorage.isLoggedIn();
  }

  refreshUpdateDate() {
    if (!this.props.isFetchingRefreshUpdateDate && this.props.labelsNeedToBeSynchronized) {
      const currentUser = RetailCompassStorage.getUser();
      let userId = currentUser.id;
      this.props.refreshUpdateDate({ labels: this.props.labels, keepalive: true, userId });
    }
  }

  logout() {
    this.refreshUpdateDate();

    RetailCompassStorage.logout();
    this.props.auth0.logout({ returnTo: Auth0Config.REDIRECT_URL });
  }

  renderTopbarNav() {
    return (
      <TopbarNav>
        <CategoryPartMenu />
      </TopbarNav>
    );
  }

  onUsernameEnter() {
    if (!this.state.showLogoutAction) {
      this.setState({ showLogoutAction: true });
    }
  }

  onUsernameLeave() {
    if (this.state.showLogoutAction) {
      this.setState({ showLogoutAction: false });
    }
  }

  onChangeSelectedStore(selectedStore) {
    let currentUser = RetailCompassStorage.getUser();

    if (currentUser.currentStore.id !== selectedStore.value) {
      this.refreshUpdateDate();
      currentUser.currentStore = find(currentUser.stores, { id: selectedStore.value });

      // condición especial: Si la tienda a la que cambia no tiene la categoría de la tienda anterior, 
      // entonces la categoría que debe mostrar por defecto debe ser la primera del menú
      const currentCategory = RetailCompassStorage.getCurrentCanonicalCategory();
      const existsCategoryInBothStores = !!find(selectedStore.categories, { path: currentCategory.path });

      if (existsCategoryInBothStores) {
        RetailCompassStorage.setLastSelectedStoreAndCategory(selectedStore.value, currentCategory.id, currentCategory.key);
      } else {
        RetailCompassStorage.setLastSelectedStoreAndCategory(selectedStore.value, null, null);
      }

      currentUser = UserUtils.processUserObj(currentUser);
      RetailCompassStorage.setUser(currentUser);
      RetailCompassStorage.setTrackClientSuccessRequired(true);
      setTimeout(() => {
        const baseUrl = RouterUtils.buildModuleViewPath(RouterUtils.MODULES_VIEWS.LIST);
        window.location.href = baseUrl;
      }, 500);

    }
  }

  getVisibleStores() {
    const stores = RetailCompassStorage.getStores();
    let storesResult = stores.filter(storeItem => !!storeItem.isValid).map((storeItem) => {
      return {
        ...storeItem,
        value: storeItem.id,
        key: storeItem.id,
        label: storeItem.internalName,
      };
    });

    return storesResult.sort((a, b) => a.label.localeCompare(b.label, 0, { numeric: false }));
  }

  renderUserSection() {
    let isLoggedIn = this.isLoggedIn();
    if (!isLoggedIn) {
      return null;
    }

    const visibleStores = this.getVisibleStores();
    const currentStoreId = RetailCompassStorage.getStoreId();
    const selectedStore = find(visibleStores, { key: currentStoreId });


    return (<div className="name-container">
      <span className="user-section" style={{ display: "block" }} onMouseEnter={this.onUsernameEnter} onMouseLeave={this.onUsernameLeave}>
        {!this.state.showLogoutAction &&
          <a className="logout" style={{ cursor: "pointer", fontSize: "12px" }} onClick={(e) => { this.logout(); }}>
            <Person style={{ fontSize: "16px", verticalAlign: "sub" }} className="icon" /> {RetailCompassStorage.getFullName()}
          </a>
        }
        {this.state.showLogoutAction &&
          <a className="logout" style={{ cursor: "pointer", fontSize: "12px" }} onClick={(e) => { this.logout(); }}>
            <ExitToApp style={{ fontSize: "14px", verticalAlign: "sub" }} className="icon" />Cerrar sesión
          </a>
        }
      </span>

      {visibleStores.length > 1 &&
        <Select
          enableHandleCloseMenuOnScroll={true}
          input={{ value: selectedStore || visibleStores[0] }}
          name="layout-stores-select"
          containerClassName='layout-stores-select'
          placeholder="Select"
          onChange={this.onChangeSelectedStore}
          options={visibleStores} />
      }

    </div>);
  }

  renderSidebarContent(props) {
    return (
      null
    );
  }

  render() {
    return (
      <div>
        <RetailCompassLayout renderUserSection={this.renderUserSection} renderTopbarNav={this.renderTopbarNav} renderSidebarContent={this.renderSidebarContent} changeMobileSidebarVisibility={() => { }} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetchingRefreshUpdateDate: state.labelStateReducer.isFetchingRefreshUpdateDate,
    labels: state.labelStateReducer.labels || [],
    labelsNeedToBeSynchronized: state.labelStateReducer.labelsNeedToBeSynchronized
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    refreshUpdateDate: (params) => refreshUpdateDate(dispatch, params)
  };
};

const LayoutConnect = connect(mapStateToProps, mapDispatchToProps)(Layout);
export default withAuth0(withRouter(LayoutConnect));
