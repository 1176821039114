import React, { Component } from 'react';
import { get, find, some, includes } from "lodash";
import { Container, Card, CardBody, Row, Button } from 'reactstrap';
import { PropTypes } from "prop-types";
import { ProductDetailHeader, RCPopover } from '@common/components';
import { AttributesApp } from '@app/attributes';
import currentFilter from './__tests__/currentFilter.json';
import { Hotjar, Utils, Constantes, RouterUtils } from "@common/utils/"
import AlertComponent from "@common/components/src/ui/AlertComponent";
import IdenticalMatchesTable, { IdenticalMatchesTableStrategy } from "../common/components/IdenticalMatchesTable";
import RemovedMatchesTable from "../common/components/RemovedMatchesTable";
import SuggestedMatchesTable from "../common/components/SuggestedMatchesTable";
import MatchesSearch from "../common/components/MatchesSearch";
import EmptyTable from "../common/components/EmptyTable";
import moment from 'moment';
import { withRouter } from 'react-router';
import { getNameToShow, getFieldFromContext, setColorsProps } from '../common/utils/utils';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export class Validations extends Component {

    static propTypes = {
        isFetching: PropTypes.bool.isRequired,
        currentProduct: PropTypes.object,
        fetchProduct: PropTypes.func.isRequired,
        attributesData: PropTypes.array,
        storedAttributes: PropTypes.array,
        getSuggestedMatchesByProductId: PropTypes.func.isRequired,
        suggestedMatches: PropTypes.array.isRequired,
        transformIntoIdentical: PropTypes.func.isRequired,
        transformIntoRemoved: PropTypes.func.isRequired,
        isTransformingTheMatch: PropTypes.bool,
        isRemovingTheMatch: PropTypes.bool,
        potentialMatches: PropTypes.array,
        searchPotentialMatches: PropTypes.func.isRequired,
        getStoreAndCompetitors: PropTypes.func.isRequired,
        competitors: PropTypes.array,
        createNewMatch: PropTypes.func.isRequired,
        allMatches: PropTypes.array,
        getAllMatchesByProductId: PropTypes.func.isRequired,
        canonicalMarkAsReviewed: PropTypes.func.isRequired,
        canonicalIsMarkAsReviewed: PropTypes.bool,
        isSavingRelationshipProductAttributeNewLov: PropTypes.bool,
        isFetchingHomologationAttributes: PropTypes.bool
    };

    static defaultProps = {
        isFetching: false,
        canonicalProduct: null,
        attributesData: [],
        storedAttributes: [],
        suggestedMatches: [],
        potentialMatches: [],
        competitors: [],
        canonicalIsMarkAsReviewed: false,
        isSavingRelationshipProductAttributeNewLov: false
    };

    constructor(props) {
        super(props);
        this.state = {};

        this.editProductAction = this.editProductAction.bind(this);
        this.afterSaveAlert = this.afterSaveAlert.bind(this);
        this.handleApproveClick = this.handleApproveClick.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.handleCreateNewColorClick = this.handleCreateNewColorClick.bind(this);
        this.handleRejectClick = this.handleRejectClick.bind(this);
        this.getParams = this.getParams.bind(this);
        this.extractMatchParamsWithContextOrEmpty = this.extractMatchParamsWithContextOrEmpty.bind(this);
        this.handleCanonicalMarkAsReviewed = this.handleCanonicalMarkAsReviewed.bind(this);
        this.computeIsCellPhoneCategory = this.computeIsCellPhoneCategory.bind(this);
        this.computeCustomTitle = this.computeCustomTitle.bind(this);
        this.handleHomologationAttributes = this.handleHomologationAttributes.bind(this);
    }

    extractMatchParamsWithContextOrEmpty() {
        let matchParams = this.extractMatchParamsOrEmpty();
        let contextInfo = Utils.getDecodedContextInfo(matchParams.contextInfo);
        return {
            ...matchParams,
            ...contextInfo
        };
    }

    extractMatchParamsOrEmpty() {
        return get(this.props.match, 'params', {});
    }

    componentDidMount() {
        let matchParams = this.extractMatchParamsWithContextOrEmpty();
        let { productId, canonicalStoreId } = matchParams;
        this.props.fetchProduct({ productId });
        this.props.getSuggestedMatchesByProductId(productId);
        this.props.getStoreAndCompetitors(canonicalStoreId);
        this.props.getAllMatchesByProductId(productId);
        Hotjar.load(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isCreatingNewMatch && !this.props.isCreatingNewMatch) {
            this.handleCanonicalMarkAsReviewed();
        }

        if (prevProps.isTransformingTheMatch && !this.props.isTransformingTheMatch) {
            this.handleCanonicalMarkAsReviewed();
        }

        if (prevProps.isRemovingTheMatch && !this.props.isRemovingTheMatch) {
            this.handleCanonicalMarkAsReviewed();
        }

        if (!prevProps.attributesSaved && this.props.attributesSaved) {
            this.handleCanonicalMarkAsReviewed();
        }

        // cuando finaliza el evento de guardado del nuevo/existente color, llamamos al servicio 
        // que valida automaticamente los atributos
        if (!prevProps.isSavingRelationshipProductAttributeNewLov &&
            this.props.isSavingRelationshipProductAttributeNewLov &&
            !!this.props.currentProduct.isCanonicalPostPago) {
            this.handleHomologationAttributes();
        }

        // cuando finaliza el proceso de homologación automatica de atributos, debemos recargar la página para que cargue los nuevos valores
        if (prevProps.isFetchingHomologationAttributes &&
            !this.props.isFetchingHomologationAttributes &&
            !!this.props.currentProduct.isCanonicalPostPago) {
            window.location.reload();
        }

        // no permitimos que el usuario deje la página hasta que el servicio que marca el producto como revisado, haya sido llamado
        if (this.props.canonicalIsMarkAsReviewed) {
            window.onbeforeunload = () => "Por favor, espera mientras se guardan los datos.";
        } else {
            window.onbeforeunload = undefined
        }
    }

    handleHomologationAttributes() {
        const { currentProduct, fetchHomologationAttributes } = this.props;
        const attributeId = Constantes.ATTRIBUTES_ID.COLOR;
        fetchHomologationAttributes(currentProduct.productId, attributeId);
    }

    handleCanonicalMarkAsReviewed() {
        const { currentProduct, canonicalMarkAsReviewed } = this.props;
        let { jobId, userId, rolName } = this.extractMatchParamsWithContextOrEmpty();
        rolName = rolName || '';

        if (jobId && rolName.toUpperCase() === Constantes.USER_TYPE_CODE.VALIDATOR) {
            canonicalMarkAsReviewed(currentProduct.productId, jobId, userId);
        }
    }

    syncAttributes() {
        const { attributesData, storedAttributes } = this.props;
        let attributes = {};

        attributesData.forEach(attributesDataItem => {
            attributes[attributesDataItem.id] = [];
            let storedAttributesValue = find(storedAttributes, { attributeId: attributesDataItem.id });
            if (storedAttributesValue) {
                let attributesDataItemValue = find(attributesDataItem.values, { id: storedAttributesValue.valueId });
                if (attributesDataItemValue) {
                    attributes[attributesDataItem.id].push(attributesDataItemValue.value);
                }
            }
        });

        return attributes;
    }

    editProductAction(object) {
        this.props.saveProductInfo({ modelo: object.model, productId: this.props.currentProduct.productId });
    }

    isValidProduct() {
        const { currentProduct } = this.props
        return !!(currentProduct && currentProduct.productId);
    }

    afterSaveAlert() {
        if (!this.props.attributesSaved) {
            return null;
        }
        return (
            <AlertComponent color="success" buttonOnHide={false}>
                <p>
                    <span className="bold-text">
                        Los cambios ya se guardaron.{" "}
                    </span>
                    Puedes seguir editando o cerrar la página
                </p>
            </AlertComponent>
        );
    }

    handleApproveClick(product, shouldBeRemovedFromAssociatedCanonicals) {
        this.props.transformIntoIdentical(this.getParams(product, shouldBeRemovedFromAssociatedCanonicals));
    }

    handleRejectClick(product) {
        this.props.transformIntoRemoved(this.getParams(product));
    }

    handleRemoveClick(product) {
        this.props.removeIdentical(this.getParams(product));
    }

    handleCreateNewColorClick(matchProduct, selectedColor) {
        const { saveRelationshipProductAttributeNewLov } = this.props;
        let { userId } = this.extractMatchParamsWithContextOrEmpty();
        const attributeId = Constantes.ATTRIBUTES_ID.COLOR;
        saveRelationshipProductAttributeNewLov(matchProduct.productId, attributeId, selectedColor.name, userId)
    }

    getParams(product, shouldBeRemovedFromAssociatedCanonicals) {
        let matchParams = this.extractMatchParamsWithContextOrEmpty();
        return Utils.buildMatchParams(product, matchParams, this.props.currentProduct.productId, shouldBeRemovedFromAssociatedCanonicals);
    }

    computeIsCellPhoneCategory() {
        let { currentProduct } = this.props;
        const TEXT_TO_MATCH_ITEMS = ['Telefon', 'Telefonía', 'Celular', 'Celulares'];

        let matchParams = this.extractMatchParamsWithContextOrEmpty();
        let fullText = [
            ...get(currentProduct, 'categoriesPaths', []),
            ...get(matchParams, 'categoriesPaths', []),
            get(matchParams, 'taskName', ''),
            get(matchParams, 'categoryName', ''),
            get(matchParams, 'categoryFullPath', '')
        ].join(' ');

        return some(TEXT_TO_MATCH_ITEMS, (el) => includes(fullText, el));
    }

    computeCustomTitle(size) {
        let isCellPhoneCategory = this.computeIsCellPhoneCategory();
        let title = Utils.getCountText(size, 'CANDIDATO A MATCH QUE YA ESTÁ EN OTRO CANONICO', 'CANDIDATOS A MATCH QUE YA ESTÁN EN OTROS CANONICOS');

        if (size === 0) {
            title = "0 CANDIDATOS A MATCH QUE YA ESTÁN EN OTROS CANONICOS"
        }

        let popoverBody = 'Estos productos ya son match de otro canónico, si apruebas el match, entonces automáticamente se quitara del canónico en el que está y se unirá al canónico que estas revisando.';
        if (isCellPhoneCategory) {
            popoverBody = 'Estos celulares ya son match de otro canónico, si apruebas el match, entonces automáticamente se agregará al canónico que estas revisando y se mantendrá en el canónico ya existente.'
        }

        return <div className="smt-header-title-container">
            <span className="smt-header--title">{title}</span>
            <span className="smt-header--icon-container">
                <RCPopover popoverBody={popoverBody}
                    className="suggested-matches-table">
                    <InfoOutlinedIcon className="smt-header__icon" />
                </RCPopover>
            </span>
        </div>;
    }

    render() {
        if (!this.isValidProduct()) {
            return null;
        }
        let { currentProduct, suggestedMatches } = this.props;
        let matchParams = this.extractMatchParamsWithContextOrEmpty();
        let userId = matchParams.userId;
        let username = matchParams.username;
        let rolName = String(matchParams.rolName);

        // solo productos con status -1 y 1, pueden ser procesados en este componente
        let productStatusId = get(this.props.currentProduct, 'status');
        let canonicalStatusItems = [Constantes.STATUS_PRODUCT.WITH_STOCK, Constantes.STATUS_PRODUCT.WITHOUT_STOCK];
        if (!canonicalStatusItems.includes(productStatusId)) {
            return null;
        }

        setColorsProps(currentProduct);

        currentProduct.attributes = this.syncAttributes();
        currentProduct.enhancedAttributes = Utils.buildAttributesArray(currentProduct.attributes, this.props.attributesData, true);
        currentProduct.identicalMatches = currentProduct.directMatches.map(o => {
            let match = find(this.props.allMatches, ["competitorId", o.productId]) || {};
            let updatedTimeStamp = moment(match.updated, Constantes.DATE_FORMATS.MATCH_UPDATED_FORMAT);
            match.updatedDateTime = updatedTimeStamp.format("X");
            return { ...match, ...o }
        });

        let suggestedMatchesAssociatedWithCanonicals = [];
        let suggestedNonCanonicalMatches = [];

        Utils.isArrayWithData(suggestedMatches) && suggestedMatches.forEach(match => {
            if (Utils.isArrayWithData(match.associatedCanonicalProducts)) {
                suggestedMatchesAssociatedWithCanonicals.push(match);
            } else {
                suggestedNonCanonicalMatches.push(match);
            }
        });

        let isCellPhoneCategory = this.computeIsCellPhoneCategory();


        return (<div id="validationModuleIndex" className="wrapper wrapper--full-width clearfix edit-view">

            {this.afterSaveAlert()}
            <Container>
                <Row>
                    <div className="edit__left">
                        <Card className="edit__product-detail--header">
                            <CardBody >
                                <div className="user-badge">
                                    {getNameToShow(matchParams.contextInfo)}
                                </div>
                                <ProductDetailHeader
                                    producto={currentProduct}
                                    labels={[]}
                                    dispatch={{}}
                                    fetchProductItems={() => { }}
                                    currentFilter={currentFilter}
                                    tableStrategy={Constantes.MODULES.VALIDATION_APP}
                                    validationsParams={{
                                        editAction: this.editProductAction
                                    }} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody className="edit__attributes-container">
                                <AttributesApp
                                    match={{
                                        params: {
                                            ...this.extractMatchParamsWithContextOrEmpty()
                                        }
                                    }}
                                    isIFrame={false}
                                />
                                {
                                    rolName.toUpperCase() === Constantes.USER_TYPE_CODE.ADMIN ?
                                        <div style={{ textAlign: "center" }}>
                                            <Button size={'sm'} outline={true} className={"icon btn-google-search "} outline onClick={() => { Utils.openInNewTab(RouterUtils.buildWebappProductDetailPath(currentProduct.productId)) }}>
                                                Abrir producto en Webapp <OpenInNewIcon />
                                            </Button>
                                        </div> : null
                                }

                            </CardBody>
                        </Card>

                    </div>
                    <div className="edit__right">
                        <Card className="edit__product-detail--body">
                            <CardBody className="product-detail-body-container" >
                                <MatchesSearch
                                    userId={userId}
                                    username={username}
                                    allMatches={this.props.allMatches}
                                    competitors={this.props.competitors}
                                    canonicalProduct={this.props.currentProduct}
                                    searchPotentialMatches={this.props.searchPotentialMatches}
                                    potentialMatches={this.props.potentialMatches}
                                    onApproval={this.handleApproveClick}
                                    createNewMatch={this.props.createNewMatch}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    showImageComparer={true}
                                    jobId={getFieldFromContext("jobId", matchParams.contextInfo)} />
                            </CardBody>
                        </Card>

                        {!!this.props.currentProduct.isCanonicalPostPago ?
                            <Card className="edit__product-detail--body matches variants">
                                <CardBody className="product-detail-body-container" >
                                    <IdenticalMatchesTable
                                        currentProduct={this.props.currentProduct}
                                        isRemovingTheMatch={this.props.isRemovingTheMatch}
                                        removeIdentical={this.handleRemoveClick}
                                        allMatches={this.props.allMatches}
                                        showImageComparer={true}
                                        strategy={IdenticalMatchesTableStrategy.VARIANTS}
                                        dispatch={this.props.dispatch}
                                        createNewColor={this.handleCreateNewColorClick}
                                        countryCode={this.props.countryCode}
                                    />
                                </CardBody>
                            </Card>
                            : null}


                        <Card className="edit__product-detail--body suggested">
                            <CardBody className="product-detail-body-container" >
                                <SuggestedMatchesTable
                                    isCellPhoneCategory={isCellPhoneCategory}
                                    computeCustomTitle={(size) => this.computeCustomTitle(size)}
                                    computeApproveButtonText={() => {
                                        if (isCellPhoneCategory) {
                                            return <div>Aprobar <span>match</span></div>
                                        }
                                        return <div>Aprobar <span>match y quitar del otro canónico</span></div>
                                    }}
                                    showViewCanonicalProductLink={true}
                                    currentProduct={currentProduct}
                                    suggestedMatches={suggestedMatchesAssociatedWithCanonicals}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    handleApproveClick={(product) => { this.handleApproveClick(product, !isCellPhoneCategory) }}
                                    handleRejectClick={this.handleRejectClick}
                                    onEmpty={<EmptyTable title={this.computeCustomTitle(0)} />}
                                    showImageComparer={true}
                                />
                            </CardBody>
                        </Card>

                        <Card className="edit__product-detail--body suggested">
                            <CardBody className="product-detail-body-container" >
                                <SuggestedMatchesTable
                                    currentProduct={currentProduct}
                                    suggestedMatches={suggestedNonCanonicalMatches}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    handleApproveClick={this.handleApproveClick}
                                    handleRejectClick={this.handleRejectClick}
                                    onEmpty={<EmptyTable title="0 CANDIDATOS A MATCH" />}
                                    showImageComparer={true}
                                />
                            </CardBody>
                        </Card>

                        <Card className="edit__product-detail--body matches">
                            <CardBody className="product-detail-body-container" >
                                <IdenticalMatchesTable
                                    currentProduct={this.props.currentProduct}
                                    isRemovingTheMatch={this.props.isRemovingTheMatch}
                                    removeIdentical={this.handleRemoveClick}
                                    allMatches={this.props.allMatches}
                                    showImageComparer={true}
                                    strategy={!!this.props.currentProduct.isCanonicalPostPago ? IdenticalMatchesTableStrategy.IDENTICAL_VARIANTS : IdenticalMatchesTableStrategy.IDENTICAL}
                                    countryCode={this.props.countryCode}
                                />
                            </CardBody>
                        </Card>

                        <Card className="edit__product-detail--body matches" style={{ marginBottom: "30px" }}>
                            <CardBody className="product-detail-body-container" >
                                <RemovedMatchesTable
                                    currentProduct={this.props.currentProduct}
                                    isTransformingTheMatch={this.props.isTransformingTheMatch}
                                    handleApproveClick={this.handleApproveClick}
                                    allMatches={this.props.allMatches}
                                    showImageComparer={true}
                                />
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>)
    }
}
export default withRouter(Validations);
